import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { fetchDecks } from "../../../redux/actions/catalogs/deck/fetchDecks";
import { deleteDeck } from "../../../redux/actions/catalogs/deck/deleteDeck";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function DeckList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deckState = useSelector((state) => state.deckReducer);
 
  useEffect(() => {
    dispatch(fetchDecks());
    scrollToTop();
  }, []);

  if (deckState.fetching || deckState.processing) {
    return <Loader active inline />;
  }

  if (deckState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (deckState.message === "resource deleted successfully") {
    dispatch(fetchDecks());
  }

  const handleDeleteDeck = (deck_id) => {
    dispatch(deleteDeck(deck_id));
  };

  return (
    <>
      <HeaderCatalog title={t("deck_title")} message={deckState.message} to="/decks_new" />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("deck_name")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {deckState.decks.map((deck) => (
          <Table.Row key={deck.id}>
            <Table.Cell>{t(deck.name)}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/decks/${deck.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("deck_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteDeck(deck.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
