import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class PassengerBySpaceService {
  static getPassengerBySpace(space_id, passenger_id, cruise_id) {
    var url = API_BASE_URL + "spacePassengers/" + space_id + "/passenger/" + passenger_id;
    if (cruise_id) {
      url = url + "?cruise_id=" + cruise_id;
    }
    return axios.get(url);
  }

  static postPassengerBySpace(booking_id, space_id, payload) {
    return axios.post("spacePassengers/"+ booking_id +"/" + space_id, payload);
  }

  static putPassengerBySpace(booking_id, space_id, passenger_id, payload) {
    return axios.put(API_BASE_URL + "spacePassengers/" + booking_id +"/"+space_id + "/passenger/" + passenger_id, payload);
  }

  static deletePassengerBySpace(space_id, passenger_id) {
    return axios.delete(API_BASE_URL + "spacePassengers/" + space_id + "/passenger/" + passenger_id);
  }

  static verifyPassengerBySpace(identification) {
    return axios.get("spacePassengers/" + identification + "/verify");
  }
}
