import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ItineraryService from "../../../../services/catalogs/ItineraryService";

export const REQUEST_DELETE_IMAGE = "REQUEST_DELETE_IMAGE";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_ERROR = "DELETE_IMAGE_ERROR";

function requestDeleteImage() {
  return {
    type: REQUEST_DELETE_IMAGE,
  };
}

function deleteImageSuccess(json) {
  return {
    type: DELETE_IMAGE_SUCCESS,
    message: json.data,
  };
}

function deleteImageError(json) {
  return {
    type: DELETE_IMAGE_ERROR,
    error: json.data,
  };
}

export function deleteImage(itinerary_id) {
  return (dispatch) => {
    dispatch(requestDeleteImage());
    return ItineraryService.deleteImage(itinerary_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteImageSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteImage(itinerary_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteImageError(json.data));
      }
    });
  };
}
