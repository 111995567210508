import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CabinBerthService from "../../../../services/catalogs/CabinBerthService";

export const REQUEST_FETCH_CABIN_BERTH = "REQUEST_FETCH_CABIN_BERTH";
export const FETCH_CABIN_BERTH_SUCCESS = "FETCH_CABIN_BERTH_SUCCESS";
export const FETCH_CABIN_BERTH_ERROR = "FETCH_CABIN_BERTH_ERROR";
export const INITIAL_STATE_CABIN_BERTH = "INITIAL_STATE_CABIN_BERTH";

function requestFetchCabinBerth() {
  return {
    type: REQUEST_FETCH_CABIN_BERTH,
  };
}

function fetchCabinBerthSuccess(json) {
  return {
    type: FETCH_CABIN_BERTH_SUCCESS,
    cabinBerth: json.data,
  };
}

function fetchCabinBerthError(json) {
  return {
    type: FETCH_CABIN_BERTH_ERROR,
    error: json.data,
  };
}

export function initialStateCabinBerth() {
  return {
    type: INITIAL_STATE_CABIN_BERTH,
  };
}

export function fetchCabinBerth(cabin_id, id) {
  return (dispatch) => {
    dispatch(requestFetchCabinBerth());
    return CabinBerthService.getCabinBerth(cabin_id, id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCabinBerthSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCabinBerth(cabin_id, id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCabinBerthError(json.data));
      }
    });
  };
}
