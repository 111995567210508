import {
  REQUEST_FETCH_NUMBER_FLIGHTS,
  FETCH_NUMBER_FLIGHTS_SUCCESS,
  FETCH_NUMBER_FLIGHTS_ERROR,
  INITIAL_STATE_NUMBER_SPACE_FLIGHT,
} from "../../../actions/reservations/spaceFlight/fetchNumberFlightsBySpace";

const initialState = {
  flights: "",
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const numberSpaceFlightReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_NUMBER_SPACE_FLIGHT: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        flights: "",
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }

    case REQUEST_FETCH_NUMBER_FLIGHTS: {
      return {
        ...state,
        flights: "",
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_NUMBER_FLIGHTS_SUCCESS: {
      return {
        ...state,
        flights: action.numberFlights,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_NUMBER_FLIGHTS_ERROR: {
      return {
        ...state,
        flights: "",
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default numberSpaceFlightReducer;
