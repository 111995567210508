import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import DayService from "../../../../services/catalogs/DayService";

export const REQUEST_CREATE_DAY = "REQUEST_CREATE_DAY";
export const CREATE_DAY_SUCCESS = "CREATE_DAY_SUCCESS";
export const CREATE_DAY_ERROR = "CREATE_DAY_ERROR";

function requestCreateDay() {
  return {
    type: REQUEST_CREATE_DAY,
  };
}

function createDaySuccess(json) {
  return {
    type: CREATE_DAY_SUCCESS,
    message: json.data,
  };
}

function createDayError(json) {
  return {
    type: CREATE_DAY_ERROR,
    error: json.data,
  };
}

export function createDay(itinerary_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateDay());
    return DayService.postDay(itinerary_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createDaySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createDay(itinerary_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createDayError(json.data));
      }
    });
  };
}
