import UserService from "../../../../services/catalogs/UserService";

export const REQUEST_SIGNIN_USER = "REQUEST_SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_ERROR = "SIGNIN_USER_ERROR";

function requestSigninUser() {
  return {
    type: REQUEST_SIGNIN_USER,
  };
}

function signinUserSuccess(json) {
  return {
    type: SIGNIN_USER_SUCCESS,
    user: json.data,
  };
}

function signinUserError(json) {
  return {
    type: SIGNIN_USER_ERROR,
    error: json.data,
  };
}

export function signInAgencyUser(payload) {
  return (dispatch) => {
    dispatch(requestSigninUser());
    return UserService.signInAgencyUser(payload).then((json) => {   
      if (json.status === 200) {
        dispatch(signinUserSuccess(json.data));
      } else {
        dispatch(signinUserError(json.data));
      }
    });
  };
}

export function signIn(payload) {
  return (dispatch) => {
    dispatch(requestSigninUser());
    return UserService.signIn(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(signinUserSuccess(json.data));
      } else {
        dispatch(signinUserError(json.data));
      }
    });
  };
}
