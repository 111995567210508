import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class BerthService {
  static getBerths() {
    return axios.get("berths");
  }
  static getBerth(berth_id) {
    return axios.get("berths/" + berth_id);
  }

  static postBerth(payload) {
    return axios.post("berths", payload);
  }

  static putBerth(berth_id, payload) {
    return axios.put("berths/" + berth_id, payload);
  }

  static deleteBerth(berth_id) {
    return axios.delete("berths/" + berth_id);
  }
}
