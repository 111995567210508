import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import LanguageService from "../../../../services/catalogs/LanguageService";

export const REQUEST_FETCH_LANGUAGE = "REQUEST_FETCH_LANGUAGE";
export const FETCH_LANGUAGE_SUCCESS = "FETCH_LANGUAGE_SUCCESS";
export const FETCH_LANGUAGE_ERROR = "FETCH_LANGUAGE_ERROR";
export const INITIAL_STATE_LANGUAGE = "INITIAL_STATE_LANGUAGE";

function requestFetchLanguage() {
  return {
    type: REQUEST_FETCH_LANGUAGE,
  };
}

function fetchLanguageSuccess(json) {
  return {
    type: FETCH_LANGUAGE_SUCCESS,
    language: json.data,
  };
}

function fetchLanguageError(json) {
  return {
    type: FETCH_LANGUAGE_ERROR,
    error: json.data,
  };
}

export function initialStateLanguage() {
  return {
    type: INITIAL_STATE_LANGUAGE,
  };
}

export function fetchLanguage(language_id) {
  return (dispatch) => {
    dispatch(requestFetchLanguage());
    return LanguageService.getLanguage(language_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchLanguageSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchLanguage(language_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchLanguageError(json.data));
      }
    });
  };
}
