import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ItineraryService from "../../../../services/catalogs/ItineraryService";

export const REQUEST_FETCH_ITINERARIES = "REQUEST_FETCH_ITINERARIES";
export const FETCH_ITINERARIES_SUCCESS = "FETCH_ITINERARIES_SUCCESS";
export const FETCH_ITINERARIES_ERROR = "FETCH_ITINERARIES_ERROR";

function requestFetchItineraries() {
  return {
    type: REQUEST_FETCH_ITINERARIES,
  };
}

function fetchItinerariesSuccess(json) {
  return {
    type: FETCH_ITINERARIES_SUCCESS,
    itineraries: json.data,
  };
}

function fetchItinerariesError(json) {
  return {
    type: FETCH_ITINERARIES_ERROR,
    error: json.data,
  };
}

export function fetchItineraries(searchWord) {
  return (dispatch) => {
    dispatch(requestFetchItineraries());
    return ItineraryService.getItineraries(searchWord).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchItinerariesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchItineraries(searchWord));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchItinerariesError(json.data));
      }
    });
  };
}
