import ContractRateList from "../../containers/rates/contractRate/ContractRateList";
import ContractRateForm from "../../containers/rates/contractRate/ContractRateForm";

const routes = [
  {
    path: "/contractRates",
    component: ContractRateList,
  },
  {
    path: "/contractRates/contractRates_new",
    component: ContractRateForm,
  },
  {
    path: "/contractRates/:contract_id",
    component: ContractRateForm,
  },
];

export { routes as contractRateRoutes };
