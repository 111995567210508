import {
  REQUEST_FETCH_GENDERS,
  FETCH_GENDERS_SUCCESS,
  FETCH_GENDERS_ERROR,
} from "../../../actions/catalogs/gender/fetchGenders";

import {
  INITIAL_STATE_GENDER,
  REQUEST_FETCH_GENDER,
  FETCH_GENDER_SUCCESS,
  FETCH_GENDER_ERROR,
} from "../../../actions/catalogs/gender/fetchGender";

import {
  REQUEST_CREATE_GENDER,
  CREATE_GENDER_SUCCESS,
  CREATE_GENDER_ERROR,
} from "../../../actions/catalogs/gender/createGender";

import {
  REQUEST_UPDATE_GENDER,
  UPDATE_GENDER_SUCCESS,
  UPDATE_GENDER_ERROR,
} from "../../../actions/catalogs/gender/updateGender";

import {
  REQUEST_DELETE_GENDER,
  DELETE_GENDER_SUCCESS,
  DELETE_GENDER_ERROR,
} from "../../../actions/catalogs/gender/deleteGender";

const initialState = {
  genders: [],
  gender: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const genderReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_GENDER: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_GENDERS: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_GENDERS_SUCCESS: {
      return {
        ...state,
        genders: action.genders,
        gender: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_GENDERS_ERROR: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_GENDER: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_GENDER_SUCCESS: {
      return {
        ...state,
        genders: [],
        gender: action.gender,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_GENDER_ERROR: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_GENDER: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_GENDER_SUCCESS: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_GENDER_ERROR: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_GENDER: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_GENDER_SUCCESS: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_GENDER_ERROR: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_GENDER: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_GENDER_SUCCESS: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_GENDER_ERROR: {
      return {
        ...state,
        genders: [],
        gender: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default genderReducer;
