import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ReservationService from "../../../../services/reservations/ReservationService";

export const REQUEST_CANCELED_RESERVATION = "REQUEST_CANCELED_RESERVATION";
export const CANCELED_RESERVATION_SUCCESS = "CANCELED_RESERVATION_SUCCESS";
export const CANCELED_RESERVATION_ERROR = "CANCELED_RESERVATION_ERROR";

function requestCanceledReservation() {
  return {
    type: REQUEST_CANCELED_RESERVATION,
  };
}

function canceledReservationSuccess(json) {
  return {
    type: CANCELED_RESERVATION_SUCCESS,
    message: json.data,
  };
}

function canceledReservationError(json) {
  return {
    type: CANCELED_RESERVATION_ERROR,
    error: json.data,
  };
}

export function canceledReservation(reservation_id, payload) {
  return (dispatch) => {
    dispatch(requestCanceledReservation());
    return ReservationService.putReservationCanceled(
      reservation_id,
      payload
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(canceledReservationSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(canceledReservation(reservation_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(canceledReservationError(json.data));
      }
    });
  };
}
