import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customReservationList = createSlice({
  name: 'customReservationList',
  initialState: {
    cruise: {},
    reservations: [],
    reservationsCanceled: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateReservationListCustom(state, action) {
      return {
        cruise: {},
        reservations: [],
        reservationsCanceled: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesReservationListCustom(state, action) {
      return {
        cruise: {},
        reservations: [],
        reservationsCanceled: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchReservationListCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        reservations: action.payload.reservations,
        reservationsCanceled: action.payload.reservationsCanceled,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchReservationListCustomError(state, action) {
      return {
        cruise: {},
        reservations: [],
        reservationsCanceled: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchReservationListCustom = (cruise_id) => dispatch => {
  dispatch(requesReservationListCustom());
  axios.get(`/cruise/reservationList/${cruise_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchReservationListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchReservationListCustomSuccess(json.data.data));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchReservationListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateReservationListCustom,
  requesReservationListCustom,
  fetchReservationListCustomSuccess,
  fetchReservationListCustomError,
} = customReservationList.actions;

export default customReservationList.reducer;