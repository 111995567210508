import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideService from "../../../../services/catalogs/GuideService";

export const REQUEST_FETCH_GUIDE = "REQUEST_FETCH_GUIDE";
export const FETCH_GUIDE_SUCCESS = "FETCH_GUIDE_SUCCESS";
export const FETCH_GUIDE_ERROR = "FETCH_GUIDE_ERROR";
export const INITIAL_STATE_GUIDE = "INITIAL_STATE_GUIDE";

function requestFetchGuide() {
  return {
    type: REQUEST_FETCH_GUIDE,
  };
}

function fetchGuideSuccess(json) {
  return {
    type: FETCH_GUIDE_SUCCESS,
    guide: json.data,
  };
}

function fetchGuideError(json) {
  return {
    type: FETCH_GUIDE_ERROR,
    error: json.data,
  };
}

export function initialStateGuide() {
  return {
    type: INITIAL_STATE_GUIDE,
  };
}

export function fetchGuide(guide_id) {
  return (dispatch) => {
    dispatch(requestFetchGuide());
    return GuideService.getGuide(guide_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchGuideSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGuide(guide_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGuideError(json.data));
      }
    });
  };
}
