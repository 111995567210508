import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customSpaceList = createSlice({
  name: 'customSpaceList',
  initialState: {
    cruise: {},
    reservation: {},
    booking: {},
    spaces: [],
    spacesCanceled: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateSpaceListCustom(state, action) {
      return {
        cruise: {},
        reservation: {},
        booking: {},
        spaces: [],
        spacesCanceled: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesSpaceListCustom(state, action) {
      return {
        cruise: {},
        reservation: {},
        booking: {},
        spaces: [],
        spacesCanceled: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchSpaceListCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        reservation: action.payload.reservation,
        booking: action.payload.booking,
        spaces: action.payload.spaces,
        spacesCanceled: action.payload.spacesCanceled,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchSpaceListCustomError(state, action) {
      return {
        cruise: {},
        reservation: {},
        booking: {},
        spaces: [],
        spacesCanceled: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchSpaceListCustom = (booking_id, cruise_id, reservation_id) => dispatch => {
  dispatch(requesSpaceListCustom());
  var url = `booking/${booking_id}/spaceList`;
  url = url + `?cruise_id=${cruise_id}&&reservation_id=${reservation_id}`;
  axios.get(`/${url}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchSpaceListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSpaceListCustom(booking_id, cruise_id, reservation_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSpaceListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateSpaceListCustom,
  requesSpaceListCustom,
  fetchSpaceListCustomSuccess,
  fetchSpaceListCustomError,
} = customSpaceList.actions;

export default customSpaceList.reducer;