import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customGuideOnBoardForm = createSlice({
  name: 'customGuideOnBoardForm',
  initialState: {
    cruise: {},
    guides: [],
    guideLanguages: [],
    guideOnBoard: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateGuideOnBoardFormCustom(state, action) {
      return {
        cruise: {},
        guides: [],
        guideLanguages: [],
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesGuideOnBoardFormCustom(state, action) {
      return {
        cruise: {},
        guides: [],
        guideLanguages: [],
        guideOnBoard: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchGuideOnBoardFormCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        guides: action.payload.guides,
        guideLanguages: action.payload.guideLanguages,
        guideOnBoard: action.payload.guideOnBoard,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchGuideOnBoardFormCustomError(state, action) {
      return {
        cruise: {},
        guides: [],
        guideLanguages: [],
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchGuideOnBoardFormCustom = (searchWord, cruise_id, id) => dispatch => {
  dispatch(requesGuideOnBoardFormCustom())
  axios.get(`cruise/${cruise_id}/guideOnBoardForm?searchWord=${searchWord}&&id=${id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchGuideOnBoardFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGuideOnBoardFormCustom(searchWord, cruise_id, id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGuideOnBoardFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateGuideOnBoardFormCustom,
  requesGuideOnBoardFormCustom,
  fetchGuideOnBoardFormCustomSuccess,
  fetchGuideOnBoardFormCustomError,
} = customGuideOnBoardForm.actions;

export default customGuideOnBoardForm.reducer;