import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
import {
  fetchAvailableCabins,
  initialStateAvailableCabins,
} from "../../../../redux/actions/reservations/changeCabin/fetchAbailableCabins";
// import { fetchPassengersByBooking } from "../../../../redux/actions/reservations/bookingPassenger/fetchPassengersByBooking";
import {
  fetchCruise,
  initialStateCruise,
} from "../../../../redux/actions/reservations/cruise/fetchCruise";
import {
  fetchBooking,
  initialStateBooking,
} from "../../../../redux/actions/reservations/booking/fetchBooking";
import { updateTwoCabins } from "../../../../redux/actions/reservations/changeCabin/updateTwoCabins";
import {
  updateOneCabin,
  initialStateUpdateCabin,
} from "../../../../redux/actions/reservations/changeCabin/updateOneCabin";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { Link, useParams, useHistory } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import HeadDetail from "../../../../components/headDetail";

export default function AvailableCabinList() {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  let { cruise_id } = useParams();
  let { reservation_id } = useParams();
  let { booking_id } = useParams();
  let { cabin_id } = useParams();
  const cruiseState = useSelector((state) => state.cruiseReducer);
  const bookingState = useSelector((state) => state.bookingReducer);
  // const availableCabinState = useSelector(
  //   (state) => state.availableCabinReducer
  // );
  // const bookingPassengerState = useSelector(
  //   (state) => state.bookingPassengerReducer
  // );
  const updateCabinState = useSelector((state) => state.updateCabinReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCruise(cruise_id));
    dispatch(fetchAvailableCabins(cruise_id, reservation_id, booking_id));
    // dispatch(fetchPassengersByBooking(booking_id));
    dispatch(fetchBooking(booking_id));
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateAvailableCabins());
      dispatch(initialStateCruise());
      dispatch(initialStateBooking());
      dispatch(initialStateUpdateCabin());
    };
  }, [dispatch, cruise_id, reservation_id, booking_id]);

  if (
    // availableCabinState.fetching ||
    // bookingPassengerState.fetching ||
    cruiseState.fetching ||
    bookingState.fetching ||
    updateCabinState.fetching
  ) {
    return <CircularProgress />;
  }

  // if (availableCabinState.processing) {
  //   return <CircularProgress />;
  // }

  if (cruiseState.error) {
    return <div>Error: {cruiseState.error}</div>;
  }
  if (bookingState.error) {
    return <div>Error: {bookingState.error}</div>;
  }
  // if (bookingPassengerState.error) {
  //   return <div>Error: {bookingPassengerState.error}</div>;
  // }
  // if (availableCabinState.error) {
  //   return <div>Error: {availableCabinState.error}</div>;
  // }

  const handleShowMessage = (newState) => {
    setState({ open: true, ...newState });

    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
  };

  const handleChangeCabin = (
    cabinId,
    bookingId,
    cruiseType,
    numberPassengers,
    singleBed
  ) => {
    let data = { cabin_id_one: cabin_id, booking_id_one: booking_id };
    if (bookingId === "No asignado") {
      data = {
        ...data,
        cabin_id_two: cabinId,
        cruise_type_two: cruiseType,
        passengers_two: numberPassengers,
        single_bed_two: singleBed,
      };

      dispatch(updateOneCabin(data));
    } else {
      data = {
        ...data,
        cabin_id_two: cabinId,
        booking_id_two: bookingId,
        cruise_type_two: cruiseType,
        passengers_two: numberPassengers,
        single_bed_two: singleBed,
      };
      dispatch(updateTwoCabins(data));
    }
  };

  if (updateCabinState.message === "updated succesfully") {
    history.push(
      `/cruises/${cruise_id}/reservations/${reservation_id}/bookings`
    );
  }

  if (updateCabinState.message === "data changed") {
    if (open === false) {
      history.go(-1);
    }
  }

  return (
    <>
      {updateCabinState.message === "data changed" ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert severity="error" color="error" variant="filled">
            <p>
              La información de la cabina seleccionada ha cambiado, se le
              redirigirá a la página anterior auntomaticamente, espere un
              momento.
            </p>
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}

      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{cruiseState.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">
          <Link to={`/cruises/${cruise_id}/reservations`}>Reservaciones</Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">
          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings`}
          >
            Cabinas
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">{bookingState.booking.cabin_name}</div>
      </div>
      <br />
      <br />
      <br />

      <HeadDetail
        yacht_name={cruiseState.cruise.yacht_name}
        itinerary_name={cruiseState.cruise.itinerary_name}
        start_date={cruiseState.cruise.start_date_format}
        end_date={cruiseState.cruise.end_date_format}
      />
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Cabina {bookingState.booking.cabin_name}
          </Typography>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Pasajero</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Sencilla</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {bookingPassengerState.bookingPassengers.map((booking) => (
              <TableRow key={booking.id}>
                <TableCell>
                  {booking.last_name} {booking.first_name}
                </TableCell>
                <TableCell>{booking.cruise_type}</TableCell>
                <TableCell>{booking.single_bed === 1 ? "SI" : "NO"}</TableCell>
              </TableRow>
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>

      <br />
      <br />
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Cabinas disponibles
          </Typography>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Cabina</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Sencilla</TableCell>
              <TableCell>Pasajeros</TableCell>
              <TableCell align="center">Cambiar cabina</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {availableCabinState.cabins.map((cabin, index) => (
              <TableRow key={index}>
                <TableCell>{cabin.cabin_name}</TableCell>
                <TableCell>{cabin.cruise_type}</TableCell>
                <TableCell>
                  {cabin.single_bed === 1
                    ? "SI"
                    : cabin.single_bed === "No asignado"
                    ? "No asigando"
                    : "NO"}
                </TableCell>
                <TableCell>{cabin.passengers}</TableCell>
                <TableCell align="center">
                  <Modal
                    trigger={
                      <Button className="ui primary button " size="tiny">
                        Cambiar
                      </Button>
                    }
                    content={`¿Quieres cambiar con la cabina ${cabin.cabin_name}?`}
                    actions={[
                      "Cancelar",
                      {
                        key: "cambiar",
                        content: "Cambiar",
                        className: "ui primary button",
                        onClick: () => {
                          handleChangeCabin(
                            cabin.cabin_id,
                            cabin.booking_id,
                            cabin.cruise_type,
                            cabin.number_passengers,
                            cabin.single_bed
                          );
                          handleShowMessage({
                            vertical: "top",
                            horizontal: "right",
                          });
                        },
                      },
                    ]}
                    size="tiny"
                  />
                </TableCell>
              </TableRow>
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
