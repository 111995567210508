import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FamilyRoomService from "../../../../services/rates/FamilyRoomService";

export const REQUEST_FETCH_FAMILY_ROOM = "REQUEST_FETCH_FAMILY_ROOM";
export const FETCH_FAMILY_ROOM_SUCCESS = "FETCH_FAMILY_ROOM_SUCCESS";
export const FETCH_FAMILY_ROOM_ERROR = "FETCH_FAMILY_ROOM_ERROR";
export const INITIAL_STATE_FAMILY_ROOM = "INITIAL_STATE_FAMILY_ROOM";

function requestFetchFamilyRoom() {
  return {
    type: REQUEST_FETCH_FAMILY_ROOM,
  };
}

function fetchFamilyRoomSuccess(json) {
  return {
    type: FETCH_FAMILY_ROOM_SUCCESS,
    familyRoom: json.data,
  };
}

function fetchFamilyRoomError(json) {
  return {
    type: FETCH_FAMILY_ROOM_ERROR,
    error: json.data,
  };
}

export function initialStateFamilyRoom() {
  return {
    type: INITIAL_STATE_FAMILY_ROOM,
  };
}

export function fetchFamilyRoom(family_room_id) {
  return (dispatch) => {
    dispatch(requestFetchFamilyRoom());
    return FamilyRoomService.getFamilyRoom(family_room_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchFamilyRoomSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchFamilyRoom(family_room_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchFamilyRoomError(json.data));
      }
    });
  };
}
