import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "../../styles/Common";
import "../../styles/common.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchCruise } from "../../../redux/actions/reservations/cruise/fetchCruise";
import { fetchCruiseRates } from "../../../redux/actions/reservations/cruiseRate/fetchCruiseRates";
import { deleteCruiseRate } from "../../../redux/actions/reservations/cruiseRate/deleteCruiseRate";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { Link, useParams } from "react-router-dom";
import { Button, Modal, Icon } from "semantic-ui-react";

export default function CruiseRateList() {
  let { cruise_id } = useParams();
  const classes = useStyles();
  const cruiseState = useSelector((state) => state.cruiseReducer);
  const cruiseRateState = useSelector((state) => state.cruiseRateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCruise(cruise_id));
    dispatch(fetchCruiseRates(cruise_id));
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [dispatch, cruise_id]);

  if (cruiseRateState.fetching || cruiseState.fetching) {
    return <CircularProgress />;
  }

  if (cruiseRateState.processing) {
    return <CircularProgress />;
  }

  if (cruiseRateState.error) {
    return <div>Error: {cruiseRateState.error}</div>;
  }

  if (cruiseRateState.message === "resource deleted successfully") {
    dispatch(fetchCruiseRates(cruise_id));
  }

  const handledeleteCruiseRate = (id) => {
    dispatch(deleteCruiseRate(cruise_id, id));
  };

  return (
    <>
      <div className="ui breadcrumb">
        <Link to="/" className="section">
          TipTops
        </Link>
        <div className="divider">/</div>
        {cruiseRateState.cruiseRates.map((cruiseItem, index) => (
          <div key={index} className="active section">
            {cruiseItem.cruise_code
              ? cruiseItem.cruise_code
              : cruiseState.cruise.code}
          </div>
        ))}
      </div>
      <br></br>
      <br></br>
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Tarifas {cruiseRateState.message}
            <Link to={`/cruises/${cruise_id}/rates_new`}>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className={classes.margin}
              >
                <AddIcon />
              </Fab>
            </Link>
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cruiseRateState.cruiseRates.map((cruiseItem) =>
              cruiseItem.rates.map((rate) => (
                <TableRow key={rate.id}>
                  <TableCell>{rate.rate_name}</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <Link to={`/cruises/${cruise_id}/rates/${rate.id}`}>
                      <Button className="edit_button" icon>
                        <Icon className="icon_color" name="pencil alternate" />
                      </Button>
                    </Link>
                    <Modal
                      trigger={
                        <Button className="ui button delete_button" icon>
                          <Icon name="trash alternate" className="icon_color" />
                        </Button>
                      }
                      content={`¿Quieres eliminar esta tarifa?`}
                      actions={[
                        "Cancelar",
                        {
                          key: "eliminar",
                          content: "Eliminar",
                          className: "delete_button icon_color",
                          onClick: () => handledeleteCruiseRate(rate.id),
                        },
                      ]}
                      size="tiny"
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
