import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import VoucherService from "../../../../services/reservations/VoucherService";

export const REQUEST_FETCH_PASSENGERS = "REQUEST_FETCH_PASSENGERS";
export const FETCH_PASSENGERS_SUCCESS = "FETCH_PASSENGERS_SUCCESS";
export const FETCH_PASSENGERS_ERROR = "FETCH_PASSENGERS_ERROR";
export const INITIAL_STATE_PASSENGER = "INITIAL_STATE_PASSENGER";

function requestFetchPassengers() {
  return {
    type: REQUEST_FETCH_PASSENGERS,
  };
}

function fetchPassengersSuccess(json) {
  return {
    type: FETCH_PASSENGERS_SUCCESS,
    passengers: json.data,
  };
}

function fetchPassengersError(json) {
  return {
    type: FETCH_PASSENGERS_ERROR,
    error: json.data,
  };
}

export function initialStatePassenger() {
  return {
    type: INITIAL_STATE_PASSENGER,
  };
}

export function fetchPassengers(cruise_id, agency_id) {
  return (dispatch) => {
    dispatch(requestFetchPassengers());
    return VoucherService.getPassengersByAgency(cruise_id, agency_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchPassengersSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchPassengers(cruise_id, agency_id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchPassengersError(json.data));
        }
      }
    );
  };
}
