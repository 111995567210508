import React from "react";
import { useTranslation } from "react-i18next";
import { Table, Header } from "semantic-ui-react";

export default function CruiseTableSalesReport(props) {
  const { t } = useTranslation();
  return (
    <>
      <Table.Body>
        <Table.Row textAlign="center">
          <Table.Cell>
            <Header>
              <span className="header_style">
                {props.cruise_type}
              </span>
            </Header>
          </Table.Cell>
          <Table.Cell>
            {props.sales_report_120_days !== "" ? props.sales_report_120_days : ""}
          </Table.Cell>
          <Table.Cell>
            {props.sales_report_90_days !== "" ? props.sales_report_90_days : ""}
          </Table.Cell>
          <Table.Cell>
            {props.sales_report_60_days !== "" ? props.sales_report_60_days : ""}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </>
  );
}
