import PassengerBySpaceService from "../../../../services/reservations/PassengerBySpaceService";

export const REQUEST_UPDATE_PASSENGER_BY_SPACE =
  "REQUEST_UPDATE_PASSENGER_BY_SPACE";
export const UPDATE_PASSENGER_BY_SPACE_SUCCESS =
  "UPDATE_PASSENGER_BY_SPACE_SUCCESS";
export const UPDATE_PASSENGER_BY_SPACE_ERROR =
  "UPDATE_PASSENGER_BY_SPACE_ERROR";

function requestUpdatePassengerBySpace() {
  return {
    type: REQUEST_UPDATE_PASSENGER_BY_SPACE,
  };
}

function updatePassengerBySpaceSuccess(json) {
  return {
    type: UPDATE_PASSENGER_BY_SPACE_SUCCESS,
    message: json.data,
  };
}

function updatePassengerBySpaceError(json) {
  return {
    type: UPDATE_PASSENGER_BY_SPACE_ERROR,
    error: json.data,
  };
}

export function updatePassengerBySpace(booking_id, space_id, passenger_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdatePassengerBySpace());
    return PassengerBySpaceService.putPassengerBySpace(
      booking_id,
      space_id,
      passenger_id,
      payload
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(updatePassengerBySpaceSuccess(json.data));
      } else {
        dispatch(updatePassengerBySpaceError(json.data));
      }
    });
  };
}
