import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import YachtItineraryService from "../../../../services/catalogs/YachtItineraryService";

export const REQUEST_UPDATE_YACHT_ITINERARY = "REQUEST_UPDATE_YACHT_ITINERARY";
export const UPDATE_YACHT_ITINERARY_SUCCESS = "UPDATE_YACHT_ITINERARY_SUCCESS";
export const UPDATE_YACHT_ITINERARY_ERROR = "UPDATE_YACHT_ITINERARY_ERROR";

function requestUpdateYachtItinerary() {
  return {
    type: REQUEST_UPDATE_YACHT_ITINERARY,
  };
}

function updateYachtItinerarySuccess(json) {
  return {
    type: UPDATE_YACHT_ITINERARY_SUCCESS,
    message: json.data,
  };
}

function updateYachtItineraryError(json) {
  return {
    type: UPDATE_YACHT_ITINERARY_ERROR,
    error: json.data,
  };
}

export function updateYachtItinerary(itinerary_id, yacht_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateYachtItinerary());
    return YachtItineraryService.putYachtItinerary(
      itinerary_id,
      yacht_id,
      payload
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateYachtItinerarySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateYachtItinerary(itinerary_id, yacht_id, payload));
      } else if (json.data.code === 498) {
        logOut()
      } else {
        dispatch(updateYachtItineraryError(json.data));
      }
    });
  };
}
