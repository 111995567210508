import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customGuideLanguageForm = createSlice({
  name: 'customGuideLanguageForm',
  initialState: {
    guide: {},
    guideLanguage: {},
    languages: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateGuideLanguageFormCustom(state, action) {
      return {
        guide: {},
        guideLanguage: {},
        languages: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesGuideLanguageFormCustom(state, action) {
      return {
        guide: {},
        guideLanguage: {},
        languages: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchGuideLanguageFormCustomSuccess(state, action) {
      return {
        guide: action.payload.guide,
        guideLanguage: action.payload.guideLanguage,
        languages: action.payload.languages,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchGuideLanguageFormCustomError(state, action) {
      return {
        guide: {},
        guideLanguage: {},
        languages: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchGuideLanguageFormCustom = (guide_id, guideLanguage_id) => dispatch => {
  dispatch(requesGuideLanguageFormCustom());
  axios.get(`/guide/${guide_id}/form/guideLanguagesForm?guideLanguage_id=${guideLanguage_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchGuideLanguageFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGuideLanguageFormCustom(guide_id, guideLanguage_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGuideLanguageFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateGuideLanguageFormCustom,
  requesGuideLanguageFormCustom,
  fetchGuideLanguageFormCustomSuccess,
  fetchGuideLanguageFormCustomError,
} = customGuideLanguageForm.actions;

export default customGuideLanguageForm.reducer;