import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { fetchYachtItinerary, initialStateYachtItinerary } from "../../../redux/actions/catalogs/yachtItinerary/fetchYachtItinerary";
import { createYachtItinerary } from "../../../redux/actions/catalogs/yachtItinerary/createYachtItinerary";
import { updateYachtItinerary } from "../../../redux/actions/catalogs/yachtItinerary/updateYachItinerary";
import { fetchYachts } from "../../../redux/actions/catalogs/yacht/fetchYachts";
import { initialStateYacht } from "../../../redux/actions/catalogs/yacht/fetchYacht";
import { fetchItinerary, initialStateItinerary } from "../../../redux/actions/catalogs/itinerary/fetchItinerary";
import { fetchYachtItineraryFormCustom, initialStateYachtItineraryFormCustom } from "../../../redux/slices/custom/catalogs/yachtItinerary/customYachtItineraryForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function YachtItineraryForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { itinerary_id } = useParams();
  let { yacht_id } = useParams();
  const { register, handleSubmit, errors } = useForm();

  const yachtItineraryStateCustom = useSelector((state) => state.customYachtItineraryForm);
  //const yachtItineraryStateCustom = useSelector((state) => state.yachtsReducer);
  const itineraryState = useSelector((state) => state.itineraryReducer);
  const yachtItineraryState = useSelector((state) => state.yachtItineraryReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchYachtItineraryFormCustom(itinerary_id, yacht_id));
    scrollToTop();
    return () => {
      dispatch(initialStateYachtItineraryFormCustom());
      dispatch(initialStateYachtItinerary());
    };
  }, []);

  if (yachtItineraryStateCustom.fetching || yachtItineraryState.fetching) {
    return <Loader active inline />;
  }

  if (yachtItineraryStateCustom.error) {
    return <div>Error: {yachtItineraryStateCustom.error}</div>;
  }

  const onSubmit = (data) => {
    if (yacht_id) {
      dispatch(updateYachtItinerary(itinerary_id, yacht_id, data));
    } else {
      dispatch(createYachtItinerary(itinerary_id, data));
    }
  };

  if (yachtItineraryState.message === "resource created successfully" || yachtItineraryState.message === "resource updated successfully") {
    history.go(-1);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section ><Link to="/itineraries" className="section">{t("itinerary_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/itineraries/${itinerary_id}/yachts`} className="section">{yachtItineraryStateCustom.itinerary.name}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("itinerary_yacht_path")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("itinerary_yacht_name")} *</label>
            <select name="yacht_id" defaultValue={yachtItineraryStateCustom.yachtItinerary.yacht_id} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {yachtItineraryStateCustom.yachts.map((yacht) => (
                <option key={yacht.id} value={yacht.id} className="selected item">{yacht.name}</option>
              ))}
            </select>
            {errors.yacht_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={yachtItineraryStateCustom.processing ? true : false}>{t("save_button")}</Button>
        <Link to={`/itineraries/${itinerary_id}/yachts`}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
