import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SpaceFlightService from "../../../../services/reservations/SpaceFlightService";

export const REQUEST_UPDATE_SPACE_FLIGHT = "REQUEST_UPDATE_SPACE_FLIGHT";
export const UPDATE_SPACE_FLIGHT_SUCCESS = "UPDATE_SPACE_FLIGHT_SUCCESS";
export const UPDATE_SPACE_FLIGHT_ERROR = "UPDATE_SPACE_FLIGHT_ERROR";

function requestUpdateSpaceFlight() {
  return {
    type: REQUEST_UPDATE_SPACE_FLIGHT,
  };
}

function updateSpaceFlightSuccess(json) {
  return {
    type: UPDATE_SPACE_FLIGHT_SUCCESS,
    message: json.data,
  };
}

function updateSpaceFlightError(json) {
  return {
    type: UPDATE_SPACE_FLIGHT_ERROR,
    error: json.data,
  };
}

export function updateSpaceFlight(space_flight_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateSpaceFlight());
    return SpaceFlightService.putSpaceFlight(space_flight_id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(updateSpaceFlightSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(updateSpaceFlight(space_flight_id, payload));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(updateSpaceFlightError(json.data));
        }
      }
    );
  };
}
