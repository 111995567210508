import AvailablePassengerService from "../../../../services/reservations/AvailablePassengerService";

export const REQUEST_UPDATE_TWO_PASSENGERS = "REQUEST_UPDATE_TWO_PASSENGERS";
export const UPDATE_TWO_PASSENGERS_SUCCESS = "UPDATE_TWO_PASSENGERS_SUCCESS";
export const UPDATE_TWO_PASSENGERS_ERROR = "UPDATE_TWO_PASSENGERS_ERROR";
export const INITIAL_STATE_UPDATE_PASSENGER = "INITIAL_STATE_UPDATE_PASSENGER";

function requestUpdateTwoPassengers() {
  return {
    type: REQUEST_UPDATE_TWO_PASSENGERS,
  };
}

function updateTwoPassengersSuccess(json) {
  return {
    type: UPDATE_TWO_PASSENGERS_SUCCESS,
    message: json.data,
  };
}

function updateTwoPassengersError(json) {
  return {
    type: UPDATE_TWO_PASSENGERS_ERROR,
    error: json.data,
  };
}

export function initialStateUpdatePassenger() {
  return {
    type: INITIAL_STATE_UPDATE_PASSENGER,
  };
}

export function updateTwoPassengers(payload) {
  return (dispatch) => {
    dispatch(requestUpdateTwoPassengers());
    return AvailablePassengerService.updateTwoPassengers(payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(updateTwoPassengersSuccess(json.data));
        } else {
          dispatch(updateTwoPassengersError(json.data));
        }
      }
    );
  };
}
