import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Input, Header, Segment, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { fetchGuides } from "../../../redux/actions/catalogs/guide/fetchGuides";
import { deleteGuide } from "../../../redux/actions/catalogs/guide/deleteGuide";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function GuideList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const guideState = useSelector((state) => state.guideReducer);
  const { register, getValues } = useForm();
 
  useEffect(() => {
    dispatch(fetchGuides(""));
    scrollToTop();
  }, []);

  if (guideState.fetching || guideState.processing) {
    return <Loader active inline />;
  }

  if (guideState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (guideState.message === "resource deleted successfully") {
    dispatch(fetchGuides(""));
  }

  const handleDeleteGuide = (guide_id) => {
    dispatch(deleteGuide(guide_id));
  };

  const handleSearchGuide = () => {
    dispatch(fetchGuides(getValues("searchWord")));
  };

  return (
    <>
      <Segment clearing> 
        <HeaderCatalog title={t("guide_title")} message={guideState.message} to="/guides_new" />
        <Header as='h5' floated='right'>
          <Input type="text" placeholder={t("search") + "..."} action>
            <input name="searchWord" maxLength={10} ref={register()} />
            <Button type="submit" onClick={() => handleSearchGuide()} icon="search" size="medium" />
          </Input>
        </Header>
      </Segment>
      
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("guide_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("guide_languages")}</Table.HeaderCell>
            <Table.HeaderCell>{t("guide_phone")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {guideState.guides.map((guide) => (
          <Table.Row key={guide.id}>
            <Table.Cell collapsing><ActiveIcon active={guide.active} /></Table.Cell>
            <Table.Cell>{guide.first_name} {guide.last_name}</Table.Cell>
            <Table.Cell>
              {guide.language_names.length >= 1 ? (
                <Link to={`/guides/${guide.id}/languages`}>
                  {guide.language_names.map((language_name) => (
                    <div>{language_name}</div>
                  ))}
                </Link>
              ) : (
                <Link to={`/guides/${guide.id}/languages`} align="center">
                  <Button circular color='blue' icon='add' size="mini" />
                </Link>
              )}
            </Table.Cell>
            <Table.Cell>{guide.cellular_phone}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/guides/${guide.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("guide_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteGuide(guide.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
