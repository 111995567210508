import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SpaceService from "../../../../services/reservations/SpaceService";

export const REQUEST_UPDATE_SPACE = "REQUEST_UPDATE_SPACE";
export const UPDATE_SPACE_SUCCESS = "UPDATE_SPACE_SUCCESS";
export const UPDATE_SPACE_ERROR = "UPDATE_SPACE_ERROR";

function requestUpdateSpace() {
  return {
    type: REQUEST_UPDATE_SPACE,
  };
}

function updateSpaceSuccess(json) {
  return {
    type: UPDATE_SPACE_SUCCESS,
    message: json.data,
  };
}

function updateSpaceError(json) {
  return {
    type: UPDATE_SPACE_ERROR,
    error: json.data,
  };
}

export function updateSpace(space_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateSpace());
    return SpaceService.putSpace(space_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateSpaceSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateSpace(space_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateSpaceError(json.data));
      }
    });
  };
}
