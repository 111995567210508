import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IdentificationTypeService from "../../../../services/catalogs/IdentificationTypeService";

export const REQUEST_FETCH_IDENTIFICATION_TYPE =
  "REQUEST_FETCH_IDENTIFICATION_TYPE";
export const FETCH_IDENTIFICATION_TYPE_SUCCESS =
  "FETCH_IDENTIFICATION_TYPE_SUCCESS";
export const FETCH_IDENTIFICATION_TYPE_ERROR =
  "FETCH_IDENTIFICATION_TYPE_ERROR";
export const INITIAL_STATE_IDENTIFICATION_TYPE =
  "INITIAL_STATE_IDENTIFICATION_TYPE";

function requestFetchIdentificationType() {
  return {
    type: REQUEST_FETCH_IDENTIFICATION_TYPE,
  };
}

function fetchIdentificationTypeSuccess(json) {
  return {
    type: FETCH_IDENTIFICATION_TYPE_SUCCESS,
    identificationType: json.data,
  };
}

function fetchIdentificationTypeError(json) {
  return {
    type: FETCH_IDENTIFICATION_TYPE_ERROR,
    error: json.data,
  };
}

export function initialStateIdentificationType() {
  return {
    type: INITIAL_STATE_IDENTIFICATION_TYPE,
  };
}

export function fetchIdentificationType(identification_type_id) {
  return (dispatch) => {
    dispatch(requestFetchIdentificationType());
    return IdentificationTypeService.getIdentificationType(
      identification_type_id
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchIdentificationTypeSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchIdentificationType(identification_type_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchIdentificationTypeError(json.data));
      }
    });
  };
}
