import {
  REQUEST_FETCH_RESERVATIONS,
  FETCH_RESERVATIONS_SUCCESS,
  FETCH_RESERVATIONS_ERROR,
} from "../../../actions/reservations/reservation/fetchReservations";

import {
  INITIAL_STATE_RESERVATION,
  REQUEST_FETCH_RESERVATION,
  FETCH_RESERVATION_SUCCESS,
  FETCH_RESERVATION_ERROR,
} from "../../../actions/reservations/reservation/fetchReservation";

import {
  REQUEST_CREATE_RESERVATION,
  CREATE_RESERVATION_SUCCESS,
  CREATE_RESERVATION_ERROR,
} from "../../../actions/reservations/reservation/createReservation";

import {
  REQUEST_UPDATE_RESERVATION,
  UPDATE_RESERVATION_SUCCESS,
  UPDATE_RESERVATION_ERROR,
} from "../../../actions/reservations/reservation/updateReservation";

import {
  REQUEST_CANCELED_RESERVATION,
  CANCELED_RESERVATION_SUCCESS,
  CANCELED_RESERVATION_ERROR,
} from "../../../actions/reservations/reservation/canceledReservation";

const initialState = {
  reservations: [],
  reservation: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const reservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_RESERVATION: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_RESERVATIONS: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        reservations: action.reservations,
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_RESERVATIONS_ERROR: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_RESERVATION: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_RESERVATION_SUCCESS: {
      return {
        ...state,
        reservations: [],
        reservation: action.reservation,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_RESERVATION_ERROR: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_RESERVATION: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_RESERVATION_SUCCESS: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_RESERVATION_ERROR: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_RESERVATION: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_RESERVATION_SUCCESS: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_RESERVATION_ERROR: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CANCELED_RESERVATION: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CANCELED_RESERVATION_SUCCESS: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CANCELED_RESERVATION_ERROR: {
      return {
        ...state,
        reservations: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default reservationReducer;
