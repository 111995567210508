import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class YachtService {
  static getYachts() {
    return axios.get("yachts");
  }

  static getYacht(yacht_id) {
    return axios.get("yachts/" + yacht_id);
  }

  static postYacht(payload) {
    return axios.post("yachts", payload);
  }

  static putYacht(yacht_id, payload) {
    return axios.put("yachts/" + yacht_id, payload);
  }

  static deleteYacht(yacht_id) {
    return axios.delete("yachts/" + yacht_id);
  }

  static getItinerariesByYacht(yacht_id) {
    return axios.get("yachts/" + yacht_id + "/itineraries");
  }
}
