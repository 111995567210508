import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FamilyRoomService from "../../../../services/rates/FamilyRoomService";

export const REQUEST_FETCH_YACHTS = "REQUEST_FETCH_YACHTS";
export const FETCH_YACHTS_SUCCESS = "FETCH_YACHTS_SUCCESS";
export const FETCH_YACHTS_ERROR = "FETCH_YACHTS_ERROR";
export const INITIAL_STATE_YACHT = "INITIAL_STATE_YACHT";

function requestFetchYachts() {
  return {
    type: REQUEST_FETCH_YACHTS,
  };
}

function fetchYachtsSuccess(json) {
  return {
    type: FETCH_YACHTS_SUCCESS,
    yachts: json.data,
  };
}

function fetchYachtsError(json) {
  return {
    type: FETCH_YACHTS_ERROR,
    error: json.data,
  };
}

export function initialStateYacht() {
  return {
    type: INITIAL_STATE_YACHT,
  };
}

export function fetchYachts() {
  return (dispatch) => {
    dispatch(requestFetchYachts());
    return FamilyRoomService.getYachts().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchYachtsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchYachts());
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchYachtsError(json.data));
      }
    });
  };
}
