import SpaceFlightList from "../containers/reservations/cruise/spaceFlight/SpaceFlightList";
import SpaceFlightForm from "../containers/reservations/cruise/spaceFlight/SpaceFlightForm";

const routes = [
  {
    path:
      "/cruises/:cruise_id/reservations/:reservation_id/bookings/:booking_id/spaces/:space_id/spaceFlights",
    component: SpaceFlightList,
  },
  {
    path:
      "/cruises/:cruise_id/reservations/:reservation_id/bookings/:booking_id/spaces/:space_id/spaceFlights_new",
    component: SpaceFlightForm,
  },
  {
    path:
      "/cruises/:cruise_id/reservations/:reservation_id/bookings/:booking_id/spaces/:space_id/spaceFlights/:space_flight_id",
    component: SpaceFlightForm,
  },
];

export { routes as spaceFlightRoutes };
