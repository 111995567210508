import SearchPassengerList from "../containers/reservations/cruise/searchPassenger/SearchPassengerList";
import CruisePassengerList from "../containers/reservations/cruise/searchPassenger/CruisePassengerList";

const routes = [
  {
    path: "/passengers",
    component: SearchPassengerList,
  },
  {
    path: "/passengers/:passenger_id",
    component: CruisePassengerList,
  },
];

export { routes as searchPassengerRoutes };
