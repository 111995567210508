import { logOut, updateLocalStorageToken } from '../../../../containers/resources/utils';
import CruiseLanguageService from '../../../../services/reservations/CruiseLanguageService';

export const REQUEST_FETCH_CRUISE_LANGUAGE_LOGS = "REQUEST_FETCH_CRUISE_LANGUAGE_LOGS";
export const FETCH_CRUISE_LANGUAGE_LOGS_SUCCESS = "FETCH_CRUISE_LANGUAGE_LOGS_SUCCESS";
export const FETCH_CRUISE_LANGUAGE_LOGS_ERROR = "FETCH_CRUISE_LANGUAGE_LOGS_ERROR";

function requestFetchCruiseLanguageLogs() {
  return {
    type: REQUEST_FETCH_CRUISE_LANGUAGE_LOGS,
  };
}

function fetchCruiseLanguageLogsSuccess(json) {
  return {
    type: FETCH_CRUISE_LANGUAGE_LOGS_SUCCESS,
    logs: json.data,
  };
}

function fetchCruiseLanguageLogsError(json) {
  return {
    type: FETCH_CRUISE_LANGUAGE_LOGS_ERROR,
    error: json.data,
  };
}

export function fetchCruiseLanguageLogs(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchCruiseLanguageLogs());
    return CruiseLanguageService.getItemsByCruise(cruise_id).then((json) => {
      if (json.status === 200) {
        dispatch(fetchCruiseLanguageLogsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCruiseLanguageLogs(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCruiseLanguageLogsError(json.data));
      }
    });
  };
}
