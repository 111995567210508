import {
  REQUEST_FETCH_YACHT_ITINERARIES,
  FETCH_YACHT_ITINERARIES_SUCCESS,
  FETCH_YACHT_ITINERARIES_ERROR,
} from "../../../actions/catalogs/yachtItinerary/fetchYachtItineraries";

import {
  INITIAL_STATE_YACHT_ITINERARY,
  REQUEST_FETCH_YACHT_ITINERARY,
  FETCH_YACHT_ITINERARY_SUCCESS,
  FETCH_YACHT_ITINERARY_ERROR,
} from "../../../actions/catalogs/yachtItinerary/fetchYachtItinerary";

import {
  REQUEST_CREATE_YACHT_ITINERARY,
  CREATE_YACHT_ITINERARY_SUCCESS,
  CREATE_YACHT_ITINERARY_ERROR,
} from "../../../actions/catalogs/yachtItinerary/createYachtItinerary";

import {
  REQUEST_UPDATE_YACHT_ITINERARY,
  UPDATE_YACHT_ITINERARY_SUCCESS,
  UPDATE_YACHT_ITINERARY_ERROR,
} from "../../../actions/catalogs/yachtItinerary/updateYachItinerary";

import {
  REQUEST_DELETE_YACHT_ITINERARY,
  DELETE_YACHT_ITINERARY_SUCCESS,
  DELETE_YACHT_ITINERARY_ERROR,
} from "../../../actions/catalogs/yachtItinerary/deleteYachtItinerary";

const initialState = {
  yachtItineraries: [],
  yachtItinerary: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const yachtItineraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_YACHT_ITINERARY: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_YACHT_ITINERARIES: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_YACHT_ITINERARIES_SUCCESS: {
      return {
        ...state,
        yachtItineraries: action.yachtItineraries,
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_YACHT_ITINERARIES_ERROR: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_YACHT_ITINERARY: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_YACHT_ITINERARY_SUCCESS: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: action.yachtItinerary,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_YACHT_ITINERARY_ERROR: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_YACHT_ITINERARY: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_YACHT_ITINERARY_SUCCESS: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_YACHT_ITINERARY_ERROR: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_YACHT_ITINERARY: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_YACHT_ITINERARY_SUCCESS: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_YACHT_ITINERARY_ERROR: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_YACHT_ITINERARY: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_YACHT_ITINERARY_SUCCESS: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_YACHT_ITINERARY_ERROR: {
      return {
        ...state,
        yachtItineraries: [],
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default yachtItineraryReducer;
