import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import KindService from "../../../../services/catalogs/KindService";

export const REQUEST_FETCH_KIND = "REQUEST_FETCH_KIND";
export const FETCH_KIND_SUCCESS = "FETCH_KIND_SUCCESS";
export const FETCH_KIND_ERROR = "FETCH_KIND_ERROR";
export const INITIAL_STATE_KIND = "INITIAL_STATE_KIND";

function requestFetchKind() {
  return {
    type: REQUEST_FETCH_KIND,
  };
}

function fetchKindSuccess(json) {
  return {
    type: FETCH_KIND_SUCCESS,
    kind: json.data,
  };
}

function fetchKindError(json) {
  return {
    type: FETCH_KIND_ERROR,
    error: json.data,
  };
}

export function initialStateKind() {
  return {
    type: INITIAL_STATE_KIND,
  };
}

export function fetchKind(kind_id) {
  return (dispatch) => {
    dispatch(requestFetchKind());
    return KindService.getKind(kind_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchKindSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchKind(kind_id));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(fetchKindError(json.data));
      }
    });
  };
}
