import VoucherService from "../../../../services/reservations/VoucherService";

export const REQUEST_FETCH_VOUCHER = "REQUEST_FETCH_VOUCHER";
export const FETCH_VOUCHER_SUCCESS = "FETCH_VOUCHER_SUCCESS";
export const FETCH_VOUCHER_ERROR = "FETCH_VOUCHER_ERROR";
export const INITIAL_STATE_VOUCHER = "INITIAL_STATE_VOUCHER";

function requestFetchVoucher() {
  return {
    type: REQUEST_FETCH_VOUCHER,
  };
}

function fetchVoucherSuccess(json) {
  return {
    type: FETCH_VOUCHER_SUCCESS,
    voucher: json.data,
  };
}

function fetchVoucherError(json) {
  return {
    type: FETCH_VOUCHER_ERROR,
    error: json.data,
  };
}

export function initialStateVoucher() {
  return {
    type: INITIAL_STATE_VOUCHER,
  };
}

export function fetchVoucher(voucher_id) {
  return (dispatch) => {
    dispatch(requestFetchVoucher());
    return VoucherService.getVoucher(voucher_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchVoucherSuccess(json.data));
      } else {
        dispatch(fetchVoucherError(json.data));
      }
    });
  };
}
