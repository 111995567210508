import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//UI
import { Button, Modal, Icon, Loader, Breadcrumb, Divider, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { deleteGeneralRatesCabin, initialStateGeneralRateCabin } from "../../../redux/actions/rates/generalRatesCabin/deleteGeneralRatesCabin";
import { fetchGeneralRateCabinListCustom } from "../../../redux/slices/custom/rates/generalRateCabins/customGeneralRateCabinList.slice";
// Resources
import { scrollToTop } from "../../resources/utils";

export default function GeneralRateCabinList() {
  const { t } = useTranslation();
  let { generalRate_id } = useParams()
  const generalRateCabinStateCustom = useSelector((state) => state.customGeneralRateCabinList);
  const generalRateCabinState = useSelector((state) => state.generalRateCabinReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGeneralRateCabinListCustom(generalRate_id));
    scrollToTop();
  }, []);

  if (generalRateCabinStateCustom.fetching || generalRateCabinState.fetching) {
    return <Loader active inline />;
  }

  if (generalRateCabinStateCustom.error) {
    return <div>Error: {generalRateCabinStateCustom.error}</div>;
  }

  if (generalRateCabinState.message === "resource deleted successfully") {
    dispatch(fetchGeneralRateCabinListCustom(generalRate_id));
    dispatch(initialStateGeneralRateCabin());
  }

  const handledeleteCruiseLanguage = (generalRateCabin_id) => {
    dispatch(deleteGeneralRatesCabin(generalRateCabin_id));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/generalRates" className="section">{t("rates_tag")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{generalRateCabinStateCustom.generalRate.year} - {generalRateCabinStateCustom.generalRate.rate} - {generalRateCabinStateCustom.generalRate.yacht} - {generalRateCabinStateCustom.generalRate.days} días</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <HeaderCatalog title={t("cabin_title")} message={generalRateCabinStateCustom.message} to={`/generalRates/${generalRate_id}/cabins_new`} />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("name")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {generalRateCabinStateCustom.generalRateCabins.map((generalRateCabin) =>
              <Table.Row key={generalRateCabin.id}>
                <Table.Cell>{generalRateCabin.cabin_name}</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell collapsing>
                  <Modal
                    trigger={
                      <Button className="ui button delete_button" icon>
                        <Icon name="trash alternate" className="icon_color" />
                      </Button>
                    }
                    content={t("delete_button")}
                    actions={[
                      t("canceled_button"),
                      {
                        key: "eliminar",
                        content:t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handledeleteCruiseLanguage(generalRateCabin.id),
                      },
                    ]}
                    size="tiny"
                  />
                </Table.Cell>
              </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}
