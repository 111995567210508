import PassengerBySpaceService from "../../../../services/reservations/PassengerBySpaceService";

export const REQUEST_CREATE_PASSENGER_BY_SPACE =
  "REQUEST_CREATE_PASSENGER_BY_SPACE";
export const CREATE_PASSENGER_BY_SPACE_SUCCESS =
  "CREATE_PASSENGER_BY_SPACE_SUCCESS";
export const CREATE_PASSENGER_BY_SPACE_ERROR =
  "CREATE_PASSENGER_BY_SPACE_ERROR";

function requestCreatePassengerBySpace() {
  return {
    type: REQUEST_CREATE_PASSENGER_BY_SPACE,
  };
}

function createPassengerBySpaceSuccess(json) {
  return {
    type: CREATE_PASSENGER_BY_SPACE_SUCCESS,
    message: json.data,
  };
}

function createPassengerBySpaceError(json) {
  return {
    type: CREATE_PASSENGER_BY_SPACE_ERROR,
    error: json.data,
  };
}

export function createPassengerBySpace(booking_id, space_id, payload) {
  return (dispatch) => {
    dispatch(requestCreatePassengerBySpace());
    return PassengerBySpaceService.postPassengerBySpace(booking_id, space_id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(createPassengerBySpaceSuccess(json.data));
        } else {
          dispatch(createPassengerBySpaceError(json.data));
        }
      }
    );
  };
}
