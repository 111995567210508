import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { fetchCompanies } from "../../../redux/actions/catalogs/company/fetchCompanies";
import { deleteCompany } from "../../../redux/actions/catalogs/company/deleteCompany";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function CompanyList() {
  const { t } = useTranslation();
  const companyState = useSelector((state) => state.companyReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanies());
    scrollToTop();
  }, []);

  if (companyState.fetching || companyState.processing) {
    return <Loader active inline />;
  }

  if (companyState.error) {
    return <Message negative
      header='Error!'
      content={t("server_error_list")}
    />;
  }

  if (companyState.message === "resource deleted successfully") {
    dispatch(fetchCompanies());
  }

  const handleDeleteCompany = (company_id) => {
    dispatch(deleteCompany(company_id));
  };

  return (
    <>
      <HeaderCatalog title={t("company_title")} message={companyState.message} to="/companies_new" />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("company_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("company_logo")}</Table.HeaderCell>
            <Table.HeaderCell>{t("company_icon")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {companyState.companies.map((company) => (
          <Table.Row key={company.id}>
            <Table.Cell>{company.name}</Table.Cell>
            <Table.Cell>{company.main_logo}</Table.Cell>
            <Table.Cell>{company.dashboard_logo}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/companies/${company.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("company_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteCompany(company.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
