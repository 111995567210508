import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { fetchRoutes } from "../../../redux/actions/catalogs/route/fetchRoutes";
import { deleteRoute } from "../../../redux/actions/catalogs/route/deleteRoute";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function RouteList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const routeState = useSelector((state) => state.routeReducer);

  useEffect(() => {
    dispatch(fetchRoutes());
    scrollToTop();
  }, []);

  if (routeState.fetching || routeState.processing) {
    return <Loader active inline />;
  }

  if (routeState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (routeState.message === "resource deleted successfully") {
    dispatch(fetchRoutes());
  }

  const handleDeleteRoute = (route_id) => {
    dispatch(deleteRoute(route_id));
  };

  return (
    <>
      <HeaderCatalog title={t("route_title")} message={routeState.message} to="/routes_new" />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("flight_origin")}</Table.HeaderCell>
            <Table.HeaderCell>{t("flight_destination")}</Table.HeaderCell>
            <Table.HeaderCell>{t("type")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {routeState.routes.map((route) => (
          <Table.Row key={route.id}>
            <Table.Cell collapsing><ActiveIcon active={route.active} /></Table.Cell>
            <Table.Cell>{route.origin_city} ({route.origin_code})</Table.Cell>
            <Table.Cell>{route.destination_city} ({route.destination_code})</Table.Cell>
            <Table.Cell>{t(route.type)}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/routes/${route.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("route_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteRoute(route.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
