import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Input, Header, Segment, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { fetchHoteliers } from "../../../redux/actions/catalogs/hotelier/fetchHoteliers";
import { deleteHotelier } from "../../../redux/actions/catalogs/hotelier/deleteHotelier";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function HotelierList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hotelierState = useSelector((state) => state.hotelierReducer);
  const { register, getValues } = useForm();

  useEffect(() => {
    dispatch(fetchHoteliers(""));
    scrollToTop();
  }, []);

  if (hotelierState.fetching || hotelierState.processing) {
    return <Loader active inline />;
  }

  if (hotelierState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (hotelierState.message === "resource deleted successfully") {
    dispatch(fetchHoteliers(""));
  }

  const handleDeleteHotelier = (hotelier_id) => {
    dispatch(deleteHotelier(hotelier_id));
  };

  const handleSearchHotelier = () => {
    dispatch(fetchHoteliers(getValues("searchWord")));
  };

  return (
    <>
      <Segment clearing> 
        <HeaderCatalog title={t("hotelier_title")} message={hotelierState.message} to="/hoteliers_new" />
        <Header as='h5' floated='right'>
          <Input type="text" placeholder={t("search") + "..."} action>
            <input name="searchWord" maxLength={10} ref={register()} />
            <Button type="submit" onClick={() => handleSearchHotelier()} icon="search" size="medium" />
          </Input>
        </Header>
      </Segment>
    
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("hotelier_name")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {hotelierState.hoteliers.map((hotelier) => (
          <Table.Row key={hotelier.id}>
            <Table.Cell collapsing><ActiveIcon active={hotelier.active} /></Table.Cell>
            <Table.Cell>{hotelier.first_name} {hotelier.last_name}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/hoteliers/${hotelier.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("hotelier_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteHotelier(hotelier.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
