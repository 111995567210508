import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import HotelierService from "../../../../services/catalogs/HotelierService";

export const REQUEST_CREATE_HOTELIER = "REQUEST_CREATE_HOTELIER";
export const CREATE_HOTELIER_SUCCESS = "CREATE_HOTELIER_SUCCESS";
export const CREATE_HOTELIER_ERROR = "CREATE_HOTELIER_ERROR";

function requestCreateHotelier() {
  return {
    type: REQUEST_CREATE_HOTELIER,
  };
}

function createHotelierSuccess(json) {
  return {
    type: CREATE_HOTELIER_SUCCESS,
    message: json.data,
  };
}

function createHotelierError(json) {
  return {
    type: CREATE_HOTELIER_ERROR,
    error: json.data,
  };
}

export function createHotelier(payload) {
  return (dispatch) => {
    dispatch(requestCreateHotelier());
    return HotelierService.postHotelier(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createHotelierSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createHotelier(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createHotelierError(json.data));
      }
    });
  };
}
