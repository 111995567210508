import {
  REQUEST_FETCH_CAPTAINS,
  FETCH_CAPTAINS_SUCCESS,
  FETCH_CAPTAINS_ERROR,
} from "../../../actions/catalogs/captain/fetchCaptains";

import {
  INITIAL_STATE_CAPTAIN,
  REQUEST_FETCH_CAPTAIN,
  FETCH_CAPTAIN_SUCCESS,
  FETCH_CAPTAIN_ERROR,
} from "../../../actions/catalogs/captain/fetchCaptain";

import {
  REQUEST_CREATE_CAPTAIN,
  CREATE_CAPTAIN_SUCCESS,
  CREATE_CAPTAIN_ERROR,
} from "../../../actions/catalogs/captain/createCaptain";

import {
  REQUEST_UPDATE_CAPTAIN,
  UPDATE_CAPTAIN_SUCCESS,
  UPDATE_CAPTAIN_ERROR,
} from "../../../actions/catalogs/captain/updateCaptain";

import {
  REQUEST_DELETE_CAPTAIN,
  DELETE_CAPTAIN_SUCCESS,
  DELETE_CAPTAIN_ERROR,
} from "../../../actions/catalogs/captain/deleteCaptain";

const initialState = {
  captains: [],
  captain: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const captainReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_CAPTAIN: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_CAPTAINS: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CAPTAINS_SUCCESS: {
      return {
        ...state,
        captains: action.captains,
        captain: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CAPTAINS_ERROR: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_CAPTAIN: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CAPTAIN_SUCCESS: {
      return {
        ...state,
        captains: [],
        captain: action.captain,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CAPTAIN_ERROR: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_CAPTAIN: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_CAPTAIN_SUCCESS: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_CAPTAIN_ERROR: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_CAPTAIN: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_CAPTAIN_SUCCESS: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_CAPTAIN_ERROR: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_CAPTAIN: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_CAPTAIN_SUCCESS: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_CAPTAIN_ERROR: {
      return {
        ...state,
        captains: [],
        captain: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default captainReducer;
