import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ContractRateService from "../../../../services/rates/ContractRateService";

export const REQUEST_FIND_CONTRACT_RATE = "REQUEST_FIND_CONTRACT_RATE";
export const FIND_CONTRACT_RATE_SUCCESS = "FIND_CONTRACT_RATE_SUCCESS";
export const FIND_CONTRACT_RATE_ERROR = "FIND_CONTRACT_RATE_ERROR";
export const INITIAL_STATE_CONTRACT_RATE = "INITIAL_STATE_CONTRACT_RATE";

function requestFindContractRate() {
  return {
    type: REQUEST_FIND_CONTRACT_RATE,
  };
}

function findContractRateSuccess(json) {
  return {
    type: FIND_CONTRACT_RATE_SUCCESS,
    contract: json.data,
  };
}

function findContractRateError(json) {
  return {
    type: FIND_CONTRACT_RATE_ERROR,
    error: json.data,
  };
}

export function initialStateContractRate() {
  return {
    type: INITIAL_STATE_CONTRACT_RATE,
  };
}

export function findContractRate(reservation_id) {
  return (dispatch) => {
    dispatch(requestFindContractRate());
    return ContractRateService.findContract(reservation_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(findContractRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(findContractRate(reservation_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(findContractRateError(json.data));
      }
    });
  };
}
