import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IdentificationTypeService from "../../../../services/catalogs/IdentificationTypeService";

export const REQUEST_UPDATE_IDENTIFICATION_TYPE =
  "REQUEST_UPDATE_IDENTIFICATION_TYPE";
export const UPDATE_IDENTIFICATION_TYPE_SUCCESS =
  "UPDATE_IDENTIFICATION_TYPE_SUCCESS";
export const UPDATE_IDENTIFICATION_TYPE_ERROR =
  "UPDATE_IDENTIFICATION_TYPE_ERROR";

function requestUpdateIdentificationType() {
  return {
    type: REQUEST_UPDATE_IDENTIFICATION_TYPE,
  };
}

function updateIdentificationTypeSuccess(json) {
  return {
    type: UPDATE_IDENTIFICATION_TYPE_SUCCESS,
    message: json.data,
  };
}

function updateIdentificationTypeError(json) {
  return {
    type: UPDATE_IDENTIFICATION_TYPE_ERROR,
    error: json.data,
  };
}

export function updateIdentificationType(identification_type_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateIdentificationType());
    return IdentificationTypeService.putIdentificationType(
      identification_type_id,
      payload
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateIdentificationTypeSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateIdentificationType(identification_type_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateIdentificationTypeError(json.data));
      }
    });
  };
}
