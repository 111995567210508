import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class DayService {
  static getDays(itinerary_id) {
    return axios.get("itinerary/" + itinerary_id + "/days");
  }
  static getDay(day_id) {
    return axios.get("days/" + day_id);
  }

  static postDay(itinerary_id, payload) {
    return axios.post(
      "itinerary/" + itinerary_id + "/days",
      payload
    );
  }

  static putDay(day_id, payload) {
    return axios.put("days/" + day_id, payload);
  }

  static deleteDay(day_id) {
    return axios.delete("days/" + day_id);
  }

  static getDaysByItinerary(yachtName, itinerary_id) {
    return axios.get(
      API_BASE_URL +
        "days/itinerary/" +
        itinerary_id +
        `?yacht_name=${yachtName}`
    );
  }
}
