import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class SpaceService {
  static getSpaces(booking_id) {
    return axios.get("booking/" + booking_id + "/spaces");
  }
  
  static getSpace(space_id) {
    return axios.get("spaces/" + space_id);
  }

  static postSpace(reservation_id, booking_id, payload) {
    return axios.post("reservation/" + reservation_id + "/booking/" + booking_id + "/spaces", payload);
  }

  static putSpace(space_id, payload) {
    return axios.put("spaces/" + space_id, payload);
  }

  static putSpaceCanceled(space_id, payload) {
    return axios.put("space/" + space_id + "/canceled", payload);
  }

  static updateDiscountPrice(space_id, payload) {
    return axios.put("spaces/" + space_id + "/updateDiscountPrice", payload);
  }

  static getSpacesCanceled(booking_id) {
    return axios.get("booking/" + booking_id + "/spaces/canceled");
  }

  static getItemsByField(booking_id) {
    return axios.get("booking/spaces/logs/" + booking_id);
  }
}
