import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GenderService from "../../../../services/catalogs/GenderService";

export const REQUEST_DELETE_GENDER = "REQUEST_DELETE_GENDER";
export const DELETE_GENDER_SUCCESS = "DELETE_GENDER_SUCCESS";
export const DELETE_GENDER_ERROR = "DELETE_GENDER_ERROR";

function requestDeleteGender() {
  return {
    type: REQUEST_DELETE_GENDER,
  };
}

function deleteGenderSuccess(json) {
  return {
    type: DELETE_GENDER_SUCCESS,
    message: json.data,
  };
}

function deleteGenderError(json) {
  return {
    type: DELETE_GENDER_ERROR,
    error: json.data,
  };
}

export function deleteGender(gender_id) {
  return (dispatch) => {
    dispatch(requestDeleteGender());
    return GenderService.deleteGender(gender_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteGenderSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteGender(gender_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteGenderError(json.data));
      }
    });
  };
}
