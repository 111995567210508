import {
  REQUEST_FETCH_FAMILY_ROOMS,
  FETCH_FAMILY_ROOMS_SUCCESS,
  FETCH_FAMILY_ROOMS_ERROR,
} from "../../../actions/rates/familyRoom/fetchFamilyRooms";

import {
  REQUEST_FETCH_YEARS,
  FETCH_YEARS_SUCCESS,
  FETCH_YEARS_ERROR,
} from "../../../actions/rates/familyRoom/fetchYears";

import {
  INITIAL_STATE_FAMILY_ROOM,
  REQUEST_FETCH_FAMILY_ROOM,
  FETCH_FAMILY_ROOM_SUCCESS,
  FETCH_FAMILY_ROOM_ERROR,
} from "../../../actions/rates/familyRoom/fetchFamilyRoom";

import {
  REQUEST_CREATE_FAMILY_ROOM,
  CREATE_FAMILY_ROOM_SUCCESS,
  CREATE_FAMILY_ROOM_ERROR,
} from "../../../actions/rates/familyRoom/createFamilyRoom";

import {
  REQUEST_UPDATE_FAMILY_ROOM,
  UPDATE_FAMILY_ROOM_SUCCESS,
  UPDATE_FAMILY_ROOM_ERROR,
} from "../../../actions/rates/familyRoom/updateFamilyRoom";

import {
  REQUEST_ACTIVE_FAMILY_ROOM_RATE,
  ACTIVE_FAMILY_ROOM_RATE_SUCCESS,
  ACTIVE_FAMILY_ROOM_RATE_ERROR,
} from "../../../actions/rates/familyRoom/activeFamilyRoom";

import {
  REQUEST_INACTIVE_FAMILY_ROOM_RATE,
  INACTIVE_FAMILY_ROOM_RATE_SUCCESS,
  INACTIVE_FAMILY_ROOM_RATE_ERROR,
} from "../../../actions/rates/familyRoom/inactiveFamilyRoom";

import {
  REQUEST_DELETE_FAMILY_ROOM,
  DELETE_FAMILY_ROOM_SUCCESS,
  DELETE_FAMILY_ROOM_ERROR,
} from "../../../actions/rates/familyRoom/deleteFamilyRoom";

const initialState = {
  familyRooms: [],
  years: [],
  familyRoom: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const familyRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_FAMILY_ROOM: {
      return {
        ...state,
        familyRooms: [],
        years: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_FAMILY_ROOMS: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_FAMILY_ROOMS_SUCCESS: {
      return {
        ...state,
        familyRooms: action.familyRooms,
        familyRoom: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_FAMILY_ROOMS_ERROR: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_FAMILY_ROOM: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_FAMILY_ROOM_SUCCESS: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: action.familyRoom,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_FAMILY_ROOM_ERROR: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_FAMILY_ROOM: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_FAMILY_ROOM_SUCCESS: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_FAMILY_ROOM_ERROR: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_FAMILY_ROOM: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_FAMILY_ROOM_SUCCESS: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_FAMILY_ROOM_ERROR: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_ACTIVE_FAMILY_ROOM_RATE: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case ACTIVE_FAMILY_ROOM_RATE_SUCCESS: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case ACTIVE_FAMILY_ROOM_RATE_ERROR: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_INACTIVE_FAMILY_ROOM_RATE: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case INACTIVE_FAMILY_ROOM_RATE_SUCCESS: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case INACTIVE_FAMILY_ROOM_RATE_ERROR: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_FAMILY_ROOM: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_FAMILY_ROOM_SUCCESS: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_FAMILY_ROOM_ERROR: {
      return {
        ...state,
        familyRooms: [],
        familyRoom: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_YEARS: {
      return {
        ...state,
        years: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_SUCCESS: {
      return {
        ...state,
        years: action.years,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_ERROR: {
      return {
        ...state,
        years: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default familyRoomReducer;
