import AdvanceCruiseService from "../../../../services/reservations/AdvanceCruiseService";

export const REQUEST_FETCH_ADVANCE_CRUISES = "REQUEST_FETCH_ADVANCE_CRUISES";
export const FETCH_ADVANCE_CRUISES_SUCCESS = "FETCH_ADVANCE_CRUISES_SUCCESS";
export const FETCH_ADVANCE_CRUISES_ERROR = "FETCH_ADVANCE_CRUISES_ERROR";
export const INITIAL_STATE_ADVANCE_CRUISE = "INITIAL_STATE_ADVANCE_CRUISE";

function requestFetchAdvanceCruises() {
  return {
    type: REQUEST_FETCH_ADVANCE_CRUISES,
  };
}

function fetchAdvanceCruisesSuccess(json) {
  return {
    type: FETCH_ADVANCE_CRUISES_SUCCESS,
    cruises: json.data,
  };
}

function fetchAdvanceCruisesError(json) {
  return {
    type: FETCH_ADVANCE_CRUISES_ERROR,
    error: json.data,
  };
}

export function initialStateAdvanceCruise() {
  return {
    type: INITIAL_STATE_ADVANCE_CRUISE,
  };
}

export function fetchAdvanceCruises(yachtId, startDate, endDate) {
  return (dispatch) => {
    dispatch(requestFetchAdvanceCruises());
    return AdvanceCruiseService.getAdvanceCruises(
      yachtId,
      startDate,
      endDate
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchAdvanceCruisesSuccess(json.data));
      } else {
        dispatch(fetchAdvanceCruisesError(json.data));
      }
    });
  };
}
