import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createRate } from "../../../redux/actions/catalogs/rate/createRate";
import { fetchRate, initialStateRate } from "../../../redux/actions/catalogs/rate/fetchRate";
import { updateRate } from "../../../redux/actions/catalogs/rate/updateRate";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function RateForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { rate_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const rateState = useSelector((state) => state.rateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (rate_id) {
      dispatch(fetchRate(rate_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateRate());
    };
  }, []);

  if (rateState.fetching) {
    return <Loader active inline />;
  }

  if (rateState.error) {
    return <div>Error: {rateState.error}</div>;
  }

  const onSubmit = (data) => {
    if (rate_id) {
      dispatch(updateRate(rate_id, data));
    } else {
      dispatch(createRate(data));
    }
  };

  if (rateState.message === "resource created successfully" || rateState.message === "resource updated successfully") {
    history.push("/rates");
  }

  return (
    <>
      <Header as='h3' dividing>{t("rate_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("rate_name")}</label>
            <input name="name" defaultValue={rateState.rate.name} placeholder={t("rate_name")} ref={register({ required: true })} autoFocus />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("commission_percentage")}</label>
            <input name="commission_percentage" defaultValue={rateState.rate.commission_percentage} placeholder={t("commission_percentage")} ref={register}/>
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("comment")}</label>
            <textarea name="comment" defaultValue={rateState.rate.comment} placeholder={t("comment")} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("active_tag")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" defaultChecked={rateState.rate.active === 1} ref={register} />
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>
        
        <Divider variant="middle" />

        <Button primary type="submit" loading={rateState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/rates"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
