import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customIngalaList = createSlice({
  name: 'customIngalaList',
  initialState: {
    cruise: {},
    passengers: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateIngalaListCustom(state, action) {
      return {
        cruise: {},
        passengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesIngalaListCustom(state, action) {
      return {
        cruise: {},
        passengers: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchIngalaListCustomSuccess(state, action) {
      console.log(action.payload)
      return {
        cruise: action.payload.cruise,
        passengers: action.payload.passengers,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchIngalaListCustomError(state, action) {
      return {
        cruise: {},
        passengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchIngalaListCustom = (cruise_id) => dispatch => {
  dispatch(requesIngalaListCustom());
  axios.get(`/cruise/${cruise_id}/passengersIngalaList`)
    .then(json => {
      if (json.data.code === 200) {
        console.log(json.data)
        dispatch(fetchIngalaListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchIngalaListCustom(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchIngalaListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateIngalaListCustom,
  requesIngalaListCustom,
  fetchIngalaListCustomSuccess,
  fetchIngalaListCustomError,
} = customIngalaList.actions;

export default customIngalaList.reducer;