import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import UserService from "../../../../services/catalogs/UserService";

export const REQUEST_UPDATE_USER = "REQUEST_UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

function requestUpdateUser() {
  return {
    type: REQUEST_UPDATE_USER,
  };
}

function updateUserSuccess(json) {
  return {
    type: UPDATE_USER_SUCCESS,
    message: json.data,
  };
}

function updateUserError(json) {
  return {
    type: UPDATE_USER_ERROR,
    error: json.data,
  };
}

export function updateAgencyUser(user_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateUser());
    return UserService.updateAgencyUser(user_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateUserSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateAgencyUser(user_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateUserError(json.data));
      }
    });
  };
}

export function updateCompanyUser(user_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateUser());
    return UserService.updateCompanyUser(user_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateUserSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateCompanyUser(user_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateUserError(json.data));
      }
    });
  };
}
