import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customFamilyRoomList = createSlice({
  name: 'customFamilyRoomList',
  initialState: {
    years: [],
    rates: [],
    yachts: [],
    days: [],
    familyRooms: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateFamilyRoomListCustom(state, action) {
      return {
        years: [],
        rates: [],
        yachts: [],
        days: [],
        familyRooms: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesFamilyRoomListCustom(state, action) {
      return {
        years: [],
        rates: [],
        yachts: [],
        days: [],
        familyRooms: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchFamilyRoomListCustomSuccess(state, action) {
      return {
        years: action.payload.years,
        rates: action.payload.rates,
        yachts: action.payload.yachts,
        days: action.payload.days,
        familyRooms: action.payload.familyRooms,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchFamilyRoomListCustomError(state, action) {
      return {
        years: [],
        rates: [],
        yachts: [],
        days: [],
        familyRooms: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchFamilyRoomListCustom = (payload) => dispatch => {
  dispatch(requesFamilyRoomListCustom());
  axios.post(`/familyRooms/familyRoomList`, payload)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchFamilyRoomListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchFamilyRoomListCustom(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchFamilyRoomListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateFamilyRoomListCustom,
  requesFamilyRoomListCustom,
  fetchFamilyRoomListCustomSuccess,
  fetchFamilyRoomListCustomError,
} = customFamilyRoomList.actions;

export default customFamilyRoomList.reducer;