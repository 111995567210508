import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customBookingForm = createSlice({
  name: 'customBookingForm',
  initialState: {
    cruise: {},
    reservation: {},
    cabins: [],
    booking: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateBookingFormCustom(state, action) {
      return {
        cruise: {},
        reservation: {},
        cabins: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesBookingFormCustom(state, action) {
      return {
        cruise: {},
        reservation: {},
        cabins: [],
        booking: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchBookingFormCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        reservation: action.payload.reservation,
        cabins: action.payload.cabinsAvailable,
        booking: action.payload.booking,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchBookingFormCustomError(state, action) {
      return {
        cruise: {},
        reservation: {},
        cabins: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchBookingFormCustom = (reservation_id, cruise_id, booking_id) => dispatch => {
  dispatch(requesBookingFormCustom());
  var url = `reservation/${reservation_id}/bookingForm`;
  url = url + `?cruise_id=${cruise_id}&&booking_id=${booking_id}`;
  axios.get(`/${url}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchBookingFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchBookingFormCustomSuccess(json.data.data));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchBookingFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateBookingFormCustom,
  requesBookingFormCustom,
  fetchBookingFormCustomSuccess,
  fetchBookingFormCustomError,
} = customBookingForm.actions;

export default customBookingForm.reducer;