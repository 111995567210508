import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideOnBoardService from "../../../../services/reservations/GuideOnBoardService";

export const REQUEST_FETCH_GUIDE_ON_BOARD = "REQUEST_FETCH_GUIDE_ON_BOARD";
export const FETCH_GUIDE_ON_BOARD_SUCCESS = "FETCH_GUIDE_ON_BOARD_SUCCESS";
export const FETCH_GUIDE_ON_BOARD_ERROR = "FETCH_GUIDE_ON_BOARD_ERROR";
export const INITIAL_STATE_GUIDE_ON_BOARD = "INITIAL_STATE_GUIDE_ON_BOARD";

function requestFetchGuideOnBoard() {
  return {
    type: REQUEST_FETCH_GUIDE_ON_BOARD,
  };
}

function fetchGuideOnBoardSuccess(json) {
  return {
    type: FETCH_GUIDE_ON_BOARD_SUCCESS,
    guideOnBoard: json.data,
  };
}

function fetchGuideOnBoardError(json) {
  return {
    type: FETCH_GUIDE_ON_BOARD_ERROR,
    error: json.data,
  };
}

export function initialStateGuideOnBoard() {
  return {
    type: INITIAL_STATE_GUIDE_ON_BOARD,
  };
}

export function fetchGuideOnBoard(cruise_id, id) {
  return (dispatch) => {
    dispatch(requestFetchGuideOnBoard());
    return GuideOnBoardService.getGuideOnBoard(cruise_id, id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchGuideOnBoardSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGuideOnBoard(cruise_id, id));
      } else if (json.data.code === 498) {
        logOut()
      } else {
        dispatch(fetchGuideOnBoardError(json.data));
      }
    });
  };
}
