import AvailabilityService from "../../../../services/b2b/AvailabilityService";

export const REQUEST_FETCH_HISTORICAL_RESERVATIONS =
  "REQUEST_FETCH_HISTORICAL_RESERVATIONS";
export const FETCH_HISTORICAL_RESERVATIONS_SUCCESS =
  "FETCH_HISTORICAL_RESERVATIONS_SUCCESS";
export const FETCH_HISTORICAL_RESERVATIONS_ERROR =
  "FETCH_HISTORICAL_RESERVATIONS_ERROR";

function requestFetchHistoricalReservations() {
  return {
    type: REQUEST_FETCH_HISTORICAL_RESERVATIONS,
  };
}

function fetchHistoricalReservationsSuccess(json) {
  return {
    type: FETCH_HISTORICAL_RESERVATIONS_SUCCESS,
    historicalReservations: json.data,
  };
}

function fetchHistoricalReservationsError(json) {
  return {
    type: FETCH_HISTORICAL_RESERVATIONS_ERROR,
    error: json.data,
  };
}

export function fetchHistoricalReservationsByAgency(agencyId) {
  return (dispatch) => {
    dispatch(requestFetchHistoricalReservations());
    return AvailabilityService.getHistoricalReservationByAgency(agencyId).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchHistoricalReservationsSuccess(json.data));
        } else {
          dispatch(fetchHistoricalReservationsError(json.data));
        }
      }
    );
  };
}
