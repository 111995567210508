import {
  REQUEST_FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_ERROR,
} from "../../../actions/catalogs/role/fetchRoles";

const initialState = {
  roles: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCH_ROLES: {
      return {
        ...state,
        roles: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.roles,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_ROLES_ERROR: {
      return {
        ...state,
        roles: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default roleReducer;
