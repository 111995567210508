import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import AirlineService from "../../../../services/catalogs/AirlineService";

export const REQUEST_FETCH_AIRLINES = "REQUEST_FETCH_AIRLINES";
export const FETCH_AIRLINES_SUCCESS = "FETCH_AIRLINES_SUCCESS";
export const FETCH_AIRLINES_ERROR = "FETCH_AIRLINES_ERROR";

function requestFetchAirlines() {
  return {
    type: REQUEST_FETCH_AIRLINES,
  };
}

function fetchAirlinesSuccess(json) {
  return {
    type: FETCH_AIRLINES_SUCCESS,
    airlines: json.data,
  };
}

function fetchAirlinesError(json) {
  return {
    type: FETCH_AIRLINES_ERROR,
    error: json.data,
  };
}

export function fetchAirlines() {
  return (dispatch) => {
    dispatch(requestFetchAirlines());
    return AirlineService.getAirlines().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchAirlinesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchAirlines());
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchAirlinesError(json.data));
      }
    });
  };
}
