import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CruiseService from "../../../../services/reservations/CruiseService";

export const REQUEST_UPDATE_CRUISE = "REQUEST_UPDATE_CRUISE";
export const UPDATE_CRUISE_SUCCESS = "UPDATE_CRUISE_SUCCESS";
export const UPDATE_CRUISE_ERROR = "UPDATE_CRUISE_ERROR";

function requestUpdateCruise() {
  return {
    type: REQUEST_UPDATE_CRUISE,
  };
}

function updateCruiseSuccess(json) {
  return {
    type: UPDATE_CRUISE_SUCCESS,
    message: json.data,
  };
}

function updateCruiseError(json) {
  return {
    type: UPDATE_CRUISE_ERROR,
    error: json.data,
  };
}

export function updateCruise(cruise_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateCruise());
    return CruiseService.putCruise(cruise_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateCruiseSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateCruise(cruise_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateCruiseError(json.data));
      }
    });
  };
}
