import {
    INITIAL_STATE_YACHT,
    REQUEST_FETCH_YACHT,
    FETCH_YACHT_SUCCESS,
    FETCH_YACHT_ERROR,
  } from "../../../actions/catalogs/yacht/fetchYacht";

  const initialState = {
    yachts: [],
    yacht: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  };

  const yachtReducer = (state = initialState, action) => {
    switch (action.type) {
      case INITIAL_STATE_YACHT: {
        return {
          ...state,
          yachts: [],
          yacht: {},
          processing: false,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case REQUEST_FETCH_YACHT: {
        return {
          ...state,
          yachts: [],
          yacht: {},
          processing: false,
          fetching: true,
          message: "",
          error: "",
        };
      }
      case FETCH_YACHT_SUCCESS: {
        return {
          ...state,
          yachts: [],
          yacht: action.yacht,
          processing: false,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case FETCH_YACHT_ERROR: {
        return {
          ...state,
          yachts: [],
          yacht: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default yachtReducer;
  