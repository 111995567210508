import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import BookingService from "../../../../services/reservations/BookingService";

export const REQUEST_DELETE_BOOKING = "REQUEST_DELETE_BOOKING";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_ERROR = "DELETE_BOOKING_ERROR";

function requestDeleteBooking() {
  return {
    type: REQUEST_DELETE_BOOKING,
  };
}

function deleteBookingSuccess(json) {
  return {
    type: DELETE_BOOKING_SUCCESS,
    message: json.data,
  };
}

function deleteBookingError(json) {
  return {
    type: DELETE_BOOKING_ERROR,
    error: json.data,
  };
}

export function deleteBooking(booking_id) {
  return (dispatch) => {
    dispatch(requestDeleteBooking());
    return BookingService.deleteBooking(booking_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteBookingSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteBooking(booking_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteBookingError(json.data));
      }
    });
  };
}
