import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class ItineraryService {
  static getImage(itinerary_id) {
    return axios.get("itineraries/upload/" + itinerary_id);
  }

  static getItineraries(searchWord) {
    var url = "itineraries";
    url = url + `?searchWord=${searchWord}`;
    return axios.get(url);
  }

  static getItinerary(itinerary_id) {
    return axios.get("itineraries/" + itinerary_id);
  }

  static postItinerary(payload) {
    return axios.post("itineraries", payload);
  }

  static putItinerary(itinerary_id, payload) {
    return axios.put("itineraries/" + itinerary_id, payload);
  }

  static deleteItinerary(itinerary_id) {
    return axios.delete("itineraries/" + itinerary_id);
  }

  static checkItineraryDivision(itinerary_id) {
    return axios.get(
      "itineraries/" + itinerary_id + "/division"
    );
  }

  //UPLOAD IMAGE

  static updateImage(itinerary_id, payload) {
    return axios.put(
      "itineraries/upload/" + itinerary_id,
      payload
    );
  }

  static deleteImage(itinerary_id) {
    return axios.delete("itineraries/upload/" + itinerary_id);
  }

  static getDays() {
    return axios.get("itineraries/days");
  }
}
