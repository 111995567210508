import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideOnBoardService from "../../../../services/reservations/GuideOnBoardService";

export const REQUEST_FETCH_GUIDES_ON_BOARD = "REQUEST_FETCH_GUIDES_ON_BOARD";
export const FETCH_GUIDES_ON_BOARD_SUCCESS = "FETCH_GUIDES_ON_BOARD_SUCCESS";
export const FETCH_GUIDES_ON_BOARD_ERROR = "FETCH_GUIDES_ON_BOARD_ERROR";

function requestFetchGuidesOnBoard() {
  return {
    type: REQUEST_FETCH_GUIDES_ON_BOARD,
  };
}

function fetchGuidesOnBoardSuccess(json) {
  return {
    type: FETCH_GUIDES_ON_BOARD_SUCCESS,
    guidesOnBoard: json.data,
  };
}

function fetchGuidesOnBoardError(json) {
  return {
    type: FETCH_GUIDES_ON_BOARD_ERROR,
    error: json.data,
  };
}

export function fetchGuidesOnBoard(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchGuidesOnBoard());
    return GuideOnBoardService.getGuidesOnBoard(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchGuidesOnBoardSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGuidesOnBoard(cruise_id));
      } else if (json.data.code === 498) {
        logOut()
      } else {
        dispatch(fetchGuidesOnBoardError(json.data));
      }
    });
  };
}
