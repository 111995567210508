import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideService from "../../../../services/catalogs/GuideService";

export const REQUEST_DELETE_GUIDE = "REQUEST_DELETE_GUIDE";
export const DELETE_GUIDE_SUCCESS = "DELETE_GUIDE_SUCCESS";
export const DELETE_GUIDE_ERROR = "DELETE_GUIDE_ERROR";

function requestDeleteGuide() {
  return {
    type: REQUEST_DELETE_GUIDE,
  };
}

function deleteGuideSuccess(json) {
  return {
    type: DELETE_GUIDE_SUCCESS,
    message: json.data,
  };
}

function deleteGuideError(json) {
  return {
    type: DELETE_GUIDE_ERROR,
    error: json.data,
  };
}

export function deleteGuide(id) {
  return (dispatch) => {
    dispatch(requestDeleteGuide());
    return GuideService.deleteGuide(id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteGuideSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteGuide(id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteGuideError(json.data));
      }
    });
  };
}
