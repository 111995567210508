import AvailabilityService from "../../../../services/b2b/AvailabilityService";

export const REQUEST_FETCH_ACTUAL_RESERVATIONS =
  "REQUEST_FETCH_ACTUAL_RESERVATIONS";
export const FETCH_ACTUAL_RESERVATIONS_SUCCESS =
  "FETCH_ACTUAL_RESERVATIONS_SUCCESS";
export const FETCH_ACTUAL_RESERVATIONS_ERROR =
  "FETCH_ACTUAL_RESERVATIONS_ERROR";
export const INITIAL_STATE_RESERVATIONS = "INITIAL_STATE_RESERVATIONS";

function requestFetchActualReservations() {
  return {
    type: REQUEST_FETCH_ACTUAL_RESERVATIONS,
  };
}

function fetchActualReservationsSuccess(json) {
  return {
    type: FETCH_ACTUAL_RESERVATIONS_SUCCESS,
    actualReservations: json.data,
  };
}

function fetchActualReservationsError(json) {
  return {
    type: FETCH_ACTUAL_RESERVATIONS_ERROR,
    error: json.data,
  };
}

export function initialStateReservations() {
  return {
    type: INITIAL_STATE_RESERVATIONS,
  };
}

export function fetchActualReservationsByAgency(agencyId) {
  return (dispatch) => {
    dispatch(requestFetchActualReservations());
    return AvailabilityService.getActualReservationByAgency(agencyId).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchActualReservationsSuccess(json.data));
        } else {
          dispatch(fetchActualReservationsError(json.data));
        }
      }
    );
  };
}
