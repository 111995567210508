import AvailabilityService from "../../../../services/b2b/AvailabilityService";

export const REQUEST_FETCH_CRUISES = "REQUEST_FETCH_CRUISES";
export const FETCH_CRUISES_SUCCESS = "FETCH_CRUISES_SUCCESS";
export const FETCH_CRUISES_ERROR = "FETCH_CRUISES_ERROR";
export const INITIAL_STATE_CRUISES = "INITIAL_STATE_CRUISES";

function requestFetchCruises() {
  return {
    type: REQUEST_FETCH_CRUISES,
  };
}

function fetchCruisesSuccess(json) {
  return {
    type: FETCH_CRUISES_SUCCESS,
    cruises: json.data,
  };
}

function fetchCruisesError(json) {
  return {
    type: FETCH_CRUISES_ERROR,
    error: json.data,
  };
}

export function initialStateCruises() {
  return {
    type: INITIAL_STATE_CRUISES,
  };
}

export function fetchCruises(month, year, yacht, itinerary) {
  return (dispatch) => {
    dispatch(requestFetchCruises());
    return AvailabilityService.getAvailabilityCruise(
      month,
      year,
      yacht,
      itinerary
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCruisesSuccess(json.data));
      } else {
        dispatch(fetchCruisesError(json.data));
      }
    });
  };
}
