import CharterRateService from "../../../../services/rates/CharterRateService";

export const REQUEST_ACTIVE_CHARTER_RATE = "REQUEST_ACTIVE_CHARTER_RATE";
export const ACTIVE_CHARTER_RATE_SUCCESS = "ACTIVE_CHARTER_RATE_SUCCESS";
export const ACTIVE_CHARTER_RATE_ERROR = "ACTIVE_CHARTER_RATE_ERROR";

function requestActiveCharterRate() {
  return {
    type: REQUEST_ACTIVE_CHARTER_RATE,
  };
}

function activeCharterRateSuccess(json) {
  return {
    type: ACTIVE_CHARTER_RATE_SUCCESS,
    message: json.data,
  };
}

function activeCharterRateError(json) {
  return {
    type: ACTIVE_CHARTER_RATE_ERROR,
    error: json.data,
  };
}

export function activeCharterRate(charterRate_id) {
  return (dispatch) => {
    dispatch(requestActiveCharterRate());
    return CharterRateService.activeCharterRate(charterRate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(activeCharterRateSuccess(json.data));
      } else {
        dispatch(activeCharterRateError(json.data));
      }
    });
  };
}
