import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import DeckService from "../../../../services/catalogs/DeckService";

export const REQUEST_UPDATE_DECK = "REQUEST_UPDATE_DECK";
export const UPDATE_DECK_SUCCESS = "UPDATE_DECK_SUCCESS";
export const UPDATE_DECK_ERROR = "UPDATE_DECK_ERROR";

function requestUpdateDeck() {
  return {
    type: REQUEST_UPDATE_DECK,
  };
}

function updateDeckSuccess(json) {
  return {
    type: UPDATE_DECK_SUCCESS,
    message: json.data,
  };
}

function updateDeckError(json) {
  return {
    type: UPDATE_DECK_ERROR,
    error: json.data,
  };
}

export function updateDeck(deck_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateDeck());
    return DeckService.putDeck(deck_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateDeckSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateDeck(deck_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateDeckError(json.data));
      }
    });
  };
}
