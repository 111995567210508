import PassengerBySpaceForm from "../containers/reservations/cruise/passengerBySpace/PassengerBySpaceForm";

const routes = [
  {
    path:
      "/cruises/:cruise_id/reservations/:reservation_id/bookings/:booking_id/spaces/:space_id/passengers_new",
    component: PassengerBySpaceForm,
  },
  {
    path:
      "/cruises/:cruise_id/reservations/:reservation_id/bookings/:booking_id/spaces/:space_id/spacePassenger/:spacePassenger_id/passengers/:passenger_id",
    component: PassengerBySpaceForm,
  },
];

export { routes as passengerBySpaceRoutes };
