import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideService from "../../../../services/catalogs/GuideService";

export const REQUEST_UPDATE_GUIDE = "REQUEST_UPDATE_GUIDE";
export const UPDATE_GUIDE_SUCCESS = "UPDATE_GUIDE_SUCCESS";
export const UPDATE_GUIDE_ERROR = "UPDATE_GUIDE_ERROR";

function requestUpdateGuide() {
  return {
    type: REQUEST_UPDATE_GUIDE,
  };
}

function updateGuideSuccess(json) {
  return {
    type: UPDATE_GUIDE_SUCCESS,
    message: json.data,
  };
}

function updateGuideError(json) {
  return {
    type: UPDATE_GUIDE_ERROR,
    error: json.data,
  };
}

export function updateGuide(guide_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateGuide());
    return GuideService.putGuide(guide_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateGuideSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateGuide(guide_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateGuideError(json.data));
      }
    });
  };
}
