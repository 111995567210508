import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customGeneralRateCabinForm = createSlice({
  name: 'customGeneralRateCabinForm',
  initialState: {
    cabins: [],
    generalRate: {},
    generalRateCabin: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateGeneralRateCabinFormCustom(state, action) {
      return {
        cabins: [],
        generalRate: {},
        generalRateCabin: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesGeneralRateCabinFormCustom(state, action) {
      return {
        cabins: [],
        generalRate: {},
        generalRateCabin: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchGeneralRateCabinFormCustomSuccess(state, action) {
      return {
        cabins: action.payload.cabins.cabins,
        generalRate: action.payload.generalRate,
        generalRateCabin: action.payload.generalRateCabin,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchGeneralRateCabinFormCustomError(state, action) {
      return {
        cabins: [],
        generalRate: {},
        generalRateCabin: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchGeneralRateCabinFormCustom = (generalRateCabin_id) => dispatch => {
  dispatch(requesGeneralRateCabinFormCustom());
  axios.get(`/generalRate/${generalRateCabin_id}/generalRateCabinForm`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchGeneralRateCabinFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGeneralRateCabinFormCustom(generalRateCabin_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGeneralRateCabinFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateGeneralRateCabinFormCustom,
  requesGeneralRateCabinFormCustom,
  fetchGeneralRateCabinFormCustomSuccess,
  fetchGeneralRateCabinFormCustomError,
} = customGeneralRateCabinForm.actions;

export default customGeneralRateCabinForm.reducer;