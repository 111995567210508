import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class UserService {
  static getAgencyUsers(agency_id) {
    return axios.get("users/agencies/" + agency_id);
  }

  static getUsers() {
    return axios.get("users");
  }

  static getUser(user_id) {
    return axios.get("users/" + user_id);
  }

  static createAgencyUser(agency_id, payload) {
    return axios.post(
      API_BASE_URL + `users/signUpAgencyUser?agency_id=${agency_id}`,
      payload
    );
  }

  static createCompanyUser(payload) {
    return axios.post(
      API_BASE_URL + `users/signUpCompanyUser`,
      payload
    );
  }

  static signInAgencyUser(payload) {
    return axios.post(API_BASE_URL + `users/agency/signin`, payload);
  }

  static signIn(payload) {
    return axios.post(API_BASE_URL + `users/signin`, payload);
  }

  static updateAgencyUser(user_id, payload) {
    return axios.put("users/updateAgencyUser/" + user_id, payload);
  }

  static updateCompanyUser(user_id, payload) {
    return axios.put("users/updateCompanyUser/" + user_id, payload);
  }

  static deleteUser(user_id) {
    return axios.delete("users/" + user_id);
  }

  static changePasswordUser(user_id, payload){
    return axios.put("users/change_password/" + user_id, payload);
  }

  static ForgotPasswordUser(payload){
    return axios.put("users/forgot_passowrd", payload);
  }
}
