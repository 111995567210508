import React from "react";
import Dashboard from "../dashboard/Dashboard";
import DashboardB2B from "../dashboardb2b/DashboardB2B";
import LoginForm from "../containers/auth/LoginForm";
import ChangePasswordForm from "./auth/ChangePasswordForm";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import configureStore from "../redux/store/configureStore";
import { Provider } from "react-redux";
import { PrivateRoute } from "./PrivateRoute";
import ForgotPasswordForm from "./auth/ForgotPasswordForm";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <LoginForm path="/login" />
          <ForgotPasswordForm path="/forgotPassword"/>
          <Route path="/changePassword/:user_id" component={ChangePasswordForm} />
          <DashboardB2B path="/b2b" />
          <PrivateRoute component={Dashboard} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
