
import React from "react";
import { useTranslation } from "react-i18next";
import { Popup, Grid, Icon, List, Header } from "semantic-ui-react";

export default function IkarusRatePopup(props) {
  const { t } = useTranslation();

  return (
    <>
      <Popup trigger={<Icon name='info' color='red' size='small' circular />} flowing hoverable>
      {props.ikarusRates.map((ikarusRate) => (
        <Grid centered divided columns={4}>
          <Grid.Column textAlign='left'>
            <Header textAlign='center' as='h4'>Cabinas<br></br>{ikarusRate.cabins}</Header>
            <p>
              Charter:  <b>{ikarusRate.charter_client}</b>
            </p>
            <p>
              <List.Icon color='red' name={ikarusRate.airplane_ticket === 1 ? 'plane' : 'dont'} />
              {ikarusRate.airplane_ticket === 1 ? t("general_priceInclude") : t("general_priceNotInclude")}
            </p>
          </Grid.Column>
        
          <Grid.Column textAlign='left'>
            <Header textAlign='center' as='h4'>Rango: {ikarusRate.range1_number_passenger_from} - {ikarusRate.range1_number_passenger_to} PAX</Header>
            <p>
              Doble: <b>{ikarusRate.range1_double_client}</b><br></br>
              Sencilla: <b>{ikarusRate.range1_single_client}</b><br></br>
              Tour Leader: <b>{ikarusRate.range1_tour_leader_percent_reduction}</b><List.Icon name='percent' color='blue' />
            </p>
          </Grid.Column>

          <Grid.Column textAlign='left'>
            <Header textAlign='center' as='h4'>Rango: {ikarusRate.range2_number_passenger_from} - {ikarusRate.range2_number_passenger_to} PAX</Header>
            <p>
              Doble: <b>{ikarusRate.range2_double_client}</b><br></br>
              Sencilla: <b>{ikarusRate.range2_single_client}</b><br></br>
              Tour Leader: <b>{ikarusRate.range2_tour_leader_percent_reduction}</b><List.Icon name='percent' color='blue' />
            </p>
          </Grid.Column>

          <Grid.Column textAlign='left'>
            <Header textAlign='center' as='h4'>Rango: {ikarusRate.range3_number_passenger_from} - {ikarusRate.range3_number_passenger_to} PAX</Header>
            <p>
              Doble: <b>{ikarusRate.range3_double_client}</b><br></br>
              Sencilla: <b>{ikarusRate.range3_single_client}</b><br></br>
              Tour Leader: <b>{ikarusRate.range3_tour_leader_percent_reduction}</b><List.Icon name='percent' color='blue' />
            </p>
          </Grid.Column>  
        </Grid>
         ))}
      </Popup>
    </>
  );
}
