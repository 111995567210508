import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import LanguageService from "../../../../services/catalogs/LanguageService";

export const REQUEST_DELETE_LANGUAGE = "REQUEST_DELETE_LANGUAGE";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_ERROR = "DELETE_LANGUAGE_ERROR";

function requestDeleteLanguage() {
  return {
    type: REQUEST_DELETE_LANGUAGE,
  };
}

function deleteLanguageSuccess(json) {
  return {
    type: DELETE_LANGUAGE_SUCCESS,
    message: json.data,
  };
}

function deleteLanguageError(json) {
  return {
    type: DELETE_LANGUAGE_ERROR,
    error: json.data,
  };
}

export function deleteLanguage(language_id) {
  return (dispatch) => {
    dispatch(requestDeleteLanguage());
    return LanguageService.deleteLanguage(language_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteLanguageSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteLanguage(language_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteLanguageError(json.data));
      }
    });
  };
}
