import NoteList from "../containers/catalogs/note/NoteList";
import NoteForm from "../containers/catalogs/note/NoteForm";

const routes = [
  {
    path: "/notes",
    component: NoteList,
  },
  {
    path: "/notes_new",
    component: NoteForm,
  },
  {
    path: "/notes/:note_id",
    component: NoteForm,
  },
];

export { routes as noteRoutes };
