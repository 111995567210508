import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Button } from "semantic-ui-react";
import useStyles from "../../styles/Common";
import PassengerForm from "../../../components/passengerForm";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {
  fetchItinerary,
  initialStateItinerary,
} from "../../../redux/actions/catalogs/itinerary/fetchItinerary";
import { useLocation, useHistory } from "react-router";

export default function AvailabilityClientHold() {
  let search = useLocation().search;
  const history = useHistory();
  const chooseOrigin = new URLSearchParams(search).get("origin");
  const { t } = useTranslation();
  const classes = useStyles();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const itineraryState = useSelector((state) => state.itineraryReducer);
  let cruise = JSON.parse(sessionStorage.getItem("cruise"));
  let spaces = JSON.parse(sessionStorage.getItem("spaces"));
  let passengers = JSON.parse(sessionStorage.getItem("passengers"));
  const itineraryId = cruise.itineraryId;
  const [message, setMessage] = useState("");
  const [origin, setOrigin] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    handleEndConcert();
    if (itineraryId) {
      dispatch(fetchItinerary(itineraryId));
    }

    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleEndConcert);

    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateItinerary());
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleEndConcert);
      // handleEndConcert();
    };
  }, [dispatch, itineraryId]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleEndConcert = () => {
    sessionStorage.removeItem("passengers");
    sessionStorage.setItem("passengers", JSON.stringify({ passengers: [] }));
  };

  // END FUNCTIONS

  if (itineraryState.fetching) {
    return <CircularProgress />;
  }

  if (itineraryState.error) {
    return <div>Error: {itineraryState.error}</div>;
  }

  if (chooseOrigin && origin === "") {
    setOrigin(chooseOrigin);
  }

  const handleShowMessage = (newState) => {
    setState({ open: true, ...newState });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 6000);
  };

  const handleBuy = () => {
    passengers = JSON.parse(sessionStorage.getItem("passengers"));

    if (spaces.length === passengers.passengers.length) {
      history.push(
        `/b2b/availability/client/hold/confirmation?origin=${origin}`
      );
    } else {
      setMessage("don't equal");
      handleShowMessage({
        vertical: "top",
        horizontal: "right",
      });
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    }
  };

  return (
    <>
      {message === "don't equal" && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert severity="info" color="error" variant="filled">
            {t("cabin_information")}
          </Alert>
        </Snackbar>
      )}

      {origin === "" ? (
        ""
      ) : (
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      )}

      <div style={{ display: "flex" }}>
        <Typography
          className={classes.title}
          variant="h6"
          noWrap
          style={{ padding: "20px 0 10px  0", width: "20%" }}
        >
          {cruise.yacht}
          <div style={{ fontWeight: "normal", fontSize: "12px" }}>
            {t("itinerary_from") + " " + cruise.startDate.split("-").join("/")}
          </div>
          <div style={{ fontWeight: "normal", fontSize: "12px" }}>
            {t("itinerary_to") + " " + cruise.endDate.split("-").join("/")}
          </div>
        </Typography>
        <div
          style={{
            paddingTop: "25px",
            paddingLeft: "35px",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#2C8ABD",
            width: "80%",
          }}
        >
          {itineraryState.itinerary.name}
        </div>
      </div>

      <Divider />
      <br />
      <br />
      {spaces.map((space, index) => (
        <PassengerForm
          id={space.spaceId}
          key={index}
          cabin={space.cabin}
          index={index}
          gender={space.gender}
          cabin_id={space.cabinId}
        />
      ))}

      <div style={{ fontSize: "15px", textAlign: "justify" }}>
        <span style={{ fontWeight: "bold" }}>{t("include")}: </span>
        <br />
        <p>{t("include_message")}</p>
      </div>
      <br />
      <div style={{ fontSize: "15px", textAlign: "justify" }}>
        <span style={{ fontWeight: "bold" }}>{t("not_include")}: </span>
        <br />
        <p>{t("not_include_message")}</p>
      </div>
      <br />
      <Button
        className="primary button"
        onClick={() => {
          handleBuy();
        }}
      >
        Buy
      </Button>
    </>
  );
}
