import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import TicketService from "../../../../services/rates/TicketService";

export const REQUEST_ACTIVE_TICKET = "REQUEST_ACTIVE_TICKET";
export const ACTIVE_TICKET_SUCCESS = "ACTIVE_TICKET_SUCCESS";
export const ACTIVE_TICKET_ERROR = "ACTIVE_TICKET_ERROR";

function requestActiveTicket() {
  return {
    type: REQUEST_ACTIVE_TICKET,
  };
}

function activeTicketSuccess(json) {
  return {
    type: ACTIVE_TICKET_SUCCESS,
    message: json.data,
  };
}

function activeTicketError(json) {
  return {
    type: ACTIVE_TICKET_ERROR,
    error: json.data,
  };
}

export function activeTicket(ticket_id) {
  return (dispatch) => {
    dispatch(requestActiveTicket());
    return TicketService.activeTicket(ticket_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(activeTicketSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(activeTicket(ticket_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(activeTicketError(json.data));
      }
    });
  };
}
