import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IkarusRateService from "../../../../services/rates/IkarusRateService";

export const REQUEST_DELETE_IKARUS_RATE = "REQUEST_DELETE_IKARUS_RATE";
export const DELETE_IKARUS_RATE_SUCCESS = "DELETE_IKARUS_RATE_SUCCESS";
export const DELETE_IKARUS_RATE_ERROR = "DELETE_IKARUS_RATE_ERROR";

function requestDeleteIkarusRate() {
  return {
    type: REQUEST_DELETE_IKARUS_RATE,
  };
}

function deleteIkarusRateSuccess(json) {
  return {
    type: DELETE_IKARUS_RATE_SUCCESS,
    message: json.data,
  };
}

function deleteIkarusRateError(json) {
  return {
    type: DELETE_IKARUS_RATE_ERROR,
    error: json.data,
  };
}

export function deleteIkarusRate(ikarusRate_id) {
  return (dispatch) => {
    dispatch(requestDeleteIkarusRate());
    return IkarusRateService.deleteIkarusRate(ikarusRate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteIkarusRateSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteIkarusRate(ikarusRate_id));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(deleteIkarusRateError(json.data));
      }
    });
  };
}
