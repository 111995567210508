import React from "react";
import GenderIcon from "../../components/reservation/genderIcon";
import { Button, Modal, Icon } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deletePassengerBySpace } from "../../redux/actions/reservations/spacePassenger/deletePassengerBySpace";

export default function PassengerCell(props) {

  const { t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();

  const handleRedirectNewPassenger = (space_id) => {
    history.push(`/cruises/${props.cruise_id}/reservations/${props.reservation_id}/bookings/${props.booking_id}/spaces/${space_id}/passengers_new`);
  }

  const handleDeletePassenger = (space_id, passenger_id) => {
    dispatch(deletePassengerBySpace(space_id, passenger_id));
  };

  return (
    <>
      <GenderIcon gender={props.space.gender_name} />
      {props.space.passenger_id !== "" ? (
        <>
          <Link to={`/cruises/${props.cruise_id}/reservations/${props.reservation_id}/bookings/${props.booking_id}/spaces/${props.space.id}/spacePassenger/${props.space.spacePassenger_id}/passengers/${props.space.passenger_id}`}> 
            {props.space.last_name} {props.space.first_name}
          </Link>{" "}

          <Modal
            trigger={
              <Link style={{ color: "red", fontSize: "11px" }} to={`/cruises/${props.cruise_id}/reservations/${props.reservation_id}/bookings/${props.booking_id}/spaces`}>
                <Button circular icon='delete' size='mini' color='red' title={t("space_remove_passenger_title")}/>
              </Link>
            }
            content={
              <div style={{paddingTop: "20px", paddingBottom: "20px", paddingLeft: "20px"}}>
                <p style={{ fontWeight: "bold", fontSize: "15px" }}>
                  {t("space_passenger")}: {props.space.last_name}{" "}
                  {props.space.first_name}
                </p>
                <br />
                <p> {t("space_remove_passenger_message")}</p>
              </div>
            }
            actions={[
              t("canceled_button"),
              {
                key: "eliminar",
                content: t("remove passenger"),
                className: "delete_button icon_color",
                onClick: () =>
                  handleDeletePassenger(
                    props.space.id,
                    props.space.passenger_id
                  ),
              },
            ]}
            size="tiny"
          />
        </>
      ) : (
        <>
          <Button className="add_button" icon circular size="mini" onClick={() => handleRedirectNewPassenger(props.space.id)}>
            <Icon className="icon_color" name="add" />
          </Button>
        </>
      )}
      <br></br>
      <span className="text_date_format">{t(props.space.passenger_nationality)}</span>
    </>
  )
}