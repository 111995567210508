import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function B2BList() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openCatalogs, setOpenCatalogs] = React.useState(false);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button component={Link} to="/b2b/availability">
        <ListItemIcon>
          <SendIcon />
        </ListItemIcon>
        <ListItemText primary={t("b2b_availability")} />
      </ListItem>
      <ListItem button onClick={() => setOpenCatalogs(!openCatalogs)}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={t("b2b_yacht")} />
        {openCatalogs ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCatalogs} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/b2b/tiptop2"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Tip Top II" />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/b2b/tiptop4"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Tip Top IV" />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/b2b/tiptop5"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Tip Top V" />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button component={Link} to="/b2b/availability/reservation">
        <ListItemIcon>
          <SendIcon />
        </ListItemIcon>
        <ListItemText primary={t("reservation_title")} />
      </ListItem>
    </List>
  );
}
