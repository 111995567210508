import CruiseRateService from "../../../../services/reservations/CruiseRateService";

export const REQUEST_CREATE_CRUISE_RATE = "REQUEST_CREATE_CRUISE_RATE";
export const CREATE_CRUISE_RATE_SUCCESS = "CREATE_CRUISE_RATE_SUCCESS";
export const CREATE_CRUISE_RATE_ERROR = "CREATE_CRUISE_RATE_ERROR";

function requestCreateCruiseRate() {
  return {
    type: REQUEST_CREATE_CRUISE_RATE,
  };
}

function createCruiseRateSuccess(json) {
  return {
    type: CREATE_CRUISE_RATE_SUCCESS,
    message: json.data,
  };
}

function createCruiseRateError(json) {
  return {
    type: CREATE_CRUISE_RATE_ERROR,
    error: json.data,
  };
}

export function createCruiseRate(cruise_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateCruiseRate());
    return CruiseRateService.postCruiseRate(cruise_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createCruiseRateSuccess(json.data));
      } else {
        dispatch(createCruiseRateError(json.data));
      }
    });
  };
}
