import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customTipTopList = createSlice({
  name: 'customTipTopList',
  initialState: {
    cruises: [],
    years: [],
    yachts: [],
    logs: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateTipTopListCustom(state, action) {
      return {
        cruises: [],
        years: [],
        yachts: [],
        logs: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesTipTopListCustom(state, action) {
      return {
        cruises: [],
        yachts: [],
        years: [],
        logs: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchTipTopListCustomSuccess(state, action) {
      return {
        cruises: action.payload.cruises,
        yachts: action.payload.yachts,
        years: action.payload.years,
        logs: action.payload.cruisesLogs,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchTipTopListCustomError(state, action) {
      return {
        cruises: [],
        years: [],
        yachts: [],
        logs: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchTipTopListCustom = (cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, filter, order) => dispatch => {
  dispatch(requesTipTopListCustom());
  var url = "cruise/tipTopList";
  url = url + `?cruiseCode=${cruiseCode}&&cruiseOperate=${cruiseOperate}&&cruiseYear=${cruiseYear}&&cruiseYacht=${cruiseYacht}&&filter=${filter}&&order=${order}`;
  axios.get(`/${url}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchTipTopListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchTipTopListCustom(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, filter, order));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchTipTopListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateTipTopListCustom,
  requesTipTopListCustom,
  fetchTipTopListCustomSuccess,
  fetchTipTopListCustomError,
} = customTipTopList.actions;

export default customTipTopList.reducer;