import React from "react";
import { Button, Modal, Image } from "semantic-ui-react";
import Carousel from "react-material-ui-carousel";

function ModalImagesByCabin(props) {
  return (
    <Modal
      trigger={<Button icon="image" size="tiny" />}
      header={
        <div style={{ display: "flex", padding: "15px" }}>
          <div
            style={{
              width: "50%",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {props.cabin}
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-end",
              fontSize: "13px",
              fontWeight: "bold",
              color: "#1678C2",
              paddingTop: "4px",
            }}
          >
            {props.yacht}
          </div>
        </div>
      }
      content={
        <Carousel
          indicators={false}
          autoPlay={false}
          navButtonsAlwaysVisible={true}
        >
          {props.images.map((image, index) => (
            <Image
              key={index}
              src={image}
              style={{ width: "100%", height: "400px" }}
            />
          ))}
        </Carousel>
      }
      actions={["Cerrar"]}
      size="tiny"
    />
  );
}

export default ModalImagesByCabin;
