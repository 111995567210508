import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GenderService from "../../../../services/catalogs/GenderService";

export const REQUEST_FETCH_GENDERS = "REQUEST_FETCH_GENDERS";
export const FETCH_GENDERS_SUCCESS = "FETCH_GENDERS_SUCCESS";
export const FETCH_GENDERS_ERROR = "FETCH_GENDERS_ERROR";

function requestFetchGenders() {
  return {
    type: REQUEST_FETCH_GENDERS,
  };
}

function fetchGendersSuccess(json) {
  return {
    type: FETCH_GENDERS_SUCCESS,
    genders: json.data,
  };
}

function fetchGendersError(json) {
  return {
    type: FETCH_GENDERS_ERROR,
    error: json.data,
  };
}

export function fetchGenders() {
  return (dispatch) => {
    dispatch(requestFetchGenders());
    return GenderService.getGenders().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchGendersSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGenders());
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGendersError(json.data));
      }
    });
  };
}
