import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class GenderService {
  static getGenders() {
    return axios.get("genders");
  }
  static getGender(gender_id) {
    return axios.get("genders/" + gender_id);
  }

  static postGender(payload) {
    return axios.post("genders", payload);
  }

  static putGender(gender_id, payload) {
    return axios.put("genders/" + gender_id, payload);
  }

  static deleteGender(gender_id) {
    return axios.delete("genders/" + gender_id);
  }
}
