import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class AdvanceCruiseService {
  static getAdvanceCruises(yachtId, startDate, endDate) {
    var url = "advanceCruises";

    url =
      url +
      "?yacht_id=" +
      yachtId +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate;

    return axios.get(url);
  }

  static getAdvanceCruisesReport(yachtId, startDate, endDate) {
    var url = "advanceCruises/report";

    url =
      url +
      "?yacht_id=" +
      yachtId +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate;

    return axios.get(url);
  }
}
