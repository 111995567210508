import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customIkarusRateForm = createSlice({
  name: 'customIkarusRateForm',
  initialState: {
    agencies: [],
    yachts: [],
    days: [],
    ikarusRate: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateIkarusRateFormCustom(state, action) {
      return {
        agencies: [],
        yachts: [],
        days: [],
        ikarusRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesIkarusRateFormCustom(state, action) {
      return {
        agencies: [],
        yachts: [],
        days: [],
        ikarusRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchIkarusRateFormCustomSuccess(state, action) {
      return {
        agencies: action.payload.agencies,
        yachts: action.payload.yachts,
        days: action.payload.days,
        ikarusRate: action.payload.ikarusRate,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchIkarusRateFormCustomError(state, action) {
      return {
        agencies: [],
        yachts: [],
        days: [],
        ikarusRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchIkarusRateFormCustom = (searchWord, ikarusRate_id) => dispatch => {
  dispatch(requesIkarusRateFormCustom());
  axios.get(`/ikarusRates/form/ikarusRatesForm?searchWord=${searchWord}&&ikarusRate_id=${ikarusRate_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchIkarusRateFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchIkarusRateFormCustom(ikarusRate_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchIkarusRateFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateIkarusRateFormCustom,
  requesIkarusRateFormCustom,
  fetchIkarusRateFormCustomSuccess,
  fetchIkarusRateFormCustomError,
} = customIkarusRateForm.actions;

export default customIkarusRateForm.reducer;