import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customPassengerDetailsList = createSlice({
  name: 'customPassengerDetailsList',
  initialState: {
    cruise: {},
    passengers: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStatePassengerDetailsListCustom(state, action) {
      return {
        cruise: {},
        passengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesPassengerDetailsListCustom(state, action) {
      return {
        cruise: {},
        passengers: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchPassengerDetailsListCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        passengers: action.payload.passengers,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchPassengerDetailsListCustomError(state, action) {
      return {
        cruise: {},
        passengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchPassengerDetailsListCustom = (cruise_id) => dispatch => {
  dispatch(requesPassengerDetailsListCustom());
  axios.get(`/cruises/${cruise_id}/passengersDetailsList`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchPassengerDetailsListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchPassengerDetailsListCustom(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchPassengerDetailsListCustomError(json.data.data));
      }
    })
};

export const {
  initialStatePassengerDetailsListCustom,
  requesPassengerDetailsListCustom,
  fetchPassengerDetailsListCustomSuccess,
  fetchPassengerDetailsListCustomError,
} = customPassengerDetailsList.actions;

export default customPassengerDetailsList.reducer;