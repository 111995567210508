import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customPassengerBySpaceForm = createSlice({
  name: 'customPassengerBySpaceForm',
  initialState: {
    identificationTypes: [],
    countries: [],
    genders: [],
    notes: [],
    cruise: {},
    reservation: {},
    booking: {},
    passengerBySpace: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStatePassengerBySpaceFormCustom(state, action) {
      return {
        identificationTypes: [],
        countries: [],
        genders: [],
        notes: [],
        cruise: {},
        reservation: {},
        booking: {},
        passengerBySpace: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesPassengerBySpaceFormCustom(state, action) {
      return {
        identificationTypes: [],
        countries: [],
        genders: [],
        notes: [],
        cruise: {},
        reservation: {},
        booking: {},
        passengerBySpace: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchPassengerBySpaceFormCustomSuccess(state, action) {
      return {
        identificationTypes: action.payload.identificationTypes,
        countries: action.payload.countries,
        genders: action.payload.genders,
        notes: action.payload.notes,
        cruise: action.payload.cruise,
        reservation: action.payload.reservation,
        booking: action.payload.booking,
        passengerBySpace: action.payload.passengerBySpace,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchPassengerBySpaceFormCustomError(state, action) {
      return {
        identificationTypes: [],
        countries: [],
        genders: [],
        notes: [],
        cruise: {},
        reservation: {},
        booking: {},
        passengerBySpace: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchPassengerBySpaceFormCustom = (searchWord, space_id, cruise_id, reservation_id, booking_id, passenger_id) => dispatch => {
  dispatch(requesPassengerBySpaceFormCustom());
  var url = `spacePassengers/${space_id}/passengerBySpaceForm`;
  url = url + `?searchWord=${searchWord}&&cruise_id=${cruise_id}&&reservation_id=${reservation_id}&&booking_id=${booking_id}&&passenger_id=${passenger_id}`;
  axios.get(`/${url}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchPassengerBySpaceFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchPassengerBySpaceFormCustomSuccess(json.data.data));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchPassengerBySpaceFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStatePassengerBySpaceFormCustom,
  requesPassengerBySpaceFormCustom,
  fetchPassengerBySpaceFormCustomSuccess,
  fetchPassengerBySpaceFormCustomError,
} = customPassengerBySpaceForm.actions;

export default customPassengerBySpaceForm.reducer;