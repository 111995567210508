import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import PassengerService from "../../../../services/reservations/PassengerService";

export const REQUEST_FETCH_PASSENGERS_SELECTED =
  "REQUEST_FETCH_PASSENGERS_SELECTED";
export const FETCH_PASSENGERS_SELECTED_SUCCESS =
  "FETCH_PASSENGERS_SELECTED_SUCCESS";
export const FETCH_PASSENGERS_SELECTED_ERROR =
  "FETCH_PASSENGERS_SELECTED_ERROR";
export const INITIAL_STATE_PASSENGER_SELECTED =
  "INITIAL_STATE_PASSENGER_SELECTED";

function requestFetchPassengersSelected() {
  return {
    type: REQUEST_FETCH_PASSENGERS_SELECTED,
  };
}

function fetchPassengersSelectedSuccess(json) {
  return {
    type: FETCH_PASSENGERS_SELECTED_SUCCESS,
    flightGroups: json.data,
  };
}

function fetchPassengersSelectedError(json) {
  return {
    type: FETCH_PASSENGERS_SELECTED_SUCCESS,
    error: json.data,
  };
}

export function initialStatePassengerSelected() {
  return {
    type: INITIAL_STATE_PASSENGER_SELECTED,
  };
}

export function fetchPassengersSelected(cruise_id, payload) {
  return (dispatch) => {
    dispatch(requestFetchPassengersSelected());
    return PassengerService.getPassengersSelected(cruise_id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchPassengersSelectedSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchPassengersSelected(cruise_id, payload));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchPassengersSelectedError(json.data));
        }
      }
    );
  };
}
