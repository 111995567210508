import {
  REQUEST_FETCH_CABINS,
  FETCH_CABINS_SUCCESS,
  FETCH_CABINS_ERROR,
} from "../../../actions/catalogs/cabin/fetchCabins";

import {
  INITIAL_STATE_CABIN,
  REQUEST_FETCH_CABIN,
  FETCH_CABIN_SUCCESS,
  FETCH_CABIN_ERROR,
} from "../../../actions/catalogs/cabin/fetchCabin";

import {
  REQUEST_CREATE_CABIN,
  CREATE_CABIN_SUCCESS,
  CREATE_CABIN_ERROR,
} from "../../../actions/catalogs/cabin/createCabin";

import {
  REQUEST_UPDATE_CABIN,
  UPDATE_CABIN_SUCCESS,
  UPDATE_CABIN_ERROR,
} from "../../../actions/catalogs/cabin/updateCabin";

import {
  REQUEST_DELETE_CABIN,
  DELETE_CABIN_SUCCESS,
  DELETE_CABIN_ERROR,
} from "../../../actions/catalogs/cabin/deleteCabin";

const initialState = {
  cabins: [],
  cabin: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const cabinReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_CABIN: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_CABINS: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CABINS_SUCCESS: {
      return {
        ...state,
        cabins: action.cabins,
        cabin: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CABINS_ERROR: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_CABIN: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CABIN_SUCCESS: {
      return {
        ...state,
        cabins: [],
        cabin: action.cabin,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CABIN_ERROR: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_CABIN: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_CABIN_SUCCESS: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_CABIN_ERROR: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_CABIN: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_CABIN_SUCCESS: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_CABIN_ERROR: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_CABIN: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_CABIN_SUCCESS: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_CABIN_ERROR: {
      return {
        ...state,
        cabins: [],
        cabin: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default cabinReducer;
