import CruiseDetailList from "../containers/reservations/cruise/cruiseDetail/CruiseDetailList";

const routes = [
  {
    path: "/cruises/:cruise_id/cruise-detail",
    component: CruiseDetailList,
  },
];

export { routes as cruiseDetailRoutes };
