import AvailabilityService from "../../../../services/b2b/AvailabilityService";

export const REQUEST_UPDATE_SPACES = "REQUEST_UPDATE_SPACES";
export const UPDATE_SPACES_SUCCESS = "UPDATE_SPACES_SUCCESS";
export const UPDATE_SPACES_ERROR = "UPDATE_SPACES_ERROR";

function requestUpdateSpaces() {
  return {
    type: REQUEST_UPDATE_SPACES,
  };
}

function updateSpacesSuccess(json) {
  return {
    type: UPDATE_SPACES_SUCCESS,
    message: json.data,
  };
}

function updateSpacesError(json) {
  return {
    type: UPDATE_SPACES_ERROR,
    error: json.data,
  };
}

export function updateSpacesByReservation(payload) {
  return (dispatch) => {
    dispatch(requestUpdateSpaces());
    return AvailabilityService.changeSpacesOfAReservation(payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(updateSpacesSuccess(json.data));
        } else {
          dispatch(updateSpacesError(json.data));
        }
      }
    );
  };
}
