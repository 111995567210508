import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createCountry } from "../../../redux/actions/catalogs/country/createCountry";
import { fetchCountry, initialStateCountry } from "../../../redux/actions/catalogs/country/fetchCountry";
import { updateCountry } from "../../../redux/actions/catalogs/country/updateCountry";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function CountryForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { country_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const countryState = useSelector((state) => state.countryReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (country_id) {
      dispatch(fetchCountry(country_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateCountry());
    };
  }, []);

  if (countryState.fetching) {
    return <Loader active inline />;
  }

  if (countryState.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (country_id) {
      dispatch(updateCountry(country_id, data));
    } else {
      dispatch(createCountry(data));
    }
  };

  if (countryState.message === "resource created successfully" || countryState.message === "resource updated successfully") {
    history.push("/countries");
  }

  return (
    <>
      <Header as='h3' dividing>{t("country_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("country_name")} </label>
            <input name="name" defaultValue={countryState.country.name} placeholder={t("country_name")} autoFocus maxLength={100} ref={register({ required: true })} />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("country_andean_pact")} </label>
            <div className="ui checkbox">
              <input type="checkbox" name="andean_pact" defaultChecked={countryState.country.andean_pact === 1} ref={register} />
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={countryState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/countries"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
