import { combineReducers } from "redux";

import agencyReducer from "./catalogs/agencies/agencyReducer";
import airlineReducer from "./catalogs/airline/airlineReducer";
import berthReducer from "./catalogs/berth/berthReducer";
import cabinReducer from "./catalogs/cabin/cabinReducer";
import cabinsByYachtReducer from "./catalogs/cabinByYacht/cabinByYachtReducer";
import cabinBerthReducer from "./catalogs/cabinBerth/cabinBerthReducer";
import captainReducer from "./catalogs/captain/captainReducer";
import companyReducer from "./catalogs/company/companyReducer";
import countryReducer from "./catalogs/country/countryReducer";
import dayReducer from "./catalogs/day/dayReducer";
import daysByItineraryReducer from "./catalogs/day/daysByItineraryReducer";
import deckReducer from "./catalogs/deck/decksReducer";
import flightReducer from "./catalogs/flight/flightReducer";
import genderReducer from "./catalogs/gender/genderReducer";
import guideReducer from "./catalogs/guide/guideReducer";
import guideLanguageReducer from "./catalogs/guideLanguage/guideLanguageReducer";
import hotelierReducer from "./catalogs/hotelier/hotelierReducer";
import identificationTypeReducer from "./catalogs/identificationType/identificationTypeReducer";
import itineraryReducer from "./catalogs/itinerary/itineraryReducer";
import itineraryDaysReducer from "./catalogs/itinerary/itineraryDaysReducer";
import imageReducer from "./catalogs/itinerary/imageReducer";
import itineraryByYachtReducer from "./catalogs/itineraryByYacht/itineraryByYachtReducer";
import kindReducer from "./catalogs/kind/kindReducer";
import languageReducer from "./catalogs/language/languageReducer";
import noteReducer from "./catalogs/note/noteReducer";
import rateReducer from "./catalogs/rate/rateReducer";
import roleReducer from "./catalogs/role/roleReducer";
import routeReducer from "./catalogs/route/routeReducer";
import userReducer from "./catalogs/user/userReducer";
import yachtsReducer from "./catalogs/yacht/yachtsReducer";
import yachtReducer from "./catalogs/yacht/yachtReducer";
import yachtItineraryReducer from "./catalogs/yachtItinerary/yachtItineraryReducer";

//Rates
import cabinByYachtReducer from "./rates/familyRoomRate/cabinByYachtReducer";
import charterRateReducer from "./rates/charterRate/charterRateReducer";
import contractRateReducer from "./rates/contractRate/contractRateReducer";
import contractRateYearsReducer from "./rates/contractRate/contractRateYearsReducer";
import familyRoomRateReducer from "./rates/familyRoomRate/familyRoomRateReducer";
import familyRoomRateYearsReducer from "./rates/familyRoomRate/familyRoomRateYearsReducer";
import generalRateReducer from "./rates/generalRate/generalRateReducer";
import generalRateYearsReducer from "./rates/generalRate/generalRateYearsReducer";
import ikarusRateReducer from "./rates/ikarusRate/ikarusRateReducer";
import ikarusRateYearsReducer from "./rates/ikarusRate/ikarusRateYearsReducer";

import seasonReducer from "./rates/season/seasonReducer";
import ticketReducer from "./rates/ticket/ticketReducer";
import ticketYearsReducer from "./rates/ticket/ticketYearsReducer";
import yachtFamilyRoomReducer from "./rates/familyRoomRate/yachtFamilyRoomReducer";
import generalRateCabinReducer from "./rates/generalRateCabin/generalRateCabinReducer";
import ikarusRateCabinReducer from "./rates/ikarusRateCabin/ikarusRateCabinReducer";

import charterRateYearsReducer from "./rates/charterRate/charterRateYearsReducer";

//Reservations
import bookingReducer from "./reservations/booking/bookingReducer";
import cabinByBookingReducer from "./reservations/cabin/cabinByBookingReducer";
import cabinAvailabilityReducer from "./reservations/cabin/cabinAvailabilityReducer";
import cabinInformationByCruiseReducer from "./reservations/cabin/cabinInformationByCruiseReducer";
import cruiseReducer from "./reservations/cruise/cruiseReducer";
import cruiseLanguageReducer from "./reservations/cruiseLanguage/cruiseLanguageReducer";
import guideOnBoardReducer from "./reservations/guideOnBoard/guideOnBoardReducer";
import cruiseRateReducer from "./reservations/cruiseRate/cruiseRateReducer";
import reservationReducer from "./reservations/reservation/reservationReducer";
import reservationCanceledReducer from "./reservations/reservation/reservationCanceledReducer";
import passengerDetailReducer from "./reservations/passenger/passengerDetailReducer";
import passengerByAgencyReducer from "./reservations/passenger/passengerByAgencyReducer";
import passengerByCruiseReducer from "./reservations/passenger/passengerByCruiseReducer";
import passengerInFlightReducer from "./reservations/passenger/passengerInFlightReducer";
import passengerIngalaReducer from "./reservations/passenger/passengerIngalaReducer";
import passengerSelectedReducer from "./reservations/passenger/passengerSelectedReducer";
import passengerZarpeReducer from "./reservations/passenger/passengerZarpeReducer";
import orderServiceReducer from "./reservations/orderService/orderServiceReducer";
import verifyPassengerReducer from "./reservations/passengerBySpace/verifyPassengerReducer";
import voucherReducer from "./reservations/voucher/voucherReducer";
import voucherPassengerReducer from "./reservations/voucherPassenger/voucherPassengerReducer";
import VoucherPdfReducer from "./reservations/voucherPDF/VoucherPdfReducer";
import ingalaReportReducer from "./reservations/ingala/ingalaReportReducer";
import searchPassengerReducer from "./reservations/passenger/searchPassengerReducer";
import cruiseByPassengerReducer from "./reservations/passenger/cruiseByPassengerReducer";
import spaceByCruiseReducer from "./reservations/cruiseDetail/spaceByCruiseReducer";
import availableCabinReducer from "./reservations/changeCabin/availableCabinReducer";
import availablePassengerReducer from "./reservations/changeCabin/availablePassengerReducer";
import updateCabinReducer from "./reservations/changeCabin/updateCabinReducer";
import updatePassengerReducer from "./reservations/changeCabin/updatePassengerReducer";
import historicalCruiseReducer from "./reservations/historicalCruise/historicalCruiseReducer";
import advanceCruiseReducer from "./reservations/advanceCruise/advanceCruiseReducer";
import advanceCruiseReportReducer from "./reservations/advanceCruise/advanceCruiseReportReducer";
import statisticReportReducer from "./reservations/statistic/statisticReportReducer";
import statisticReducer from "./reservations/statistic/statisticReducer";
import spaceReducer from "./reservations/space/spaceReducer";
import spaceCanceledReducer from "./reservations/space/spaceCanceledReducer";
import verifySpacesByResevationReducer from "./reservations/reservation/verifySpacesByResevationReducer";
import reservationLogReducer from "./reservations/reservation/reservationLogsReducer";
import bookingLogReducer from "./reservations/booking/bookingLogsReducer";
import cruiseLogReducer from "./reservations/cruise/cruiseLogsReducer";
import cruiseLanguageLogReducer from "./reservations/cruiseLanguage/cruiseLanguageLogsReducer"
import guideOnBoardLogReducer from "./reservations/guideOnBoard/cruiseOnBoardLogsReducer";
import spaceLogReducer from "./reservations/space/spaceLogsReducer";
import spaceFlightReducer from "./reservations/spaceFlight/spaceFlightReducer";
import spaceFlightsLogReducer from "./reservations/spaceFlight/spaceFlightLogsReducer";
import numberSpaceFlightReducer from "./reservations/spaceFlight/numberFlightsBySpaceReducer";
import passengerBySpaceReducer from "./reservations/passengerBySpace/passengerBySpaceReducer";

//B2B
import yachtsB2BReducer from "./b2b/yachts/yachtsReducer";
import availabilityReducer from "./b2b/availability/availabilityReducer";
import availabilityActualReservationsReducer from "./b2b/availability/availabilityActualReservationsReducer";
import availabilityHistoricalReservationsReducer from "./b2b/availability/availabilityHistoricalReservationsReducer";
import availabilityReservationDetailReducer from "./b2b/availability/availabilityReservationDetailReducer";

//CUSTOM SLICE
import customTipTopList from "../slices/custom/reservations/cruise/TipTop/customTipTopList.slice";
import customTipTopForm from "../slices/custom/reservations/cruise/TipTop/customTipTopForm.slice";
import customCruiseDetails from "../slices/custom/reservations/cruise/cruiseDetails/customCruiseDetails.slice";
import customReservationList from "../slices/custom/reservations/cruise/reservation/customReservationList.slice";
import customReservationForm from "../slices/custom/reservations/cruise/reservation/customReservationForm.slice";
import customBookingList from "../slices/custom/reservations/cruise/booking/customBookingList.slice";
import customBookingForm from "../slices/custom/reservations/cruise/booking/customBookingForm.slice";
import customSpaceList from "../slices/custom/reservations/cruise/space/customSpaceList.slice";
import customSpaceForm from "../slices/custom/reservations/cruise/space/customSpaceForm.slice";
import customSpaceFlightList from "../slices/custom/reservations/cruise/spaceFlight/customSpaceFlightList.slice";
import customSpaceFlightForm from "../slices/custom/reservations/cruise/spaceFlight/customSpaceFlightForm.slice";
import customPassengerBySpaceForm from "../slices/custom/reservations/cruise/passengerBySpace/customPassengerBySpaceForm.slice";
import customCruiseLanguajeList from "../slices/custom/reservations/cruiseLanguage/customCruiseLanguageList.slice";
import customCruiseLanguageForm from "../slices/custom/reservations/cruiseLanguage/customCruiseLanguageForm.slice";
import customGuideOnBoardList from "../slices/custom/reservations/guideOnBoard/customGuideOnBoardList.slice";
import customGuideOnBoardForm from "../slices/custom/reservations/guideOnBoard/customGuideOnBoardForm.slice";
import customPassengerDetailsList from "../slices/custom/reservations/cruise/passengerDetails/customPassengerDetailsList.slice";
import customIngalaList from "../slices/custom/reservations/cruise/ingala/customIngalaList.slice";
import customGpsTaxList from "../slices/custom/reservations/cruise/gpsTax/customGpsTaxList.slice";
import customPassengerInFlightList from "../slices/custom/reservations/cruise/flight/customPassengerInFlightList.slice";
import customVoucherList from "../slices/custom/reservations/cruise/voucher/customVoucherList.slice";
import customVoucherForm from "../slices/custom/reservations/cruise/voucher/customVoucherForm.slice";
import customGeneralRateList from "../slices/custom/rates/generalRate/customGeneralRateList.slice";
import customGeneralRateForm from "../slices/custom/rates/generalRate/customGeneralRateForm.slice";
import customGeneralRateCabinList from "../slices/custom/rates/generalRateCabins/customGeneralRateCabinList.slice";
import customGeneralRateCabinForm from "../slices/custom/rates/generalRateCabins/customGeneralRateCabinForm.slice";
import customFamilyRoomList from "../slices/custom/rates/familyRoom/customFamilyRoomList.slice";
import customFamilyRoomForm from "../slices/custom/rates/familyRoom/customFamilyRoomForm.slice";
import customCharterRateList from "../slices/custom/rates/charterRate/customCharterRateList.slice";
import customCharterRateForm from "../slices/custom/rates/charterRate/customCharterRateForm.slice";
import customContractRateList from "../slices/custom/rates/contractRate/customContractRateList.slice";
import customContractRateForm from "../slices/custom/rates/contractRate/customContractRateForm.slice";
import customIkarusRateList from "../slices/custom/rates/ikarusRate/customIkarusRateList.slice";
import customIkarusRateForm from "../slices/custom/rates/ikarusRate/customIkarusRateForm.slice";
import customIkarusRateCabinList from "../slices/custom/rates/ikarusRateCabins/customIkarusRateCabinList.slice";
import customIkarusRateCabinForm from "../slices/custom/rates/ikarusRateCabins/customIkarusRateCabinForm.slice";
import customTicketList from "../slices/custom/rates/ticket/customTicketList.slice";
import customTicketForm from "../slices/custom/rates/ticket/customTicketForm.slice";
import customSeasonForm from "../slices/custom/rates/season/customIkarusRateForm.slice";
import customCompanyUserForm from "../slices/custom/catalogs/user/customCompanyUserForm.slice";
import customAgencyUserList from "../slices/custom/catalogs/user/customAgencyUserList.slice";
import customAgencyUserForm from "../slices/custom/catalogs/user/customAgencyUserForm.slice";
import customYachtForm from "../slices/custom/catalogs/yacht/customYachtForm.slice";
import customAgencyForm from "../slices/custom/catalogs/agency/customAgencyForm.slice";
import customCabinBerthList from "../slices/custom/catalogs/cabinBerth/customCabinBerthList.slice";
import customCabinBerthForm from "../slices/custom/catalogs/cabinBerth/customCabinBerthForm.slice";
import customDayList from "../slices/custom/catalogs/day/customDayList.slice";
import customDayForm from "../slices/custom/catalogs/day/customDayForm.slice";
import customFlightForm from "../slices/custom/catalogs/flight/customFlightForm.slice";
import customYachtItineraryList from "../slices/custom/catalogs/yachtItinerary/customYachtItineraryList.slice";
import customYachtItineraryForm from "../slices/custom/catalogs/yachtItinerary/customYachtItineraryForm.slice";
import customGuideLanguageList from "../slices/custom/catalogs/guideLanguage/customGuideLanguageList.slice";
import customGuideLanguageForm from "../slices/custom/catalogs/guideLanguage/customGuideLanguageForm.slice";

const rootReducer = combineReducers({
  agencyReducer,
  airlineReducer,
  availabilityReducer,
  berthReducer,
  bookingReducer,
  cabinAvailabilityReducer,
  cabinByBookingReducer,
  cabinInformationByCruiseReducer,
  cabinReducer,
  cabinsByYachtReducer,
  cabinBerthReducer,
  captainReducer,
  charterRateYearsReducer,
  companyReducer,
  contractRateReducer,
  contractRateYearsReducer,
  countryReducer,
  cruiseReducer,
  cruiseLanguageReducer,
  cruiseRateReducer,
  dayReducer,
  daysByItineraryReducer,
  deckReducer,
  familyRoomRateReducer,
  familyRoomRateYearsReducer,
  flightReducer,
  generalRateReducer,
  ikarusRateReducer,
  ikarusRateYearsReducer,
  genderReducer,
  guideReducer,
  guideLanguageReducer,
  guideOnBoardReducer,
  hotelierReducer,
  identificationTypeReducer,
  itineraryReducer,
  imageReducer,
  itineraryByYachtReducer,
  kindReducer,
  languageReducer,
  noteReducer,
  orderServiceReducer,
  passengerDetailReducer,
  passengerByCruiseReducer,
  passengerByAgencyReducer,
  passengerInFlightReducer,
  passengerIngalaReducer,
  passengerSelectedReducer,
  passengerZarpeReducer,
  rateReducer,
  reservationReducer,
  reservationCanceledReducer,
  roleReducer,
  routeReducer,
  ticketReducer,
  ticketYearsReducer,
  seasonReducer,
  userReducer,
  verifyPassengerReducer,
  reservationLogReducer,
  bookingLogReducer,
  cruiseLogReducer,
  cruiseLanguageLogReducer,
  voucherReducer,
  voucherPassengerReducer,
  VoucherPdfReducer,
  yachtsReducer,
  yachtReducer,
  yachtFamilyRoomReducer,
  generalRateCabinReducer,
  ikarusRateCabinReducer,
  yachtItineraryReducer,
  ingalaReportReducer,
  searchPassengerReducer,
  cruiseByPassengerReducer,
  spaceByCruiseReducer,
  availableCabinReducer,
  availablePassengerReducer,
  updateCabinReducer,
  updatePassengerReducer,
  historicalCruiseReducer,
  advanceCruiseReducer,
  advanceCruiseReportReducer,
  generalRateYearsReducer,
  itineraryDaysReducer,
  charterRateReducer,
  statisticReportReducer,
  statisticReducer,
  spaceReducer,
  spaceCanceledReducer,
  spaceFlightReducer,
  spaceLogReducer,
  spaceFlightsLogReducer,
  numberSpaceFlightReducer,
  guideOnBoardLogReducer,
  passengerBySpaceReducer,
  cabinByYachtReducer,
  yachtsB2BReducer,
  verifySpacesByResevationReducer,
  availabilityActualReservationsReducer,
  availabilityHistoricalReservationsReducer,
  availabilityReservationDetailReducer,
  //SLICES
  customTipTopList,
  customTipTopForm,
  customCruiseDetails,
  customReservationList,
  customReservationForm,
  customBookingList,
  customBookingForm,
  customSpaceList,
  customSpaceForm,
  customSpaceFlightList,
  customSpaceFlightForm,
  customPassengerBySpaceForm,
  customCruiseLanguajeList,
  customCruiseLanguageForm,
  customGuideOnBoardList,
  customGuideOnBoardForm,
  customPassengerDetailsList,
  customPassengerDetailsList,
  customIngalaList,
  customGpsTaxList,
  customPassengerInFlightList,
  customVoucherList,
  customVoucherForm,
  customGeneralRateList,
  customGeneralRateForm,
  customGeneralRateCabinList,
  customGeneralRateCabinForm,
  customFamilyRoomList,
  customFamilyRoomForm,
  customCharterRateList,
  customCharterRateForm,
  customContractRateList,
  customContractRateForm,
  customIkarusRateList,
  customIkarusRateForm,
  customIkarusRateCabinList,
  customIkarusRateCabinForm,
  customTicketList,
  customTicketForm,
  customSeasonForm,
  customCompanyUserForm,
  customAgencyUserList,
  customAgencyUserForm,
  customYachtForm,
  customAgencyForm,
  customCabinBerthList,
  customCabinBerthForm,
  customDayList,
  customDayForm,
  customFlightForm,
  customYachtItineraryList,
  customYachtItineraryForm,
  customGuideLanguageList,
  customGuideLanguageForm 

});

export default rootReducer;
