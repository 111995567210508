import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ReservationService from "../../../../services/reservations/ReservationService";

export const REQUEST_SPACES_BY_RESERVATION = "REQUEST_SPACES_BY_RESERVATION";
export const VERIFY_SPACES_BY_RESERVATION_SUCCESS =
  "VERIFY_SPACES_BY_RESERVATION_SUCCESS";
export const VERIFY_SPACES_BY_RESERVATION_ERROR =
  "VERIFY_SPACES_BY_RESERVATION_ERROR";
export const INITIAL_STATE_VERIFY_SPACES_BY_RESERVATION =
  "INITIAL_STATE_VERIFY_SPACES_BY_RESERVATION";

function requestSpacesByReservation() {
  return {
    type: REQUEST_SPACES_BY_RESERVATION,
  };
}

function verifySpacesByReservationSuccess(json) {
  return {
    type: VERIFY_SPACES_BY_RESERVATION_SUCCESS,
    message: json.data,
  };
}

function verifySpacesByReservationError(json) {
  return {
    type: VERIFY_SPACES_BY_RESERVATION_ERROR,
    error: json.data,
  };
}

export function initialStateVerifySpacesByReservation() {
  return {
    type: INITIAL_STATE_VERIFY_SPACES_BY_RESERVATION,
  };
}

export function verifySpacesByReservation(reservation_id) {
  return (dispatch) => {
    dispatch(requestSpacesByReservation());
    return ReservationService.verifySpacesByReservation(reservation_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(verifySpacesByReservationSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(verifySpacesByReservation(reservation_id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(verifySpacesByReservationError(json.data));
        }
      }
    );
  };
}
