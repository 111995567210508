import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ContractRateService from "../../../../services/rates/ContractRateService";

export const REQUEST_INACTIVE_CONTRACT_RATE = "REQUEST_INACTIVE_CONTRACT_RATE";
export const INACTIVE_CONTRACT_RATE_SUCCESS = "INACTIVE_CONTRACT_RATE_SUCCESS";
export const INACTIVE_CONTRACT_RATE_ERROR = "INACTIVE_CONTRACT_RATE_ERROR";

function requestInactiveContractRate() {
  return {
    type: REQUEST_INACTIVE_CONTRACT_RATE,
  };
}

function inactiveContractRateSuccess(json) {
  return {
    type: INACTIVE_CONTRACT_RATE_SUCCESS,
    message: json.data,
  };
}

function inactiveContractRateError(json) {
  return {
    type: INACTIVE_CONTRACT_RATE_ERROR,
    error: json.data,
  };
}

export function inactiveContractRate(contractRate_id, payload) {
  return (dispatch) => {
    dispatch(requestInactiveContractRate());
    return ContractRateService.inactiveContractRate(contractRate_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(inactiveContractRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(inactiveContractRate(contractRate_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(inactiveContractRateError(json.data));
      }
    });
  };
}
