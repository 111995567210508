import {
  REQUEST_FETCH_ROUTES,
  FETCH_ROUTES_SUCCESS,
  FETCH_ROUTES_ERROR,
} from "../../../actions/catalogs/route/fetchRoutes";

import {
  INITIAL_STATE_ROUTE,
  REQUEST_FETCH_ROUTE,
  FETCH_ROUTE_SUCCESS,
  FETCH_ROUTE_ERROR,
} from "../../../actions/catalogs/route/fetchRoute";

import {
  REQUEST_CREATE_ROUTE,
  CREATE_ROUTE_SUCCESS,
  CREATE_ROUTE_ERROR,
} from "../../../actions/catalogs/route/createRoute";

import {
  REQUEST_UPDATE_ROUTE,
  UPDATE_ROUTE_SUCCESS,
  UPDATE_ROUTE_ERROR,
} from "../../../actions/catalogs/route/updateRoute";

import {
  REQUEST_DELETE_ROUTE,
  DELETE_ROUTE_SUCCESS,
  DELETE_ROUTE_ERROR,
} from "../../../actions/catalogs/route/deleteRoute";

const initialState = {
  routes: [],
  route: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const routeReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_ROUTE: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_ROUTES: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_ROUTES_SUCCESS: {
      return {
        ...state,
        routes: action.routes,
        route: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_ROUTES_ERROR: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_ROUTE: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_ROUTE_SUCCESS: {
      return {
        ...state,
        routes: [],
        route: action.route,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_ROUTE_ERROR: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_ROUTE: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_ROUTE_SUCCESS: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_ROUTE_ERROR: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_ROUTE: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_ROUTE_SUCCESS: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_ROUTE_ERROR: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_ROUTE: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_ROUTE_SUCCESS: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_ROUTE_ERROR: {
      return {
        ...state,
        routes: [],
        route: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default routeReducer;
