import {
  REQUEST_FETCH_AVAILABLE_PASSENGERS,
  FETCH_AVAILABLE_PASSENGERS_SUCCESS,
  FETCH_AVAILABLE_PASSENGERS_ERROR,
  INITIAL_STATE_AVAILABLE_PASSENGERS,
} from "../../../actions/reservations/changeCabin/fetchAvailablePassengers";

const initialState = {
  passengers: [],
  fetching: false,
  message: "",
  error: "",
};

const availablePassengerReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_AVAILABLE_PASSENGERS: {
      return {
        ...state,
        passengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_AVAILABLE_PASSENGERS: {
      return {
        ...state,
        passengers: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_AVAILABLE_PASSENGERS_SUCCESS: {
      return {
        ...state,
        passengers: action.passengers,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_AVAILABLE_PASSENGERS_ERROR: {
      return {
        ...state,
        passengers: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default availablePassengerReducer;
