import {
    REQUEST_FETCH_IKARUS_RATE_CABINS,
    FETCH_IKARUS_RATE_CABINS_SUCCESS,
    FETCH_IKARUS_RATE_CABINS_ERROR,
  } from "../../../actions/rates/ikarusRatesCabin/fetchIkarusRatesCabin";
  
  import {
    REQUEST_CREATE_IKARUS_RATE_CABIN,
    CREATE_IKARUS_RATE_CABIN_SUCCESS,
    CREATE_IKARUS_RATE_CABIN_ERROR,
  } from "../../../actions/rates/ikarusRatesCabin/createIkarusRatesCabin";
  
  import {
    REQUEST_DELETE_IKARUS_RATE_CABIN,
    DELETE_IKARUS_RATE_CABIN_SUCCESS,
    DELETE_IKARUS_RATE_CABIN_ERROR,
    INITIAL_STATE_IKARUS_RATE_CABIN,
  } from "../../../actions/rates/ikarusRatesCabin/deleteIkarusRatesCabin";
  
  const initialState = {
    ikarusRateCabins: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  };
  
  const ikarusRateCabinReducer = (state = initialState, action) => {
    switch (action.type) {
      case INITIAL_STATE_IKARUS_RATE_CABIN: {
        return {
          ...state,
          ikarusRateCabins: [],
          ikarusRateCabin: {},
          processing: false,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case REQUEST_FETCH_IKARUS_RATE_CABINS: {
        return {
          ...state,
          ikarusRateCabins: [],
          ikarusRateCabin: {},
          processing: false,
          fetching: true,
          message: "",
          error: "",
        };
      }
      case FETCH_IKARUS_RATE_CABINS_SUCCESS: {
        return {
          ...state,
          ikarusRateCabins: action.ikarusRateCabins,
          ikarusRateCabin: {},
          processing: false,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case FETCH_IKARUS_RATE_CABINS_ERROR: {
        return {
          ...state,
          ikarusRateCabins: [],
          ikarusRateCabin: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      case REQUEST_CREATE_IKARUS_RATE_CABIN: {
        return {
          ...state,
          ikarusRateCabins: [],
          ikarusRateCabin: {},
          processing: true,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case CREATE_IKARUS_RATE_CABIN_SUCCESS: {
        return {
          ...state,
          ikarusRateCabins: [],
          ikarusRateCabin: {},
          processing: false,
          fetching: false,
          message: action.message,
          error: "",
        };
      }
      case CREATE_IKARUS_RATE_CABIN_ERROR: {
        return {
          ...state,
          ikarusRateCabins: [],
          ikarusRateCabin: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      case REQUEST_DELETE_IKARUS_RATE_CABIN: {
        return {
          ...state,
          ikarusRateCabins: [],
          ikarusRateCabin: {},
          processing: true,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case DELETE_IKARUS_RATE_CABIN_SUCCESS: {
        return {
          ...state,
          ikarusRateCabins: [],
          ikarusRateCabin: {},
          processing: false,
          fetching: false,
          message: action.message,
          error: "",
        };
      }
      case DELETE_IKARUS_RATE_CABIN_ERROR: {
        return {
          ...state,
          ikarusRateCabins: [],
          ikarusRateCabin: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default ikarusRateCabinReducer;
  