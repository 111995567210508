import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
import {
  fetchAvailablePassengers,
  initialStateAvailablePassengers,
} from "../../../../redux/actions/reservations/changeCabin/fetchAvailablePassengers";
import {
  fetchCruise,
  initialStateCruise,
} from "../../../../redux/actions/reservations/cruise/fetchCruise";
import {
  fetchBooking,
  initialStateBooking,
} from "../../../../redux/actions/reservations/booking/fetchBooking";
import {
  updateTwoPassengers,
  initialStateUpdatePassenger,
} from "../../../../redux/actions/reservations/changeCabin/updateTwoPassengers";
// import {
//   fetchBookingPassenger,
//   initialStateBookingPassenger,
// } from "../../../../redux/actions/reservations/bookingPassenger/fetchBookingPassenger"; //PONER SPACE_PASSENGER
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { Link, useParams, useHistory } from "react-router-dom";
import { Button, Modal } from "semantic-ui-react";
import HeadDetail from "../../../../components/headDetail";

export default function AvailablePassengerList() {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  let { cruise_id } = useParams();
  let { reservation_id } = useParams();
  let { booking_id } = useParams();
  let { passenger_id } = useParams();
  const cruiseState = useSelector((state) => state.cruiseReducer);
  const bookingState = useSelector((state) => state.bookingReducer);
  const availablePassengerState = useSelector(
    (state) => state.availablePassengerReducer
  );
  const bookingPassengerState = useSelector(
    (state) => state.bookingPassengerReducer
  );
  const updatePassengerState = useSelector(
    (state) => state.updatePassengerReducer
  );
  let data = { passenger_id_one: passenger_id, booking_id_one: booking_id };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCruise(cruise_id));
    dispatch(
      fetchAvailablePassengers(
        cruise_id,
        reservation_id,
        booking_id,
        passenger_id
      )
    );
    dispatch(fetchBooking(booking_id));
    // dispatch(fetchBookingPassenger(passenger_id, booking_id, cruise_id));
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateCruise());
      dispatch(initialStateBooking());
      dispatch(initialStateAvailablePassengers());
      // dispatch(initialStateBookingPassenger());
      dispatch(initialStateUpdatePassenger());
    };
  }, [dispatch, cruise_id, reservation_id, booking_id, passenger_id]);

  if (
    availablePassengerState.fetching ||
    bookingPassengerState.fetching ||
    cruiseState.fetching ||
    bookingState.fetching ||
    updatePassengerState.fetching
  ) {
    return <CircularProgress />;
  }

  if (availablePassengerState.processing) {
    return <CircularProgress />;
  }

  if (cruiseState.error) {
    return <div>Error: {cruiseState.error}</div>;
  }
  if (bookingState.error) {
    return <div>Error: {bookingState.error}</div>;
  }
  if (bookingPassengerState.error) {
    return <div>Error: {bookingPassengerState.error}</div>;
  }
  if (availablePassengerState.error) {
    return <div>Error: {availablePassengerState.error}</div>;
  }

  const handleShowMessage = (newState) => {
    setState({ open: true, ...newState });

    setTimeout(() => {
      setState({ ...state, open: false });
    }, 5000);
  };

  const handleChangeCabin = (passengerId, bookingId) => {
    data = {
      ...data,
      passenger_id_two: passengerId,
      booking_id_two: bookingId,
      cruise_id: cruise_id,
    };

    dispatch(updateTwoPassengers(data));
  };

  if (updatePassengerState.message === "updated succesfully") {
    history.go(-1);
  }

  if (updatePassengerState.message === "data changed") {
    if (open === false) {
      history.go(-1);
    }
  }
  return (
    <>
      {updatePassengerState.message === "data changed" ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert severity="error" color="error" variant="filled">
            <p>
              La información del pasajero seleccionado ha cambiado, se le
              redirigirá a la página anterior auntomaticamente, espere un
              momento.
            </p>
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{cruiseState.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">
          <Link to={`/cruises/${cruise_id}/reservations`}>Reservaciones</Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">
          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings`}
          >
            Bookings
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">
          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/passengers`}
          >
            Pasajeros
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">
          {bookingPassengerState.bookingPassenger.first_name}{" "}
          {bookingPassengerState.bookingPassenger.last_name}
        </div>
      </div>
      <br />
      <br />
      <br />

      <HeadDetail
        yacht_name={cruiseState.cruise.yacht_name}
        itinerary_name={cruiseState.cruise.itinerary_name}
        start_date={cruiseState.cruise.start_date_format}
        end_date={cruiseState.cruise.end_date_format}
      />
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Cabina {bookingState.booking.cabin_name}
          </Typography>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Pasajero</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Sencilla</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                {bookingPassengerState.bookingPassenger.last_name}{" "}
                {bookingPassengerState.bookingPassenger.first_name}
              </TableCell>
              <TableCell>
                {bookingPassengerState.bookingPassenger.cruise_type}
              </TableCell>
              <TableCell>
                {bookingPassengerState.bookingPassenger.single_bed === 1
                  ? "SI"
                  : "NO"}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <br />
      <br />
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Pasajeros disponibles
          </Typography>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Cabina</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Sencilla</TableCell>
              <TableCell>Pasajero</TableCell>
              <TableCell align="center">Cambiar pasajero</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availablePassengerState.passengers.map((passenger, index) => (
              <TableRow key={index}>
                <TableCell>{passenger.cabin_name}</TableCell>
                <TableCell>{passenger.cruise_type}</TableCell>
                <TableCell>
                  {passenger.single_bed === 1
                    ? "SI"
                    : passenger.single_bed === "No asignado"
                    ? "No asigando"
                    : "NO"}
                </TableCell>
                <TableCell>
                  {passenger.last_name} {passenger.first_name}
                </TableCell>
                <TableCell align="center">
                  <Modal
                    trigger={
                      <Button className="ui primary button " size="tiny">
                        Cambiar
                      </Button>
                    }
                    content={`¿Quieres cambiar con el pasajero ${passenger.first_name} ${passenger.last_name}?`}
                    actions={[
                      "Cancelar",
                      {
                        key: "cambiar",
                        content: "Cambiar",
                        type: "submit",
                        className: "ui primary button",
                        onClick: () => {
                          handleChangeCabin(
                            passenger.passenger_id,
                            passenger.id
                          );
                          handleShowMessage({
                            vertical: "top",
                            horizontal: "right",
                          });
                        },
                      },
                    ]}
                    size="tiny"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
