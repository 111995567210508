import {
  REQUEST_UPDATE_TWO_CABINS,
  UPDATE_TWO_CABINS_SUCCESS,
  UPDATE_TWO_CABINS_ERROR,
} from "../../../actions/reservations/changeCabin/updateTwoCabins";

import {
  REQUEST_UPDATE_ONE_CABIN,
  UPDATE_ONE_CABIN_SUCCESS,
  UPDATE_ONE_CABIN_ERROR,
  INITIAL_STATE_UPDATE_CABIN,
} from "../../../actions/reservations/changeCabin/updateOneCabin";

const initialState = {
  fetching: false,
  message: "",
  error: "",
};

const updateCabinReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_UPDATE_CABIN: {
      return {
        ...state,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_UPDATE_TWO_CABINS: {
      return {
        ...state,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case UPDATE_TWO_CABINS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_TWO_CABINS_ERROR: {
      return {
        ...state,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_ONE_CABIN: {
      return {
        ...state,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case UPDATE_ONE_CABIN_SUCCESS: {
      return {
        ...state,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_ONE_CABIN_ERROR: {
      return {
        ...state,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default updateCabinReducer;
