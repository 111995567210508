import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Feed } from "semantic-ui-react";

const TitlesLogs = ({ log }) => {
    const { t } = useTranslation();
    return (
        <Feed.Summary style={{ display: "flex", gap: "5px" }}>
            <p style={{ fontWeight: "initial" }}>{t(log.action)}</p>
            {
                log.action.includes("reservation") ? <p>{log.reservation}</p> :
                log.action.includes("booking") ? <p>{log.booking}</p> :
                log.action.includes("spaceFlight") ? <p>{log.spaceFlight}</p> :
                log.action.includes("passenger_create_logs") ? <p>{"-" + " " + log.dataAfter?.passenger}</p> :
                log.action.includes("passenger_update_logs") ? <p>{"-" + " " + log.dataAfter?.passenger}</p> :
                log.action.includes("passenger_updateName_logs") ? 
                <div style={{ display: "flex", gap: "5px" }}>
                    <p>{"-" + " " + log.dataBefore?.passenger}</p>
                    <p style={{ fontWeight: "initial" }}>{t("passenger_a")}</p>
                    <p>{log.dataAfter?.passenger}</p></div> :
                log.action.includes("spacePassenger_create_logs") ? 
                    <div style={{ display: "flex", gap: "5px" }}>
                        <p>{log.dataAfter?.passenger}</p>
                        <p style={{ fontWeight: "initial" }}>{t("spacePssenger_to_space")}</p>
                        <p>{log.space}</p>
                    </div> :
                log.action.includes("spacePassenger_delete_logs") ? 
                    <div style={{ display: "flex", gap: "5px" }}>
                        <p>{log.dataBefore?.passenger}</p>
                        <p style={{ fontWeight: "initial" }}>{t("spacePssenger_from_space")}</p>
                        <p>{log.space}</p></div> :
                log.action.includes("cruiseLanguage_assing_logs") ? 
                <div style={{ display: "flex", gap: "5px" }}>
                    <p>{log.dataAfter?.name}</p>
                    <p style={{ fontWeight: "initial" }}>{t("cruiseLanguage_to")}</p>
                    <p>{log.cruise}</p>
                </div> :
                 log.action.includes("cruiseLanguage_update_logs") ? 
                 <div style={{ display: "flex", gap: "5px" }}>
                     <p>{log.dataBefore?.name}</p>
                     <p style={{ fontWeight: "initial" }}>{t("cruiseLanguage_a")}</p>
                     <p>{log.dataAfter?.name}</p></div> :
                 log.action.includes("cruiseLanguage_delete_logs") ? 
                <div style={{ display: "flex", gap: "5px" }}>
                    <p>{log.dataBefore?.name}</p>
                    <p style={{ fontWeight: "initial" }}>{t("cruiseLanguage_from")}</p>
                    <p>{log.cruise}</p></div> :
                log.action.includes("guideOnBoard_assing_logs") ? 
                <div style={{ display: "flex", gap: "5px" }}>
                    <p>{log.dataAfter?.guide}</p>
                    <p style={{ fontWeight: "initial" }}>{t("guideOnBoard_to")}</p>
                    <p>{log.cruise}</p></div> :
                 log.action.includes("guideOnBoard_update_logs") ? 
                 <div style={{ display: "flex", gap: "5px" }}>
                     <p>{log.dataAfter?.guide}</p>
                </div> :
                log.action.includes("guideOnBoard_updateGuide_logs") ? 
                <div style={{ display: "flex", gap: "5px" }}>
                    <p>{"-" + " " + log.dataBefore?.guide}</p>
                    <p style={{ fontWeight: "initial" }}>{t("guideOnBoard_a")}</p>
                    <p>{log.dataAfter?.guide}</p></div> :
                 log.action.includes("guideOnBoard_delete_logs") ? 
                <div style={{ display: "flex", gap: "5px" }}>
                    <p>{log.dataBefore?.guide}</p>
                    <p style={{ fontWeight: "initial" }}>{t("guideOnBoard_from")}</p>
                    <p>{log.cruise}</p></div> :
                log.action.includes("space") ? <p>{log.space}</p> :
                log.action.includes("cruise") ? <p>{log.cruise}</p> : ""
            }
        </Feed.Summary>
    );
};
export default TitlesLogs;