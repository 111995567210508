import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CountryService from "../../../../services/catalogs/CountryService";

export const REQUEST_CREATE_COUNTRY = "REQUEST_CREATE_COUNTRY";
export const CREATE_COUNTRY_SUCCESS = "CREATE_COUNTRY_SUCCESS";
export const CREATE_COUNTRY_ERROR = "CREATE_COUNTRY_ERROR";

function requestCreateCountry() {
  return {
    type: REQUEST_CREATE_COUNTRY,
  };
}

function createCountrySuccess(json) {
  return {
    type: CREATE_COUNTRY_SUCCESS,
    message: json.data,
  };
}

function createCountryError(json) {
  return {
    type: CREATE_COUNTRY_ERROR,
    error: json.data,
  };
}

export function createCountry(payload) {
  return (dispatch) => {
    dispatch(requestCreateCountry());
    return CountryService.postCountry(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createCountrySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createCountry(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createCountryError(json.data));
      }
    });
  };
}
