import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import BerthService from "../../../../services/catalogs/BerthService";

export const REQUEST_FETCH_BERTH = "REQUEST_FETCH_BERTH";
export const FETCH_BERTH_SUCCESS = "FETCH_BERTH_SUCCESS";
export const FETCH_BERTH_ERROR = "FETCH_BERTH_ERROR";
export const INITIAL_STATE_BERTH = "INITIAL_STATE_BERTH";

function requestFetchBerth() {
  return {
    type: REQUEST_FETCH_BERTH,
  };
}

function fetchBerthSuccess(json) {
  return {
    type: FETCH_BERTH_SUCCESS,
    berth: json.data,
  };
}

function fetchBerthError(json) {
  return {
    type: FETCH_BERTH_ERROR,
    error: json.data,
  };
}

export function initialStateBerth() {
  return {
    type: INITIAL_STATE_BERTH,
  };
}

export function fetchBerth(berth_id) {
  return (dispatch) => {
    dispatch(requestFetchBerth());
    return BerthService.getBerth(berth_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchBerthSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchBerth(berth_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchBerthError(json.data));
      }
    });
  };
}
