import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customTicketForm = createSlice({
  name: 'customTicketForm',
  initialState: {
    agencies: [],
    ticket: {},
    airlines: [],
    routes: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateTicketFormCustom(state, action) {
      return {
        agencies: [],
        ticket: {},
        airlines: [],
        routes: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesTicketFormCustom(state, action) {
      return {
        agencies: [],
        ticket: {},
        airlines: [],
        routes: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchTicketFormCustomSuccess(state, action) {
      return {
        agencies: action.payload.agencies,
        ticket: action.payload.ticket,
        airlines: action.payload.airlines,
        routes: action.payload.routes,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchTicketFormCustomError(state, action) {
      return {
        agencies: [],
        ticket: {},
        airlines: [],
        routes: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchTicketFormCustom = (searchWord, ticket_id) => dispatch => {
  dispatch(requesTicketFormCustom());
  axios.get(`/tickets/form/ticketForm?searchWord=${searchWord}&&ticket_id=${ticket_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchTicketFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchTicketFormCustom(ticket_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchTicketFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateTicketFormCustom,
  requesTicketFormCustom,
  fetchTicketFormCustomSuccess,
  fetchTicketFormCustomError,
} = customTicketForm.actions;

export default customTicketForm.reducer;