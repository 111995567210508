import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import AgencyService from "../../../../services/catalogs/AgencyService";

export const REQUEST_FETCH_AGENCY = "REQUEST_FETCH_AGENCY";
export const FETCH_AGENCY_SUCCESS = "FETCH_AGENCY_SUCCESS";
export const FETCH_AGENCY_ERROR = "FETCH_AGENCY_ERROR";
export const INITIAL_STATE_AGENCY = "INITIAL_STATE_AGENCY";

function requestFetchAgency() {
  return {
    type: REQUEST_FETCH_AGENCY,
  };
}

function fetchAgencySuccess(json) {
  return {
    type: FETCH_AGENCY_SUCCESS,
    agency: json.data,
  };
}

function fetchAgencyError(json) {
  return {
    type: FETCH_AGENCY_ERROR,
    error: json.data,
  };
}

export function initialStateAgency() {
  return {
    type: INITIAL_STATE_AGENCY,
  };
}

export function fetchAgency(agency_id) {
  return (dispatch) => {
    dispatch(requestFetchAgency());
    return AgencyService.getAgency(agency_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchAgencySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchAgency(agency_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchAgencyError(json.data));
      }
    });
  };
}
