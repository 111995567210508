import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import VoucherPassenegrService from "../../../../services/reservations/VoucherPassenegrService";

export const REQUEST_FETCH_VOUCHER_PASSENGERS =
  "REQUEST_FETCH_VOUCHER_PASSENGERS";
export const FETCH_VOUCHER_PASSENGERS_SUCCESS =
  "FETCH_VOUCHER_PASSENGERS_SUCCESS";
export const FETCH_VOUCHER_PASSENGERS_ERROR = "FETCH_VOUCHER_PASSENGERS_ERROR";
export const INITIAL_STATE_VOUCHER_PASSENGER =
  "INITIAL_STATE_VOUCHER_PASSENGER";

function requestFetchVaucherPassengers() {
  return {
    type: REQUEST_FETCH_VOUCHER_PASSENGERS,
  };
}

function fetchVoucherPassengersSuccess(json) {
  return {
    type: FETCH_VOUCHER_PASSENGERS_SUCCESS,
    voucherPassengers: json.data,
  };
}

function fetchVoucherPassengersError(json) {
  return {
    type: FETCH_VOUCHER_PASSENGERS_ERROR,
    error: json.data,
  };
}

export function initialStateVoucherPassenger() {
  return {
    type: INITIAL_STATE_VOUCHER_PASSENGER,
  };
}

export function fetchVoucherPassengers(voucher_id) {
  return (dispatch) => {
    dispatch(requestFetchVaucherPassengers());
    return VoucherPassenegrService.getVoucherPassengers(voucher_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchVoucherPassengersSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchVoucherPassengers(voucher_id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchVoucherPassengersError(json.data));
        }
      }
    );
  };
}
