import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createAirline } from "../../../redux/actions/catalogs/airline/createAirline";
import { fetchAirline, initialStateAirline } from "../../../redux/actions/catalogs/airline/fetchAirline";
import { updateAirline } from "../../../redux/actions/catalogs/airline/updateAirline";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function AirlineForm() {
  const { t } = useTranslation();
  const history = useHistory();
  const { airline_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const airlineState = useSelector((state) => state.airlineReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (airline_id) {
      dispatch(fetchAirline(airline_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateAirline());
    };
  }, []);

  if (airlineState.fetching) {
    return <Loader active inline />;
  }

  if (airlineState.error) {
    return <Message negative
      header='Error!'
      content={t("server_error_form")}
    />
  }

  const onSubmit = (data) => {
    if (airline_id) {
      dispatch(updateAirline(airline_id, data));
    } else {
      dispatch(createAirline(data));
    }
  };

  if (airlineState.message === "resource created successfully" || airlineState.message === "resource updated successfully") {
    history.push("/airlines");
  }

  return (
    <>
      <Header as='h3' dividing>{t("airline_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("airline_name")}</label>
            <input name="name" defaultValue={airlineState.airline.name} placeholder={t("airline_name")} maxLength={100} ref={register({ required: true })} autoFocus />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("active_tag")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" defaultChecked={airlineState.airline.active === 1} ref={register}/>
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider />
        
        <Button primary type="submit" loading={airlineState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/airlines"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
