import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class HistoricalCruiseService {
  static getHistoricalYears(startYear) {
    return axios.get(
      "historicalCruises/" + startYear + "/historicalYear"
    );
  }
  static getHistoricalCruises(cruiseCode, cruiseYear) {
    var url = "historicalCruises";
    if (cruiseCode !== null) {
      url = url + "?cruiseCode=" + cruiseCode;
    } else if (cruiseYear !== null) {
      url = url + `?cruiseYear=${cruiseYear} `;
    }
    return axios.get(url);
  }
}
