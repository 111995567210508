import VoucherService from "../../../../services/reservations/VoucherService";

export const REQUEST_FETCH_VOUCHERS = "REQUEST_FETCH_VOUCHERS";
export const FETCH_VOUCHERS_SUCCESS = "FETCH_VOUCHERS_SUCCESS";
export const FETCH_VOUCHERS_ERROR = "FETCH_VOUCHERS_ERROR";

function requestFetchVauchers() {
  return {
    type: REQUEST_FETCH_VOUCHERS,
  };
}

function fetchVouchersSuccess(json) {
  return {
    type: FETCH_VOUCHERS_SUCCESS,
    vouchers: json.data,
  };
}

function fetchVouchersError(json) {
  return {
    type: FETCH_VOUCHERS_ERROR,
    error: json.data,
  };
}

export function fetchVouchers(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchVauchers());
    return VoucherService.getVouchers(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchVouchersSuccess(json.data));
      } else {
        dispatch(fetchVouchersError(json.data));
      }
    });
  };
}
