import {
  INITIAL_STATE_ADVANCE_CRUISE,
  REQUEST_FETCH_ADVANCE_CRUISES,
  FETCH_ADVANCE_CRUISES_SUCCESS,
  FETCH_ADVANCE_CRUISES_ERROR,
} from "../../../actions/reservations/advanceCruise/fetchAdvanceCruises";

const initialState = {
  cruises: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const AdvanceCruiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_ADVANCE_CRUISE: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_ADVANCE_CRUISES: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_ADVANCE_CRUISES_SUCCESS: {
      return {
        ...state,
        cruises: action.cruises,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_ADVANCE_CRUISES_ERROR: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default AdvanceCruiseReducer;
