import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import DayService from "../../../../services/catalogs/DayService";

export const REQUEST_UPDATE_DAY = "REQUEST_UPDATE_DAY";
export const UPDATE_DAY_SUCCESS = "UPDATE_DAY_SUCCESS";
export const UPDATE_DAY_ERROR = "UPDATE_DAY_ERROR";

function requestUpdateDay() {
  return {
    type: REQUEST_UPDATE_DAY,
  };
}

function updateDaySuccess(json) {
  return {
    type: UPDATE_DAY_SUCCESS,
    message: json.data,
  };
}

function updateDayError(json) {
  return {
    type: UPDATE_DAY_ERROR,
    error: json.data,
  };
}

export function updateDay(day_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateDay());
    return DayService.putDay(day_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateDaySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateDay(day_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateDayError(json.data));
      }
    });
  };
}
