import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import TicketService from "../../../../services/rates/TicketService";

export const REQUEST_FETCH_TICKET = "REQUEST_FETCH_TICKET";
export const FETCH_TICKET_SUCCESS = "FETCH_TICKET_SUCCESS";
export const FETCH_TICKET_ERROR = "FETCH_TICKET_ERROR";
export const INITIAL_STATE_TICKET = "INITIAL_STATE_TICKET";

function requestFetchTicket() {
  return {
    type: REQUEST_FETCH_TICKET,
  };
}

function fetchTicketSuccess(json) {
  return {
    type: FETCH_TICKET_SUCCESS,
    ticket: json.data,
  };
}

function fetchTicketError(json) {
  return {
    type: FETCH_TICKET_ERROR,
    error: json.data,
  };
}

export function initialStateTicket() {
  return {
    type: INITIAL_STATE_TICKET,
  };
}

export function fetchTicket(ticket_id) {
  return (dispatch) => {
    dispatch(requestFetchTicket());
    return TicketService.getTicket(ticket_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchTicketSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchTicket(ticket_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchTicketError(json.data));
      }
    });
  };
}
