import CabinBerthList from "../containers/catalogs/cabinBerth/CabinBerthList";
import CabinBerthForm from "../containers/catalogs/cabinBerth/CabinBerthForm";

const routes = [
  {
    path: "/cabins/:cabin_id/berths",
    component: CabinBerthList,
  },
  {
    path: "/cabins/:cabin_id/berths_new",
    component: CabinBerthForm,
  },
  {
    path: "/cabins/:cabin_id/berths/:id",
    component: CabinBerthForm,
  },
];

export { routes as cabinBerthRoutes };
