import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
//UI
import { Dropdown, Card, Segment, Image, Table, Header, Divider, Menu, Icon, Form, Button, Accordion, Loader,} from "semantic-ui-react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";
//ACTIONS
import { fetchCruiseLogs } from "../../../../redux/actions/reservations/cruise/fetchCruiseLogs";
import { updateCruiseNote } from "../../../../redux/actions/reservations/cruise/updateCruiseNote";
import { fetchOrderService, initialStateOrderService, } from "../../../../redux/actions/reservations/orderService/fetchOrderService";
import { fetchPassengersZarpe, initialStatePassengerZarpe, } from "../../../../redux/actions/reservations/passenger/fetchPassengersZarpe";
import { fetchCruiseDetailsCustom, initialStateCruiseDetailsCustom } from "../../../../redux/slices/custom/reservations/cruise/cruiseDetails/customCruiseDetails.slice";
//RESOURCES
import CruiseTableResumenDivided from "../../../../components/cruise/cruiseTableResumenDivided";
import CruiseTableResumenNotDivided from "../../../../components/cruise/cruiseTableResumenNotDivided";
import CruiseTableSalesReport from "../../../../components/cruise/cruiseTableSalesReport";
import childIcon from "../../../icons/childIcon.png";
import manIcon from "../../../icons/manIcon.png";
import womanIcon from "../../../icons/womanIcon.png";
import orderServicePDF from "../../orderService/OrderServicePDF";
import generateZarpeReport from "../zarpe/zarpePDF";
import rwittmerLogo from "../../../../containers/icons/rwittmerLogo.png";
import LogsHistory from "../../../../components/reservation/logsHistory";
import single_bed from "../../../icons/single_bed.png";
import double_bed from "../../../icons/double_bed.png";
import bunk from "../../../icons/bunk.png";
import "../../../styles/common.css";

export default function CruiseDetailList() {
  let history = useHistory();
  let { cruise_id } = useParams();
  const { t } = useTranslation();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const { register, errors, handleSubmit } = useForm();
  const [openHistory, setOpenHistory] = useState(false);
  const [cruiseType, setCruiseType] = useState("");
  const customCruiseState = useSelector(state => state.customCruiseDetails);
  const cruiseState = useSelector(state => state.cruiseReducer);
  const cruiseLogState = useSelector((state) => state.cruiseLogReducer);
  const orderServiceState = useSelector((state) => state.orderServiceReducer);
  const passengersZarpeState = useSelector((state) => state.passengerZarpeReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCruiseDetailsCustom(cruise_id));
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateCruiseDetailsCustom());
    };
  }, [dispatch, cruise_id]);

  if (customCruiseState.fetching) {
    return <CircularProgress />;
  }

  if (customCruiseState.error) {
    return <div>Error : {customCruiseState.error}</div>;
  }

  const handleReservation = () => {
    history.push(`/cruises/${cruise_id}/reservations`);
  };
  const handleEdit = () => {
    history.push(`/cruises/${cruise_id}`);
  };
  const handlePassengers = () => {
    history.push(`/cruises/${cruise_id}/passengers`);
  };

  const handleIngala = () => {
    history.push(`/cruises/${cruise_id}/ingalaReport`);
  };

  const handleGpsTax = () => {
    history.push(`/cruises/${cruise_id}/gpsTaxReport`);
  };

  const handleOrderService = () => {
    dispatch(fetchOrderService(cruise_id));
  };

  const handleVoucher = () => {
    history.push(`/cruises/${cruise_id}/vouchers`);
  };

  const handleFlights = () => {
    history.push(`/cruises/${cruise_id}/flightListPassengersReport`);
  };

  const onSubmit = (data) => {
    dispatch(updateCruiseNote(cruise_id, data));
  };

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });

    setTimeout(() => {
      setState({ ...state, open: false });
    }, 4000);
  };

  const handleGetPassengersTotal = () => {
    dispatch(fetchPassengersZarpe(cruise_id));
  };

  let filterPassengers = async (passengers, cruiseType) => {
    if (cruiseType === "TO") {
      return await passengers.filter(
        (passenger) => passenger.cruise_type === "TO"
      );
    } else if (cruiseType === "PP") {
      return await passengers.filter(
        (passenger) => passenger.cruise_type !== "SP"
      );
    } else if (cruiseType === "SP") {
      return await passengers.filter(
        (passenger) => passenger.cruise_type !== "PP"
      );
    }
  };

  if (orderServiceState.message === "Order service send successfully") {
    orderServicePDF(orderServiceState.orderService);
    dispatch(initialStateOrderService());
  }

  if (passengersZarpeState.message === "resource found succesfully") {
    filterPassengers(passengersZarpeState.passengers, cruiseType).then(
      (value) => {
        generateZarpeReport(value, customCruiseState.cruise);
        dispatch(initialStatePassengerZarpe());
      }
    );
  }

  const handleHistoyClick = () => {
    setOpenHistory(!openHistory);
    if (!openHistory ) {
     dispatch(fetchCruiseLogs(cruise_id));
    }
  };

  return (
    <>
      {cruiseState.message === "notes updated successfully" ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert severity="success" color="success" variant="filled">
            {t("cruise_detail_upadate")}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <div className="ui breadcrumb">
        <Link to="/" className="section">
          {t("cruise_tiptop")}
        </Link>
        <div className="divider">/</div>

        <div className="active section">{customCruiseState.cruise.code}</div>
      </div>
      <br></br>
      <br></br>
      <Menu compact floated="right">
        <Menu.Item
          style={{ backgroundColor: "#46D6DB" }}
          name="reservas"
          as="a"
          onClick={() => handleReservation()}
        >
          <Icon name="calendar alternate " />
          {t("cruise_detail_reservations")}
        </Menu.Item>
        <Menu.Item name="editar" as="a" onClick={() => handleEdit()}>
          <Icon name="pencil alternate" />
          {t("cruise_detail_edit")}
        </Menu.Item>
        <Menu.Item name="passengers" as="a" onClick={() => handlePassengers()}>
          <Icon name="user " />
          {t("cruise_detail_passengers")}
        </Menu.Item>
        <Menu.Item name="ingala" as="a" onClick={() => handleIngala()}>
          <Icon name="file alternate " />
          {t("cruise_detail_ingala")}
        </Menu.Item>
        <Menu.Item name="gpsTax" as="a" onClick={() => handleGpsTax()}>
          <Icon name="file alternate " />
          {t("cruise_detail_gpstax")}
        </Menu.Item>
        <Menu.Item
          name="orden_de_servicios"
          onClick={() => handleOrderService()}
        >
          <Button
            className={
              orderServiceState.fetching
                ? "loading order_button"
                : "order_button"
            }
          >
            <Icon name="file alternate " />
            {t("cruise_detail_orderServices")}
          </Button>
        </Menu.Item>
        <Menu.Item name="voucher" as="a" onClick={() => handleVoucher()}>
          <Icon name="file alternate " />
          {t("cruise_detail_voucher")}
        </Menu.Item>
        <Menu.Item name="vuelos" as="a" onClick={() => handleFlights()}>
          <Icon name="file alternate " />
          {t("cruise_detail_flights")}
        </Menu.Item>
        <Menu.Item name="zarpe" as="a">
          <Icon name="file alternate " />

          <Dropdown
            text="Zarpe"
            loading={passengersZarpeState.fetching === true ? true : false}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text="TO"
                onClick={() => {
                  handleGetPassengersTotal();
                  setCruiseType("TO");
                }}
              />
              <Dropdown.Item
                text="TO - PP"
                onClick={() => {
                  handleGetPassengersTotal();
                  setCruiseType("PP");
                }}
              />
              <Dropdown.Item
                text="TO - SP"
                onClick={() => {
                  handleGetPassengersTotal();
                  setCruiseType("SP");
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu>
      <br />
      <br />
      <br />
      <h4>{t("general_information_tag")}</h4>
      <Divider variant="middle" />
      <Card.Group itemsPerRow={3}>
        <Card className="card_header_style">
          <Card.Content>
            <Table basic="very">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <span
                      style={{
                        color: "#2185D0",
                        fontSize: "22px",
                        fontWeight: "800",
                      }}
                    >
                      {customCruiseState.cruise.code}
                    </span>
                  </Table.Cell>
                  <Table.Cell>
                    <span className="header_style">
                      {customCruiseState.yacht.name}
                    </span>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Header>
                      <span className="header_style">
                        {t("cruise_detail_name")}
                      </span>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{customCruiseState.cruise.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Header>
                      <span className="header_style">
                        {t("cruise_detail_itinerary")}
                      </span>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{customCruiseState.itinerary.name}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Header>
                      <span className="header_style">
                        {t("cruise_detail_startDate")}
                      </span>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    {customCruiseState.cruise.start_date_format}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Header>
                      <span className="header_style">
                        {t("cruise_detail_endDate")}
                      </span>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{customCruiseState.cruise.end_date_format}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Header>
                      <span className="header_style">
                        {t("cruise_detail_languages")}
                      </span>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    {customCruiseState.cruiseLanguages.map(
                      (cruiseLanguageItem) =>
                        cruiseLanguageItem.languages.length > 0
                          ? cruiseLanguageItem.languages
                              .map((language) => language.language_name)
                              .join(" / ")
                          : t("cruise_detail_notAssigned")
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Card.Content>
        </Card>
        <Card className="card_header_style">
          <Card.Content>
            <Table basic="very">
              <Table.Body>
                {customCruiseState.guidesOnBoard.map((guideOnBoardItem) =>
                  guideOnBoardItem.guides.map((guide, index) => (
                    <Table.Row key={guide.id}>
                      <Table.Cell>
                        <Header>
                          <span className="header_style">
                            {t("cruise_detail_guide")} {guide.order}
                          </span>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        {guide.first_name} {guide.last_name}
                      </Table.Cell>
                      <Table.Cell>{guide.language_name}</Table.Cell>
                      <Table.Cell>
                        {t("cruise_detail_identification")}:{" "}
                        {guide.document_number
                          ? guide.document_number
                          : t("cruise_detail_notAssigned")}
                      </Table.Cell>
                    </Table.Row>
                  ))
                )}
                <Table.Row>
                  <Table.Cell>
                    <Header>
                      <span className="header_style">
                        {t("cruise_detail_captain")}
                      </span>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    {customCruiseState.captain.first_name}{" "}
                    {customCruiseState.captain.last_name}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Header>
                      <span className="header_style">
                        {t("cruise_detail_hotelier")}
                      </span>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    {customCruiseState.hotelier.first_name}{" "}
                    {customCruiseState.hotelier.last_name}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Card.Content>
        </Card>
        <Card className="card_header_style">
          <Card.Content>
            <Table celled>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell style={{ backgroundColor: "#DBADFF" }}>
                    {t("cruise_detail_available")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("cruise_detail_reserved")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("cruise_detail_confirmed")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>{t("cruise_detail_wait")}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
                {customCruiseState.cruise.transfer_day === 0 ? (
                  <CruiseTableResumenNotDivided
                    TO={customCruiseState.cruise.TO}
                    numberSpacesUsed_TO_Confirmed={customCruiseState.cruise.numberSpacesUsed_TO_Confirmed}
                    numberSpacesUsed_TO_Reserved={customCruiseState.cruise.numberSpacesUsed_TO_Reserved}
                    numberSpacesUsed_TO_Waiting={customCruiseState.cruise.numberSpacesUsed_TO_Waiting}
                  />
                ) : (
                  <CruiseTableResumenDivided
                    TO={customCruiseState.cruise.TO}
                    numberSpacesUsed_TO_Confirmed={customCruiseState.cruise.numberSpacesUsed_TO_Confirmed}
                    numberSpacesUsed_TO_Reserved={customCruiseState.cruise.numberSpacesUsed_TO_Reserved}
                    numberSpacesUsed_TO_Waiting={customCruiseState.cruise.numberSpacesUsed_TO_Waiting}
                    PP={customCruiseState.cruise.PP}
                    numberSpacesUsed_PP_Confirmed={customCruiseState.cruise.numberSpacesUsed_PP_Confirmed}
                    numberSpacesUsed_PP_Reserved={customCruiseState.cruise.numberSpacesUsed_PP_Reserved}
                    numberSpacesUsed_PP_Waiting={customCruiseState.cruise.numberSpacesUsed_PP_Waiting}
                    SP={customCruiseState.cruise.SP}
                    numberSpacesUsed_SP_Confirmed={customCruiseState.cruise.numberSpacesUsed_SP_Confirmed}
                    numberSpacesUsed_SP_Reserved={customCruiseState.cruise.numberSpacesUsed_SP_Reserved}
                    numberSpacesUsed_SP_Waiting={customCruiseState.cruise.numberSpacesUsed_SP_Waiting}
                  />
                )}
            </Table>
            <Divider variant="middle" />
            <Table celled>
              <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell>Sales Report</Table.HeaderCell>
                    <Table.HeaderCell>
                      120 {t("cruise_detail_days")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      90 {t("cruise_detail_days")}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      60 {t("cruise_detail_days")}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

              {customCruiseState.reservations.map((reservation, index) =>
                reservation.charter === 1 ? (
                  <CruiseTableSalesReport
                    cruise_type={reservation.cruise_type}
                    sales_report_120_days={reservation.sales_report_120_days}
                    sales_report_90_days={reservation.sales_report_90_days}
                    sales_report_60_days={reservation.sales_report_60_days}
                  />
                ) : (
                  ""
                )
              )}
            </Table>
          </Card.Content>
        </Card>
      </Card.Group>

      <h4> {t("cruise_detail_notes")}</h4>
      <Divider variant="middle" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <textarea
          placeholder={t("cruise_detail_notes")}
          name="notes"
          autoFocus
          defaultValue={customCruiseState.cruise.notes}
          rows="3"
          ref={register({ required: true })}
        ></textarea>
        {errors.notes && (
          <span className="error_message">{t("required_information")}</span>
        )}
        <br></br>
        <br></br>
        <button
          type="submit"
          onClick={handleClick({ vertical: "top", horizontal: "right" })}
          className={
            customCruiseState.processing
              ? "ui loading primary button"
              : "ui primary button"
          }
        >
          {t("update_notes")}
        </button>
      </Form>
      <h4>{t("cruise_detail_cabins")}</h4>
      <Divider variant="middle" />
      <Card.Group itemsPerRow={2}>
        {customCruiseState.cabins.map((cabin, index) => (
          <Card key={index}>
            <Card.Header className="cruise_header">
              <div className="bed_display">
                <Segment inverted color="blue" textAlign="center" className="cruise_name">
                  <span>{cabin.cabin}</span>
                </Segment>
                <div className="bed_display">
                  {cabin.berths.map((cabin_icon, index) => (
                    <span key={index}>
                      {cabin_icon === "single_bed.png" ? (
                        <Image src={single_bed} className="bed_image" />
                      ) : cabin_icon === "double_bed.png" ? (
                        <Image src={double_bed} className="bed_image" />
                      ) : cabin_icon === "bunk.png" ? (
                        <Image src={bunk} className="bed_image" />
                      ) : (
                        ""
                      )}
                    </span>
                  ))}
                </div>
              </div>
              {cabin.bookings.length == 0 ? (
              <div>
                <b style={{ fontSize: "13px" }}>{t("cruise_detail_cabin_available")}</b>
                <center><Image src={rwittmerLogo} alt="rwittmerLogo"  style={{ width: "50px", height: "30px" }} /></center>
              </div>
              ) : (
                ""
              )}

              <div className="cruise_deck">
                <span className="cruise_text">{cabin.deck}</span>
              </div>
            </Card.Header>

            {cabin.bookings.length > 0 ? (
              <Card.Content>
                <Card.Group
                  itemsPerRow={4}
                  style={{
                    padding: "11px",
                  }}
                >
                  {cabin.bookings.map((booking, index) => (
                    <Card key={index}>
                      <Card.Header
                        textAlign="center"
                        style={{
                          fontSize: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {booking.cruise_type}
                      </Card.Header>
                      <Card.Content textAlign="center">
                        <p>{t(booking.state)}</p>
                        <p>
                          {booking.genderIcon === "childIcon.png" ? (
                            <Link
                              to={`/cruises/${cruise_id}/reservations/${booking.reservation_id}/bookings/${booking.booking_id}/spaces/${booking.space_id}/passengers/${booking.passenger_id}`}
                            >
                              <Image src={childIcon} size="mini" />
                            </Link>
                          ) : booking.genderIcon === "manIcon.png" ? (
                            <Link
                              to={`/cruises/${cruise_id}/reservations/${booking.reservation_id}/bookings/${booking.booking_id}/spaces/${booking.space_id}/passengers/${booking.passenger_id}`}
                            >
                              <Image src={manIcon} size="mini" />
                            </Link>
                          ) : booking.genderIcon === "womanIcon.png" ? (
                            <Link
                              to={`/cruises/${cruise_id}/reservations/${booking.reservation_id}/bookings/${booking.booking_id}/spaces/${booking.space_id}/passengers/${booking.passenger_id}`}
                            >
                              <Image src={womanIcon} size="mini" />
                            </Link>
                          ) : (
                            <span>
                              <Icon
                                name="question circle "
                                size="big"
                              />
                              <br />
                              <span>{t("cruise_detail_notAssigned")}</span>
                            </span>
                          )}
                        </p>

                        <p>{booking.name}</p>
                      </Card.Content>
                    </Card>
                  ))}
                </Card.Group>
              </Card.Content>
            ) : (
             ""
            )}
          </Card>
        ))}
      </Card.Group>
      <br />
      <Accordion styled style={{ width: "91vw" }}>
        <Accordion.Title onClick={handleHistoyClick} style={{ display: "flex", gap: "5px" }}>
          {t("log_historical")}
          {openHistory ? <ExpandLess /> : <ExpandMore />}
        </Accordion.Title>
        {cruiseLogState.fetching  ? (
          <div style={{ display: "flex", width: "91vw", height: "80px", justifyContent: "center" }}>
            <Loader size="medium" active inline>Loading...</Loader>
          </div>
        ) : (
          <Collapse in={openHistory} timeout="auto" unmountOnExit style={{ padding: "15px" }}>
            <LogsHistory
              logsHistory={cruiseLogState.logs}
            />
          </Collapse>
        )}
      </Accordion>
    </>
  );
}
