import FamilyRoomList from "../../containers/rates/familyRoom/FamilyRoomList";
import FamilyRoomForm from "../../containers/rates/familyRoom/FamilyRoomForm";

const routes = [
  {
    path: "/familyRooms",
    component: FamilyRoomList,
  },
  {
    path: "/familyRooms_new",
    component: FamilyRoomForm,
  },
  {
    path: "/familyRooms/:family_room_id",
    component: FamilyRoomForm,
  },
];

export { routes as familyRoomRoutes };
