import CruiseRateList from "../containers/reservations/cruiseRate/CruiseRateList";
import CruiseRateForm from "../containers/reservations/cruiseRate/CruiseRateForm";

const routes = [
  {
    path: "/cruises/:cruise_id/rates",
    component: CruiseRateList,
  },
  {
    path: "/cruises/:cruise_id/rates_new",
    component: CruiseRateForm,
  },
  {
    path: "/cruises/:cruise_id/rates/:id",
    component: CruiseRateForm,
  },
];

export { routes as cruiseRateRoutes };
