import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//UI
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
//ACTIONS
import { fetchPassengerInFlightListCustom, initialStatePassengerInFlightListCustom } from "../../../../redux/slices/custom/reservations/cruise/flight/customPassengerInFlightList.slice";
import { fetchPassengersSelected, initialStatePassengerSelected } from "../../../../redux/actions/reservations/passenger/fetchPassengersSelected";
//RESOURCE
import HeadDetail from "../../../../components/headDetail";
import { scrollToTop } from "../../../resources/utils";

export default function PassengerInFlightList() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { cruise_id } = useParams();
  const [active, setActive] = useState(false);
  var choices = [];
  const passengersInFlightStateCustom = useSelector((state) => state.customPassengerInFlightList);
  const passengerSelectedState = useSelector((state) => state.passengerSelectedReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPassengerInFlightListCustom(cruise_id));
    scrollToTop();
    return () => {
      dispatch(initialStatePassengerInFlightListCustom());
      dispatch(initialStatePassengerSelected());
    };
  }, [dispatch, cruise_id]);

  if (passengersInFlightStateCustom.fetching || passengersInFlightStateCustom.fetching) {
    return <CircularProgress />;
  }

  if (passengersInFlightStateCustom.error) {
    return <div>Error: {passengersInFlightStateCustom.error}</div>;
  }

  if (passengersInFlightStateCustom.error) {
    return <div>Error: {passengersInFlightStateCustom.error}</div>;
  }

  const handleCheck = async function () {
    getSelectedPassengers();
    if (choices.length > 0) {
      dispatch(
        fetchPassengersSelected(cruise_id, {
          passengers_ids: choices,
        })
      );
    } else {
      dispatch(initialStatePassengerSelected());
    }
  };

  const getSelectedPassengers = function (id) {
    var ele = document.getElementsByName("active");
    choices.length = 0;
    ele.forEach((el) => {
      if (el.checked) {
        choices.push(el.id);
      }
    });
    return choices;
  };

  return (
    <>
      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{passengersInFlightStateCustom.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">{t("passenger_flight_title")}</div>
      </div>
      <br />
      <br />
      <br />
      <HeadDetail
        yacht_name={passengersInFlightStateCustom.cruise.yacht_name}
        itinerary_name={passengersInFlightStateCustom.cruise.itinerary_name}
        start_date={passengersInFlightStateCustom.cruise.start_date_format}
        end_date={passengersInFlightStateCustom.cruise.end_date_format}
      />
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("passenger_flight_title")} {passengersInFlightStateCustom.message}
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Toolbar>
        <Divider variant="fullWidth" />
        {passengerSelectedState.fetching === true ? (
          <CircularProgress />
        ) : passengerSelectedState.flightGroups.length > 0 ? (
          passengerSelectedState.flightGroups.map((flightGroup, index) => (
            <div
              className="ibox-content"
              key={index}
              style={{ paddingLeft: "10px", paddingBottom: "20px" }}
            >
              <br></br>
              <table className="table_flight">
                <thead className="table_flight">
                  <tr>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px", paddingLeft: "10px" }}
                    >
                      {t("passenger_flight_flight")}
                    </td>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px" }}
                    >
                      {t("passenger_flight_flightDate")}
                    </td>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px" }}
                    >
                      {t("passenger_flight_route")}
                    </td>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px" }}
                    >
                      {t("passenger_flight_record")}
                    </td>
                  </tr>
                </thead>
                <tbody className="table_flight">
                  <tr>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px", paddingLeft: "10px" }}
                    >
                      {flightGroup.departureFlight}
                    </td>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px", paddingLeft: "10px" }}
                    >
                      {flightGroup.departure_flight_date + ""}
                    </td>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px", paddingLeft: "10px" }}
                    >
                      {flightGroup.departure_route}
                    </td>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px", paddingLeft: "10px" }}
                    >
                      {t("passenger_flight_rec")}
                    </td>
                  </tr>

                  <tr>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px", paddingLeft: "10px" }}
                    >
                      {flightGroup.returnFlight}
                    </td>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px", paddingLeft: "10px" }}
                    >
                      {flightGroup.return_flight_date}
                    </td>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px", paddingLeft: "10px" }}
                    >
                      {flightGroup.return_route}
                    </td>
                    <td
                      className="table_flight"
                      style={{ paddingRight: "50px", paddingLeft: "10px" }}
                    >
                      {t("passenger_flight_rec")}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="table_flight">
                <thead>
                  <tr>
                    <td className="table_flight"> </td>
                    <td
                      className="table_flight"
                      style={{
                        paddingRight: "50px",
                        paddingLeft: "10px",
                      }}
                    >
                      {t("passenger_flight_fullName")}
                    </td>
                    <td
                      className="table_flight"
                      style={{
                        paddingRight: "50px",
                        paddingLeft: "10px",
                      }}
                    >
                      {t("passenger_flight_passport")}
                    </td>
                    <td
                      className="table_flight"
                      style={{
                        paddingRight: "50px",
                        paddingLeft: "10px",
                      }}
                    >
                      {t("passenger_flight_nationality")}
                    </td>
                  </tr>
                </thead>
                {flightGroup.passengers.map((passenger, index) => (
                  <tbody key={passenger.passenger_id}>
                    <tr>
                      <td
                        className="table_flight"
                        style={{
                          paddingRight: "20px",
                          paddingLeft: "20px",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="table_flight"
                        style={{
                          paddingRight: "50px",
                          paddingLeft: "10px",
                        }}
                      >
                        {passenger.last_name} / {passenger.first_name}
                      </td>
                      <td
                        className="table_flight"
                        style={{
                          paddingRight: "50px",
                          paddingLeft: "10px",
                        }}
                      >
                        {" "}
                        {passenger.identification}{" "}
                      </td>
                      <td
                        className="table_flight"
                        style={{
                          paddingRight: "50px",
                          paddingLeft: "10px",
                        }}
                      >
                        {passenger.country}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          ))
        ) : (
          ""
        )}

        <Divider variant="fullWidth" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell>{t("passenger_flight_type")}</TableCell>
              <TableCell>{t("passenger_flight_lastName")}</TableCell>
              <TableCell>{t("passenger_flight_name")}</TableCell>
              <TableCell>{t("passenger_flight_executive")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passengersInFlightStateCustom.passengers.map((passenger, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <div className="ui checkbox">
                    <input
                      type="checkbox"
                      name="active"
                      id={passenger.passenger_id}
                      defaultChecked={active}
                      onChange={(event) => {
                        setActive(event.target.checked);
                        handleCheck(
                          passenger.passenger_id,
                          event.target.checked
                        );
                        getSelectedPassengers(passenger.passenger_id);
                      }}
                    />
                    <label></label>
                  </div>
                </TableCell>
                <TableCell>{passenger.cruise_type}</TableCell>
                <TableCell>{passenger.last_name.toUpperCase()}</TableCell>

                <TableCell>{passenger.first_name.toUpperCase()}</TableCell>
                <TableCell>N</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
