import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CruiseService from "../../../../services/reservations/CruiseService";

export const REQUEST_FETCH_RESERVATIONS_CANCELED =
  "REQUEST_FETCH_RESERVATIONS_CANCELED";
export const FETCH_RESERVATIONS_CANCELED_SUCCESS =
  "FETCH_RESERVATIONS_CANCELED_SUCCESS";
export const FETCH_RESERVATIONS_CANCELED_ERROR =
  "FETCH_RESERVATIONS_CANCELED_ERROR";
export const INITIAL_STATE_RESERVATION_CANCELED =
  "INITIAL_STATE_RESERVATION_CANCELED";

function requestFetchReservationsCanceled() {
  return {
    type: REQUEST_FETCH_RESERVATIONS_CANCELED,
  };
}

function fetchReservationsCanceledSuccess(json) {
  return {
    type: FETCH_RESERVATIONS_CANCELED_SUCCESS,
    reservationsCanceled: json.data,
  };
}

function fetchReservationsCanceledError(json) {
  return {
    type: FETCH_RESERVATIONS_CANCELED_ERROR,
    error: json.data,
  };
}

export function initialStateReservationCanceled() {
  return {
    type: INITIAL_STATE_RESERVATION_CANCELED,
  };
}

export function fetchReservationsCanceled(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchReservationsCanceled());
    return CruiseService.getReservationsCanceled(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchReservationsCanceledSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchReservationsCanceled(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchReservationsCanceledError(json.data));
      }
    });
  };
}
