import {
  REQUEST_FETCH_NOTES,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_ERROR,
} from "../../../actions/catalogs/note/fetchNotes";

import {
  INITIAL_STATE_NOTE,
  REQUEST_FETCH_NOTE,
  FETCH_NOTE_SUCCESS,
  FETCH_NOTE_ERROR,
} from "../../../actions/catalogs/note/fetchNote";

import {
  REQUEST_CREATE_NOTE,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_ERROR,
} from "../../../actions/catalogs/note/createNote";

import {
  REQUEST_UPDATE_NOTE,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_ERROR,
} from "../../../actions/catalogs/note/updateNote";

import {
  REQUEST_DELETE_NOTE,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_ERROR,
} from "../../../actions/catalogs/note/deleteNote";

const initialState = {
  notes: [],
  note: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const noteReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_NOTE: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_NOTES: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_NOTES_SUCCESS: {
      return {
        ...state,
        notes: action.notes,
        note: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_NOTES_ERROR: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_NOTE: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_NOTE_SUCCESS: {
      return {
        ...state,
        notes: [],
        note: action.note,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_NOTE_ERROR: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_NOTE: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_NOTE_SUCCESS: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_NOTE_ERROR: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_NOTE: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_NOTE_SUCCESS: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_NOTE_ERROR: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_NOTE: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_NOTE_SUCCESS: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_NOTE_ERROR: {
      return {
        ...state,
        notes: [],
        note: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default noteReducer;
