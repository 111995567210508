import {
  REQUEST_FETCH_VOUCHERS,
  FETCH_VOUCHERS_SUCCESS,
  FETCH_VOUCHERS_ERROR,
} from "../../../actions/reservations/voucher/fetchVouchers";

import {
  INITIAL_STATE_VOUCHER,
  REQUEST_FETCH_VOUCHER,
  FETCH_VOUCHER_SUCCESS,
  FETCH_VOUCHER_ERROR,
} from "../../../actions/reservations/voucher/fetchVoucher";

import {
  REQUEST_CREATE_VOUCHER,
  CREATE_VOUCHER_SUCCESS,
  CREATE_VOUCHER_ERROR,
} from "../../../actions/reservations/voucher/createVoucher";

import {
  REQUEST_UPDATE_VOUCHER,
  UPDATE_VOUCHER_SUCCESS,
  UPDATE_VOUCHER_ERROR,
} from "../../../actions/reservations/voucher/updateVoucher";

import {
  REQUEST_DELETE_VOUCHER,
  DELETE_VOUCHER_SUCCESS,
  DELETE_VOUCHER_ERROR,
} from "../../../actions/reservations/voucher/deleteVoucher";

const initialState = {
  vouchers: [],
  voucher: {},
  processing: false,
  fetching: false,
  generating: false,
  message: "",
  error: "",
};

const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_VOUCHER: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: false,
        fetching: false,
        generating: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_VOUCHERS: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: false,
        fetching: true,
        generating: false,
        message: "",
        error: "",
      };
    }
    case FETCH_VOUCHERS_SUCCESS: {
      return {
        ...state,
        vouchers: action.vouchers,
        voucher: {},
        processing: false,
        fetching: false,
        generating: false,
        message: "",
        error: "",
      };
    }
    case FETCH_VOUCHERS_ERROR: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: false,
        fetching: false,
        generating: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_VOUCHER: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: false,
        fetching: true,
        generating: true,
        message: "",
        error: "",
      };
    }
    case FETCH_VOUCHER_SUCCESS: {
      return {
        ...state,
        vouchers: [],
        voucher: action.voucher,
        processing: false,
        fetching: false,
        generating: false,
        message: "",
        error: "",
      };
    }
    case FETCH_VOUCHER_ERROR: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: false,
        fetching: false,
        generating: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_VOUCHER: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: true,
        fetching: false,
        generating: false,
        message: "",
        error: "",
      };
    }
    case CREATE_VOUCHER_SUCCESS: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: false,
        fetching: false,
        generating: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_VOUCHER_ERROR: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: false,
        fetching: false,
        generating: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_VOUCHER: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: true,
        fetching: false,
        generating: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_VOUCHER_SUCCESS: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: false,
        fetching: false,
        generating: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_VOUCHER_ERROR: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: false,
        fetching: false,
        generating: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_VOUCHER: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: true,
        fetching: false,
        generating: false,
        message: "",
        error: "",
      };
    }
    case DELETE_VOUCHER_SUCCESS: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: false,
        fetching: false,
        generating: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_VOUCHER_ERROR: {
      return {
        ...state,
        vouchers: [],
        voucher: {},
        processing: false,
        fetching: false,
        generating: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default voucherReducer;
