import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SpaceFlightService from "../../../../services/reservations/SpaceFlightService";

export const REQUEST_FETCH_SPACE_FLIGHT = "REQUEST_FETCH_SPACE_FLIGHT";
export const FETCH_SPACE_FLIGHT_SUCCESS = "FETCH_SPACE_FLIGHT_SUCCESS";
export const FETCH_SPACE_FLIGHT_ERROR = "FETCH_SPACE_FLIGHT_ERROR";
export const INITIAL_STATE_SPACE_FLIGHT = "INITIAL_STATE_SPACE_FLIGHT";

function requestFetchSpaceFlight() {
  return {
    type: REQUEST_FETCH_SPACE_FLIGHT,
  };
}

function fetchSpaceFlightSuccess(json) {
  return {
    type: FETCH_SPACE_FLIGHT_SUCCESS,
    spaceFlight: json.data,
    numberFlights: json.numberFlights,
  };
}

function fetchSpaceFlightError(json) {
  return {
    type: FETCH_SPACE_FLIGHT_ERROR,
    error: json.data,
  };
}

export function initialStateSpaceFlight() {
  return {
    type: INITIAL_STATE_SPACE_FLIGHT,
  };
}

export function fetchSpaceFlight(space_id, space_flight_id) {
  return (dispatch) => {
    dispatch(requestFetchSpaceFlight());
    return SpaceFlightService.getSpaceFlight(space_id, space_flight_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchSpaceFlightSuccess(json.data, json.numberFlights));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchSpaceFlight(space_id, space_flight_id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchSpaceFlightError(json.data));
        }
      }
    );
  };
}
