import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import UserService from "../../../../services/catalogs/UserService";

export const REQUEST_FETCH_USER = "REQUEST_FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";
export const INITIAL_STATE_USER = "INITIAL_STATE_USER";

function requestFetchUser() {
  return {
    type: REQUEST_FETCH_USER,
  };
}

function fetchUserSuccess(json) {
  return {
    type: FETCH_USER_SUCCESS,
    user: json.data,
  };
}

function fetchUserError(json) {
  return {
    type: FETCH_USER_ERROR,
    error: json.data,
  };
}

export function initialStateUser() {
  return {
    type: INITIAL_STATE_USER,
  };
}

export function fetchUser(user_id) {
  return (dispatch) => {
    dispatch(requestFetchUser());
    return UserService.getUser(user_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchUserSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchUser(user_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchUserError(json.data));
      }
    });
  };
}
