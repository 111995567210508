import React from "react";
import { useTranslation } from "react-i18next";
import { Table, Header } from "semantic-ui-react";

export default function CruiseTableResumenNotDivided(props) {
  const { t } = useTranslation();
  return (
    <>
      <Table.Body>
        <Table.Row textAlign="center">
          <Table.Cell>
            <Header>
              <span className="header_style">
                {t("cruise_detail_to")}
              </span>
            </Header>
          </Table.Cell>
          <Table.Cell style={{ backgroundColor: "#DBADFF" }}>
            {props.TO}
          </Table.Cell>
          <Table.Cell>{props.numberSpacesUsed_TO_Reserved}</Table.Cell>
          <Table.Cell>{props.numberSpacesUsed_TO_Confirmed}</Table.Cell>
          <Table.Cell>{props.numberSpacesUsed_TO_Waiting}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </>
  );
}
