import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import HotelierService from "../../../../services/catalogs/HotelierService";

export const REQUEST_FETCH_HOTELIER = "REQUEST_FETCH_HOTELIER";
export const FETCH_HOTELIER_SUCCESS = "FETCH_HOTELIER_SUCCESS";
export const FETCH_HOTELIER_ERROR = "FETCH_HOTELIER_ERROR";
export const INITIAL_STATE_HOTELIER = "INITIAL_STATE_HOTELIER";

function requestFetchHotelier() {
  return {
    type: REQUEST_FETCH_HOTELIER,
  };
}

function fetchHotelierSuccess(json) {
  return {
    type: FETCH_HOTELIER_SUCCESS,
    hotelier: json.data,
  };
}

function fetchHotelierError(json) {
  return {
    type: FETCH_HOTELIER_ERROR,
    error: json.data,
  };
}

export function initialStateHotelier() {
  return {
    type: INITIAL_STATE_HOTELIER,
  };
}

export function fetchHotelier(hotelier_id) {
  return (dispatch) => {
    dispatch(requestFetchHotelier());
    return HotelierService.getHotelier(hotelier_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchHotelierSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchHotelier(hotelier_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchHotelierError(json.data));
      }
    });
  };
}
