import PassengerBySpaceService from "../../../../services/reservations/PassengerBySpaceService";

export const REQUEST_FETCH_VERIFY_PASSENGER = "REQUEST_FETCH_VERIFY_PASSENGER";
export const FETCH_VERIFY_PASSENGER_SUCCESS = "FETCH_VERIFY_PASSENGER_SUCCESS";
export const FETCH_VERIFY_PASSENGER_ERROR = "FETCH_VERIFY_PASSENGER_ERROR";
export const INITIAL_STATE_VERIFY_PASSENGER = "INITIAL_STATE_VERIFY_PASSENGER";

function requestFetchVerifyPassenger() {
  return {
    type: REQUEST_FETCH_VERIFY_PASSENGER,
  };
}

function fetchVerifyPassengerSuccess(json) {
  return {
    type: FETCH_VERIFY_PASSENGER_SUCCESS,
    passenger: json.data,
  };
}

function fetchVerifyPassengerError(json) {
  return {
    type: FETCH_VERIFY_PASSENGER_ERROR,
    error: json.data,
  };
}

export function initialStateVerifyPassenger() {
  return {
    type: INITIAL_STATE_VERIFY_PASSENGER,
  };
}

export function verifyPassenger(identification) {
  return (dispatch) => {
    dispatch(requestFetchVerifyPassenger());
    return PassengerBySpaceService.verifyPassengerBySpace(identification).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchVerifyPassengerSuccess(json.data));
        } else {
          dispatch(fetchVerifyPassengerError(json.data));
        }
      }
    );
  };
}
