import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customCabinBerthForm = createSlice({
  name: 'customCabinBerthForm',
  initialState: {
    cabin: {},
    cabinBerth: {},
    berths: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateCabinBerthFormCustom(state, action) {
      return {
        cabin: {},
        cabinBerth: {},
        berths: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesCabinBerthFormCustom(state, action) {
      return {
        cabin: {},
        cabinBerth: {},
        berths: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchCabinBerthFormCustomSuccess(state, action) {
      return {
        cabin: action.payload.cabin,
        cabinBerth: action.payload.cabinBerth,
        berths: action.payload.berths,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchCabinBerthFormCustomError(state, action) {
      return {
        cabin: {},
        cabinBerth: {},
        berths: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchCabinBerthFormCustom = (cabin_id, cabinBerth_id) => dispatch => {
  dispatch(requesCabinBerthFormCustom());
  axios.get(`/cabin/${cabin_id}/form/cabinBerthsForm?cabinBerth_id=${cabinBerth_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchCabinBerthFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCabinBerthFormCustom(cabin_id, cabinBerth_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCabinBerthFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateCabinBerthFormCustom,
  requesCabinBerthFormCustom,
  fetchCabinBerthFormCustomSuccess,
  fetchCabinBerthFormCustomError,
} = customCabinBerthForm.actions;

export default customCabinBerthForm.reducer;