import AvailabilityService from "../../../../services/b2b/AvailabilityService";

export const REQUEST_FETCH_RESERVATION_DETAIL =
  "REQUEST_FETCH_RESERVATION_DETAIL";
export const FETCH_RESERVATION_DETAIL_SUCCESS =
  "FETCH_RESERVATION_DETAIL_SUCCESS";
export const FETCH_RESERVATION_DETAIL_ERROR = "FETCH_RESERVATION_DETAIL_ERROR";
export const INITIAL_STATE_RESERVATION_DETAIL =
  "INITIAL_STATE_RESERVATION_DETAIL";

function requestFetchReservationDetail() {
  return {
    type: REQUEST_FETCH_RESERVATION_DETAIL,
  };
}

function fetchReservationDetailSuccess(json) {
  return {
    type: FETCH_RESERVATION_DETAIL_SUCCESS,
    reservation: json.data,
  };
}

function fetchReservationDetailError(json) {
  return {
    type: FETCH_RESERVATION_DETAIL_ERROR,
    error: json.data,
  };
}

export function initialStateReservationDetail() {
  return {
    type: INITIAL_STATE_RESERVATION_DETAIL,
  };
}

export function fetchReservationDetail(reservationId) {
  return (dispatch) => {
    dispatch(requestFetchReservationDetail());
    return AvailabilityService.getReservationDetail(reservationId).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchReservationDetailSuccess(json.data));
        } else {
          dispatch(fetchReservationDetailError(json.data));
        }
      }
    );
  };
}
