import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SeasonService from "../../../../services/rates/SeasonService";

export const REQUEST_DELETE_SEASON = "REQUEST_DELETE_SEASON";
export const DELETE_SEASON_SUCCESS = "DELETE_SEASON_SUCCESS";
export const DELETE_SEASON_ERROR = "DELETE_SEASON_ERROR";

function requestDeleteSeason() {
  return {
    type: REQUEST_DELETE_SEASON,
  };
}

function deleteSeasonSuccess(json) {
  return {
    type: DELETE_SEASON_SUCCESS,
    message: json.data,
  };
}

function deleteSeasonError(json) {
  return {
    type: DELETE_SEASON_ERROR,
    error: json.data,
  };
}

export function deleteSeason(season_id) {
  return (dispatch) => {
    dispatch(requestDeleteSeason());
    return SeasonService.deleteSeason(season_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteSeasonSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteSeason(season_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteSeasonError(json.data));
      }
    });
  };
}
