import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import DomainIcon from "@material-ui/icons/Domain";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import ListIcon from "@material-ui/icons/List";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function NestedList() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openCatalogs, setOpenCatalogs] = React.useState(false);
  const [openRate, setOpenRate] = React.useState(false);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader inset component="div" id="nested-list-subheader">
          {t("administration_tag")}
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem button component={Link} to="/companies">
        <ListItemIcon>
          <DomainIcon />
        </ListItemIcon>
        <ListItemText primary={t("companies_tag")} />
      </ListItem>
      <ListItem button onClick={() => setOpenCatalogs(!openCatalogs)}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary={t("catalogs_tag")} />
        {openCatalogs ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openCatalogs} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/airlines"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("airlines_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/agencies"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("agencies_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/berths"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("berths_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/captains"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("captains_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/kinds"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("kinds_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/genders"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("genders_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/guides"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("guides_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/hoteliers"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("hoteliers_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/languages"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("languages_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/itineraries"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("itineraries_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/decks"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("decks_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/notes"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("notes_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/countries"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("countries_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/routes"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("routes_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/identificationTypes"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("identificationTypes_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/flights"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("flights_tag")} />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button onClick={() => setOpenRate(!openRate)}>
        <ListItemIcon>
          <AttachMoneyIcon />
        </ListItemIcon>
        <ListItemText primary={t("rates_tag")} />
        {openRate ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openRate} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/rates"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("rate_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/generalRates"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("generalRates_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/familyRooms"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("familyRoom_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/charterRates"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("charterRates_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/contractRates"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("contracts_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/ikarusRates"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("ikarusRates_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/tickets"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("tickets_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/seasons"
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("seasons_tag")} />
          </ListItem>
        </List>
      </Collapse>

      <ListItem button component={Link} to="/users">
        <ListItemIcon>
          <AccountBoxRoundedIcon />
        </ListItemIcon>
        <ListItemText primary={t("users_tag")} />
      </ListItem>
      <ListItem button component={Link} to="/yachts">
        <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon>
        <ListItemText primary={t("yachts_tag")} />
      </ListItem>
    </List>
  );
}
