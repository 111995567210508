import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customBookingList = createSlice({
  name: 'customBookingList',
  initialState: {
    cruise: {},
    bookings: [],
    reservation: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateBookingListCustom(state, action) {
      return {
        cruise: {},
        bookings: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesBookingListCustom(state, action) {
      return {
        cruise: {},
        bookings: [],
        reservation: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchBookingListCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        bookings: action.payload.bookings,
        reservation: action.payload.reservation,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchBookingListCustomError(state, action) {
      return {
        cruise: {},
        bookings: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchBookingListCustom = (reservation_id, cruise_id) => dispatch => {
  dispatch(requesBookingListCustom());
  var url = `reservation/${reservation_id}/bookingList`;
  url = url + `?cruise_id=${cruise_id}`;
  axios.get(`/${url}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchBookingListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchBookingListCustom = (reservation_id, cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchBookingListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateBookingListCustom,
  requesBookingListCustom,
  fetchBookingListCustomSuccess,
  fetchBookingListCustomError,
} = customBookingList.actions;

export default customBookingList.reducer;