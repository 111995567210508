import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customAgencyUserList = createSlice({
  name: 'customAgencyUserList',
  initialState: {
    agency: {},
    users: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateAgencyUserListCustom(state, action) {
      return {
        agency: {},
        users: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesAgencyUserListCustom(state, action) {
      return {
        agency: {},
        users: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchAgencyUserListCustomSuccess(state, action) {
      return {
        agency: action.payload.agency,
        users: action.payload.users,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchAgencyUserListCustomError(state, action) {
      return {
        agency: {},
        users: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchAgencyUserListCustom = (agency_id) => dispatch => {
  dispatch(requesAgencyUserListCustom());
  axios.get(`/users/agencies/${agency_id}/agencyUsersList`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchAgencyUserListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchAgencyUserListCustom(agency_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchAgencyUserListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateAgencyUserListCustom,
  requesAgencyUserListCustom,
  fetchAgencyUserListCustomSuccess,
  fetchAgencyUserListCustomError,
} = customAgencyUserList.actions;

export default customAgencyUserList.reducer;