import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class KindService {
  static getKinds() {
    return axios.get("kinds");
  }
  static getKind(kind_id) {
    return axios.get("kinds/" + kind_id);
  }

  static postKind(payload) {
    return axios.post("kinds", payload);
  }

  static putKind(kind_id, payload) {
    return axios.put("kinds/" + kind_id, payload);
  }

  static deleteKind(kind_id) {
    return axios.delete("kinds/" + kind_id);
  }
}
