import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GeneralRateService from "../../../../services/rates/GeneralRateService";

export const REQUEST_FETCH_YEARS = "REQUEST_FETCH_YEARS";
export const FETCH_YEARS_SUCCESS = "FETCH_YEARS_SUCCESS";
export const FETCH_YEARS_ERROR = "FETCH_YEARS_ERROR";
export const INITIAL_STATE_YEARS = "INITIAL_STATE_YEARS";

function requestFetchYears() {
  return {
    type: REQUEST_FETCH_YEARS,
  };
}

function fetchYearsSuccess(json) {
  return {
    type: FETCH_YEARS_SUCCESS,
    years: json.data,
  };
}

function fetchYearsError(json) {
  return {
    type: FETCH_YEARS_ERROR,
    error: json.data,
  };
}

export function initialStateYears() {
  return {
    type: INITIAL_STATE_YEARS,
  };
}

export function fetchYears() {
  return (dispatch) => {
    dispatch(requestFetchYears());
    return GeneralRateService.getYears().then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchYearsSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchYears());
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchYearsError(json.data));
        }
      }
    );
  };
}
