import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Loader, Button, Breadcrumb, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createGuideLanguage } from "../../../redux/actions/catalogs/guideLanguage/createGuideLanguage";
import { initialStateGuideLanguage, } from "../../../redux/actions/catalogs/guideLanguage/fetchGuideLanguage";
import { updateGuideLanguage } from "../../../redux/actions/catalogs/guideLanguage/updateGuideLanguage";
import { fetchGuideLanguageFormCustom, initialStateGuideLanguageFormCustom } from "../../../redux/slices/custom/catalogs/guideLanguage/customGuideLanguageForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function GuideLanguageForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { guide_id } = useParams();
  let { language_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const guideLanguageStateCustom = useSelector((state) => state.customGuideLanguageForm);
  const guideLanguageState = useSelector((state) => state.guideLanguageReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGuideLanguageFormCustom(guide_id, language_id));
    scrollToTop();
    return () => {
      dispatch(initialStateGuideLanguageFormCustom());
      dispatch(initialStateGuideLanguage());
    };
  }, []);

  if (guideLanguageStateCustom.fetching || guideLanguageState.fetching) {
    return <Loader active inline />;
  }

  if (guideLanguageStateCustom.error || guideLanguageState.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (language_id) {
      dispatch(updateGuideLanguage(guide_id, language_id, data));
    } else {
      dispatch(createGuideLanguage(guide_id, data));
    }
  };

  if (guideLanguageState.message === "resource created successfully" || guideLanguageState.message === "resource updated successfully") {
    history.go(-1);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/guides" className="section">{t("guide_title")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section>
          <Link to={`/guides/${guide_id}/languages`} className="section">
          {guideLanguageStateCustom.guide.first_name + " " + guideLanguageStateCustom.guide.last_name}
          </Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("guide_lang_path")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("guide_lang_name")}</label>
            <select name="language_id" defaultValue={guideLanguageStateCustom.guideLanguage.language_id} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {guideLanguageStateCustom.languages.map((language) => (
                <option key={language.id} value={language.id} className="selected item">{t(language.name)}</option>
              ))}
            </select>
            {errors.language_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />
        
        <Button primary type="submit" loading={guideLanguageStateCustom.processing ? true : false}>{t("save_button")}</Button>
        <Link to={`/guides/${guide_id}/languages`}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
