import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GenderService from "../../../../services/catalogs/GenderService";

export const REQUEST_CREATE_GENDER = "REQUEST_CREATE_GENDER";
export const CREATE_GENDER_SUCCESS = "CREATE_GENDER_SUCCESS";
export const CREATE_GENDER_ERROR = "CREATE_GENDER_ERROR";

function requestCreateGender() {
  return {
    type: REQUEST_CREATE_GENDER,
  };
}

function createGenderSuccess(json) {
  return {
    type: CREATE_GENDER_SUCCESS,
    message: json.data,
  };
}

function createGenderError(json) {
  return {
    type: CREATE_GENDER_ERROR,
    error: json.data,
  };
}

export function createGender(payload) {
  return (dispatch) => {
    dispatch(requestCreateGender());
    return GenderService.postGender(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createGenderSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createGender(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createGenderError(json.data));
      }
    });
  };
}
