import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class RouteService {
  static getRoutes() {
    return axios.get("routes");
  }
  static getRoute(route_id) {
    return axios.get("routes/" + route_id);
  }

  static postRoute(payload) {
    return axios.post("routes", payload);
  }

  static putRoute(route_id, payload) {
    return axios.put("routes/" + route_id, payload);
  }

  static deleteRoute(route_id) {
    return axios.delete("routes/" + route_id);
  }
}
