import AdvanceCruiseService from "../../../../services/reservations/AdvanceCruiseService";

export const REQUEST_GENERATE_ADVANCE_CRUISE_REPORT =
  "REQUEST_GENERATE_ADVANCE_CRUISE_REPORT";
export const GENERATE_ADVANCE_CRUISE_REPORT_SUCCESS =
  "GENERATE_ADVANCE_CRUISE_REPORT_SUCCESS";
export const GENERATE_ADVANCE_CRUISE_REPORT_ERROR =
  "GENERATE_ADVANCE_CRUISE_REPORT_ERROR";
export const INITIAL_STATE_ADVANCE_CRUISE_REPORT =
  "INITIAL_STATE_ADVANCE_CRUISE_REPORT";

function requestGenerateAdvanceCruiseReport() {
  return {
    type: REQUEST_GENERATE_ADVANCE_CRUISE_REPORT,
  };
}

function generateAdvanceCruiseReportSuccess(json) {
  return {
    type: GENERATE_ADVANCE_CRUISE_REPORT_SUCCESS,
    data: json,
  };
}

function generateAdvanceCruiseReportError(json) {
  return {
    type: GENERATE_ADVANCE_CRUISE_REPORT_ERROR,
    error: json.data,
  };
}

export function initialStateAdvanceCruiseReport() {
  return {
    type: INITIAL_STATE_ADVANCE_CRUISE_REPORT,
  };
}

export function generateAdvanceCruiseReport(yachtId, startDate, endDate) {
  return (dispatch) => {
    dispatch(requestGenerateAdvanceCruiseReport());
    return AdvanceCruiseService.getAdvanceCruisesReport(
      yachtId,
      startDate,
      endDate
    ).then((json) => {
      if (json.status === 200) {
        dispatch(generateAdvanceCruiseReportSuccess(json.config.url));
      } else {
        dispatch(generateAdvanceCruiseReportError(json.data));
      }
    });
  };
}
