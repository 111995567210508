import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customSpaceFlightForm = createSlice({
  name: 'customSpaceFlightForm',
  initialState: {
    flights: [],
    routes: [],
    cruise: {},
    reservation: {},
    spaceFlight: {},
    numberFlights: [],
    booking: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateSpaceFlightFormCustom(state, action) {
      return {
        flights: [],
        routes: [],
        cruise: {},
        reservation: {},
        spaceFlight: {},
        numberFlights: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesSpaceFlightFormCustom(state, action) {
      return {
        flights: [],
        routes: [],
        cruise: {},
        reservation: {},
        spaceFlight: {},
        numberFlights: [],
        booking: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchSpaceFlightFormCustomSuccess(state, action) {
      return {
        flights: action.payload.flights,
        routes: action.payload.routes,
        cruise: action.payload.cruise,
        reservation: action.payload.reservation,
        spaceFlight: action.payload.spaceFlight.spaceFlightRoute ? action.payload.spaceFlight.spaceFlightRoute : action.payload.spaceFlight,
        numberFlights: action.payload.numberFlights,
        booking: action.payload.booking,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchSpaceFlightFormCustomError(state, action) {
      return {
        flights: [],
        routes: [],
        cruise: {},
        reservation: {},
        spaceFlight: {},
        numberFlights: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchSpaceFlightFormCustom = (searchWord, space_id, cruise_id, reservation_id, booking_id, space_flight_id) => dispatch => {
  dispatch(requesSpaceFlightFormCustom());
  var url = `space/${space_id}/spaceFlightsForm`;
  url = url + `?searchWord=${searchWord}&&cruise_id=${cruise_id}&&reservation_id=${reservation_id}&&booking_id=${booking_id}&&space_flight_id=${space_flight_id}`;
  axios.get(`/${url}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchSpaceFlightFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSpaceFlightFormCustom(searchWord, cruise_id, reservation_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSpaceFlightFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateSpaceFlightFormCustom,
  requesSpaceFlightFormCustom,
  fetchSpaceFlightFormCustomSuccess,
  fetchSpaceFlightFormCustomError,
} = customSpaceFlightForm.actions;

export default customSpaceFlightForm.reducer;