import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ItineraryService from "../../../../services/catalogs/ItineraryService";

export const REQUEST_DELETE_ITINERARY = "REQUEST_DELETE_ITINERARY";
export const DELETE_ITINERARY_SUCCESS = "DELETE_ITINERARY_SUCCESS";
export const DELETE_ITINERARY_ERROR = "DELETE_ITINERARY_ERROR";

function requestDeleteItinerary() {
  return {
    type: REQUEST_DELETE_ITINERARY,
  };
}

function deleteItinerarySuccess(json) {
  return {
    type: DELETE_ITINERARY_SUCCESS,
    message: json.data,
  };
}

function deleteItineraryError(json) {
  return {
    type: DELETE_ITINERARY_ERROR,
    error: json.data,
  };
}

export function deleteItinerary(itinerary_id) {
  return (dispatch) => {
    dispatch(requestDeleteItinerary());
    return ItineraryService.deleteItinerary(itinerary_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteItinerarySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteItinerary(itinerary_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteItineraryError(json.data));
      }
    });
  };
}
