import {
  INITIAL_STATE_PASSENGER_IN_FLIGHT,
  REQUEST_FETCH_PASSENGERS_IN_FLIGHT,
  FETCH_PASSENGERS_IN_FLIGHT_SUCCESS,
  FETCH_PASSENGERS_IN_FLIGHT_ERROR,
} from "../../../actions/reservations/passenger/fetchPassengersInFlight";

const initialState = {
  passengers: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const passengerInFlightReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_PASSENGER_IN_FLIGHT: {
      return {
        ...state,
        passengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_PASSENGERS_IN_FLIGHT: {
      return {
        ...state,
        passengers: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_PASSENGERS_IN_FLIGHT_SUCCESS: {
      return {
        ...state,
        passengers: action.passengers,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_PASSENGERS_IN_FLIGHT_ERROR: {
      return {
        ...state,
        passengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default passengerInFlightReducer;
