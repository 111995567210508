import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GeneralRateService from "../../../../services/rates/GeneralRateService";

export const REQUEST_FETCH_GENERAL_RATES = "REQUEST_FETCH_GENERAL_RATES";
export const FETCH_GENERAL_RATES_SUCCESS = "FETCH_GENERAL_RATES_SUCCESS";
export const FETCH_GENERAL_RATES_ERROR = "FETCH_GENERAL_RATES_ERROR";

function requestFetchGeneralRates() {
  return {
    type: REQUEST_FETCH_GENERAL_RATES,
  };
}

function fetchGeneralRatesSuccess(json) {
  return {
    type: FETCH_GENERAL_RATES_SUCCESS,
    generalRates: json.data,
  };
}

function fetchGeneralRatesError(json) {
  return {
    type: FETCH_GENERAL_RATES_ERROR,
    error: json.data,
  };
}

export function fetchGeneralRates(payload) {
  return (dispatch) => {
    dispatch(requestFetchGeneralRates());
    return GeneralRateService.getGeneralRates(payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchGeneralRatesSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchGeneralRates(payload));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchGeneralRatesError(json.data));
        }
      }
    );
  };
}
