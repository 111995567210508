import { logOut, updateLocalStorageToken } from '../../../../containers/resources/utils';
import ReservationLogService from '../../../../services/reservations/ReservationService';

export const REQUEST_FETCH_RESERVATION_LOGS = "REQUEST_FETCH_RESERVATION_LOGS";
export const FETCH_RESERVATION_LOGS_SUCCESS = "FETCH_RESERVATION_LOGS_SUCCESS";
export const FETCH_RESERVATION_LOGS_ERROR = "FETCH_RESERVATION_LOGS_ERROR";

function requestFetchReservationLogs() {
  return {
    type: REQUEST_FETCH_RESERVATION_LOGS,
  };
}

function fetchReservationLogsSuccess(json) {
  return {
    type: FETCH_RESERVATION_LOGS_SUCCESS,
    logs: json.data,
  };
}

function fetchReservationLogsError(json) {
  return {
    type: FETCH_RESERVATION_LOGS_ERROR,
    error: json,
  };
}

export function fetchReservationLogs(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchReservationLogs());
    return ReservationLogService.getItemsByField(cruise_id).then((json) => {
      if (json.status === 200) {
        console.log(json)
        dispatch(fetchReservationLogsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchReservationLogs(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchReservationLogsError(json.data));
      }
    });
  };
}
