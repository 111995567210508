import GuideOnBoardList from "../containers/reservations/guideOnBoard/GuideOnBoardList";
import GuideOnBoardForm from "../containers/reservations/guideOnBoard/GuideOnBoardForm";

const routes = [
  {
    path: "/cruises/:cruise_id/guides",
    component: GuideOnBoardList,
  },
  {
    path: "/cruises/:cruise_id/guides_new",
    component: GuideOnBoardForm,
  },
  {
    path: "/cruises/:cruise_id/guides/:id",
    component: GuideOnBoardForm,
  },
];

export { routes as guideonBoardRoutes };
