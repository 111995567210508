import CharterRateService from "../../../../services/rates/CharterRateService";

export const REQUEST_INACTIVE_CHARTER_RATE = "REQUEST_INACTIVE_CHARTER_RATE";
export const INACTIVE_CHARTER_RATE_SUCCESS = "INACTIVE_CHARTER_RATE_SUCCESS";
export const INACTIVE_CHARTER_RATE_ERROR = "INACTIVE_CHARTER_RATE_ERROR";

function requestInactiveCharterRate() {
  return {
    type: REQUEST_INACTIVE_CHARTER_RATE,
  };
}

function inactiveCharterRateSuccess(json) {
  return {
    type: INACTIVE_CHARTER_RATE_SUCCESS,
    message: json.data,
  };
}

function inactiveCharterRateError(json) {
  return {
    type: INACTIVE_CHARTER_RATE_ERROR,
    error: json.data,
  };
}

export function inactiveCharterRate(charterRate_id, payload) {
  return (dispatch) => {
    dispatch(requestInactiveCharterRate());
    return CharterRateService.inactiveCharterRate(charterRate_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(inactiveCharterRateSuccess(json.data));
      } else {
        dispatch(inactiveCharterRateError(json.data));
      }
    });
  };
}
