import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Breadcrumb, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createCabinBerth } from "../../../redux/actions/catalogs/cabinBerth/createCabinBerth";
import { initialStateCabinBerth } from "../../../redux/actions/catalogs/cabinBerth/fetchCabinBerth";
import { updateCabinBerth } from "../../../redux/actions/catalogs/cabinBerth/updateCabinBerth";
import { fetchCabinBerthFormCustom, initialStateCabinBerthFormCustom } from "../../../redux/slices/custom/catalogs/cabinBerth/customCabinBerthForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function CabinBerthForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { cabin_id } = useParams();
  let { id } = useParams();
  const { register, handleSubmit, errors } = useForm();

  const cabinBerthStateCustom = useSelector((state) => state.customCabinBerthForm);
  const cabinBerthState = useSelector((state) => state.cabinBerthReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCabinBerthFormCustom(cabin_id, id));
    scrollToTop();
    return () => {
      dispatch(initialStateCabinBerthFormCustom());
      dispatch(initialStateCabinBerth());
    };
  }, [dispatch, cabin_id, id]);

  if (cabinBerthStateCustom.fetching || cabinBerthState.fetching ) {
    return <Loader active inline />;
  }

  if (cabinBerthStateCustom.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (id) {
      dispatch(updateCabinBerth(cabin_id, id, data));
    } else {
      dispatch(createCabinBerth(cabin_id, data));
    }
  };

  if (cabinBerthState.message === "resource created successfully" || cabinBerthState.message === "resource updated successfully") {
    history.go(-1);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/yachts" className="section">{t("yacht_title")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section >
          <Link to={`/yacht/${cabinBerthStateCustom.cabin.yacht_id}/cabins`}>{cabinBerthStateCustom.cabin.yacht_name}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section >
          <Link to={`/cabins/${cabin_id}/berths`}>{cabinBerthStateCustom.cabin.name}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("cabinBerth_path")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("cabinBerth_name")}</label>
            <select name="berth_id" defaultValue={cabinBerthStateCustom.cabinBerth.berth_id} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {cabinBerthStateCustom.berths.map((berth) => (
                <option className="selected item" key={berth.id} value={berth.id}>{t(berth.name)}</option>
              ))}
            </select>
            {errors.berth_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={cabinBerthStateCustom.processing ? true : false}>{t("save_button")}</Button>
        <Link to={`/cabins/${cabin_id}/berths`}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
