import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class GuideOnBoardService {
  static getGuidesOnBoard(cruise_id) {
    return axios.get("cruise/" + cruise_id + "/guides");
  }
  static getGuideOnBoard(cruise_id, id) {
    return axios.get("cruise/" + cruise_id + "/guide/" + id);
  }

  static postGuideOnBoard(cruise_id, payload) {
    return axios.post(
      "cruise/" + cruise_id + "/guides",
      payload
    );
  }

  static putGuideOnBoard(cruise_id, id, payload) {
    return axios.put(
      "cruise/" + cruise_id + "/guide/" + id,
      payload
    );
  }

  static deleteGuideOnBoard(cruise_id, id) {
    return axios.delete("cruise/" + cruise_id + "/guide/" + id);
  }

  static getItemsByCruise(cruise_id) {
    return axios.get("guideOnBoard/logs/"+ cruise_id);
  }
}
