import StatisticReportList from "../containers/reservations/statistic/StatisticReportList";

const routes = [
  {
    path: "/statisticReport",
    component: StatisticReportList,
  },
];

export { routes as statisticReportRoutes };
