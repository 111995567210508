
import React from "react";
import { useTranslation } from "react-i18next";
import { List } from "semantic-ui-react";

export default function GeneralRateCell(props) {
    const { t } = useTranslation();
    return (
      <>
        <List size="large">
          <List.Item>
              <List.Icon name='dollar' color='blue' />
              <List.Content >
              <List.Header >{props.client}</List.Header>
              <List.Description>{t("general_client")}</List.Description>
              </List.Content>
          </List.Item>
          <List.Item>
              <List.Icon name='dollar' color='blue' />
              <List.Content>
              <List.Header>{props.operator}</List.Header>
              <List.Description>{t("operator")}</List.Description>
              </List.Content>
          </List.Item>
          <List.Item>
              <List.Icon color='red' name={props.ticket === 1 ? 'plane' : 'dont'} />
              <List.Content>
              <List.Description>{props.ticket === 1 ? t("general_priceInclude") : t("general_priceNotInclude")} </List.Description>
              </List.Content>
          </List.Item>
        </List>
      </>
    );
  }
  