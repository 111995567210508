import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customTicketList = createSlice({
  name: 'customTicketList',
  initialState: {
    years: [],
    airlines: [],
    tickets: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateTicketListCustom(state, action) {
      return {
        years: [],
        airlines: [],
        tickets: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesTicketListCustom(state, action) {
      return {
        years: [],
        airlines: [],
        tickets: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchTicketListCustomSuccess(state, action) {
      return {
        years: action.payload.years,
        airlines: action.payload.airlines,
        tickets: action.payload.tickets,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchTicketListCustomError(state, action) {
      return {
        years: [],
        airlines: [],
        tickets: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchTicketListCustom = (payload) => dispatch => {
  const params = new URLSearchParams([['year', payload.year], ['airline_id', payload.airline_id]]);
  dispatch(requesTicketListCustom());
  axios.get(`/tickets/list/ticketList`, {params})
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchTicketListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchTicketListCustom(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchTicketListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateTicketListCustom,
  requesTicketListCustom,
  fetchTicketListCustomSuccess,
  fetchTicketListCustomError,
} = customTicketList.actions;

export default customTicketList.reducer;