import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import DeckService from "../../../../services/catalogs/DeckService";

export const REQUEST_CREATE_DECK = "REQUEST_CREATE_DECK";
export const CREATE_DECK_SUCCESS = "CREATE_DECK_SUCCESS";
export const CREATE_DECK_ERROR = "CREATE_DECK_ERROR";

function requestCreateDeck() {
  return {
    type: REQUEST_CREATE_DECK,
  };
}

function createDeckSuccess(json) {
  return {
    type: CREATE_DECK_SUCCESS,
    message: json.data,
  };
}

function createDeckError(json) {
  return {
    type: CREATE_DECK_ERROR,
    error: json.data,
  };
}

export function createDeck(payload) {
  return (dispatch) => {
    dispatch(requestCreateDeck());
    return DeckService.postDeck(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createDeckSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createDeck(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createDeckError(json.data));
      }
    });
  };
}
