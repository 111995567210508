import {
  REQUEST_FETCH_BERTHS,
  FETCH_BERTHS_SUCCESS,
  FETCH_BERTHS_ERROR,
} from "../../../actions/catalogs/berth/fetchBerths";

import {
  INITIAL_STATE_BERTH,
  REQUEST_FETCH_BERTH,
  FETCH_BERTH_SUCCESS,
  FETCH_BERTH_ERROR,
} from "../../../actions/catalogs/berth/fetchBerth";

import {
  REQUEST_CREATE_BERTH,
  CREATE_BERTH_SUCCESS,
  CREATE_BERTH_ERROR,
} from "../../../actions/catalogs/berth/createBerth";

import {
  REQUEST_UPDATE_BERTH,
  UPDATE_BERTH_SUCCESS,
  UPDATE_BERTH_ERROR,
} from "../../../actions/catalogs/berth/updateBerth";

import {
  REQUEST_DELETE_BERTH,
  DELETE_BERTH_SUCCESS,
  DELETE_BERTH_ERROR,
} from "../../../actions/catalogs/berth/deleteBerth";

const initialState = {
  berths: [],
  berth: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const berthReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_BERTH: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_BERTHS: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_BERTHS_SUCCESS: {
      return {
        ...state,
        berths: action.berths,
        berth: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_BERTHS_ERROR: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_BERTH: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_BERTH_SUCCESS: {
      return {
        ...state,
        berths: [],
        berth: action.berth,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_BERTH_ERROR: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_BERTH: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_BERTH_SUCCESS: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_BERTH_ERROR: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_BERTH: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_BERTH_SUCCESS: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_BERTH_ERROR: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_BERTH: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_BERTH_SUCCESS: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_BERTH_ERROR: {
      return {
        ...state,
        berths: [],
        berth: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default berthReducer;
