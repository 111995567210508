import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CabinService from "../../../../services/catalogs/CabinService";

export const REQUEST_FETCH_AVAILABILITY_CABIN =
  "REQUEST_FETCH_AVAILABILITY_CABIN";
export const FETCH_AVAILABILITY_CABIN_SUCCESS =
  "FETCH_AVAILABILITY_CABIN_SUCCESS";
export const FETCH_AVAILABILITY_CABIN_ERROR = "FETCH_AVAILABILITY_CABIN_ERROR";

function requestFetchAvailabilityCabin() {
  return {
    type: REQUEST_FETCH_AVAILABILITY_CABIN,
  };
}

function fetchAvailabilityCabinSuccess(json) {
  return {
    type: FETCH_AVAILABILITY_CABIN_SUCCESS,
    cabin: json.data,
  };
}

function fetchAvailabilityCabinError(json) {
  return {
    type: FETCH_AVAILABILITY_CABIN_ERROR,
    error: json.data,
  };
}

export function fetchAvailabilityCabin(cabin_id) {
  return (dispatch) => {
    dispatch(requestFetchAvailabilityCabin());
    return CabinService.getAvailabilityCabin(cabin_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchAvailabilityCabinSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchAvailabilityCabin(cabin_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchAvailabilityCabinError(json.data));
      }
    });
  };
}
