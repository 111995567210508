import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Icon, Form, Divider, Header, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";
import GeneralRateCell from "../../../components/rates/generalRateCell"
import StateRateCell from "../../../components/rates/stateRateCell"
import ChangeStateRateModal from "../../../components/rates/changeStateRateModal"

//Actions
import { fetchGeneralRateListCustom, initialStateGeneralRateListCustom} from "../../../redux/slices/custom/rates/generalRate/customGeneralRateList.slice";
import { initialStateGeneralRate } from "../../../redux/actions/rates/generalRates/fetchGeneralRate";
import { deleteGeneralRate } from "../../../redux/actions/rates/generalRates/deleteGeneralRate";

// Resources
import { scrollToTop } from "../../resources/utils";
import { ContactSupportOutlined } from "@material-ui/icons";
//import { NoteAddSharp } from "@material-ui/icons";

export default function GeneralRateList() {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false)
  let actualDate = new Date();
  let currentYear = actualDate.getFullYear();
  let rateType = "";
  let yacht = "";
  let days = "all";

  const generalRateFilters = JSON.parse(localStorage.getItem("generalRateFilters"));
  if (generalRateFilters) {
    currentYear = generalRateFilters.year;
    rateType = generalRateFilters.rate;
    yacht = generalRateFilters.yacht;
    days = generalRateFilters.days;
  }

  
  const { register, handleSubmit, errors, getValues } = useForm();
  const generalRateStateCustom = useSelector((state) => state.customGeneralRateList);
  const generalRateState = useSelector((state) => state.generalRateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGeneralRateListCustom({year: currentYear, rate_id: rateType, yacht_id: yacht, days: days}));
    scrollToTop();
    return () => {
     dispatch(initialStateGeneralRateListCustom());
    };
  }, []);
  
  if (generalRateStateCustom.fetching|| generalRateState.fetching) {
    return <Loader active inline />;
  }

  if (generalRateStateCustom.error) {
    return <div>Error: {generalRateStateCustom.error}</div>;
  }

  if (generalRateState.message === "resource deleted successfully") {
    dispatch(
      fetchGeneralRateListCustom({year: getValues("year"), rate_id: getValues("rate_id"), yacht_id: getValues("yacht_id"), days: getValues("days")})
    );
    dispatch(initialStateGeneralRate())
  }

  if (generalRateState.message === "resource updated successfully") {
    dispatch(
      fetchGeneralRateListCustom({year: getValues("year"), rate_id: getValues("rate_id"), yacht_id: getValues("yacht_id"), days: getValues("days")})
    );
    dispatch(initialStateGeneralRate())
  }

  const onSubmit = (data) => {
    let generalRateFilters = {
      year: getValues("year"),
      rate: getValues("rate_id"),
      yacht: getValues("yacht_id"),
      days: getValues("days"),
    };
    localStorage.setItem("generalRateFilters", JSON.stringify(generalRateFilters));
    dispatch(fetchGeneralRateListCustom(data));
  };

  const handleDeleteGeneralRate = (generalRate_id) => {
    dispatch(deleteGeneralRate(generalRate_id));
  };

  return (
    <>
      <HeaderCatalog title={t("general_title")} message={generalRateStateCustom.message} to="/generalRates_new" />
      <Divider />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("general_year")}</label>
            <select name="year" defaultValue={currentYear} aria-expanded="true" ref={register}>
              {generalRateStateCustom.years.map((year, index) => (
                <option key={index} value={year.year}>{year.year}</option>
              ))}
            </select>  
          </Form.Field>
          <Form.Field required>
            <label>{t("general_rateType")}</label>
            <select name="rate_id" defaultValue={rateType} aria-expanded="true" ref={register({ required: true })}>
              <option />
              {generalRateStateCustom.rates.map((rateType) => (
                <option key={rateType.id} value={rateType.id}>{t(rateType.name)}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("general_yacht")}</label>
            <select name="yacht_id" defaultValue={yacht} aria-expanded="true" ref={register({ required: true })}>
              <option />
              {generalRateStateCustom.yachts.map((yacht) => (
                <option key={yacht.id} value={yacht.id}>{yacht.name}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("general_days")}</label>
            <select name="days" defaultValue={days} aria-expanded="true" ref={register}>
              <option value="all">{t("all")}</option>
              {generalRateStateCustom.days.map((day, index) => (
                 <option key={index} value={day.number_days}>{t(day.number_days)}</option>
              ))}
            </select>
          </Form.Field>
          <Form.Field>
            <label>&nbsp;</label>
            <Button type="submit" loading={generalRateStateCustom.fetching ? true : false} color="green"><Icon name='search' />{t("search_button")}</Button>
          </Form.Field>
        </Form.Group>
      </Form>

      {generalRateStateCustom.generalRates !== undefined ? (
        <Table color="blue" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>{t("general_year")}</Table.HeaderCell>
              <Table.HeaderCell>{t("general_rateType")}</Table.HeaderCell>
              <Table.HeaderCell>{t("general_yacht")}</Table.HeaderCell>
              <Table.HeaderCell>{t("general_days")}</Table.HeaderCell>
              <Table.HeaderCell>{t("general_cabin")}</Table.HeaderCell>
              <Table.HeaderCell>{t("general_double")}</Table.HeaderCell>
              <Table.HeaderCell>{t("general_single")}</Table.HeaderCell>
              <Table.HeaderCell>{t("general_child")}</Table.HeaderCell>
              <Table.HeaderCell>{t("state")}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {generalRateStateCustom.generalRates.map((generalRate) => (
            <Table.Row key={generalRate.id}>
              <Table.Cell collapsing><ActiveIcon active={generalRate.active} /></Table.Cell>
              <Table.Cell>{generalRate.year}</Table.Cell>
              <Table.Cell>{t(generalRate.rate)}</Table.Cell>
              <Table.Cell>{generalRate.yacht}</Table.Cell>
              <Table.Cell>{generalRate.days}</Table.Cell>
              <Table.Cell>
                {generalRate.cabins !== null ? (
                  <Link to={`/generalRates/${generalRate.id}/cabins`}>{generalRate.cabins}</Link>
                ) : (
                  <Link to={`/generalRates/${generalRate.id}/cabins`}><Button circular color='blue' icon='add' size="mini" /></Link>
                )}
              </Table.Cell>
              <Table.Cell><GeneralRateCell client={generalRate.double_client} operator={generalRate.double_operator} ticket={generalRate.airplane_ticket} /></Table.Cell>
              <Table.Cell><GeneralRateCell client={generalRate.single_client} operator={generalRate.single_operator} ticket={generalRate.airplane_ticket} /></Table.Cell>
              <Table.Cell><GeneralRateCell client={generalRate.child_client} operator={generalRate.child_operator} ticket={generalRate.airplane_ticket} /></Table.Cell>
              <Table.Cell width="2"><StateRateCell activation_date={generalRate.activation_date} inactivation_date={generalRate.inactivation_date} reason_deactivation={generalRate.reason_deactivation} /></Table.Cell>
              <Table.Cell collapsing>
                {generalRate.active != false ? (
                  <ChangeStateRateModal stateRate={generalRate} typeRate="GeneralRate" />
                ):(
                  null
                )}
                <Link to={`/generalRates/${generalRate.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
                <Modal
                  closeOnDimmerClick={false}
                  trigger={
                    <Button circular color='red' icon='trash alternate' />
                  }
                  content={t("general_delete")}
                  actions={[
                    t("canceled_button"),
                      {
                        key: "eliminar",
                        content: t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handleDeleteGeneralRate(generalRate.id),
                      },
                  ]}
                  size="tiny"
                />
              </Table.Cell>
            </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        ""
      )}
    </>
  );
}


