import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customCompanyUserForm = createSlice({
  name: 'customCompanyUserForm',
  initialState: {
    roles: [],
    user: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateCompanyUserFormCustom(state, action) {
      return {
        roles: [],
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesCompanyUserFormCustom(state, action) {
      return {
        roles: [],
        user: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchCompanyUserFormCustomSuccess(state, action) {
      return {
        roles: action.payload.roles,
        user: action.payload.user,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchCompanyUserFormCustomError(state, action) {
      return {
        roles: [],
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchCompanyUserFormCustom = (companyUser_id) => dispatch => {
  dispatch(requesCompanyUserFormCustom());
  axios.get(`/users/form/companyUsersForm?companyUser_id=${companyUser_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchCompanyUserFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCompanyUserFormCustom(companyUser_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCompanyUserFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateCompanyUserFormCustom,
  requesCompanyUserFormCustom,
  fetchCompanyUserFormCustomSuccess,
  fetchCompanyUserFormCustomError,
} = customCompanyUserForm.actions;

export default customCompanyUserForm.reducer;