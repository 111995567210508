import DeckList from "../containers/catalogs/deck/DeckList";
import DeckForm from "../containers/catalogs/deck/DeckForm";

const routes = [
  {
    path: "/decks",
    component: DeckList,
  },
  {
    path: "/decks_new",
    component: DeckForm,
  },
  {
    path: "/decks/:deck_id",
    component: DeckForm,
  },
];

export { routes as deckRoutes };
