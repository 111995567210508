import {
  INITIAL_STATE_DAYS_BY_ITINERARY,
  REQUEST_FETCH_DAYS_BY_ITINERARY,
  FETCH_DAYS_BY_ITINERARY_SUCCESS,
  FETCH_DAYS_BY_ITINERARY_ERROR,
} from "../../../actions/catalogs/day/fetchDaysByItinerary";

const initialState = {
  days: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const daysByItieneraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_DAYS_BY_ITINERARY: {
      return {
        ...state,
        days: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_DAYS_BY_ITINERARY: {
      return {
        ...state,
        days: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_DAYS_BY_ITINERARY_SUCCESS: {
      return {
        ...state,
        days: action.itineraryDays,
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case FETCH_DAYS_BY_ITINERARY_ERROR: {
      return {
        ...state,
        days: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default daysByItieneraryReducer;
