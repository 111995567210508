import {
  REQUEST_FETCH_CRUISE_RATES,
  FETCH_CRUISE_RATES_SUCCESS,
  FETCH_CRUISE_RATES_ERROR,
} from "../../../actions/reservations/cruiseRate/fetchCruiseRates";

import {
  INITIAL_STATE_CRUISE_RATE,
  REQUEST_FETCH_CRUISE_RATE,
  FETCH_CRUISE_RATE_SUCCESS,
  FETCH_CRUISE_RATE_ERROR,
} from "../../../actions/reservations/cruiseRate/fetchCruiseRate";

import {
  REQUEST_CREATE_CRUISE_RATE,
  CREATE_CRUISE_RATE_SUCCESS,
  CREATE_CRUISE_RATE_ERROR,
} from "../../../actions/reservations/cruiseRate/createCruiseRate";

import {
  REQUEST_UPDATE_CRUISE_RATE,
  UPDATE_CRUISE_RATE_SUCCESS,
  UPDATE_CRUISE_RATE_ERROR,
} from "../../../actions/reservations/cruiseRate/updateCruiseRate";

import {
  REQUEST_DELETE_CRUISE_RATE,
  DELETE_CRUISE_RATE_SUCCESS,
  DELETE_CRUISE_RATE_ERROR,
} from "../../../actions/reservations/cruiseRate/deleteCruiseRate";

const initialState = {
  cruiseRates: [],
  cruiseRate: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const cruiseRateReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_CRUISE_RATE: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_CRUISE_RATES: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISE_RATES_SUCCESS: {
      return {
        ...state,
        cruiseRates: action.cruiseRates,
        cruiseRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISE_RATES_ERROR: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_CRUISE_RATE: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISE_RATE_SUCCESS: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: action.cruiseRate,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISE_RATE_ERROR: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_CRUISE_RATE: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_CRUISE_RATE_SUCCESS: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_CRUISE_RATE_ERROR: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_CRUISE_RATE: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_CRUISE_RATE_SUCCESS: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_CRUISE_RATE_ERROR: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_CRUISE_RATE: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_CRUISE_RATE_SUCCESS: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_CRUISE_RATE_ERROR: {
      return {
        ...state,
        cruiseRates: [],
        cruiseRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default cruiseRateReducer;
