import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FlightService from "../../../../services/catalogs/FlightService";

export const REQUEST_CREATE_FLIGHT = "REQUEST_CREATE_FLIGHT";
export const CREATE_FLIGHT_SUCCESS = "CREATE_FLIGHT_SUCCESS";
export const CREATE_FLIGHT_ERROR = "CREATE_FLIGHT_ERROR";

function requestCreateFlight() {
  return {
    type: REQUEST_CREATE_FLIGHT,
  };
}

function createFlightSuccess(json) {
  return {
    type: CREATE_FLIGHT_SUCCESS,
    message: json.data,
  };
}

function createFlightError(json) {
  return {
    type: CREATE_FLIGHT_ERROR,
    error: json.data,
  };
}

export function createFlight(payload) {
  return (dispatch) => {
    dispatch(requestCreateFlight());
    return FlightService.postFlight(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createFlightSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createFlight(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createFlightError(json.data));
      }
    });
  };
}
