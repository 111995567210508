import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { fetchLanguages } from "../../../redux/actions/catalogs/language/fetchLanguages";
import { deleteLanguage } from "../../../redux/actions/catalogs/language/deleteLanguage";

// Resources
import { scrollToTop } from "../../resources/utils";


export default function LanguageList() {
  const { t } = useTranslation();
  const languageState = useSelector((state) => state.languageReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLanguages());
    scrollToTop();
  }, []);

  if (languageState.fetching || languageState.processing) {
    return <Loader active inline />;
  }

  if (languageState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (languageState.message === "resource deleted successfully") {
    dispatch(fetchLanguages());
  }

  const handleDeleteLanguage = (language_id) => {
    dispatch(deleteLanguage(language_id));
  };

  return (
    <>
      <HeaderCatalog title={t("lang_title")} message={languageState.message} to="/languages_new" />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("lang_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("lang_initials")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

      <Table.Body>
        {languageState.languages.map((language) => (
        <Table.Row key={language.id}>
          <Table.Cell>{t(language.name)}</Table.Cell>
          <Table.Cell>{t(language.short)}</Table.Cell>
          <Table.Cell collapsing>
            <Link to={`/languages/${language.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
            <Modal
              trigger={
                <Button circular color='red' icon='trash alternate' />
              }
              content={t("lang_delete")}
              actions={[
                t("canceled_button"),
                  {
                    key: "eliminar",
                    content: t("delete_button"),
                    className: "delete_button icon_color",
                    onClick: () => handleDeleteLanguage(language.id),
                  },
              ]}
              size="tiny"
            />
          </Table.Cell>
        </Table.Row>
        ))}
      </Table.Body>
      </Table>
    </>
  );
}
