import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import BerthService from "../../../../services/catalogs/BerthService";

export const REQUEST_CREATE_BERTH = "REQUEST_CREATE_BERTH";
export const CREATE_BERTH_SUCCESS = "CREATE_BERTH_SUCCESS";
export const CREATE_BERTH_ERROR = "CREATE_BERTH_ERROR";

function requestCreateBerth() {
  return {
    type: REQUEST_CREATE_BERTH,
  };
}

function createBerthSuccess(json) {
  return {
    type: CREATE_BERTH_SUCCESS,
    message: json.data,
  };
}

function createBerthError(json) {
  return {
    type: CREATE_BERTH_ERROR,
    error: json.data,
  };
}

export function createBerth(payload) {
  return (dispatch) => {
    dispatch(requestCreateBerth());
    return BerthService.postBerth(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createBerthSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createBerth(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createBerthError(json.data));
      }
    });
  };
}
