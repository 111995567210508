import {
  REQUEST_FETCH_CHARTER_RATES,
  FETCH_CHARTER_RATES_SUCCESS,
  FETCH_CHARTER_RATES_ERROR,
} from "../../../actions/rates/charterRates/fetchCharterRates";

import {
  INITIAL_STATE_CHARTER_RATE,
  REQUEST_FETCH_CHARTER_RATE,
  FETCH_CHARTER_RATE_SUCCESS,
  FETCH_CHARTER_RATE_ERROR,
} from "../../../actions/rates/charterRates/fetchCharterRate";

import {
  REQUEST_CREATE_CHARTER_RATE,
  CREATE_CHARTER_RATE_SUCCESS,
  CREATE_CHARTER_RATE_ERROR,
} from "../../../actions/rates/charterRates/createCharterRate";

import {
  REQUEST_UPDATE_CHARTER_RATE,
  UPDATE_CHARTER_RATE_SUCCESS,
  UPDATE_CHARTER_RATE_ERROR,
} from "../../../actions/rates/charterRates/updateCharterRate";

import {
  REQUEST_ACTIVE_CHARTER_RATE,
  ACTIVE_CHARTER_RATE_SUCCESS,
  ACTIVE_CHARTER_RATE_ERROR,
} from "../../../actions/rates/charterRates/activeCharterRate";

import {
  REQUEST_INACTIVE_CHARTER_RATE,
  INACTIVE_CHARTER_RATE_SUCCESS,
  INACTIVE_CHARTER_RATE_ERROR,
} from "../../../actions/rates/charterRates/inactiveCharterRate";

import {
  REQUEST_DELETE_CHARTER_RATE,
  DELETE_CHARTER_RATE_SUCCESS,
  DELETE_CHARTER_RATE_ERROR,
} from "../../../actions/rates/charterRates/deleteCharterRate";

import {
  REQUEST_FETCH_YEARS,
  FETCH_YEARS_SUCCESS,
  FETCH_YEARS_ERROR,
} from "../../../actions/rates/charterRates/fetchYears";

const initialState = {
  charterRates: [],
  charterRate: {},
  years: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const charterRateReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_CHARTER_RATE: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        years: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_CHARTER_RATES: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CHARTER_RATES_SUCCESS: {
      return {
        ...state,
        charterRates: action.charterRates,
        charterRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CHARTER_RATES_ERROR: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_CHARTER_RATE: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CHARTER_RATE_SUCCESS: {
      return {
        ...state,
        charterRates: [],
        charterRate: action.charterRate,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CHARTER_RATE_ERROR: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_CHARTER_RATE: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_CHARTER_RATE_SUCCESS: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_CHARTER_RATE_ERROR: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_CHARTER_RATE: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_CHARTER_RATE_SUCCESS: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_CHARTER_RATE_ERROR: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_CHARTER_RATE: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_ACTIVE_CHARTER_RATE: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case ACTIVE_CHARTER_RATE_SUCCESS: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case ACTIVE_CHARTER_RATE_ERROR: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_INACTIVE_CHARTER_RATE: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case INACTIVE_CHARTER_RATE_SUCCESS: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case INACTIVE_CHARTER_RATE_ERROR: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case DELETE_CHARTER_RATE_SUCCESS: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_CHARTER_RATE_ERROR: {
      return {
        ...state,
        charterRates: [],
        charterRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_YEARS: {
      return {
        ...state,
        years: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_SUCCESS: {
      return {
        ...state,
        years: action.years,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_ERROR: {
      return {
        ...state,
        years: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default charterRateReducer;
