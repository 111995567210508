import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

export default function GenderIcon(props) {
  const { t } = useTranslation();

  if (props.gender=="Male") {
    return (
        <>
          <Icon name="male" size="large" title= {t(props.gender)} />
        </>
      );
  }

  if (props.gender=="Female") {
    return (
        <>
          <Icon name="female" size="large" title= {t(props.gender)} />
        </>
      );
  }

  return (
    <>
      <Icon name="child" size="large" title= {t(props.gender)} />
    </>
  );
 
}
