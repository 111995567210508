import {
  REQUEST_FETCH_CRUISE_LANGUAGES,
  FETCH_CRUISE_LANGUAGES_SUCCESS,
  FETCH_CRUISE_LANGUAGES_ERROR,
} from "../../../actions/reservations/cruiseLanguage/fetchCuiseLanguages";

import {
  INITIAL_STATE_CRUISE_LANGUAGE,
  REQUEST_FETCH_CRUISE_LANGUAGE,
  FETCH_CRUISE_LANGUAGE_SUCCESS,
  FETCH_CRUISE_LANGUAGE_ERROR,
} from "../../../actions/reservations/cruiseLanguage/fetchCruiseLanguage";

import {
  REQUEST_CREATE_CRUISE_LANGUAGE,
  CREATE_CRUISE_LANGUAGE_SUCCESS,
  CREATE_CRUISE_LANGUAGE_ERROR,
} from "../../../actions/reservations/cruiseLanguage/createCruiseLanguage";

import {
  REQUEST_UPDATE_CRUISE_LANGUAGE,
  UPDATE_CRUISE_LANGUAGE_SUCCESS,
  UPDATE_CRUISE_LANGUAGE_ERROR,
} from "../../../actions/reservations/cruiseLanguage/updateCruiseLanguage";

import {
  REQUEST_DELETE_CRUISE_LANGUAGE,
  DELETE_CRUISE_LANGUAGE_SUCCESS,
  DELETE_CRUISE_LANGUAGE_ERROR,
} from "../../../actions/reservations/cruiseLanguage/deleteCruiseLanguage";

const initialState = {
  cruiseLanguages: [],
  cruiseLanguage: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const cruiseLanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_CRUISE_LANGUAGE: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_CRUISE_LANGUAGES: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISE_LANGUAGES_SUCCESS: {
      return {
        ...state,
        cruiseLanguages: action.cruiseLanguages,
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISE_LANGUAGES_ERROR: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_CRUISE_LANGUAGE: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: action.cruiseLanguage,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISE_LANGUAGE_ERROR: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_CRUISE_LANGUAGE: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_CRUISE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_CRUISE_LANGUAGE_ERROR: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_CRUISE_LANGUAGE: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_CRUISE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_CRUISE_LANGUAGE_ERROR: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_CRUISE_LANGUAGE: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_CRUISE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_CRUISE_LANGUAGE_ERROR: {
      return {
        ...state,
        cruiseLanguages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default cruiseLanguageReducer;
