import CompanyList from "../containers/catalogs/company/CompanyList";
import CompanyForm from "../containers/catalogs/company/CompanyForm";

const routes = [
  {
    path: "/companies",
    component: CompanyList,
  },
  {
    path: "/companies_new",
    component: CompanyForm,
  },
  {
    path: "/companies/:company_id",
    component: CompanyForm,
  },
];

export { routes as companyRoutes };
