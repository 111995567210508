import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import RouteService from "../../../../services/catalogs/RouteService";

export const REQUEST_FETCH_ROUTES = "REQUEST_FETCH_ROUTES";
export const FETCH_ROUTES_SUCCESS = "FETCH_ROUTES_SUCCESS";
export const FETCH_ROUTES_ERROR = "FETCH_ROUTES_ERROR";

function requestFetchRoutes() {
  return {
    type: REQUEST_FETCH_ROUTES,
  };
}

function fetchRoutesSuccess(json) {
  return {
    type: FETCH_ROUTES_SUCCESS,
    routes: json.data,
  };
}

function fetchRoutesError(json) {
  return {
    type: FETCH_ROUTES_ERROR,
    error: json.data,
  };
}

export function fetchRoutes() {
  return (dispatch) => {
    dispatch(requestFetchRoutes());
    return RouteService.getRoutes().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchRoutesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchRoutes());
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchRoutesError(json.data));
      }
    });
  };
}
