import {
  REQUEST_FETCH_CABINS,
  FETCH_CABINS_SUCCESS,
  FETCH_CABINS_ERROR,
  INITIAL_STATE_CABIN,
} from "../../../actions/rates/familyRoom/fetchCabinsByYacht";

const initialState = {
  cabins: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const cabinByYachtReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_CABIN: {
      return {
        ...state,
        cabins: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_CABINS: {
      return {
        ...state,
        cabins: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CABINS_SUCCESS: {
      return {
        ...state,
        cabins: action.cabins,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CABINS_ERROR: {
      return {
        ...state,
        cabins: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default cabinByYachtReducer;
