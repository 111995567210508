import AgencyUserList from "../containers/catalogs/user/AgencyUserList";
import CompanyUserList from "../containers/catalogs/user/CompanyUserList";
import AgencyUserForm from "../containers/catalogs/user/AgencyUserForm";
import CompanyUserForm from "../containers/catalogs/user/CompanyUserForm";

const routes = [
  {
    path: "/users",
    component: CompanyUserList,
  },
  {
    path: "/agencies/:agency_id/users",
    component: AgencyUserList,
  },
  {
    path: "/users/users_new",
    component: CompanyUserForm,
  },
  {
    path: "/users/:user_id",
    component: CompanyUserForm,
  },
  {
    path: "/agencies/:agency_id/users_new",
    component: AgencyUserForm,
  },
  {
    path: "/agencies/:agency_id/users/:user_id",
    component: AgencyUserForm,
  },
];

export { routes as userRoutes };
