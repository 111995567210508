import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class PassengerService {
  static getPassengersInFlight(cruise_id) {
    return axios.get(
      "cruise/" + cruise_id + "/passengersInFlight"
    );
  }

  static getPassengersZarpe(cruise_id) {
    return axios.get("cruise/" + cruise_id + "/passengersZarpe");
  }

  static getPassengersSelected(cruise_id, payload) {
    return axios.post(
      "cruise/" + cruise_id + "/passengersSelected",
      payload
    );
  }

  static getPassengersIngala(cruise_id) {
    return axios.get(
      "cruise/" + cruise_id + "/passengersIngala"
    );
  }

  static getSearchPassengers(name, passport) {
    var url = "searchPassengers";
    if (name !== null) {
      url = url + "?name=" + name;
    } else if (passport !== null) {
      url = url + "?passport=" + passport;
    }
    return axios.get(url);
  }

  static getPassenger(passenger_id) {
    return axios.get("passenger/" + passenger_id);
  }

  static getCruisesByPassenger(passenger_id) {
    return axios.get("passenger/" + passenger_id + "/cruises");
  }
}
