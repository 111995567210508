import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class IdentificationTypeService {
  static getIdentificationTypes() {
    return axios.get("identificationTypes");
  }
  static getIdentificationType(identification_type_id) {
    return axios.get(
      "identificationTypes/" + identification_type_id
    );
  }

  static postIdentificationType(payload) {
    return axios.post("identificationTypes", payload);
  }

  static putIdentificationType(identification_type_id, payload) {
    return axios.put(
      "identificationTypes/" + identification_type_id,
      payload
    );
  }

  static deleteIdentificationType(identification_type_id) {
    return axios.delete(
      "identificationTypes/" + identification_type_id
    );
  }
}
