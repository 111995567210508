import CompanyService from "../../../../services/catalogs/CompanyService";
import { updateLocalStorageToken, logOut } from "../../../../containers/resources/utils";

export const REQUEST_FETCH_COMPANIES = "REQUEST_FETCH_COMPANIES";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_ERROR = "FETCH_COMPANIES_ERROR";

function requestFetchCompanies() {
  return {
    type: REQUEST_FETCH_COMPANIES,
  };
}

function fetchCompaniesSuccess(json) {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    companies: json.data,
  };
}

function fetchCompaniesError(json) {
  return {
    type: FETCH_COMPANIES_ERROR,
    error: json.data,
  };
}

export function fetchCompanies() {
  return (dispatch) => {
    dispatch(requestFetchCompanies());
    return CompanyService.getCompanies().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCompaniesSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token); 
        dispatch(fetchCompanies());
      }else if (json.data.code === 498){
        logOut();
      }else {
        dispatch(fetchCompaniesError(json.data));
      }
    });
  };
}
