import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customGpsTaxList = createSlice({
  name: 'customGpsTaxList',
  initialState: {
    cruise: [],
    guidesOnBoard: [],
    reservations: [],
    passengers: [],
    company: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateGpsTaxListCustom(state, action) {
      return {
        cruise: [],
        guidesOnBoard: [],
        reservations: [],
        passengers: [],
        company: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesGpsTaxListCustom(state, action) {
      return {
        cruise: [],
        guidesOnBoard: [],
        reservations: [],
        passengers: [],
        company: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchGpsTaxListCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        reservations: action.payload.reservations,
        guidesOnBoard: action.payload.guidesOnBoard,
        passengers: action.payload.passengers,
        company: action.payload.company,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchGpsTaxListCustomError(state, action) {
      return {
        cruise: [],
        guidesOnBoard: [],
        reservations: [],
        passengers: [],
        company: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchGpsTaxListCustom = (cruise_id) => dispatch => {
  dispatch(requesGpsTaxListCustom());
  axios.get(`/cruise/${cruise_id}/gpsTaxList`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchGpsTaxListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGpsTaxListCustom(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGpsTaxListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateGpsTaxListCustom,
  requesGpsTaxListCustom,
  fetchGpsTaxListCustomSuccess,
  fetchGpsTaxListCustomError,
} = customGpsTaxList.actions;

export default customGpsTaxList.reducer;