import {
  REQUEST_FETCH_LANGUAGES,
  FETCH_LANGUAGES_SUCCESS,
  FETCH_LANGUAGES_ERROR,
} from "../../../actions/catalogs/language/fetchLanguages";

import {
  INITIAL_STATE_LANGUAGE,
  REQUEST_FETCH_LANGUAGE,
  FETCH_LANGUAGE_SUCCESS,
  FETCH_LANGUAGE_ERROR,
} from "../../../actions/catalogs/language/fetchLanguage";

import {
  REQUEST_CREATE_LANGUAGE,
  CREATE_LANGUAGE_SUCCESS,
  CREATE_LANGUAGE_ERROR,
} from "../../../actions/catalogs/language/createLanguage";

import {
  REQUEST_UPDATE_LANGUAGE,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_LANGUAGE_ERROR,
} from "../../../actions/catalogs/language/updateLanguage";

import {
  REQUEST_DELETE_LANGUAGE,
  DELETE_LANGUAGE_SUCCESS,
  DELETE_LANGUAGE_ERROR,
} from "../../../actions/catalogs/language/deleteLanguage";

const initialState = {
  languages: [],
  language: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_LANGUAGE: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_LANGUAGES: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_LANGUAGES_SUCCESS: {
      return {
        ...state,
        languages: action.languages,
        language: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_LANGUAGES_ERROR: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_LANGUAGE: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_LANGUAGE_SUCCESS: {
      return {
        ...state,
        languages: [],
        language: action.language,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_LANGUAGE_ERROR: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_LANGUAGE: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_LANGUAGE_ERROR: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_LANGUAGE: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_LANGUAGE_ERROR: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_LANGUAGE: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_LANGUAGE_ERROR: {
      return {
        ...state,
        languages: [],
        language: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default languageReducer;
