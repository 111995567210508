
import React from "react";
import { useTranslation } from "react-i18next";
import { List, Header } from "semantic-ui-react";

export default function IkarusRateCell(props) {
  const { t } = useTranslation();
  return (
    <>
      <Header size='tiny' block>
      {t("range")}: {props.from} - {props.to} PAX
      </Header>
      <Header size='tiny' color='blue'>{t("general_double")}</Header>
      <List horizontal divided relaxed>
        <List.Item>
          <List.Content >
            <List.Header >{props.double_client}</List.Header>
            <List.Description>{t("general_client")}</List.Description>
          </List.Content>
          <br></br>
          <List.Content>
            <List.Header>{props.double_operator}</List.Header>
            <List.Description>{t("operator")}</List.Description>
          </List.Content>
      </List.Item>
      </List>
      <Header size='tiny' color='blue'>{t("general_single")}</Header>
      <List horizontal divided relaxed>
        <List.Item>
          <List.Content >
            <List.Header >{props.single_client}</List.Header>
            <List.Description>{t("general_client")}</List.Description>
          </List.Content>
          <br></br>
          <List.Content>
            <List.Header>{props.single_operator}</List.Header>
            <List.Description>{t("operator")}</List.Description>
          </List.Content>
        </List.Item>
      </List>
    </>
  );
}
  