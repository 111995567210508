import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "../containers/styles/common.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "10%",
    marginTop: -12,
    marginLeft: -82,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

export default function PassengerList(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  let passengers = JSON.parse(sessionStorage.getItem("passengers")) || [];
  let newPassengers = [];
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [dispatch]);

  passengers.passengers.forEach((e) => {
    let date = e.birthdate.split("-");
    let ageDifMs =
      Date.now() - new Date(`${date[1]}/${date[2]}/${date[0]}`).getTime();
    let ageDate = new Date(ageDifMs);
    let age = Math.abs(ageDate.getUTCFullYear() - 1970);
    newPassengers = newPassengers.concat(...[{ ...e, age: age }]);
  });

  return (
    <div>
      {newPassengers.map((passenger, index) => (
        <div key={index}>
          <br />
          <div
            style={{ fontSize: "18px", fontWeight: "bold", color: "#2C8ABD" }}
          >
            {" "}
            {t("passenger_cabin")} {passenger.cabin} - {t("passenger_info")}{" "}
            {index + 1}
          </div>
          <br />

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                <React.Fragment>
                  <TableRow>
                    <TableCell width="100px">
                      <span className="passenger_list_head">
                        {t("passenger_path")}{" "}
                      </span>
                    </TableCell>
                    <TableCell align="left" width="150px">
                      <span className="passenger_list_body">
                        {passenger.first_name} {passenger.last_name}
                      </span>
                    </TableCell>
                    <TableCell width="100px">
                      <span className="passenger_list_head">
                        {t("passenger_nationality")}
                      </span>
                    </TableCell>
                    <TableCell align="left" width="150px">
                      <span className="passenger_list_body">
                        {passenger.country_name}
                      </span>
                    </TableCell>
                    <TableCell width="150px">
                      <span className="passenger_list_head">
                        {t("departure_route")}
                      </span>
                    </TableCell>
                    <TableCell width="250px" align="left">
                      <span className="passenger_list_body">
                        {passenger.departure_name}
                        <span style={{ paddingLeft: "20px" }}>
                          {passenger.date_departure
                            .split("-")
                            .reverse()
                            .join("/")}
                        </span>
                      </span>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell width="100px">
                      <span className="passenger_list_head">
                        {t("passenger_email")}
                      </span>
                    </TableCell>
                    <TableCell width="150px">
                      <span className="passenger_list_body">
                        {passenger.passenger_email}
                      </span>
                    </TableCell>
                    <TableCell width="100px">
                      <span className="passenger_list_head">
                        {t("passenger_birthdate")}
                      </span>
                    </TableCell>
                    <TableCell width="150px">
                      <span className="passenger_list_body">
                        {passenger.birthdate.split("-").reverse().join("/")}
                      </span>
                    </TableCell>

                    <TableCell width="100px">
                      <span className="passenger_list_head">
                        {t("return_route")}
                      </span>
                    </TableCell>
                    <TableCell width="150px">
                      <span className="passenger_list_body">
                        {passenger.return_name}
                        <span style={{ paddingLeft: "20px" }}>
                          {passenger.return_date.split("-").reverse().join("/")}
                        </span>
                      </span>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell width="220px">
                      <span className="passenger_list_head">
                        {t("passenger_passport_number")}
                      </span>
                    </TableCell>
                    <TableCell width="150px">
                      <span className="passenger_list_body">
                        {passenger.passport_number}
                      </span>
                    </TableCell>
                    <TableCell width="100px">
                      <span className="passenger_list_head">
                        {t("passenger_age")}
                      </span>
                    </TableCell>
                    <TableCell width="150px">
                      <span className="passenger_list_body">
                        {passenger.age} {t("passenger_search_years_old")}
                      </span>
                    </TableCell>

                    <TableCell width="100px">
                      <span className="passenger_list_head">
                        {t("passenger_gender")}
                      </span>
                    </TableCell>

                    <TableCell width="150px">
                      <span className="passenger_list_body">
                        {passenger.gender_name}
                      </span>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell width="100px">
                      <span className="passenger_list_head">
                        {t("passenger_marital_satatus")}
                      </span>
                    </TableCell>
                    <TableCell width="150px">
                      <span className="passenger_list_body">
                        {t(passenger.status)}
                      </span>
                    </TableCell>
                    <TableCell colSpan={1} width="200px">
                      <span className="passenger_list_head">
                        {t("passenger_aditional_notes")}
                      </span>
                    </TableCell>
                    <TableCell colSpan={3}>
                      <span className="passenger_list_body">
                        {passenger.message}
                      </span>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#2C8ABD",
                        }}
                      >
                        Total:
                      </span>{" "}
                    </TableCell>
                    <TableCell>
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#2C8ABD",
                        }}
                      >
                        $3300
                      </span>{" "}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ))}
      <br />
      <br />
    </div>
  );
}
