import jsPDF from "jspdf";
import "jspdf-autotable";
import path from "path";

// Resources
import rolfWittmerLogo from '../../../icons/rolfwittmer_logo.png';
import galanauticalLogo from '../../../icons/galanautical_logo.png';
import galamarexLogo from '../../../icons/galamarex_logo.png';
import kolnLogo from '../../../icons/koln_logo.png';
import footer from '../../../icons/footer.jpg'
import header from '../../../icons/header.png'

export default function gpsTaxPDF(
  company,
  cruise,
  guide,
  passengers,
  informationPayment,
  reservation
) {
  var doc = new jsPDF("p", "pt");

  doc.setFont("helvetica", "normal");
  var yIncrementalPosition = 130;
  var lineBreak = 12;
  var doubleLineBreak = 24;
  var tripleLineBreak = 36;
  const imgWidth = 600; 
  const imgHeight = 40; 
  const imgX = 0; 
  const imgY = doc.internal.pageSize.height - imgHeight - 10;

  var columnsBody = [
    "No.",
    "Nombre",
    "País",
    "Adulto / Niño",
    "No. Pasaporte",
    "Valor",
  ];
  var rowsBody = [];
  var priceTotal = 0;

  passengers.map((passenger, index) => {
    var passengerName =
      passenger.last_name.toUpperCase() +
      " / " +
      passenger.first_name.toUpperCase();
    var gender = passenger.gender === "Niño" ? "Niño" : "Adulto";
    var gpsTaxLocal = parseInt(passenger.gps_tax_local);
    var gpsTaxForeign = parseInt(passenger.gps_tax_foreign);
    var gpsTaxForeignAndeanPact = parseInt(
      passenger.gps_tax_foreign_andean_pact
    );

    var price = 0;
    if (passenger.country === "Ecuador") {
      price = gpsTaxLocal;
    } else if (passenger.andean_pact === true) {
      price = gpsTaxForeignAndeanPact;
    } else {
      price = gpsTaxForeign;
    }
    priceTotal = priceTotal + price;

    var passengerRow = [
      index + 1,
      passengerName,
      passenger.country,
      gender,
      passenger.identification,
      "$ " + price,
    ];
    return rowsBody.push(passengerRow);
  });

  const fortmatDate = (date) => {
    var d = new Date(date);
    var options = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" };
    var date = (d.toLocaleDateString("es", options).split("/").join("-")).split(',')
    let dateFormat = date[0].split('-').reverse().join('/') + date[1]
    return dateFormat;
  };

  var writtenNumber = require("written-number");
  var priceTotalWords = writtenNumber(priceTotal, { lang: "es" });

  // HEADER
  doc.setFontSize(10);

  var date = new Date();
  var monthList = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  var gpsTaxFooter = new Image();
  gpsTaxFooter.src = path.resolve(footer);

  var gpsTaxHeader = new Image();
  gpsTaxHeader.src = path.resolve(header);

  var reportLogo = new Image();
  if (company.main_logo === "rolfwittmer_logo.png") {
    reportLogo.src = path.resolve(rolfWittmerLogo);
  }
  if (company.main_logo === "galanautical_logo.png") {
    reportLogo.src = path.resolve(galanauticalLogo);
  }
  if (company.main_logo === "koln_logo.png") {
    reportLogo.src = path.resolve(kolnLogo);
  }
  if (company.main_logo === "galamarex_logo.png") {
    reportLogo.src = path.resolve(galamarexLogo);
  }

  doc.addImage(gpsTaxHeader, "JPEG", 30, 10, 500, 40);

  if (company.main_logo === "galanautical_logo.png") {
  doc.addImage(reportLogo, "JPEG", 30, 70, 171, 81);
  }else {
  doc.addImage(reportLogo, "JPEG", 30, 70, 226, 50);
  }

  doc.text("LUGAR Y FECHA: Puerto Ayora, " + date.getDate() + " de " + monthList[date.getMonth()] + " del " + date.getFullYear(), 312, yIncrementalPosition);

  doc.text("Señores:", 30, (yIncrementalPosition += tripleLineBreak));
  doc.text("PARQUE NACIONAL GALAPAGOS", 30, (yIncrementalPosition += lineBreak));
  doc.text("Presente.-", 30, (yIncrementalPosition += lineBreak));

  doc.text("De mis consideraciones:", 30, (yIncrementalPosition += doubleLineBreak));

  var charter = reservation.charter === true ? "charter" : "";

  var text1 =
  "Adjunto a la presente sírvase encontrar,el comprobante de pago por el valor de $" + priceTotal
  + " que corresponde al ingreso de turistas a las Áreas Protegidas de Galápagos de " + passengers.length + " pasajeros, que ingresan en el vuelo "+ passengers[0].flight_name 
  + " el dia " + cruise.start_date_format + " a las " + passengers[0].flight_time + " horas.";

  var splitTitle = doc.splitTextToSize(text1, 520);
  doc.text(splitTitle, 30, (yIncrementalPosition += doubleLineBreak));

  doc.setFont("helvetica",'bold');
  doc.text("Embarcación: " + cruise.yacht_name + " - " + cruise.start_date_format + " - " + cruise.end_date_format + ".", 30, (yIncrementalPosition += tripleLineBreak + lineBreak));
  doc.text("Nombre del Guía: " + guide , 30, (yIncrementalPosition += lineBreak));

  const text = "AEROPUERTO DE INGRESO"
  doc.text(text, 30, yIncrementalPosition += doubleLineBreak);
  const textWidth = doc.getTextWidth(text);
  doc.line(30, yIncrementalPosition += 2, 30 + textWidth, yIncrementalPosition)

  doc.setFont("helvetica",'normal');

  doc.text("*BALTRA", 30, yIncrementalPosition += lineBreak);
  if (passengers[0].destination_code === "GPS") {
      doc.rect(130, yIncrementalPosition - 8, 10, 10, 'F');
  } else {
      doc.rect(130, yIncrementalPosition - 8, 10, 10, 'S');
  }

  doc.text("*SAN CRISTOBAL", 230, yIncrementalPosition);
  if (passengers[0].destination_code == "SCY") {
      doc.rect(330, yIncrementalPosition - 8, 10, 10, 'F');
  } else {
      doc.rect(330, yIncrementalPosition - 8, 10, 10, 'S');
  }

  doc.text("*ISABELA", 430, yIncrementalPosition);
  if (passengers[0].destination_code == "ISB") {
      doc.rect(530, yIncrementalPosition - 8, 10, 10, 'F');
  } else {
      doc.rect(530, yIncrementalPosition - 8, 10, 10, 'S');
  }

  doc.setFont("helvetica",'bold');
  const bankEntityTitle = "ENTIDAD BANCARIA DE TRANSACCIÓN"
  doc.text(bankEntityTitle, 30, yIncrementalPosition += doubleLineBreak);
  const textWidthBankEntity = doc.getTextWidth(bankEntityTitle);
  doc.line(30, yIncrementalPosition += 2, 30 + textWidthBankEntity, yIncrementalPosition)

  doc.setFont("helvetica",'normal');
  doc.text("*PACIFICO", 30, yIncrementalPosition += lineBreak);

  doc.setFillColor(0, 0, 0);
  doc.rect(130, yIncrementalPosition - 8, 10, 10, 'F');

  doc.setFont("helvetica",'bold');
  const textPaymentType = "FORMA DE PAGO"
  doc.text(textPaymentType, 30, yIncrementalPosition += doubleLineBreak);
  const textWidthPaymentType = doc.getTextWidth(textPaymentType);
  doc.line(30, yIncrementalPosition += 2, 30 + textWidthPaymentType, yIncrementalPosition)

  doc.setFont("helvetica",'normal');
  doc.text("*CHEQUE CERTIFICADO", 30, yIncrementalPosition += lineBreak);

  doc.setFillColor(255, 255, 200);
  doc.rect(150, yIncrementalPosition - 8, 10, 10, 'S');

  doc.text("*TRANSFERENCIA", 230, yIncrementalPosition);

  doc.setFillColor(0, 0, 0);
  doc.rect(330, yIncrementalPosition - 8, 10, 10, 'F');


  doc.text("*DEPÓSITO", 430, yIncrementalPosition);

  doc.setFillColor(255, 255, 200);
  doc.rect(530, yIncrementalPosition - 8, 10, 10, 'S');

  yIncrementalPosition += lineBreak

  doc.setFont("helvetica",'bold');
  doc.text("FECHA Y HORA REAL EN LA QUE SE REALIZA EL PAGO:", 30, yIncrementalPosition += doubleLineBreak);
  doc.setFont("helvetica",'normal');

  if (informationPayment.paymentDate != undefined) {
       doc.text(fortmatDate(informationPayment.paymentDate), 370, yIncrementalPosition);
  }

  doc.setFont("helvetica",'bold');
  doc.text("VALOR PROCESADO:", 30, yIncrementalPosition += lineBreak);
  doc.setFont("helvetica",'normal');

  if (informationPayment.paymentValue != undefined) {
       doc.text(informationPayment.paymentValue, 370, yIncrementalPosition);
  }

  doc.setFont("helvetica",'bold');
  doc.text("REFERENCIA BANCO# / NUMERO DE PAPELETA:", 30, yIncrementalPosition += lineBreak);
  doc.setFont("helvetica",'normal');

  if (informationPayment.paymentReference != undefined) {
      doc.text(informationPayment.paymentReference, 370, yIncrementalPosition);
  }

  doc.setFont("helvetica",'bold');
  doc.text("DESCRIPCIÓN, CONCEPTO O REFERENCIA DE LA TRANSACCIÓN:", 30, yIncrementalPosition += lineBreak);
  doc.setFont("helvetica",'normal');

  if (informationPayment.paymentDescription != undefined) {
      doc.text(informationPayment.paymentDescription, 30, yIncrementalPosition += lineBreak);
  }

  doc.setFont("helvetica",'normal');
  doc.text("A continuación detallo nombres y número de pasajeros:", 30, yIncrementalPosition += doubleLineBreak);

  // BODY
  doc.autoTable(columnsBody, rowsBody, {
    theme: "grid",
    startY: (yIncrementalPosition += doubleLineBreak),
    headStyles: {
      fillColor: [28, 132, 198],
    },
  });

  yIncrementalPosition = doc.lastAutoTable.finalY;

  // BODYINVOICE
  createInvoiceSection(doc, yIncrementalPosition, tripleLineBreak, company);

  yIncrementalPosition = doc.lastAutoTable.finalY;

  doc.text("Atentamente,", 30, (yIncrementalPosition += tripleLineBreak));
  doc.setFont("helvetica",'bold');
  doc.text(company.comercial_name, 30, (yIncrementalPosition += tripleLineBreak));
  doc.text(company.yacht_name, 30, (yIncrementalPosition += lineBreak));
// ADD FOOTER
  addFooter(doc);
  
  // GENERATE PDF
  doc.save("GPSTax.pdf");

  function createInvoiceSection(doc, yIncrementalPosition, doubleLineBreak, company) {
    var columnsHeaderInvoiceInfo = ["DATOS PARA FACTURACIÓN"];
    var rowsHeaderInvoiceInfo = [];

    doc.autoTable(columnsHeaderInvoiceInfo, rowsHeaderInvoiceInfo, {
      theme: 'grid',
      startY: yIncrementalPosition += doubleLineBreak,
      headerStyles: {
        fillColor: [28, 132, 198]
      }
    });

    createNotePassengerTable(doc, "Ruc:", company.ruc);
    createNotePassengerTable(doc, "Razón Social:", company.comercial_name);
    createNotePassengerTable(doc, "Correo Electrónico:", company.email);
    createNotePassengerTable(doc, "Nro. Teléfono de Contacto", company.contact_phone);

    var columnsHeaderInvoiceInfoBank = ["FORMULARIO DE REGISTRO DE CUENTA BANCARIA PARA DEVOLUCIÓN POR EXCEDENTE EN RECAUDACIÓN DE TRIBUTO (DEBE SER LA CUENTA A NOMBRE DE QUIEN SE EMITE LA FACTURA)"];
    var rowsHeaderInvoiceInfoBank = [];

    doc.autoTable(columnsHeaderInvoiceInfoBank, rowsHeaderInvoiceInfoBank, {
      theme: 'grid',
      //overflow: 'linebreak',
      startY: doc.autoTableEndPosY(),
      headerStyles: {
        fillColor: [28, 132, 198]
      }
    });

    createNotePassengerTable(doc, "Banco del Beneficiario:", company.beneficiary_bank);
    createNotePassengerTable(doc, "Número y tipo de cuenta del Beneficiario:", company.beneficiaryAccount_number + " " + company.beneficiaryAccount_type);
    createNotePassengerTable(doc, "Dirección del Beneficiario:", company.beneficiary_adress);

  }

  function createNotePassengerTable(doc, typeNote, note) {
    var columnNotePassenger = [
      { title: "", dataKey: "label" },
      { title: "", dataKey: "name" }
    ];

    var rowNotePassenger = [
      { "label": typeNote, "name": note }
    ];

    doc.autoTable(columnNotePassenger, rowNotePassenger, {
      //  overflow: 'linebreak',
      drawHeaderRow: function () {
        return false; // Don't draw header row
      },
      startY: doc.autoTableEndPosY(),
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 0,
        fontSize: 8
      },
      columnStyles: {
        label: { columnWidth: 130 },
        name: { fillColor: [255, 255, 255] }
      }
    });
  }

  function addFooter(doc) {
    for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
      doc.setPage(i)

      // doc.addImage(gpsTaxFooter, 'PNG', 0, 800, 560, 46);
  
      doc.addImage(gpsTaxFooter, 'JPEG', imgX, imgY, imgWidth, imgHeight);
    }
  };
}
