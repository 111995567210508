import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createGender } from "../../../redux/actions/catalogs/gender/createGender";
import { fetchGender, initialStateGender } from "../../../redux/actions/catalogs/gender/fetchGender";
import { updateGender } from "../../../redux/actions/catalogs/gender/updateGender";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function GenderForm() {
  const sixDotTwoRegex = new RegExp(/^\d{0,6}(\.\d{0,2})?$/);

  const { t } = useTranslation();
  let history = useHistory();
  let { gender_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const genderState = useSelector((state) => state.genderReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (gender_id) {
      dispatch(fetchGender(gender_id));
    }
    scrollToTop();

    return () => {
      dispatch(initialStateGender());
    };
  }, []);

  if (genderState.fetching) {
    return <Loader active inline />;
  }

  if (genderState.error) {
    return  <Message negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (gender_id) {
      dispatch(updateGender(gender_id, data));
    } else {
      dispatch(createGender(data));
    }
  };

  if (genderState.message === "resource created successfully" || genderState.message === "resource updated successfully") {
    history.push("/genders");
  }

  return (
    <>
      <Header as='h3' dividing>{t("gender_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("gender_name")}</label>
            <input name="name" defaultValue={genderState.gender.name} placeholder={t("gender_name")} maxLength={50} ref={register({ required: true })} autoFocus />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("gender_initials")}</label>
            <input name="short" defaultValue={genderState.gender.short} placeholder={t("gender_initials")} maxLength={50} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("gender_icon")}</label>
            <input name="icon" defaultValue={genderState.gender.icon} placeholder={t("gender_icon")} maxLength={50} ref={register} />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("gender_national")}</label>
            <input name="gps_tax_local" defaultValue={genderState.gender.gps_tax_local} placeholder={t("gender_national")} min={0} maxLength={15} ref={register({ pattern: sixDotTwoRegex, required: true })} />
            {errors.gps_tax_local && errors.gps_tax_local.type === "required" && <span className="error_message">{t("required_information")}</span>}
            {errors.gps_tax_local && errors.gps_tax_local.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
          </Form.Field>
          <Form.Field required>
            <label>{t("gender_foreign")}</label>
            <input name="gps_tax_foreign" defaultValue={genderState.gender.gps_tax_foreign} placeholder={t("gender_foreign")} min={0} maxLength={15} ref={register({ pattern: sixDotTwoRegex, required: true })} />
            {errors.gps_tax_foreign && errors.gps_tax_foreign.type === "required" && <span className="error_message">{t("required_information")}</span>}
            {errors.gps_tax_foreign && errors.gps_tax_foreign.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
          </Form.Field>
          <Form.Field required>
            <label>{t("gender_andean_pact")}</label>
            <input name="gps_tax_foreign_andean_pact" defaultValue={genderState.gender.gps_tax_foreign_andean_pact} placeholder={t("gender_andean_pact")} min={0} maxLength={15} ref={register({ pattern: sixDotTwoRegex, required: true })} />
            {errors.gps_tax_foreign_andean_pact && errors.gps_tax_foreign_andean_pact.type === "required" && <span className="error_message">{t("required_information")}</span>}
            {errors.gps_tax_foreign_andean_pact && errors.gps_tax_foreign_andean_pact.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("passenger_tct")}</label>
            <input name="tct" defaultValue={genderState.gender.tct} placeholder={t("passenger_tct")} min={0} maxLength={15} ref={register({ pattern: sixDotTwoRegex, required: true })} />
            {errors.tct && errors.tct.type === "required" && <span className="error_message">{t("required_information")}</span>}
            {errors.tct && errors.tct.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
          </Form.Field>
          <Form.Field></Form.Field>
          <Form.Field></Form.Field>
        </Form.Group>

        <Divider />

        <Button primary type="submit" loading={genderState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/genders"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
