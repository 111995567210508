import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import DayService from "../../../../services/catalogs/DayService";

export const REQUEST_FETCH_DAY = "REQUEST_FETCH_DAY";
export const FETCH_DAY_SUCCESS = "FETCH_DAY_SUCCESS";
export const FETCH_DAY_ERROR = "FETCH_DAY_ERROR";
export const INITIAL_STATE_DAY = "INITIAL_STATE_DAY";

function requestFetchDay() {
  return {
    type: REQUEST_FETCH_DAY,
  };
}

function fetchDaySuccess(json) {
  return {
    type: FETCH_DAY_SUCCESS,
    day: json.data,
  };
}

function fetchDayError(json) {
  return {
    type: FETCH_DAY_ERROR,
    error: json.data,
  };
}

export function initialStateDay() {
  return {
    type: INITIAL_STATE_DAY,
  };
}

export function fetchDay(day_id) {
  return (dispatch) => {
    dispatch(requestFetchDay());
    return DayService.getDay(day_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchDaySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchDay(day_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchDayError(json.data));
      }
    });
  };
}
