import IngalaList from "../containers/reservations/cruise/ingala/IngalaList";

const routes = [
  {
    path: "/cruises/:cruise_id/ingalaReport",
    component: IngalaList,
  },
];

export { routes as passengerIngalaRoutes };
