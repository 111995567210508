import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Icon, Form, Divider } from "semantic-ui-react";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";
import { useTranslation } from "react-i18next";
import StateRateCell from "../../../components/rates/stateRateCell"
import ChangeStateRateModal from "../../../components/rates/changeStateRateModal"

//Actions
import { deleteContractRate } from "../../../redux/actions/rates/contractRates/deleteContractRate";
import { initialStateContractRate } from "../../../redux/actions/rates/contractRates/fetchContractRate";
import { fetchContractRateListCustom, initialStateContractRateListCustom } from "../../../redux/slices/custom/rates/contractRate/customContractRateList.slice";

import GeneralRateCell from "../../../components/rates/generalRateCell"

// Resources
import { scrollToTop } from "../../resources/utils";

export default function ContractList() {
  const { t } = useTranslation();
  let actualDate = new Date();
  let currentYear = actualDate.getFullYear();
  let yacht = "";
  let agency = "";
  let days = "all";

  const contractRateFilters = JSON.parse(localStorage.getItem("contractRateFilters"));
  if (contractRateFilters) {
    currentYear = contractRateFilters.year;
    yacht = contractRateFilters.yacht;
    agency = contractRateFilters.agency;
    days = contractRateFilters.days;
  }

  const { register, handleSubmit, errors, getValues } = useForm();
  const contractStateCustom = useSelector((state) => state.customContractRateList);;
  const contractState = useSelector((state) => state.contractRateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContractRateListCustom({year: currentYear, yacht_id: yacht, agency_id: agency, days: days},""));
    scrollToTop();
    return () => {
      dispatch(initialStateContractRateListCustom())
      dispatch(initialStateContractRate());
    };
  }, []);

  if (contractStateCustom.fetching || contractState.fetching) {
    return <Loader active inline />;
  }

  if (contractStateCustom.error) {
    return <div>Error: {contractStateCustom.error}</div>;
  }

  if (contractState.message === "resource deleted successfully") {
    dispatch(
      fetchContractRateListCustom({year: getValues("year"), yacht_id: getValues("yacht_id"), agency_id: getValues("agency_id"), days: getValues("days")}, "")
    );
    dispatch(initialStateContractRate());
  }

  if (contractState.message === "resource updated successfully") {
    dispatch(
      fetchContractRateListCustom({year: getValues("year"), yacht_id: getValues("yacht_id"), agency_id: getValues("agency_id"), days: getValues("days")}, "")
    );
    dispatch(initialStateContractRate());
  }

  const onSubmit = (data) => {
    let contractRateFilters = {
      year: getValues("year"),
      yacht: getValues("yacht_id"),
      agency: getValues("agency_id"),
      days: getValues("days"),
    };
    localStorage.setItem("contractRateFilters", JSON.stringify(contractRateFilters));
    dispatch(fetchContractRateListCustom(data, ""));
  };

  const handledeleteContract = (contract_id) => {
    dispatch(deleteContractRate(contract_id));
  };

  return (
    <>
      <HeaderCatalog title={t("contract_title")} message={contractStateCustom.message} to="/contractRates/contractRates_new" />
      <Divider />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("contract_year")}</label>
            <select name="year" defaultValue={currentYear} aria-expanded="true" ref={register}>
              {contractStateCustom.years.map((year, index) => (
                <option key={index} value={year.year}>{year.year}</option>
              ))}
            </select>  
          </Form.Field>
          <Form.Field required>
            <label>{t("contract_yacht")}</label>
            <select name="yacht_id" defaultValue={yacht} aria-expanded="true" ref={register({ required: true })}>
              <option />
              {contractStateCustom.yachts.map((yacht) => (
                <option key={yacht.id} value={yacht.id}>{t(yacht.name)}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("contract_agency")}</label>
            <select name="agency_id" defaultValue={agency} aria-expanded="true" ref={register({ required: true })}>
              <option />
              {contractStateCustom.agencies.map((agency) => (
                <option key={agency.id} value={agency.id}>{agency.name}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("contract_days")}</label>
            <select name="days" defaultValue={days} aria-expanded="true" ref={register}>
              <option value="all">{t("all")}</option>
              {contractStateCustom.days.map((day, index) => (
                 <option key={index} value={day.number_days}>{t(day.number_days)}</option>
              ))}
            </select>
          </Form.Field>
          <Form.Field>
            <label>&nbsp;</label>
            <Button type="submit" loading={contractStateCustom.fetching ? true : false} color="green"><Icon name='search' />{t("search_button")}</Button>
          </Form.Field>
        </Form.Group>
      </Form>

      {contractStateCustom.contractRates !== undefined ? (
        <Table color="blue" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>{t("contract_year")}</Table.HeaderCell>
              <Table.HeaderCell>{t("contract_yacht")}</Table.HeaderCell>
              <Table.HeaderCell>{t("contract_agency")}</Table.HeaderCell>
              <Table.HeaderCell>{t("contract_days")}</Table.HeaderCell>
              <Table.HeaderCell>{t("contract_charter")}</Table.HeaderCell>
              <Table.HeaderCell>{t("contract_double")}</Table.HeaderCell>
              <Table.HeaderCell>{t("contract_single")}</Table.HeaderCell>
              <Table.HeaderCell>{t("state")}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {contractStateCustom.contractRates.map((contractRate) => (
              <Table.Row key={contractRate.id}>
                <Table.Cell collapsing><ActiveIcon active={contractRate.active} /></Table.Cell>
                <Table.Cell>{contractRate.year}</Table.Cell>
                <Table.Cell>{contractRate.yacht_name} </Table.Cell>
                <Table.Cell>{contractRate.agency_name}
                  <div>
                    <span className="ticket_title">{t(contractRate.contract)}</span>
                  </div>
                </Table.Cell>
                <Table.Cell align="center">{contractRate.days}</Table.Cell>
                <Table.Cell align="center"><GeneralRateCell client={contractRate.charter_client} operator={contractRate.charter_operator} ticket={contractRate.airplane_ticket} /></Table.Cell>
                <Table.Cell align="center"><GeneralRateCell client={contractRate.double_client} operator={contractRate.double_operator} ticket={contractRate.airplane_ticket} /></Table.Cell>
                <Table.Cell align="center"><GeneralRateCell client={contractRate.single_client} operator={contractRate.single_operator} ticket={contractRate.airplane_ticket} /></Table.Cell>
                <Table.Cell width="2"><StateRateCell activation_date={contractRate.activation_date} inactivation_date={contractRate.inactivation_date} reason_deactivation={contractRate.reason_deactivation} /></Table.Cell>
                <Table.Cell collapsing>
                  {contractRate.active != false ? (
                    <ChangeStateRateModal stateRate={contractRate} typeRate="ContractRate" />
                  ):(
                    null
                  )}
                  <Link to={`/contractRates/${contractRate.id}`}><Button circular color='blue' icon='pencil alternate' /></Link> 
                  <Modal
                    trigger={
                      <Button circular color='red' icon='trash alternate' />
                    }
                    content={t("contract_delete")}
                    actions={[
                      t("canceled_button"),
                      {
                        key: "eliminar",
                        content: t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handledeleteContract(contractRate.id),
                      },
                    ]}
                    size="tiny"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        ""
      )}
    </>
  );
}
