import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import LanguageService from "../../../../services/catalogs/LanguageService";

export const REQUEST_CREATE_LANGUAGE = "REQUEST_CREATE_LANGUAGE";
export const CREATE_LANGUAGE_SUCCESS = "CREATE_LANGUAGE_SUCCESS";
export const CREATE_LANGUAGE_ERROR = "CREATE_LANGUAGE_ERROR";

function requestCreateLanguage() {
  return {
    type: REQUEST_CREATE_LANGUAGE,
  };
}

function createLanguageSuccess(json) {
  return {
    type: CREATE_LANGUAGE_SUCCESS,
    message: json.data,
  };
}

function createLanguageError(json) {
  return {
    type: CREATE_LANGUAGE_ERROR,
    error: json.data,
  };
}

export function createLanguage(payload) {
  return (dispatch) => {
    dispatch(requestCreateLanguage());
    return LanguageService.postLanguage(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createLanguageSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createLanguage(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createLanguageError(json.data));
      }
    });
  };
}
