import UserService from "../../../../services/catalogs/UserService";

export const REQUEST_CHANGE_PASSWORD_USER = "REQUEST_CHANGE_PASSWORD_USER";
export const CHANGE_PASSWORD_USER_SUCCESS = "CHANGE_PASSWORD_USER_SUCCESS";
export const CHANGE_PASSWORD_USER_ERROR = "CHANGE_PASSWORD_USER_ERROR";

function requestchangePasswordUser() {
  return {
    type: REQUEST_CHANGE_PASSWORD_USER,
  };
}

function changePasswordUserSuccess(json) {
  return {
    type: CHANGE_PASSWORD_USER_SUCCESS,
    message: json.data,
  };
}

function changePasswordUserError(json) {
  return {
    type: CHANGE_PASSWORD_USER_ERROR,
    error: json.data,
  };
}

export function changePasswordUser(user_id, payload) {
  return (dispatch) => {
    dispatch(requestchangePasswordUser());
    return UserService.changePasswordUser(user_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(changePasswordUserSuccess(json.data));
      } else {
        dispatch(changePasswordUserError(json.data));
      }
    });
  };
}
