import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import UserService from "../../../../services/catalogs/UserService";

export const REQUEST_DELETE_USER = "REQUEST_DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

function requestDeleteUser() {
  return {
    type: REQUEST_DELETE_USER,
  };
}

function deleteUserSuccess(json) {
  return {
    type: DELETE_USER_SUCCESS,
    message: json.data,
  };
}

function deleteUserError(json) {
  return {
    type: DELETE_USER_ERROR,
    error: json.data,
  };
}

export function deleteUser(user_id) {
  return (dispatch) => {
    dispatch(requestDeleteUser());
    return UserService.deleteUser(user_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteUserSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteUser(user_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteUserError(json.data));
      }
    });
  };
}
