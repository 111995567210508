import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Input, Header, Segment, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { fetchNotes } from "../../../redux/actions/catalogs/note/fetchNotes";
import { deleteNote } from "../../../redux/actions/catalogs/note/deleteNote";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function NoteList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const noteState = useSelector((state) => state.noteReducer);
  const { register, getValues } = useForm();

  useEffect(() => {
    dispatch(fetchNotes(""));
    scrollToTop();
  }, []);

  if (noteState.fetching || noteState.processing) {
    return <Loader active inline />;
  }

  if (noteState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (noteState.message === "resource deleted successfully") {
    dispatch(fetchNotes(""));
  }

  const handleDeleteNote = (note_id) => {
    dispatch(deleteNote(note_id));
  };

  const handleSearchNote = () => {
    dispatch(fetchNotes(getValues("searchWord")));
  };

  return (
    <>
      <Segment clearing> 
        <HeaderCatalog title={t("note_title")} message={noteState.message} to="/notes_new" />
        <Header as='h5' floated='right'>
          <Input type="text" placeholder={t("search") + "..."} action>
            <input name="searchWord" maxLength={10} ref={register()} />
            <Button type="submit" onClick={() => handleSearchNote()} icon="search" size="medium" />
          </Input>
        </Header>
      </Segment>
      
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("note_message")}</Table.HeaderCell>
            <Table.HeaderCell>{t("note_type")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {noteState.notes.map((note) => (
          <Table.Row key={note.id}>
            <Table.Cell collapsing><ActiveIcon active={note.active} /></Table.Cell>
            <Table.Cell>{note.message}</Table.Cell>
            <Table.Cell>{t(note.type)}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/notes/${note.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("note_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteNote(note.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
