import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "../../styles/common.css";

//Actions
import { createTicket } from "../../../redux/actions/rates/ticket/createTicket";
import { initialStateTicket } from "../../../redux/actions/rates/ticket/fetchTicket";
import { updateTicket } from "../../../redux/actions/rates/ticket/updateTicket";
import { fetchTicketFormCustom, initialStateTicketFormCustom } from "../../../redux/slices/custom/rates/ticket/customTicketForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";
import { years, ticketRoutes } from "../../resources/optionsList";

export default function TicketForm() {
  const sixDotTwoRegex = new RegExp(/^\d{0,6}(\.\d{0,2})?$/);
  const { t } = useTranslation();
  let history = useHistory();
  let { ticket_id } = useParams();
  const { register, handleSubmit, errors, getValues } = useForm();
  
  const ticketStateCustom = useSelector((state) => state.customTicketForm);
  const ticketState = useSelector((state) => state.ticketReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTicketFormCustom("", ticket_id));
    scrollToTop();
    return () => {
      dispatch(initialStateTicketFormCustom());
      dispatch(initialStateTicket());
    };
  }, []);

  if (ticketStateCustom.fetching || ticketState.fetching ) {
    return <Loader active inline />;
  }

  if (ticketStateCustom.error) {
    return <div>Error: {ticketStateCustom.error}</div>;
  }

  const onSubmit = (data) => {
    if (ticket_id) {
      dispatch(updateTicket(ticket_id, data));
    } else {
      dispatch(createTicket(data));
    }
  };

  if (ticketState.message === "resource created successfully" || ticketState.message === "resource updated successfully") {
    let ticketRateFilters = {
      year: getValues("year"),
      airline: getValues("airline_id"),
    };
    localStorage.setItem("ticketRateFilters", JSON.stringify(ticketRateFilters));

    history.push("/tickets");
  }

  let departureRoutes = ticketStateCustom.routes.filter(
    (e) => e.type === "departure"
  );

  let returnRoutes = ticketStateCustom.routes.filter(
    (e) => e.type === "return"
  );

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/tickets" className="section">{t("ticket_title")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("ticket_path")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <Form onSubmit={handleSubmit(onSubmit)}>
          <Header as='h4' dividing color='grey'>{t("general_information_tag")}</Header>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("ticket_year")}*</label>
              <select name="year" aria-expanded="true" defaultValue={ticketStateCustom.ticket.year} ref={register({ required: true })}>
                <option></option>
                {years.map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </select>
              {errors.year && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field>
              <label>{t("flight_airline")} *</label>
              <select name="airline_id" defaultValue={ticketStateCustom.ticket.airline_id} aria-expanded="true" ref={register({ required: true })}>
                <option></option>
                {ticketStateCustom.airlines.map((airline) => (
                  <option key={airline.id} value={airline.id}>{airline.name}</option>
                ))}
              </select>
              {errors.airline_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field>
              <label>{t("ticket_departureFlight")}*</label>
              <select aria-expanded="true" name="departure_route" defaultValue={ticketStateCustom.ticket.departure_route} ref={register({ required: true })}>
                <option></option>
                {departureRoutes.map((route) => (
                  <option className="selected item" key={route.id} value={route.id}>
                    {route.origin_city}({route.origin_code}) -{" "}
                    {route.destination_city}({route.destination_code})
                  </option>
                ))}
              </select>
              {errors.route_id && (<span className="error_message">{t("required_information")}{" "}</span>)}
            </Form.Field>
            <Form.Field>
              <label>{t("ticket_returnFLight")}*</label>
              <select aria-expanded="true" name="return_route" defaultValue={ticketStateCustom.ticket.return_route} ref={register({ required: true })}>
                <option></option>
                {returnRoutes.map((route) => (
                  <option className="selected item" key={route.id} value={route.id} >
                    {route.origin_city}({route.origin_code}) -{" "}
                    {route.destination_city}({route.destination_code})
                  </option>
                ))}
              </select>
              {errors.route_id && (<span className="error_message">{t("required_information")}{" "}</span>)}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">

          <Form.Field>
              <label>{t("tour_conductor")}</label>
              <input name="tour_conductor" defaultValue={ticketStateCustom.ticket.tour_conductor} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.tour_conductor && errors.tour_conductor.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.tour_conductor && errors.tour_conductor.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label>{t("contract_agency")}</label>
              <select name="agency_id" aria-expanded="true" defaultValue={ticketStateCustom.ticket.agency_id} ref={register()}>
                <option />
                {ticketStateCustom.agencies.map((agency, index) => (
                  <option key={index} value={agency.id}>{agency.name}</option>
                ))}
              </select>
            </Form.Field>
         
          </Form.Group>
          
          <Header as='h4' dividing color='grey'>{t("rate_title")}</Header>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("ticket_foreingAdult")} - {t("ticket_airlinePrice")}</label>
              <input name="airlinePrice_foreingAdult_lowSeason" defaultValue={ticketStateCustom.ticket.airlinePrice_foreingAdult_lowSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_low").toUpperCase()}</span><br></br>
              {errors.airlinePrice_foreingAdult_lowSeason && errors.airlinePrice_foreingAdult_lowSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.airlinePrice_foreingAdult_lowSeason && errors.airlinePrice_foreingAdult_lowSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label> {t("ticket_foreingAdult")} - {t("ticket_passengerPrice")}</label>
              <input name="passengerPrice_foreingAdult_lowSeason" defaultValue={ticketStateCustom.ticket.passengerPrice_foreingAdult_lowSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_low").toUpperCase()}</span><br></br>
              {errors.passengerPrice_foreingAdult_lowSeason && errors.passengerPrice_foreingAdult_lowSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.passengerPrice_foreingAdult_lowSeason && errors.passengerPrice_foreingAdult_lowSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
            <Form.Field>
              <label>{t("ticket_foreingAdult")} - {t("ticket_airlinePrice")}</label>
              <input name="airlinePrice_foreingAdult_highSeason" defaultValue={ticketStateCustom.ticket.airlinePrice_foreingAdult_highSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_high").toUpperCase()}</span><br></br>
              {errors.airlinePrice_foreingAdult_highSeason && errors.airlinePrice_foreingAdult_highSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.airlinePrice_foreingAdult_highSeason && errors.airlinePrice_foreingAdult_highSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label>{t("ticket_foreingAdult")} - {t("ticket_passengerPrice")}</label>
              <input name="passengerPrice_foreingAdult_highSeason" defaultValue={ticketStateCustom.ticket.passengerPrice_foreingAdult_highSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_high").toUpperCase()}</span><br></br>
              {errors.passengerPrice_foreingAdult_highSeason && errors.passengerPrice_foreingAdult_highSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.passengerPrice_foreingAdult_highSeason && errors.passengerPrice_foreingAdult_highSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          {/** FOREING CHILD */}
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("ticket_foreingChild")} - {t("ticket_airlinePrice")}</label>
              <input name="airlinePrice_foreingChild_lowSeason" defaultValue={ticketStateCustom.ticket.airlinePrice_foreingChild_lowSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_low").toUpperCase()}</span><br></br>
              {errors.airlinePrice_foreingChild_lowSeason && errors.airlinePrice_foreingChild_lowSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.airlinePrice_foreingChild_lowSeason && errors.airlinePrice_foreingChild_lowSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label>{t("ticket_foreingChild")} -{t("ticket_passengerPrice")}</label>
              <input name="passengerPrice_foreingChild_lowSeason" defaultValue={ticketStateCustom.ticket.passengerPrice_foreingChild_lowSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_low").toUpperCase()}</span><br></br>
              {errors.passengerPrice_foreingChild_lowSeason && errors.passengerPrice_foreingChild_lowSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.passengerPrice_foreingChild_lowSeason && errors.passengerPrice_foreingChild_lowSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
            <Form.Field>
              <label>{t("ticket_foreingChild")} - {t("ticket_airlinePrice")}</label>
              <input name="airlinePrice_foreingChild_highSeason" defaultValue={ticketStateCustom.ticket.airlinePrice_foreingChild_highSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_high").toUpperCase()}</span><br></br>
              {errors.airlinePrice_foreingChild_highSeason && errors.airlinePrice_foreingChild_highSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.airlinePrice_foreingChild_highSeason && errors.airlinePrice_foreingChild_highSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label>{t("ticket_foreingChild")} - {t("ticket_passengerPrice")}</label>
              <input name="passengerPrice_foreingChild_highSeason" defaultValue={ticketStateCustom.ticket.passengerPrice_foreingChild_highSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_high").toUpperCase()}</span><br></br>
              {errors.passengerPrice_foreingChild_highSeason && errors.passengerPrice_foreingChild_highSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.passengerPrice_foreingChild_highSeason && errors.passengerPrice_foreingChild_highSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          {/** NATIONAL ADULT */}
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("ticket_nationalAdult")} - {t("ticket_airlinePrice")}</label>
              <input name="airlinePrice_nationalAdult_lowSeason" defaultValue={ticketStateCustom.ticket.airlinePrice_nationalAdult_lowSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_low").toUpperCase()}</span><br></br>
              {errors.airlinePrice_nationalAdult_lowSeason && errors.airlinePrice_nationalAdult_lowSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.airlinePrice_nationalAdult_lowSeason && errors.airlinePrice_nationalAdult_lowSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label>{t("ticket_nationalAdult")} - {t("ticket_passengerPrice")}
              </label>
              <input name="passengerPrice_nationalAdult_lowSeason" defaultValue={ticketStateCustom.ticket.passengerPrice_nationalAdult_lowSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_low").toUpperCase()}</span><br></br>
              {errors.passengerPrice_nationalAdult_lowSeason && errors.passengerPrice_nationalAdult_lowSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.passengerPrice_nationalAdult_lowSeason && errors.passengerPrice_nationalAdult_lowSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
            <Form.Field>
              <label>{t("ticket_nationalAdult")} - {t("ticket_airlinePrice")}</label>
              <input name="airlinePrice_nationalAdult_highSeason" defaultValue={ticketStateCustom.ticket.airlinePrice_nationalAdult_highSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_high").toUpperCase()}</span><br></br>
              {errors.airlinePrice_nationalAdult_highSeason && errors.airlinePrice_nationalAdult_highSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.airlinePrice_nationalAdult_highSeason && errors.airlinePrice_nationalAdult_highSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label>{t("ticket_nationalAdult")} - {t("ticket_passengerPrice")}</label>
              <input name="passengerPrice_nationalAdult_highSeason" defaultValue={ticketStateCustom.ticket.passengerPrice_nationalAdult_highSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_high").toUpperCase()}</span><br></br>
              {errors.passengerPrice_nationalAdult_highSeason && errors.passengerPrice_nationalAdult_highSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.passengerPrice_nationalAdult_highSeason && errors.passengerPrice_nationalAdult_highSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          {/** NATIONAL CHILD */}
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("ticket_nationalChild")} - {t("ticket_airlinePrice")}</label>
              <input name="airlinePrice_nationalChild_lowSeason" defaultValue={ticketStateCustom.ticket.airlinePrice_nationalChild_lowSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_low").toUpperCase()}</span><br></br>
              {errors.airlinePrice_nationalChild_lowSeason && errors.airlinePrice_nationalChild_lowSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.airlinePrice_nationalChild_lowSeason && errors.airlinePrice_nationalChild_lowSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label>{t("ticket_nationalChild")} - {t("ticket_passengerPrice")}</label>
              <input name="passengerPrice_nationalChild_lowSeason" defaultValue={ticketStateCustom.ticket.passengerPrice_nationalChild_lowSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_low").toUpperCase()}</span><br></br>
              {errors.passengerPrice_nationalChild_lowSeason && errors.passengerPrice_nationalChild_lowSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.passengerPrice_nationalChild_lowSeason && errors.passengerPrice_nationalChild_lowSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
            <Form.Field>
              <label>{t("ticket_nationalChild")} - {t("ticket_airlinePrice")}</label>
              <input name="airlinePrice_nationalChild_highSeason" defaultValue={ticketStateCustom.ticket.airlinePrice_nationalChild_highSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_high").toUpperCase()}</span><br></br>
              {errors.airlinePrice_nationalChild_highSeason && errors.airlinePrice_nationalChild_highSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.airlinePrice_nationalChild_highSeason && errors.airlinePrice_nationalChild_highSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label>{t("ticket_nationalChild")}- {t("ticket_passengerPrice")}</label>
              <input name="passengerPrice_nationalChild_highSeason" defaultValue={ticketStateCustom.ticket.passengerPrice_nationalChild_highSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_high").toUpperCase()}</span><br></br>
              {errors.passengerPrice_nationalChild_highSeason && errors.passengerPrice_nationalChild_highSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.passengerPrice_nationalChild_highSeason && errors.passengerPrice_nationalChild_highSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          {/** COLONUS ADULT */}
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("ticket_colonistAdult")} - {t("ticket_airlinePrice")}</label>
              <input name="airlinePrice_colonistAdult_lowSeason" defaultValue={ticketStateCustom.ticket.airlinePrice_colonistAdult_lowSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_low").toUpperCase()}</span><br></br>
              {errors.airlinePrice_colonistAdult_lowSeason && errors.airlinePrice_colonistAdult_lowSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.airlinePrice_colonistAdult_lowSeason && errors.airlinePrice_colonistAdult_lowSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label>{t("ticket_colonistAdult")} - {t("ticket_passengerPrice")}</label>
              <input name="passengerPrice_colonistAdult_lowSeason" defaultValue={ticketStateCustom.ticket.passengerPrice_colonistAdult_lowSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_low").toUpperCase()}</span><br></br>
              {errors.passengerPrice_colonistAdult_lowSeason && errors.passengerPrice_colonistAdult_lowSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.passengerPrice_colonistAdult_lowSeason && errors.passengerPrice_colonistAdult_lowSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
            <Form.Field>
              <label>{t("ticket_colonistAdult")} - {t("ticket_airlinePrice")}</label>
              <input name="airlinePrice_colonistAdult_highSeason" defaultValue={ticketStateCustom.ticket.airlinePrice_colonistAdult_highSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_high").toUpperCase()}</span><br></br>
              {errors.airlinePrice_colonistAdult_highSeason && errors.airlinePrice_colonistAdult_highSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.airlinePrice_colonistAdult_highSeason && errors.airlinePrice_colonistAdult_highSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label>{t("ticket_colonistAdult")} - {t("ticket_passengerPrice")}</label>
              <input name="passengerPrice_colonistAdult_highSeason" defaultValue={ticketStateCustom.ticket.passengerPrice_colonistAdult_highSeason} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              <span className="bottom_label">{t("ticket_season").toUpperCase()}{" "}{t("ticket_high").toUpperCase()}</span><br></br>
              {errors.passengerPrice_colonistAdult_highSeason && errors.passengerPrice_colonistAdult_highSeason.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.passengerPrice_colonistAdult_highSeason && errors.passengerPrice_colonistAdult_highSeason.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
          <Form.Field>
              <label>{t("comment")}</label>
              <textarea rows="3" name="comment" defaultValue={ticketStateCustom.ticket.comment} ref={register}/>
            </Form.Field>
          </Form.Group>
          
          <Divider variant="middle" />
          
          <Button primary type="submit" loading={ticketStateCustom.processing ? true : false}>{t("save_button")}</Button>
          <Link to={"/tickets"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
