import AvailabilityService from "../../../../services/b2b/AvailabilityService";

export const REQUEST_CREATE_AVAILABILITYB2B = "REQUEST_CREATE_AVAILABILITYB2B";
export const CREATE_AVAILABILITYB2B_SUCCESS = "CREATE_AVAILABILITYB2B_SUCCESS";
export const CREATE_AVAILABILITYB2B_ERROR = "CREATE_AVAILABILITYB2B_ERROR";

function requestCreateAvailabilityB2b() {
  return {
    type: REQUEST_CREATE_AVAILABILITYB2B,
  };
}

function createAvailabilityB2bSuccess(json) {
  return {
    type: CREATE_AVAILABILITYB2B_SUCCESS,
    message: json.data,
  };
}

function createAvailabilityB2bError(json) {
  return {
    type: CREATE_AVAILABILITYB2B_ERROR,
    error: json.data,
  };
}

export function createAvailabilityB2b(payload) {
  return (dispatch) => {
    dispatch(requestCreateAvailabilityB2b());
    return AvailabilityService.insertAvailabilityCruise(payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(createAvailabilityB2bSuccess(json.data));
        } else {
          dispatch(createAvailabilityB2bError(json.data));
        }
      }
    );
  };
}
