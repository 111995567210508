import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CabinService from "../../../../services/catalogs/CabinService";

export const REQUEST_UPDATE_CABIN = "REQUEST_UPDATE_CABIN";
export const UPDATE_CABIN_SUCCESS = "UPDATE_CABIN_SUCCESS";
export const UPDATE_CABIN_ERROR = "UPDATE_CABIN_ERROR";

function requestUpdateCabin() {
  return {
    type: REQUEST_UPDATE_CABIN,
  };
}

function updateCabinSuccess(json) {
  return {
    type: UPDATE_CABIN_SUCCESS,
    message: json.data,
  };
}

function updateCabinError(json) {
  return {
    type: UPDATE_CABIN_ERROR,
    error: json.data,
  };
}

export function updateCabin(cabin_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateCabin());
    return CabinService.putCabin(cabin_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateCabinSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateCabin(cabin_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateCabinError(json.data));
      }
    });
  };
}
