import TipTopII from "../containers/b2b/yachts/section/TipTopII";
import TipTopIV from "../containers/b2b/yachts/section/TipTopIV";
import TipTopV from "../containers/b2b/yachts/section/TipTopV";

const routes = [
  {
    path: "/b2b/tiptop2",
    component: TipTopII,
  },
  {
    path: "/b2b/tiptop4",
    component: TipTopIV,
  },
  {
    path: "/b2b/tiptop5",
    component: TipTopV,
  },
];

export { routes as yachtb2bRoutes };
