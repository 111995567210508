import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Loader, Button, Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { fetchRates } from "../../../redux/actions/catalogs/rate/fetchRates";
import { fetchYachts } from "../../../redux/actions/catalogs/yacht/fetchYachts";
import { fetchDays } from "../../../redux/actions/catalogs/itinerary/fetchDays";
import { fetchCharterRate, initialStateCharterRate } from "../../../redux/actions/rates/charterRates/fetchCharterRate";
import { createCharterRate } from "../../../redux/actions/rates/charterRates/createCharterRate";
import { updateCharterRate } from "../../../redux/actions/rates/charterRates/updateCharterRate";
import { fetchCharterRateFormCustom, initialStateCharterRateFormCustom } from "../../../redux/slices/custom/rates/charterRate/customCharterRateForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";
import { years } from "../../resources/optionsList";

export default function CharterRateForm() {
  const sixDotTwoRegex = new RegExp(/^\d{0,6}(\.\d{0,2})?$/);
  const { t } = useTranslation();
  let history = useHistory();
  let { charterRate_id } = useParams();
  const { register, handleSubmit, errors, getValues } = useForm();

  const charterRateStateCustom = useSelector((state) => state.customCharterRateForm);
  const charterRateState = useSelector((state) => state.charterRateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCharterRateFormCustom(charterRate_id));
    scrollToTop();
    return () => {
      dispatch(initialStateCharterRateFormCustom());
      dispatch(initialStateCharterRate());
    };
  }, []);

  if (charterRateStateCustom.fetching || charterRateState.fetching) {
    return <Loader active inline />;
  }

  if (charterRateStateCustom.error) {
    return <div>Error: {charterRateStateCustom.error}</div>;
  }

  const onSubmit = (data) => {
    if (charterRate_id) {
      dispatch(updateCharterRate(charterRate_id, data));
    } else {
      dispatch(createCharterRate(data));
    }
  };

  if (charterRateState.message === "resource created successfully" || charterRateState.message === "resource updated successfully") {
    let charterRateFilters = {
      year: getValues("year"),
      rate: getValues("rate_id"),
      yacht: getValues("yacht_id"),
      days: getValues("days"),
    };
    localStorage.setItem("charterRateFilters", JSON.stringify(charterRateFilters));

    history.push("/charterRates");
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section><Link to="/charterRates" className="section">{t("charter_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("charter_path")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t("charter_year")}</label>
              <select name="year" defaultValue={charterRateStateCustom.charterRate.year} aria-expanded="true" ref={register({ required: true })}>
                <option />
                {years.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
              {errors.year && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("charter_rateType")}</label>
              <select name="rate_id" defaultValue={charterRateStateCustom.charterRate.rate_id} aria-expanded="true" ref={register({ required: true })}>
                <option />
                {charterRateStateCustom.rates.map((rateType) => (
                  <option key={rateType.id} value={rateType.id}>{t(rateType.name)}</option>
                ))}
              </select>
              {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("charter_yacht")}</label>
              <select name="yacht_id" defaultValue={charterRateStateCustom.charterRate.yacht_id} aria-expanded="true" ref={register({ required: true })}>
                <option />
                {charterRateStateCustom.yachts.map((yacht) => (
                  <option key={yacht.id} value={yacht.id}>{yacht.name}</option>
                ))}
              </select>
              {errors.yacht_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("charter_days")}</label>
              <select name="days" defaultValue={charterRateStateCustom.charterRate.days} aria-expanded="true" ref={register({ required: true })}>
                <option />
                {charterRateStateCustom.days.map((day, index) => (
                  <option key={index} value={day.number_days}>{t(day.number_days)}</option>
                ))}
              </select>
              {errors.days && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field required width={4}>
              <label>{t("charter_clientRate")}</label>
              <input name="rate_client" defaultValue={charterRateStateCustom.charterRate.rate_client} ref={register({pattern: sixDotTwoRegex, required: true,})} />
              {errors.rate_client && errors.rate_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.rate_client && errors.rate_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field width={2}>
              <label>{t("ticket_airline")} </label>
              <select name="airplane_ticket" defaultValue={charterRateStateCustom.charterRate.airplane_ticket} aria-expanded="true" ref={register({ required: true })}>
                <option value="1">{t("yes")}</option>
                <option value="0">{t("not")}</option>
              </select>
            </Form.Field>

            <Form.Field width={2} />

            <Form.Field required width={4}>
              <label>{t("charter_operatorRate")}</label>
              <input name="rate_operator" defaultValue={charterRateStateCustom.charterRate.rate_operator} ref={register({pattern: sixDotTwoRegex, required: true,})} />
              {errors.rate_operator && errors.rate_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.rate_operator && errors.rate_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            
            <Form.Field>
              <label>{t("comment")}</label>
              <textarea rows="3" name="comment" defaultValue={charterRateStateCustom.charterRate.comment} ref={register}/>
            </Form.Field>
          </Form.Group>

          <Divider variant="middle" />

          <Button primary type="submit" loading={charterRateStateCustom.processing ? true : false}>{t("save_button")}</Button>
          <Link to={"/charterRates"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
