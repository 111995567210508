import FlightList from "../containers/catalogs/flight/FlightList";
import FlightForm from "../containers/catalogs/flight/FlightForm";

const routes = [
  {
    path: "/flights",
    component: FlightList,
  },
  {
    path: "/flights_new",
    component: FlightForm,
  },
  {
    path: "/flights/:flight_id",
    component: FlightForm,
  },
];

export { routes as flightRoutes };
