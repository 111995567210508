import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal, Icon } from "semantic-ui-react";
import { useForm } from "react-hook-form";

//Actions
import { updateDiscountPrice } from "../../redux/actions/reservations/space/updateDiscountPrice";

export default function ChangeStateRateModal(props) {
  const sixDotTwoRegex = new RegExp(/^\d{0,6}(\.\d{0,2})?$/);
  const porcentageRegex = new RegExp(/^\d{0,2}(\.\d{0,2})?$/);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false)
  const { register, handleSubmit, errors, getValues } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(updateDiscountPrice(props.stateSpace.id, data));
  };

  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={ <Button className="add_button" icon circular size="mini">
        <Icon className="icon_color" name="add" />
      </Button> }
      >
        <Modal.Header>
          { t("discount") }
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group widths="equal">
                <Form.Field>
                  <label> { t("total_label") }</label>
                  <label>$ {parseFloat(props.stateSpace.price) + parseFloat(props.stateSpace.flight_price) - parseFloat(props.stateSpace.commission_price) - parseFloat(props.stateSpace.special_discount_price)}  </label>
                </Form.Field>
                <Form.Field>
                  <label>{ t("discount_value") }</label>
                  <input name="special_discount_price" defaultValue={props.stateSpace.special_discount_price} ref={register({pattern: sixDotTwoRegex,required: true})} />
                  {errors.special_discount_price && (<span className="error_message">{t("valid_number")}</span>)}
                </Form.Field>
              </Form.Group>
              <Button primary type="submit" loading={props.stateSpace.processing ? true : false}>
              { t("apply_discount") }
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            {t("canceled_button")}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );

}