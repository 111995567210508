import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import PassengerService from "../../../../services/reservations/PassengerService";

export const REQUEST_FETCH_PASSENGER = "REQUEST_FETCH_PASSENGER";
export const FETCH_PASSENGER_SUCCESS = "FETCH_PASSENGER_SUCCESS";
export const FETCH_PASSENGER_ERROR = "FETCH_PASSENGER_ERROR";
export const INITIAL_STATE_PASSENGER = "INITIAL_STATE_PASSENGER";

function requestFetchPassenger() {
  return {
    type: REQUEST_FETCH_PASSENGER,
  };
}

function fetchPassengerSuccess(json) {
  return {
    type: FETCH_PASSENGER_SUCCESS,
    passenger: json.data,
  };
}

function fetchPassengerError(json) {
  return {
    type: FETCH_PASSENGER_ERROR,
    error: json.data,
  };
}

export function initialStatePassenger() {
  return {
    type: INITIAL_STATE_PASSENGER,
  };
}

export function fetchPassenger(passenger_id) {
  return (dispatch) => {
    dispatch(requestFetchPassenger());
    return PassengerService.getPassenger(passenger_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchPassengerSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchPassenger(passenger_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchPassengerError(json.data));
      }
    });
  };
}
