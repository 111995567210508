import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createDeck } from "../../../redux/actions/catalogs/deck/createDeck";
import { fetchDeck, initialStateDeck } from "../../../redux/actions/catalogs/deck/fetchDeck";
import { updateDeck } from "../../../redux/actions/catalogs/deck/updateDeck";

//Resources
import { scrollToTop } from "../../resources/utils";

export default function DeckForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { deck_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const deckState = useSelector((state) => state.deckReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (deck_id) {
      dispatch(fetchDeck(deck_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateDeck());
    };
  }, []);

  if (deckState.fetching) {
    return <Loader active inline />;
  }

  if (deckState.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (deck_id) {
      dispatch(updateDeck(deck_id, data));
    } else {
      dispatch(createDeck(data));
    }
  };

  if (deckState.message === "resource created successfully" || deckState.message === "resource updated successfully") {
    history.push("/decks");
  }

  return (
    <>
      <Header as='h3' dividing>{t("deck_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("deck_name")}</label>
            <input name="name" defaultValue={deckState.deck.name} placeholder={t("deck_name")} autoFocus maxLength={45} ref={register({ required: true })} />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={deckState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/decks"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
