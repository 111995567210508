import ItineraryList from "../containers/catalogs/itinerary/ItineraryList";
import ItineraryForm from "../containers/catalogs/itinerary/ItineraryForm";
import ItineraryMap from "../containers/catalogs/itinerary/ItineraryMap";

const routes = [
  {
    path: "/itineraries",
    component: ItineraryList,
  },
  {
    path: "/itineraries_new",
    component: ItineraryForm,
  },
  {
    path: "/itineraries/:itinerary_id",
    component: ItineraryForm,
  },
  {
    path: "/itineraries/:itinerary_id/itinerary_map",
    component: ItineraryMap,
  },
];

export { routes as itineraryRoutes };
