import IdentificationTypeList from "../containers/catalogs/identificationType/IdentificationTypeList";
import IdentificationTypeForm from "../containers/catalogs/identificationType/IdentificationTypeForm";

const routes = [
  {
    path: "/identificationTypes",
    component: IdentificationTypeList,
  },
  {
    path: "/identificationTypes_new",
    component: IdentificationTypeForm,
  },
  {
    path: "/identificationTypes/:identification_type_id",
    component: IdentificationTypeForm,
  },
];

export { routes as identificationTypeRoutes };
