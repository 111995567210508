import RouteList from "../containers/catalogs/route/RouteList";
import RouteForm from "../containers/catalogs/route/RouteForm";

const routes = [
  {
    path: "/routes",
    component: RouteList,
  },
  {
    path: "/routes_new",
    component: RouteForm,
  },
  {
    path: "/routes/:route_id",
    component: RouteForm,
  },
];

export { routes as routeRoutes };
