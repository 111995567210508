import React, { useState } from "react";
import clsx from "clsx";
import { Dropdown } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import B2BList from "./B2BList";
import { Route, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom";
import HeadRoftwittmer from "../components/headRofwittmer";
import HeadTipTopCruise from "../components/headTipTopCruise";

//B2B
import { yachtb2bRoutes } from "../routes/YachtB2BRoutes";
import { availabilityb2bRoutes } from "../routes/AvailabilityB2BRoutes";

//TRASLATION
import { useTranslation } from "react-i18next";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "revert",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function DashboardB2B() {
  const classes = useStyles();
  let search = useLocation().search;
  const chooseOrigin = new URLSearchParams(search).get("origin");
  const [language, setLanguage] = useState("ES");
  const [origin, setOrigin] = useState("");
  const { i18n, t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  if (chooseOrigin && origin === "") {
    setOrigin(chooseOrigin);
  }

  return (
    <div>
      {origin !== "" && (
        <div
          style={{
            width: "100%",
            height: "100px",
            position: "absolute",
          }}
        >
          {origin === "rolfwittmer" ? (
            <HeadRoftwittmer />
          ) : origin === "tiptopcruises" ? (
            <HeadTipTopCruise />
          ) : (
            ""
          )}
        </div>
      )}

      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          style={{
            marginTop: origin !== "" ? "112px" : "0",
            position: "absolute",
            zIndex: "1000",
          }}
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {t("b2b_title")}
            </Typography>
            <Dropdown text={language} direction="left">
              <Dropdown.Menu>
                <Dropdown.Item
                  value="es"
                  text="Español - ES"
                  onClick={() => {
                    changeLanguage("es");
                    setLanguage("ES");
                  }}
                />
                <Dropdown.Item
                  value="en"
                  text="English - EN"
                  onClick={() => {
                    changeLanguage("en");
                    setLanguage("EN");
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>

            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          style={{ marginTop: origin !== "" ? "112px" : "0" }}
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <Divider />
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <B2BList />
          <Divider />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Switch>
              {yachtb2bRoutes.map((route, i) => (
                <Route
                  key={i}
                  exact
                  path={route.path}
                  component={route.component}
                />
              ))}
              {availabilityb2bRoutes.map((route, i) => (
                <Route
                  key={i}
                  exact
                  path={route.path}
                  component={route.component}
                />
              ))}
            </Switch>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </div>
  );
}
