import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IkarusRateService from "../../../../services/rates/IkarusRateService";

export const REQUEST_UPDATE_IKARUS_RATE = "REQUEST_UPDATE_IKARUS_RATE";
export const UPDATE_IKARUS_RATE_SUCCESS = "UPDATE_IKARUS_RATE_SUCCESS";
export const UPDATE_IKARUS_RATE_ERROR = "UPDATE_IKARUS_RATE_ERROR";

function requestUpdateIkarusRate() {
  return {
    type: REQUEST_UPDATE_IKARUS_RATE,
  };
}

function updateIkarusRateSuccess(json) {
  return {
    type: UPDATE_IKARUS_RATE_SUCCESS,
    message: json.data,
  };
}

function updateIkarusRateError(json) {
  return {
    type: UPDATE_IKARUS_RATE_ERROR,
    error: json.data,
  };
}

export function updateIkarusRate(ikarusRate_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateIkarusRate());
    return IkarusRateService.putIkarusRate(ikarusRate_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateIkarusRateSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateIkarusRate(ikarusRate_id, payload));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(updateIkarusRateError(json.data));
      }
    });
  };
}