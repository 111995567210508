import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import DayService from "../../../../services/catalogs/DayService";

export const REQUEST_FETCH_DAYS_BY_ITINERARY =
  "REQUEST_FETCH_DAYS_BY_ITINERARY";
export const FETCH_DAYS_BY_ITINERARY_SUCCESS =
  "FETCH_DAYS_BY_ITINERARY_SUCCESS";
export const FETCH_DAYS_BY_ITINERARY_ERROR = "FETCH_DAYS_BY_ITINERARY_ERROR";
export const INITIAL_STATE_DAYS_BY_ITINERARY =
  "INITIAL_STATE_BY_ITINERARY_ERROR";

function requestFetchDaysByItinerary() {
  return {
    type: REQUEST_FETCH_DAYS_BY_ITINERARY,
  };
}

function fetchDaysByItinerarySuccess(json) {
  return {
    type: FETCH_DAYS_BY_ITINERARY_SUCCESS,
    itineraryDays: json.data,
    message: json.message,
  };
}

function fetchDaysByItineraryError(json) {
  return {
    type: FETCH_DAYS_BY_ITINERARY_ERROR,
    error: json.data,
  };
}

export function initialStateDaysByItinerary() {
  return {
    type: INITIAL_STATE_DAYS_BY_ITINERARY,
  };
}

export function fetchDaysByItinerary(yachtName, itinerary_id) {
  return (dispatch) => {
    dispatch(requestFetchDaysByItinerary());
    return DayService.getDaysByItinerary(yachtName, itinerary_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchDaysByItinerarySuccess(json.data, json.message));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchDaysByItinerary(yachtName, itinerary_id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchDaysByItineraryError(json.data));
        }
      }
    );
  };
}
