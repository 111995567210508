import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customAgencyUserForm = createSlice({
  name: 'customAgencyUserForm',
  initialState: {
    agency: {},
    user: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateAgencyUserFormCustom(state, action) {
      return {
        agency: {},
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesAgencyUserFormCustom(state, action) {
      return {
        agency: {},
        user: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchAgencyUserFormCustomSuccess(state, action) {
      return {
        agency: action.payload.agency,
        user: action.payload.user,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchAgencyUserFormCustomError(state, action) {
      return {
        agency: {},
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchAgencyUserFormCustom = (agency_id, user_id) => dispatch => {
  dispatch(requesAgencyUserFormCustom());
  axios.get(`/users/agencies/${agency_id}/form/agencyUsersForm?user_id=${user_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchAgencyUserFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchAgencyUserFormCustom(agency_id, user_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchAgencyUserFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateAgencyUserFormCustom,
  requesAgencyUserFormCustom,
  fetchAgencyUserFormCustomSuccess,
  fetchAgencyUserFormCustomError,
} = customAgencyUserForm.actions;

export default customAgencyUserForm.reducer;