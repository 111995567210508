import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import TicketService from "../../../../services/rates/TicketService";

export const REQUEST_DELETE_TICKET = "REQUEST_DELETE_TICKET";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_ERROR = "DELETE_TICKET_ERROR";

function requestDeleteTicket() {
  return {
    type: REQUEST_DELETE_TICKET,
  };
}

function deleteTicketSuccess(json) {
  return {
    type: DELETE_TICKET_SUCCESS,
    message: json.data,
  };
}

function deleteTicketError(json) {
  return {
    type: DELETE_TICKET_ERROR,
    error: json.data,
  };
}

export function deleteTicket(ticket_id) {
  return (dispatch) => {
    dispatch(requestDeleteTicket());
    return TicketService.deleteTicket(ticket_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteTicketSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteTicket(ticket_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteTicketError(json.data));
      }
    });
  };
}
