import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GeneralRateCabinService from "../../../../services/rates/GeneralRateCabinService";

export const REQUEST_FETCH_GENERAL_RATE_CABINS = "REQUEST_FETCH_GENERAL_RATE_CABINS";
export const FETCH_GENERAL_RATE_CABINS_SUCCESS = "FETCH_GENERAL_RATE_CABINS_SUCCESS";
export const FETCH_GENERAL_RATE_CABINS_ERROR = "FETCH_GENERAL_RATE_CABINS_ERROR";

function requestFetchGeneralRateCabins() {
  return {
    type: REQUEST_FETCH_GENERAL_RATE_CABINS,
  };
}

function fetchGeneralRateCabinsSuccess(json) {
  return {
    type: FETCH_GENERAL_RATE_CABINS_SUCCESS,
    generalRateCabins: json.data,
  };
}

function fetchGeneralRateCabinsError(json) {
  return {
    type: FETCH_GENERAL_RATE_CABINS_ERROR,
    error: json.data,
  };
}

export function fetchGeneralRatesCabins(generalRate_id) {
  return (dispatch) => {
    dispatch(requestFetchGeneralRateCabins());
    return GeneralRateCabinService.getGeneralRateCabins(generalRate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchGeneralRateCabinsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGeneralRatesCabins(generalRate_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGeneralRateCabinsError(json.data));
      }
    });
  };
}
