import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class SeasonService {
  static getSeasons() {
    return axios.get("seasons");
  }
  static getSeason(season_id) {
    return axios.get("seasons/" + season_id);
  }

  static postSeason(payload) {
    return axios.post("seasons", payload);
  }

  static putSeason(season_id, payload) {
    return axios.put("seasons/" + season_id, payload);
  }

  static deleteSeason(season_id) {
    return axios.delete("seasons/" + season_id);
  }
}
