import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class CaptainService {
  static getCaptains(searchWord) {
    var url = "captains";
    url = url + `?searchWord=${searchWord}`;
    return axios.get(url);
  }
  static getCaptain(captain_id) {
    return axios.get("captains/" + captain_id);
  }

  static postCaptain(payload) {
    return axios.post("captains", payload);
  }

  static putCaptain(captain_id, payload) {
    return axios.put("captains/" + captain_id, payload);
  }

  static deleteCaptain(captain_id) {
    return axios.delete("captains/" + captain_id);
  }
}
