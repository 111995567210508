import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import KindService from "../../../../services/catalogs/KindService";

export const REQUEST_DELETE_KIND = "REQUEST_DELETE_KIND";
export const DELETE_KIND_SUCCESS = "DELETE_KIND_SUCCESS";
export const DELETE_KIND_ERROR = "DELETE_KIND_ERROR";

function requestDeleteKind() {
  return {
    type: REQUEST_DELETE_KIND,
  };
}

function deleteKindSuccess(json) {
  return {
    type: DELETE_KIND_SUCCESS,
    message: json.data,
  };
}

function deleteKindError(json) {
  return {
    type: DELETE_KIND_ERROR,
    error: json.data,
  };
}

export function deleteKind(kind_id) {
  return (dispatch) => {
    dispatch(requestDeleteKind());
    return KindService.deleteKind(kind_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteKindSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteKind(kind_id));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(deleteKindError(json.data));
      }
    });
  };
}
