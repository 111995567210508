import React from "react";
import { useTranslation } from "react-i18next";

export default function StateCell(props) {
    const { t } = useTranslation();
    return (
        <>
            {t(props.space.state)}
            <br></br>
            {props.space.state == "reserved" ? (
                <span className="text_date_format">
                    Time limit: {props.space.time_limit}
                </span>   
            ) : (
                ""
            )}
        </>
    )
}