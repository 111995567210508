import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import YachtItineraryService from "../../../../services/catalogs/YachtItineraryService";

export const REQUEST_DELETE_YACHT_ITINERARY = "REQUEST_DELETE_YACHT_ITINERARY";
export const DELETE_YACHT_ITINERARY_SUCCESS = "DELETE_YACHT_ITINERARY_SUCCESS";
export const DELETE_YACHT_ITINERARY_ERROR = "DELETE_YACHT_ITINERARY_ERROR";

function requestDeleteYachtItinerary() {
  return {
    type: REQUEST_DELETE_YACHT_ITINERARY,
  };
}

function deleteYachtItinerarySuccess(json) {
  return {
    type: DELETE_YACHT_ITINERARY_SUCCESS,
    message: json.data,
  };
}

function deleteYachtItineraryError(json) {
  return {
    type: DELETE_YACHT_ITINERARY_ERROR,
    error: json.data,
  };
}

export function deleteYachtItinerary(itinerary_id, id) {
  return (dispatch) => {
    dispatch(requestDeleteYachtItinerary());
    return YachtItineraryService.deleteYachtItinerary(itinerary_id, id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(deleteYachtItinerarySuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(deleteYachtItinerary(itinerary_id, id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(deleteYachtItineraryError(json.data));
        }
      }
    );
  };
}
