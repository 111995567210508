import {
  REQUEST_FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
} from "../../../actions/catalogs/user/fetchUsers";

import {
  INITIAL_STATE_USER,
  REQUEST_FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
} from "../../../actions/catalogs/user/fetchUser";

import {
  REQUEST_CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
} from "../../../actions/catalogs/user/createUser";

import {
  REQUEST_UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
} from "../../../actions/catalogs/user/updateUser";

import {
  REQUEST_DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from "../../../actions/catalogs/user/deleteUser";

import {
  REQUEST_SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_ERROR,
} from "../../../actions/catalogs/user/signinUser";

import {
  REQUEST_CHANGE_PASSWORD_USER,
  CHANGE_PASSWORD_USER_SUCCESS,
  CHANGE_PASSWORD_USER_ERROR,
} from "../../../actions/catalogs/user/changePasswordUser";

import {
  REQUEST_FORGOT_PASSWORD_USER,
  FORGOT_PASSWORD_USER_SUCCESS,
  FORGOT_PASSWORD_USER_ERROR,
} from "../../../actions/catalogs/user/forgotPasswordUser";

const initialState = {
  users: [],
  user: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_USER: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
        emailDisabledField: false
      };
    }
    case REQUEST_FETCH_USERS: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_USERS_SUCCESS: {
      return {
        ...state,
        users: action.users,
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_USERS_ERROR: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_USER: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        users: [],
        user: action.user,
        processing: false,
        fetching: false,
        message: "",
        error: "",
        emailDisabledField: true
      };
    }
    case FETCH_USER_ERROR: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_USER: {
      return {
        ...state,
        users: [],
        user: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_USER_ERROR: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_USER: {
      return {
        ...state,
        users: [],
        user: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_USER_ERROR: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_USER: {
      return {
        ...state,
        users: [],
        user: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_USER_ERROR: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    case REQUEST_SIGNIN_USER: {
      return {
        ...state,
        users: [],
        user: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        users: [],
        user: action.user,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case SIGNIN_USER_ERROR: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CHANGE_PASSWORD_USER: {
      return {
        ...state,
        users: [],
        user: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CHANGE_PASSWORD_USER_SUCCESS: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CHANGE_PASSWORD_USER_ERROR: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FORGOT_PASSWORD_USER: {
      return {
        ...state,
        users: [],
        user: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FORGOT_PASSWORD_USER_SUCCESS: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case FORGOT_PASSWORD_USER_ERROR: {
      return {
        ...state,
        users: [],
        user: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
