import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import RateService from "../../../../services/catalogs/RateService";

export const REQUEST_DELETE_RATE = "REQUEST_DELETE_RATE";
export const DELETE_RATE_SUCCESS = "DELETE_RATE_SUCCESS";
export const DELETE_RATE_ERROR = "DELETE_RATE_ERROR";

function requestDeleteRate() {
  return {
    type: REQUEST_DELETE_RATE,
  };
}

function deleteRateSuccess(json) {
  return {
    type: DELETE_RATE_SUCCESS,
    message: json.data,
  };
}

function deleteRateError(json) {
  return {
    type: DELETE_RATE_ERROR,
    error: json.data,
  };
}

export function deleteRate(rate_id) {
  return (dispatch) => {
    dispatch(requestDeleteRate());
    return RateService.deleteRate(rate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteRate(rate_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteRateError(json.data));
      }
    });
  };
}
