import {
  INITIAL_STATE_VOUCHER_PASSENGER,
  REQUEST_FETCH_VOUCHER_PASSENGERS,
  FETCH_VOUCHER_PASSENGERS_SUCCESS,
  FETCH_VOUCHER_PASSENGERS_ERROR,
} from "../../../actions/reservations/voucherPassenger/fetchVoucherPassengers";

const initialState = {
  voucherPassengers: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const voucherPassengerReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_VOUCHER_PASSENGER: {
      return {
        ...state,
        voucherPassengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_VOUCHER_PASSENGERS: {
      return {
        ...state,
        voucherPassengers: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_VOUCHER_PASSENGERS_SUCCESS: {
      return {
        ...state,
        voucherPassengers: action.voucherPassengers,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_VOUCHER_PASSENGERS_ERROR: {
      return {
        ...state,
        voucherPassengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default voucherPassengerReducer;
