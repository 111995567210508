import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CruiseLanguageService from "../../../../services/reservations/CruiseLanguageService";

export const REQUEST_DELETE_CRUISE_LANGUAGE = "REQUEST_DELETE_CRUISE_LANGUAGE";
export const DELETE_CRUISE_LANGUAGE_SUCCESS = "DELETE_CRUISE_LANGUAGE_SUCCESS";
export const DELETE_CRUISE_LANGUAGE_ERROR = "DELETE_CRUISE_LANGUAGE_ERROR";

function requestDeleteCruiseLanguage() {
  return {
    type: REQUEST_DELETE_CRUISE_LANGUAGE,
  };
}

function deleteCruiseLanguageSuccess(json) {
  return {
    type: DELETE_CRUISE_LANGUAGE_SUCCESS,
    message: json.data,
  };
}

function deleteCruiseLanguageError(json) {
  return {
    type: DELETE_CRUISE_LANGUAGE_ERROR,
    error: json.data,
  };
}

export function deleteCruiseLanguage(cruise_id, id) {
  return (dispatch) => {
    dispatch(requestDeleteCruiseLanguage());
    return CruiseLanguageService.deleteCruiseLanguage(cruise_id, id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(deleteCruiseLanguageSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(deleteCruiseLanguage(cruise_id, id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(deleteCruiseLanguageError(json.data));
        }
      }
    );
  };
}
