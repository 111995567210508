import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import BookingService from "../../../../services/reservations/BookingService";

export const REQUEST_FETCH_BOOKINGS = "REQUEST_FETCH_BOOKINGS";
export const FETCH_BOOKINGS_SUCCESS = "FETCH_BOOKINGS_SUCCESS";
export const FETCH_BOOKINGS_ERROR = "FETCH_BOOKINGS_ERROR";

function requestFetchBookings() {
  return {
    type: REQUEST_FETCH_BOOKINGS,
  };
}

function fetchBookingsSuccess(json) {
  return {
    type: FETCH_BOOKINGS_SUCCESS,
    bookings: json.data,
  };
}

function fetchBookingsError(json) {
  return {
    type: FETCH_BOOKINGS_ERROR,
    error: json.data,
  };
}

export function fetchBookings(reservation_id) {
  return (dispatch) => {
    dispatch(requestFetchBookings());
    return BookingService.getBookings(reservation_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchBookingsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchBookings(reservation_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchBookingsError(json.data));
      }
    });
  };
}
