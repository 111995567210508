import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SeasonService from "../../../../services/rates/SeasonService";

export const REQUEST_CREATE_SEASON = "REQUEST_CREATE_SEASON";
export const CREATE_SEASON_SUCCESS = "CREATE_SEASON_SUCCESS";
export const CREATE_SEASON_ERROR = "CREATE_SEASON_ERROR";

function requestCreateSeason() {
  return {
    type: REQUEST_CREATE_SEASON,
  };
}

function createSeasonSuccess(json) {
  return {
    type: CREATE_SEASON_SUCCESS,
    message: json.data,
  };
}

function createSeasonError(json) {
  return {
    type: CREATE_SEASON_ERROR,
    error: json.data,
  };
}

export function createSeason(payload) {
  return (dispatch) => {
    dispatch(requestCreateSeason());
    return SeasonService.postSeason(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createSeasonSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createSeason(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createSeasonError(json.data));
      }
    });
  };
}
