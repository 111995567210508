import ReservationForm from "../containers/reservations/cruise/reservation/ReservationForm";

const routes = [
  {
    path: "/cruises/:cruise_id/reservations_new",
    component: ReservationForm,
  },
  {
    path: "/cruises/:cruise_id/reservations/:reservation_id",
    component: ReservationForm,
  },
];

export { routes as reservationRoutes };
