import AvailabilityListWholesaler from "../containers/b2b/availability/AvailabilityListWholesaler";
import AvailabilityListRetailer from "../containers/b2b/availability/AvailabilityListRetailer";
import AvailabilityListClient from "../containers/b2b/availability/AvailabilityListClient";
import AvailabilityListHold from "../containers/b2b/availability/AvailabilityListHold";
import AvailabilityListCharter from "../containers/b2b/availability/AvailabilityListCharter";
import AvailabilityListReservation from "../containers/b2b/availability/AvailabilityListReservation";
import AvailabilityListReservationDetail from "../containers/b2b/availability/AvailabilityListReservationDetail";
import AvailabilityClientHold from "../containers/b2b/availability/AvailabilityClientHold";
import AvailabilityConfirmClientSpaces from "../containers/b2b/availability/AvailabilityConfirmClientSpaces";
import AvailabilityPurchase from "../containers/b2b/availability/AvailabilityPurchase";

const routes = [
  {
    path: "/b2b/availability/wholesaler",
    component: AvailabilityListWholesaler,
  },
  {
    path: "/b2b/availability/reservation",
    component: AvailabilityListReservation,
  },
  {
    path: "/b2b/availability/reservationDetail/:reservation_id",
    component: AvailabilityListReservationDetail,
  },
  {
    path: "/b2b/availability/wholesaler/hold",
    component: AvailabilityListHold,
  },
  {
    path: "/b2b/availability/wholesaler/charter",
    component: AvailabilityListCharter,
  },
  {
    path: "/b2b/availability/retailer",
    component: AvailabilityListRetailer,
  },
  {
    path: "/b2b/availability/client",
    component: AvailabilityListClient,
  },
  {
    path: "/b2b/availability/client/hold",
    component: AvailabilityClientHold,
  },
  {
    path: "/b2b/availability/client/hold/confirmation",
    component: AvailabilityConfirmClientSpaces,
  },
  {
    path: "/b2b/availability/confirmation/purchase",
    component: AvailabilityPurchase,
  },
];

export { routes as availabilityb2bRoutes };
