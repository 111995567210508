import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import TicketService from "../../../../services/rates/TicketService";

export const REQUEST_INACTIVE_TICKET = "REQUEST_INACTIVE_TICKET";
export const INACTIVE_TICKET_SUCCESS = "INACTIVE_TICKET_SUCCESS";
export const INACTIVE_TICKET_ERROR = "INACTIVE_TICKET_ERROR";

function requestInactiveTicket() {
  return {
    type: REQUEST_INACTIVE_TICKET,
  };
}

function inactiveTicketSuccess(json) {
  return {
    type: INACTIVE_TICKET_SUCCESS,
    message: json.data,
  };
}

function inactiveTicketError(json) {
  return {
    type: INACTIVE_TICKET_ERROR,
    error: json.data,
  };
}

export function inactiveTicket(ticket_id, payload) {
  return (dispatch) => {
    dispatch(requestInactiveTicket());
    return TicketService.inactiveTicket(ticket_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(inactiveTicketSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(inactiveTicket(ticket_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(inactiveTicketError(json.data));
      }
    });
  };
}
