import {
  REQUEST_UPDATE_TWO_PASSENGERS,
  UPDATE_TWO_PASSENGERS_SUCCESS,
  UPDATE_TWO_PASSENGERS_ERROR,
  INITIAL_STATE_UPDATE_PASSENGER,
} from "../../../actions/reservations/changeCabin/updateTwoPassengers";

const initialState = {
  fetching: false,
  message: "",
  error: "",
};

const updatePassengerReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_UPDATE_PASSENGER: {
      return {
        ...state,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_UPDATE_TWO_PASSENGERS: {
      return {
        ...state,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case UPDATE_TWO_PASSENGERS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_TWO_PASSENGERS_ERROR: {
      return {
        ...state,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};
export default updatePassengerReducer;
