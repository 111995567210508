import YachtItineraryService from "../../../../services/catalogs/YachtService";

export const REQUEST_FETCH_ITINERARIES_BY_YACHT = "REQUEST_FETCH_ITINERARIES_BY_YACHT";
export const FETCH_ITINERARIES_BY_YACHT_SUCCESS = "FETCH_ITINERARIES_BY_YACHT_SUCCESS";
export const FETCH_ITINERARIES_BY_YACHT_ERROR = "FETCH_ITINERARIES_BY_YACHT_ERROR";
export const INITIAL_STATE_ITINERARIES_BY_YACHT = "INITIAL_STATE_ITINERARIES_BY_YACHT";

function requestFetchItinerariesByYacht() {
  return {
    type: REQUEST_FETCH_ITINERARIES_BY_YACHT,
  };
}

function fetchItinerariesByYachtSuccess(json) {
  return {
    type: FETCH_ITINERARIES_BY_YACHT_SUCCESS,
    itineraries: json.data,
  };
}

function fetchItinerariesByYachtError(json) {
  return {
    type: FETCH_ITINERARIES_BY_YACHT_ERROR,
    error: json.data,
  };
}

export function initialStateItinerariesByYacht() {
  return {
    type: INITIAL_STATE_ITINERARIES_BY_YACHT,
  };
}

export function fetchItinerariesByYacht(yacht_id) {
  return (dispatch) => {
    dispatch(requestFetchItinerariesByYacht());
    return YachtItineraryService.getItinerariesByYacht(yacht_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchItinerariesByYachtSuccess(json.data));
        } else {
          dispatch(fetchItinerariesByYachtError(json.data));
        }
      }
    );
  };
}
