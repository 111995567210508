import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Image, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { fetchGenders } from "../../../redux/actions/catalogs/gender/fetchGenders";
import { deleteGender } from "../../../redux/actions/catalogs/gender/deleteGender";

// Resources
import { scrollToTop } from "../../resources/utils";
import childIcon from "../../icons/childIcon.png";
import manIcon from "../../icons/manIcon.png";
import womanIcon from "../../icons/womanIcon.png";

export default function GenderList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const genderState = useSelector((state) => state.genderReducer);

  useEffect(() => {
    dispatch(fetchGenders());
    scrollToTop();
  }, []);

  if (genderState.fetching || genderState.processing) {
    return <Loader active inline />;
  }

  if (genderState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (genderState.message === "resource deleted successfully") {
    dispatch(fetchGenders());
  }

  const handleDeleteGender = (gender_id) => {
    dispatch(deleteGender(gender_id));
  };

  return (
    <>
      <HeaderCatalog title={t("gender_title")} message={genderState.message} to="/genders_new" />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("gender_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("gender_initials")}</Table.HeaderCell>
            <Table.HeaderCell>{t("gender_icon")}</Table.HeaderCell>
            <Table.HeaderCell>{t("gender_national")}</Table.HeaderCell>
            <Table.HeaderCell>{t("gender_foreign")}</Table.HeaderCell>
            <Table.HeaderCell>{t("gender_andean_pact")}</Table.HeaderCell>
            <Table.HeaderCell>{t("passenger_tct")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {genderState.genders.map((gender) => (
          <Table.Row key={gender.id}>
            <Table.Cell>{t(gender.name)}</Table.Cell>
            <Table.Cell>{gender.short}</Table.Cell>
            <Table.Cell>
              {gender.icon === "childIcon.png" ? (
                <Image src={childIcon} size="mini" />
              ) : gender.icon === "manIcon.png" ? (
                <Image src={manIcon} size="mini" />
              ) : gender.icon === "womanIcon.png" ? (
                <Image src={womanIcon} size="mini" />
              ) : (
                ""
              )}
            </Table.Cell>
            <Table.Cell>{gender.gps_tax_local}</Table.Cell>
            <Table.Cell>{gender.gps_tax_foreign}</Table.Cell>
            <Table.Cell align="center">{gender.gps_tax_foreign_andean_pact}</Table.Cell>
            <Table.Cell align="center">{gender.tct}</Table.Cell>

            <Table.Cell collapsing>
              <Link to={`/genders/${gender.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("gender_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteGender(gender.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
