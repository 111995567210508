import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createGuide } from "../../../redux/actions/catalogs/guide/createGuide";
import { fetchGuide, initialStateGuide } from "../../../redux/actions/catalogs/guide/fetchGuide";
import { updateGuide } from "../../../redux/actions/catalogs/guide/updateGuide";

// Resources
import { scrollToTop } from "../../resources/utils";
import { levels } from "../../resources/optionsList";

export default function GuideForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { guide_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const guideState = useSelector((state) => state.guideReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (guide_id) {
      dispatch(fetchGuide(guide_id));
    }
    scrollToTop();

    return () => {
      dispatch(initialStateGuide());
    };
  }, []);

  if (guideState.fetching) {
    return <Loader active inline />;
  }

  if (guideState.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (guide_id) {
      dispatch(updateGuide(guide_id, data));
    } else {
      dispatch(createGuide(data));
    }
  };

  if (guideState.message === "resource created successfully" || guideState.message === "resource updated successfully") {
    history.push("/guides");
  }

  const emailValid = new RegExp(/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,)

  return (
    <>
      <Header as='h3' dividing>{t("guide_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header as='h4' dividing color='grey'>{t("general_information_tag")}</Header>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("guide_name")}</label>
            <input name="first_name" defaultValue={guideState.guide.first_name} placeholder={t("guide_name")} maxLength={100} ref={register({ required: true })} autoFocus />
            {errors.first_name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("guide_lastName")}</label>
            <input name="last_name" defaultValue={guideState.guide.last_name} placeholder={t("guide_lastName")} maxLength={100} ref={register({ required: true })} />
            {errors.last_name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("guide_identification_card")}</label>
            <input name="document_number" defaultValue={guideState.guide.document_number} placeholder={t("guide_identification_card")} maxLength={50} ref={register({ required: true })} />
            {errors.document_number && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("guide_level")}</label>
            <select name="level" defaultValue={guideState.guide.level} aria-expanded="false" ref={register}>
              <option></option>
              {levels.map((level, index) => (
                <option key={index} value={level}>
                  {level}
                </option>
              ))}
            </select>
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("active_tag")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" defaultChecked={guideState.guide.active === 1} ref={register}/>
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Header as='h4' dividing color='grey'>{t("contact_information_tag")}</Header>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("guide_phone")}</label>
            <input name="cellular_phone" defaultValue={guideState.guide.cellular_phone} placeholder={t("guide_phone")} maxLength={50} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("guide_email")}</label>
            <input name="email" defaultValue={guideState.guide.email} placeholder={t("guide_email")} maxLength={200} ref={register({ pattern: emailValid })} />
            {errors.email && (<span className="error_message">{t("guide_error")}</span>)}
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={guideState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/guides"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
