import {
  REQUEST_FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_ERROR,
} from "../../../actions/catalogs/country/fetchCountries";

import {
  INITIAL_STATE_COUNTRY,
  REQUEST_FETCH_COUNTRY,
  FETCH_COUNTRY_SUCCESS,
  FETCH_COUNTRY_ERROR,
} from "../../../actions/catalogs/country/fetchCountry";

import {
  REQUEST_CREATE_COUNTRY,
  CREATE_COUNTRY_SUCCESS,
  CREATE_COUNTRY_ERROR,
} from "../../../actions/catalogs/country/createCountry";

import {
  REQUEST_UPDATE_COUNTRY,
  UPDATE_COUNTRY_SUCCESS,
  UPDATE_COUNTRY_ERROR,
} from "../../../actions/catalogs/country/updateCountry";

import {
  REQUEST_DELETE_COUNTRY,
  DELETE_COUNTRY_SUCCESS,
  DELETE_COUNTRY_ERROR,
} from "../../../actions/catalogs/country/deleteCountry";

const initialState = {
  countries: [],
  country: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_COUNTRY: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_COUNTRIES: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_COUNTRIES_SUCCESS: {
      return {
        ...state,
        country: {},
        countries: action.countries,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_COUNTRIES_ERROR: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_COUNTRY: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_COUNTRY_SUCCESS: {
      return {
        ...state,
        country: action.country,
        countries: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_COUNTRY_ERROR: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_COUNTRY: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_COUNTRY_SUCCESS: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_COUNTRY_ERROR: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_COUNTRY: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_COUNTRY_SUCCESS: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_COUNTRY_ERROR: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_COUNTRY: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_COUNTRY_SUCCESS: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_COUNTRY_ERROR: {
      return {
        ...state,
        country: {},
        countries: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default countryReducer;
