import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FamilyRoomService from "../../../../services/rates/FamilyRoomService";

export const REQUEST_ACTIVE_FAMILY_ROOM_RATE = "REQUEST_ACTIVE_FAMILY_ROOM_RATE";
export const ACTIVE_FAMILY_ROOM_RATE_SUCCESS = "ACTIVE_FAMILY_ROOM_RATE_SUCCESS";
export const ACTIVE_FAMILY_ROOM_RATE_ERROR = "ACTIVE_FAMILY_ROOM_RATE_ERROR";

function requestActiveFamilyRoomRate() {
  return {
    type: REQUEST_ACTIVE_FAMILY_ROOM_RATE,
  };
}

function activeFamilyRoomRateSuccess(json) {
  return {
    type: ACTIVE_FAMILY_ROOM_RATE_SUCCESS,
    message: json.data,
  };
}

function activeFamilyRoomRateError(json) {
  return {
    type: ACTIVE_FAMILY_ROOM_RATE_ERROR,
    error: json.data,
  };
}

export function activeFamilyRoomRate(familyRoomRate_id) {
  return (dispatch) => {
    dispatch(requestActiveFamilyRoomRate());
    return FamilyRoomService.activeFamilyRoom(familyRoomRate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(activeFamilyRoomRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(activeFamilyRoomRate(familyRoomRate_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(activeFamilyRoomRateError(json.data));
      }
    });
  };
}
