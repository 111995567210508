import KindList from "../containers/catalogs/kind/KindList";
import KindForm from "../containers/catalogs/kind/KindForm";

const routes = [
  {
    path: "/kinds",
    component: KindList,
  },
  {
    path: "/kinds_new",
    component: KindForm,
  },
  {
    path: "/kinds/:kind_id",
    component: KindForm,
  },
];

export { routes as kindRoutes };
