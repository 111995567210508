import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//UI
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Loader, Button, Modal, Icon, Accordion } from "semantic-ui-react";
import { Collapse } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
//ACTIONS
import { initialStateSpaceFlight } from "../../../../redux/actions/reservations/spaceFlight/fetchSpaceFlight";
import { deleteSpaceFlight } from "../../../../redux/actions/reservations/spaceFlight/deleteSpaceFlight";
import { fetchSpaceFlightsLogs } from "../../../../redux/actions/reservations/spaceFlight/fetchSpacesFlightsLogs";
import { fetchSpaceFlightListCustom, initialStateSpaceFlightListCustom} from "../../../../redux/slices/custom/reservations/cruise/spaceFlight/customSpaceFlightList.slice"
//RESOURCE
import HeadDetail from "../../../../components/headDetail";
import HeadDetailReservation from "../../../../components/headDetailReservation";
import LogsHistory from "../../../../components/reservation/logsHistory";
import { scrollToTop } from "../../../resources/utils";

export default function SpaceList() {
  let { cruise_id } = useParams();
  let { reservation_id } = useParams();
  let { booking_id } = useParams();
  let { space_id } = useParams();
  const [openHistory, setOpenHistory] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const spaceFlightStateCustom = useSelector((state) => state.customSpaceFlightList);
  const spaceFlightState = useSelector((state) => state.spaceFlightReducer);
  const spaceFlightLogState = useSelector((state)=> state.spaceFlightsLogReducer)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSpaceFlightListCustom(space_id, cruise_id, reservation_id, booking_id))
    scrollToTop();
    return () => {
      dispatch(initialStateSpaceFlightListCustom());
    };
  }, [dispatch, cruise_id, space_id, reservation_id, booking_id]);

  if (spaceFlightStateCustom.fetching ) {
    return <CircularProgress />;
  }

  if (spaceFlightStateCustom.processing) {
    return <CircularProgress />;
  }

  if (spaceFlightStateCustom.error) {
    return <div>Error: {spaceFlightStateCustom.error}</div>;
  }

  if (spaceFlightState.message === "resource deleted successfully") {
    dispatch(fetchSpaceFlightListCustom(space_id, cruise_id, reservation_id, booking_id))
    dispatch(fetchSpaceFlightsLogs(space_id));
    dispatch(initialStateSpaceFlight());
  }

  const handleDeleteSpaceFlight = (space_flight_id) => {
    dispatch(deleteSpaceFlight(space_flight_id));
  };

  const handleHistoyClick = () => {
    setOpenHistory(!openHistory);
    if (!openHistory ) {
      dispatch(fetchSpaceFlightsLogs(space_id));
    } 
  };

  return (
    <>
      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{spaceFlightStateCustom.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">
          <Link to={`/cruises/${cruise_id}/reservations`}>
            {t("reservation_title")}
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">
          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings`}
          >
            {t("cabin_title")}
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">
          {t("cabin_cabin")} {spaceFlightStateCustom.booking.cabin_name}
        </div>
        <div className="divider">/</div>
        <div className="active section">
          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces`}
          >
            {t("space_title")}
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">{t("space_flight_title")}</div>
      </div>
      <br />
      <br />
      <HeadDetail
        yacht_name={spaceFlightStateCustom.cruise.yacht_name}
        itinerary_name={spaceFlightStateCustom.cruise.itinerary_name}
        start_date={spaceFlightStateCustom.cruise.start_date_format}
        end_date={spaceFlightStateCustom.cruise.end_date_format}
      />
      <HeadDetailReservation
        reservation_date={spaceFlightStateCustom.reservation.creation_date}
        reservation_agency={spaceFlightStateCustom.reservation.agency_name}
        reservation_executive={spaceFlightStateCustom.reservation.user}
        reservation_type={spaceFlightStateCustom.reservation.cruise_type}
      />
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("space_flight_title")} {spaceFlightStateCustom.message}
            <Link
              to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces/${space_id}/spaceFlights_new`}
            >
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className={classes.margin}
              >
                <AddIcon />
              </Fab>
            </Link>
          </Typography>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "50px" }}>
                {t("space_flight_code")}
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("space_flight_date")}
                <br></br>
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </TableCell>
              <TableCell align="center">
                {t("space_flight_path")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("space_flight_ticket")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("space_flight_route")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("space_flight_note")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {spaceFlightStateCustom.spaceFlights.map((spaceFlight) => (
              <TableRow key={spaceFlight.id}>
                <TableCell >{spaceFlight.id}</TableCell>
                <TableCell align="center">{spaceFlight.date}</TableCell>
                <TableCell align="center">{spaceFlight.flight_code}</TableCell>
                <TableCell align="center">
                  {spaceFlight.ticket === 1 ? t("yes") : t("not")}
                </TableCell>
                <TableCell align="center">
                  {spaceFlight.origin_city} ({spaceFlight.origin_code}) -{" "}
                  {spaceFlight.destination_city} ({spaceFlight.destination_code}
                  )
                </TableCell>
                <TableCell align="justify">{spaceFlight.notes}</TableCell>
                <TableCell align="right">
                  <Link
                    to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces/${space_id}/spaceFlights/${spaceFlight.id}`}
                  >
                    <Button className="edit_button" icon>
                      <Icon className="icon_color" name="pencil alternate" />
                    </Button>
                  </Link>
                  <Modal
                    trigger={
                      <Button className="ui button delete_button" icon>
                        <Icon name="trash alternate" className="icon_color" />
                      </Button>
                    }
                    content={t("space_flight_delete")}
                    actions={[
                      t("canceled_button"),
                      {
                        key: "eliminar",
                        content: t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handleDeleteSpaceFlight(spaceFlight.id),
                      },
                    ]}
                    size="tiny"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <br></br>
      </TableContainer>
     <Accordion styled style={{ width: "91vw" }}>
        <Accordion.Title onClick={handleHistoyClick} style={{ display: "flex", gap: "5px" }}>
          {t("log_historical")}
          {openHistory ? <ExpandLess /> : <ExpandMore />}
        </Accordion.Title>
        {spaceFlightLogState.fetching  ? (
          <div style={{ display: "flex", width: "91vw", height: "80px", justifyContent: "center" }}>
            <Loader size="medium" active inline>Loading...</Loader>
          </div>
        ) : (
          <Collapse in={openHistory} timeout="auto" unmountOnExit style={{ padding: "15px" }}>
            <LogsHistory
              logsHistory={spaceFlightLogState.logs}
            />
          </Collapse>
        )}
      </Accordion>
    </>
  );
}
