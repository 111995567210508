import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
//UI
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Icon, Form } from "semantic-ui-react";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
//ACTIONS
import { fetchIngalaListCustom, initialStateIngalaListCustom } from "../../../../redux/slices/custom/reservations/cruise/ingala/customIngalaList.slice";
//RESOURCES
import HeadDetail from "../../../../components/headDetail";
import { API_BASE_URL } from "../../../../services/constants";
import { scrollToTop } from "../../../resources/utils";

export default function IngalaList() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { cruise_id } = useParams();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const passengerIngalaStateCustom = useSelector((state) => state.customIngalaList);
  const ingalaReportState = useSelector((state) => state.ingalaReportReducer);
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  var choices = [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIngalaListCustom(cruise_id));
    scrollToTop();
    return () => {
      dispatch(initialStateIngalaListCustom());
    };
  }, [dispatch, cruise_id]);

  if (passengerIngalaStateCustom.fetching || passengerIngalaStateCustom.fetching) {
    return <CircularProgress />;
  }

  if (passengerIngalaStateCustom.error) {
    return <div>Error: {passengerIngalaStateCustom.error}</div>;
  }

  if (passengerIngalaStateCustom.error) {
    return <div>Error: {passengerIngalaStateCustom.error}</div>;
  }

  const handleGenerateReport = async () => {
    getSelectedPassengers();
    if (choices.length > 0) {
      const url = API_BASE_URL + "cruise/" + cruise_id + "/ingala?passengers_ids=" + choices;
      window.open(url, "_self");
    }
  };

  const getSelectedPassengers = function (id) {
    var ele = document.getElementsByName("tct");

    choices.length = 0;
    ele.forEach((el) => {
      if (el.checked) {
        choices.push(el.id);
      }
    });
    return choices;
  };

  const handleClick = (newState) => () => {
    if (company === "") {
      setState({ open: true, ...newState });

      setTimeout(() => {
        setState({ ...state, open: false });
      }, 4000);
    } else {
      handleGenerateReport();
    }
  };

  if (ingalaReportState.error === "Incomplete route" && message === "") {
    setMessage(ingalaReportState.error);
    setState({
      open: true,
      vertical: "top",
      horizontal: "right",
    });
  }

  const handleClose = () => {
    setState({
      open: false,
      vertical: "top",
      horizontal: "right",
    });
  };

  return (
    <>
      {company === "" ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert severity="warning" color="error" variant="filled">
            {t("ingala_required")}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}

      {message === "Incomplete route" ? (
        <Snackbar
          open={open}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          key={vertical}
          onClose={() => {
            handleClose();
          }}
        >
          <Alert severity="error" color="error" variant="filled">
            {t("ingala_message_error")}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}

      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{passengerIngalaStateCustom.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">{t("ingala_title")}</div>
      </div>
      <br />
      <br />
      <br />
      <HeadDetail
        yacht_name={passengerIngalaStateCustom.cruise.yacht_name}
        itinerary_name={passengerIngalaStateCustom.cruise.itinerary_name}
        start_date={passengerIngalaStateCustom.cruise.start_date_format}
        end_date={passengerIngalaStateCustom.cruise.end_date_format}
      />
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("ingala_title")} {passengerIngalaStateCustom.message}
          </Typography>
          <div className={classes.search}>
            <button
              type="submit"
              onClick={handleClick({ vertical: "top", horizontal: "right" })}
              className="ui primary button"
            >
              <Icon name="file alternate" />
              {t("generate_report_button")}
            </button>
          </div>
        </Toolbar>
        <Divider variant="fullWidth" />
        <br />

        <Toolbar>
          <Form>
            <Form.Group>
              <Form.Field>
                <label>{t("ingala_company")}*</label>
                <select
                  style={{ width: "400px" }}
                  aria-expanded="true"
                  name="company"
                  defaultValue={company}
                  onChange={(event) => setCompany(event.target.value)}
                >
                  <option></option>
                  <option value="rolfwittmer">
                    ROLF WITTMER TURISMO GALÁPAGOS
                  </option>
                  <option value="tiptop">TIP TOP TRAVEL</option>
                </select>
              </Form.Field>
            </Form.Group>
          </Form>
        </Toolbar>
        <Divider variant="fullWidth" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("ingala_type")}</TableCell>
              <TableCell>{t("ingala_lastName")}</TableCell>
              <TableCell>{t("ingala_name")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passengerIngalaStateCustom.passengers.map((passenger, index) => (
              <TableRow key={passenger.passenger_id}>
                <TableCell align="center">
                  <div className="ui checkbox">
                    <input
                      type="checkbox"
                      name="tct"
                      id={passenger.passenger_id}
                      defaultChecked={passenger.tct === 1}
                      onChange={() =>
                        getSelectedPassengers(passenger.passenger_id)
                      }
                    />
                    <label></label>
                  </div>
                </TableCell>
                <TableCell>{passenger.cruise_type}</TableCell>
                <TableCell>{passenger.last_name}</TableCell>
                <TableCell>{passenger.first_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
