import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createCaptain } from "../../../redux/actions/catalogs/captain/createCaptain";
import { fetchCaptain, initialStateCaptain } from "../../../redux/actions/catalogs/captain/fetchCaptain";
import { updateCaptain } from "../../../redux/actions/catalogs/captain/updateCaptain";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function CaptainForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { captain_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const captainState = useSelector((state) => state.captainReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (captain_id) {
      dispatch(fetchCaptain(captain_id));
    }
    scrollToTop();

    return () => {
      dispatch(initialStateCaptain());
    };
  }, []);

  if (captainState.fetching) {
    return <Loader active inline />;
  }

  if (captainState.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (captain_id) {
      dispatch(updateCaptain(captain_id, data));
    } else {
      dispatch(createCaptain(data));
    }
  };

  if (captainState.message === "resource created successfully" || captainState.message === "resource updated successfully") {
    history.push("/captains");
  }

  return (
    <>
      <Header as='h3' dividing>{t("captain_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("captain_name")}</label>
            <input name="first_name" defaultValue={captainState.captain.first_name} placeholder={t("captain_name")} maxLength={100} ref={register({ required: true })} autoFocus />
            {errors.first_name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("captain_lastName")}</label>
            <input name="last_name" defaultValue={captainState.captain.last_name} placeholder={t("captain_lastName")} maxLength={100} ref={register({ required: true })} />
            {errors.last_name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("captain_phone")}</label>
            <input name="cell_phone" defaultValue={captainState.captain.cell_phone} placeholder={t("captain_phone")} maxLength={50} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("active_tag")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" defaultChecked={captainState.captain.active === 1} ref={register}/>
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={captainState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/captains"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
