import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import AirlineService from "../../../../services/catalogs/AirlineService";

export const REQUEST_CREATE_AIRLINE = "REQUEST_CREATE_AIRLINE";
export const CREATE_AIRLINE_SUCCESS = "CREATE_AIRLINE_SUCCESS";
export const CREATE_AIRLINE_ERROR = "CREATE_AIRLINE_ERROR";

function requestCreateAirline() {
  return {
    type: REQUEST_CREATE_AIRLINE,
  };
}

function createAirlineSuccess(json) {
  return {
    type: CREATE_AIRLINE_SUCCESS,
    message: json.data,
  };
}

function createAirlineError(json) {
  return {
    type: CREATE_AIRLINE_ERROR,
    error: json.data,
  };
}

export function createAirline(payload) {
  return (dispatch) => {
    dispatch(requestCreateAirline());
    return AirlineService.postAirline(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createAirlineSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createAirline(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createAirlineError(json.data));
      }
    });
  };
}
