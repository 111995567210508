import {
    REQUEST_FETCH_CRUISE_LANGUAGE_LOGS,
    FETCH_CRUISE_LANGUAGE_LOGS_SUCCESS,
    FETCH_CRUISE_LANGUAGE_LOGS_ERROR,
  } from '../../../actions/reservations/cruiseLanguage/fetchCruiseLanguageLogs';
    
  const initialState = {
    logs: [],   
    processing: false,
    fetching: false,
    message: "",
    error: "",
  };
  
  const cruiseLanguageLogReducer = (state = initialState, action) => {
    switch (action.type) {
      case REQUEST_FETCH_CRUISE_LANGUAGE_LOGS: {
        return {
          ...state,
          logs: [],
         
          processing: false,
          fetching: true,
          message: "",
          error: "",
        };
      }
      case FETCH_CRUISE_LANGUAGE_LOGS_SUCCESS: {
        return {
          ...state,
          logs: action.logs,
         
          processing: false,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case FETCH_CRUISE_LANGUAGE_LOGS_ERROR: {
        return {
          ...state,
          logs: [],
         
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
     
      default: {
        return state;
      }
    }
  };
  
  export default cruiseLanguageLogReducer;
  