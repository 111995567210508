import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import BookingService from "../../../../services/reservations/BookingService";

export const REQUEST_UPDATE_BOOKING = "REQUEST_UPDATE_BOOKING";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";
export const UPDATE_BOOKING_ERROR = "UPDATE_BOOKING_ERROR";

function requestUpdateBooking() {
  return {
    type: REQUEST_UPDATE_BOOKING,
  };
}

function updateBookingSuccess(json) {
  return {
    type: UPDATE_BOOKING_SUCCESS,
    message: json.data,
  };
}

function updateBookingError(json) {
  return {
    type: UPDATE_BOOKING_ERROR,
    error: json.data,
  };
}

export function updateBooking(cruise_id, reservation_id, booking_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateBooking());
    return BookingService.putBooking(cruise_id, reservation_id, booking_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateBookingSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateBooking(cruise_id, reservation_id, booking_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateBookingError(json.data));
      }
    });
  };
}
