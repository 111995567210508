import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ReservationService from "../../../../services/reservations/ReservationService";

export const REQUEST_UPDATE_RESERVATION = "REQUEST_UPDATE_RESERVATION";
export const UPDATE_RESERVATION_SUCCESS = "UPDATE_RESERVATION_SUCCESS";
export const UPDATE_RESERVATION_ERROR = "UPDATE_RESERVATION_ERROR";

function requestUpdateReservation() {
  return {
    type: REQUEST_UPDATE_RESERVATION,
  };
}

function updateReservationSuccess(json) {
  return {
    type: UPDATE_RESERVATION_SUCCESS,
    message: json.data,
  };
}

function updateReservationError(json) {
  return {
    type: UPDATE_RESERVATION_ERROR,
    error: json.data,
  };
}

export function updateReservation(reservation_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateReservation());
    return ReservationService.putReservation(reservation_id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(updateReservationSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(updateReservation(reservation_id, payload));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(updateReservationError(json.data));
        }
      }
    );
  };
}
