import AvailabilityService from "../../../../services/b2b/AvailabilityService";

export const REQUEST_CREATE_AVAILABILITYB2B_CHARTER =
  "REQUEST_CREATE_AVAILABILITYB2B_CHARTER";
export const CREATE_AVAILABILITYB2B_CHARTER_SUCCESS =
  "CREATE_AVAILABILITYB2B_CHARTER_SUCCESS";
export const CREATE_AVAILABILITYB2B_CHARTER_ERROR =
  "CREATE_AVAILABILITYB2B_CHARTER_ERROR";

function requestCreateAvailabilityB2bCharter() {
  return {
    type: REQUEST_CREATE_AVAILABILITYB2B_CHARTER,
  };
}

function createAvailabilityB2bCharterCharterSuccess(json) {
  return {
    type: CREATE_AVAILABILITYB2B_CHARTER_SUCCESS,
    message: json.data,
  };
}

function createAvailabilityB2bCharterCharterError(json) {
  return {
    type: CREATE_AVAILABILITYB2B_CHARTER_ERROR,
    error: json.data,
  };
}

export function createAvailabilityB2bCharter(payload) {
  return (dispatch) => {
    dispatch(requestCreateAvailabilityB2bCharter());
    return AvailabilityService.insertAvailabilityCruiseCharter(payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(createAvailabilityB2bCharterCharterSuccess(json.data));
        } else {
          dispatch(createAvailabilityB2bCharterCharterError(json.data));
        }
      }
    );
  };
}
