import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import BerthService from "../../../../services/catalogs/BerthService";

export const REQUEST_FETCH_BERTHS = "REQUEST_FETCH_BERTHS";
export const FETCH_BERTHS_SUCCESS = "FETCH_BERTHS_SUCCESS";
export const FETCH_BERTHS_ERROR = "FETCH_BERTHS_ERROR";

function requestFetchBerths() {
  return {
    type: REQUEST_FETCH_BERTHS,
  };
}

function fetchBerthsSuccess(json) {
  return {
    type: FETCH_BERTHS_SUCCESS,
    berths: json.data,
  };
}

function fetchBerthsError(json) {
  return {
    type: FETCH_BERTHS_ERROR,
    error: json.data,
  };
}

export function fetchBerths() {
  return (dispatch) => {
    dispatch(requestFetchBerths());
    return BerthService.getBerths().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchBerthsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchBerths());
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchBerthsError(json.data));
      }
    });
  };
}
