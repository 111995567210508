import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import YachtService from "../../../../services/catalogs/YachtService";

export const REQUEST_FETCH_YACHT = "REQUEST_FETCH_YACHT";
export const FETCH_YACHT_SUCCESS = "FETCH_YACHT_SUCCESS";
export const FETCH_YACHT_ERROR = "FETCH_YACHT_ERROR";
export const INITIAL_STATE_YACHT = "INITIAL_STATE_YACHT";

function requestFetchYacht() {
  return {
    type: REQUEST_FETCH_YACHT,
  };
}

function fetchYachtSuccess(json) {
  return {
    type: FETCH_YACHT_SUCCESS,
    yacht: json.data,
  };
}

function fetchYachtError(json) {
  return {
    type: FETCH_YACHT_ERROR,
    error: json.data,
  };
}

export function initialStateYacht() {
  return {
    type: INITIAL_STATE_YACHT,
  };
}

export function fetchYacht(yacht_id) {
  return (dispatch) => {
    dispatch(requestFetchYacht());
    return YachtService.getYacht(yacht_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchYachtSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchYacht(yacht_id));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(fetchYachtError(json.data));
      }
    });
  };
}
