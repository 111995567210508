import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IkarusRateService from "../../../../services/rates/IkarusRateService";

export const REQUEST_CREATE_IKARUS_RATE = "REQUEST_CREATE_IKARUS_RATE";
export const CREATE_IKARUS_RATE_SUCCESS = "CREATE_IKARUS_RATE_SUCCESS";
export const CREATE_IKARUS_RATE_ERROR = "CREATE_IKARUS_RATE_ERROR";

function requestCreateIkarusRate() {
  return {
    type: REQUEST_CREATE_IKARUS_RATE,
  };
}

function createIkarusRateSuccess(json) {
  return {
    type: CREATE_IKARUS_RATE_SUCCESS,
    message: json.data,
  };
}

function createIkarusRateError(json) {
  return {
    type: CREATE_IKARUS_RATE_ERROR,
    error: json.data,
  };
}

export function createIkarusRate(payload) {
  return (dispatch) => {
    dispatch(requestCreateIkarusRate());
    return IkarusRateService.postIkarusRate(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createIkarusRateSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createIkarusRate(payload));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(createIkarusRateError(json.data));
      }
    });
  };
}
