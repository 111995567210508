import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class AvailableCabinService {
  static getAvailableCabins(cruise_id, reservation_id, booking_id) {
    var url =
      API_BASE_URL +
      "cruise/" +
      cruise_id +
      "/reservation/" +
      reservation_id +
      "/booking/" +
      booking_id +
      "/selectCabins";
    return axios.get(url);
  }

  static updateTwoCabins(payload) {
    var url = "bookings/switchTwoCabins";
    return axios.put(url, payload);
  }
  static updateOneCabin(payload) {
    var url = "bookings/switchOneCabin";
    return axios.put(url, payload);
  }
}
