import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CabinService from "../../../../services/catalogs/CabinService";

export const REQUEST_FETCH_CABIN = "REQUEST_FETCH_CABIN";
export const FETCH_CABIN_SUCCESS = "FETCH_CABIN_SUCCESS";
export const FETCH_CABIN_ERROR = "FETCH_CABIN_ERROR";
export const INITIAL_STATE_CABIN = "INITIAL_STATE_CABIN";

function requestFetchCabin() {
  return {
    type: REQUEST_FETCH_CABIN,
  };
}

function fetchCabinSuccess(json) {
  return {
    type: FETCH_CABIN_SUCCESS,
    cabin: json.data,
  };
}

function fetchCabinError(json) {
  return {
    type: FETCH_CABIN_ERROR,
    error: json.data,
  };
}

export function initialStateCabin() {
  return {
    type: INITIAL_STATE_CABIN,
  };
}

export function fetchCabin(cabin_id) {
  return (dispatch) => {
    dispatch(requestFetchCabin());
    return CabinService.getCabin(cabin_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCabinSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCabin(cabin_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCabinError(json.data));
      }
    });
  };
}
