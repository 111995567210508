import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideLanguageService from "../../../../services/catalogs/GuideLanguageService";

export const REQUEST_DELETE_GUIDE_LANGUAGE = "REQUEST_DELETE_GUIDE_LANGUAGE";
export const DELETE_GUIDE_LANGUAGE_SUCCESS = "DELETE_GUIDE_LANGUAGE_SUCCESS";
export const DELETE_GUIDE_LANGUAGE_ERROR = "DELETE_GUIDE_LANGUAGE_ERROR";

function requestDeleteGuideLanguage() {
  return {
    type: REQUEST_DELETE_GUIDE_LANGUAGE,
  };
}

function deleteGuideLanguageSuccess(json) {
  return {
    type: DELETE_GUIDE_LANGUAGE_SUCCESS,
    message: json.data,
  };
}

function deleteGuideLanguageError(json) {
  return {
    type: DELETE_GUIDE_LANGUAGE_ERROR,
    error: json.data,
  };
}

export function deleteGuideLanguage(guide_id, id) {
  return (dispatch) => {
    dispatch(requestDeleteGuideLanguage());
    return GuideLanguageService.deleteGuideLanguage(guide_id, id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(deleteGuideLanguageSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(deleteGuideLanguage(guide_id, id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(deleteGuideLanguageError(json.data));
        }
      }
    );
  };
}
