import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IkarusRateCabinService from "../../../../services/rates/IkarusRateCabinService";

export const REQUEST_DELETE_IKARUS_RATE_CABIN = "REQUEST_DELETE_IKARUS_RATE_CABIN";
export const DELETE_IKARUS_RATE_CABIN_SUCCESS = "DELETE_IKARUS_RATE_CABIN_SUCCESS";
export const DELETE_IKARUS_RATE_CABIN_ERROR = "DELETE_IKARUS_RATE_CABIN_ERROR";
export const INITIAL_STATE_IKARUS_RATE_CABIN = "INITIAL_STATE_IKARUS_RATE_CABIN";

function requestDeleteIkarusRateCabin() {
  return {
    type: REQUEST_DELETE_IKARUS_RATE_CABIN,
  };
}

function deleteIkarusRateCabinSuccess(json) {
  return {
    type: DELETE_IKARUS_RATE_CABIN_SUCCESS,
    message: json.data,
  };
}

function deleteIkarusRateCabinError(json) {
  return {
    type: DELETE_IKARUS_RATE_CABIN_ERROR,
    error: json.data,
  };
}

export function initialStateIkarusRateCabin() {
  return {
    type: INITIAL_STATE_IKARUS_RATE_CABIN,
  };
}

export function deleteIkarusRatesCabin(ikarusRateCabin_id) {
  return (dispatch) => {
    dispatch(requestDeleteIkarusRateCabin());
    return IkarusRateCabinService.deleteIkarusRateCabin(ikarusRateCabin_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(deleteIkarusRateCabinSuccess(json.data));
        } else if(json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(deleteIkarusRatesCabin(ikarusRateCabin_id));
        } else if(json.data.code === 498){
          logOut();
        } else {
          dispatch(deleteIkarusRateCabinError(json.data));
        }
      }
    );
  };
}
