import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customCruiseLanguageList = createSlice({
  name: 'customCruiseLanguageList',
  initialState: {
    cruise: {},
    cruiseLanguages: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateCruiseLanguageListCustom(state, action) {
      return {
        cruise: {},
        cruiseLanguages: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesCruiseLanguageListCustom(state, action) {
      return {
        cruise: {},
        cruiseLanguages: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchCruiseLanguageListCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        cruiseLanguages: action.payload.cruiseLanguages,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchCruiseLanguageListCustomError(state, action) {
      return {
        cruise: {},
        cruiseLanguages: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchCruiseLanguageListCustom = (cruise_id) => dispatch => {
  dispatch(requesCruiseLanguageListCustom());
  axios.get(`/cruise/${cruise_id}/customCruiseLanguageList`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchCruiseLanguageListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCruiseLanguageListCustom(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCruiseLanguageListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateCruiseLanguageListCustom,
  requesCruiseLanguageListCustom,
  fetchCruiseLanguageListCustomSuccess,
  fetchCruiseLanguageListCustomError,
} = customCruiseLanguageList.actions;

export default customCruiseLanguageList.reducer;