import {
  REQUEST_FETCH_YACHTS,
  FETCH_YACHTS_SUCCESS,
  FETCH_YACHTS_ERROR,
  INITIAL_STATE_YACHT,
} from "../../../actions/rates/familyRoom/fetchYachts";

const initialState = {
  yachts: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const yachtFamilyRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_YACHT: {
      return {
        ...state,
        yachts: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_YACHTS: {
      return {
        ...state,
        yachts: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_YACHTS_SUCCESS: {
      return {
        ...state,
        yachts: action.yachts,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_YACHTS_ERROR: {
      return {
        ...state,
        yachts: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default yachtFamilyRoomReducer;
