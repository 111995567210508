import {
  REQUEST_FETCH_CONTRACT_RATES,
  FETCH_CONTRACT_RATES_SUCCESS,
  FETCH_CONTRACT_RATES_ERROR,
} from "../../../actions/rates/contractRates/fetchContractRates";

import {
  INITIAL_STATE_CONTRACT_RATE,
  REQUEST_FETCH_CONTRACT_RATE,
  FETCH_CONTRACT_RATE_SUCCESS,
  FETCH_CONTRACT_RATE_ERROR,
} from "../../../actions/rates/contractRates/fetchContractRate";

import {
  REQUEST_FETCH_YEARS,
  FETCH_YEARS_SUCCESS,
  FETCH_YEARS_ERROR,
} from "../../../actions/rates/contractRates/fetchYears";

import {
  REQUEST_CREATE_CONTRACT_RATE,
  CREATE_CONTRACT_RATE_SUCCESS,
  CREATE_CONTRACT_RATE_ERROR,
} from "../../../actions/rates/contractRates/createContractRate";

import {
  REQUEST_UPDATE_CONTRACT_RATE,
  UPDATE_CONTRACT_RATE_SUCCESS,
  UPDATE_CONTRACT_RATE_ERROR,
} from "../../../actions/rates/contractRates/updateContractRate";

import {
  REQUEST_ACTIVE_CONTRACT_RATE,
  ACTIVE_CONTRACT_RATE_SUCCESS,
  ACTIVE_CONTRACT_RATE_ERROR,
} from "../../../actions/rates/contractRates/activeContractRate";

import {
  REQUEST_INACTIVE_CONTRACT_RATE,
  INACTIVE_CONTRACT_RATE_SUCCESS,
  INACTIVE_CONTRACT_RATE_ERROR,
} from "../../../actions/rates/contractRates/inactiveContractRate";

import {
  REQUEST_DELETE_CONTRACT_RATE,
  DELETE_CONTRACT_RATE_SUCCESS,
  DELETE_CONTRACT_RATE_ERROR,
} from "../../../actions/rates/contractRates/deleteContractRate";

import {
  REQUEST_FIND_CONTRACT_RATE,
  FIND_CONTRACT_RATE_SUCCESS,
  FIND_CONTRACT_RATE_ERROR,
} from "../../../actions/rates/contractRates/findContractRate";

import {
  REQUEST_FIND_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE,
  FIND_CONTRACT_RATE__BY_CRUISE_AGENCY_CRUISETYPE_SUCCESS,
  FIND_CONTRACT_RATE__BY_CRUISE_AGENCY_CRUISETYPE_ERROR,
  INITIAL_STATE_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE,
} from "../../../actions/rates/contractRates/findContractRateByCruiseAgencyCruiseType";

const initialState = {
  contractRates: [],
  years: [],
  contractRate: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const contractRateReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_CONTRACT_RATE: {
      return {
        ...state,
        contractRates: [],
        years: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_CONTRACT_RATES: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CONTRACT_RATES_SUCCESS: {
      return {
        ...state,
        contractRates: action.contracts,
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CONTRACT_RATES_ERROR: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_CONTRACT_RATE: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CONTRACT_RATE_SUCCESS: {
      return {
        ...state,
        contractRates: [],
        contractRate: action.contract,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CONTRACT_RATE_ERROR: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_CONTRACT_RATE: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_CONTRACT_RATE_SUCCESS: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_CONTRACT_RATE_ERROR: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_CONTRACT_RATE: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_CONTRACT_RATE_SUCCESS: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_CONTRACT_RATE_ERROR: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_ACTIVE_CONTRACT_RATE: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case ACTIVE_CONTRACT_RATE_SUCCESS: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case ACTIVE_CONTRACT_RATE_ERROR: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_INACTIVE_CONTRACT_RATE: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case INACTIVE_CONTRACT_RATE_SUCCESS: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case INACTIVE_CONTRACT_RATE_ERROR: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_CONTRACT_RATE: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_CONTRACT_RATE_SUCCESS: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_CONTRACT_RATE_ERROR: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_YEARS: {
      return {
        ...state,
        years: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_SUCCESS: {
      return {
        ...state,
        years: action.years,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_ERROR: {
      return {
        ...state,
        years: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FIND_CONTRACT_RATE: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FIND_CONTRACT_RATE_SUCCESS: {
      return {
        ...state,
        contractRates: [],
        contractRate: action.contract,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FIND_CONTRACT_RATE_ERROR: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FIND_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FIND_CONTRACT_RATE__BY_CRUISE_AGENCY_CRUISETYPE_SUCCESS: {
      return {
        ...state,
        contractRates: [],
        contractRate: action.contract,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FIND_CONTRACT_RATE__BY_CRUISE_AGENCY_CRUISETYPE_ERROR: {
      return {
        ...state,
        contractRates: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case INITIAL_STATE_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE: {
      return {
        ...state,
        contractRates: [],
        years: [],
        contractRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    default: {
      return state;
    }
  }
};

export default contractRateReducer;
