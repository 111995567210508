import {
  REQUEST_FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_ERROR,
} from "../../../actions/catalogs/company/fetchCompanies";

import {
  INITIAL_STATE_COMPANY,
  REQUEST_FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
} from "../../../actions/catalogs/company/fetchCompany";

import {
  REQUEST_CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_ERROR,
} from "../../../actions/catalogs/company/createCompany";

import {
  REQUEST_UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
} from "../../../actions/catalogs/company/updateCompany";

import {
  REQUEST_DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
} from "../../../actions/catalogs/company/deleteCompany";

const initialState = {
  companies: [],
  company: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_COMPANY: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_COMPANIES: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_COMPANIES_SUCCESS: {
      return {
        ...state,
        companies: action.companies,
        company: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_COMPANIES_ERROR: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_COMPANY: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_COMPANY_SUCCESS: {
      return {
        ...state,
        companies: [],
        company: action.company,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_COMPANY_ERROR: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_COMPANY: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_COMPANY_SUCCESS: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_COMPANY_ERROR: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_COMPANY: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_COMPANY_SUCCESS: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_COMPANY_ERROR: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_COMPANY: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_COMPANY_SUCCESS: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_COMPANY_ERROR: {
      return {
        ...state,
        companies: [],
        company: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default companyReducer;
