import YachtsService from "../../../../services/b2b/YachtsService";

export const REQUEST_FETCH_ITINERARIES = "REQUEST_FETCH_ITINERARIES";
export const FETCH_ITINERARIES_SUCCESS = "FETCH_ITINERARIES_SUCCESS";
export const FETCH_ITINERARIES_ERROR = "FETCH_ITINERARIES_ERROR";
export const INITIAL_STATE_ITINERARIES = "INITIAL_STATE_ITINERARIES";

function requestFetchItineraries() {
  return {
    type: REQUEST_FETCH_ITINERARIES,
  };
}

function fetchItinerariesSuccess(json) {
  return {
    type: FETCH_ITINERARIES_SUCCESS,
    itineraries: json.data,
  };
}

function fetchItinerariesError(json) {
  return {
    type: FETCH_ITINERARIES_ERROR,
    error: json.data,
  };
}

export function initialStateItineraries() {
  return {
    type: INITIAL_STATE_ITINERARIES,
  };
}

export function fetchItineraries(yachtName) {
  return (dispatch) => {
    dispatch(requestFetchItineraries());
    return YachtsService.getB2bItineraries(yachtName).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchItinerariesSuccess(json.data));
      } else {
        dispatch(fetchItinerariesError(json.data));
      }
    });
  };
}
