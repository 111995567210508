import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//UI
import { Button, Modal, Icon } from "semantic-ui-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
//ACTIONS
import { initialStateVoucher } from "../../../../redux/actions/reservations/voucher/fetchVoucher";
import { deleteVoucher } from "../../../../redux/actions/reservations/voucher/deleteVoucher";
import { fetchVoucherInformacion, initialStateVoucherInformation } from "../../../../redux/actions/reservations/voucherPDF/fetchInformationVoucherPdf";
import { fetchVoucherListCustom, initialStateVoucherListCustom } from "../../../../redux/slices/custom/reservations/cruise/voucher/customVoucherList.slice";
//RESOURCE
import HeadDetail from "../../../../components/headDetail";
import VoucherPDF from "./VoucherPDF";
import { scrollToTop } from "../../../resources/utils";

export default function VoucherList() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { cruise_id } = useParams();
  const voucherStateCustom = useSelector((state) => state.customVoucherList);
  const voucherState = useSelector((state) => state.voucherReducer);
  const voucherInformationState = useSelector((state) => state.VoucherPdfReducer);
  const [isVoucherLoading, setIsVoucherLoading] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVoucherListCustom(cruise_id));
    scrollToTop();
    return () => {
      dispatch(initialStateVoucherListCustom());
    };
  }, [dispatch, cruise_id]);

  if (voucherStateCustom.fetching || voucherStateCustom.fetching) {
    return <CircularProgress />;
  }

  if (voucherStateCustom.processing) {
    return <CircularProgress />;
  }

  if (voucherStateCustom.error) {
    return <div>Error: {voucherStateCustom.error}</div>;
  }

  if (voucherStateCustom.error) {
    return <div>Error: {voucherStateCustom.error}</div>;
  }

  if (voucherState.message === "resource deleted successfully") {
    dispatch(fetchVoucherListCustom(cruise_id));
    dispatch(initialStateVoucher());
  }

  const handleVoucher = (id) => {
    setIsVoucherLoading(id);
    dispatch(fetchVoucherInformacion(cruise_id, id));
  };

  if (voucherInformationState.message === "resource found successfully") {
    VoucherPDF(
      voucherStateCustom.cruise,
      voucherStateCustom.company,
      voucherInformationState.voucherInformation
    );
  }

  const handledeleteVoucher = (id) => {
    dispatch(deleteVoucher(id));
    dispatch(initialStateVoucherInformation());
  };

  return (
    <>
      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{voucherStateCustom.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">{t("voucher_title")}</div>
      </div>
      <br />
      <br />
      <br />
      <HeadDetail
        yacht_name={voucherStateCustom.cruise.yacht_name}
        itinerary_name={voucherStateCustom.cruise.itinerary_name}
        start_date={voucherStateCustom.cruise.start_date_format}
        end_date={voucherStateCustom.cruise.end_date_format}
      />
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("voucher_title")} {voucherStateCustom.message}
            <Link to={`/cruises/${cruise_id}/vouchers_new`}>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className={classes.margin}
              >
                <AddIcon />
              </Fab>
            </Link>
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("voucher_agency")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {voucherStateCustom.vouchers.map((voucher) => (
              <TableRow key={voucher.id}>
                <TableCell>{voucher.agency_name}</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => handleVoucher(voucher.id)}
                    className={
                      voucherInformationState.buildingpdf &&
                      voucher.id === isVoucherLoading
                        ? "ui loading positive button "
                        : "ui positive button"
                    }
                    icon
                  >
                    <Icon
                      className="icon_color"
                      name="file alternate"
                    />
                  </Button>

                  <Link to={`/cruises/${cruise_id}/vouchers/${voucher.id}`}>
                    <Button className="edit_button" icon>
                      <Icon className="icon_color" name="pencil alternate" />
                    </Button>
                  </Link>
                  <Modal
                    trigger={
                      <Button className="ui button delete_button" icon>
                        <Icon name="trash alternate" className="icon_color" />
                      </Button>
                    }
                    content={t("voucher_delete")}
                    actions={[
                      t("canceled_button"),
                      {
                        key: "eliminar",
                        content: t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handledeleteVoucher(voucher.id),
                      },
                    ]}
                    size="tiny"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
