import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import { createIkarusRatesCabin } from "../../../redux/actions/rates/ikarusRatesCabin/createIkarusRatesCabin";
import { initialStateIkarusRateCabin } from "../../../redux/actions/rates/ikarusRatesCabin/deleteIkarusRatesCabin";
import { fetchIkarusRateCabinFormCustom, initialStateIkarusRateCabinFormCustom } from "../../../redux/slices/custom/rates/ikarusRateCabins/customIkarusRateCabinForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function IkarusRateCabinForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { ikarusRate_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const ikarusRateCabinStateCustom = useSelector((state) => state.customIkarusRateCabinForm);
  const ikarusRateCabinState = useSelector((state) => state.ikarusRateCabinReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIkarusRateCabinFormCustom(ikarusRate_id));
    scrollToTop();
    return () => {
      dispatch(initialStateIkarusRateCabinFormCustom())
      dispatch(initialStateIkarusRateCabin());
    };
  }, []);  
  
  if (ikarusRateCabinStateCustom.fetching || ikarusRateCabinState.fetching) {
    return <Loader />;
  }

  if (ikarusRateCabinStateCustom.error) {
    return <div>Error: {ikarusRateCabinStateCustom.error}</div>;
  }

  if (ikarusRateCabinStateCustom.error) {
    return <div>Error: {ikarusRateCabinStateCustom.error}</div>;
  }

  const onSubmit = (data) => {
    dispatch(createIkarusRatesCabin(ikarusRate_id, data));
  };

  if (ikarusRateCabinState.message === "resource created successfully" || ikarusRateCabinState.message === "resource updated successfully") {
    history.go(-1);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section ><Link to="/ikarusRates" className="section">{t("cruise_rates")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/ikarusRates/${ikarusRate_id}/cabins`} className="section">{ikarusRateCabinStateCustom.ikarusRate.year} - {ikarusRateCabinStateCustom.ikarusRate.yacht} - {ikarusRateCabinStateCustom.ikarusRate.days} días</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("cabin_title")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Nombre</label>
              <select name="cabin_id"  aria-expanded="true" ref={register({ required: true })}>
                <option></option>
                {ikarusRateCabinStateCustom.cabins.map((cabin) => (
                  <option key={cabin.id} value={cabin.id} className="selected item">{cabin.name}</option>
                ))}
              </select>
              {errors.cabin_id && (<span className="error_message">Información requerida</span>)}
            </Form.Field>
          </Form.Group>

          <Divider variant="middle" />

          <Button primary type="submit" loading={ikarusRateCabinStateCustom.processing ? true : false}>{t("save_button")}</Button>
          <Link to={`/ikarusRates/${ikarusRate_id}/cabins`}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
