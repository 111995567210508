import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IkarusRateService from "../../../../services/rates/IkarusRateService";

export const REQUEST_FETCH_IKARUS_RATES = "REQUEST_FETCH_GENERAL_RATES";
export const FETCH_IKARUS_RATES_SUCCESS = "FETCH_GENERAL_RATES_SUCCESS";
export const FETCH_IKARUS_RATES_ERROR = "FETCH_GENERAL_RATES_ERROR";

function requestFetchIkarusRates() {
  return {
    type: REQUEST_FETCH_IKARUS_RATES,
  };
}

function fetchIkarusRatesSuccess(json) {
  return {
    type: FETCH_IKARUS_RATES_SUCCESS,
    ikarusRates: json.data,
  };
}

function fetchIkarusRatesError(json) {
  return {
    type: FETCH_IKARUS_RATES_ERROR,
    error: json.data,
  };
}

export function fetchIkarusRates(payload) {
  return (dispatch) => {
    dispatch(requestFetchIkarusRates());
    return IkarusRateService.getIkarusRates(payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchIkarusRatesSuccess(json.data));
        } else if(json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchIkarusRates(payload));
        } else if(json.data.code === 498){
          logOut();
        } else {
          dispatch(fetchIkarusRatesError(json.data));
        }
      }
    );
  };
}
