import {
  INITIAL_STATE_STATISTIC_REPORT,
  REQUEST_GENERATE_STATISTIC_REPORT,
  GENERATE_STATISTIC_REPORT_SUCCESS,
  GENERATE_STATISTIC_REPORT_ERROR,
} from "../../../actions/reservations/statistic/generateStatisticReport";

const initialState = {
  statistic: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const statisticReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_STATISTIC_REPORT: {
      return {
        ...state,
        statistic: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_GENERATE_STATISTIC_REPORT: {
      return {
        ...state,
        statistic: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case GENERATE_STATISTIC_REPORT_SUCCESS: {
      return {
        ...state,
        statistic: action.statistic,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case GENERATE_STATISTIC_REPORT_ERROR: {
      return {
        ...state,
        statistic: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default statisticReportReducer;
