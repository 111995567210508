import {
  INITIAL_STATE_SPACE_CANCELED,
  REQUEST_FETCH_SPACES_CANCELED,
  FETCH_SPACES_CANCELED_SUCCESS,
  FETCH_SPACES_CANCELED_ERROR,
} from "../../../actions/reservations/space/fetchSpacesCanceled";

const initialState = {
  spacesCanceled: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const spaceCanceledReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_SPACE_CANCELED: {
      return {
        ...state,
        spacesCanceled: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }

    case REQUEST_FETCH_SPACES_CANCELED: {
      return {
        ...state,
        spacesCanceled: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACES_CANCELED_SUCCESS: {
      return {
        ...state,
        spacesCanceled: action.spacesCanceled,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACES_CANCELED_ERROR: {
      return {
        ...state,
        spacesCanceled: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default spaceCanceledReducer;
