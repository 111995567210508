import StatisticService from "../../../../services/reservations/StatisticService";

export const REQUEST_GENERATE_STATISTIC_REPORT =
  "REQUEST_GENERATE_STATISTIC_REPORT";
export const GENERATE_STATISTIC_REPORT_SUCCESS =
  "GENERATE_STATISTIC_REPORT_SUCCESS";
export const GENERATE_STATISTIC_REPORT_ERROR =
  "GENERATE_STATISTIC_REPORT_ERROR";
export const INITIAL_STATE_STATISTIC_REPORT = "INITIAL_STATE_STATISTIC_REPORT";

function requestGenerateStatisticReport() {
  return {
    type: REQUEST_GENERATE_STATISTIC_REPORT,
  };
}

function generateStatisticReportSuccess(json) {
  return {
    type: GENERATE_STATISTIC_REPORT_SUCCESS,
    statistic: json.data,
  };
}

function generateStatisticReportError(json) {
  return {
    type: GENERATE_STATISTIC_REPORT_ERROR,
    error: json.data,
  };
}

export function initialStateStatisticReport() {
  return {
    type: INITIAL_STATE_STATISTIC_REPORT,
  };
}

export function generateStatisticReport(payload) {
  return (dispatch) => {
    dispatch(requestGenerateStatisticReport());
    return StatisticService.getStatisticReport(payload).then((json) => {
      if (json.status === 200) {
        dispatch(generateStatisticReportSuccess(json.data));
      } else {
        dispatch(generateStatisticReportError(json.data));
      }
    });
  };
}
