import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../styles/Common";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchReservationDetail,
  initialStateReservationDetail,
} from "../../../redux/actions/b2b/availability/fetchReservationDetail";
import { updateSpacesByReservation } from "../../../redux/actions/b2b/availability/updateSpacesByReservation";

export default function AvailabilityListReservationDetail() {
  let history = useHistory();
  let { reservation_id } = useParams();
  let startDate = [];
  let endDate = [];
  let startDay = "";
  let endDay = "";
  let canceledIds = [];
  let confirmedIds = [];
  const classes = useStyles();
  const { t } = useTranslation();
  const availabilityReservationDetailState = useSelector(
    (state) => state.availabilityReservationDetailReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReservationDetail(reservation_id));
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateReservationDetail());
    };
  }, [dispatch, reservation_id]);

  if (availabilityReservationDetailState.fetching) {
    return <CircularProgress />;
  }

  if (availabilityReservationDetailState.processing) {
    return <CircularProgress />;
  }

  if (availabilityReservationDetailState.error) {
    return <div>Error: {availabilityReservationDetailState.error}</div>;
  }

  if (
    availabilityReservationDetailState.message ===
    "resource updated successfully"
  ) {
    history.go(-1);
  }

  if (availabilityReservationDetailState.reservation.start_date !== undefined) {
    startDate = availabilityReservationDetailState.reservation.start_date.split(
      "/"
    );
    endDate = availabilityReservationDetailState.reservation.end_date.split(
      "/"
    );
    startDay = new Date(`${startDate[1]}/${startDate[0]}/${startDate[2]}`);
    endDay = new Date(`${endDate[1]}/${endDate[0]}/${endDate[2]}`);
  }

  const handleCanceledSpace = (id, check) => {
    if (check === true) {
      canceledIds.push(id);
      return canceledIds;
    } else {
      let index = canceledIds.findIndex((e) => e === id);
      canceledIds.splice(index, 1);
      return canceledIds;
    }
  };

  const handleConfirmedSpace = (id, check) => {
    if (check === true) {
      confirmedIds.push(id);

      return confirmedIds;
    } else {
      let index = confirmedIds.findIndex((e) => e === id);
      confirmedIds.splice(index, 1);
      return confirmedIds;
    }
  };

  const handleUpdateReservation = () => {
    dispatch(
      updateSpacesByReservation({
        canceledIds: canceledIds,
        confirmedIds: confirmedIds,
      })
    );
  };
  console.log(availabilityReservationDetailState);
  return (
    <>
      <TableContainer component={Paper}>
        <Toolbar>
          <div style={{ display: "flex" }}>
            <Typography
              className={classes.title}
              variant="h6"
              noWrap
              style={{ padding: "20px 0 10px  0" }}
            >
              {availabilityReservationDetailState.reservation.code}
              <div style={{ fontWeight: "normal", fontSize: "12px" }}>
                {t("itinerary_from") +
                  " " +
                  availabilityReservationDetailState.reservation.start_date}
              </div>
              <div style={{ fontWeight: "normal", fontSize: "12px" }}>
                {t("itinerary_to") +
                  " " +
                  availabilityReservationDetailState.reservation.end_date}
              </div>
              <br />
              <div style={{ fontWeight: "normal", fontSize: "12px" }}>
                <span style={{ fontWeight: "bold" }}>
                  {t("reservation_detail_executive")}:{" "}
                </span>{" "}
                {availabilityReservationDetailState.reservation.first_name}{" "}
                {availabilityReservationDetailState.reservation.last_name}
              </div>
            </Typography>
            <div
              style={{
                paddingTop: "20px",
                paddingLeft: "35px",
                fontSize: "14px",
                fontWeight: "bold",
                color: "#2C8ABD",
              }}
            >
              {availabilityReservationDetailState.reservation.number_days}D -{" "}
              {availabilityReservationDetailState.reservation.number_nights}N /{" "}
              {t(
                startDay.toLocaleString("es-CO", { weekday: "long" })
              ).toUpperCase()}{" "}
              -{" "}
              {t(
                endDay.toLocaleString("es-CO", { weekday: "long" })
              ).toUpperCase()}
            </div>

            <div
              style={{
                fontWeight: "normal",
                fontSize: "12px",
                paddingLeft: "500px",
                paddingTop: "115px",
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {t("reservation_detail_date_reservation")}:{" "}
              </span>{" "}
              {availabilityReservationDetailState.reservation.reservation_date}
            </div>
          </div>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t("reservation_detail_cabin")}
              </TableCell>
              <TableCell align="center">
                {t("reservation_detail_deck")}
              </TableCell>
              <TableCell align="center">
                {t("reservation_detail_passenger")}
              </TableCell>
              <TableCell align="center">
                {t("reservation_detail_time_limit")}
              </TableCell>
              <TableCell align="center">
                {t("reservation_detail_price")}
              </TableCell>
              <TableCell align="center">
                {t("reservation_detail_discount")}
              </TableCell>
              <TableCell>{t("reservation_detail_discount_child")}</TableCell>
              <TableCell>{t("reservation_detail_subtotal")}</TableCell>
              <TableCell align="center">
                {t("reservation_detail_confirm")}
              </TableCell>
              <TableCell>{t("reservation_detail_extend")}</TableCell>
              <TableCell align="center">
                {t("reservation_detail_cancel")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availabilityReservationDetailState.reservation.spaces !==
              undefined &&
              availabilityReservationDetailState.reservation.spaces.map(
                (space, index) => (
                  <TableRow key={index}>
                    {space.unionCabins === 1 ? (
                      <>
                        <TableCell
                          colSpan="4"
                          align="center"
                          style={{ fontWeight: "bold", fontSize: "13px" }}
                        >
                          {" "}
                          Family Room {t("between")}{" "}
                          <span style={{ color: "#2C8ABD" }}>
                            {" "}
                            Cabin {space.cabinOne}
                          </span>{" "}
                          {t("and")}{" "}
                          <span style={{ color: "#2C8ABD" }}>
                            {" "}
                            Cabin {space.cabinTwo}
                          </span>
                        </TableCell>
                        <TableCell align="center">$0</TableCell>
                        <TableCell align="center">$0</TableCell>
                        <TableCell align="center">$0</TableCell>
                        <TableCell align="center">$0</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="center">{space.cabin}</TableCell>
                        <TableCell align="center">
                          <span>
                            {space.deck}
                            <br />
                            <span style={{ fontSize: "9px" }}>
                              {" "}
                              (one full bed)
                            </span>
                          </span>
                        </TableCell>
                        <TableCell align="center">{space.gender}</TableCell>
                        <TableCell align="center">
                          {space.time_limit !== null ? space.time_limit : "-"}
                        </TableCell>
                        <TableCell align="center">{space.price}</TableCell>
                        <TableCell align="center">
                          {space.family_room === 0 ? "$0" : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {space.family_room === 0 ? "$0" : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {space.family_room === 0 ? "$0" : "-"}
                        </TableCell>
                        <TableCell align="center" width="160px">
                          {space.state === "confirmed" ? (
                            <>
                              <span>{t("reservation_confirmed_text")}</span>
                              <br />
                              <span
                                style={{
                                  fontSize: "11px",
                                }}
                              >
                                {t("reservation_confirm_date")}
                                <br />
                                {space.creation_date}
                              </span>
                            </>
                          ) : (
                            <div className="ui checkbox">
                              <input
                                type="checkbox"
                                name="confirmed"
                                value={space.space_id}
                                onChange={(e) =>
                                  handleConfirmedSpace(
                                    e.target.value,
                                    e.target.checked
                                  )
                                }
                              />
                              <label></label>
                            </div>
                          )}
                        </TableCell>
                        <TableCell align="center" width="160px">
                          Extender
                        </TableCell>
                        <TableCell align="center" width="160px">
                          {space.canceled === 0 || space.canceled === null ? (
                            <div className="ui checkbox">
                              <input
                                type="checkbox"
                                name="canceled"
                                value={space.space_id}
                                onChange={(e) =>
                                  handleCanceledSpace(
                                    e.target.value,
                                    e.target.checked
                                  )
                                }
                              />
                              <label></label>
                            </div>
                          ) : (
                            <>
                              <span>{t("reservation_cancel_text")}</span>
                              <br />
                              <span
                                style={{
                                  fontSize: "11px",
                                }}
                              >
                                {t("reservation_cancel_date")}
                                <br />
                                {space.date_cancellation}
                              </span>
                            </>
                          )}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
        <br />
        <div style={{ paddingLeft: "500px" }}>
          <button
            type="submit"
            className={
              availabilityReservationDetailState.processing
                ? "ui loading primary button"
                : "ui primary button"
            }
            onClick={() => handleUpdateReservation()}
          >
            {t("reservation_detail_update")}
          </button>
        </div>
        <br />
        <br />
      </TableContainer>
    </>
  );
}
