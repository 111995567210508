import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CountryService from "../../../../services/catalogs/CountryService";

export const REQUEST_DELETE_COUNTRY = "REQUEST_DELETE_COUNTRY";
export const DELETE_COUNTRY_SUCCESS = "DELETE_COUNTRY_SUCCESS";
export const DELETE_COUNTRY_ERROR = "DELETE_COUNTRY_ERROR";

function requestDeleteCountry() {
  return {
    type: REQUEST_DELETE_COUNTRY,
  };
}

function deleteCountrySuccess(json) {
  return {
    type: DELETE_COUNTRY_SUCCESS,
    message: json.data,
  };
}

function deleteCountryError(json) {
  return {
    type: DELETE_COUNTRY_ERROR,
    error: json.data,
  };
}

export function deleteCountry(country_id) {
  return (dispatch) => {
    dispatch(requestDeleteCountry());
    return CountryService.deleteCountry(country_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteCountrySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteCountry(country_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteCountryError(json.data));
      }
    });
  };
}
