import CruiseRateService from "../../../../services/reservations/CruiseRateService";

export const REQUEST_FETCH_CRUISE_RATE = "REQUEST_FETCH_CRUISE_RATE";
export const FETCH_CRUISE_RATE_SUCCESS = "FETCH_CRUISE_RATE_SUCCESS";
export const FETCH_CRUISE_RATE_ERROR = "FETCH_CRUISE_RATE_ERROR";
export const INITIAL_STATE_CRUISE_RATE = "INITIAL_STATE_CRUISE_RATE";

function requestFetchCrusieRate() {
  return {
    type: REQUEST_FETCH_CRUISE_RATE,
  };
}

function fetchCruiseRateSuccess(json) {
  return {
    type: FETCH_CRUISE_RATE_SUCCESS,
    cruiseRate: json.data,
  };
}

function fetchCruiseRateError(json) {
  return {
    type: FETCH_CRUISE_RATE_ERROR,
    error: json.data,
  };
}

export function initialStateCruiseRate() {
  return {
    type: INITIAL_STATE_CRUISE_RATE,
  };
}

export function fetchCruiseRate(cruise_id, id) {
  return (dispatch) => {
    dispatch(requestFetchCrusieRate());
    return CruiseRateService.getCruiseRate(cruise_id, id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCruiseRateSuccess(json.data));
      } else {
        dispatch(fetchCruiseRateError(json.data));
      }
    });
  };
}
