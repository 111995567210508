import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customGeneralRateForm = createSlice({
  name: 'customGeneralRateForm',
  initialState: {
    rates: [],
    yachts: [],
    days: [],
    generalRate: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateGeneralRateFormCustom(state, action) {
      return {
        rates: [],
        yachts: [],
        days: [],
        generalRate: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesGeneralRateFormCustom(state, action) {
      return {
        rates: [],
        yachts: [],
        days: [],
        generalRate: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchGeneralRateFormCustomSuccess(state, action) {
      return {
        rates: action.payload.rates,
        yachts: action.payload.yachts,
        days: action.payload.days,
        generalRate: action.payload.generalRate,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchGeneralRateFormCustomError(state, action) {
      return {
        rates: [],
        yachts: [],
        days: [],
        generalRate: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchGeneralRateFormCustom = (generalRate_id) => dispatch => {
  dispatch(requesGeneralRateFormCustom());
  axios.get(`/generalRates/form/generalRatesForm?generalRate_id=${generalRate_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchGeneralRateFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGeneralRateFormCustom(generalRate_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGeneralRateFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateGeneralRateFormCustom,
  requesGeneralRateFormCustom,
  fetchGeneralRateFormCustomSuccess,
  fetchGeneralRateFormCustomError,
} = customGeneralRateForm.actions;

export default customGeneralRateForm.reducer;