import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Icon, Table, Input, Header, Loader, Segment, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { fetchAgencies } from "../../../redux/actions/catalogs/agency/fetchAgencies";
import { deleteAgency } from "../../../redux/actions/catalogs/agency/deleteAgency";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function AgencyList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const agencyState = useSelector((state) => state.agencyReducer);
  const { register, getValues } = useForm();
  
  useEffect(() => {
    dispatch(fetchAgencies(""));
    scrollToTop();
  }, []);

  if (agencyState.fetching || agencyState.processing) {
    return <Loader active inline />;
  }

  if (agencyState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (agencyState.message === "resource deleted successfully") {
    dispatch(fetchAgencies(""));
  }

  const handledeleteAgency = (agency_id) => {
    dispatch(deleteAgency(agency_id));
  };

  const handleSearchAgency = () => {
    dispatch(fetchAgencies(getValues("searchWord")));
  };

  return (
    <>
      <Segment clearing> 
        <HeaderCatalog title={t("agency_title")} message={agencyState.message} to="/agencies_new" />
        <Header as='h5' floated='right'>
          <Input type="text" placeholder={t("search") + "..."} action>
            <input name="searchWord" maxLength={10} ref={register()} />
            <Button type="submit" onClick={() => handleSearchAgency()} icon="search" size="medium" />
          </Input>
        </Header>
      </Segment>
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("agency_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("agency_country")}</Table.HeaderCell>
            <Table.HeaderCell>{t("agency_email")}</Table.HeaderCell>
            <Table.HeaderCell>{t("agency_sales_commission")}</Table.HeaderCell>
            <Table.HeaderCell>{t("agency_type")}</Table.HeaderCell>
            <Table.HeaderCell>{t("agency_users")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {agencyState.agencies.map((agency) => (
          <Table.Row key={agency.id}>
            <Table.Cell collapsing><ActiveIcon active={agency.active} /></Table.Cell>
            <Table.Cell>{agency.name}</Table.Cell>
            <Table.Cell>{t(agency.country_name)}</Table.Cell>
            <Table.Cell>{agency.email}</Table.Cell>
            <Table.Cell>{agency.commission}</Table.Cell>
            <Table.Cell>{t(agency.agency_type)}</Table.Cell>
            <Table.Cell>
              {agency.users.length >= 1 ? (
                <Link to={`/agencies/${agency.id}/users`}>
                  {agency.users.map((user) => (
                    <div>{user}</div>
                  ))}
                </Link>
              ) : (
                <Link to={`/agencies/${agency.id}/users`}>
                  <Button circular color='blue' icon='add' size="mini" />
                </Link>
              )}
            </Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/agencies/${agency.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("agency_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handledeleteAgency(agency.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
