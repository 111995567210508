import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createAgency } from "../../../redux/actions/catalogs/agency/createAgency";
import { fetchCountries } from "../../../redux/actions/catalogs/country/fetchCountries";
import { fetchIdentificationTypes } from "../../../redux/actions/catalogs/identificationType/fetchIdentificationTypes";
import { initialStateCountry } from "../../../redux/actions/catalogs/country/fetchCountry";
import { initialStateIdentificationType } from "../../../redux/actions/catalogs/identificationType/fetchIdentificationType";
import { fetchAgency, initialStateAgency } from "../../../redux/actions/catalogs/agency/fetchAgency";
import { updateAgency } from "../../../redux/actions/catalogs/agency/updateAgency";
import { fetchAgencyFormCustom, initialStateAgencyFormCustom } from "../../../redux/slices/custom/catalogs/agency/customAgencyForm.slice";

//Resources
import { scrollToTop } from "../../resources/utils";
import { commissions } from "../../resources/optionsList";

export default function AgencyForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { agency_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const agencyStateCustom = useSelector((state) => state.customAgencyForm);
  
  const agencyState = useSelector((state) => state.agencyReducer);
  // const agencyStateCustom = useSelector((state) => state.countryReducer);
  // const agencyStateCustom = useSelector((state) => state.identificationTypeReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAgencyFormCustom("", agency_id));
    scrollToTop();
    return () => {
      dispatch(initialStateAgency());
      dispatch(initialStateAgencyFormCustom());
    };
  }, []);

  if (agencyStateCustom.fetching || agencyState.fetching) {
    return <Loader active inline />;
  }

  if (agencyStateCustom.error) {
    return <Message max negative
      header='Error!'
      content={t("server_error_form")}
    />
  }

  const onSubmit = (data) => {
    if (agency_id) {
      dispatch(updateAgency(agency_id, data));
    } else {
      dispatch(createAgency(data));
    }
  };

  if (agencyState.message === "resource created successfully" || agencyState.message === "resource updated successfully") {
    history.push("/agencies");
  }

  let identificationTypesAgency = agencyStateCustom.identificationTypes.filter(
    (identificationType) => identificationType.type === "agency"
  );

  return (
    <>
      <Header as='h3' dividing>{t("agency_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header as='h4' dividing color='grey'>{t("general_information_tag")}</Header>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("agency_name")}</label>
            <input name="name" defaultValue={agencyStateCustom.agency.name} placeholder={t("agency_name")} maxLength={200} ref={register({ required: true })} autoFocus />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("agency_identification_type")}</label>
            <select name="identification_type_id" defaultValue={agencyStateCustom.agency.identification_type_id} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {identificationTypesAgency.map((identificationType) => (
                <option key={identificationType.id} value={identificationType.id}>{identificationType.name}</option>
              ))}
            </select>
            {errors.identification_type_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("agency_identification")}</label>
            <input name="identification" defaultValue={agencyStateCustom.agency.identification} placeholder={t("agency_identification")} maxLength={100} ref={register} />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("agency_address")}</label>
            <input name="address" defaultValue={agencyStateCustom.agency.address} placeholder={t("agency_address")} maxLength={500} ref={register} />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("agency_country")}</label>
            <select name="country_id" defaultValue={agencyStateCustom.agency.country_id} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {agencyStateCustom.countries.map((country) => (
                <option key={country.id} value={country.id}>{country.name}</option>
              ))}
            </select>
            {errors.country_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("agency_city")}</label>
            <input name="city" defaultValue={agencyStateCustom.agency.city} placeholder={t("agency_city")} maxLength={100} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("agency_state")}</label>
            <input name="state" defaultValue={agencyStateCustom.agency.state} placeholder={t("agency_state")} maxLength={100} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("agency_postal_code")}</label>
            <input name="postal_code" defaultValue={agencyStateCustom.agency.postal_code} placeholder={t("agency_postal_code")} maxLength={100} ref={register} />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("agency_phone")}</label>
            <input name="phone" defaultValue={agencyStateCustom.agency.phone} placeholder={t("agency_phone")} maxLength={50} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("agency_cellphone")}</label>
            <input name="cellular_phone" defaultValue={agencyStateCustom.agency.cellular_phone} placeholder={t("agency_cellphone")} maxLength={50} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("agency_fax")}</label>
            <input name="fax" defaultValue={agencyStateCustom.agency.fax} placeholder={t("agency_fax")} maxLength={50} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("agency_email")}</label>
            <input name="email" defaultValue={agencyStateCustom.agency.email} placeholder={t("agency_email")} maxLength={200} ref={register} />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("agency_sales_commission")}</label>
            <select name="commission" defaultValue={agencyStateCustom.agency.commission} aria-expanded="true" ref={register}>
              <option></option>
              {commissions.map((commission, index) => (
                <option key={index} value={commission}>{commission}</option>
              ))}
            </select>
          </Form.Field>
          <Form.Field required>
            <label>{t("agency_type")}</label>
            <select name="agency_type" defaultValue={agencyStateCustom.agency.agency_type} aria-expanded="true" ref={register({ required: true })} >
              <option></option>
              <option value="retailer">{t("retailer")}</option>
              <option value="wholesaler">{t("wholesaler")}</option>
            </select>
            {errors.agency_type && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("agency_credit")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="credit" defaultChecked={agencyStateCustom.agency.credit === 1} ref={register} />
              <label></label>
            </div>
          </Form.Field>
          <Form.Field>
            <label>{t("active_tag")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" defaultChecked={agencyStateCustom.agency.active === 1} ref={register} />
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Header as='h4' dividing color='grey'>{t("contact_information_tag")}</Header>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("agency_contact_name")}</label>
            <input name="contact_person" defaultValue={agencyStateCustom.agency.contact_person} placeholder={t("agency_contact_name")} maxLength={100} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("agency_skype")}</label>
            <input name="contact_skype" defaultValue={agencyStateCustom.agency.contact_skype} placeholder={t("agency_skype")} maxLength={100} ref={register} />
          </Form.Field>
        </Form.Group>

        <Header as='h4' dividing color='grey'>{t("manager_information_tag")}</Header>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("agency_name")}</label>
            <input name="manager" defaultValue={agencyStateCustom.agency.manager} placeholder={t("agency_name")} maxLength={100}ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("agency_position")}</label>
            <input name="contact_position" defaultValue={agencyStateCustom.agency.contact_position} placeholder={t("agency_position")} maxLength={100} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("agency_phone")}</label>
            <input name="manager_phone" defaultValue={agencyStateCustom.agency.manager_phone} placeholder={t("agency_phone")} maxLength={50} ref={register} />
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={agencyStateCustom.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/agencies"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
