let numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
let years = [
  2020,
  2021,
  2022,
  2023,
  2024,
  2025,
  2026,
  2027,
  2028,
  2029,
  2030,
  2031,
  2032,
  2033,
  2034,
  2035,
  2036,
  2037,
  2038,
  2039,
  2040,
  2041,
  2042,
  2043,
  2044,
  2045,
  2046,
  2047,
  2048,
  2049,
  2050,
];
let days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
let types = ["child", "transfer"];
let capacities = [1, 2];
let levels = ["I", "II", "III"];
let identificationTypes = ["agency", "passenger"];
let arriveTypes = ["return_gps", "departure_gps"];
let orders = [1, 2];
const reservationStates = {
  CONFIRMED: { name: "confirmed", value: "confirmed" },
  RESERVED: { name: "reserved", value: "reserved" },
  WAITING: { name: "waiting", value: "waiting" },
};
const cruiseTypes = {
  FIRSTPART: { name: "first_part", value: "PP" },
  SECONDPART: { name: "second_part", value: "SP" },
  TOTAL: { name: "total", value: "TO" },
};

let salesReport = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
];

let ticketRoutes = [
  "QUITO (UIO)",
  "BALTRA (GPS)",
  "SAN CRISTOBAL (SCY)",
  "GUAYAQUIL (GYE)",
];

let cityFlight = [
  "Quito (UIO)",
  "Guayaquil (GYE)",
  "Baltra (GPS)",
  "San Cristobal (SCY)",
];

let typeRoutes = [
  "departure",
  "return",
];

let commissions = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

let months = [
  { name: "january", value: "01" },
  { name: "february", value: "02" },
  { name: "march", value: "03" },
  { name: "april", value: "04" },
  { name: "may", value: "05" },
  { name: "june", value: "06" },
  { name: "july", value: "07" },
  { name: "august", value: "08" },
  { name: "september", value: "09" },
  { name: "october", value: "10" },
  { name: "november", value: "11" },
  { name: "december", value: "12" },
];

let status = [
  { name: "married", value: "married" },
  { name: "single", value: "single" },
  { name: "divorced", value: "divorced" },
  { name: "widower", value: "widower" },
];

let passengerNationalities = [
  { name: "foreign", value: "foreign" },
  { name: "national", value: "national" },
  { name: "colonist", value: "colonist" },
];

let capacityYacht = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
];

let availabilityYacht = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
];

let capacityCabin = [
  1,
  2
];

export {
  numbers,
  days,
  capacities,
  levels,
  identificationTypes,
  arriveTypes,
  orders,
  reservationStates,
  cruiseTypes,
  salesReport,
  ticketRoutes,
  types,
  years,
  cityFlight,
  typeRoutes,
  commissions,
  months,
  status,
  passengerNationalities,
  capacityYacht,
  availabilityYacht,
  capacityCabin
};
