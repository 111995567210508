import {
  REQUEST_FETCH_AVAILABILITY_CABIN,
  FETCH_AVAILABILITY_CABIN_SUCCESS,
  FETCH_AVAILABILITY_CABIN_ERROR,
} from "../../../actions/reservations/cabin/fetchAvailabilityCabin";

const initialState = {
  cabin: {},
  fetching: false,
  message: "",
  error: "",
};

const cabinAvailabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCH_AVAILABILITY_CABIN: {
      return {
        ...state,
        cabin: {},
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_AVAILABILITY_CABIN_SUCCESS: {
      return {
        ...state,
        cabin: action.cabin,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_AVAILABILITY_CABIN_ERROR: {
      return {
        ...state,
        cabin: {},
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default cabinAvailabilityReducer;
