import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customAgencyForm = createSlice({
  name: 'customAgencyForm',
  initialState: {
    countries: [],
    identificationTypes: [],
    agency: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateAgencyFormCustom(state, action) {
      return {
        countries: [],
        identificationTypes: [],
        agency: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesAgencyFormCustom(state, action) {
      return {
        countries: [],
        identificationTypes: [],
        agency: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchAgencyFormCustomSuccess(state, action) {
      return {
        countries: action.payload.countries,
        identificationTypes: action.payload.identificationTypes,
        agency: action.payload.agency,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchAgencyFormCustomError(state, action) {
      return {
        countries: [],
        identificationTypes: [],
        agency: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchAgencyFormCustom = (searchWord, agency_id) => dispatch => {
  dispatch(requesAgencyFormCustom());
  axios.get(`/agencies/form/agencyForm?agency_id=${agency_id}&&searchWord=${searchWord}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchAgencyFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchAgencyFormCustom(agency_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchAgencyFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateAgencyFormCustom,
  requesAgencyFormCustom,
  fetchAgencyFormCustomSuccess,
  fetchAgencyFormCustomError,
} = customAgencyForm.actions;

export default customAgencyForm.reducer;