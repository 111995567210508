import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../styles/Common";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchItinerary,
  initialStateItinerary,
} from "../../../redux/actions/catalogs/itinerary/fetchItinerary";
import { fetchGenders } from "../../../redux/actions/catalogs/gender/fetchGenders";
import { createAvailabilityB2b } from "../../../redux/actions/b2b/availability/createAvailabilityB2b";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function AvailabilityListHold() {
  let history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  let cruise = JSON.parse(sessionStorage.getItem("cruise"));
  let startDate = cruise.startDate.split("-");
  let endDate = cruise.endDate.split("-");
  let startDay = new Date(`${startDate[1]}/${startDate[0]}/${startDate[2]}`);
  let endDay = new Date(`${endDate[1]}/${endDate[0]}/${endDate[2]}`);
  let commission = parseInt(0);
  let child = parseInt(0);
  const user = JSON.parse(sessionStorage.getItem("userInfo"));
  const [spaces, setSpaces] = useState(
    JSON.parse(sessionStorage.getItem("spaces"))
  );
  const [passengerReference, setPassengerReference] = useState("");
  const itineraryState = useSelector((state) => state.itineraryReducer);
  const genderState = useSelector((state) => state.genderReducer);
  const availabilityB2b2State = useSelector(
    (state) => state.availabilityReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGenders());
    if (cruise.itineraryId) {
      dispatch(fetchItinerary(cruise.itineraryId));
    }
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }

    return () => {
      dispatch(initialStateItinerary());
    };
  }, [dispatch, cruise.itineraryId]);

  if (itineraryState.fetching) {
    return <CircularProgress />;
  }

  if (itineraryState.error) {
    return <div>Error: {itineraryState.error}</div>;
  }

  if (availabilityB2b2State.message === "resource created successfully") {
    history.go(-1);
    sessionStorage.removeItem("spaces");
  }

  const handleCancel = () => {
    history.go(-1);
    sessionStorage.removeItem("spaces");
  };

  const handleEditSpace = (indexSpace, value) => {
    spaces[indexSpace].gender = value;
    sessionStorage.setItem("spaces", JSON.stringify(spaces));
  };

  const handleSubmit = () => {
    let finalSpaces = spaces.filter((e) => !e.unionCabins);

    dispatch(
      createAvailabilityB2b({
        cruise: {
          ...cruise,
          notes: passengerReference,
          agencyId: "rREK2Gy2zl",
          rateId: "bEB9ZLKPgW",
          userId: "bEB9ZLKPgW",
          spaces: finalSpaces,
        },
      })
    );
  };

  return (
    <TableContainer component={Paper}>
      <Toolbar>
        <div style={{ display: "flex" }}>
          <Typography
            className={classes.title}
            variant="h6"
            noWrap
            style={{ padding: "20px 0 10px  0" }}
          >
            {cruise.yacht}
            <div style={{ fontWeight: "normal", fontSize: "12px" }}>
              {t("itinerary_from") +
                " " +
                cruise.startDate.split("-").join("/")}
            </div>
            <div style={{ fontWeight: "normal", fontSize: "12px" }}>
              {t("itinerary_to") + " " + cruise.endDate.split("-").join("/")}
            </div>
          </Typography>
          <div
            style={{
              paddingTop: "20px",
              paddingLeft: "35px",
              fontSize: "14px",
              fontWeight: "bold",
              color: "#2C8ABD",
            }}
          >
            {cruise.days}D - {cruise.nights}N /{" "}
            {t(
              startDay.toLocaleString("es-CO", { weekday: "long" })
            ).toUpperCase()}{" "}
            -{" "}
            {t(
              endDay.toLocaleString("es-CO", { weekday: "long" })
            ).toUpperCase()}
          </div>
        </div>
        <Typography className={classes.title} variant="h6">
          <div
            style={{
              paddingLeft: "600px",
            }}
          >
            {user.userName}
          </div>
          <div
            style={{
              paddingLeft: "600px",
              fontSize: "12px",
              fontWeight: "normal",
            }}
          >
            {user.agencyName}
          </div>
        </Typography>
      </Toolbar>
      <Divider variant="middle" />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">{t("cabin")}</TableCell>
            <TableCell>{t("deck_bridge")}</TableCell>
            <TableCell align="center">{t("passenger_hold")}</TableCell>
            <TableCell>{t("price")}</TableCell>
            <TableCell align="center">{t("commission_discount")}</TableCell>
            <TableCell align="center">{t("child_discount")}</TableCell>
            <TableCell align="center">{t("double_bed")}</TableCell>
            <TableCell align="center">{t("single_bed")}</TableCell>
            <TableCell>{t("subtotal")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {spaces.map((space, index) => (
            <TableRow key={index}>
              {space.unionCabins === true ? (
                <>
                  <TableCell
                    colSpan="3"
                    align="center"
                    style={{ fontWeight: "bold", fontSize: "13px" }}
                  >
                    {" "}
                    Family Room {t("between")}{" "}
                    <span style={{ color: "#2C8ABD" }}>
                      {" "}
                      Cabin {space.cabinOne}
                    </span>{" "}
                    {t("and")}{" "}
                    <span style={{ color: "#2C8ABD" }}>
                      {" "}
                      Cabin
                      {space.cabinTwo}
                    </span>
                  </TableCell>

                  <TableCell>$ 2759</TableCell>
                  <TableCell align="center">$ 2118</TableCell>
                  <TableCell align="center">$ 0</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell>$ 4068</TableCell>
                  <TableCell align="center"></TableCell>
                </>
              ) : (
                <>
                  <TableCell align="center">{space.cabin}</TableCell>
                  <TableCell align="center">{space.deck}</TableCell>
                  <TableCell align="center">
                    <select
                      aria-expanded="true"
                      name="gender"
                      className="ui selection dropdown"
                      onChange={(e) => handleEditSpace(index, e.target.value)}
                      value={space.gender}
                    >
                      {genderState.genders.map((gender) => (
                        <option key={gender.id} value={gender.id}>
                          {gender.name}
                        </option>
                      ))}
                    </select>
                  </TableCell>
                  <TableCell>
                    {space.active !== true ? `$ ${space.price}` : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {space.active !== true ? `$ ${commission}` : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {space.active !== true ? `$ ${child}` : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {space.active !== true
                      ? space.matrimonial === true
                        ? "SI"
                        : "NO"
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {space.active !== true
                      ? space.single === true
                        ? "SI"
                        : "NO"
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {space.active !== true
                      ? `$ ${space.price - commission - child}`
                      : "-"}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div
        style={{
          display: "flex",
          width: "100%",
          paddingTop: "30px",
          paddingBottom: "30px",
        }}
      >
        <div style={{ padding: "0 20px 0 20px", width: "70%" }}>
          <div className="ui form">
            <label style={{ fontWeight: "bold" }}>
              {t("passenger_reference")} *
            </label>
            <textarea
              rows={3}
              autoFocus
              name="passengerReference"
              onChange={(e) => setPassengerReference(e.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "30%",
            padding: "0 30px 0 30px",
            color: "#2C8ABD",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          TOTAL: <span style={{ paddingLeft: "80px" }}>$ 8890</span>
        </div>
      </div>
      <div style={{ padding: "20px" }}>
        <Button onClick={() => handleCancel()}>{t("canceled_button")}</Button>
        <button
          onClick={() => handleSubmit()}
          className={
            availabilityB2b2State.processing
              ? "ui loading primary button"
              : "ui primary button"
          }
        >
          {t("hold")}
        </button>
      </div>
    </TableContainer>
  );
}
