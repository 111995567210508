import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customCruiseLanguageForm = createSlice({
  name: 'customCruiseLanguageForm',
  initialState: {
    cruise: {},
    languages: [],
    cruiseLanguage: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateCruiseLanguageFormCustom(state, action) {
      return {
        cruise: {},
        languages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesCruiseLanguageFormCustom(state, action) {
      return {
        cruise: {},
        languages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchCruiseLanguageFormCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        languages: action.payload.languages,
        cruiseLanguage: action.payload.cruiseLanguage,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchCruiseLanguageFormCustomError(state, action) {
      return {
        cruise: {},
        languages: [],
        cruiseLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchCruiseLanguageFormCustom = (cruise_id, id) => dispatch => {
  dispatch(requesCruiseLanguageFormCustom());
  axios.get(`/cruise/${cruise_id}/customCruiseLanguageForm?id=${id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchCruiseLanguageFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCruiseLanguageFormCustomSuccess(json.data.data));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCruiseLanguageFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateCruiseLanguageFormCustom,
  requesCruiseLanguageFormCustom,
  fetchCruiseLanguageFormCustomSuccess,
  fetchCruiseLanguageFormCustomError,
} = customCruiseLanguageForm.actions;

export default customCruiseLanguageForm.reducer;