import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FamilyRoomService from "../../../../services/rates/FamilyRoomService";

export const REQUEST_FETCH_FAMILY_ROOMS = "REQUEST_FETCH_FAMILY_ROOMS";
export const FETCH_FAMILY_ROOMS_SUCCESS = "FETCH_FAMILY_ROOMS_SUCCESS";
export const FETCH_FAMILY_ROOMS_ERROR = "FETCH_FAMILY_ROOMS_ERROR";

function requestFetchFamilyRooms() {
  return {
    type: REQUEST_FETCH_FAMILY_ROOMS,
  };
}

function fetchFamilyRoomsSuccess(json) {
  return {
    type: FETCH_FAMILY_ROOMS_SUCCESS,
    familyRooms: json.data,
  };
}

function fetchFamilyRoomsError(json) {
  return {
    type: FETCH_FAMILY_ROOMS_ERROR,
    error: json.data,
  };
}

export function fetchFamilyRooms(payload) {
  return (dispatch) => {
    dispatch(requestFetchFamilyRooms());
    return FamilyRoomService.getFamilyRooms(payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchFamilyRoomsSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchFamilyRooms(payload));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchFamilyRoomsError(json.data));
        }
      }
    );
  };
}
