import {
  INITIAL_STATE_PASSENGER_BY_SPACE,
  REQUEST_FETCH_PASSENGER_BY_SPACE,
  FETCH_PASSENGER_BY_SPACE_SUCCESS,
  FETCH_PASSENGER_BY_SPACE_ERROR,
} from "../../../actions/reservations/spacePassenger/fetchPassengerBySpace";

import {
  REQUEST_CREATE_PASSENGER_BY_SPACE,
  CREATE_PASSENGER_BY_SPACE_SUCCESS,
  CREATE_PASSENGER_BY_SPACE_ERROR,
} from "../../../actions/reservations/spacePassenger/createPassengerBySpace";

import {
  REQUEST_UPDATE_PASSENGER_BY_SPACE,
  UPDATE_PASSENGER_BY_SPACE_SUCCESS,
  UPDATE_PASSENGER_BY_SPACE_ERROR,
} from "../../../actions/reservations/spacePassenger/updatePassengerBySpace";

const initialState = {
  passengerBySpace: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const passengerBySpaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_PASSENGER_BY_SPACE: {
      return {
        ...state,
        passengerBySpace: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_PASSENGER_BY_SPACE: {
      return {
        ...state,
        passengerBySpace: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_PASSENGER_BY_SPACE_SUCCESS: {
      return {
        ...state,
        passengerBySpace: action.passengerBySpace,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_PASSENGER_BY_SPACE_ERROR: {
      return {
        ...state,
        passengerBySpace: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    case REQUEST_CREATE_PASSENGER_BY_SPACE: {
      return {
        ...state,
        passengerBySpace: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_PASSENGER_BY_SPACE_SUCCESS: {
      return {
        ...state,
        passengerBySpace: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_PASSENGER_BY_SPACE_ERROR: {
      return {
        ...state,
        passengerBySpace: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_PASSENGER_BY_SPACE: {
      return {
        ...state,
        passengerBySpace: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_PASSENGER_BY_SPACE_SUCCESS: {
      return {
        ...state,
        passengerBySpace: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_PASSENGER_BY_SPACE_ERROR: {
      return {
        ...state,
        passengerBySpace: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default passengerBySpaceReducer;
