import { CodeSharp } from "@material-ui/icons";
import ItineraryService from "../../../../services/catalogs/ItineraryService";
import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";

export const REQUEST_UPDATE_IMAGE = "REQUEST_UPDATE_IMAGE";
export const UPDATE_IMAGE_SUCCESS = "UPDATE_IMAGE_SUCCESS";
export const UPDATE_IMAGE_ERROR = "UPDATE_IMAGE_ERROR";

function requestUpdateImage() {
  return {
    type: REQUEST_UPDATE_IMAGE,
  };
}

function updateImageSuccess(json) {
  return {
    type: UPDATE_IMAGE_SUCCESS,
    message: json.data,
  };
}

function updateImageError(json) {
  return {
    type: UPDATE_IMAGE_ERROR,
    error: json.data,
  };
}

export function updateImage(itinerary_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateImage());
    return ItineraryService.updateImage(itinerary_id, payload).then((json) => {
      if (json.data.code === 200) {
        console.log("json.data", json.data);
        dispatch(updateImageSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateImage(itinerary_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateImageError(json.data));
      }
    });
  };
}
