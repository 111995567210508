import React, { useEffect, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../styles/Common";
import "../../styles/common.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCruises,
  initialStateCruises,
} from "../../../redux/actions/b2b/availability/fetchCruises";
import { fetchYachts } from "../../../redux/actions/catalogs/yacht/fetchYachts";
import { fetchGenders } from "../../../redux/actions/catalogs/gender/fetchGenders";
import { fetchItineraries } from "../../../redux/actions/catalogs/itinerary/fetchItineraries";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchYears } from "../../../redux/actions/reservations/cruise/fetchYears";
import { Button, Card, Image, Modal, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { Container } from "@material-ui/core";
import { months } from "../../resources/optionsList";
import ModalImagesByCabin from "../../../Modals/ModalImagesByCabin";

export default function AvailabilityList() {
  let itineraryDays = [];
  let history = useHistory();
  let actualYear = new Date().getFullYear();
  let years = [actualYear];
  let actualMonth = new Date().getMonth() + 1;
  let monthsCruise = [{ name: "december", value: actualMonth }];

  const { t } = useTranslation();
  const [year, setYear] = useState(actualYear.toString());
  const [month, setMonth] = useState();
  const [yacht, setYacht] = useState("all");
  const [day, setDays] = useState("all");
  const [defaultGenderId, setDefaultGenderId] = useState("");
  const [matrimonial, setMatrimonial] = useState(false);
  const [single, setSingle] = useState(true);
  const [cabins, setCabin] = useState([]);
  const [finalSelectedSpaces, setFinalSelectedSpaces] = useState();
  const [borderSpaces, setBorderSpaces] = useState([]);
  const classes = useStyles();
  const cruiseAvailabilityState = useSelector(
    (state) => state.availabilityReducer
  );
  const cruiseState = useSelector((state) => state.cruiseReducer);
  const genderState = useSelector((state) => state.genderReducer);
  const yachtState = useSelector((state) => state.yachtsB2BReducer);
  const itineraryState = useSelector((state) => state.itineraryReducer);
  const user = JSON.parse(sessionStorage.getItem("userInfo"));
  let singleSpaceId = [];
  var spaces = [];
  let spacesSelectedTemporary = [];
  let spacesId = [];
  let spacesSelectedId = [];
  let familyRoomIds = [];
  var cabinsFamilyRoom = [];
  let unselectedSpaces = [];
  let selectedFamilyRoomsIds = [];
  let filters = [];
  let cabinsWithFamilyRoom = [];
  let cabinsWithFamilyRoomConfirmed = [];
  let cabinsWithoutFamilyRoom = [];
  let spacesBorderSelected = [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchYachts());
    dispatch(fetchGenders());
    dispatch(fetchItineraries());

    if (actualYear) {
      dispatch(fetchYears(actualYear));
    }

    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateCruises());
    };
  }, [dispatch, actualYear]);

  useEffect(() => {
    dispatch(fetchCruises(month, year, yacht, day));
  }, [dispatch]);

  if (
    cruiseState.fetching ||
    yachtState.fetching ||
    itineraryState.fetching ||
    genderState.fetching
  ) {
    return <CircularProgress />;
  }

  if (cruiseState.processing) {
    return <CircularProgress />;
  }

  if (cruiseState.error) {
    return <div>Error: {cruiseState.error}</div>;
  }

  if (yachtState.error) {
    return <div>Error: {yachtState.error}</div>;
  }
  if (itineraryState.error) {
    return <div>Error: {itineraryState.error}</div>;
  }
  if (genderState.error) {
    return <div>Error: {itineraryState.error}</div>;
  }
  if (genderState.genders[0] && defaultGenderId === "") {
    setDefaultGenderId(genderState.genders[0].id);
  }

  if (month === undefined) {
    actualMonth < 9 ? setMonth(`0${actualMonth}`) : setMonth(actualMonth);
  }

  itineraryState.itineraries.filter((itinerary) => {
    itineraryDays.push(itinerary.number_days);
    return (itineraryDays = [...new Set(itineraryDays)]);
  });

  const handleSearchCruises = () => {
    // sessionStorage.clear();
    dispatch(fetchCruises(month, year, yacht, day));
  };

  if (cruiseState.years === []) {
    return years;
  } else {
    cruiseState.years.filter((year) => {
      if (year.year >= actualYear) {
        years.push(year.year);
        years.sort();
      }

      return (years = [...new Set(years)]);
    });
  }

  if (monthsCruise === []) {
    return monthsCruise;
  } else {
    months.filter((month) => {
      monthsCruise.push(month);
      monthsCruise.sort(function (a, b) {
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        return 0;
      });

      return (monthsCruise = Array.from(
        new Set(monthsCruise.map(JSON.stringify))
      ).map(JSON.parse));
    });
  }

  const handleSingleCabin = (
    id,
    partId,
    cabin,
    deck,
    cruiseIndex,
    cabinId,
    familyRoom
  ) => {
    const cb = document.getElementById(id);
    if (cb.checked === true) {
      if (spacesId.length > 0) {
        spacesId = [];
        spacesId.push(partId + 0, partId + 1);
        singleSpaceId.push(id);
        spacesSelectedId.map((spaceSelectedId) => {
          document.getElementById(spaceSelectedId).checked = false;
          spacesSelectedTemporary.map((space) => {
            spacesId.map((spaceId) =>
              spacesSelectedTemporary.splice(space.id === spaceId, 1)
            );
            return spacesSelectedTemporary;
          });
          return spacesSelectedId;
        });
        return spacesSelectedTemporary.push({
          id: id,
          cruiseIndex: cruiseIndex,
          cabin: cabin,
          deck: deck,
          price: 3250,
          gender: defaultGenderId,
          single: true,
          matrimonial: matrimonial,
          cabinId: cabinId,
          familyRoom: familyRoom,
          state: "reserved",
          active: false,
        });
      } else {
        spacesId.push(partId + 0, partId + 1);
        singleSpaceId.push(id);

        return spacesSelectedTemporary.push({
          id: id,
          cruiseIndex: cruiseIndex,
          cabin: cabin,
          deck: deck,
          price: 3250,
          gender: defaultGenderId,
          single: true,
          matrimonial: matrimonial,
          cabinId: cabinId,
          familyRoom: familyRoom,
          state: "reserved",
          active: false,
        });
      }
    } else {
      spacesSelectedTemporary.splice(0, 1);
      spacesId = [];
    }
  };

  const handleSelectedSpace = (
    id,
    space,
    cabin,
    deck,
    indexSpace,
    cruiseIndex,
    spaceIdSingle,
    cabinId,
    familyRoom
  ) => {
    const cb = document.getElementById(id);

    if (cb.checked === true) {
      if (spacesId.length >= 2) {
        spacesId = [];
        spacesId.push(space);
        document.getElementById(spaceIdSingle).checked = false;

        spacesSelectedTemporary.map(() => {
          spacesSelectedTemporary.splice(0, 1);

          return spacesSelectedTemporary;
        });
        spacesSelectedId.push(id);
        return spacesSelectedTemporary.push({
          id: id,
          cruiseIndex: cruiseIndex,
          cabin: cabin,
          deck: deck,
          price: 3250,
          gender: defaultGenderId,
          single: false,
          matrimonial: matrimonial,
          cabinId: cabinId,
          familyRoom: familyRoom,
          state: "reserved",
          active: false,
        });
      } else if (spacesId.length >= 1 && spacesId.length < 2) {
        spacesId.push(space);
        spacesSelectedId.push(id);
        return spacesSelectedTemporary.push({
          id: id,
          cruiseIndex: cruiseIndex,
          cabin: cabin,
          deck: deck,
          price: 3250,
          gender: defaultGenderId,
          single: false,
          matrimonial: matrimonial,
          cabinId: cabinId,
          familyRoom: familyRoom,
          state: "reserved",
          active: false,
        });
      } else {
        spacesId.push(space);
        spacesSelectedId.push(id);
        return spacesSelectedTemporary.push({
          id: id,
          cruiseIndex: cruiseIndex,
          cabin: cabin,
          deck: deck,
          price: 3250,
          gender: defaultGenderId,
          single: false,
          matrimonial: matrimonial,
          cabinId: cabinId,
          familyRoom: familyRoom,
          state: "reserved",
          active: false,
        });
      }
    } else {
      spacesSelectedTemporary.splice(indexSpace, 1);
      spacesId.splice(indexSpace, 1);
    }
  };

  const handleGender = (id, gender) => {
    spacesSelectedTemporary.map((a) => {
      if (a.id === id) {
        a.gender = gender;
      }
      return spacesSelectedTemporary;
    });
  };

  function handleMatrimonial() {
    const cb = document.getElementById("double");
    const single = document.getElementById("single");

    if (cb.checked === true) {
      single.checked = false;
      spacesSelectedTemporary.map((a) => {
        a.matrimonial = true;
        return spacesSelectedTemporary;
      });
    }
  }

  function handleSingle() {
    const cb = document.getElementById("double");
    const single = document.getElementById("single");

    if (single.checked === true) {
      cb.checked = false;
      spacesSelectedTemporary.map((a) => {
        a.matrimonial = false;
        return spacesSelectedTemporary;
      });
    }
  }

  const handleOk = () => {
    spacesId.map((spaceId) => {
      document.getElementById(spaceId).style.border = "2px solid #000";
      document.getElementById(spaceId).style.fontSize = "11.5px";

      return 1;
    });
    spaces = spaces.concat(spacesSelectedTemporary);
    spacesSelectedTemporary = [];
    singleSpaceId = [];
    setSingle(true);
    setMatrimonial(false);
    unselectedSpaces = [];
    spacesBorderSelected.push(...spacesId);
    spacesId = [];
  };

  const handleCancel = (cabin, cabinFamilyRoom) => {
    spacesId = [];
    singleSpaceId = [];
    unselectedSpaces = [];
    setSingle(true);
    setMatrimonial(false);
    spacesSelectedTemporary = [];

    //Delete array from familyRoomIds
    let indexOne = familyRoomIds.findIndex(
      (e) => e.cabinFamilyRoom === cabinFamilyRoom
    );

    familyRoomIds.splice(indexOne, 1);

    //Delete array from cabinsFamilyRoom
    let indexTwo = cabinsFamilyRoom.findIndex(
      (e) => e.cabinOne || e.cabinTwo === cabin
    );

    return cabinsFamilyRoom.splice(indexTwo, 1);
  };

  const handleHold = (
    cruiseId,
    yacht,
    startDate,
    endDtate,
    itineraryId,
    days,
    nights,
    cruiseIndex,
    cruiseType
  ) => {
    setBorderSpaces([...spacesBorderSelected]);
    spaces = spaces.filter((space) => space.cruiseIndex === cruiseIndex);
    setFinalSelectedSpaces(spaces);

    sessionStorage.setItem(
      "cruise",
      JSON.stringify({
        cruiseId: cruiseId,
        cruiseType: cruiseType,
        yacht: yacht,
        startDate: startDate,
        endDate: endDtate,
        itineraryId: itineraryId,
        days: days,
        nights: nights,
      })
    );
    setCabin(cabinsFamilyRoom);
  };

  const handleCharter = (
    cruiseId,
    yacht,
    startDate,
    endDtate,
    itineraryId,
    days,
    nights,
    cruiseType,
    availability
  ) => {
    sessionStorage.setItem(
      "cruise",
      JSON.stringify({
        cruiseId: cruiseId,
        yacht: yacht,
        startDate: startDate,
        endDate: endDtate,
        itineraryId: itineraryId,
        days: days,
        nights: nights,
        cruiseType: cruiseType,
        yacht_availability: availability,
      })
    );
    history.push("/b2b/availability/wholesaler/charter");
  };

  const handleClickSpace = (
    id,
    spaceOneState,
    spaceTwoState,
    cabin_family_room,
    cabinId,
    cabin
  ) => {
    const cb = document.getElementById(id);

    if (cb.checked === true) {
      if (spaceOneState === "available" && spaceTwoState === "available") {
        familyRoomIds.push({
          cabin: cabin,
          cabinFamilyRoom: cabin_family_room,
        });

        //Not repeat objects in array
        familyRoomIds = [
          ...new Set(familyRoomIds.map((s) => JSON.stringify(s))),
        ].map((s) => JSON.parse(s));

        if (familyRoomIds.length > 0) {
          familyRoomIds.forEach((family_room_id) => {
            if (family_room_id.cabinFamilyRoom === cabinId) {
              cabinsFamilyRoom.push({
                cabinOne: family_room_id.cabin,
                cabinTwo: cabin,
                cabinOneId: cabin_family_room,
                cabinTwoId: family_room_id.cabinFamilyRoom,
              });

              //Not repeat objects in array
              cabinsFamilyRoom = [
                ...new Set(cabinsFamilyRoom.map((s) => JSON.stringify(s))),
              ].map((s) => JSON.parse(s));

              return cabinsFamilyRoom;
            }
          });
        } else {
          return familyRoomIds;
        }
      } else {
        return (familyRoomIds = []);
      }
    } else {
      unselectedSpaces.push(id);
      if (unselectedSpaces.length === 2) {
        //Delete array from familyRoomIds
        let indexOne = familyRoomIds.findIndex(
          (e) => e.cabinFamilyRoom === cabin_family_room
        );

        familyRoomIds.splice(indexOne, 1);

        //Delete array from cabinsFamilyRoom
        let indexTwo = cabinsFamilyRoom.findIndex(
          (e) => e.cabinOne || e.cabinTwo === cabin
        );

        return cabinsFamilyRoom.splice(indexTwo, 1);
      }
    }
  };

  const handleFamilyRoom = (id, cabinIdOne, cabinIdTwo, cabinOne, cabinTwo) => {
    const cb = document.getElementById(id);
    if (cb.checked === true) {
      selectedFamilyRoomsIds.push(cabinIdOne, cabinIdTwo);
      filters.push({
        filterOne: cabinIdOne,
        filterTwo: cabinIdTwo,
        cabinOne: cabinOne,
        cabinTwo: cabinTwo,
      });
    } else {
      let indexOne = selectedFamilyRoomsIds.findIndex((e) => e === cabinIdOne);
      selectedFamilyRoomsIds.splice(indexOne, 1);

      let indexTwo = selectedFamilyRoomsIds.findIndex((e) => e === cabinIdTwo);
      selectedFamilyRoomsIds.splice(indexTwo, 1);

      let index = filters.findIndex((e) => e.filterOne === cabinIdOne);
      filters.splice(index, 1);
    }
  };

  function buildNewArray(arr, filOne, filTwo, cabinOne, cabinTwo) {
    cabinsWithFamilyRoom = [];
    arr.filter((e) =>
      e.cabinId === filOne
        ? cabinsWithFamilyRoom.push(e)
        : e.cabinId === filTwo
        ? cabinsWithFamilyRoom.push(e)
        : ""
    );
    cabinsWithFamilyRoomConfirmed.push(...cabinsWithFamilyRoom, {
      cabinOne: cabinOne,
      cabinTwo: cabinTwo,
      unionCabins: true,
    });
    return cabinsWithFamilyRoomConfirmed;
  }

  const handleConfirm = () => {
    if (selectedFamilyRoomsIds.length > 0) {
      finalSelectedSpaces.map((finalSelectedSpace) =>
        selectedFamilyRoomsIds.map((selectedFamilyRoomsId) => {
          if (finalSelectedSpace.cabinId === selectedFamilyRoomsId) {
            finalSelectedSpace.active = true;
          }
          return finalSelectedSpaces;
        })
      );
    }

    if (filters.length > 0) {
      filters.map((el) =>
        buildNewArray(
          finalSelectedSpaces,
          el.filterOne,
          el.filterTwo,
          el.cabinOne,
          el.cabinTwo
        )
      );
      cabinsWithoutFamilyRoom = finalSelectedSpaces.filter(
        (e) => e.active === false
      );

      cabinsWithFamilyRoomConfirmed = cabinsWithFamilyRoomConfirmed.concat(
        cabinsWithoutFamilyRoom
      );

      sessionStorage.setItem(
        "spaces",
        JSON.stringify(cabinsWithFamilyRoomConfirmed)
      );
    } else {
      sessionStorage.setItem("spaces", JSON.stringify(finalSelectedSpaces));
    }

    history.push("/b2b/availability/wholesaler/hold");
  };

  const handleCanceledSpaces = () => {
    borderSpaces.map((e) => {
      document.getElementById(e).style.border = "none";
      return 1;
    });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6">
            {t("b2b_availability_title")} {cruiseState.message}{" "}
          </Typography>
          <Typography className={classes.title} variant="h6">
            <div
              style={{
                paddingLeft: "870px",
              }}
            >
              {user.userName}
            </div>
            <div
              style={{
                paddingLeft: "870px",
                fontSize: "12px",
                fontWeight: "normal",
              }}
            >
              {user.agencyName}
            </div>
          </Typography>
        </Toolbar>
        <br />
        <br />
        <Toolbar style={{ paddingBottom: "15px" }}>
          <div style={{ paddingRight: "5px" }}>
            <label className="labels_ticket">
              {t("b2b_availability_yacht")} *
            </label>
            <br />
            <select
              className="ui selection dropdown"
              aria-expanded="true"
              name="yacht"
              defaultValue={yacht}
              onChange={(event) => {
                setYacht(event.target.value);
              }}
            >
              <option className="selected item" value="all">
                {t("b2b_availability_all")}
              </option>
              {yachtState.yachts.map((yacht) => (
                <option
                  className="selected item"
                  key={yacht.id}
                  value={yacht.id}
                >
                  {yacht.name}
                </option>
              ))}
            </select>
          </div>

          <div style={{ paddingRight: "5px" }}>
            <label className="labels_ticket">
              {t("b2b_availability_days")} *
            </label>
            <br />
            <select
              className="ui selection dropdown"
              aria-expanded="true"
              name="day"
              value={day}
              onChange={(event) => {
                setDays(event.target.value);
              }}
            >
              <option className="selected item" value="all">
                {t("b2b_availability_all")}
              </option>
              {itineraryDays.map((itinerary, index) => (
                <option className="selected item" key={index} value={itinerary}>
                  {itinerary} D / {itinerary - 1} N
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="labels_ticket">
              {t("b2b_availability_month")} *
            </label>
            <br />
            <div style={{ paddingRight: "5px" }} className="ui input">
              <select
                className="ui selection dropdown"
                aria-expanded="true"
                name="month"
                value={month}
                onChange={(event) => {
                  setMonth(event.target.value);
                }}
              >
                {months.map((month, index) => (
                  <option
                    className="selected item"
                    key={index}
                    value={month.value}
                  >
                    {t(month.name)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label className="labels_ticket">
              {t("b2b_availability_year")} *
            </label>
            <br />
            <div style={{ paddingRight: "5px" }} className="ui input">
              <select
                className="ui selection dropdown"
                aria-expanded="true"
                name="year"
                value={year}
                onChange={(event) => {
                  setYear(event.target.value);
                }}
              >
                {years.map((nextYear, index) => (
                  <option
                    className="selected item"
                    key={index}
                    value={nextYear}
                  >
                    {nextYear}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label>{t("search_button")} </label>
            <br />
            <Button
              onClick={() => {
                handleSearchCruises();
              }}
              icon="search"
              size="medium"
            ></Button>
          </div>

          <div style={{ paddingLeft: "220px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <br />
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#7DC31F",
                }}
              ></div>
              <span
                style={{
                  paddingInlineStart: "15px",
                  fontSize: "10px",
                }}
              >
                {t("b2b_availability_available")}
              </span>
            </div>
            <br />
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#FCD817",
                }}
              ></div>
              <span
                style={{
                  paddingInlineStart: "15px",
                  fontSize: "10px",
                }}
              >
                {t("b2b_availability_hold")}
              </span>
            </div>
            <br />
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#F01212",
                }}
              ></div>
              <span
                style={{
                  paddingInlineStart: "15px",
                  fontSize: "10px",
                }}
              >
                {t("b2b_availability_booked")}
              </span>
            </div>
          </div>
        </Toolbar>
      </TableContainer>

      <Container>
        {cruiseAvailabilityState.cruises.map((cruise, index) => (
          <div style={{ paddingBottom: "50px" }} key={index}>
            <Card.Group
              key={index}
              style={{
                width: "1500px",
                height: "500px",
                paddingTop: "20px",
                paddingBottom: "200px",
              }}
            >
              <Card style={{ width: "400px" }}>
                <Carousel
                  indicators={false}
                  autoPlay={false}
                  navButtonsAlwaysVisible={true}
                >
                  {cruise.images.map((item, i) => (
                    <Image
                      key={i}
                      src={item}
                      style={{ width: "400px", height: "400px" }}
                    />
                  ))}
                </Carousel>
                <Card.Content>
                  <Card.Header>
                    <div className="flex-container">
                      <div style={{ marginRight: "30px" }}>{cruise.yacht}</div>
                      <div style={{ marginRight: "30px", fontSize: "15px" }}>
                        {cruise.itinerary_days} {t("itinerary_days")} /{" "}
                        {cruise.itinerary_nights} {t("itinerary_nights")}
                      </div>
                    </div>
                  </Card.Header>
                </Card.Content>
                <Card.Description>
                  <div className="flex-container">
                    <div style={{ marginLeft: "12px" }}>
                      {t("itinerary_from")} {cruise.start_date}
                    </div>
                    <div style={{ marginLeft: "130px" }}>Tarifa</div>
                  </div>
                  <div style={{ marginLeft: "12px" }}>
                    {t("itinerary_to")} {cruise.end_date}
                  </div>
                  <div
                    style={{
                      marginLeft: "12px",
                      fontWeight: "500",
                      paddingTop: "5px",
                    }}
                  >
                    FR = Family Room
                  </div>
                </Card.Description>
              </Card>

              <Card style={{ width: "780px", padding: "15px" }}>
                <Card.Group itemsPerRow={5}>
                  {cruise.cabins.map((cabin, indexCabin) => (
                    <Card key={indexCabin} style={{ padding: "5px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          fontWeight: "500",
                          fontSize: "10px",
                        }}
                      >
                        {cabin.deck}
                      </div>
                      <Card.Header
                        textAlign="center"
                        style={{ fontWeight: "bold" }}
                      >
                        {t("cabin_cabin").toUpperCase()} {cabin.cabin}
                      </Card.Header>
                      {/* SPACES */}
                      <Card.Group
                        itemsPerRow={2}
                        style={{
                          display: "flex",
                          paddingTop: "5px",
                          justifyContent: "space-between",
                          marginLeft: "-5px",
                          marginRight: "-5px",
                        }}
                      >
                        {cabin.spaces.map((space, indexSpace) =>
                          space.state === "available" ? (
                            <Modal
                              key={indexSpace}
                              trigger={
                                <div
                                  key={indexSpace}
                                  className="ui card"
                                  id={
                                    index +
                                    cruise.code +
                                    cabin.cabin +
                                    indexSpace
                                  }
                                  style={{
                                    width: "42px",
                                    height: "45px",
                                    backgroundColor:
                                      space.state === "reserved"
                                        ? "#FCD817"
                                        : space.state === "confirmed"
                                        ? "#F01212"
                                        : "#7DC31F",
                                  }}
                                >
                                  {space.state === "available" ? (
                                    <div
                                      style={{
                                        boxSizing: "borderBox",
                                        paddingTop: "15px",
                                        paddingBottom: "15px",
                                        paddingLeft: "3px",
                                      }}
                                    >
                                      $3226
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        paddingTop: "15px",
                                        paddingLeft: "4px",
                                      }}
                                    >
                                      $3226
                                    </div>
                                  )}
                                </div>
                              }
                              header={
                                <div
                                  style={{ display: "flex", padding: "15px" }}
                                >
                                  <div
                                    style={{
                                      width: "33%",
                                      fontSize: "13px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {cruise.yacht}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "33%",
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {t("cabin_cabin").toUpperCase() +
                                      " " +
                                      cabin.cabin}
                                  </div>
                                  <div
                                    style={{
                                      width: "33%",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {cabin.deck}
                                    <br />
                                    <br />
                                    {cabin.cabin_family_room !== 0 && "FR"}
                                  </div>
                                </div>
                              }
                              content={
                                <div>
                                  {/* SECTION 1 */}
                                  <div
                                    style={{
                                      display: "flex",
                                      padding: "20px",
                                    }}
                                  >
                                    <div
                                      className="ui checkbox"
                                      style={{
                                        width: "50%",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        id={
                                          index +
                                          cruise.code +
                                          indexCabin +
                                          cabin.cabin +
                                          3
                                        }
                                        name="activeOne"
                                        disabled={
                                          cabin.spaces[0].state ===
                                          cabin.spaces[1].state
                                            ? false
                                            : true
                                        }
                                        defaultChecked={
                                          space.state !== "available"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          handleSingleCabin(
                                            index +
                                              cruise.code +
                                              indexCabin +
                                              cabin.cabin +
                                              3,
                                            index + cruise.code + cabin.cabin,
                                            cabin.cabin,
                                            cabin.deck,
                                            index,
                                            cabin.id,
                                            cabin.cabin_family_room
                                          );
                                        }}
                                      />
                                      <label
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "12px",
                                          paddingBottom: "3px",
                                          paddingLeft: "30px",
                                        }}
                                      >
                                        {t("single_cabin")}
                                      </label>
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                          paddingLeft: "30px",
                                        }}
                                      >
                                        $4500
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        width: "50%",
                                        display: "grid",
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "12px",
                                          paddingBottom: "3px",
                                        }}
                                      >
                                        {t("passenger_path")} *
                                      </div>

                                      <select
                                        className="ui selection dropdown"
                                        aria-expanded="true"
                                        name="gender_id_one"
                                        value={space.gender_id}
                                        disabled={
                                          cabin.spaces[0].state ===
                                          cabin.spaces[1].state
                                            ? false
                                            : true
                                        }
                                        onChange={(e) => {
                                          handleGender(
                                            index +
                                              cruise.code +
                                              indexCabin +
                                              cabin.cabin +
                                              3,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        {genderState.genders.map((gender) => (
                                          <option
                                            key={gender.id}
                                            value={gender.id}
                                          >
                                            {t(gender.name)}
                                          </option>
                                        ))}
                                      </select>
                                      <br />
                                    </div>
                                  </div>
                                  <br />
                                  <Divider />
                                  {/* SECTION 2 */}
                                  <div
                                    style={{
                                      padding: "0 20px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t("option_cabin")}
                                  </div>
                                  {cabin.spaces.map((space, indexSpace) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        padding: "20px",
                                      }}
                                      key={indexSpace}
                                    >
                                      <div
                                        className="ui checkbox"
                                        style={{
                                          width: "50%",
                                        }}
                                      >
                                        <input
                                          id={
                                            index +
                                            cruise.code +
                                            indexCabin +
                                            cabin.cabin +
                                            indexSpace
                                          }
                                          type="checkbox"
                                          name="activeTwo"
                                          disabled={
                                            space.state !== "available"
                                              ? true
                                              : false
                                          }
                                          defaultChecked={
                                            space.state !== "available"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            handleSelectedSpace(
                                              index +
                                                cruise.code +
                                                indexCabin +
                                                cabin.cabin +
                                                indexSpace,
                                              index +
                                                cruise.code +
                                                cabin.cabin +
                                                indexSpace,
                                              cabin.cabin,
                                              cabin.deck,
                                              indexSpace,
                                              index,
                                              index +
                                                cruise.code +
                                                indexCabin +
                                                cabin.cabin +
                                                3,
                                              cabin.id,
                                              cabin.cabin_family_room
                                            );
                                          }}
                                          onClick={() => {
                                            handleClickSpace(
                                              index +
                                                cruise.code +
                                                indexCabin +
                                                cabin.cabin +
                                                indexSpace,
                                              cabin.spaces[0].state,
                                              cabin.spaces[1].state,
                                              cabin.cabin_family_room,
                                              cabin.id,
                                              cabin.cabin
                                            );
                                          }}
                                        />
                                        <label
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                            paddingBottom: "3px",
                                            paddingLeft: "30px",
                                          }}
                                        >
                                          {t("space_path") + (indexSpace + 1)}
                                        </label>
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "16px",
                                            paddingLeft: "30px",
                                          }}
                                        >
                                          $3500
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          width: "50%",
                                          display: "grid",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                            paddingBottom: "3px",
                                          }}
                                        >
                                          {t("passenger_path")} *
                                        </div>
                                        <select
                                          className="ui selection dropdown"
                                          aria-expanded="true"
                                          name={
                                            index +
                                            cruise.code +
                                            indexCabin +
                                            cabin.cabin +
                                            indexSpace
                                          }
                                          disabled={
                                            space.state !== "available"
                                              ? true
                                              : false
                                          }
                                          value={space.gender_id}
                                          onChange={(e) => {
                                            handleGender(
                                              index +
                                                cruise.code +
                                                indexCabin +
                                                cabin.cabin +
                                                indexSpace,
                                              e.target.value
                                            );
                                          }}
                                        >
                                          {genderState.genders.map((gender) => (
                                            <option
                                              key={gender.id}
                                              value={gender.id}
                                            >
                                              {t(gender.name)}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  ))}
                                  <div
                                    style={{
                                      padding: "0 20px 0 20px",
                                      fontSize: "11px",
                                      textAlign: "justify",
                                    }}
                                  >
                                    {t("messageOne_cabin")}
                                  </div>
                                  <Divider />
                                  <br />
                                  {/* SECTION 3*/}
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "grid",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {cabin.union_beds === 0 ? (
                                      <div>{t(cabin.berth)}</div>
                                    ) : (
                                      <div>
                                        <div className="ui radio checkbox">
                                          <input
                                            type="radio"
                                            id="double"
                                            name="matrimonial"
                                            value="matrimonial"
                                            defaultChecked={
                                              matrimonial === true
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              handleMatrimonial();
                                            }}
                                          />
                                          <label>{t("double_bed")}</label>
                                        </div>
                                        <br />

                                        <div className="ui radio checkbox">
                                          <input
                                            type="radio"
                                            id="single"
                                            name="single"
                                            value="single"
                                            checked={
                                              single === true ? true : false
                                            }
                                            onChange={() => {
                                              handleSingle();
                                            }}
                                          />
                                          <label>{t("single_bed")}</label>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <br />
                                  <div
                                    style={{
                                      padding: "0 20px 0 20px",
                                      fontSize: "11px",
                                      textAlign: "justify",
                                    }}
                                  >
                                    {t("messageTwo_cabin")}
                                  </div>
                                  <br />
                                </div>
                              }
                              actions={[
                                {
                                  key: "Ok",
                                  content: "Ok",
                                  className: "ui primary button",
                                  onClick: () => handleOk(),
                                },

                                {
                                  key: "Cancelar",
                                  content: "Cancelar",
                                  className: "ui button",
                                  onClick: () =>
                                    handleCancel(
                                      cabin.cabin,
                                      cabin.cabin_family_room
                                    ),
                                },
                              ]}
                              size="tiny"
                            />
                          ) : (
                            <div
                              key={indexSpace}
                              className="ui card"
                              style={{
                                width: "42px",
                                height: "45px",
                                backgroundColor:
                                  space.state === "reserved"
                                    ? "#FCD817"
                                    : space.state === "confirmed"
                                    ? "#F01212"
                                    : "",
                              }}
                            >
                              <div
                                style={{
                                  boxSizing: "borderBox",
                                  paddingTop: "15px",
                                  paddingBottom: "15px",
                                  paddingLeft: "3px",
                                }}
                              >
                                $3226
                              </div>
                            </div>
                          )
                        )}
                      </Card.Group>
                      <div
                        style={{
                          paddingTop: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {cabin.cabin.cabin_family_room !== 0 && (
                          <div
                            style={{
                              color: "#2C8ABD",
                              fontWeight: "500",
                              paddingTop: "7px",
                            }}
                          >
                            FR
                          </div>
                        )}
                        <div
                          style={{
                            paddingTop: "7px",
                          }}
                        >
                          one full bed
                        </div>
                        <div>
                          <ModalImagesByCabin
                            cabin={t("cabin_cabin") + " " + cabin.cabin}
                            yacht={cruise.yacht}
                            images={cabin.images}
                          />
                        </div>
                      </div>
                    </Card>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      paddingTop: "20px",
                    }}
                  >
                    <Modal
                      trigger={
                        <Button
                          className=" ui primary button"
                          onClick={() =>
                            handleHold(
                              cruise.id,
                              cruise.yacht,
                              cruise.start_date,
                              cruise.end_date,
                              cruise.itinerary_id,
                              cruise.itinerary_days,
                              cruise.itinerary_nights,
                              index,
                              cruise.cruise_type
                            )
                          }
                        >
                          Hold
                        </Button>
                      }
                      header={t("b2b_availability_confirm_title")}
                      content={
                        cabins.length > 0
                          ? cabins.map((cabin, index) => (
                              <div key={index}>
                                <div>
                                  <span>
                                    {t("b2b_availability_familyRoom")}{" "}
                                    {t("b2b_availability_cabin")}{" "}
                                    {cabin.cabinOne} {t("and")}{" "}
                                    {t("b2b_availability_cabin")}{" "}
                                    {cabin.cabinTwo}
                                  </span>{" "}
                                  <span style={{ paddingLeft: "20px" }}>
                                    <label>SI </label>
                                    <div className="ui checkbox">
                                      <input
                                        id={cabin.cabinOneId + cabin.cabinTwoId}
                                        onChange={() =>
                                          handleFamilyRoom(
                                            cabin.cabinOneId + cabin.cabinTwoId,
                                            cabin.cabinOneId,
                                            cabin.cabinTwoId,
                                            cabin.cabinOne,
                                            cabin.cabinTwo
                                          )
                                        }
                                        type="checkbox"
                                      />
                                      <label></label>
                                    </div>
                                  </span>
                                </div>
                              </div>
                            ))
                          : t("b2b_availability_confirm")
                      }
                      actions={[
                        {
                          key: "canceled",
                          content: t("canceled_button"),
                          className: "ui button",
                          onClick: () => handleCanceledSpaces(),
                        },
                        {
                          key: "ok",
                          content: t("b2b_availability_confirm_button"),
                          className: "ui primary button",
                          onClick: () => handleConfirm(index),
                        },
                      ]}
                      size="tiny"
                    />

                    <Button
                      className="primary button"
                      onClick={() =>
                        handleCharter(
                          cruise.id,
                          cruise.yacht,
                          cruise.start_date,
                          cruise.end_date,
                          cruise.itinerary_id,
                          cruise.itinerary_days,
                          cruise.itinerary_nights,
                          cruise.cruise_type,
                          cruise.yacht_availability
                        )
                      }
                    >
                      Charter
                    </Button>
                  </div>
                </Card.Group>
              </Card>
            </Card.Group>
          </div>
        ))}
      </Container>
      <br />
    </>
  );
}
