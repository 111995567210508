import {
  REQUEST_FETCH_KINDS,
  FETCH_KINDS_SUCCESS,
  FETCH_KINDS_ERROR,
} from "../../../actions/catalogs/kind/fetchKinds";

import {
  INITIAL_STATE_KIND,
  REQUEST_FETCH_KIND,
  FETCH_KIND_SUCCESS,
  FETCH_KIND_ERROR,
} from "../../../actions/catalogs/kind/fetchKind";

import {
  REQUEST_CREATE_KIND,
  CREATE_KIND_SUCCESS,
  CREATE_KIND_ERROR,
} from "../../../actions/catalogs/kind/createKind";

import {
  REQUEST_UPDATE_KIND,
  UPDATE_KIND_SUCCESS,
  UPDATE_KIND_ERROR,
} from "../../../actions/catalogs/kind/updateKind";

import {
  REQUEST_DELETE_KIND,
  DELETE_KIND_SUCCESS,
  DELETE_KIND_ERROR,
} from "../../../actions/catalogs/kind/deleteKind";

const initialState = {
  kinds: [],
  kind: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const kindReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_KIND: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_KINDS: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_KINDS_SUCCESS: {
      return {
        ...state,
        kinds: action.kinds,
        kind: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_KINDS_ERROR: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_KIND: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_KIND_SUCCESS: {
      return {
        ...state,
        kinds: [],
        kind: action.kind,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_KIND_ERROR: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_KIND: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_KIND_SUCCESS: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_KIND_ERROR: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_KIND: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_KIND_SUCCESS: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_KIND_ERROR: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_KIND: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_KIND_SUCCESS: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_KIND_ERROR: {
      return {
        ...state,
        kinds: [],
        kind: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default kindReducer;
