import {
    REQUEST_FETCH_RESERVATION_LOGS,
    FETCH_RESERVATION_LOGS_SUCCESS,
    FETCH_RESERVATION_LOGS_ERROR,
  } from '../../../actions/reservations/reservation/fetchReservationLogs';
    
  const initialState = {
    logs: [],
    log: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  };
  
  const reservationLogReducer = (state = initialState, action) => {
    switch (action.type) {
      case REQUEST_FETCH_RESERVATION_LOGS: {
        return {
          ...state,
          logs: [],
          log: {},
          processing: false,
          fetching: true,
          message: "",
          error: "",
        };
      }
      case FETCH_RESERVATION_LOGS_SUCCESS: {
        return {
          ...state,
          logs: action.logs,
          log: {},
          processing: false,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case FETCH_RESERVATION_LOGS_ERROR: {
        return {
          ...state,
          logs: [],
          log: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
     
      default: {
        return state;
      }
    }
  };
  
  export default reservationLogReducer;
  