import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SpaceService from "../../../../services/reservations/SpaceService";

export const REQUEST_FETCH_SPACE = "REQUEST_FETCH_SPACE";
export const FETCH_SPACE_SUCCESS = "FETCH_SPACE_SUCCESS";
export const FETCH_SPACE_ERROR = "FETCH_SPACE_ERROR";
export const INITIAL_STATE_SPACE = "INITIAL_STATE_SPACE";

function requestFetchSpace() {
  return {
    type: REQUEST_FETCH_SPACE,
  };
}

function fetchSpaceSuccess(json) {
  return {
    type: FETCH_SPACE_SUCCESS,
    space: json.data,
  };
}

function fetchSpaceError(json) {
  return {
    type: FETCH_SPACE_ERROR,
    error: json.data,
  };
}

export function initialStateSpace() {
  return {
    type: INITIAL_STATE_SPACE,
  };
}

export function fetchSpace(space_id) {
  return (dispatch) => {
    dispatch(requestFetchSpace());
    return SpaceService.getSpace(space_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchSpaceSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSpace(space_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSpaceError(json.data));
      }
    });
  };
}
