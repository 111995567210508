import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//UI
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
//ACTION
import { fetchPassengerDetailsListCustom, initialStatePassengerDetailsListCustom} from "../../../../redux/slices/custom/reservations/cruise/passengerDetails/customPassengerDetailsList.slice";
//RESOURCE
import HeadDetail from "../../../../components/headDetail";
import { scrollToTop } from "../../../resources/utils";

export default function PassengerDetailList() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { cruise_id } = useParams();
  const passengerByCruiseStateCustom = useSelector(
    (state) => state.customPassengerDetailsList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPassengerDetailsListCustom(cruise_id));
   scrollToTop();
    return () => {
      dispatch(initialStatePassengerDetailsListCustom());
    };
  }, [dispatch, cruise_id]);

  if (passengerByCruiseStateCustom.fetching) {
    return <CircularProgress />;
  }

  if (passengerByCruiseStateCustom.error) {
    return <div>Error: {passengerByCruiseStateCustom.error}</div>;
  }

  if (passengerByCruiseStateCustom.error) {
    return <div>Error: {passengerByCruiseStateCustom.error}</div>;
  }

  return (
    <>
      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{passengerByCruiseStateCustom.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">{t("passenger_detail_title")}</div>
      </div>
      <br />
      <br />
      <br />
      <HeadDetail
        yacht_name={passengerByCruiseStateCustom.cruise.yacht_name}
        itinerary_name={passengerByCruiseStateCustom.cruise.itinerary_name}
        start_date={passengerByCruiseStateCustom.cruise.start_date_format}
        end_date={passengerByCruiseStateCustom.cruise.end_date_format}
      />
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("passenger_detail_title")} {passengerByCruiseStateCustom.message}
          </Typography>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {t("passenger_detail_cabin")}
              </TableCell>
              <TableCell>{t("passenger_detail_name")}</TableCell>
              <TableCell>{t("passenger_detail_identification")}</TableCell>
              <TableCell>{t("passenger_detail_country")}</TableCell>
              <TableCell align="center">{t("passenger_detail_type")}</TableCell>
              <TableCell align="center">
                {t("passenger_detail_level")}
              </TableCell>
              <TableCell align="center">
                {t("passenger_detail_gender")}
              </TableCell>
              <TableCell align="center">
                {t("passenger_detail_route")}
              </TableCell>
              <TableCell>{t("passenger_detail_agency")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passengerByCruiseStateCustom.passengers.map((passenger, index) => (
              <TableRow key={index}>
                <TableCell align="center">{passenger.cabin_name}</TableCell>
                <TableCell>
                  {passenger.last_name.toUpperCase()} /{" "}
                  {passenger.first_name.toUpperCase()}
                </TableCell>

                <TableCell>{t(passenger.identification)}</TableCell>
                <TableCell>{t(passenger.country)}</TableCell>
                <TableCell align="center">{t(passenger.cruise_type)}</TableCell>
                <TableCell align="center">{passenger.deck}</TableCell>
                <TableCell align="center">{t(passenger.gender)}</TableCell>
                <TableCell align="center">{passenger.routes}</TableCell>
                <TableCell>{passenger.agency}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
