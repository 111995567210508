import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customCruiseDetails = createSlice({
  name: 'customCruiseDetails',
  initialState: {
    cruise: [],
    reservations: [],
    cruiseLanguages: [],
    guidesOnBoard: [],
    cabins: [],
    itinerary: {},
    hotelier:{},
    captain: {},
    yacht: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateCruiseDetailsCustom(state, action) {
      return {
        cruise: [],
        reservations: [],
        cruiseLanguages: [],
        guidesOnBoard: [],
        cabins: [],
        itinerary: {},
        hotelier:{},
        captain: {},
        yacht: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesCruiseDetailsCustom(state, action) {
      return {
        cruise: [],
        cruiseLanguages: [],
        reservations: [],
        guidesOnBoard: [],
        cabins: [],
        itinerary: {},
        hotelier:{},
        captain: {},
        yacht: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchCruiseDetailsCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        cruiseLanguages: action.payload.cruiseLanguages,
        reservations: action.payload.reservations,
        guidesOnBoard: action.payload.guidesOnBoard,
        cabins: action.payload.informationCabinByCruise,
        itinerary: action.payload.itinerary,
        hotelier:action.payload.hotelier,
        captain: action.payload.captain,
        yacht: action.payload.yacht,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchCruiseDetailsCustomError(state, action) {
      return {
        cruise: [],
        reservations: [],
        cruiseLanguages: [],
        guidesOnBoard: [],
        cabins: [],
        itinerary: {},
        hotelier:{},
        captain: {},
        yacht: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchCruiseDetailsCustom = (cruise_id) => dispatch => {
  dispatch(requesCruiseDetailsCustom());
  axios.get(`/cruise/cruiseDetails/${cruise_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchCruiseDetailsCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCruiseDetailsCustom(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCruiseDetailsCustomError(json.data.data));
      }
    })
};

export const {
  initialStateCruiseDetailsCustom,
  requesCruiseDetailsCustom,
  fetchCruiseDetailsCustomSuccess,
  fetchCruiseDetailsCustomError,
} = customCruiseDetails.actions;

export default customCruiseDetails.reducer;