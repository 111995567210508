import IkarusRateList from "../../containers/rates/ikarusRate/IkarusRateList";
import IkarusRateForm from "../../containers/rates/ikarusRate/IkarusRateForm";
import IkarusRateCabinList from "../../containers/rates/ikarusRateCabin/IkarusRateCabinList";
import IkarusRateCabinForm from "../../containers/rates/ikarusRateCabin/IkarusRateCabinForm";

const routes = [
  {
    path: "/ikarusRates",
    component: IkarusRateList,
  },
  {
    path: "/ikarusRates/ikarusRates_new",
    component: IkarusRateForm,
  },
  {
    path: "/ikarusRates/:ikarusRate_id",
    component: IkarusRateForm,
  },
  {
    path: "/ikarusRates/:ikarusRate_id/cabins",
    component: IkarusRateCabinList,
  },
  {
    path: "/ikarusRates/:ikarusRate_id/cabins_new",
    component: IkarusRateCabinForm,
  },
];

export { routes as ikarusRateRoutes };
