import {
  REQUEST_FETCH_HOTELIERS,
  FETCH_HOTELIERS_SUCCESS,
  FETCH_HOTELIERS_ERROR,
} from "../../../actions/catalogs/hotelier/fetchHoteliers";

import {
  INITIAL_STATE_HOTELIER,
  REQUEST_FETCH_HOTELIER,
  FETCH_HOTELIER_SUCCESS,
  FETCH_HOTELIER_ERROR,
} from "../../../actions/catalogs/hotelier/fetchHotelier";

import {
  REQUEST_CREATE_HOTELIER,
  CREATE_HOTELIER_SUCCESS,
  CREATE_HOTELIER_ERROR,
} from "../../../actions/catalogs/hotelier/createHotelier";

import {
  REQUEST_UPDATE_HOTELIER,
  UPDATE_HOTELIER_SUCCESS,
  UPDATE_HOTELIER_ERROR,
} from "../../../actions/catalogs/hotelier/updateHotelier";

import {
  REQUEST_DELETE_HOTELIER,
  DELETE_HOTELIER_SUCCESS,
  DELETE_HOTELIER_ERROR,
} from "../../../actions/catalogs/hotelier/deleteHotelier";

const initialState = {
  hoteliers: [],
  hotelier: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const hotelierReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_HOTELIER: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_HOTELIERS: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_HOTELIERS_SUCCESS: {
      return {
        ...state,
        hoteliers: action.hoteliers,
        hotelier: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_HOTELIERS_ERROR: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_HOTELIER: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_HOTELIER_SUCCESS: {
      return {
        ...state,
        hoteliers: [],
        hotelier: action.hotelier,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_HOTELIER_ERROR: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_HOTELIER: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_HOTELIER_SUCCESS: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_HOTELIER_ERROR: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_HOTELIER: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_HOTELIER_SUCCESS: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_HOTELIER_ERROR: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_HOTELIER: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_HOTELIER_SUCCESS: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_HOTELIER_ERROR: {
      return {
        ...state,
        hoteliers: [],
        hotelier: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default hotelierReducer;
