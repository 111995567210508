import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ContractRateService from "../../../../services/rates/ContractRateService";

export const REQUEST_FIND_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE = "REQUEST_FIND_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE";
export const FIND_CONTRACT_RATE__BY_CRUISE_AGENCY_CRUISETYPE_SUCCESS = "FIND_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE_SUCCESS";
export const FIND_CONTRACT_RATE__BY_CRUISE_AGENCY_CRUISETYPE_ERROR = "FIND_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE_ERROR";
export const INITIAL_STATE_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE = "INITIAL_STATE_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE";

function requestFindContractRateByCruiseAgencyContractType() {
  return {
    type: REQUEST_FIND_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE,
  };
}

function findContractRateByCruiseAgencyContractTypeSuccess(json) {
  return {
    type: FIND_CONTRACT_RATE__BY_CRUISE_AGENCY_CRUISETYPE_SUCCESS,
    contract: json.data,
  };
}

function findContractRateByCruiseAgencyContractTypeError(json) {
  return {
    type: FIND_CONTRACT_RATE__BY_CRUISE_AGENCY_CRUISETYPE_ERROR,
    error: json.data,
  };
}

export function initialStateContractRateByCruiseAgencyContractType() {
  return {
    type: INITIAL_STATE_CONTRACT_RATE_BY_CRUISE_AGENCY_CRUISETYPE,
  };
}

export function findContractRateByCruiseAgencyContractType(cruise_id, payload) {
  return (dispatch) => {
    dispatch(requestFindContractRateByCruiseAgencyContractType());
    return ContractRateService.findContractByCruiseAgencyCruiseType(cruise_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(findContractRateByCruiseAgencyContractTypeSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(findContractRateByCruiseAgencyContractType(cruise_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(findContractRateByCruiseAgencyContractTypeError(json.data));
      }
    });
  };
}
