import CharterRateList from "../../containers/rates/charterRate/CharterRateList";
import CharterRateForm from "../../containers/rates/charterRate/CharterRateForm";

const routes = [
  {
    path: "/charterRates",
    component: CharterRateList,
  },
  {
    path: "/charterRates_new",
    component: CharterRateForm,
  },
  {
    path: "/charterRates/:charterRate_id",
    component: CharterRateForm,
  },
];

export { routes as charterRateRoutes };
