import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createRoute } from "../../../redux/actions/catalogs/route/createRoute";
import { fetchRoute, initialStateRoute } from "../../../redux/actions/catalogs/route/fetchRoute";
import { updateRoute } from "../../../redux/actions/catalogs/route/updateRoute";

// Resources
import { scrollToTop } from "../../resources/utils";
import { cityFlight, typeRoutes } from "../../resources/optionsList";

export default function RouteForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { route_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const routeState = useSelector((state) => state.routeReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (route_id) {
      dispatch(fetchRoute(route_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateRoute());
    };
  }, [dispatch, route_id]);

  if (routeState.fetching) {
    return <Loader active inline />;
  }

  if (routeState.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (route_id) {
      dispatch(updateRoute(route_id, data));
    } else {
      dispatch(createRoute(data));
    }
  };

  if (routeState.message === "resource created successfully" || routeState.message === "resource updated successfully") {
    history.push("/routes");
  }

  return (
    <>
      <Header as='h3' dividing>{t("route_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("flight_origin_city")}</label>
            <select name="origin_city" defaultValue={routeState.route.origin_city} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {cityFlight.map((city, index) => (
                <option key={index} value={city}>{city}</option>
              ))}
            </select>
            {errors.origin_city && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("flight_destination_city")}</label>
            <select name="destination_city" defaultValue={routeState.route.destination_city} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {cityFlight.map((city, index) => (
                <option key={index} value={city}>{city}</option>
              ))}
            </select>
            {errors.destination_city && (<span className="error_message">{t("required_information")}</span>
            )}
          </Form.Field>
          <Form.Field required>
            <label>{t("type")}</label>
            <select name="type" defaultValue={routeState.route.type} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {typeRoutes.map((type, index) => (
                <option key={index} value={type}>{t(type)}</option>
              ))}
            </select>
            {errors.type && (<span className="error_message">{t("required_information")}</span>
            )}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("active_tag")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" className="ui checkbox" defaultChecked={routeState.route.active === 1} ref={register} />
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={routeState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/routes"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
