import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createNote } from "../../../redux/actions/catalogs/note/createNote";
import { fetchNote, initialStateNote } from "../../../redux/actions/catalogs/note/fetchNote";
import { updateNote } from "../../../redux/actions/catalogs/note/updateNote";

// Resources
import { scrollToTop } from "../../resources/utils";
import { types } from "../../resources/optionsList";

export default function NoteForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { note_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const noteState = useSelector((state) => state.noteReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (note_id) {
      dispatch(fetchNote(note_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateNote());
    };
  }, []);

  if (noteState.fetching) {
    return <Loader active inline />;
  }

  if (noteState.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (note_id) {
      dispatch(updateNote(note_id, data));
    } else {
      dispatch(createNote(data));
    }
  };

  if (noteState.message === "resource created successfully" || noteState.message === "resource updated successfully") {
    history.push("/notes");
  }

  return (
    <>
      <Header as='h3' dividing>{t("note_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("note_message")}</label>
            <textarea name="message" defaultValue={noteState.note.message} placeholder={t("note_message")} rows={3} maxLength={500} autoFocus ref={register({ required: true })}></textarea>
            {errors.message && (<span className="error_message">{t("required_information")}{" "}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("note_type")}</label>
            <select name="type" defaultValue={noteState.note.type} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {types.map((type, index) => (
                <option key={index} value={type}>{t(type)}</option>
              ))}
            </select>
            {errors.type && (<span className="error_message">{t("required_information")}{" "}</span>)}
          </Form.Field>

          <Form.Field>
            <label>{t("active_tag")} </label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" defaultChecked={noteState.note.active === 1} ref={register} />
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={noteState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/notes"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
