import CharterRateService from "../../../../services/rates/CharterRateService";

export const REQUEST_FETCH_CHARTER_RATES = "REQUEST_FETCH_CHARTER_RATES";
export const FETCH_CHARTER_RATES_SUCCESS = "FETCH_CHARTER_RATES_SUCCESS";
export const FETCH_CHARTER_RATES_ERROR = "FETCH_CHARTER_RATES_ERROR";

function requestFetchCharterRates() {
  return {
    type: REQUEST_FETCH_CHARTER_RATES,
  };
}

function fetchCharterRatesSuccess(json) {
  return {
    type: FETCH_CHARTER_RATES_SUCCESS,
    charterRates: json.data,
  };
}

function fetchCharterRatesError(json) {
  return {
    type: FETCH_CHARTER_RATES_ERROR,
    error: json.data,
  };
}

export function fetchCharterRates(payload) {
  return (dispatch) => {
    dispatch(requestFetchCharterRates());
    return CharterRateService.getCharterRates(payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchCharterRatesSuccess(json.data));
        } else {
          dispatch(fetchCharterRatesError(json.data));
        }
      }
    );
  };
}
