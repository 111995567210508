import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customVoucherList = createSlice({
  name: 'customVoucherList',
  initialState: {
    cruise: {},
    company: {},
    vouchers: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateVoucherListCustom(state, action) {
      return {
        cruise: {},
        company: {},
        vouchers: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesVoucherListCustom(state, action) {
      return {
        cruise: {},
        company: {},
        vouchers: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchVoucherListCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        company: action.payload.company,
        vouchers: action.payload.vouchers,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchVoucherListCustomError(state, action) {
      return {
        cruise: {},
        company: {},
        vouchers: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchVoucherListCustom = (cruise_id) => dispatch => {
  dispatch(requesVoucherListCustom());
  axios.get(`/cruise/${cruise_id}/voucherList`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchVoucherListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchVoucherListCustom(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchVoucherListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateVoucherListCustom,
  requesVoucherListCustom,
  fetchVoucherListCustomSuccess,
  fetchVoucherListCustomError,
} = customVoucherList.actions;

export default customVoucherList.reducer;