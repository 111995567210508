import PassengerBySpaceService from "../../../../services/reservations/PassengerBySpaceService";

export const REQUEST_DELETE_PASSENGER_BY_SPACE =
  "REQUEST_DELETE_PASSENGER_BY_SPACE";
export const DELETE_PASSENGER_BY_SPACE_SUCCESS =
  "DELETE_PASSENGER_BY_SPACE_SUCCESS";
export const DELETE_PASSENGER_BY_SPACE_ERROR =
  "DELETE_PASSENGER_BY_SPACE_ERROR";

function requestDeletePassengerBySpace() {
  return {
    type: REQUEST_DELETE_PASSENGER_BY_SPACE,
  };
}

function deletePassengerBySpaceSuccess(json) {
  return {
    type: DELETE_PASSENGER_BY_SPACE_SUCCESS,
    message: json.data,
  };
}

function deletePassengerBySpaceError(json) {
  return {
    type: DELETE_PASSENGER_BY_SPACE_ERROR,
    error: json.data,
  };
}

export function deletePassengerBySpace(space_id, passenger_id) {
  return (dispatch) => {
    dispatch(requestDeletePassengerBySpace());
    return PassengerBySpaceService.deletePassengerBySpace(
      space_id,
      passenger_id
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(deletePassengerBySpaceSuccess(json.data));
      } else {
        dispatch(deletePassengerBySpaceError(json.data));
      }
    });
  };
}
