import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Input, Header, Segment, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { fetchCaptains } from "../../../redux/actions/catalogs/captain/fetchCaptains";
import { deleteCaptain } from "../../../redux/actions/catalogs/captain/deleteCaptain";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function CaptainList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const captainState = useSelector((state) => state.captainReducer);
  const { register, getValues } = useForm();
  
  useEffect(() => {
    dispatch(fetchCaptains(""));
    scrollToTop();
  }, []);

  if (captainState.fetching || captainState.processing) {
    return <Loader active inline />;
  }

  if (captainState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (captainState.message === "resource deleted successfully") {
    dispatch(fetchCaptains(""));
  }

  const handledeleteCaptain = (captain_id) => {
    dispatch(deleteCaptain(captain_id));
  };

  const handleSearchCaptain = () => {
    dispatch(fetchCaptains(getValues("searchWord")));
  };

  return (
    <>
      <Segment clearing> 
        <HeaderCatalog title={t("captain_title")} message={captainState.message} to="/captains_new" />
        <Header as='h5' floated='right'>
          <Input type="text" placeholder={t("search") + "..."} action>
            <input name="searchWord" maxLength={10} ref={register()} />
            <Button type="submit" onClick={() => handleSearchCaptain()} icon="search" size="medium" />
          </Input>
        </Header>
      </Segment>
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("captain_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("captain_phone")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {captainState.captains.map((captain) => (
          <Table.Row key={captain.id}>
            <Table.Cell collapsing><ActiveIcon active={captain.active} /></Table.Cell>
            <Table.Cell>{captain.first_name} {captain.last_name}</Table.Cell>
            <Table.Cell>{captain.cell_phone}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/captains/${captain.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("captain_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handledeleteCaptain(captain.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
