import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customDayList = createSlice({
  name: 'customDayList',
  initialState: {
    itinerary: {},
    days: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateDayListCustom(state, action) {
      return {
        itinerary: {},
        days: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesDayListCustom(state, action) {
      return {
        itinerary: {},
        days: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchDayListCustomSuccess(state, action) {
      return {
        itinerary: action.payload.itinerary,
        days: action.payload.days,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchDayListCustomError(state, action) {
      return {
        itinerary: {},
        days: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchDayListCustom = (itinerary_id) => dispatch => {
  dispatch(requesDayListCustom());
  axios.get(`/itinerary/${itinerary_id}/daysList`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchDayListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchDayListCustom(itinerary_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchDayListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateDayListCustom,
  requesDayListCustom,
  fetchDayListCustomSuccess,
  fetchDayListCustomError,
} = customDayList.actions;

export default customDayList.reducer;