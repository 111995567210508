import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CaptainService from "../../../../services/catalogs/CaptainService";

export const REQUEST_DELETE_CAPTAIN = "REQUEST_DELETE_CAPTAIN";
export const DELETE_CAPTAIN_SUCCESS = "DELETE_CAPTAIN_SUCCESS";
export const DELETE_CAPTAIN_ERROR = "DELETE_CAPTAIN_ERROR";

function requestDeleteCaptain() {
  return {
    type: REQUEST_DELETE_CAPTAIN,
  };
}

function deleteCaptainSuccess(json) {
  return {
    type: DELETE_CAPTAIN_SUCCESS,
    message: json.data,
  };
}

function deleteCaptainError(json) {
  return {
    type: DELETE_CAPTAIN_ERROR,
    error: json.data,
  };
}

export function deleteCaptain(captain_id) {
  return (dispatch) => {
    dispatch(requestDeleteCaptain());
    return CaptainService.deleteCaptain(captain_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteCaptainSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteCaptain(captain_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteCaptainError(json.data));
      }
    });
  };
}
