import GeneralRateList from "../../containers/rates/generalRate/GeneralRateList";
import GeneralRateForm from "../../containers/rates/generalRate/GeneralRateForm";
import GeneralRateCabinForm from "../../containers/rates/generalRateCabin/GeneralRateCabinForm";
import GeneralRateCabinList from "../../containers/rates/generalRateCabin/GeneralRateCabinList";

const routes = [
  {
    path: "/generalRates",
    component: GeneralRateList,
  },
  {
    path: "/generalRates_new",
    component: GeneralRateForm,
  },
  {
    path: "/generalRates/:generalRate_id",
    component: GeneralRateForm,
  },
  {
    path: "/generalRates/:generalRate_id/cabins",
    component: GeneralRateCabinList,
  },
  {
    path: "/generalRates/:generalRate_id/cabins_new",
    component: GeneralRateCabinForm,
  },
];

export { routes as generalRateRoutes };
