import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SeasonService from "../../../../services/rates/SeasonService";

export const REQUEST_FETCH_SEASON = "REQUEST_FETCH_SEASON";
export const FETCH_SEASON_SUCCESS = "FETCH_SEASON_SUCCESS";
export const FETCH_SEASON_ERROR = "FETCH_SEASON_ERROR";
export const INITIAL_STATE_SEASON = "INITIAL_STATE_SEASON";

function requestFetchSeason() {
  return {
    type: REQUEST_FETCH_SEASON,
  };
}

function fetchSeasonSuccess(json) {
  return {
    type: FETCH_SEASON_SUCCESS,
    season: json.data,
  };
}

function fetchSeasonError(json) {
  return {
    type: FETCH_SEASON_ERROR,
    error: json.data,
  };
}

export function initialStateSeason() {
  return {
    type: INITIAL_STATE_SEASON,
  };
}

export function fetchSeason(season_id) {
  return (dispatch) => {
    dispatch(requestFetchSeason());
    return SeasonService.getSeason(season_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchSeasonSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSeason(season_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSeasonError(json.data));
      }
    });
  };
}
