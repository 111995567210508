import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Form } from "semantic-ui-react";
import useStyles from "../../styles/Common";
import "../../styles/common.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchYachts } from "../../../redux/actions/catalogs/yacht/fetchYachts";
import { initialStateYacht } from "../../../redux/actions/catalogs/yacht/fetchYacht";
import {
  fetchAdvanceCruises,
  initialStateAdvanceCruise,
} from "../../../redux/actions/reservations/advanceCruise/fetchAdvanceCruises";
import {
  generateAdvanceCruiseReport,
  initialStateAdvanceCruiseReport,
} from "../../../redux/actions/reservations/advanceCruise/generateAdvanceCruiseReport";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { useForm } from "react-hook-form";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function AdvanceCruiseList() {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();
  const yachtState = useSelector((state) => state.yachtsReducer);
  const advanceCruiseState = useSelector((state) => state.advanceCruiseReducer);
  const advanceCruiseReportState = useSelector(
    (state) => state.advanceCruiseReportReducer
  );
  const [advanceCruise, setAdvanceCruise] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchYachts());
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateYacht());
      dispatch(initialStateAdvanceCruise());
      dispatch(initialStateAdvanceCruiseReport());
    };
  }, [dispatch]);

  if (yachtState.fetching) {
    return <CircularProgress />;
  }

  if (yachtState.processing) {
    return <CircularProgress />;
  }

  if (yachtState.error) {
    return <div>Error: {yachtState.error}</div>;
  }

  if (yachtState.message === "resource deleted successfully") {
  }

  const onSubmit = (data) => {
    setAdvanceCruise(data);
    dispatch(
      fetchAdvanceCruises(data.yacht_id, data.start_date, data.end_date)
    );
  };

  const handleGenerateReport = () => {
    dispatch(
      generateAdvanceCruiseReport(
        advanceCruise.yacht_id,
        advanceCruise.start_date,
        advanceCruise.end_date
      )
    );
  };

  if (advanceCruiseReportState.message === "data send succesfully") {
    window.open(advanceCruiseReportState.data, "_self");
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <label>{t("advance_report_cruise")}</label>
          <Divider variant="fullWidth" />
          <br />

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("advance_report_yacht")}*</label>

              <select
                aria-expanded="true"
                name="yacht_id"
                ref={register({ required: true })}
              >
                <option></option>
                {yachtState.yachts.map((yacht) => (
                  <option key={yacht.id} value={yacht.id}>
                    {yacht.name}
                  </option>
                ))}
              </select>
              {errors.yacht_id && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>
            <Form.Field>
              <label>
                {t("advance_report_startDate")}*{" "}
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </label>
              <input
                type="date"
                name="start_date"
                ref={register({ required: true })}
              />
              {errors.start_date && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>
            <Form.Field>
              <label>
                {t("advance_report_endDate")}*{" "}
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </label>
              <input
                type="date"
                name="end_date"
                ref={register({ required: true })}
              />
              {errors.end_date && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>
          </Form.Group>

          <button
            type="submit"
            className={
              advanceCruiseState.fetching
                ? "ui loading primary button"
                : "ui primary button"
            }
          >
            <Icon name="file alternate outline" />
            {t("generate_report_button")}
          </button>
        </div>
      </Form>
      <br></br>
      <Divider variant="fullWidth" />
      <br></br>
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("advance_report_title")} {yachtState.message}
          </Typography>
          <div className={classes.search}>
            <button
              type="submit"
              onClick={() => handleGenerateReport()}
              className={
                advanceCruiseReportState.fetching
                  ? "ui loading primary button"
                  : "ui primary button"
              }
              style={{ backgroundColor: "#1BB394", color: "white" }}
            >
              <Icon name="file excel outline" />
              {t("excel_button")}
            </button>
          </div>
        </Toolbar>

        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("advance_report_reservation")}</TableCell>
              <TableCell>{t("advance_report_start")}</TableCell>
              <TableCell>{t("advance_report_end")}</TableCell>
              <TableCell width="100px">{t("advance_report_name")}</TableCell>
              <TableCell>{t("advance_report_total")}</TableCell>
              <TableCell>{t("advance_report_pp")}</TableCell>
              <TableCell>{t("advance_report_sp")}</TableCell>
              <TableCell>{t("advance_report_guide_one")}</TableCell>
              <TableCell>{t("advance_report_lang_one")}</TableCell>
              <TableCell>{t("advance_report_guide_two")}</TableCell>
              <TableCell>{t("advance_report_lang_two")}</TableCell>
              <TableCell>{t("advance_report_captain")}</TableCell>
              <TableCell width="250px">{t("advance_report_notes")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {advanceCruiseState.cruises.map((cruise) => (
              <TableRow key={cruise.id}>
                <TableCell>{cruise.code}</TableCell>
                <TableCell>{cruise.start_date_format}</TableCell>
                <TableCell>{cruise.end_date_format}</TableCell>
                <TableCell>{cruise.name}</TableCell>
                <TableCell align="center">{cruise.TO}</TableCell>
                <TableCell align="center">{cruise.PP}</TableCell>
                <TableCell align="center">{cruise.SP}</TableCell>
                <TableCell>{cruise.guide1}</TableCell>
                <TableCell>{t(cruise.language1)}</TableCell>
                <TableCell>{cruise.guide2}</TableCell>
                <TableCell>{t(cruise.language2)}</TableCell>
                <TableCell>{cruise.captain}</TableCell>
                <TableCell>{cruise.notes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
