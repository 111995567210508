import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customYachtForm = createSlice({
  name: 'customYachtForm',
  initialState: {
    kinds: [],
    companies: [],
    yacht: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateYachtFormCustom(state, action) {
      return {
        kinds: [],
        companies: [],
        yacht: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesYachtFormCustom(state, action) {
      return {
        kinds: [],
        companies: [],
        yacht: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchYachtFormCustomSuccess(state, action) {
      return {
        kinds: action.payload.kinds,
        companies: action.payload.companies,
        yacht: action.payload.yacht,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchYachtFormCustomError(state, action) {
      return {
        kinds: [],
        companies: [],
        yacht: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchYachtFormCustom = (yacht_id) => dispatch => {
  dispatch(requesYachtFormCustom());
  axios.get(`/yachts/form/yachtForm?yacht_id=${yacht_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchYachtFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchYachtFormCustom(yacht_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchYachtFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateYachtFormCustom,
  requesYachtFormCustom,
  fetchYachtFormCustomSuccess,
  fetchYachtFormCustomError,
} = customYachtForm.actions;

export default customYachtForm.reducer;