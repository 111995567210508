import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { fetchGeneralRateFormCustom, initialStateGeneralRateFormCustom } from "../../../redux/slices/custom/rates/generalRate/customGeneralRateForm.slice";
import { initialStateGeneralRate } from "../../../redux/actions/rates/generalRates/fetchGeneralRate";
import { createGeneralRate } from "../../../redux/actions/rates/generalRates/createGeneralRate";
import { updateGeneralRate } from "../../../redux/actions/rates/generalRates/updateGeneralRate";

// Resources
import { scrollToTop } from "../../resources/utils";
import { years } from "../../resources/optionsList";

export default function GeneralRateForm() {
  const porcentageRegex = new RegExp(/^\d{0,2}(\.\d{0,2})?$/);
  const sixDotTwoRegex = new RegExp(/^\d{0,6}(\.\d{0,2})?$/);
  const { t } = useTranslation();
  let history = useHistory();
  let { generalRate_id } = useParams();
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const generalRateStateCustom = useSelector((state) => state.customGeneralRateForm);
  const generalRateState = useSelector((state) => state.generalRateReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGeneralRateFormCustom(generalRate_id));
    scrollToTop();
    return () => {
      dispatch(initialStateGeneralRateFormCustom());
      dispatch(initialStateGeneralRate());
    };
  }, []);

  // General functions
  function calculateIncrement(value, percent) {
    return (value * (1 + (percent * 0.01))).toFixed(2);
  }

  function calculatePercentIncrement(singleRate, doubleRate) {
    return (((singleRate / doubleRate ) - 1) / 0.01).toFixed(2);
  }

  function calculateDiscount(value, percent) {
    return (value * (1 - (percent * 0.01))).toFixed(2);
  }

  function calculatePercentDiscount(childRate, doubleRate) {
    return ((1 - (childRate / doubleRate)) / 0.01).toFixed(2);
  }

  // Client functions
  function calculateClientRates() {
    setValue("single_client", calculateIncrement(getValues("double_client"), getValues("single_client_percent")));
    setValue("child_client", calculateDiscount(getValues("double_client"), getValues("child_client_percent")));
  }

  function calculateSingleClientPercent() {
    setValue("single_client_percent", calculatePercentIncrement(getValues("single_client"), getValues("double_client")));
  }

  function calculateChildClientPercent() {
    setValue("child_client_percent", calculatePercentDiscount(getValues("child_client"), getValues("double_client")));
  }

  function calculateSingleClientRate() {
    setValue("single_client", calculateIncrement(getValues("double_client"), getValues("single_client_percent")));
  }

  function calculateChildClientRate() {
    setValue("child_client", calculateDiscount(getValues("double_client"), getValues("child_client_percent")));
  }

  // Operator functions
  function calculateOperatorRates() {
    setValue("single_operator", calculateIncrement(getValues("double_operator"), getValues("single_operator_percent")));
    setValue("child_operator", calculateDiscount(getValues("double_operator"), getValues("child_operator_percent")));
  }

  function calculateSingleOperatorPercent() {
    setValue("single_operator_percent", calculatePercentIncrement(getValues("single_operator"), getValues("double_operator")));
  }

  function calculateChildOperatorPercent() {
    setValue("child_operator_percent", calculatePercentDiscount(getValues("child_operator"), getValues("double_operator")));
  }

  function calculateSingleOperatorRate() {
    setValue("single_operator", calculateIncrement(getValues("double_operator"), getValues("single_operator_percent")));
  }

  function calculateChildOperatorRate() {
    setValue("child_operator", calculateDiscount(getValues("double_operator"), getValues("child_operator_percent")));
  }

  if (generalRateStateCustom.fetching || generalRateState.fetching) {
    return <Loader active inline />;
  }

  if (generalRateStateCustom.error) {
    return <div>Error: {generalRateStateCustom.error}</div>;
  }

  const onSubmit = (data) => {
    if (generalRate_id) {
      dispatch(updateGeneralRate(generalRate_id, data));
    } else {
      dispatch(createGeneralRate(data));
    }
  };

  if (generalRateState.message === "resource created successfully" || generalRateState.message === "resource updated successfully") {
    let generalRateFilters = {
      year: getValues("year"),
      rate: getValues("rate_id"),
      yacht: getValues("yacht_id"),
      days: getValues("days"),
    };
    localStorage.setItem("generalRateFilters", JSON.stringify(generalRateFilters));

    history.push("/generalRates");
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section><Link to="/generalRates" className="section">{t("general_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("general_path")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />
      
      <Form onSubmit={handleSubmit(onSubmit)}>
          <Header as='h4' dividing color='grey'>{t("general_information_tag")}</Header>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t("general_year")}</label>
              <select name="year" aria-expanded="true" defaultValue={generalRateStateCustom.generalRate.year} ref={register({ required: true })}>
                <option />
                {years.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
              {errors.year && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("general_rateType")}</label>
              <select name="rate_id" defaultValue={generalRateStateCustom.generalRate.rate_id} aria-expanded="true" ref={register({ required: true })}>
                <option />
                {generalRateStateCustom.rates.map((rateType) => (
                  <option key={rateType.id} value={rateType.id}>{t(rateType.name)}</option>
                ))}
              </select>
              {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("general_yacht")}</label>
              <select name="yacht_id" defaultValue={generalRateStateCustom.generalRate.yacht_id} aria-expanded="true" ref={register({ required: true })}>
                <option />
                {generalRateStateCustom.yachts.map((yacht) => (
                  <option key={yacht.id}value={yacht.id}>{yacht.name}</option>
                ))}
              </select>
              {errors.yacht_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("general_days")}</label>
              <select name="days" defaultValue={generalRateStateCustom.generalRate.days} aria-expanded="true" ref={register({ required: true })}>
                <option />
                {generalRateStateCustom.days.map((day, index) => (
                  <option key={index} value={day.number_days}>{t(day.number_days)}</option>
                ))}
              </select>
              {errors.days && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
          </Form.Group>

          <Header as='h4' dividing color='grey'>{t("general_clientRate")}</Header>
          <Form.Group>
            <Form.Field width={2} required>
              <label>{t("general_double")}</label>
              <input name="double_client" defaultValue={generalRateStateCustom.generalRate.double_client} onChange={calculateClientRates} ref={register({pattern: sixDotTwoRegex, required: true })} />
              {errors.double_client && errors.double_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.double_client && errors.double_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field width={1} />
            
            <Form.Field width={2} required>
              <label>{t("general_single")}</label>
              <input name="single_client" defaultValue={generalRateStateCustom.generalRate.single_client} onChange={calculateSingleClientPercent} ref={register({pattern: sixDotTwoRegex, required: true })} />
              {errors.single_client && errors.single_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.single_client && errors.single_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field width={2}>
              <label>%</label>
              <input name="single_client_percent" defaultValue={generalRateStateCustom.generalRate.percent_single_client_increment == null ?  "50.00" : generalRateStateCustom.generalRate.percent_single_client_increment}  onChange={calculateSingleClientRate} ref={register()} />
              <div className="percent_label_rate" >{t("general_doubleRate")}</div> 
            </Form.Field>

            <Form.Field width={1} />

            <Form.Field width={2} required>
              <label>{t("general_child")}</label>
              <input name="child_client" defaultValue={generalRateStateCustom.generalRate.child_client} onChange={calculateChildClientPercent} ref={register({pattern: sixDotTwoRegex, required: true })} />
              {errors.child_client && errors.child_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.child_client && errors.child_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field width={2}>
              <label>%</label>
              <input name="child_client_percent" defaultValue={generalRateStateCustom.generalRate.percent_child_client_discount == null ?  "50.00" : generalRateStateCustom.generalRate.percent_child_client_discount} onChange={calculateChildClientRate} ref={register()} />
              <div className="percent_label_rate">{t("general_doubleDiscount")}</div>
            </Form.Field>

            <Form.Field width={1} />

            <Form.Field width={3}>
              <label>{t("ticket_airline")}</label>
              <select aria-expanded="true" name="airplane_ticket" defaultValue={generalRateStateCustom.generalRate.airplane_ticket} ref={register({ required: true })}>
                <option value="1">{t("yes")}</option>
                <option value="0">{t("not")}</option>
              </select>
            </Form.Field>

          </Form.Group>

          <Header as='h4' dividing color='grey'>{t("general_operatorRate")}</Header>
          <Form.Group>
            <Form.Field width={2} required>
              <label>{t("general_double")}</label>
              <input name="double_operator" defaultValue={generalRateStateCustom.generalRate.double_operator} onChange={calculateOperatorRates} ref={register({pattern: sixDotTwoRegex, required: true })} />
              {errors.double_operator && errors.double_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.double_operator && errors.double_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field width={1} />

            <Form.Field width={2} required>
              <label>{t("general_single")}</label>
              <input name="single_operator" defaultValue={generalRateStateCustom.generalRate.single_operator} onChange={calculateSingleOperatorPercent} ref={register({pattern: sixDotTwoRegex, required: true })} />
              {errors.single_operator && errors.single_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.single_operator && errors.single_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field width={2}>
              <label>%</label>
              <input placeholder="%" defaultValue={generalRateStateCustom.generalRate.percent_single_operator_increment == null ?  "50.00" : generalRateStateCustom.generalRate.percent_single_operator_increment} name="single_operator_percent" onChange={calculateSingleOperatorRate} ref={register()} />
              <div className="percent_label_rate">{t("general_doubleRate")}</div>
            </Form.Field>

            <Form.Field width={1} />
            
            <Form.Field width={2} required>
              <label>{t("general_child")}</label>
              <input name="child_operator" defaultValue={generalRateStateCustom.generalRate.child_operator} onChange={calculateChildOperatorPercent} ref={register({pattern: sixDotTwoRegex, required: true })} />
              {errors.child_operator && errors.child_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.child_operator && errors.child_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
            
            <Form.Field width={2}>
              <label>%</label>
              <input name="child_operator_percent" placeholder="%" defaultValue={generalRateStateCustom.generalRate.percent_child_operator_discount == null ?  "50.00" : generalRateStateCustom.generalRate.percent_child_operator_discount} onChange={calculateChildOperatorRate} ref={register()} />
              {errors.child_operator_percent && errors.child_operator_percent.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.child_operator_percent && errors.child_operator_percent.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          <Divider />
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("comment")}</label>
              <textarea rows="3" name="comment" defaultValue={generalRateStateCustom.generalRate.comment} ref={register}/>
            </Form.Field>
          </Form.Group>

          <Divider variant="middle" />
        
          <Button primary type="submit" loading={generalRateStateCustom.processing ? true : false}>{t("save_button")}</Button>
          <Link to={"/generalRates"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
