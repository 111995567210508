import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SpaceService from "../../../../services/reservations/SpaceService";

export const REQUEST_CANCELED_SPACE = "REQUEST_CANCELED_SPACE";
export const CANCELED_SPACE_SUCCESS = "CANCELED_SPACE_SUCCESS";
export const CANCELED_SPACE_ERROR = "CANCELED_SPACE_ERROR";

function requestCanceledSpace() {
  return {
    type: REQUEST_CANCELED_SPACE,
  };
}

function canceledSpaceSuccess(json) {
  return {
    type: CANCELED_SPACE_SUCCESS,
    message: json.data,
  };
}

function canceledSpaceError(json) {
  return {
    type: CANCELED_SPACE_ERROR,
    error: json.data,
  };
}

export function canceledSpace(space_id, payload) {
  return (dispatch) => {
    dispatch(requestCanceledSpace());
    return SpaceService.putSpaceCanceled(space_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(canceledSpaceSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(canceledSpace(space_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(canceledSpaceError(json.data));
      }
    });
  };
}
