import CabinService from "../../../../services/catalogs/CabinService";

export const REQUEST_FETCH_CABINS_BY_YACHT = "REQUEST_FETCH_CABINS_BY_YACHT";
export const FETCH_CABINS_BY_YACHT_SUCCESS = "FETCH_CABINS_BY_YACHT_SUCCESS";
export const FETCH_CABINS_BY_YACHT_ERROR = "FETCH_CABINS_BY_YACHT_ERROR";

function requestFetchCabinsByYacht() {
  return {
    type: REQUEST_FETCH_CABINS_BY_YACHT,
  };
}

function fetchCabinsByYachtSuccess(json) {
  return {
    type: FETCH_CABINS_BY_YACHT_SUCCESS,
    cabins: json.data.cabins,
  };
}

function fetchCabinsByYachtError(json) {
  return {
    type: FETCH_CABINS_BY_YACHT_ERROR,
    error: json.data,
  };
}

export function fetchCabinsByYacht(yacht_id) {
  return (dispatch) => {
    dispatch(requestFetchCabinsByYacht());
    return CabinService.getCabins(yacht_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchCabinsByYachtSuccess(json.data));
        } else {
          dispatch(fetchCabinsByYachtError(json.data));
        }
      }
    );
  };
}