import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { fetchRates } from "../../../redux/actions/catalogs/rate/fetchRates";
import { deleteRate } from "../../../redux/actions/catalogs/rate/deleteRate";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function RateList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rateState = useSelector((state) => state.rateReducer);

  useEffect(() => {
    dispatch(fetchRates());
    scrollToTop();
  }, []);

  if (rateState.fetching || rateState.processing) {
    return <Loader active inline />;
  }

  if (rateState.error) {
    return <div>Error: {rateState.error}</div>;
  }

  if (rateState.message === "resource deleted successfully") {
    dispatch(fetchRates());
  }

  const handleDeleteRate = (rate_id) => {
    dispatch(deleteRate(rate_id));
  };

  return (
    <>
      <HeaderCatalog title={t("rate_title")} message={rateState.message} to="/rates_new" />
        <Table color="blue" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>{t("rate_name")}</Table.HeaderCell>
              <Table.HeaderCell>{t("commission_percentage")}</Table.HeaderCell>
              <Table.HeaderCell>{t("comment")}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {rateState.rates.map((rate) => (
            <Table.Row key={rate.id}>
              <Table.Cell collapsing><ActiveIcon active={rate.active} /></Table.Cell>
              <Table.Cell>{t(rate.name)}</Table.Cell>
              <Table.Cell>{t(rate.commission_percentage)}</Table.Cell>
              <Table.Cell>{t(rate.comment)}</Table.Cell>
              <Table.Cell >
                <Link to={`/rates/${rate.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
                <Modal
                  trigger={
                    <Button circular color='red' icon='trash alternate' />
                  }
                  content={t("rate_delete")}
                  actions={[
                    t("canceled_button"),
                      {
                        key: "eliminar",
                        content: t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handleDeleteRate(rate.id),
                      },
                  ]}
                  size="tiny"
                />
              </Table.Cell>
            </Table.Row>
            ))}
          </Table.Body>
        </Table>
    </>
  );
}
