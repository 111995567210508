import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customGeneralRateCabinList = createSlice({
  name: 'customGeneralRateCabinList',
  initialState: {
    generalRateCabins: [],
    generalRate: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateGeneralRateCabinListCustom(state, action) {
      return {
        generalRateCabins: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesGeneralRateCabinListCustom(state, action) {
      return {
        generalRateCabins: [],
        generalRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchGeneralRateCabinListCustomSuccess(state, action) {
      return {
        generalRateCabins: action.payload.cabins,
        generalRate: action.payload.generalRate,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchGeneralRateCabinListCustomError(state, action) {
      return {
        generalRateCabins: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchGeneralRateCabinListCustom = (generalRate_id) => dispatch => {
  dispatch(requesGeneralRateCabinListCustom());
  axios.get(`/generalRate/${generalRate_id}/generalRateCabinList`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchGeneralRateCabinListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGeneralRateCabinListCustom(generalRate_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGeneralRateCabinListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateGeneralRateCabinListCustom,
  requesGeneralRateCabinListCustom,
  fetchGeneralRateCabinListCustomSuccess,
  fetchGeneralRateCabinListCustomError,
} = customGeneralRateCabinList.actions;

export default customGeneralRateCabinList.reducer;