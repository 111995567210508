import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Icon, Form, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";
import IkarusRateCell from "../../../components/rates/ikarusRateCell"
import GeneralRateCell from "../../../components/rates/generalRateCell"
import StateRateCell from "../../../components/rates/stateRateCell"
import ChangeStateRateModal from "../../../components/rates/changeStateRateModal"

//Actions
import { initialStateIkarusRate } from "../../../redux/actions/rates/ikarusRates/fetchIkarusRate";
import { deleteIkarusRate } from "../../../redux/actions/rates/ikarusRates/deleteIkarusRate";
import { fetchIkarusRateListCustom, initialStateIkarusRateListCustom } from "../../../redux/slices/custom/rates/ikarusRate/customIkarusRateList.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function IkarusRateList() {
  const { t } = useTranslation();
  let actualDate = new Date();
  let currentYear = actualDate.getFullYear();
  let yacht = "";
  let agency = "";
  let days = "all";

  const ikarusRateFilters = JSON.parse(localStorage.getItem("ikarusRateFilters"));
  if (ikarusRateFilters) {
    currentYear = ikarusRateFilters.year;
    yacht = ikarusRateFilters.yacht;
    agency = ikarusRateFilters.agency;
    days = ikarusRateFilters.days;
  }

  const { register, handleSubmit, errors, getValues } = useForm();

  const ikarusRateStateCustom  = useSelector((state) => state.customIkarusRateList);
  const ikarusRateState = useSelector((state) => state.ikarusRateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(fetchIkarusRateListCustom({year: currentYear, yacht_id: yacht, agency_id: agency, days: days}, ""));
      scrollToTop();
      return () => {
        dispatch(initialStateIkarusRateListCustom());
        dispatch(initialStateIkarusRate());
      };
    }, []);

  if (ikarusRateStateCustom.fetching || ikarusRateState.fetching) {
    return <Loader active inline />;
  }

  if (ikarusRateStateCustom.error) {
    return <div>Error: {ikarusRateStateCustom.error}</div>;
  }

  if (ikarusRateState.message === "resource deleted successfully") {
    dispatch(fetchIkarusRateListCustom({year: getValues("year"), yacht_id: getValues("yacht_id"), agency_id: getValues("agency_id"), days: getValues("days")}, ""));
    dispatch(initialStateIkarusRate());
  }

  if (ikarusRateState.message === "resource updated successfully") {
    dispatch(fetchIkarusRateListCustom({year: getValues("year"), yacht_id: getValues("yacht_id"), agency_id: getValues("agency_id"), days: getValues("days")}, ""));
    dispatch(initialStateIkarusRate());
  }

  const onSubmit = (data) => {
    let ikarusRateFilters = {
      year: getValues("year"),
      yacht: getValues("yacht_id"),
      agency: getValues("agency_id"),
      days: getValues("days"),
    };
    localStorage.setItem("ikarusRateFilters", JSON.stringify(ikarusRateFilters));
    dispatch(fetchIkarusRateListCustom(data,""));
  };

  const handleDeleteIkarusRate = (ikarusRate_id) => {
    dispatch(deleteIkarusRate(ikarusRate_id));
  };

  return (
    <>
      <HeaderCatalog title={t("ikarusRate_tag")} message={ikarusRateStateCustom.message} to="/ikarusRates/ikarusRates_new" />
      <Divider />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("general_year")}</label>
            <select name="year" defaultValue={currentYear} aria-expanded="true" ref={register}>
              {ikarusRateStateCustom.years.map((year, index) => (
                <option key={index} value={year.year}>{year.year}</option>
              ))}
            </select>  
          </Form.Field>
          <Form.Field required>
            <label>{t("general_yacht")}</label>
            <select name="yacht_id" defaultValue={yacht} aria-expanded="true" ref={register({ required: true })}>
              <option />
              {ikarusRateStateCustom.yachts.map((yacht) => (
                <option key={yacht.id} value={yacht.id}>{yacht.name}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("contract_agency")}</label>
            <select name="agency_id" defaultValue={agency} aria-expanded="true" ref={register({ required: true })}>
              <option />
              {ikarusRateStateCustom.agencies.map((agency) => (
                <option key={agency.id} value={agency.id}>{agency.name}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("general_days")}</label>
            <select name="days" defaultValue={days} aria-expanded="true" ref={register}>
              <option value="all">{t("all")}</option>
              {ikarusRateStateCustom.days.map((day, index) => (
                  <option key={index} value={day.number_days}>{t(day.number_days)}</option>
              ))}
            </select>
          </Form.Field>
          <Form.Field>
            <label>&nbsp;</label>
            <Button type="submit" loading={ikarusRateStateCustom.fetching ? true : false} color="green"><Icon name='search' />{t("search_button")}</Button>
          </Form.Field>
        </Form.Group>
      </Form>

      {ikarusRateStateCustom.ikarusRates !== undefined ? (
        <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("general_year")}</Table.HeaderCell>
            <Table.HeaderCell>{t("general_yacht")}</Table.HeaderCell>
            <Table.HeaderCell>{t("general_agency")}</Table.HeaderCell>
            <Table.HeaderCell>{t("general_days")}</Table.HeaderCell>
            <Table.HeaderCell>{t("general_cabin")}</Table.HeaderCell>
            <Table.HeaderCell>{t("range1")}</Table.HeaderCell>
            <Table.HeaderCell>{t("range2")}</Table.HeaderCell>
            <Table.HeaderCell>{t("range3")}</Table.HeaderCell>
            <Table.HeaderCell>{t("charter")}</Table.HeaderCell>
            <Table.HeaderCell>{t("state")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {ikarusRateStateCustom.ikarusRates.map((ikarusRate) => (
          <Table.Row key={ikarusRate.id}>
            <Table.Cell collapsing><ActiveIcon active={ikarusRate.active} /></Table.Cell>
            <Table.Cell>{ikarusRate.year}</Table.Cell>
            <Table.Cell>{ikarusRate.yacht}</Table.Cell>
            <Table.Cell>{ikarusRate.agency_name}</Table.Cell>
            <Table.Cell>{ikarusRate.days}</Table.Cell>
            <Table.Cell>
              {ikarusRate.cabins !== null ? (
                <Link to={`/ikarusRates/${ikarusRate.id}/cabins`}>{ikarusRate.cabins}</Link>
              ) : (
                <Link to={`/ikarusRates/${ikarusRate.id}/cabins`}><Button circular color='blue' icon='add' size="mini" /></Link>
              )}
            </Table.Cell>
            <Table.Cell>
              <IkarusRateCell 
                from={ikarusRate.range1_number_passenger_from}
                to={ikarusRate.range1_number_passenger_to} 
                charter_client={ikarusRate.range1_charter_client} 
                charter_operator={ikarusRate.range1_charter_operator} 
                double_client={ikarusRate.range1_double_client} 
                double_operator={ikarusRate.range1_double_operator} 
                single_client={ikarusRate.range1_single_client} 
                single_operator={ikarusRate.range1_single_operator} 
                ticket={ikarusRate.airplane_ticket} />
            </Table.Cell>
            <Table.Cell>
              <IkarusRateCell 
                from={ikarusRate.range2_number_passenger_from}
                to={ikarusRate.range2_number_passenger_to} 
                charter_client={ikarusRate.range2_charter_client} 
                charter_operator={ikarusRate.range2_charter_operator} 
                double_client={ikarusRate.range2_double_client} 
                double_operator={ikarusRate.range2_double_operator} 
                single_client={ikarusRate.range2_single_client} 
                single_operator={ikarusRate.range2_single_operator} 
                ticket={ikarusRate.airplane_ticket} />
            </Table.Cell>
            <Table.Cell>
              <IkarusRateCell 
                from={ikarusRate.range3_number_passenger_from}
                to={ikarusRate.range3_number_passenger_to} 
                charter_client={ikarusRate.range3_charter_client} 
                charter_operator={ikarusRate.range3_charter_operator} 
                double_client={ikarusRate.range3_double_client} 
                double_operator={ikarusRate.range3_double_operator} 
                single_client={ikarusRate.range3_single_client} 
                single_operator={ikarusRate.range3_single_operator} 
                ticket={ikarusRate.airplane_ticket} />
            </Table.Cell>
            <Table.Cell><GeneralRateCell client={ikarusRate.charter_client} operator={ikarusRate.charter_operator} ticket={ikarusRate.airplane_ticket} /></Table.Cell>
            <Table.Cell width="2"><StateRateCell activation_date={ikarusRate.activation_date} inactivation_date={ikarusRate.inactivation_date} reason_deactivation={ikarusRate.reason_deactivation} /></Table.Cell>
            <Table.Cell collapsing>
              {ikarusRate.active != false ? (
                <ChangeStateRateModal stateRate={ikarusRate} typeRate="IkarusRate" />
              ):(
                null
              )}
              <Link to={`/ikarusRates/${ikarusRate.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                closeOnDimmerClick={false}
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("general_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteIkarusRate(ikarusRate.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
      ) : (
        ""
      )}
    </>
  );
}