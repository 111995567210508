import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class StatisticService {
  static getStatisticReport(payload) {
    return axios.post("statistic", payload);
  }

  static statisticReport(startDate, endDate, type, payload) {
    var url = "statistic/report";
    url =
      url +
      "?start_date=" +
      startDate +
      "&&end_date=" +
      endDate +
      "&&type=" +
      type +
      "&&filters=" +
      payload;
    return axios.get(url);
  }
}
