import {
  REQUEST_FETCH_AIRLINES,
  FETCH_AIRLINES_SUCCESS,
  FETCH_AIRLINES_ERROR,
} from "../../../actions/catalogs/airline/fetchAirlines";

import {
  INITIAL_STATE_AIRLINE,
  REQUEST_FETCH_AIRLINE,
  FETCH_AIRLINE_SUCCESS,
  FETCH_AIRLINE_ERROR,
} from "../../../actions/catalogs/airline/fetchAirline";

import {
  REQUEST_CREATE_AIRLINE,
  CREATE_AIRLINE_SUCCESS,
  CREATE_AIRLINE_ERROR,
} from "../../../actions/catalogs/airline/createAirline";

import {
  REQUEST_UPDATE_AIRLINE,
  UPDATE_AIRLINE_SUCCESS,
  UPDATE_AIRLINE_ERROR,
} from "../../../actions/catalogs/airline/updateAirline";

import {
  REQUEST_DELETE_AIRLINE,
  DELETE_AIRLINE_SUCCESS,
  DELETE_AIRLINE_ERROR,
} from "../../../actions/catalogs/airline/deleteAirline";

const initialState = {
  airlines: [],
  airline: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const airlineReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_AIRLINE: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_AIRLINES: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_AIRLINES_SUCCESS: {
      return {
        ...state,
        airlines: action.airlines,
        airline: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_AIRLINES_ERROR: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_AIRLINE: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_AIRLINE_SUCCESS: {
      return {
        ...state,
        airlines: [],
        airline: action.airline,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_AIRLINE_ERROR: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_AIRLINE: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_AIRLINE_SUCCESS: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_AIRLINE_ERROR: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_AIRLINE: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_AIRLINE_SUCCESS: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_AIRLINE_ERROR: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_AIRLINE: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_AIRLINE_SUCCESS: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_AIRLINE_ERROR: {
      return {
        ...state,
        airlines: [],
        airline: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default airlineReducer;
