import React, { useEffect, useState } from "react";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//UI
import { Icon, Form, Header } from "semantic-ui-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
//ACTIONS
import { fetchGpsTaxListCustom, initialStateGpsTaxListCustom } from "../../../../redux/slices/custom/reservations/cruise/gpsTax/customGpsTaxList.slice";
//RESOURCES
import HeadDetail from "../../../../components/headDetail";
import gpsTaxPDF from "../../../reservations/cruise/gpsTax/gpsTaxPDF";
import { scrollToTop } from "../../../resources/utils";

export default function GpsTaxList() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { cruise_id } = useParams();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const gpsTaxState = useSelector((state) => state.customGpsTaxList);
  const [guide, setGuide] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [paymentValue, setPaymentValue] = useState("");
  const [paymentReference, setPaymentReference] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");
  const informationPayment ={
    paymentDate,
    paymentValue,
    paymentReference,
    paymentDescription
  }

  const [active, setActive] = useState(true);
  const [passengersIds, setPassengersIds] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGpsTaxListCustom(cruise_id));
    scrollToTop();
    return () => {
      dispatch(initialStateGpsTaxListCustom());
    };
  }, [dispatch, cruise_id]);

  if (
    gpsTaxState.fetching
  ) {
    return <CircularProgress />;
  }

  if (gpsTaxState.error) {
    return <div>Error: {gpsTaxState.error}</div>;
  }

  if (gpsTaxState.error) {
    return <div>Error: {gpsTaxState.error}</div>;
  }

  let passengersGpsTax = gpsTaxState.passengers.filter(
    (element) => element.gps === 1
  );

  const handleCheck = (id, check) => {
    if (check === false) {
      setPassengersIds(passengersIds.concat({ id: id }));
    } else {
      var indice = passengersIds.findIndex((passId) => passId.id === id);
      passengersIds.splice(indice, 1);
    }
  };

  var passengers = passengersGpsTax.filter(
    (passenger) =>
      !passengersIds.some(
        (passengerId) => passengerId.id === passenger.passenger_id
      )
  );

  const handleGenerateReport = () => {
    gpsTaxPDF(
      gpsTaxState.company,
      gpsTaxState.cruise,
      guide,
      passengers,
      informationPayment,
      gpsTaxState.reservations[0]
    );
  };

  const handleClick = (newState) => () => {
    if (guide === "") {
      setState({ open: true, ...newState });

      setTimeout(() => {
        setState({ ...state, open: false });
      }, 4000);
    } else {
      handleGenerateReport();
    }
  };

  return (
    <>
      {guide === "" ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert severity="warning" color="error" variant="filled">
            {t("gpstax_required")}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{gpsTaxState.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">{t("gpstax_title")}</div>
      </div>
      <br />
      <br />
      <br />
      <HeadDetail
        yacht_name={gpsTaxState.cruise.yacht_name}
        itinerary_name={gpsTaxState.cruise.itinerary_name}
        start_date={gpsTaxState.cruise.start_date_format}
        end_date={gpsTaxState.cruise.end_date_format}
      />
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("gpstax_title")} {gpsTaxState.message}
          </Typography>
          <div className={classes.search}>
            <button
              onClick={handleClick({ vertical: "top", horizontal: "right" })}
              className="ui primary button"
            >
              <Icon name="file alternate" />
              {t("generate_report_button")}
            </button>
          </div>
        </Toolbar>
        <Divider variant="fullWidth" />
        <br />

        <Toolbar>
          <Form style={{ "width": "100%" }}>
            <Form.Group>
              <Form.Field required>
                <label>{t("gpstax_guide")}</label>
                <select
                  style={{ width: "300px" }}
                  aria-expanded="true"
                  name="guide"
                  defaultValue={guide}
                  onChange={(event) => setGuide(event.target.value)}
                >
                  <option></option>
                  {gpsTaxState.guidesOnBoard.map((item) =>
                    item.guides.map((guide) => (
                      <option
                        key={guide.id}
                        value={guide.first_name + " " + guide.last_name}
                      >
                        {guide.first_name} {guide.last_name}
                      </option>
                    ))
                  )}
                </select>
              </Form.Field>
            </Form.Group>
            <Header as='h4' dividing color='grey'>{t("gpstax_payment_info")}</Header>
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("gpstax_payment_date")}</label>
                <input onChange={(event) => setPaymentDate(event.target.value)} type="datetime-local" />
              </Form.Field>
              <Form.Field>
                <label>{t("gpstax_payment_value")}</label>
                <input onChange={(event) => setPaymentValue(event.target.value)} />
              </Form.Field>
              <Form.Field>
                <label>{t("gpstax_payment_reference")}</label>
                <input onChange={(event) => setPaymentReference(event.target.value)} />
              </Form.Field>
            </Form.Group>
            
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("gpstax_payment_description")}</label>
                <textarea rows="3" onChange={(event) => setPaymentDescription(event.target.value)}/>
              </Form.Field>
            </Form.Group>
            <Divider />
            <br />
          </Form>
        </Toolbar>
        <Divider variant="fullWidth" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("gpstax_type")}</TableCell>
              <TableCell>{t("gpstax_lastName")}</TableCell>
              <TableCell>{t("gpstax_name")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passengersGpsTax.map((passenger, index) => (
              <TableRow key={passenger.passenger_id}>
                <TableCell align="center">
                  <div className="ui checkbox">
                    <input
                      type="checkbox"
                      name="active"
                      defaultChecked={active}
                      onChange={(event) => {
                        setActive(event.target.checked);
                        handleCheck(
                          passenger.passenger_id,
                          event.target.checked
                        );
                      }}
                    />
                    <label></label>
                  </div>
                </TableCell>
                <TableCell>{passenger.cruise_type}</TableCell>
                <TableCell>{passenger.last_name}</TableCell>
                <TableCell>{passenger.first_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
