import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//UI
import { Button, Modal, Table, Input, Header, Loader, Breadcrumb, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { initialStateUser } from "../../../redux/actions/catalogs/user/fetchUser";
import { deleteUser } from "../../../redux/actions/catalogs/user/deleteUser";
import { fetchAgencyUserListCustom } from "../../../redux/slices/custom/catalogs/user/customAgencyUserList.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function UserList() {
  const { t } = useTranslation();
  let { agency_id } = useParams();
  const userStateCustom = useSelector((state) => state.customAgencyUserList);
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchAgencyUserListCustom(agency_id));
    scrollToTop();
  }, []);

  if (userStateCustom.fetching || userState.fetching) {
    return <Loader active inline />;
  }

  if (userStateCustom.error) {
    return <div>Error: {userStateCustom.error}</div>;
  }

  if (userState.message === "resource deleted successfully") {
    dispatch(fetchAgencyUserListCustom(agency_id));
    dispatch(initialStateUser());
  }

  const handledeleteUser = (user_id) => {
    dispatch(deleteUser(user_id));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/agencies" className="section">{t("agency_title")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{userStateCustom.agency.name}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <HeaderCatalog title={t("user_title")} message={userStateCustom.message} to={`/agencies/${agency_id}/users_new`} />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("user_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("user_email")}</Table.HeaderCell>
            <Table.HeaderCell>{t("user_role")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {userStateCustom.users.map((user) => (
          <Table.Row key={user.id}>
            <Table.Cell collapsing><ActiveIcon active={user.active} /></Table.Cell>
            <Table.Cell>{user.first_name} {user.last_name}</Table.Cell>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{user.role_name}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/agencies/${agency_id}/users/${user.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("airline_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handledeleteUser(user.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
