import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class GuideService {
  static getGuides(searchWord) {
    var url = "guides";
    url = url + `?searchWord=${searchWord}`;
    return axios.get(url);
  }
  static getGuide(guide_id) {
    return axios.get("guides/" + guide_id);
  }

  static postGuide(payload) {
    return axios.post("guides", payload);
  }

  static putGuide(guide_id, payload) {
    return axios.put("guides/" + guide_id, payload);
  }

  static deleteGuide(guide_id) {
    return axios.delete("guides/" + guide_id);
  }
}
