import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class NoteService {
  static getNotes(searchWord) {
    var url = "notes";
    url = url + `?searchWord=${searchWord}`;
    return axios.get(url);
  }
  static getNote(note_id) {
    return axios.get("notes/" + note_id);
  }

  static postNote(payload) {
    return axios.post("notes", payload);
  }

  static putNote(note_id, payload) {
    return axios.put("notes/" + note_id, payload);
  }

  static deleteNote(note_id) {
    return axios.delete("notes/" + note_id);
  }
}
