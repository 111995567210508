import {
  REQUEST_FETCH_SPACES_BY_CRUISE,
  FETCH_SPACES_BY_CRUISE_SUCCESS,
  FETCH_SPACES_BY_CRUISE_ERROR,
  INITIAL_STATE_SPACES_BY_CRUISE,
} from "../../../actions/reservations/cruiseDetail/fetchSpacesByCruise";

const initialState = {
  spaces: [],
  fetching: false,
  message: "",
  error: "",
};

const spaceByCruiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_SPACES_BY_CRUISE: {
      return {
        ...state,
        spaces: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_SPACES_BY_CRUISE: {
      return {
        ...state,
        spaces: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACES_BY_CRUISE_SUCCESS: {
      return {
        ...state,
        spaces: action.spaces,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACES_BY_CRUISE_ERROR: {
      return {
        ...state,
        spaces: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default spaceByCruiseReducer;
