  import React, { useEffect } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { useForm } from "react-hook-form";
  import { Link, useHistory, useParams } from "react-router-dom";

  //UI
  import { Form, Divider, Header, Loader, Button, Breadcrumb } from "semantic-ui-react";
  import { useTranslation } from "react-i18next";
  //ACTIONS
  import { createGeneralRatesCabin } from "../../../redux/actions/rates/generalRatesCabin/createGeneralRatesCabin";
  import { initialStateGeneralRateCabin } from "../../../redux/actions/rates/generalRatesCabin/deleteGeneralRatesCabin";
  import { fetchGeneralRateCabinFormCustom, initialStateGeneralRateCabinFormCustom} from "../../../redux/slices/custom/rates/generalRateCabins/customGeneralRateCabinForm.slice";
  // Resources
  import { scrollToTop } from "../../resources/utils";

  export default function GeneralRateCabinForm() {
    const { t } = useTranslation();
    let history = useHistory();
    let { generalRate_id } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const generalRateCabinStateCustom = useSelector((state) => state.customGeneralRateCabinForm);
    const generalRateCabinState = useSelector((state) => state.generalRateCabinReducer);
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(fetchGeneralRateCabinFormCustom(generalRate_id));
      scrollToTop();
      return () => {
        dispatch(initialStateGeneralRateCabinFormCustom());
        dispatch(initialStateGeneralRateCabin());
      };
    }, []);  
    
    if (generalRateCabinStateCustom.fetching || generalRateCabinState.fetching ) {
      return <Loader />;
    }

    if (generalRateCabinState.error) {
      return <div>Error: {generalRateCabinState.error}</div>;
    }

    if (generalRateCabinStateCustom.error) {
      return <div>Error: {generalRateCabinStateCustom.error}</div>;
    }

    const onSubmit = (data) => {
      dispatch(createGeneralRatesCabin(generalRate_id, data));
    };

    if (generalRateCabinState.message === "resource created successfully" || generalRateCabinState.message === "resource updated successfully") {
      history.go(-1);
    }

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Section ><Link to="/generalRates" className="section">{t("cruise_rates")}</Link></Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section><Link to={`/generalRates/${generalRate_id}/cabins`} className="section">{generalRateCabinStateCustom.generalRate.year} - {generalRateCabinStateCustom.generalRate.rate} - {generalRateCabinStateCustom.generalRate.yacht} - {generalRateCabinStateCustom.generalRate.days} días</Link></Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>{t("cabin_title")}</Breadcrumb.Section>
        </Breadcrumb>
        <Divider variant="fullWidth" />

        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Nombre</label>
                <select name="cabin_id"  aria-expanded="true" ref={register({ required: true })}>
                  <option></option>
                  {generalRateCabinStateCustom.cabins.map((cabin) => (
                    <option key={cabin.id} value={cabin.id} className="selected item">{cabin.name}</option>
                  ))}
                </select>
                {errors.cabin_id && (<span className="error_message">Información requerida</span>)}
              </Form.Field>
            </Form.Group>

            <Divider variant="middle" />

            <Button primary type="submit" loading={generalRateCabinState.processing ? true : false}>{t("save_button")}</Button>
            <Link to={`/generalRates/${generalRate_id}/cabins`}><Button>{t("canceled_button")}</Button></Link>
        </Form>
      </>
    );
  }
