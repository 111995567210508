import React, { useState, useEffect } from "react";
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import { signIn } from "../../redux/actions/catalogs/user/signinUser";
import { initialStateUser } from "../../redux/actions/catalogs/user/fetchUser";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, Redirect, Link } from "react-router-dom";
import logo from '../icons/logo.png';
import ShowPassword from '../../components/ShowPassword';

//TRASLATION
import { useTranslation } from "react-i18next";
import UserLogo from "../../components/UserLogo";

export default function LoginAgencyForm() {
  let history = useHistory();
  const { i18n, t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const [languageName, setLanguageName] = useState("English");
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const changeLanguageName = () => {
    if (languageName === "English") {
      setLanguageName("Español");
      changeLanguage("en");
    } else {
      setLanguageName("English");
      changeLanguage("es");
    }
  };

  useEffect(() => {
    return () => {
      dispatch(initialStateUser());
    };
  }, []);

  const onSubmit = (data) => {
    dispatch(signIn(data));
  };

  if (Object.keys(userState.user).length !== 0) {
    let userInfo = {
      id: userState.user.id,
      userName: userState.user.user_name,
      agencyName: userState.user.agency_name,
      token: userState.user.accessToken,
      role: userState.user.role,
    };
    if (userState.user.changePassword) {
      history.push(`/changePassword/${userState.user.id}`)
    } else {
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      history.push("/");
    }
  }

  return (
    <div
      className={`text-sky-800 flex justify-center items-center 2xl:items-center transition-all h-full w-full min-h-screen bg-cover bg-center bg-[url('../src/containers/icons/Picture3.jpg')]`}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-gray-600/40 backdrop-blur-sm"></div>
      <div className="w-[90%] h-full transition-all fle rounded-md sm:w-2/3 md:w-[35%]  bg-[#ffffff6a] sm:bg-[#ffffff6a] backdrop-blur-lg shadow-lg shadow-gray-500 flex flex-col items-center justify-center">
        <div className='flex flex-col gap-2 w-[65%] mt-10'>
          <h1 className="text-center text-[34px] font-semibold mb-0">¡Bienvenido!</h1>
          <h1 className="text-center text-[25px] font-semibold mt-0">{t("reservation_system")}</h1>
        </div>
        {userState.error === "invalid password" || userState.error === "resource not found" ?
          <>
            <div className='flex flex-col p-2 w-[80%] border-red-500 rounded-md border mt-6 bg-red-200'>
              <h1 className="text-left text-sm mb-0">Error!</h1>
              <div className='pl-2 text-sm'>
                <p className='mb-0'>Usuario o contraseña incorrectas.</p>
              </div>
            </div>
          </> : userState.error === "temporary password has expired" ?
           <>
           <div className='flex flex-col p-2 w-[80%] border-red-500 rounded-md border mt-6 bg-red-200'>
             <h1 className="text-left text-sm mb-0">Error!</h1>
             <div className='pl-2 text-sm'>
               <p className='mb-0'>La contraseña temporal ha expirado. Por favor, solicite una nueva.</p>
             </div>
           </div>
         </>:""}
        <form onSubmit={handleSubmit(onSubmit)} className="w-[75%] mx-auto text-gray-800 my-10">
          <div className='flex flex-col'>
            <div className="relative flex flex-col mt-8">
              <input className={'w-full placeholder-gray-800 p-2 rounded-xl focus:outline-none peer bg-transparent shadow-[inset_0_-1px_0_0_rgba(29,34,43,.2)]'}
                type='text'
                ref={register({ required: true })}
                placeholder="Email"
                name="email"
              />
              <UserLogo />
            </div>
            {errors.email && (<span className="error_message">{t("required_information")}</span>)}
            <div className="relative flex flex-col mt-8">
              <input className='w-full p-2 rounded-xl placeholder-gray-800 focus:outline-none peer bg-transparent shadow-[inset_0_-1px_0_0_rgba(29,34,43,.2)]'           
                type={isPasswordHidden ? 'password' : 'text'}
                ref={register({ required: true })}
                placeholder="Password"
                name="password"
              />
              <ShowPassword
                isPasswordHidden={isPasswordHidden}
                setIsPasswordHidden={setIsPasswordHidden}
              />
            </div>
            {errors.email && (<span className="text-red-600">{t("required_information")}</span>)}
            <div className='flex gap-2 w-[100%] mt-2 justify-end'>
              <h1 className="text-[13px] flex gap-2"><Link to={'/forgotPassword'}><p className='text-sky-700 hover:text-sky-800'>¿Has olvidado tu contraseña?</p> </Link></h1>
            </div>
          </div>
          <div className='flex w-full justify-center h-[35px] mt-10'>
            <button type=" submit" className="btn shadow-lg btn-block w-[70%] bg-sky-700 hover:bg-sky-800 hover:duration-75 border-none rounded-md  text-white">
              {t("sign_in")}
            </button>
          </div>
        </form>
      </div>
      <div className="absolute w-[220px] h-[20px] z-10 -translate-x-1/2 top-5 left-1/2  sm:translate-x-0  sm:top-[80%] sm:left-5 rotate-[0.5deg] ">
        <img src={logo} alt="logo" />
      </div>
    </div >
  );
}
