import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CabinBerthService from "../../../../services/catalogs/CabinBerthService";

export const REQUEST_CREATE_CABIN_BERTH = "REQUEST_CREATE_CABIN_BERTH";
export const CREATE_CABIN_BERTH_SUCCESS = "CREATE_CABIN_BERTH_SUCCESS";
export const CREATE_CABIN_BERTH_ERROR = "CREATE_CABIN_BERTH_ERROR";

function requestCreateCabinBerth() {
  return {
    type: REQUEST_CREATE_CABIN_BERTH,
  };
}

function createCabinBerthSuccess(json) {
  return {
    type: CREATE_CABIN_BERTH_SUCCESS,
    message: json.data,
  };
}

function createCabinBerthError(json) {
  return {
    type: CREATE_CABIN_BERTH_ERROR,
    error: json.data,
  };
}

export function createCabinBerth(cabin_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateCabinBerth());
    return CabinBerthService.postCabinBerth(cabin_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createCabinBerthSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createCabinBerth(cabin_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createCabinBerthError(json.data));
      }
    });
  };
}
