import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GeneralRateService from "../../../../services/rates/GeneralRateService";

export const REQUEST_UPDATE_GENERAL_RATE = "REQUEST_UPDATE_GENERAL_RATE";
export const UPDATE_GENERAL_RATE_SUCCESS = "UPDATE_GENERAL_RATE_SUCCESS";
export const UPDATE_GENERAL_RATE_ERROR = "UPDATE_GENERAL_RATE_ERROR";

function requestUpdateGeneralRate() {
  return {
    type: REQUEST_UPDATE_GENERAL_RATE,
  };
}

function updateGeneralRateSuccess(json) {
  return {
    type: UPDATE_GENERAL_RATE_SUCCESS,
    message: json.data,
  };
}

function updateGeneralRateError(json) {
  return {
    type: UPDATE_GENERAL_RATE_ERROR,
    error: json.data,
  };
}

export function updateGeneralRate(generalRate_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateGeneralRate());
    return GeneralRateService.putGeneralRate(generalRate_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateGeneralRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateGeneralRate(generalRate_id, payload));
      } else if (json.data.code === 498) {
        logOut(); 
      } else {
        dispatch(updateGeneralRateError(json.data));
      }
    });
  };
}
