import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import PassengerService from "../../../../services/reservations/PassengerService";

export const REQUEST_FETCH_PASSENGERS_ZARPE = "REQUEST_FETCH_PASSENGERS_ZARPE";
export const FETCH_PASSENGERS_ZARPE_SUCCESS = "FETCH_PASSENGERS_ZARPE_SUCCESS";
export const FETCH_PASSENGERS_ZARPE_ERROR = "FETCH_PASSENGERS_ZARPE_ERROR";
export const INITIAL_STATE_PASSENGER_ZARPE = "INITIAL_STATE_PASSENGER_ZARPE";

function requestFetchPassengersZarpe() {
  return {
    type: REQUEST_FETCH_PASSENGERS_ZARPE,
  };
}

function fetchPassengersZarpeSuccess(json) {
  return {
    type: FETCH_PASSENGERS_ZARPE_SUCCESS,
    passengers: json.data,
    message: json.message,
  };
}

function fetchPassengersZarpeError(json) {
  return {
    type: FETCH_PASSENGERS_ZARPE_ERROR,
    error: json.data,
  };
}

export function initialStatePassengerZarpe() {
  return {
    type: INITIAL_STATE_PASSENGER_ZARPE,
  };
}

export function fetchPassengersZarpe(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchPassengersZarpe());
    return PassengerService.getPassengersZarpe(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchPassengersZarpeSuccess(json.data, json.message));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchPassengersZarpe(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchPassengersZarpeError(json.data));
      }
    });
  };
}
