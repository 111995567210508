import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createKind } from "../../../redux/actions/catalogs/kind/createKind";
import { fetchKind, initialStateKind } from "../../../redux/actions/catalogs/kind/fetchKind";
import { updateKind } from "../../../redux/actions/catalogs/kind/updateKind";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function KindForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { kind_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const kindState = useSelector((state) => state.kindReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (kind_id) {
      dispatch(fetchKind(kind_id));
    }
    scrollToTop();

    return () => {
      dispatch(initialStateKind());
    };
  }, []);

  if (kindState.fetching) {
    return <Loader active inline />;
  }

  if (kindState.error) {
    return <Message negative
      header='Error!'
      content={t("server_error_form")}
    />;
  }

  const onSubmit = (data) => {
    if (kind_id) {
      dispatch(updateKind(kind_id, data));
    } else {
      dispatch(createKind(data));
    }
  };

  if (kindState.message === "resource created successfully" || kindState.message === "resource updated successfully") {
    history.push("/kinds");
  }

  return (
    <>
      <Header as='h3' dividing>{t("kind_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("kind_name")}</label>
            <input name="name" defaultValue={kindState.kind.name} placeholder={t("kind_name")} maxLength={100} ref={register({ required: true })} autoFocus />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("kind_initials")} </label>
            <input name="short" defaultValue={kindState.kind.short} placeholder={t("kind_initials")} maxLength={45} ref={register ({ required: true })} />
            {errors.short && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />
    
        <Button primary type="submit" loading={kindState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/kinds"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
