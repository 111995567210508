import AvailablePassengerService from "../../../../services/reservations/AvailablePassengerService";

export const REQUEST_FETCH_AVAILABLE_PASSENGERS =
  "REQUEST_FETCH_AVAILABLE_PASSENGERS";
export const FETCH_AVAILABLE_PASSENGERS_SUCCESS =
  "FETCH_AVAILABLE_PASSENGERS_SUCCESS";
export const FETCH_AVAILABLE_PASSENGERS_ERROR =
  "FETCH_AVAILABLE_PASSENGERS_ERROR";
export const INITIAL_STATE_AVAILABLE_PASSENGERS =
  "INITIAL_STATE_AVAILABLE_PASSENGERS";

function requestFetchAvailablePassengers() {
  return {
    type: REQUEST_FETCH_AVAILABLE_PASSENGERS,
  };
}

function fetchAvailablePassengersSuccess(json) {
  return {
    type: FETCH_AVAILABLE_PASSENGERS_SUCCESS,
    passengers: json.data,
  };
}

function fetchAvailablePassengersError(json) {
  return {
    type: FETCH_AVAILABLE_PASSENGERS_ERROR,
    error: json.data,
  };
}

export function initialStateAvailablePassengers() {
  return {
    type: INITIAL_STATE_AVAILABLE_PASSENGERS,
  };
}

export function fetchAvailablePassengers(
  cruise_id,
  reservation_id,
  booking_id,
  passenger_id
) {
  return (dispatch) => {
    dispatch(requestFetchAvailablePassengers());
    return AvailablePassengerService.getAvailablePassengers(
      cruise_id,
      reservation_id,
      booking_id,
      passenger_id
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchAvailablePassengersSuccess(json.data));
      } else {
        dispatch(fetchAvailablePassengersError(json.data));
      }
    });
  };
}
