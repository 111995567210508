import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

export default function FlightCell(props) {
    return (
      <>
        {props.space.flights !== null ? (
          <Link to={`/cruises/${props.cruise_id}/reservations/${props.reservation_id}/bookings/${props.booking_id}/spaces/${props.space.id}/spaceFlights`}>
            <span>{props.space.flights.split("|")[0]}</span>
            <br />
            <span>{props.space.flights.split("|")[1]}</span>
          </Link>
        ) : (
          <>
            <Link to={`/cruises/${props.cruise_id}/reservations/${props.reservation_id}/bookings/${props.booking_id}/spaces/${props.space.id}/spaceFlights`}>
              <Button className="add_button" icon circular size="mini">
                <Icon className="icon_color" name="add" />
              </Button>
            </Link>
          </>
        )}
      </>
    )
}