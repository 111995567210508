import {
  INITIAL_STATE_RESERVATION_CANCELED,
  REQUEST_FETCH_RESERVATIONS_CANCELED,
  FETCH_RESERVATIONS_CANCELED_SUCCESS,
  FETCH_RESERVATIONS_CANCELED_ERROR,
} from "../../../actions/reservations/reservation/fetchReservationsCanceled";

const initialState = {
  reservationsCanceled: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const reservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_RESERVATION_CANCELED: {
      return {
        ...state,
        reservationsCanceled: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }

    case REQUEST_FETCH_RESERVATIONS_CANCELED: {
      return {
        ...state,
        reservations: [],
        reservationsCanceled: [],
        reservation: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_RESERVATIONS_CANCELED_SUCCESS: {
      return {
        ...state,
        reservations: [],
        reservationsCanceled: action.reservationsCanceled,
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_RESERVATIONS_CANCELED_ERROR: {
      return {
        ...state,
        reservations: [],
        reservationsCanceled: [],
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default reservationReducer;
