import {
    REQUEST_FETCH_DAYS,
    FETCH_DAYS_SUCCESS,
    FETCH_DAYS_ERROR,
    INITIAL_STATE_DAYS,
} from "../../../actions/catalogs/itinerary/fetchDays";

const initialState = {
    days: [],
    fetching: false,
    message: "",
    error: "",
};

const itineraryDaysReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_DAYS: {
      return {
        ...state,
        days: [],
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_DAYS: {
      return {
        ...state,
        days: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_DAYS_SUCCESS: {
      return {
        ...state,
        days: action.days,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_DAYS_ERROR: {
      return {
        ...state,
        days: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default itineraryDaysReducer;