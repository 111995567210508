import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class DeckService {
  static getDecks() {
    return axios.get("decks");
  }
  static getDeck(deck_id) {
    return axios.get("decks/" + deck_id);
  }

  static postDeck(payload) {
    return axios.post("decks", payload);
  }

  static putDeck(deck_id, payload) {
    return axios.put("decks/" + deck_id, payload);
  }

  static deleteDeck(deck_id) {
    return axios.delete("decks/" + deck_id);
  }
}
