import PassengerInFlightList from "../containers/reservations/cruise/flight/PassengerInFlightList";

const routes = [
  {
    path: "/cruises/:cruise_id/flightListPassengersReport",
    component: PassengerInFlightList,
  },
];

export { routes as passengerInFlightRoutes };
