import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class FamilyRoomService {
  static getYears() {
    return axios.get("familyRooms/year");
  }

  static getFamilyRooms(payload) {
    return axios.post("familyRooms/getByBody", payload);
  }

  static getYachts() {
    return axios.get("familyRooms/yachts");
  }
  static getFamilyRoom(family_room_id) {
    return axios.get("familyRooms/" + family_room_id);
  }

  static postFamilyRoom(payload) {
    return axios.post("familyRooms", payload);
  }

  static putFamilyRoom(family_room_id, payload) {
    return axios.put("familyRooms/" + family_room_id, payload);
  }

  static activeFamilyRoom(family_room_id) {
    return axios.put("familyRooms/" + family_room_id + "/activeFamilyRoomRate");
  }

  static inactiveFamilyRoom(family_room_id, payload) {
    return axios.put("familyRooms/" + family_room_id + "/inactiveFamilyRoomRate", payload);
  }

  static deleteFamilyRoom(family_room_id) {
    return axios.delete("familyRooms/" + family_room_id);
  }
  static getCabinsByYacht(yacht_id) {
    return axios.get("familyRooms/" + yacht_id + "/cabins");
  }
}
