import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customFlightForm = createSlice({
  name: 'customFlightForm',
  initialState: {
    airlines: [],
    flight: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateFlightFormCustom(state, action) {
      return {
        airlines: [],
        flight: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesFlightFormCustom(state, action) {
      return {
        airlines: [],
        flight: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchFlightFormCustomSuccess(state, action) {
      return {
        airlines: action.payload.airlines,
        flight: action.payload.flight,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchFlightFormCustomError(state, action) {
      return {
        airlines: [],
        flight: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchFlightFormCustom = (flight_id) => dispatch => {
  dispatch(requesFlightFormCustom());
  axios.get(`/flights/form/flightForm?flight_id=${flight_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchFlightFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchFlightFormCustom(flight_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchFlightFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateFlightFormCustom,
  requesFlightFormCustom,
  fetchFlightFormCustomSuccess,
  fetchFlightFormCustomError,
} = customFlightForm.actions;

export default customFlightForm.reducer;