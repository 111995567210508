import {
  INITIAL_STATE_HISTORICAL_CRUISE,
  REQUEST_FETCH_HISTORICAL_CRUISES,
  FETCH_HISTORICAL_CRUISES_SUCCESS,
  FETCH_HISTORICAL_CRUISES_ERROR,
} from "../../../actions/reservations/historicalCruise/fetchHistoricalCruises";

import {
  REQUEST_FETCH_HISTORICAL_YEARS,
  FETCH_HISTORICAL_YEARS_SUCCESS,
  FETCH_HISTORICAL_YEARS_ERROR,
} from "../../../actions/reservations/historicalCruise/fetchHistoricalYears";

const initialState = {
  cruises: [],
  years: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const historicalTipTopReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_HISTORICAL_CRUISE: {
      return {
        ...state,
        cruises: [],
        years: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_HISTORICAL_CRUISES: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_HISTORICAL_CRUISES_SUCCESS: {
      return {
        ...state,
        cruises: action.cruises,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_HISTORICAL_CRUISES_ERROR: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_HISTORICAL_YEARS: {
      return {
        ...state,
        years: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_HISTORICAL_YEARS_SUCCESS: {
      return {
        ...state,
        years: action.years,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_HISTORICAL_YEARS_ERROR: {
      return {
        ...state,
        years: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default historicalTipTopReducer;
