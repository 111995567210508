import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FlightService from "../../../../services/catalogs/FlightService";

export const REQUEST_FETCH_FLIGHT = "REQUEST_FETCH_FLIGHT";
export const FETCH_FLIGHT_SUCCESS = "FETCH_FLIGHT_SUCCESS";
export const FETCH_FLIGHT_ERROR = "FETCH_FLIGHT_ERROR";
export const INITIAL_STATE_FLIGHT = "INITIAL_STATE_FLIGHT";

function requestFetchFlight() {
  return {
    type: REQUEST_FETCH_FLIGHT,
  };
}

function fetchFlightSuccess(json) {
  return {
    type: FETCH_FLIGHT_SUCCESS,
    flight: json.data,
  };
}

function fetchFlightError(json) {
  return {
    type: FETCH_FLIGHT_ERROR,
    error: json.data,
  };
}

export function initialStateFlight() {
  return {
    type: INITIAL_STATE_FLIGHT,
  };
}

export function fetchFlight(flight_id) {
  return (dispatch) => {
    dispatch(requestFetchFlight());
    return FlightService.getFlight(flight_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchFlightSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchFlight(flight_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchFlightError(json.data));
      }
    });
  };
}
