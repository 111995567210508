import {
  INITIAL_STATE_VOUCHER_INFORMATION,
  REQUEST_FETCH_VOUCHER_INFORMATION,
  FETCH_VOUCHER_INFORMATION_SUCCESS,
  FETCH_VOUCHER_INFORMATION_ERROR,
} from "../../../actions/reservations/voucherPDF/fetchInformationVoucherPdf";

const initialState = {
  voucherInformation: {},
  buildingpdf: false,
  message: "",
  error: "",
};

const voucherPdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_VOUCHER_INFORMATION: {
      return {
        ...state,
        voucherInformation: {},
        buildingpdf: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_VOUCHER_INFORMATION: {
      return {
        ...state,
        voucherInformation: {},
        buildingpdf: true,
        message: "",
        error: "",
      };
    }
    case FETCH_VOUCHER_INFORMATION_SUCCESS: {
      return {
        ...state,
        voucherInformation: action.voucherInformation,
        buildingpdf: false,
        message: action.message,
        error: "",
      };
    }
    case FETCH_VOUCHER_INFORMATION_ERROR: {
      return {
        ...state,
        voucherInformation: {},
        buildingpdf: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default voucherPdfReducer;
