import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CabinBerthService from "../../../../services/catalogs/CabinBerthService";

export const REQUEST_FETCH_CABIN_BERTHS = "REQUEST_FETCH_CABIN_BERTHS";
export const FETCH_CABIN_BERTHS_SUCCESS = "FETCH_CABIN_BERTHS_SUCCESS";
export const FETCH_CABIN_BERTHS_ERROR = "FETCH_CABIN_BERTHS_ERROR";

function requestFetchCabinBerths() {
  return {
    type: REQUEST_FETCH_CABIN_BERTHS,
  };
}

function fetchCabinBerthsSuccess(json) {
  return {
    type: FETCH_CABIN_BERTHS_SUCCESS,
    cabinBerths: json.data,
  };
}

function fetchCabinBerthsError(json) {
  return {
    type: FETCH_CABIN_BERTHS_ERROR,
    error: json.data,
  };
}

export function fetchCabinBerths(cabin_id) {
  return (dispatch) => {
    dispatch(requestFetchCabinBerths());
    return CabinBerthService.getCabinBerths(cabin_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCabinBerthsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCabinBerths(cabin_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCabinBerthsError(json.data));
      }
    });
  };
}
