import React from "react";
import { useTranslation } from "react-i18next";
import { Table, Header } from "semantic-ui-react";

export default function CruiseTableResumenDivided(props) {
  const { t } = useTranslation();
  return (
    <>
      <Table.Body>
        <Table.Row textAlign="center">
          <Table.Cell>
            <Header>
              <span className="header_style">
                {t("cruise_detail_to")}
              </span>
            </Header>
          </Table.Cell>
          <Table.Cell style={{ backgroundColor: "#DBADFF" }}>
            {props.TO}
          </Table.Cell>
          <Table.Cell>{props.numberSpacesUsed_TO_Reserved}</Table.Cell>
          <Table.Cell>{props.numberSpacesUsed_TO_Confirmed}</Table.Cell>        
          <Table.Cell>{props.numberSpacesUsed_TO_Waiting}</Table.Cell>
        </Table.Row>
        <Table.Row textAlign="center">
          <Table.Cell>
            <Header>
              <span className="header_style">
                {t("cruise_detail_pp")}
              </span>
            </Header>
          </Table.Cell>
          <Table.Cell style={{ backgroundColor: "#DBADFF" }}>
            {props.PP}
          </Table.Cell>
          <Table.Cell>{props.numberSpacesUsed_PP_Reserved}</Table.Cell>
          <Table.Cell>{props.numberSpacesUsed_PP_Confirmed}</Table.Cell>
          <Table.Cell>{props.numberSpacesUsed_PP_Waiting}</Table.Cell>
        </Table.Row>
        <Table.Row textAlign="center">
          <Table.Cell>
            <Header>
              <span className="header_style">
                {t("cruise_detail_sp")}
              </span>
            </Header>
          </Table.Cell>
          <Table.Cell style={{ backgroundColor: "#DBADFF" }}>
            {props.SP}
          </Table.Cell>
          <Table.Cell>{props.numberSpacesUsed_SP_Reserved}</Table.Cell>
          <Table.Cell>{props.numberSpacesUsed_SP_Confirmed}</Table.Cell>
          <Table.Cell>{props.numberSpacesUsed_SP_Waiting}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </>
  );
}
