import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customCharterRateList = createSlice({
  name: 'customCharterRateList',
  initialState: {
    years: [],
    rates: [],
    yachts: [],
    days: [],
    charterRates: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateCharterRateListCustom(state, action) {
      return {
        years: [],
        rates: [],
        yachts: [],
        days: [],
        charterRates: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesCharterRateListCustom(state, action) {
      return {
        years: [],
        rates: [],
        yachts: [],
        days: [],
        charterRates: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchCharterRateListCustomSuccess(state, action) {
      return {
        years: action.payload.years,
        rates: action.payload.rates,
        yachts: action.payload.yachts,
        days: action.payload.days,
        charterRates: action.payload.charterRates,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchCharterRateListCustomError(state, action) {
      return {
        years: [],
        rates: [],
        yachts: [],
        days: [],
        charterRates: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchCharterRateListCustom = (payload) => dispatch => {
  dispatch(requesCharterRateListCustom());
  axios.post(`/charterRates/charterRatesList`, payload)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchCharterRateListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCharterRateListCustom(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCharterRateListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateCharterRateListCustom,
  requesCharterRateListCustom,
  fetchCharterRateListCustomSuccess,
  fetchCharterRateListCustomError,
} = customCharterRateList.actions;

export default customCharterRateList.reducer;