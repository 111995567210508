import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//ACTIONS
import { createCruise } from "../../../../redux/actions/reservations/cruise/createCruise";
import { fetchItinerariesByYacht, initialStateItinerariesByYacht } from "../../../../redux/actions/catalogs/itineraryByYacht/fetchItinerariesByYacht";
import { initialStateCruise} from "../../../../redux/actions/reservations/cruise/fetchCruise";
import { updateCruise } from "../../../../redux/actions/reservations/cruise/updateCruise";
import { fetchTipTopFormCustom, initialStateTipTopFormCustom} from "../../../../redux/slices/custom/reservations/cruise/TipTop/customTipTopForm.slice";
//UI
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Form } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "../../../styles/common.css";


const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "10%",
    marginTop: -12,
    marginLeft: -82,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

export default function TipTopForm() {
  let history = useHistory();
  let { cruise_id } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const cruiseState = useSelector((state) => state.cruiseReducer);
  const cruiseCustomState = useSelector((state) => state.customTipTopForm);
  let yacht_id = cruiseCustomState.cruise.yacht_id;
  const itineraryByYachtState= useSelector((state) => state.itineraryByYachtReducer);
  const [itinerary, setItinerary] = useState();
  const [itineraryDays, setItineraryDays] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTipTopFormCustom("", cruise_id))
    return () => {
      dispatch(initialStateCruise());
      dispatch(initialStateTipTopFormCustom());
      dispatch(initialStateItinerariesByYacht());
    };
  }, [dispatch, cruise_id]);

  useEffect(() => {
    if (yacht_id) {
      dispatch(fetchItinerariesByYacht(yacht_id));
    }
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [dispatch, yacht_id]);

  if (
    cruiseState.fetching ||
    cruiseCustomState.fetching
  ) {
    return <CircularProgress />;
  }

  if (cruiseCustomState.error) {
    return <div>Error: {cruiseCustomState.error}</div>;
  }

  if (cruiseCustomState.cruise.end_date_format && endDate === undefined) {
    setEndDate(
      cruiseCustomState.cruise.end_date_format.split("/").reverse().join("-")
    );
  }

  if (cruiseCustomState.cruise.start_date_format && startDate === undefined) {
    setStartDate(
      cruiseCustomState.cruise.start_date_format.split("/").reverse().join("-")
    );
  }

  if (cruiseCustomState.cruise.itinerary_name && itineraryDays === undefined) {
    setItineraryDays(
      parseInt(cruiseCustomState.cruise.itinerary_name.substr(0, 2), 10)
    );
  }

  const getItineraries = (event) => {
    if (event) {
      dispatch(fetchItinerariesByYacht(event));
    }
  };

  const handleItinerary = (event) => {
    setItinerary(event);
  };

  const onSubmit = (data) => {
    if (cruise_id) {
      dispatch(updateCruise(cruise_id, data));
    } else {
      dispatch(createCruise(data));
    }
  };

  const handleGetNameItinerary = async () => {
    let itinerary = document.getElementById("itineraryId");
    let name = itinerary.options[itinerary.selectedIndex].text;
    setItineraryDays(parseInt(name.substr(0, 2), 10));
    if (cruise_id) {
      var d = new Date(startDate);
      var nd = new Date(
        d.setDate(d.getDate() + parseInt(name.substr(0, 2), 10))
      );
      var options = { year: "numeric", month: "2-digit", day: "2-digit" };
      setEndDate(
        nd.toLocaleDateString("es", options).split("/").reverse().join("-")
      );
    }
  };

  const handleEndDate = (e) => {
    var d = new Date(e);
    var nd = new Date(d.setDate(d.getDate() + itineraryDays));
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    setEndDate(
      nd.toLocaleDateString("es", options).split("/").reverse().join("-")
    );
  };

  if (
    cruiseState.message === "resource created successfully" ||
    cruiseState.message === "resource updated successfully"
  ) {
    history.goBack();
  }

  const goBack = (e) => {
    history.goBack();
  }

  return (
    <>
      <div className="ui breadcrumb">
        <Link to="/">
          <div className="active section">{t("cruise_tiptop")}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">{t("cruise_path")}</div>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <label>{t("general_information_tag")}</label>
          <Divider variant="fullWidth" />
          <br />

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("cruise_code")} *</label>
              <input placeholder={t("cruise_code")} name="code" autoFocus defaultValue={cruiseCustomState.cruise.code} ref={register({ required: true })} />
              {errors.code && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field>
              <label>{t("cruise_name")} *</label>
              <input placeholder={t("cruise_name")} name="name" defaultValue={cruiseCustomState.cruise.name} ref={register({ required: true })} />
              {errors.name && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("cruise_yacht")}*</label>
              <select aria-expanded="true" name="yacht_id" defaultValue={cruiseCustomState.cruise.yacht_id} onChange={(event) => { getItineraries(event.target.value); }} ref={register({ required: true })}>
                <option></option>
                {cruiseCustomState.yachts.map((yacht) => (
                  <option key={yacht.id} value={yacht.id}>
                    {yacht.name}
                  </option>
                ))}
              </select>
              {errors.yacht_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field>
              <label>{t("cruise_itinerary")}*{itineraryByYachtState.fetching === true ? (<CircularProgress size={17} />) : ("")}</label>
              <select aria-expanded="true" id="itineraryId" name="itinerary_id" value={itinerary ? itinerary : cruiseCustomState.cruise.itinerary_id}
                onChange={(event) => {
                  handleItinerary(event.target.value);
                  handleGetNameItinerary();
                }}
                ref={register({ required: true })}
              >
                <option></option>
                {itineraryByYachtState.itineraries.map((itinerary) => (
                  <option key={itinerary.id} value={itinerary.itinerary_id}>
                    {itinerary.name}
                  </option>
                ))}
              </select>

              {errors.itinerary_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("cruise_captain")}*</label>
              <select aria-expanded="true" name="captain_id" defaultValue={cruiseCustomState.cruise.captain_id} ref={register({ required: true })}>
                <option></option>
                {cruiseCustomState.captains.map((captain) => (
                  <option key={captain.id} value={captain.id}>
                    {captain.first_name} {captain.last_name}
                  </option>
                ))}
              </select>
              {errors.captain_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field>
              <label>{t("cruise_hotelier")}*</label>

              <select aria-expanded="true" name="hotelier_id" defaultValue={cruiseCustomState.cruise.hotelier_id} ref={register({ required: true })}>
                <option></option>
                {cruiseCustomState.hoteliers.map((hotelier) => (
                  <option key={hotelier.id} value={hotelier.id}>
                    {hotelier.first_name} {hotelier.last_name}
                  </option>
                ))}
              </select>
              {errors.hotelier_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("cruise_startDate")}{" "}<span className="text_date_format">(dd-mm-yyyy)</span>*</label>
              <input type="date" name="start_date" value={startDate} ref={register({ required: true })}
                onChange={(e) => {
                  handleEndDate(e.target.value);
                  setStartDate(e.target.value);
                }}
              />
              {errors.start_date && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field>
              <label>{t("cruise_endDate")}{" "}<span className="text_date_format">(dd-mm-yyyy)</span>*</label>
              <input type="date" name="end_date" value={endDate} onChange={(e) => setEndDate(e.target.value)} ref={register({ required: true })} />
              {errors.end_date && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <label>{t("special_rate")}*</label>
              <select aria-expanded="true" name="special_rate_id" defaultValue={cruiseCustomState.cruise.special_rate_id} ref={register()}>
                <option></option>
                {cruiseCustomState.rates.map((rate) => (
                  <option className="selected item" key={rate.id} value={rate.id}>{rate.name}</option>
                ))}
              </select>
              {errors.special_rate_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("cruise_notes")} </label>
              <textarea rows={3} placeholder={t("cruise_note_placeholder")} name="notes" ref={register} defaultValue={cruiseCustomState.cruise.notes}></textarea>
            </Form.Field>
          </Form.Group>

          <Divider variant="middle" />
          <br></br>

          <button type="button" className="ui button" onClick={(e) => { goBack(); }}>{t("canceled_button")}</button>

          <button
            type="submit"
            className={
              cruiseCustomState.processing
                ? "ui loading primary button"
                : "ui primary button"
            }
          >
            {t("save_button")}
          </button>
        </div>
      </Form>
    </>
  );
}
