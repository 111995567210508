import CaptainList from "../containers/catalogs/captain/CaptainList";
import CaptainForm from "../containers/catalogs/captain/CaptainForm";

const routes = [
  {
    path: "/captains",
    component: CaptainList,
  },
  {
    path: "/captains_new",
    component: CaptainForm,
  },
  {
    path: "/captains/:captain_id",
    component: CaptainForm,
  },
];

export { routes as captainRoutes };
