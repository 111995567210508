import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { findContractRateByCruiseAgencyContractType, initialStateContractRateByCruiseAgencyContractType, } from "../../redux/actions/rates/contractRates/findContractRateByCruiseAgencyCruiseType"

//UI
import { Loader } from "semantic-ui-react";

//Components
import ContractRatePopup from "../../components/rates/contractRatePopup"

export default function ContractCheckBox(props) {

  const { t } = useTranslation();
  const { register } = useForm();
	const contractRateState = useSelector((state) => state.contractRateReducer);
	const dispatch = useDispatch();

  useEffect(() => {
    var payload = new Object();
    payload.cruise_type = props.cruise_type;
    payload.agency_id = props.agency_id;
    dispatch(findContractRateByCruiseAgencyContractType(props.cruise_id, payload));

		return () => {
			dispatch(initialStateContractRateByCruiseAgencyContractType());
		}
  }, []);
    
  return (
		<>
			{contractRateState.fetching === true ? (
      	<Loader active inline />
    	) : (
				Object.entries(contractRateState.contractRate).length > 0 ? (
				  <>
      		  <label>{t("contract_contract")}
        		  {<ContractRatePopup charter_client={contractRateState.contractRate.charter_client} 
																double_client={contractRateState.contractRate.double_client} 
																single_client={contractRateState.contractRate.single_client} 
																airplane_ticket={contractRateState.contractRate.airplane_ticket} />}
      		  </label>
      		  <div className="ui checkbox">
        	    {
					      <input  type="checkbox" 
						            name="contract_rate" 
						            defaultChecked={props.contract_rate === 1} 
						            ref={register} 
                        onChange={(event) => {props.handleChange(event.target.checked);}}
					      /> 
				      }
        	    <label></label>
      		  </div>
				  </>
				) : (
          null
        )
			)}
		</>
  );
}