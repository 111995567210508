import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class CruiseRateService {
  static getCruiseRates(cruise_id) {
    return axios.get("cruise/" + cruise_id + "/rates");
  }
  static getCruiseRate(cruise_id, id) {
    return axios.get("cruise/" + cruise_id + "/rate/" + id);
  }

  static postCruiseRate(cruise_id, payload) {
    return axios.post("cruise/" + cruise_id + "/rates", payload);
  }

  static putCruiseRate(cruise_id, id, payload) {
    return axios.put(
      "cruise/" + cruise_id + "/rate/" + id,
      payload
    );
  }

  static deleteCruiseRate(cruise_id, id) {
    return axios.delete("cruise/" + cruise_id + "/rate/" + id);
  }
}
