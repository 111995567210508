import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//UI
import { Form, Message } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../styles/common.css";
import "semantic-ui-css/semantic.min.css";
//ACTIONS
import { createReservation } from "../../../../redux/actions/reservations/reservation/createReservation";
import { updateReservation } from "../../../../redux/actions/reservations/reservation/updateReservation";
import { findContractRateByCruiseAgencyContractType, initialStateContractRateByCruiseAgencyContractType, } from "../../../../redux/actions/rates/contractRates/findContractRateByCruiseAgencyCruiseType";
import { findIkarusRateByCruiseAgencyContractType, initialStateIkarusRateByCruiseAgencyContractType, } from "../../../../redux/actions/rates/ikarusRates/findIkarusRateByCruiseAgencyCruiseType";
import { fetchReservationFormCustom, initialStateReservationFormCustom } from "../../../../redux/slices/custom/reservations/cruise/reservation/customReservationForm.slice";
//Components
import ContractCheckBox from "../../../../components/reservation/contractCheckBox"
import IkarusRateCheckBox from "../../../../components/reservation/ikarusRateCheckBox"
import { reservationStates, cruiseTypes, salesReport, } from "../../../resources/optionsList";
import HeadDetail from "../../../../components/headDetail";
import { scrollToTop } from "../../../resources/utils";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "10%",
    marginTop: -12,
    marginLeft: -82,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

export default function ReservationForm() {
  let reserveStates = [
    reservationStates.RESERVED,
    reservationStates.CONFIRMED,
    reservationStates.WAITING,
  ];
  let cruiserTypes = [
    cruiseTypes.FIRSTPART,
    cruiseTypes.SECONDPART,
    cruiseTypes.TOTAL,
  ];
  let history = useHistory();
  let { cruise_id } = useParams();
  let { reservation_id } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const { register, handleSubmit, errors, getValues } = useForm();
  const reservationStateCustom = useSelector((state) => state.customReservationForm);
  const reservationState = useSelector((state) => state.reservationReducer);
  const contractRateState = useSelector((state) => state.contractRateReducer);

  const [disabled, setDisabled] = useState(true);
  const [disabledTimeLimit, setDisabledTimeLimit] = useState(true);
  const [charterState, setCharterState] = useState();
  const [charterTimeLimit, setCharterTimeLimit] = useState();
  const [active, setActive] = useState();
  const [disabledActive, setDisabledActive] = useState();
  const [contract, setContract] = useState();
  const [ikarusRate, setIkarusRate] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReservationFormCustom(" ", cruise_id, reservation_id))
    scrollToTop();
    return(()=>{
      dispatch(initialStateReservationFormCustom());
    })
  }, []);

  if (reservationStateCustom.fetching || reservationStateCustom.fetching ) {
    return <CircularProgress />;
  }

  const handleDisable = (e) => {
    if (e === true) {
      setDisabled(false);
      setDisabledTimeLimit(true);
    } else {
      setDisabled(true);
      setCharterState("");
      setCharterTimeLimit("");
      document.getElementById("charterState").selectedIndex = 0;
      document.getElementById("charterTimeLimit").value = "";
    }
  };

  function calculateTimeLimitDays() {
    var currentDate = new Date();
    var startCruiseDateArray = reservationStateCustom.cruise.start_date_format.split("/");
    var startCruiseDate = new Date(startCruiseDateArray[1] + "/" + startCruiseDateArray[0] + "/" + startCruiseDateArray[2]);
    var differenceInTime = startCruiseDate.getTime() - currentDate.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);
    
    if (differenceInDays < 60) {
      return 3;
    }
    return 7;
  }

  const handleDisableTimeLimit = (state) => {
    if (state === "reserved") {
      setDisabledTimeLimit(false);
      var currentDate = new Date();
      var timeLimitDate = new Date(currentDate.setDate(currentDate.getDate() + calculateTimeLimitDays()));
      var options = { year: "numeric", month: "2-digit", day: "2-digit" };
      document.getElementById("charterTimeLimit").value = timeLimitDate.toLocaleDateString("es", options).split("/").reverse().join("-");

    } else {
      setDisabledTimeLimit(true);
      setCharterTimeLimit("");
      document.getElementById("charterTimeLimit").value = "";
    }
  };

  const handleFindContractRate = (event) => {
    let agencyId = getValues("agency_id")
    let cruiseType = getValues("cruise_type");

    if (agencyId !== "" && cruiseType !== "") {
      var payload = new Object();
      payload.cruise_type = cruiseType; 
      payload.agency_id = agencyId;
      dispatch(findContractRateByCruiseAgencyContractType(cruise_id, payload));
      dispatch(findIkarusRateByCruiseAgencyContractType(cruise_id, payload));
    } else {
      dispatch(initialStateContractRateByCruiseAgencyContractType());
      dispatch(initialStateIkarusRateByCruiseAgencyContractType());
    }
  };

  const handleChange = (event) => {
    setContract(event);
  };

  const handleIkarusRateChange = (event) => {
    setIkarusRate(event);
  };

  const onSubmit = (data) => {
    data["contract_rate"] = contract;
    data["ikarus_rate"] = ikarusRate;
    if (reservation_id) {
      data["cruise_id"] = cruise_id;
      dispatch(updateReservation(reservation_id, data));
    } else {
      dispatch(createReservation(cruise_id, data));
    }
  };

  if (reservationState.message === "resource created successfully" || reservationState.message === "resource updated successfully") {
     history.go(-1);
  }

  if (reservationStateCustom.verifySpacesByReservation === "this reservation can´t be changed" && disabledActive === undefined) {
    setDisabledActive(true);
  }

  if (reservationStateCustom.reservation.charter && active === undefined) {
    setActive(Boolean(reservationStateCustom.reservation.charter));
  }

  if (reservationStateCustom.reservation.charter_state && charterState === undefined) {
    setCharterState(reservationStateCustom.reservation.charter_state);
  }

  if (reservationStateCustom.reservation.charter_time_limit && charterTimeLimit === undefined) {
    setCharterTimeLimit(reservationStateCustom.reservation.charter_time_limit);
  }

  if (reservationStateCustom.reservation.charter && active === undefined && disabled === true && disabledTimeLimit === true) {
    setActive(Boolean(reservationStateCustom.reservation.charter));
    setDisabled(false);
    setDisabledTimeLimit(true);
  }

  return (
    <>
      {reservationStateCustom.error ? (
        <Message negative content={t(reservationStateCustom.error)} />
      ) : (
        ""
      )}
      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{reservationStateCustom.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <Link
          to={`/cruises/${
            cruise_id ? cruise_id : reservationStateCustom.reservation.cruise_id
          }/reservations`}
        >
          <div className="active section">{t("reservation_title")} </div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">{t("reservation_path")} </div>
      </div>
      <br />
      <br />
      <br />
      <HeadDetail
        yacht_name={reservationStateCustom.cruise.yacht_name}
        itinerary_name={reservationStateCustom.cruise.itinerary_name}
        start_date={reservationStateCustom.cruise.start_date_format}
        end_date={reservationStateCustom.cruise.end_date_format}
      />

      <br />
      <br />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <label>{t("general_information_tag")} </label>
          <br />
          <Divider variant="fullWidth" />
          <br />
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("reservation_agency")} *</label>
              <select
                aria-expanded="true"
                name="agency_id"
                defaultValue={reservationStateCustom.reservation.agency_id}
                onChange={(event) => {handleFindContractRate(event.target.value);}}
                ref={register({ required: true })}
              >
                <option></option>
                {reservationStateCustom.agencies.map((agency) => (
                  <option
                    className="selected item"
                    key={agency.id}
                    value={agency.id}
                  >
                    {agency.name}
                  </option>
                ))}
              </select>
              {errors.agency_id && (
                <span className="error_message">
                  {t("required_information")}{" "}
                </span>
              )}
            </Form.Field>
            <Form.Field>
              <label>{t("reservation_type")} *</label>
              {reservationStateCustom.checkItineraryDivision === "itinerary divided" ? (
                <select
                  aria-expanded="true"
                  name="cruise_type"
                  defaultValue={reservationStateCustom.reservation.cruise_type}
                  onChange={(event) => {handleFindContractRate(event.target.value);}}
                  ref={register({ required: true })}
                >
                  <option></option>
                  {cruiserTypes.map((cruiseType, index) => (
                    <option
                      className="selected item"
                      key={index}
                      value={cruiseType.value}
                    >
                      {t(cruiseType.name)}
                    </option>
                  ))}
                </select>
              ) : reservationStateCustom.checkItineraryDivision === "itinerary not divided" ? (
                <select
                  aria-expanded="true"
                  name="cruise_type"
                  defaultValue={reservationStateCustom.reservation.cruise_type}
                  onChange={(event) => {handleFindContractRate(event.target.value);}}
                  ref={register({ required: true })}
                >
                  <option></option>

                  <option
                    className="selected item"
                    value={cruiserTypes[2].value}
                  >
                    {cruiserTypes[2].value}
                  </option>
                </select>
              ) : (
                ""
              )}
              {errors.cruise_type && (
                <span className="error_message">
                  {t("required_information")}{" "}
                </span>
              )}
            </Form.Field>
            <Form.Field>
              <ContractCheckBox cruise_id={reservationStateCustom.reservation.cruise_id} 
                                cruise_type={reservationStateCustom.reservation.cruise_type} 
                                agency_id={reservationStateCustom.reservation.agency_id}
                                contract_rate={reservationStateCustom.reservation.contract_rate}
                                handleChange={handleChange}
              />

              <IkarusRateCheckBox cruise_id={reservationStateCustom.reservation.cruise_id} 
                                  cruise_type={reservationStateCustom.reservation.cruise_type} 
                                  agency_id={reservationStateCustom.reservation.agency_id}
                                  ikarus_rate={reservationStateCustom.reservation.ikarus_rate}
                                  handleIkarusRateChange={handleIkarusRateChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("reservation_notes")} </label>
              <textarea
                rows={3}
                placeholder={t("reservation_notes")}
                name="notes"
                ref={register}
                defaultValue={reservationStateCustom.reservation.notes}
              ></textarea>
            </Form.Field>
          </Form.Group>

          <br />
          <label>{t("reservation_charter_tag")} </label>
          <br />
          <Divider variant="fullWidth" />
          <br />
          <Form.Group widths="equal">
            <Form.Field width={4}>
              <label>{t("reservation_charter")} </label>
              <div className="ui checkbox">
                <input
                  disabled={disabledActive}
                  type="checkbox"
                  name="charter"
                  ref={register}
                  defaultChecked={active}
                  onChange={(e) => {
                    handleDisable(e.target.checked);
                    setActive(e.target.checked);
                  }}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>
                {active === true
                  ? t("reservation_charterState") + " *"
                  : t("reservation_charterState")}
              </label>

              <select
                id="charterState"
                disabled={
                  reservation_id &&
                  reservationStateCustom.reservation.charter_state === "confirmed"
                    ? true
                    : active === true
                    ? false
                    : true
                }
                aria-expanded="true"
                name="charter_state"
                defaultValue={charterState}
                ref={register(
                  active === true ? { required: true } : { required: false }
                )}
                onChange={(e) => {
                  setCharterState(e.target.value);
                  handleDisableTimeLimit(e.target.value);
                }}
              >
                <option></option>
                {reserveStates.map((reserveState, index) => (
                  <option
                    className="selected item"
                    key={index}
                    value={reserveState.value}
                  >
                    {t(reserveState.name)}
                  </option>
                ))}
              </select>

              {active === true && errors.charter_state && (
                <span className="error_message">
                  {t("required_information")}{" "}
                </span>
              )}
            </Form.Field>

            <Form.Field>
              <label>
                {charterState === "reserved"
                  ? t("reservation_timeLimit") + " *"
                  : t("reservation_timeLimit")}
              </label>
              <input
                id="charterTimeLimit"
                disabled={charterState === "reserved" ? false : true}
                type="date"
                name="charter_time_limit"
                defaultValue={charterTimeLimit}
                ref={register(
                  charterState === "reserved"
                    ? {
                        required: true,
                      }
                    : {
                        required: false,
                      }
                )}
              />
              {charterState === "reserved"
                ? errors.charter_time_limit && (
                    <span className="error_message">
                      {t("required_information")}{" "}
                    </span>
                  )
                : ""}
            </Form.Field>

          </Form.Group>
          <br />
          <label>{t("reservation_salesReport")} </label>
          <br />
          <Divider variant="fullWidth" />
          <br />
          <Form.Group widths="equal">
            <Form.Field>
              <label>120 {t("reservation_days")} </label>
              <select
                aria-expanded="true"
                name="salesReport120Days"
                defaultValue={reservationStateCustom.reservation.salesReport120Days}
                ref={register}
              >
                <option></option>
                {salesReport.map((day, index) => (
                  <option className="selected item" key={index} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </Form.Field>

            <Form.Field>
              <label>90 {t("reservation_days")}</label>
              <select
                aria-expanded="true"
                name="salesReport90Days"
                defaultValue={reservationStateCustom.reservation.salesReport90Days}
                ref={register}
              >
                <option></option>
                {salesReport.map((day, index) => (
                  <option className="selected item" key={index} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </Form.Field>

            <Form.Field>
              <label>60 {t("reservation_days")}</label>
              <select
                aria-expanded="true"
                name="salesReport60Days"
                defaultValue={reservationStateCustom.reservation.salesReport60Days}
                ref={register}
              >
                <option></option>
                {salesReport.map((day, index) => (
                  <option className="selected item" key={index} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </Form.Field>
          </Form.Group>

          <br></br>
          <Divider variant="middle" />
          <br></br>

          <Link to={`/cruises/${cruise_id}/reservations`}>
            <button type="button" className="ui button">
              {t("canceled_button")}
            </button>
          </Link>

          <button
            type="submit"
            className={
              reservationStateCustom.processing
                ? "ui loading primary button"
                : "ui primary button"
            }
          >
            {t("save_button")}
          </button>
        </div>
      </Form>
    </>
  );
}
