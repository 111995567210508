import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customIkarusRateCabinList = createSlice({
  name: 'customIkarusRateCabinList',
  initialState: {
    ikarusRateCabins: [],
    ikarusRate: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateIkarusRateCabinListCustom(state, action) {
      return {
        ikarusRateCabins: [],
        ikarusRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesIkarusRateCabinListCustom(state, action) {
      return {
        ikarusRateCabins: [],
        ikarusRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchIkarusRateCabinListCustomSuccess(state, action) {
      return {
        ikarusRateCabins: action.payload.cabins,
        ikarusRate: action.payload.ikarusRate,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchIkarusRateCabinListCustomError(state, action) {
      return {
        ikarusRateCabins: [],
        ikarusRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchIkarusRateCabinListCustom = (ikarusRate_id) => dispatch => {
  dispatch(requesIkarusRateCabinListCustom());
  axios.get(`/ikarusRate/${ikarusRate_id}/ikarusRateCabinList`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchIkarusRateCabinListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchIkarusRateCabinListCustom(ikarusRate_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchIkarusRateCabinListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateIkarusRateCabinListCustom,
  requesIkarusRateCabinListCustom,
  fetchIkarusRateCabinListCustomSuccess,
  fetchIkarusRateCabinListCustomError,
} = customIkarusRateCabinList.actions;

export default customIkarusRateCabinList.reducer;