
import React from "react";
import { useTranslation } from "react-i18next";
import { List, Header } from "semantic-ui-react";

export default function IkarusRateCell(props) {
    const { t } = useTranslation();
    return (
      <>
        <Header size='tiny' color='blue'>{t("low_season")}</Header>
        <List horizontal divided relaxed>
          <List.Item>
            <List.Content >
              <List.Header >{props.passenger_low_season}</List.Header>
              <List.Description>{t("passenger")}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>{props.operator_low_season}</List.Header>
              <List.Description>{t("airline")}</List.Description>
            </List.Content>
          </List.Item>
        </List>
        <Header size='tiny' color='blue'>{t("high_season")}</Header>
        <List horizontal divided relaxed>
          <List.Item>
            <List.Content >
              <List.Header >{props.passenger_high_season}</List.Header>
              <List.Description>{t("passenger")}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              <List.Header>{props.operator_high_season}</List.Header>
              <List.Description>{t("airline")}</List.Description>
            </List.Content>
          </List.Item>
        </List>
      </>
    );
  }
  