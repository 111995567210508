import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customFamilyRoomForm = createSlice({
  name: 'customFamilyRoomForm',
  initialState: {
    rates: [],
    yachts: [],
    days: [],
    familyRoom: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateFamilyRoomFormCustom(state, action) {
      return {
        rates: [],
        yachts: [],
        days: [],
        familyRoom: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesFamilyRoomFormCustom(state, action) {
      return {
        rates: [],
        yachts: [],
        days: [],
        familyRoom: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchFamilyRoomFormCustomSuccess(state, action) {
      return {
        rates: action.payload.rates,
        yachts: action.payload.yachts,
        days: action.payload.days,
        familyRoom: action.payload.familyRoom,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchFamilyRoomFormCustomError(state, action) {
      return {
        rates: [],
        yachts: [],
        days: [],
        familyRoom: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchFamilyRoomFormCustom = (familyRoom_id) => dispatch => {
  dispatch(requesFamilyRoomFormCustom());
  axios.get(`/familyRooms/form/familyRoomForm?familyRoom_id=${familyRoom_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchFamilyRoomFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchFamilyRoomFormCustom(familyRoom_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchFamilyRoomFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateFamilyRoomFormCustom,
  requesFamilyRoomFormCustom,
  fetchFamilyRoomFormCustomSuccess,
  fetchFamilyRoomFormCustomError,
} = customFamilyRoomForm.actions;

export default customFamilyRoomForm.reducer;