import {
  INITIAL_STATE_INGALA_REPORT,
  REQUEST_GENERATE_INGALA_REPORT,
  GENERATE_INGALA_REPORT_SUCCESS,
  GENERATE_INGALA_REPORT_ERROR,
} from "../../../actions/reservations/ingala/generateIngalaReport";

const initialState = {
  data: File,
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const ingalaReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_INGALA_REPORT: {
      return {
        ...state,
        data: "",
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_GENERATE_INGALA_REPORT: {
      return {
        ...state,
        data: "",
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case GENERATE_INGALA_REPORT_SUCCESS: {
      return {
        ...state,
        data: action.data,
        processing: false,
        fetching: false,
        message: "data send succesfully",
        error: "",
      };
    }
    case GENERATE_INGALA_REPORT_ERROR: {
      return {
        ...state,
        data: "",
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default ingalaReportReducer;
