import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ContractRateService from "../../../../services/rates/ContractRateService";

export const REQUEST_FETCH_CONTRACT_RATES = "REQUEST_FETCH_CONTRACT_RATES";
export const FETCH_CONTRACT_RATES_SUCCESS = "FETCH_CONTRACT_RATES_SUCCESS";
export const FETCH_CONTRACT_RATES_ERROR = "FETCH_CONTRACT_RATES_ERROR";

function requestFetchContractRates() {
  return {
    type: REQUEST_FETCH_CONTRACT_RATES,
  };
}

function fetchContractRatesSuccess(json) {
  return {
    type: FETCH_CONTRACT_RATES_SUCCESS,
    contracts: json.data,
  };
}

function fetchContractRatesError(json) {
  return {
    type: FETCH_CONTRACT_RATES_ERROR,
    error: json.data,
  };
}

export function fetchContractRates(payload) {
  return (dispatch) => {
    dispatch(requestFetchContractRates());
    return ContractRateService.getContracts(payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchContractRatesSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchContractRates(payload));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchContractRatesError(json.data));
        }
      }
    );
  };
}
