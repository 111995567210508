import {
  REQUEST_FETCH_CRUISES,
  FETCH_CRUISES_SUCCESS,
  FETCH_CRUISES_ERROR,
} from "../../../actions/reservations/cruise/fetchCruises";

import {
  INITIAL_STATE_CRUISE,
  REQUEST_FETCH_CRUISE,
  FETCH_CRUISE_SUCCESS,
  FETCH_CRUISE_ERROR,
} from "../../../actions/reservations/cruise/fetchCruise";

import {
  REQUEST_CREATE_CRUISE,
  CREATE_CRUISE_SUCCESS,
  CREATE_CRUISE_ERROR,
} from "../../../actions/reservations/cruise/createCruise";

import {
  REQUEST_UPDATE_CRUISE,
  UPDATE_CRUISE_SUCCESS,
  UPDATE_CRUISE_ERROR,
} from "../../../actions/reservations/cruise/updateCruise";

import {
  REQUEST_DELETE_CRUISE,
  DELETE_CRUISE_SUCCESS,
  DELETE_CRUISE_ERROR,
} from "../../../actions/reservations/cruise/deleteCruise";

import {
  REQUEST_FETCH_YEARS,
  FETCH_YEARS_SUCCESS,
  FETCH_YEARS_ERROR,
} from "../../../actions/reservations/cruise/fetchYears";

import {
  REQUEST_UPDATE_CRUISE_NOTE,
  UPDATE_CRUISE_NOTE_SUCCESS,
  UPDATE_CRUISE_NOTE_ERROR,
} from "../../../actions/reservations/cruise/updateCruiseNote";

const initialState = {
  cruises: [],
  cruise: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const cruiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_CRUISE: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_CRUISES: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISES_SUCCESS: {
      return {
        ...state,
        cruises: action.cruises.cruises,
        cruise: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISES_ERROR: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_CRUISE: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISE_SUCCESS: {
      return {
        ...state,
        cruises: [],
        cruise: action.cruise,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISE_ERROR: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_CRUISE: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_CRUISE_SUCCESS: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_CRUISE_ERROR: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_CRUISE: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_CRUISE_SUCCESS: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_CRUISE_ERROR: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_CRUISE: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_CRUISE_SUCCESS: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_CRUISE_ERROR: {
      return {
        ...state,
        cruises: [],
        cruise: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_YEARS: {
      return {
        ...state,
        years: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_SUCCESS: {
      return {
        ...state,
        years: action.years,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_ERROR: {
      return {
        ...state,
        years: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_CRUISE_NOTE: {
      return {
        ...state,
        processing: true,
        message: "",
        error: "",
      };
    }
    case UPDATE_CRUISE_NOTE_SUCCESS: {
      return {
        ...state,
        processing: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_CRUISE_NOTE_ERROR: {
      return {
        ...state,
        processing: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default cruiseReducer;
