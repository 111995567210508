import IngalaService from "../../../../services/reservations/IngalaService";

export const REQUEST_GENERATE_INGALA_REPORT = "REQUEST_GENERATE_INGALA_REPORT";
export const GENERATE_INGALA_REPORT_SUCCESS = "GENERATE_INGALA_REPORT_SUCCESS";
export const GENERATE_INGALA_REPORT_ERROR = "GENERATE_INGALA_REPORT_ERROR";
export const INITIAL_STATE_INGALA_REPORT = "INITIAL_STATE_INGALA_REPORT";

function requestGenerateIngalaReport() {
  return {
    type: REQUEST_GENERATE_INGALA_REPORT,
  };
}

function generateIngalaReportSuccess(json) {
  return {
    type: GENERATE_INGALA_REPORT_SUCCESS,
    data: json,
  };
}

function generateIngalaReportError(json) {
  return {
    type: GENERATE_INGALA_REPORT_ERROR,
    error: json.data,
  };
}

export function initialStateIngalaReport() {
  return {
    type: INITIAL_STATE_INGALA_REPORT,
  };
}

export function generateIngalaReport(cruise_id, payload) {
  return (dispatch) => {
    dispatch(requestGenerateIngalaReport());
    return IngalaService.getIngalaReport(cruise_id, payload).then((json) => {
      if (json.status === 200) {
        dispatch(generateIngalaReportSuccess(json.config.url));
      } else {
        dispatch(generateIngalaReportError(json.data));
      }
    });
  };
}
