import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
//UI
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Form, Message } from "semantic-ui-react";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import HeadDetail from "../../../../components/headDetail";
import HeadDetailReservation from "../../../../components/headDetailReservation";
import "../../../styles/common.css";
import "semantic-ui-css/semantic.min.css";
//ACTIONS
import { createSpaceFlight } from "../../../../redux/actions/reservations/spaceFlight/createSpaceFlight";
import { updateSpaceFlight } from "../../../../redux/actions/reservations/spaceFlight/updateSpaceFlight";
import { initialStateSpaceFlight, } from "../../../../redux/actions/reservations/spaceFlight/fetchSpaceFlight";
import { fetchSpaceFlightFormCustom, initialStateSpaceFlightFormCustom } from "../../../../redux/slices/custom/reservations/cruise/spaceFlight/customSpaceFlightForm.slice";
//RESOURCE
import { scrollToTop } from "../../../resources/utils";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "10%",
    marginTop: -12,
    marginLeft: -82,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

export default function SpaceFlightForm() {
  let history = useHistory();
  let { cruise_id } = useParams();
  let { reservation_id } = useParams();
  let { booking_id } = useParams();
  let { space_id } = useParams();
  let { space_flight_id } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const spaceFlightStateCustom = useSelector((state) => state.customSpaceFlightForm);
  const spaceFlightState = useSelector((state) => state.spaceFlightReducer);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [messageDate, setMessageDate] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSpaceFlightFormCustom("", space_id, cruise_id, reservation_id, booking_id, space_flight_id))
    scrollToTop();
    return () => {
      dispatch(initialStateSpaceFlightFormCustom());
      dispatch(initialStateSpaceFlight());
    };
  }, [
    dispatch,
    cruise_id,
    space_flight_id,
    reservation_id,
    space_id,
    booking_id,
  ]);

  if (spaceFlightStateCustom.fetching || spaceFlightState.fetching ) {
    return <CircularProgress />;
  }

  const onSubmit = (data) => {
    if (space_flight_id) {
      dispatch(updateSpaceFlight(space_flight_id, data));
    } else if (space_id) {
      dispatch(createSpaceFlight(space_id, data));
    }
  };

  if (
    spaceFlightState.message === "resource created successfully" ||
    spaceFlightState.message === "resource updated successfully"
  ) {
    history.go(-1);
  }

  if (spaceFlightStateCustom.cruise.start_date_format && startDate === undefined) {
    setStartDate(spaceFlightStateCustom.cruise.start_date_flight);
  }

  if (spaceFlightStateCustom.cruise.end_date_format && startDate === undefined) {
    setEndDate(spaceFlightStateCustom.cruise.end_date_flight);
  }

  const handleChangeDate = (event)=>{
     var selectedDate = new Date(event.target.value);
     var startDate = new Date(spaceFlightStateCustom.cruise.start_date_flight);
     var endDate = new Date(spaceFlightStateCustom.cruise.end_date_flight);
     startDate.setDate(startDate.getDate() + 1);
     endDate.setDate(endDate.getDate() - 1);
     if (selectedDate >= startDate && selectedDate <= endDate) {
      setMessageDate(true)        
      event.target.value = spaceFlightStateCustom.cruise.start_date_flight; 
     } else {
      setMessageDate(false)  
     }
  }

  return (
    <>
      {spaceFlightStateCustom.error ? (
        <Message negative content={t(spaceFlightStateCustom.error)} />
      ) : (
        ""
      )}

      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{spaceFlightStateCustom.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">
          <Link to={`/cruises/${cruise_id}/reservations`}>
            {t("reservation_title")}
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">
          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings`}
          >
            {t("cabin_title")}
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">
          {t("cabin_cabin")} {spaceFlightStateCustom.booking.cabin_name}
        </div>
        <div className="divider">/</div>
        <div className="active section">
          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces`}
          >
            {t("space_title")}
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">
          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces/${space_id}/spaceFlights`}
          >
            {t("space_flight_title")}
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">{t("space_flight_path")}</div>
      </div>
      <br />
      <br />

      <HeadDetail
        yacht_name={spaceFlightStateCustom.cruise.yacht_name}
        itinerary_name={spaceFlightStateCustom.cruise.itinerary_name}
        start_date={spaceFlightStateCustom.cruise.start_date_format}
        end_date={spaceFlightStateCustom.cruise.end_date_format}
      />
      <HeadDetailReservation
        reservation_date={spaceFlightStateCustom.reservation.creation_date}
        reservation_agency={spaceFlightStateCustom.reservation.agency_name}
        reservation_executive={spaceFlightStateCustom.reservation.user}
        reservation_type={spaceFlightStateCustom.reservation.cruise_type}
      />
      <br />
      <br />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <label>{t("general_information_tag")}</label>
          <br />
          <Divider variant="fullWidth" />
          <br />

          <Form.Group widths="equal">
            <Form.Field width={3}>
              <label>{t("space_flight_ticket")} </label>
              <div className="ui checkbox">
                <input
                  autoFocus
                  type="checkbox"
                  name="ticket"
                  ref={register}
                  defaultChecked={spaceFlightStateCustom.spaceFlight.ticket === 1}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>{t("space_flight_path")}*</label>
              <select
                aria-expanded="true"
                name="flight_id"
                defaultValue={spaceFlightStateCustom.spaceFlight.flight_id}
                ref={register({ required: true })}
              >
                <option></option>
                {spaceFlightStateCustom.flights.map((flight) => (
                  <option
                    className="selected item"
                    key={flight.id}
                    value={flight.id}
                  >
                    {flight.code}
                  </option>
                ))}
              </select>
              {errors.flight_id && (
                <span className="error_message">
                  {t("required_information")}{" "}
                </span>
              )}
            </Form.Field>

            <Form.Field>
              <label>{t("route_name")}*</label>
              <select
                aria-expanded="true"
                name="route_id"
                defaultValue={spaceFlightStateCustom.spaceFlight.route_id}
                ref={register({ required: true })}
              >
                <option></option>
                {spaceFlightStateCustom.routes.map((route) => (
                  <option
                    className="selected item"
                    key={route.id}
                    value={route.id}
                  >
                    {route.origin_city}({route.origin_code}) -{" "}
                    {route.destination_city}({route.destination_code})
                  </option>
                ))}
              </select>
              {errors.route_id && (
                <span className="error_message">
                  {t("required_information")}{" "}
                </span>
              )}
            </Form.Field>

            <Form.Field>
              <label>
                {t("space_flight_date")}{" "}
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </label>
                <input
                  type="datetime-local"
                  name="date"
                  onChange={handleChangeDate}
                  defaultValue={
                    spaceFlightStateCustom.spaceFlight.date ? spaceFlightStateCustom.spaceFlight.date :
                    spaceFlightStateCustom.numberFlights.numberFlights > 0 ? endDate : startDate
                  }
                  ref={register}
                />
                {messageDate ?
                  <span className="error_message">
                    {t("spaceFlight_message_date")}
                  </span> : ''
                }
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("space_flight_note")}</label>
              <textarea
                rows={3}
                placeholder={t("space_flight_note")}
                name="notes"
                ref={register}
                defaultValue={spaceFlightStateCustom.spaceFlight.notes}
              ></textarea>
              {errors.notes && (
                <span className="error_message">
                  {t("required_information")}{" "}
                </span>
              )}
            </Form.Field>
          </Form.Group>

          <br></br>
          <Divider variant="middle" />
          <br></br>

          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces/${space_id}/spaceFlights`}
          >
            <button type="button" className="ui button">
              {t("canceled_button")}
            </button>
          </Link>

          <button
            type="submit"
            className={
              spaceFlightStateCustom.processing
                ? "ui loading primary button"
                : "ui primary button"
            }
          >
            {t("save_button")}
          </button>
        </div>
      </Form>
    </>
  );
}
