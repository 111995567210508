import {
  REQUEST_FETCH_IDENTIFICATION_TYPES,
  FETCH_IDENTIFICATION_TYPES_SUCCESS,
  FETCH_IDENTIFICATION_TYPES_ERROR,
} from "../../../actions/catalogs/identificationType/fetchIdentificationTypes";

import {
  INITIAL_STATE_IDENTIFICATION_TYPE,
  REQUEST_FETCH_IDENTIFICATION_TYPE,
  FETCH_IDENTIFICATION_TYPE_SUCCESS,
  FETCH_IDENTIFICATION_TYPE_ERROR,
} from "../../../actions/catalogs/identificationType/fetchIdentificationType";

import {
  REQUEST_CREATE_IDENTIFICATION_TYPE,
  CREATE_IDENTIFICATION_TYPE_SUCCESS,
  CREATE_IDENTIFICATION_TYPE_ERROR,
} from "../../../actions/catalogs/identificationType/createIdentificationType";

import {
  REQUEST_UPDATE_IDENTIFICATION_TYPE,
  UPDATE_IDENTIFICATION_TYPE_SUCCESS,
  UPDATE_IDENTIFICATION_TYPE_ERROR,
} from "../../../actions/catalogs/identificationType/updateIdentificationType";

import {
  REQUEST_DELETE_IDENTIFICATION_TYPE,
  DELETE_IDENTIFICATION_TYPE_SUCCESS,
  DELETE_IDENTIFICATION_TYPE_ERROR,
} from "../../../actions/catalogs/identificationType/deleteIdentificationType";

const initialState = {
  identificationTypes: [],
  identificationType: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const identificationTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_IDENTIFICATION_TYPE: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_IDENTIFICATION_TYPES: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_IDENTIFICATION_TYPES_SUCCESS: {
      return {
        ...state,
        identificationTypes: action.identificationTypes,
        identificationType: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_IDENTIFICATION_TYPES_ERROR: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_IDENTIFICATION_TYPE: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_IDENTIFICATION_TYPE_SUCCESS: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: action.identificationType,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_IDENTIFICATION_TYPE_ERROR: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_IDENTIFICATION_TYPE: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_IDENTIFICATION_TYPE_SUCCESS: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_IDENTIFICATION_TYPE_ERROR: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_IDENTIFICATION_TYPE: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_IDENTIFICATION_TYPE_SUCCESS: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_IDENTIFICATION_TYPE_ERROR: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_IDENTIFICATION_TYPE: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_IDENTIFICATION_TYPE_SUCCESS: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_IDENTIFICATION_TYPE_ERROR: {
      return {
        ...state,
        identificationTypes: [],
        identificationType: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default identificationTypeReducer;
