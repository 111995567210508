import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideOnBoardService from "../../../../services/reservations/GuideOnBoardService";

export const REQUEST_UPDATE_GUIDE_ON_BOARD = "REQUEST_UPDATE_GUIDE_ON_BOARD";
export const UPDATE_GUIDE_ON_BOARD_SUCCESS = "UPDATE_GUIDE_ON_BOARD_SUCCESS";
export const UPDATE_GUIDE_ON_BOARD_ERROR = "UPDATE_GUIDE_ON_BOARD_ERROR";

function requestUpdateGuideOnBoard() {
  return {
    type: REQUEST_UPDATE_GUIDE_ON_BOARD,
  };
}

function updateGuideOnBoardSuccess(json) {
  return {
    type: UPDATE_GUIDE_ON_BOARD_SUCCESS,
    message: json.data,
  };
}

function updateGuideOnBoardError(json) {
  return {
    type: UPDATE_GUIDE_ON_BOARD_ERROR,
    error: json.data,
  };
}

export function updateGuideOnBoard(cruise_id, id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateGuideOnBoard());
    return GuideOnBoardService.putGuideOnBoard(cruise_id, id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(updateGuideOnBoardSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(updateGuideOnBoard(cruise_id, id, payload));
        } else if (json.data.code === 498) {
          logOut()
        } else {
          dispatch(updateGuideOnBoardError(json.data));
        }
      }
    );
  };
}
