import CabinList from "../containers/catalogs/cabin/CabinList";
import CabinForm from "../containers/catalogs/cabin/CabinForm";

const routes = [
  {
    path: "/yacht/:yacht_id/cabins",
    component: CabinList,
  },
  {
    path: "/yacht/:yacht_id/cabins_new",
    component: CabinForm,
  },
  {
    path: "/yacht/:yacht_id/cabin/:cabin_id",
    component: CabinForm,
  },
];

export { routes as cabinRoutes };
