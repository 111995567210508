import {
  REQUEST_FETCH_VERIFY_PASSENGER,
  FETCH_VERIFY_PASSENGER_SUCCESS,
  FETCH_VERIFY_PASSENGER_ERROR,
  INITIAL_STATE_VERIFY_PASSENGER,
} from "../../../actions/reservations/spacePassenger/verifyPassenger";

const initialState = {
  passenger: {},
  fetching: false,
  message: "",
  error: "",
};

const verifyPassengerReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_VERIFY_PASSENGER: {
      return {
        ...state,
        passenger: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_VERIFY_PASSENGER: {
      return {
        ...state,
        passenger: {},
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_VERIFY_PASSENGER_SUCCESS: {
      return {
        ...state,
        passenger: action.passenger,
        fetching: false,
        message: "passenger exist",
        error: "",
      };
    }
    case FETCH_VERIFY_PASSENGER_ERROR: {
      return {
        ...state,
        passenger: {},
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default verifyPassengerReducer;
