import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ContractRateService from "../../../../services/rates/ContractRateService";

export const REQUEST_UPDATE_CONTRACT_RATE = "REQUEST_UPDATE_CONTRACT_RATE";
export const UPDATE_CONTRACT_RATE_SUCCESS = "UPDATE_CONTRACT_RATE_SUCCESS";
export const UPDATE_CONTRACT_RATE_ERROR = "UPDATE_CONTRACT_RATE_ERROR";

function requestUpdateContractRate() {
  return {
    type: REQUEST_UPDATE_CONTRACT_RATE,
  };
}

function updateContractRateSuccess(json) {
  return {
    type: UPDATE_CONTRACT_RATE_SUCCESS,
    message: json.data,
  };
}

function updateContractRateError(json) {
  return {
    type: UPDATE_CONTRACT_RATE_ERROR,
    error: json.data,
  };
}

export function updateContractRate(contract_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateContractRate());
    return ContractRateService.putContract(contract_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateContractRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateContractRate(contract_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateContractRateError(json.data));
      }
    });
  };
}
