import DayList from "../containers/catalogs/day/DayList";
import DayForm from "../containers/catalogs/day/DayForm";

const routes = [
  {
    path: "/itinerary/:itinerary_id/days",
    component: DayList,
  },
  {
    path: "/itinerary/:itinerary_id/days_new",
    component: DayForm,
  },
  {
    path: "/itinerary/:itinerary_id/day/:day_id",
    component: DayForm,
  },
];

export { routes as dayRoutes };
