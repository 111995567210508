import AdvanceCruiseList from "../containers/reservations/advanceCruise/AdvanceCruiseList";

const routes = [
  {
    path: "/advanceReport",
    component: AdvanceCruiseList,
  },
];

export { routes as advanceCruiseRoutes };
