import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Icon, Form, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";
import TicketRateCell from "../../../components/rates/ticketRateCell"
import StateRateCell from "../../../components/rates/stateRateCell"
import ChangeStateRateModal from "../../../components/rates/changeStateRateModal"

//Actions
import { deleteTicket } from "../../../redux/actions/rates/ticket/deleteTicket";
import { initialStateTicket } from "../../../redux/actions/rates/ticket/fetchTicket";
import { fetchTicketListCustom, initialStateTicketListCustom } from "../../../redux/slices/custom/rates/ticket/customTicketList.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function TicketList() {
  const { t } = useTranslation();
  let actualDate = new Date();
  let currentYear = actualDate.getFullYear();
  let airline = "";

  const ticketRateFilters = JSON.parse(localStorage.getItem("ticketRateFilters"));
  if (ticketRateFilters) {
    currentYear = ticketRateFilters.year;
    airline = ticketRateFilters.airline;
  }

  const { register, handleSubmit, errors, getValues } = useForm();

  const ticketStateCustom = useSelector((state) => state.customTicketList);
  const ticketState = useSelector((state) => state.ticketReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTicketListCustom({year: currentYear, airline_id: airline}));
    scrollToTop();
    return () => {
      dispatch(initialStateTicket());
      dispatch(initialStateTicketListCustom());
    };
  }, []);

  if (ticketStateCustom.fetching || ticketStateCustom.fetching) {
    return <Loader active inline />;
  }

  if (ticketStateCustom.error) {
    return <div>Error: {ticketStateCustom.error}</div>;
  }

  if (ticketState.message === "resource deleted successfully") {
    dispatch(fetchTicketListCustom({year: getValues("year"), airline_id: getValues("airline_id")}));
    dispatch(initialStateTicket());
  }

  if (ticketState.message === "resource updated successfully") {
    dispatch(fetchTicketListCustom({year: getValues("year"), airline_id: getValues("airline_id")}));
    dispatch(initialStateTicket());
  }

  const handleDeleteTicket = (ticket_id) => {
    dispatch(deleteTicket(ticket_id));
  };

  const onSubmit = (data) => {
    let ticketRateFilters = {
      year: getValues("year"),
      airline: getValues("airline_id"),
    };
    localStorage.setItem("ticketRateFilters", JSON.stringify(ticketRateFilters));
    dispatch(fetchTicketListCustom(data));
  };

  return (
    <>
      <HeaderCatalog title={t("ticket_title")} message={ticketStateCustom.message} to="/tickets/tickets_new" />
      <Divider />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("ticket_year")}</label>
            <select name="year" aria-expanded="true" defaultValue={currentYear} ref={register({ required: true })}>
              {ticketStateCustom.years.map((year, index) => (
                <option key={index} value={year.year}>{year.year}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("flight_airline")} *</label>
            <select name="airline_id" defaultValue={airline} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {ticketStateCustom.airlines.map((airline) => (
                <option key={airline.id} value={airline.id}>{airline.name}</option>
              ))}
            </select>
            {errors.airline_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>&nbsp;</label>
            <Button type="submit" loading={ticketStateCustom.fetching ? true : false} color="green"><Icon name='search' />{t("search_button")}</Button>
          </Form.Field>
        </Form.Group>
      </Form>

      {ticketStateCustom.tickets !== undefined ? (
        <Table color="blue" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>{t("ticket_year")}</Table.HeaderCell>
              <Table.HeaderCell>{t("ticket_route")}</Table.HeaderCell>
              <Table.HeaderCell>{t("ticket_foreingAdult")}</Table.HeaderCell>
              <Table.HeaderCell>{t("ticket_foreingChild")}</Table.HeaderCell>
              <Table.HeaderCell>{t("ticket_nationalAdult")}</Table.HeaderCell>
              <Table.HeaderCell>{t("ticket_nationalChild")}</Table.HeaderCell>
              <Table.HeaderCell>{t("ticket_colonistAdult")}</Table.HeaderCell>
              <Table.HeaderCell>{t("state")}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {ticketStateCustom.tickets.map((ticket) => (
            <Table.Row key={ticket.id}>
              <Table.Cell collapsing><ActiveIcon active={ticket.active} /></Table.Cell>
              <Table.Cell>{ticket.year}</Table.Cell>
              <Table.Cell>{ticket.departure_route} <br></br> {ticket.return_route}</Table.Cell>
              <Table.Cell>
                <TicketRateCell 
                  passenger_low_season={ticket.passengerPrice_foreingAdult_lowSeason}
                  operator_low_season={ticket.airlinePrice_foreingAdult_lowSeason} 
                  passenger_high_season={ticket.passengerPrice_foreingAdult_highSeason} 
                  operator_high_season={ticket.airlinePrice_foreingAdult_highSeason} />
              </Table.Cell>
              <Table.Cell>
                <TicketRateCell 
                  passenger_low_season={ticket.passengerPrice_foreingChild_lowSeason}
                  operator_low_season={ticket.airlinePrice_foreingChild_lowSeason} 
                  passenger_high_season={ticket.passengerPrice_foreingChild_highSeason} 
                  operator_high_season={ticket.airlinePrice_foreingChild_highSeason} />
              </Table.Cell>
              <Table.Cell>
                <TicketRateCell 
                  passenger_low_season={ticket.passengerPrice_nationalAdult_lowSeason}
                  operator_low_season={ticket.airlinePrice_nationalAdult_lowSeason} 
                  passenger_high_season={ticket.passengerPrice_nationalAdult_highSeason} 
                  operator_high_season={ticket.airlinePrice_nationalAdult_highSeason} />
              </Table.Cell>
              <Table.Cell>
                <TicketRateCell 
                  passenger_low_season={ticket.passengerPrice_nationalChild_lowSeason}
                  operator_low_season={ticket.airlinePrice_nationalChild_lowSeason} 
                  passenger_high_season={ticket.passengerPrice_nationalChild_highSeason} 
                  operator_high_season={ticket.airlinePrice_nationalChild_highSeason} />
              </Table.Cell>
              <Table.Cell>
                <TicketRateCell 
                  passenger_low_season={ticket.passengerPrice_colonistAdult_lowSeason}
                  operator_low_season={ticket.airlinePrice_colonistAdult_lowSeason} 
                  passenger_high_season={ticket.passengerPrice_colonistAdult_highSeason} 
                  operator_high_season={ticket.airlinePrice_colonistAdult_highSeason} />
              </Table.Cell>
              <Table.Cell width="2"><StateRateCell activation_date={ticket.activation_date} inactivation_date={ticket.inactivation_date} reason_deactivation={ticket.reason_deactivation} /></Table.Cell>
              <Table.Cell collapsing>
                {ticket.active != false ? (
                  <ChangeStateRateModal stateRate={ticket} typeRate="TicketRate" />
                ):(
                  null
                )}
                <Link to={`/tickets/${ticket.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
                <Modal
                  closeOnDimmerClick={false}
                  trigger={
                    <Button circular color='red' icon='trash alternate' />
                  }
                  content={t("ticket_delete")}
                  actions={[
                    t("canceled_button"),
                      {
                        key: "eliminar",
                        content: t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handleDeleteTicket(ticket.id),
                      },
                  ]}
                  size="tiny"
                />
              </Table.Cell>
            </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        ""
      )}
    </>
  );
}
