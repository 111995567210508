import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CruiseService from "../../../../services/reservations/CruiseService";

export const REQUEST_UPDATE_CRUISE_NOTE = "REQUEST_UPDATE_CRUISE_NOTE";
export const UPDATE_CRUISE_NOTE_SUCCESS = "UPDATE_CRUISE_NOTE_SUCCESS";
export const UPDATE_CRUISE_NOTE_ERROR = "UPDATE_CRUISE_NOTE_ERROR";

function requestUpdateCruiseNote() {
  return {
    type: REQUEST_UPDATE_CRUISE_NOTE,
  };
}

function updateCruiseNoteSuccess(json) {
  return {
    type: UPDATE_CRUISE_NOTE_SUCCESS,
    message: json.data,
  };
}

function updateCruiseNoteError(json) {
  return {
    type: UPDATE_CRUISE_NOTE_ERROR,
    error: json.data,
  };
}

export function updateCruiseNote(cruise_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateCruiseNote());
    return CruiseService.putCruiseNotes(cruise_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateCruiseNoteSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateCruiseNote(cruise_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateCruiseNoteError(json.data));
      }
    });
  };
}
