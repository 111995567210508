import {
  INITIAL_STATE_PASSENGER_SELECTED,
  REQUEST_FETCH_PASSENGERS_SELECTED,
  FETCH_PASSENGERS_SELECTED_SUCCESS,
  FETCH_PASSENGERS_SELECTED_ERROR,
} from "../../../actions/reservations/passenger/fetchPassengersSelected";

const initialState = {
  flightGroups: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const passengerSelectedReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_PASSENGER_SELECTED: {
      return {
        ...state,
        flightGroups: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_PASSENGERS_SELECTED: {
      return {
        ...state,
        flightGroups: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_PASSENGERS_SELECTED_SUCCESS: {
      return {
        ...state,
        flightGroups: action.flightGroups,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_PASSENGERS_SELECTED_ERROR: {
      return {
        ...state,
        flightGroups: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default passengerSelectedReducer;
