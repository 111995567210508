import {
    REQUEST_FETCH_CABINS_BY_YACHT,
    FETCH_CABINS_BY_YACHT_SUCCESS,
    FETCH_CABINS_BY_YACHT_ERROR,
  } from "../../../actions/catalogs/cabinByYacht/fetchCabinsByYacht";
  
  const initialState = {
    cabins: [],
    fetching: false,
    message: "",
    error: "",
  };
  
  const cabinByYachtReducer = (state = initialState, action) => {
    switch (action.type) {
      case REQUEST_FETCH_CABINS_BY_YACHT: {
        return {
          ...state,
          cabins: [],
          fetching: true,
          message: "",
          error: "",
        };
      }
      case FETCH_CABINS_BY_YACHT_SUCCESS: {
        return {
          ...state,
          cabins: action.cabins,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case FETCH_CABINS_BY_YACHT_ERROR: {
        return {
          ...state,
          cabins: [],
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      default: {
        return state;
      }
    }
  };
  export default cabinByYachtReducer;
  