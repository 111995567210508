import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class ReservationService {
  static getReservations(cruise_id) {
    return axios.get("cruises/" + cruise_id + "/reservations");
  }

  static getReservation(reservation_id) {
    return axios.get("reservation/" + reservation_id);
  }

  static postReservation(cruise_id, payload) {
    return axios.post(
      "cruises/" + cruise_id + "/reservations",
      payload
    );
  }

  static putReservation(reservation_id, payload) {
    return axios.put("reservation/" + reservation_id, payload);
  }

  static putReservationCanceled(reservation_id, payload) {
    return axios.put(
      "reservation/" + reservation_id + "/canceled",
      payload
    );
  }

  static getAvailableCabinsByReservation(cruise_id, reservation_id) {
    return axios.get(
      API_BASE_URL +
        "cruises/" +
        cruise_id +
        "/reservation/" +
        reservation_id +
        "/cabinsAvailable"
    );
  }

  static verifySpacesByReservation(reservation_id) {
    return axios.get(
      "reservation/" + reservation_id + "/verifySpaces"
    );
  }

  static getItemsByField(cruise_id) {
    return axios.get("reservation/logs/" + cruise_id);
}
}
