import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GeneralRateService from "../../../../services/rates/GeneralRateService";

export const REQUEST_CREATE_GENERAL_RATE = "REQUEST_CREATE_GENERAL_RATE";
export const CREATE_GENERAL_RATE_SUCCESS = "CREATE_GENERAL_RATE_SUCCESS";
export const CREATE_GENERAL_RATE_ERROR = "CREATE_GENERAL_RATE_ERROR";

function requestCreateGeneralRate() {
  return {
    type: REQUEST_CREATE_GENERAL_RATE,
  };
}

function createGeneralRateSuccess(json) {
  return {
    type: CREATE_GENERAL_RATE_SUCCESS,
    message: json.data,
  };
}

function createGeneralRateError(json) {
  return {
    type: CREATE_GENERAL_RATE_ERROR,
    error: json.data,
  };
}

export function createGeneralRate(payload) {
  return (dispatch) => {
    dispatch(requestCreateGeneralRate());
    return GeneralRateService.postGeneralRate(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createGeneralRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createGeneralRate(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createGeneralRateError(json.data));
      }
    });
  };
}
