import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import NoteService from "../../../../services/catalogs/NoteService";

export const REQUEST_FETCH_NOTES = "REQUEST_FETCH_NOTES";
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";
export const FETCH_NOTES_ERROR = "FETCH_NOTES_ERROR";

function requestFetchNotes() {
  return {
    type: REQUEST_FETCH_NOTES,
  };
}

function fetchNotesSuccess(json) {
  return {
    type: FETCH_NOTES_SUCCESS,
    notes: json.data,
  };
}

function fetchNotesError(json) {
  return {
    type: FETCH_NOTES_ERROR,
    error: json.data,
  };
}

export function fetchNotes(searchWord) {
  return (dispatch) => {
    dispatch(requestFetchNotes());
    return NoteService.getNotes(searchWord).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchNotesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchNotes(searchWord));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchNotesError(json.data));
      }
    });
  };
}
