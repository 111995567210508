import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FamilyRoomService from "../../../../services/rates/FamilyRoomService";

export const REQUEST_DELETE_FAMILY_ROOM = "REQUEST_DELETE_FAMILY_ROOM";
export const DELETE_FAMILY_ROOM_SUCCESS = "DELETE_FAMILY_ROOM_SUCCESS";
export const DELETE_FAMILY_ROOM_ERROR = "DELETE_FAMILY_ROOM_ERROR";

function requestDeleteFamilyRoom() {
  return {
    type: REQUEST_DELETE_FAMILY_ROOM,
  };
}

function deleteFamilyRoomSuccess(json) {
  return {
    type: DELETE_FAMILY_ROOM_SUCCESS,
    message: json.data,
  };
}

function deleteFamilyRoomError(json) {
  return {
    type: DELETE_FAMILY_ROOM_ERROR,
    error: json.data,
  };
}

export function deleteFamilyRoom(family_room_id) {
  return (dispatch) => {
    dispatch(requestDeleteFamilyRoom());
    return FamilyRoomService.deleteFamilyRoom(family_room_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteFamilyRoomSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteFamilyRoom(family_room_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteFamilyRoomError(json.data));
      }
    });
  };
}
