import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Loader, Button, Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createCompanyUser } from "../../../redux/actions/catalogs/user/createUser";
import { initialStateUser } from "../../../redux/actions/catalogs/user/fetchUser";
import { updateCompanyUser } from "../../../redux/actions/catalogs/user/updateUser";
import { fetchCompanyUserFormCustom, initialStateCompanyUserFormCustom} from "../../../redux/slices/custom/catalogs/user/customCompanyUserForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function UserForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { user_id } = useParams();
  const { register, handleSubmit, errors } = useForm();

  const userStateCustom = useSelector((state) => state.  customCompanyUserForm);
  const userState = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(fetchCompanyUserFormCustom(user_id));
    scrollToTop();
    return () => {
      dispatch(initialStateCompanyUserFormCustom());
      dispatch(initialStateUser());
    };
  }, []);

  if (userStateCustom.fetching || userState.fetching) {
    return <Loader active inline />;
  }

  if (userStateCustom.error) {
    return <div>Error: {userStateCustom.error}</div>;
  }

  const onSubmit = (data) => {
    if (user_id) {
      dispatch(updateCompanyUser(user_id, data));
    } else {
      dispatch(createCompanyUser(data));
    }
  };

  if (userState.message === "resource created successfully" || userState.message === "resource updated successfully") {
    history.push(`/users`);
  }

  let roles = userStateCustom.roles.filter(
    (role) => role.name != "agency"
  );

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/users" className="section">{t("user_title")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("user_user")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />
     
      <Form onSubmit={handleSubmit(onSubmit)}>   
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("user_name")}</label>
            <input name="first_name" defaultValue={userStateCustom.user.first_name} placeholder={t("user_name")} ref={register({ required: true })} autoFocus />
            {errors.first_name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("user_lastName")}</label>
            <input name="last_name" defaultValue={userStateCustom.user.last_name} placeholder={t("user_lastName")} ref={register({ required: true })} />
            {errors.last_name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("user_email")}</label>
            <input name="email" defaultValue={userStateCustom.user.email}  placeholder={t("user_email")} disabled={userStateCustom.emailDisabledField}
              ref={register({
                required: (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                ),
                pattern: {
                  value: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: (
                    <span className="error_message">{t("user_error")}</span>
                  ),
                },
              })}
            />
            {errors.email && errors.email.message}
          </Form.Field>
        </Form.Group>
        
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("user_cellphone")}</label>
            <input name="cellular_phone" defaultValue={userStateCustom.user.cellular_phone} placeholder={t("user_cellphone")} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("user_phone")}</label>
            <input name="phone" defaultValue={userStateCustom.user.phone} placeholder={t("user_phone")} ref={register} />
          </Form.Field>
          <Form.Field required>
            <label>{t("user_role")}</label>
            <select name="role_id" defaultValue={userStateCustom.user.role_id} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>{role.name}</option>
              ))}
            </select>
            {errors.role_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>
        
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("active_tag")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" defaultChecked={userStateCustom.user.active === 1}  ref={register} />
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />
        
        <Button primary type="submit" loading={userStateCustom.processing ? true : false}>{t("save_button")}</Button>
        <Link to={`/users`}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
