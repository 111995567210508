
import React from "react";
import { useTranslation } from "react-i18next";
import { Popup, Grid, Icon, List, Header, Table, StateCell } from "semantic-ui-react";

export default function DetailRatePopup(props) {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <>
      <Popup trigger={<Icon name='info' color='red' size='small' circular />} flowing hoverable>
        <Table color="blue" striped>
          <Table.Header>
            <Table.Row align="center">
            <Table.HeaderCell>Cabina<br/><br/></Table.HeaderCell>
              <Table.HeaderCell>Crucero<br/><br/></Table.HeaderCell>
              <Table.HeaderCell>Comisión<br/><br/></Table.HeaderCell>
              <Table.HeaderCell>Vuelo<br/><br/></Table.HeaderCell>
              <Table.HeaderCell>Descuento<br/>Adicional</Table.HeaderCell>
              <Table.HeaderCell>Total<br/><br/></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {props.spaces.map((space) => (
            <Table.Row align="center" key={space.id}>
              <Table.Cell><b><div style={{color:"#1261A0"}}>{space.cabin_name}</div></b></Table.Cell>
              <Table.Cell>$ {space.price}<br/><span className="text_date_format">{space.rate}</span></Table.Cell>
              <Table.Cell>- $ {space.commission_price}<br/><span className="text_date_format">{space.commission_percentage}%</span></Table.Cell>
              <Table.Cell>$ {space.flight_price}<br/><span className="text_date_format">{space.flight_price_include_ticket ? "Valor incluído en tarifa" : ""}</span></Table.Cell>
              <Table.Cell>- $ {space.special_discount_price}</Table.Cell>
              <Table.Cell align="left"><b><div>$ {space.total_price}</div></b><span className="text_date_format">Operadora: $ {space.operator_price}</span></Table.Cell>
            </Table.Row>
          ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row align="center">
              <Table.HeaderCell><b>TOTAL:</b></Table.HeaderCell>  
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell><b><div style={{color:"#1261A0"}}>$ {props.client_price}</div></b><span className="text_date_format">Operadora: $ {props.operator_price}</span></Table.HeaderCell>  
            </Table.Row>
          </Table.Footer>
         
        </Table>
      </Popup>
    </>
  );
}