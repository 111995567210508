import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CaptainService from "../../../../services/catalogs/CaptainService";

export const REQUEST_CREATE_CAPTAIN = "REQUEST_CREATE_CAPTAIN";
export const CREATE_CAPTAIN_SUCCESS = "CREATE_CAPTAIN_SUCCESS";
export const CREATE_CAPTAIN_ERROR = "CREATE_CAPTAIN_ERROR";

function requestCreateCaptain() {
  return {
    type: REQUEST_CREATE_CAPTAIN,
  };
}

function createCaptainSuccess(json) {
  return {
    type: CREATE_CAPTAIN_SUCCESS,
    message: json.data,
  };
}

function createCaptainError(json) {
  return {
    type: CREATE_CAPTAIN_ERROR,
    error: json.data,
  };
}

export function createCaptain(payload) {
  return (dispatch) => {
    dispatch(requestCreateCaptain());
    return CaptainService.postCaptain(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createCaptainSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createCaptain(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createCaptainError(json.data));
      }
    });
  };
}
