// React
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

// Actions
import { initialStateSpace } from "../../../../redux/actions/reservations/space/fetchSpace";
import { fetchSpaceLogs } from "../../../../redux/actions/reservations/space/fetchSpacesLogs";
import { fetchSpaceListCustom, initialStateSpaceListCustom } from "../../../../redux/slices/custom/reservations/cruise/space/customSpaceList.slice";

// Components
import HeaderCatalog from "../../../../components/headerCatalog";
import HeadDetail from "../../../../components/headDetail";
import HeadDetailReservation from "../../../../components/headDetailReservation";
import PassengerCell from "../../../../components/space/passengerCell";
import PassengerCellCanceledSpace from "../../../../components/space/passengerCellCanceledSpace";
import FlightCell from "../../../../components/space/flightCell";
import FlightCellCanceledSpace from "../../../../components/space/flightCellCanceledSpace";
import DiscountCell from "../../../../components/space/discountCell";
import StateCell from "../../../../components/space/stateCell";
import CancelSpaceModal from "../../../../components/space/cancelSpaceModal";
import LogsHistory from "../../../../components/reservation/logsHistory";

// UI
import { Table, Loader, Button, Icon, Breadcrumb, Divider, Accordion } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

// Resources
import { scrollToTop } from "../../../resources/utils";
import { Collapse } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

export default function SpaceList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openHistory, setOpenHistory] = useState(false);
  let { cruise_id } = useParams();
  let { reservation_id } = useParams();
  let { booking_id } = useParams();
  
  const spaceStateCustom = useSelector((state) => state.customSpaceList)
  const spaceState = useSelector((state) => state.spaceReducer);
  const spaceLogState = useSelector((state) => state.spaceLogReducer)

  const user = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    dispatch(fetchSpaceListCustom(booking_id, cruise_id, reservation_id));
    scrollToTop();
    return () => {
      dispatch(initialStateSpaceListCustom());
    };
  }, []);

  if (spaceState.fetching || spaceStateCustom.fetching) {
    return <Loader active inline />;
  }

  if (spaceStateCustom.processing) {
    return <Loader active inline />;
  }

  if (spaceStateCustom.error) {
    return <div>Error: {spaceStateCustom.error}</div>;
  }

  if (spaceState.message === "resource canceled successfully") {
    dispatch(fetchSpaceListCustom(booking_id, cruise_id, reservation_id));
    dispatch(fetchSpaceLogs(booking_id));
    dispatch(initialStateSpace());
  }

  if (spaceState.message === "resource deleted successfully") {
    dispatch(fetchSpaceListCustom(booking_id, cruise_id, reservation_id));
    dispatch(fetchSpaceLogs(booking_id));
    dispatch(initialStateSpace());
  }

  if (spaceState.message === "resource updated successfully") {
    dispatch(fetchSpaceListCustom(booking_id, cruise_id, reservation_id));
    dispatch(fetchSpaceLogs(booking_id));
    dispatch(initialStateSpace());
  }

  const handleHistoyClick = () => {
    setOpenHistory(!openHistory);
    if (!openHistory ) {
      dispatch(fetchSpaceLogs(booking_id));
    } 
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/cruise-detail`} className="section">{spaceStateCustom.cruise.code}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/reservations`} className="section"> {t("reservation_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings`} className="section">{t("cabin_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active> {t("cabin_cabin")} {spaceStateCustom.booking.cabin_name}</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active> {t("space_title")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <HeadDetail yacht_name={spaceStateCustom.cruise.yacht_name} itinerary_name={spaceStateCustom.cruise.itinerary_name} start_date={spaceStateCustom.cruise.start_date_format} end_date={spaceStateCustom.cruise.end_date_format}/>
      <HeadDetailReservation reservation_date={spaceStateCustom.reservation.creation_date} reservation_agency={spaceStateCustom.reservation.agency_name} reservation_executive={spaceStateCustom.reservation.user} reservation_type={spaceStateCustom.reservation.cruise_type}/>

      <br></br>
        
      <HeaderCatalog title={t("space_title")} message={spaceStateCustom.message} to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces_new`} />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row align="center">
            <Table.HeaderCell>{t("space_code")}<br/></Table.HeaderCell>
            <Table.HeaderCell>{t("space_executive")}<br/></Table.HeaderCell>
            <Table.HeaderCell>{t("space_creation_date")}<br/><span className="text_date_format">(dd-mm-yyyy)</span></Table.HeaderCell>
            <Table.HeaderCell>{t("space_state")}<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>{t("space_passenger")}<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>{t("space_flight")}<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>Crucero<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>Comisión<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>Vuelo<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>Descuento<br/>Adicional</Table.HeaderCell>
            <Table.HeaderCell>Total<br/><br/></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {spaceStateCustom.spaces.map((space) => (
          <Table.Row align="center" key={space.id}>
            <Table.Cell>{space.id}</Table.Cell>
            <Table.Cell>{space.user}</Table.Cell>
            <Table.Cell>{space.creation_date}</Table.Cell>
            <Table.Cell><StateCell space={space} /></Table.Cell>
            <Table.Cell><PassengerCell space={space} cruise_id={cruise_id} reservation_id={reservation_id} booking_id={booking_id} /></Table.Cell>
            <Table.Cell><FlightCell space={space} cruise_id={cruise_id} reservation_id={reservation_id} booking_id={booking_id} /></Table.Cell>
            <Table.Cell>$ {space.price}<br/><span className="text_date_format">{space.rate}</span></Table.Cell>
            <Table.Cell>- $ {space.commission_price}<br/><span className="text_date_format">{space.commission_percentage}%</span></Table.Cell>
            <Table.Cell>$ {space.flight_price}<br/><span className="text_date_format">{space.flight_price_include_ticket ? "Valor incluído en tarifa" : ""}</span></Table.Cell>
            <Table.Cell><DiscountCell space={space} user={user} /></Table.Cell>
            <Table.Cell align="left"><b><div style={{color:"#1261A0"}}>$ {space.total_price}</div></b><span className="text_date_format">Operadora: $ {space.operator_price}</span></Table.Cell>
            <Table.Cell>
              <Link to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces/${space.id}`}>
                <Button className="edit_button" icon>
                  <Icon className="icon_color" name="pencil alternate" />
                </Button>
              </Link>
              <CancelSpaceModal space={space} />
            </Table.Cell>
          </Table.Row>
        ))}
        </Table.Body>
      </Table>
      
      <span style={{fontWeight: "bold", fontSize: "18px", color: "red"}}>{t("space_canceled")}</span>
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row align="center">
            <Table.HeaderCell>{t("space_code")}<br/></Table.HeaderCell>
            <Table.HeaderCell>{t("space_executive")}<br/></Table.HeaderCell>
            <Table.HeaderCell>{t("space_creation_date")}<br/><span className="text_date_format">(dd-mm-yyyy)</span></Table.HeaderCell>
            <Table.HeaderCell>{t("space_state")}<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>{t("space_cancelation_date")}<br/><span className="text_date_format">(dd-mm-yyyy)</span></Table.HeaderCell>
            <Table.HeaderCell>{t("space_passenger")}<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>{t("space_flight")}<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>Crucero<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>Comisión<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>Vuelo<br/><br/></Table.HeaderCell>
            <Table.HeaderCell>Descuento<br/>Adicional</Table.HeaderCell>
            <Table.HeaderCell>Total<br/><br/></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {spaceStateCustom.spacesCanceled.map((space) => (
          <Table.Body>
            <Table.Row align="center">
              <Table.Cell>{space.id}</Table.Cell>
              <Table.Cell>{space.user}</Table.Cell>
              <Table.Cell>{space.creation_date}</Table.Cell>
              <Table.Cell><StateCell space={space} /></Table.Cell>
              <Table.Cell>{space.date_cancellation}</Table.Cell>
              <Table.Cell><PassengerCellCanceledSpace space={space} cruise_id={cruise_id} reservation_id={reservation_id} booking_id={booking_id} /></Table.Cell>
              <Table.Cell><FlightCellCanceledSpace space={space} cruise_id={cruise_id} reservation_id={reservation_id} booking_id={booking_id} /></Table.Cell>
              <Table.Cell>$ {space.price}<br/><span className="text_date_format">{space.rate}</span></Table.Cell>
              <Table.Cell>- $ {space.commission_price}<br/><span className="text_date_format">{space.commission_percentage}%</span></Table.Cell>
              <Table.Cell>$ {space.flight_price}<br/><span className="text_date_format">{space.flight_price_include_ticket ? "Valor incluído en tarifa" : ""}</span></Table.Cell>
              <Table.Cell>{space.special_discount_price}</Table.Cell>
              <Table.Cell><b><div style={{color:"#1261A0"}}>$ {space.total_price}</div></b><span className="text_date_format">Operadora: $ {space.operator_price}</span></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell colSpan="1" warning><b>{t("space_cancelation_reason")} :</b></Table.Cell>
              <Table.Cell colSpan="11" warning>{space.reason_cancellation}</Table.Cell>
            </Table.Row>
          </Table.Body>
        ))}
      </Table>
      <Accordion styled style={{ width: "91vw" }}>
        <Accordion.Title onClick={handleHistoyClick} style={{ display: "flex", gap: "5px" }}>
          {t("log_historical")}
          {openHistory ? <ExpandLess /> : <ExpandMore />}
        </Accordion.Title>
        {spaceLogState.fetching  ? (
          <div style={{ display: "flex", width: "91vw", height: "80px", justifyContent: "center" }}>
            <Loader size="medium" active inline>Loading...</Loader>
          </div>
        ) : (
          <Collapse in={openHistory} timeout="auto" unmountOnExit style={{ padding: "15px" }}>
            <LogsHistory
              logsHistory={spaceLogState.logs}
            />
          </Collapse>
        )}
      </Accordion>
    </>
  );
}
