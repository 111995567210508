import {
    REQUEST_FETCH_IKARUS_RATES,
    FETCH_IKARUS_RATES_SUCCESS,
    FETCH_IKARUS_RATES_ERROR,
  } from "../../../actions/rates/ikarusRates/fetchIkarusRates";

  import {
    INITIAL_STATE_IKARUS_RATE,
    REQUEST_FETCH_IKARUS_RATE,
    FETCH_IKARUS_RATE_SUCCESS,
    FETCH_IKARUS_RATE_ERROR,
  } from "../../../actions/rates/ikarusRates/fetchIkarusRate";

  import {
    REQUEST_CREATE_IKARUS_RATE,
    CREATE_IKARUS_RATE_SUCCESS,
    CREATE_IKARUS_RATE_ERROR,
  } from "../../../actions/rates/ikarusRates/createIkarusRate";

  import {
    REQUEST_UPDATE_IKARUS_RATE,
    UPDATE_IKARUS_RATE_SUCCESS,
    UPDATE_IKARUS_RATE_ERROR,
  } from "../../../actions/rates/ikarusRates/updateIkarusRate";

  import {
    REQUEST_ACTIVE_IKARUS_RATE,
    ACTIVE_IKARUS_RATE_SUCCESS,
    ACTIVE_IKARUS_RATE_ERROR,
  } from "../../../actions/rates/ikarusRates/activeIkarusRate";
  
  import {
    REQUEST_INACTIVE_IKARUS_RATE,
    INACTIVE_IKARUS_RATE_SUCCESS,
    INACTIVE_IKARUS_RATE_ERROR,
  } from "../../../actions/rates/ikarusRates/inactiveIkarusRate";

  import {
    REQUEST_DELETE_IKARUS_RATE,
    DELETE_IKARUS_RATE_SUCCESS,
    DELETE_IKARUS_RATE_ERROR,
  } from "../../../actions/rates/ikarusRates/deleteIkarusRate";

  import {
    REQUEST_FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE,
    FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE_SUCCESS,
    FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE_ERROR,
    INITIAL_STATE_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE,
  } from "../../../actions/rates/ikarusRates/findIkarusRateByCruiseAgencyCruiseType";
  
  const initialState = {
    ikarusRates: [],
    ikarusRate: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  };
  
  const ikarusRateReducer = (state = initialState, action) => {
    switch (action.type) {
      case INITIAL_STATE_IKARUS_RATE: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case REQUEST_FETCH_IKARUS_RATES: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: true,
          message: "",
          error: "",
        };
      }
      case FETCH_IKARUS_RATES_SUCCESS: {
        return {
          ...state,
          ikarusRates: action.ikarusRates,
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case FETCH_IKARUS_RATES_ERROR: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      case REQUEST_FETCH_IKARUS_RATE: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: true,
          message: "",
          error: "",
        };
      }
      case FETCH_IKARUS_RATE_SUCCESS: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: action.ikarusRate,
          processing: false,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case FETCH_IKARUS_RATE_ERROR: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      case REQUEST_CREATE_IKARUS_RATE: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: true,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case CREATE_IKARUS_RATE_SUCCESS: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: action.message,
          error: "",
        };
      }
      case CREATE_IKARUS_RATE_ERROR: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      case REQUEST_UPDATE_IKARUS_RATE: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: true,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case UPDATE_IKARUS_RATE_SUCCESS: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: action.message,
          error: "",
        };
      }
      case UPDATE_IKARUS_RATE_ERROR: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      case REQUEST_ACTIVE_IKARUS_RATE: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: true,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case ACTIVE_IKARUS_RATE_SUCCESS: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: action.message,
          error: "",
        };
      }
      case ACTIVE_IKARUS_RATE_ERROR: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      case REQUEST_INACTIVE_IKARUS_RATE: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: true,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case INACTIVE_IKARUS_RATE_SUCCESS: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: action.message,
          error: "",
        };
      }
      case INACTIVE_IKARUS_RATE_ERROR: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      case REQUEST_DELETE_IKARUS_RATE: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: true,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case DELETE_IKARUS_RATE_SUCCESS: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: action.message,
          error: "",
        };
      }
      case DELETE_IKARUS_RATE_ERROR: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      case REQUEST_FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: true,
          message: "",
          error: "",
        };
      }
      case FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE_SUCCESS: {
        return {
          ...state,
          ikarusRates: action.ikarusRates,
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: "",
        };
      }
      case FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE_ERROR: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: action.error,
        };
      }
      case INITIAL_STATE_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE: {
        return {
          ...state,
          ikarusRates: [],
          ikarusRate: {},
          processing: false,
          fetching: false,
          message: "",
          error: "",
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default ikarusRateReducer;
  