import GpsTaxList from "../containers/reservations/cruise/gpsTax/GpsTaxList";

const routes = [
  {
    path: "/cruises/:cruise_id/gpsTaxReport",
    component: GpsTaxList,
  },
];

export { routes as gpsTaxRoutes };
