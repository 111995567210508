import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import TicketService from "../../../../services/rates/TicketService";

export const REQUEST_UPDATE_TICKET = "REQUEST_UPDATE_TICKET";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_ERROR = "UPDATE_TICKET_ERROR";

function requestUpdateTicket() {
  return {
    type: REQUEST_UPDATE_TICKET,
  };
}

function updateTicketSuccess(json) {
  return {
    type: UPDATE_TICKET_SUCCESS,
    message: json.data,
  };
}

function updateTicketError(json) {
  return {
    type: UPDATE_TICKET_ERROR,
    error: json.data,
  };
}

export function updateTicket(ticket_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateTicket());
    return TicketService.putTicket(ticket_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateTicketSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateTicket(ticket_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateTicketError(json.data));
      }
    });
  };
}
