import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IdentificationTypeService from "../../../../services/catalogs/IdentificationTypeService";

export const REQUEST_FETCH_IDENTIFICATION_TYPES =
  "REQUEST_FETCH_IDENTIFICATION_TYPES";
export const FETCH_IDENTIFICATION_TYPES_SUCCESS =
  "FETCH_IDENTIFICATION_TYPES_SUCCESS";
export const FETCH_IDENTIFICATION_TYPES_ERROR =
  "FETCH_IDENTIFICATION_TYPES_ERROR";

function requestFetchIdentificationTypes() {
  return {
    type: REQUEST_FETCH_IDENTIFICATION_TYPES,
  };
}

function fetchIdentificationTypesSuccess(json) {
  return {
    type: FETCH_IDENTIFICATION_TYPES_SUCCESS,
    identificationTypes: json.data,
  };
}

function fetchIdentificationTypesError(json) {
  return {
    type: FETCH_IDENTIFICATION_TYPES_ERROR,
    error: json.data,
  };
}

export function fetchIdentificationTypes() {
  return (dispatch) => {
    dispatch(requestFetchIdentificationTypes());
    return IdentificationTypeService.getIdentificationTypes().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchIdentificationTypesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchIdentificationTypes());
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchIdentificationTypesError(json.data));
      }
    });
  };
}
