import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createBerth } from "../../../redux/actions/catalogs/berth/createBerth";
import { fetchBerth, initialStateBerth } from "../../../redux/actions/catalogs/berth/fetchBerth";
import { updateBerth } from "../../../redux/actions/catalogs/berth/updateBerth";

// Resources
import { capacities } from "../../resources/optionsList";
import { scrollToTop } from "../../resources/utils";

export default function BerthForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { berth_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const berthState = useSelector((state) => state.berthReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (berth_id) {
      dispatch(fetchBerth(berth_id));
    }
    scrollToTop();

    return () => {
      dispatch(initialStateBerth());
    };
  }, []);

  if (berthState.fetching) {
    return <Loader active inline />;
  }

  if (berthState.error) {
    return <Message max negative
      header='Error!'
      content={t("server_error_form")}
    />
  }

  const onSubmit = (data) => {
    if (berth_id) {
      dispatch(updateBerth(berth_id, data));
    } else {
      dispatch(createBerth(data));
    }
  };

  if (berthState.message === "resource created successfully" || berthState.message === "resource updated successfully") {
    history.push("/berths");
  }

  return (
    <>
      <Header as='h3' dividing>{t("berth_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("berth_name")}</label>
            <input name="name" defaultValue={berthState.berth.name} placeholder={t("berth_name")} maxLength={50} ref={register({ required: true })} autoFocus />
            {errors.name && (<span className="error_message">{t("required_information")}{" "}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("berth_capacity")} </label>
            <select name="capacity" defaultValue={berthState.berth.capacity} aria-expanded="true"  ref={register}>
              <option></option>
              {capacities.map((capacity, index) => (
                <option key={index} value={capacity}>{capacity}</option>
              ))}
            </select>
          </Form.Field>
          <Form.Field>
            <label>{t("berth_icon")} </label>
            <input name="icon" defaultValue={berthState.berth.icon} maxLength={50} ref={register} />
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />
        
        <Button primary type="submit" loading={berthState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/berths"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
