import {
  REQUEST_FETCH_SPACE_FLIGHTS,
  FETCH_SPACE_FLIGHTS_SUCCESS,
  FETCH_SPACE_FLIGHTS_ERROR,
} from "../../../actions/reservations/spaceFlight/fetchSpaceFlights";

import {
  INITIAL_STATE_SPACE_FLIGHT,
  REQUEST_FETCH_SPACE_FLIGHT,
  FETCH_SPACE_FLIGHT_SUCCESS,
  FETCH_SPACE_FLIGHT_ERROR,
} from "../../../actions/reservations/spaceFlight/fetchSpaceFlight";

import {
  REQUEST_CREATE_SPACE_FLIGHT,
  CREATE_SPACE_FLIGHT_SUCCESS,
  CREATE_SPACE_FLIGHT_ERROR,
} from "../../../actions/reservations/spaceFlight/createSpaceFlight";

import {
  REQUEST_UPDATE_SPACE_FLIGHT,
  UPDATE_SPACE_FLIGHT_SUCCESS,
  UPDATE_SPACE_FLIGHT_ERROR,
} from "../../../actions/reservations/spaceFlight/updateSpaceFlight";

import {
  REQUEST_DELETE_SPACE_FLIGHT,
  DELETE_SPACE_FLIGHT_SUCCESS,
  DELETE_SPACE_FLIGHT_ERROR,
} from "../../../actions/reservations/spaceFlight/deleteSpaceFlight";

const initialState = {
  spaceFlights: [],
  spaceFlight: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const spaceFlightReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_SPACE_FLIGHT: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }

    case REQUEST_FETCH_SPACE_FLIGHTS: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACE_FLIGHTS_SUCCESS: {
      return {
        ...state,
        spaceFlights: action.spaceFlights,
        spaceFlight: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACE_FLIGHTS_ERROR: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_SPACE_FLIGHT: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACE_FLIGHT_SUCCESS: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: action.spaceFlight,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACE_FLIGHT_ERROR: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_SPACE_FLIGHT: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_SPACE_FLIGHT_SUCCESS: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_SPACE_FLIGHT_ERROR: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_SPACE_FLIGHT: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_SPACE_FLIGHT_SUCCESS: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_SPACE_FLIGHT_ERROR: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_SPACE_FLIGHT: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_SPACE_FLIGHT_SUCCESS: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_SPACE_FLIGHT_ERROR: {
      return {
        ...state,
        spaceFlights: [],
        spaceFlight: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default spaceFlightReducer;
