import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CruiseService from "../../../../services/reservations/CruiseService";

export const REQUEST_CREATE_CRUISE = "REQUEST_CREATE_CRUISE";
export const CREATE_CRUISE_SUCCESS = "CREATE_CRUISE_SUCCESS";
export const CREATE_CRUISE_ERROR = "CREATE_CRUISE_ERROR";

function requestCreateCruise() {
  return {
    type: REQUEST_CREATE_CRUISE,
  };
}

function createCruiseSuccess(json) {
  return {
    type: CREATE_CRUISE_SUCCESS,
    message: json.data,
  };
}

function createCruiseError(json) {
  return {
    type: CREATE_CRUISE_ERROR,
    error: json.data,
  };
}

export function createCruise(payload) {
  return (dispatch) => {
    dispatch(requestCreateCruise());
    return CruiseService.postCruise(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createCruiseSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createCruise(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createCruiseError(json.data));
      }
    });
  };
}
