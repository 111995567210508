import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SpaceFlightService from "../../../../services/reservations/SpaceFlightService";

export const REQUEST_FETCH_SPACE_FLIGHTS = "REQUEST_FETCH_SPACE_FLIGHTS";
export const FETCH_SPACE_FLIGHTS_SUCCESS = "FETCH_SPACE_FLIGHTS_SUCCESS";
export const FETCH_SPACE_FLIGHTS_ERROR = "FETCH_SPACE_FLIGHTS_ERROR";

function requestFetchSpaceFlights() {
  return {
    type: REQUEST_FETCH_SPACE_FLIGHTS,
  };
}

function fetchSpaceFlightsSuccess(json) {
  return {
    type: FETCH_SPACE_FLIGHTS_SUCCESS,
    spaceFlights: json.data,
  };
}

function fetchSpaceFlightsError(json) {
  return {
    type: FETCH_SPACE_FLIGHTS_ERROR,
    error: json.data,
  };
}

export function fetchSpaceFlights(space_id) {
  return (dispatch) => {
    dispatch(requestFetchSpaceFlights());
    return SpaceFlightService.getSpaceFlights(space_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchSpaceFlightsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSpaceFlights(space_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSpaceFlightsError(json.data));
      }
    });
  };
}
