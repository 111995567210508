import PassengerDetailList from "../containers/reservations/cruise/passengerDetail/PassengerDetailList";

const routes = [
  {
    path: "/cruises/:cruise_id/passengers",
    component: PassengerDetailList,
  },
];

export { routes as passengerDetailRoutes };
