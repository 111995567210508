import React, { useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
//UI
import { Form } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import "semantic-ui-css/semantic.min.css";
import "../../styles/common.css";
//ACTIONS
import { createCruiseLanguage } from "../../../redux/actions/reservations/cruiseLanguage/createCruiseLanguage";
import { updateCruiseLanguage } from "../../../redux/actions/reservations/cruiseLanguage/updateCruiseLanguage";
import { fetchCruiseLanguageFormCustom, initialStateCruiseLanguageFormCustom } from "../../../redux/slices/custom/reservations/cruiseLanguage/customCruiseLanguageForm.slice";
//RESOURCE
import { scrollToTop } from "../../../containers/resources/utils";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "10%",
    marginTop: -12,
    marginLeft: -82,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

export default function CruiseLanguageForm() {
  let history = useHistory();
  let { cruise_id } = useParams();
  let { id } = useParams();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const cruiseLanguageStateCustom = useSelector((state) => state.customCruiseLanguageForm);
  const cruiseLanguageState = useSelector((state) => state.cruiseLanguageReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCruiseLanguageFormCustom(cruise_id, id));
    scrollToTop();
    return () => {
      dispatch(initialStateCruiseLanguageFormCustom());
    };
  }, [dispatch, cruise_id, id]);

  if (
    cruiseLanguageStateCustom.fetching ||
    cruiseLanguageStateCustom.fetching ||
    cruiseLanguageStateCustom.fetching
  ) {
    return <CircularProgress />;
  }

  if (cruiseLanguageStateCustom.error) {
    return <div>Error: {cruiseLanguageStateCustom.error}</div>;
  }


  const onSubmit = (data) => {
    if (id) {
      dispatch(updateCruiseLanguage(cruise_id, id, data));
    } else {
      dispatch(createCruiseLanguage(cruise_id, data));
    }
  };

  if (
    cruiseLanguageState.message === "resource created successfully" ||
    cruiseLanguageState.message === "resource updated successfully"
  ) {
    history.go(-1);
  }

  return (
    <>
      <div className="ui breadcrumb">
        <Link to="/" className="section">
          TipTops
        </Link>
        <div className="divider">/</div>

        <Link className="section" to={`/cruises/${cruise_id}/languages`}>
          {cruiseLanguageStateCustom.cruise.code}
        </Link>

        <div className="divider">/</div>
        <div className="active section">Idiomas</div>
      </div>
      <br></br>
      <br></br>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Nombre *</label>
              <select
                aria-expanded="true"
                name="language_id"
                defaultValue={cruiseLanguageStateCustom.cruiseLanguage.language_id}
                ref={register({ required: true })}
              >
                <option></option>
                {cruiseLanguageStateCustom.languages.map((language) => (
                  <option
                    className="selected item"
                    key={language.id}
                    value={language.id}
                  >
                    {language.name}
                  </option>
                ))}
              </select>
              {errors.language_id && (
                <span className="error_message">Información requerida</span>
              )}
            </Form.Field>
          </Form.Group>

          <Divider variant="middle" />
          <br></br>

          <Link to={`/cruises/${cruise_id}/languages`}>
            <button type="button" className="ui button">
              Cancelar
            </button>
          </Link>

          <button
            type="submit"
            className={
              cruiseLanguageStateCustom.processing
                ? "ui loading primary button"
                : "ui primary button"
            }
          >
            Guardar Cambios
          </button>
        </div>
      </Form>
    </>
  );
}
