import React, { useState } from "react";
import { Button, Modal, Icon, Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { canceledSpace } from "../../redux/actions/reservations/space/canceledSpace";

export default function CancelSpaceModal(props) {
    
    const [spaceId, setSpaceId] = useState("");
    const { t } = useTranslation();
    const { register, errors, handleSubmit } = useForm();
    const dispatch = useDispatch();

    const spaceCanceledState = useSelector((state) => state.spaceCanceledReducer);

    const onSubmitCanceled = (data) => {
        dispatch(canceledSpace(spaceId, data));
      };

    return (
        <>
            <Modal
                trigger={
                    <Button className="add_button" icon onClick={() => setSpaceId(props.space.id)}>
                    <Icon name="times circle" className="icon_color" />
                    </Button>
                }
                header={
                    <p
                    style={{
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "bold",
                        paddingTop: "15px",
                        paddingLeft: "20px",
                    }}
                    >
                    {t("space_cancelation_message")}
                    </p>
                }
                content={
                    <Form
                    onSubmit={handleSubmit(onSubmitCanceled)}
                    style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingBottom: "25px",
                    }}
                    >
                    <textarea rows="3" name="reason_cancellation" style={{ minHeight: 200 }} placeholder={t("space_canceled_text")} ref={register({ required: true })}/>
                    {errors.reason_cancellation && (<span className="error_message">{t("required_information")}</span>)}
                    <br></br>
                    <br></br>
                    <button
                        type="submit"
                        className={
                        spaceCanceledState.processing
                            ? "ui loading primary button"
                            : "ui primary button"
                        }
                    >
                        {t("cancel_space_button")}
                    </button>
                    </Form>
                }
                actions={[t("close_button")]}
                size="tiny"
            /> 
        </>
    )
}