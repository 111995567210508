import React from "react";
import "../containers/styles/common.css";

export default function HeadRofwittmer() {
  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <div
        id="header-row"
        style={{
          zIndex: 1000,
          top: "100px",
          marginLeft: "147px",
          width: "78%",
          left: "300px",
        }}
      >
        <div
          style={{
            maxWidth: "1210px",
            marginRight: "auto",
            marginLeft: "auto",
            display: "block",
          }}
        >
          <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
            <header style={{ display: "block" }}>
              <div
                style={{
                  marginLeft: "-30px",
                  width: "auto",
                  display: "block",
                }}
              >
                <div
                  style={{
                    marginLeft: "0",
                    width: "30%",
                    boxSizing: "border-box",
                    float: "left",
                    minHeight: "1px",
                  }}
                >
                  <div
                    id="logo"
                    style={{ paddingTop: "13px", display: "block" }}
                  >
                    <a href="/">
                      <img
                        src="https://www.rwittmer.com/images/rwittmer.jpg"
                        alt="Rolf Wittmer . Tip Top . Galapagos Cruises"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          verticalAlign: "middle",
                          border: "0",
                        }}
                      />
                      <h1
                        style={{
                          fontSize: "0",
                          lineHeight: "0",
                          height: " 0",
                          width: "0",
                          border: " none",
                          padding: "0",
                          margin: "0",
                          overflow: "hidden",
                          textIndent: "-9999px",
                        }}
                      >
                        Rolf Wittmer . Tip Top . Galapagos Cruises
                      </h1>
                    </a>
                  </div>
                </div>
                <nav
                  style={{
                    paddingLeft: "50px",
                    width: "70%",
                    marginLeft: "0",
                    float: "left",
                    boxSizing: "border-box",
                    minHeight: "1px",
                  }}
                >
                  <ul
                    className="sf-menu"
                    style={{
                      margin: "0",
                      padding: "0",
                      listStyle: "none",
                      float: "left",
                      position: "relative",
                      overflow: "visible",
                      clear: "both",
                      height: "auto !important",
                      boxSizing: "inherit",
                    }}
                  >
                    <li className="header_rolfwittmer_li">
                      <a
                        href="https://www.rwittmer.com/index.php/about"
                        className="header_rolfwittmer_a"
                      >
                        About
                      </a>
                    </li>
                    <li className="header_rolfwittmer_li">
                      <a
                        href="https://www.rwittmer.com/index.php/services"
                        className="header_rolfwittmer_a"
                      >
                        Galapagos{" "}
                      </a>
                      <ul
                        className="sub-menu"
                        style={{
                          display: "none",
                          float: "none",
                          width: "27em",
                        }}
                      >
                        <li
                          className="item-280 firstItem"
                          style={{
                            whiteSpace: "normal",
                            float: "none",
                            width: "100%",
                          }}
                        >
                          <a
                            href="https://www.rwittmer.com/index.php/services/galapagos-islands-map"
                            style={{ float: "none", width: "auto" }}
                          >
                            Galapagos Islands Map
                          </a>
                        </li>
                        <li
                          className="item-266 lastItem"
                          style={{
                            whiteSpace: "normal",
                            float: "none",
                            width: "100%",
                          }}
                        >
                          <a
                            href="https://www.rwittmer.com/index.php/services/cruises-vs-island-hoping"
                            style={{ float: "none", width: "auto" }}
                          >
                            Why to choose a first class boat?
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="header_rolfwittmer_li">
                      <a
                        href="https://www.rwittmer.com/index.php/land"
                        className="header_rolfwittmer_a"
                      >
                        Land Services
                      </a>
                    </li>
                    <li className="header_rolfwittmer_li">
                      <a href="/" className="header_rolfwittmer_a">
                        Promotions
                      </a>
                      <ul
                        className="sub-menu"
                        style={{
                          display: "none",
                          float: "none",
                          width: "21.2308em",
                        }}
                      >
                        <li
                          className="item-267 firstItem"
                          style={{
                            whiteSpace: "normal",
                            float: "none",
                            width: "100%",
                          }}
                        >
                          <a
                            href="/index.php/promotions/tip-top-ii-promotions"
                            style={{ float: "none", width: "auto" }}
                          >
                            Tip Top II promotions
                          </a>
                        </li>
                        <li
                          className="item-260"
                          style={{
                            whiteSpace: "normal",
                            float: "none",
                            width: "100%",
                          }}
                        >
                          <a
                            href="/index.php/promotions/tip-top-iv-promotions"
                            style={{ float: "none", width: "auto" }}
                          >
                            Tip Top IV Promotions
                          </a>
                        </li>
                        <li
                          className="item-285"
                          style={{
                            whiteSpace: "normal",
                            float: "none",
                            width: "100%",
                          }}
                        >
                          <a
                            href="/index.php/promotions/tip-top-v-promotions"
                            style={{ float: "none", width: "auto" }}
                          >
                            Tip Top V Promotions{" "}
                          </a>
                        </li>
                        <li
                          className="item-286"
                          style={{
                            whiteSpace: "normal",
                            float: "none",
                            width: "100%",
                          }}
                        >
                          <a
                            href="/index.php/promotions/photography-tour"
                            style={{ float: "none", width: "auto" }}
                          >
                            Photography Tour{" "}
                          </a>
                        </li>
                        <li
                          className="item-304 lastItem"
                          style={{
                            whiteSpace: "normal",
                            float: "none",
                            width: "100%",
                          }}
                        >
                          <a
                            href="/index.php/promotions/special-family-charters"
                            style={{ float: "none", width: "auto" }}
                          >
                            Special Family Charters
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="header_rolfwittmer_li">
                      <a
                        href="https://www.rwittmer.com/index.php/contact"
                        className="header_rolfwittmer_a"
                      >
                        Contact
                      </a>
                    </li>
                    <li className="header_rolfwittmer_li lastItem">
                      <a
                        href="https://www.rwittmer.com/"
                        className="header_rolfwittmer_a"
                        target="blank"
                      >
                        My Account
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </header>
          </div>
        </div>
      </div>
    </div>
  );
}
