import PassengerBySpaceService from "../../../../services/reservations/PassengerBySpaceService";

export const REQUEST_FETCH_PASSENGER_BY_SPACE =
  "REQUEST_FETCH_PASSENGER_BY_SPACE";
export const FETCH_PASSENGER_BY_SPACE_SUCCESS =
  "FETCH_PASSENGER_BY_SPACE_SUCCESS";
export const FETCH_PASSENGER_BY_SPACE_ERROR = "FETCH_PASSENGER_BY_SPACE_ERROR";
export const INITIAL_STATE_PASSENGER_BY_SPACE =
  "INITIAL_STATE_PASSENGER_BY_SPACE";

function requestFetchPassengerBySpace() {
  return {
    type: REQUEST_FETCH_PASSENGER_BY_SPACE,
  };
}

function fetchPassengerBySpaceSuccess(json) {
  return {
    type: FETCH_PASSENGER_BY_SPACE_SUCCESS,
    passengerBySpace: json.data,
  };
}

function fetchPassengerBySpaceError(json) {
  return {
    type: FETCH_PASSENGER_BY_SPACE_ERROR,
    error: json.data,
  };
}

export function initialStatePassengerBySpace() {
  return {
    type: INITIAL_STATE_PASSENGER_BY_SPACE,
  };
}

export function fetchPassengerBySpace(space_id, passenger_id, cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchPassengerBySpace());
    return PassengerBySpaceService.getPassengerBySpace(
      space_id,
      passenger_id,
      cruise_id
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchPassengerBySpaceSuccess(json.data));
      } else {
        dispatch(fetchPassengerBySpaceError(json.data));
      }
    });
  };
}
