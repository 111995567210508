import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import YachtService from "../../../../services/catalogs/YachtService";

export const REQUEST_UPDATE_YACHT = "REQUEST_UPDATE_YACHT";
export const UPDATE_YACHT_SUCCESS = "UPDATE_YACHT_SUCCESS";
export const UPDATE_YACHT_ERROR = "UPDATE_YACHT_ERROR";

function requestUpdateYacht() {
  return {
    type: REQUEST_UPDATE_YACHT,
  };
}

function updateYachtSuccess(json) {
  return {
    type: UPDATE_YACHT_SUCCESS,
    message: json.data,
  };
}

function updateYachtError(json) {
  return {
    type: UPDATE_YACHT_ERROR,
    error: json.data,
  };
}

export function updateYacht(yacht_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateYacht());
    return YachtService.putYacht(yacht_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateYachtSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateYacht(yacht_id, payload));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(updateYachtError(json.data));
      }
    });
  };
}
