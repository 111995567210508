import AirlineList from "../containers/catalogs/airline/AirlineList";
import AirlineForm from "../containers/catalogs/airline/AirlineForm";

const routes = [
  {
    path: "/airlines",
    component: AirlineList,
  },
  {
    path: "/airlines_new",
    component: AirlineForm,
  },
  {
    path: "/airlines/:airline_id",
    component: AirlineForm,
  },
];

export { routes as airlineRoutes };
