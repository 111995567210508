import BookingList from "../containers/reservations/cruise/booking/BookingList";
import BookingForm from "../containers/reservations/cruise/booking/BookingForm";

const routes = [
  {
    path: "/cruises/:cruise_id/reservations/:reservation_id/bookings",
    component: BookingList,
  },
  {
    path: "/cruises/:cruise_id/reservations/:reservation_id/bookings_new",
    component: BookingForm,
  },
  {
    path:
      "/cruises/:cruise_id/reservations/:reservation_id/booking/:booking_id",
    component: BookingForm,
  },
];

export { routes as bookingRoutes };
