import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import NoteService from "../../../../services/catalogs/NoteService";

export const REQUEST_FETCH_NOTE = "REQUEST_FETCH_NOTE";
export const FETCH_NOTE_SUCCESS = "FETCH_NOTE_SUCCESS";
export const FETCH_NOTE_ERROR = "FETCH_NOTE_ERROR";
export const INITIAL_STATE_NOTE = "INITIAL_STATE_NOTE";

function requestFetchNote() {
  return {
    type: REQUEST_FETCH_NOTE,
  };
}

function fetchNoteSuccess(json) {
  return {
    type: FETCH_NOTE_SUCCESS,
    note: json.data,
  };
}

function fetchNoteError(json) {
  return {
    type: FETCH_NOTE_ERROR,
    error: json.data,
  };
}

export function initialStateNote() {
  return {
    type: INITIAL_STATE_NOTE,
  };
}

export function fetchNote(note_id) {
  return (dispatch) => {
    dispatch(requestFetchNote());
    return NoteService.getNote(note_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchNoteSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchNote(note_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchNoteError(json.data));
      }
    });
  };
}
