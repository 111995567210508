import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SeasonService from "../../../../services/rates/SeasonService";

export const REQUEST_FETCH_SEASONS = "REQUEST_FETCH_SEASONS";
export const FETCH_SEASONS_SUCCESS = "FETCH_SEASONS_SUCCESS";
export const FETCH_SEASONS_ERROR = "FETCH_SEASONS_ERROR";

function requestFetchSeasons() {
  return {
    type: REQUEST_FETCH_SEASONS,
  };
}

function fetchSeasonsSuccess(json) {
  return {
    type: FETCH_SEASONS_SUCCESS,
    seasons: json.data,
  };
}

function fetchSeasonsError(json) {
  return {
    type: FETCH_SEASONS_ERROR,
    error: json.data,
  };
}

export function fetchSeasons() {
  return (dispatch) => {
    dispatch(requestFetchSeasons());
    return SeasonService.getSeasons().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchSeasonsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSeasons());
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSeasonsError(json.data));
      }
    });
  };
}
