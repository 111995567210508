import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class AvailabilityService {
  static getAvailabilityCruise(month, year, yacht, itinerary) {
    return axios.get(
      API_BASE_URL +
        "availablecruises" +
        `?month=${month}&&year=${year}&&yacht=${yacht}&&itinerary=${itinerary}`
    );
  }

  static insertAvailabilityCruise(payload) {
    return axios.post("b2b/availablecruises", payload);
  }

  static insertAvailabilityCruiseCharter(payload) {
    return axios.post("b2b/availablecruises/charter", payload);
  }

  static getActualReservationByAgency(agencyId) {
    return axios.get(
      API_BASE_URL + `b2b/actualReservations?agency_id=${agencyId}`
    );
  }

  static getHistoricalReservationByAgency(agencyId) {
    return axios.get(
      API_BASE_URL + `b2b/historicalReservations?agency_id=${agencyId}`
    );
  }

  static getReservationDetail(reservationId) {
    return axios.get(API_BASE_URL + `b2b/spacesByReservation/${reservationId}`);
  }

  static changeSpacesOfAReservation(payload) {
    return axios.post(API_BASE_URL + `b2b/changeSpace`, payload);
  }

  static postSpacesAndPassengers(payload) {
    return axios.post(
      API_BASE_URL + `b2b/spacesAndPassengersByReservation`,
      payload
    );
  }
}
