import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import DayService from "../../../../services/catalogs/DayService";

export const REQUEST_FETCH_DAYS = "REQUEST_FETCH_DAYS";
export const FETCH_DAYS_SUCCESS = "FETCH_DAYS_SUCCESS";
export const FETCH_DAYS_ERROR = "FETCH_DAYS_ERROR";

function requestFetchDays() {
  return {
    type: REQUEST_FETCH_DAYS,
  };
}

function fetchDaysSuccess(json) {
  return {
    type: FETCH_DAYS_SUCCESS,
    days: json.data,
  };
}

function fetchDaysError(json) {
  return {
    type: FETCH_DAYS_ERROR,
    error: json.data,
  };
}

export function fetchDays(itinerary_id) {
  return (dispatch) => {
    dispatch(requestFetchDays());
    return DayService.getDays(itinerary_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchDaysSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchDays(itinerary_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchDaysError(json.data));
      }
    });
  };
}
