import CruiseService from "../../../../services/reservations/CruiseService";

export const REQUEST_FETCH_CABINS_INFORMATION_BY_CRUISE =
  "REQUEST_FETCH_CABINS_INFORMATION_BY_CRUISE";
export const FETCH_CABINS_INFORMATION_BY_CRUISE_SUCCESS =
  "FETCH_CABINS_INFORMATION_BY_CRUISE_SUCCESS";
export const FETCH_CABINS_INFORMATION_BY_CRUISE_ERROR =
  "FETCH_CABINS_INFORMATION_BY_CRUISE_ERROR";

function requestFetchCabinsInformationByCruise() {
  return {
    type: REQUEST_FETCH_CABINS_INFORMATION_BY_CRUISE,
  };
}

function fetchCabinsInformationByCriuseSuccess(json) {
  return {
    type: FETCH_CABINS_INFORMATION_BY_CRUISE_SUCCESS,
    cabins: json.data,
  };
}

function fetchCabinsInformationByCriuseError(json) {
  return {
    type: FETCH_CABINS_INFORMATION_BY_CRUISE_ERROR,
    error: json.data,
  };
}

export function fetchCabinsInformationByCruise(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchCabinsInformationByCruise());
    return CruiseService.getCabinsInformationByCruise(cruise_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchCabinsInformationByCriuseSuccess(json.data));
        } else {
          dispatch(fetchCabinsInformationByCriuseError(json.data));
        }
      }
    );
  };
}
