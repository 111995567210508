import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from "react-router-dom";
//UI
import logo from '../icons/logo.png'
import { FaArrowLeft } from 'react-icons/fa';
//TRASLATION
import { useTranslation } from "react-i18next";
//ACTIONS
import { ForgotPasswordUser } from '../../redux/actions/catalogs/user/forgotPasswordUser';


const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();
  const { getValues, register, handleSubmit, errors } = useForm();
  const userState = useSelector(state => state.userReducer)

  const onSubmit = (data) => {
    dispatch(ForgotPasswordUser(data));
  };

  return (
    <div
      className={`text-sky-800 flex justify-center items-center 2xl:items-center transition-all h-full w-full min-h-screen bg-cover bg-center bg-[url('../src/containers/icons/Picture3.jpg')]`}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-gray-600/40 backdrop-blur-sm"></div>
      <div className="w-[90%] h-full transition-all fle rounded-md sm:w-2/3 md:w-[35%]  bg-[#ffffff6a] sm:bg-[#ffffff6a] backdrop-blur-lg shadow-lg shadow-gray-500 flex flex-col items-center justify-center">
        {userState.message === "password updated successfully" ?
          <>
            <div className='flex flex-col p-2 w-[80%] border-green-500 rounded-md border bg-green-200 mt-14'>
              <h1 className="text-center text-[14px] mb-5">Se ha enviado un email a la dirección de correo electrónico ingresada con una contraseña temporal con la que podrás iniciar sesión y restablecer tu contraseña</h1>
              <div className='pl-2 text-sm'>
                <p className='mb-0'>Nota: Si no encuentras nuestro email en tu bandeja de entrada, por favor revisa la bandeja de spam o correo no deseado</p>
              </div>
            </div>
            <div className='flex gap-2 w-[100%] my-10 justify-center'>
              <h1 className="text-[13px] flex gap-2"><Link to={'/login'}><p className='text-sky-700 hover:text-sky-800 flex items-center gap-1'><FaArrowLeft />Iniciar sesión</p> </Link></h1>
            </div>
          </> :
          <>
            <div className='flex flex-col text-[15px] text-center gap-2 w-[75%] mt-14'>
              <h1 className="text-[18px] font-semibold ">¿Olvidaste tu contraseña?</h1>
              <p>Ingrese la dirección de correo electrónico asociada con su cuenta y le enviaremos un enlace por correo electrónico para restablecer su contraseña.</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-[75%] mx-auto text-gray-800 mb-8">
              <div className='flex flex-col'>
                <div className="relative flex flex-col mt-8">
                  <input className={'w-full placeholder-gray-800 p-2 rounded-xl focus:outline-none peer bg-transparent shadow-[inset_0_-1px_0_0_rgba(29,34,43,.2)]'}
                    type='text'
                    ref={register({ required: true })}
                    placeholder="Email"
                    name="email"
                  />
                </div>
                {errors.email && <span className="text-red-600">{t("required_information")}</span>}
              </div>
              <div className='flex w-full justify-center h-[35px] mt-10'>
                <button type=" submit" className="btn shadow-lg btn-block w-[70%] bg-sky-700 hover:bg-sky-800 hover:duration-75 border-none rounded-md  text-white">
                  {t("change_password_reset_pass")}
                </button>
              </div>
              <div className='flex gap-2 w-[100%] mt-2 justify-center'>
                <h1 className="text-[13px] flex gap-2"><Link to={'/login'}><p className='text-sky-700 hover:text-sky-800 flex items-center gap-1'><FaArrowLeft />Iniciar sesión</p> </Link></h1>
              </div>
            </form>
          </>}
      </div >
      <div className="absolute w-[220px] h-[20px] z-10 -translate-x-1/2 top-5 left-1/2  sm:translate-x-0  sm:top-[80%] sm:left-5 rotate-[0.5deg] ">
        <img src={logo} alt="logo" />
      </div>
    </div >
  );
};

export default ForgotPasswordForm;