import StatisticService from "../../../../services/reservations/StatisticService";

export const REQUEST_GENERATE_STATISTIC = "REQUEST_GENERATE_STATISTIC";
export const GENERATE_STATISTIC_SUCCESS = "GENERATE_STATISTIC_SUCCESS";
export const GENERATE_STATISTIC_ERROR = "GENERATE_STATISTIC_ERROR";
export const INITIAL_STATE_STATISTIC = "INITIAL_STATE_STATISTIC";

function requestGenerateStatistic() {
  return {
    type: REQUEST_GENERATE_STATISTIC,
  };
}

function generateStatisticSuccess(json) {
  return {
    type: GENERATE_STATISTIC_SUCCESS,
    data: json,
  };
}

function generateStatisticError(json) {
  return {
    type: GENERATE_STATISTIC_ERROR,
    error: json.data,
  };
}

export function initialStateStatistic() {
  return {
    type: INITIAL_STATE_STATISTIC,
  };
}

export function generateStatistic(startDate, endDate, type, payload) {
  return (dispatch) => {
    dispatch(requestGenerateStatistic());
    return StatisticService.statisticReport(
      startDate,
      endDate,
      type,
      payload
    ).then((json) => {
      if (json.status === 200) {
        dispatch(generateStatisticSuccess(json.config.url));
      } else {
        dispatch(generateStatisticError(json.data));
      }
    });
  };
}
