import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CabinBerthService from "../../../../services/catalogs/CabinBerthService";

export const REQUEST_UPDATE_CABIN_BERTH = "REQUEST_UPDATE_CABIN_BERTH";
export const UPDATE_CABIN_BERTH_SUCCESS = "UPDATE_CABIN_BERTH_SUCCESS";
export const UPDATE_CABIN_BERTH_ERROR = "UPDATE_CABIN_BERTH_ERROR";

function requestUpdateCabinBerth() {
  return {
    type: REQUEST_UPDATE_CABIN_BERTH,
  };
}

function updateCabinBerthSuccess(json) {
  return {
    type: UPDATE_CABIN_BERTH_SUCCESS,
    message: json.data,
  };
}

function updateCabinBerthError(json) {
  return {
    type: UPDATE_CABIN_BERTH_ERROR,
    error: json.data,
  };
}

export function updateCabinBerth(cabin_id, id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateCabinBerth());
    return CabinBerthService.putCabinBerth(cabin_id, id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(updateCabinBerthSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(updateCabinBerth(cabin_id, id, payload));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(updateCabinBerthError(json.data));
        }
      }
    );
  };
}
