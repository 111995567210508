import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { findIkarusRateByCruiseAgencyContractType, initialStateIkarusRateByCruiseAgencyContractType, } from "../../redux/actions/rates/ikarusRates/findIkarusRateByCruiseAgencyCruiseType"

//UI
import { Loader } from "semantic-ui-react";

//Components
import IkarusRatePopup from "../../components/rates/ikarusRatePopup"

export default function IkarusRateCheckBox(props) {

  const { t } = useTranslation();
  const { register } = useForm();
	const ikarusRateState = useSelector((state) => state.ikarusRateReducer);
	const dispatch = useDispatch();

  useEffect(() => {
    var payload = new Object();
    payload.cruise_type = props.cruise_type;
    payload.agency_id = props.agency_id;
    dispatch(findIkarusRateByCruiseAgencyContractType(props.cruise_id, payload));

		return () => {
			dispatch(initialStateIkarusRateByCruiseAgencyContractType());
		}
  }, []);

  return (
		<>
			{ikarusRateState.fetching === true ? (
      	<Loader active inline />
    	) : (
				Object.entries(ikarusRateState.ikarusRates).length > 0 ? (
				  <>
      		  <label>{t("ikarus_contract")}
        		  {<IkarusRatePopup ikarusRates = {ikarusRateState.ikarusRates}
                                range1_number_passenger_from={ikarusRateState.ikarusRate.range1_number_passenger_from}
                                range1_number_passenger_to={ikarusRateState.ikarusRate.range1_number_passenger_to}
                                range1_double_client={ikarusRateState.ikarusRate.range1_double_client}
                                range1_single_client={ikarusRateState.ikarusRate.range1_single_client}
                                range1_tour_leader_percent_reduction={ikarusRateState.ikarusRate.range1_tour_leader_percent_reduction}

                                range2_number_passenger_from={ikarusRateState.ikarusRate.range2_number_passenger_from}
                                range2_number_passenger_to={ikarusRateState.ikarusRate.range2_number_passenger_to}
                                range2_double_client={ikarusRateState.ikarusRate.range2_double_client}
                                range2_single_client={ikarusRateState.ikarusRate.range2_single_client}
                                range2_tour_leader_percent_reduction={ikarusRateState.ikarusRate.range2_tour_leader_percent_reduction}

                                range3_number_passenger_from={ikarusRateState.ikarusRate.range3_number_passenger_from}
                                range3_number_passenger_to={ikarusRateState.ikarusRate.range3_number_passenger_to}
                                range3_double_client={ikarusRateState.ikarusRate.range3_double_client}
                                range3_single_client={ikarusRateState.ikarusRate.range3_single_client}
                                range3_tour_leader_percent_reduction={ikarusRateState.ikarusRate.range3_tour_leader_percent_reduction}

                                charter_client={ikarusRateState.ikarusRate.charter_client} />
              }
      		  </label>
      		  <div className="ui checkbox">
        	    {
					      <input  type="checkbox" 
						            name="contract_rate" 
						            defaultChecked={props.ikarus_rate === 1} 
						            ref={register} 
                        onChange={(event) => {props.handleIkarusRateChange(event.target.checked);}}
					      /> 
				      }
        	    <label></label>
      		  </div>
				  </>
				) : (
          null
        )
			)}
		</>
  );
}