import jsPDF from "jspdf";
import "jspdf-autotable";
import rwittmer from "../../icons/rwittmer.jpg";
import path from "path";

export default function orderServicePDF(cruise) {
  var reportLogoRolfWittmer = new Image();
  reportLogoRolfWittmer.src = path.resolve(rwittmer);

  var doc = new jsPDF({
    orientation: "landscape",
    unit: "pt",
  });

  // HEADER

  // IMAGE (LOGO)

  doc.addImage(reportLogoRolfWittmer, "JPEG", 560, 20, 236, 46);

  doc.setFontSize(18);
  doc.setFont("helvetica","bold");
  doc.text("ORDEN DE SERVICIOS GALAPAGOS No. " + cruise.code, 40, 34);

  if (cruise.yacht_kind.toUpperCase() === "CATAMARÁN") {
    doc.text(cruise.yacht_name, 40, 55);
  } else {
    doc.text(cruise.yacht_kind.toUpperCase() + " " + cruise.yacht_name, 40, 55);
  }

  doc.setFontSize(10);
  doc.text("RWTG-REG-43", 350, 55);
  //GENERAL INFORMATION CRUISE
  var columnsHeader = [
    { title: "", dataKey: "label" },
    { title: "", dataKey: "name" },
    { title: "", dataKey: "label2" },
    { title: "", dataKey: "name2" },
    { title: "", dataKey: "label3" },
    { title: "", dataKey: "name3" },
  ];

  //GET CRUISE TYPES
  let TO = cruise.numberSpacesUsed_TO_Confirmed;
  let PP = cruise.numberSpacesUsed_PP_Confirmed;
  let SP = cruise.numberSpacesUsed_SP_Confirmed;

  // cruise.cruise_types.map((cruiseType, index) => {
  //   TO = cruiseType.TO;
  //   PP = cruiseType.PP;
  //   SP = cruiseType.SP;
  //   return 0;
  // });

  //GET GUIDES
  let guide1 = "";
  let guide2 = "";

  cruise.guides.map((guide, index) => {
    if (index === 0) {
      guide1 =
        guide.first_name +
        " " +
        guide.last_name +
        " " +
        "|" +
        " " +
        "CI:" +
        guide.document_number +
        " " +
        "|" +
        " " +
        guide.language_name;
    }
    if (index === 1) {
      guide2 =
        guide.first_name +
        " " +
        guide.last_name +
        " " +
        "|" +
        " " +
        "CI:" +
        guide.document_number +
        " " +
        "|" +
        " " +
        guide.language_name;
    }
    return 0;
  });

  var rowsHeader = [
    {
      label: "Fecha",
      name: cruise.date,
      label2: "Capitán",
      name2: cruise.captain,
      label3: "TO",
      name3: TO,
    },
    {
      label: "Itinerario",
      name: cruise.itinerary,
      label2: "Guía 1",
      name2: guide1,
      label3: "PP",
      name3: PP,
    },
    {
      label: "Nombre",
      name: cruise.name,
      label2: "Guía 2",
      name2: guide2,
      label3: "SP",
      name3: SP,
    },
    { label: "Hotelero", name: cruise.hotelier },
  ];
  var options = {
    didDrawPage: () => {
      return false; // Don't draw header row
    },

    startY: 80,
    styles: {
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: 10,
    },
    columnStyles: {
      label: { fillColor: [28, 133, 199], textColor: [255, 255, 255] },
      label2: { fillColor: [28, 133, 199], textColor: [255, 255, 255] },
      label3: { fillColor: [28, 133, 199], textColor: [255, 255, 255] },
      name: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      name2: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      name3: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
    },
  };
  doc.autoTable(columnsHeader, rowsHeader, options);

  //NOTES INFORMATION
  var columnNote = [
    { title: "", dataKey: "label" },
    { title: "", dataKey: "name" },
  ];

  var notes = cruise.notes;

  var rowNote = [{ label: "Notas", name: notes }];

  doc.autoTable(columnNote, rowNote, {
    didDrawPage: () => {
      return false; // Don't draw header row
    },
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: "linebreak",
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: 9,
    },
    columnStyles: {
      label: {
        fillColor: [28, 133, 199],
        textColor: [255, 255, 255],
        cellWidth: 69.5,
      },
      name: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
    },
  });

  // BODY
  cruise.cabins.map((cabin) => {
    var flights = [];
    var dates = [];
    var routes = [];
    var airlineTickets = [];

    var columnPassenger = [
      { title: "CAB", dataKey: "cab" },
      { title: "Apellido / Nombre", dataKey: "nombre" },
      { title: "Edad", dataKey: "edad" },
      { title: "Pasaporte", dataKey: "pasaporte" },
      { title: "País", dataKey: "pais" },
      { title: "Vuelo ida", dataKey: "vueloIda" },
      { title: "TKT", dataKey: "tkti" },
      { title: "Vuelo regreso", dataKey: "vueloRegreso" },
      { title: "TKT", dataKey: "tktr" },
      { title: "VEG", dataKey: "veg" },
      { title: "GF", dataKey: "gf" },
      { title: "TAX", dataKey: "tax" },
      { title: "TCT", dataKey: "tct" },
      { title: "Tipo", dataKey: "tipo" },
      { title: "Agencia", dataKey: "agencia" },
      { title: "Arribo a GPS", dataKey: "arribo" },
      { title: "Salida de GPS", dataKey: "salida" },
    ];

    if (cabin.flight !== null) {
      flights = cabin.flight.split("-");
    } else {
      flights = [];
    }

    if (cabin.date !== null) {
      dates = cabin.date.split("-");
    } else {
      dates = [];
    }

    if (cabin.passenger_route !== null) {
      routes = cabin.passenger_route.split("-");
    } else {
      routes = [];
    }

    if (cabin.tickets !== null) {
      airlineTickets = cabin.tickets.split("-");
    } else {
      airlineTickets = [];
    }

    var rowPassener = [
      {
        cab: cabin.cabin_name,
        nombre:
          cabin.passenger_last_name.toUpperCase() +
          " / " +
          cabin.passenger_first_name.toUpperCase(),
        edad: cabin.passenger_age,
        pasaporte: cabin.passenger_passport,
        pais: cabin.passenger_country,
        vueloIda: routes[0] ? routes[0] : "N/A",
        tkti: airlineTickets[0]
          ? airlineTickets[0] === "1"
            ? "Si"
            : "No"
          : "N/A",
        vueloRegreso: routes[1] ? routes[1] : "N/A",
        tktr: airlineTickets[1]
          ? airlineTickets[1] === "1"
            ? "Si"
            : "No"
          : "N/A",
        veg: cabin.passenger_vegetrian === 1 ? "Si" : "No",
        gf: cabin.passenger_free_gluten === 1 ? "Si" : "No",
        tax: cabin.passenger_tax === 1 ? "Si" : "No",
        tct: cabin.passenger_tct === 1 ? "Si" : "No",
        tipo: cabin.cruise_type,
        agencia: cabin.agency_name,
        arribo:
          dates.length !== 0 && flights.length !== 0
            ? dates[0] + "\n" + flights[0]
            : "",
        salida:
          dates.length !== 0 && flights.length !== 0
            ? dates[1] !== undefined && flights[1] !== undefined
              ? dates[1] + "\n" + flights[1]
              : ""
            : "",
      },
    ];

    doc.autoTable(columnPassenger, rowPassener, {
      startY: doc.lastAutoTable.finalY + 6,
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        overflow: "linebreak",
        fontSize: 8,
        fillColor: [255, 255, 255],
      },
      headStyles: {
        fillColor: [243, 244, 244],
        textColor: [0, 0, 0],
      },
      columnStyles: {
        cab: {
          cellWidth: 27,
          fillColor: [28, 133, 199],
          textColor: [0, 0, 0, 0],
          halign: "center",
          valign: "middle",
        },
        nombre: { cellWidth: 100, fillColor: [255, 255, 255] },
        edad: { cellWidth: 29, fillColor: [255, 255, 255] },
        pasaporte: { cellWidth: 57, fillColor: [255, 255, 255] },
        pais: { cellWidth: 45, fillColor: [255, 255, 255] },
        vueloIda: {
          cellWidth: 35,
          fillColor: [255, 255, 255],
        },
        tkti: { cellWidth: 25, fillColor: [255, 255, 255] },
        vueloRegreso: {
          cellWidth: 40,
          fillColor: [255, 255, 255],
        },
        tktr: { cellWidth: 25, fillColor: [255, 255, 255] },
        veg: { cellWidth: 27, fillColor: [255, 255, 255] },
        gf: { cellWidth: 25, fillColor: [255, 255, 255] },
        tax: { cellWidth: 25, fillColor: [255, 255, 255] },
        tct: { cellWidth: 25, fillColor: [255, 255, 255] },
        tipo: { cellWidth: 27, fillColor: [255, 255, 255] },
        agencia: { cellWidth: 110, fillColor: [255, 255, 255] },
        arribo: { cellWidth: 70, fillColor: [255, 255, 255] },
        salida: { cellWidth: 70, fillColor: [255, 255, 255] },
      },
    });

    if (cabin.feed !== "") {
      createNotePassengerTable(doc, "Alimentación - Bebidas:", cabin.feed);
    }

    if (cabin.medical_information !== "") {
      createNotePassengerTable(
        doc,
        "Información médica y alergias:",
        cabin.medical_information
      );
    }

    if (cabin.rent_wetsuits !== "") {
      createNotePassengerTable(
        doc,
        "Renta wetsuits - aletas:",
        cabin.rent_wetsuits
      );
    }

    if (cabin.special_requirements.trim() !== "") {
      createNotePassengerTable(
        doc,
        "Requirimientos especiales:",
        cabin.special_requirements
      );
    }

    if (cabin.notes.trim() !== "") {
      createNotePassengerTable(doc, "Notas vuelo:", cabin.notes);
    }

    if (cabin.transfer_notes.trim() !== "") {
      createNotePassengerTable(doc, "Notas traslados:", cabin.transfer_notes);
    }

    if (cabin.double_bed === 1) {
      createNotePassengerTable(doc, "Cama matrimonial:", "Si");
    }

    if (cabin.family_bed === 1) {
      createNotePassengerTable(doc, "Cabina familiar:", "Si");
    }

    return 0;
  });

  //GENERATE PDF
  addFooter(doc);
  doc.save("OrdenDeServicios" + cruise.code + ".pdf");
}

function createNotePassengerTable(doc, typeNote, note) {
  var columnNotePassenger = [
    { title: "", dataKey: "label" },
    { title: "", dataKey: "name" },
  ];

  var rowNotePassenger = [{ label: typeNote, name: note }];

  doc.autoTable(columnNotePassenger, rowNotePassenger, {
    didDrawPage: function () {
      return false; // Don't draw header row
    },
    startY: doc.lastAutoTable.finalY,
    styles: {
      overflow: "linebreak",
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: 8,
    },
    columnStyles: {
      label: { cellWidth: 127 },
      name: { fillColor: [255, 255, 255] },
    },
  });
}

function addFooter(doc) {
  for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
    doc.setPage(i);

    doc.line(40, 566, 800, 566);

    doc.setFontSize(10);
    var date = new Date();

    var currentDate =
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear();
    doc.text(40, 580, currentDate);

    doc.setFontSize(8);
    doc.setFont("helvetica","normal");
    doc.text(
      100,
      580,
      "TKT: Ticket Aereo  -  VEG: Vegetariano  -  GF: Glutten Free  -  TAX: Impuesto Galápagos  -  TCT: Tarjeta de Control de Tránsito"
    );

    doc.setFontSize(10);
    doc.setFont("helvetica","bold");
    doc.text(
      735,
      580,
      "Página " + i + " de " + doc.internal.getNumberOfPages()
    );
  }
}
