import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Message, Breadcrumb, Divider, Loader, Header, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createSpace } from "../../../../redux/actions/reservations/space/createSpace";
import { updateSpace } from "../../../../redux/actions/reservations/space/updateSpace";
import { initialStateSpace, } from "../../../../redux/actions/reservations/space/fetchSpace";
import { fetchSpaceFormCustom, initialStateSpaceFormCustom } from "../../../../redux/slices/custom/reservations/cruise/space/customSpaceForm.slice";

//Components
import ContractRatePopup from "../../../../components/rates/contractRatePopup"
import HeadDetail from "../../../../components/headDetail";
import HeadDetailReservation from "../../../../components/headDetailReservation";

// Resources
import { reservationStates, passengerNationalities } from "../../../resources/optionsList";
import { scrollToTop } from "../../../resources/utils";

export default function SpaceForm() {
  let reserveStates = [
    reservationStates.RESERVED,
    reservationStates.CONFIRMED,
    reservationStates.WAITING,
  ];
  let history = useHistory();
  let { cruise_id } = useParams();
  let { reservation_id } = useParams();
  let { booking_id } = useParams();
  let { space_id } = useParams();
  const { t } = useTranslation();

  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const [spaceStates, setSpaceState] = useState();
  const [spaceTimeLimit, setSpaceTimeLimit] = useState();
  const [disabled, setDisabled] = useState(true);
  const [disabledState, setDisabledState] = useState(false);
  const bookingStateCustom = useSelector((state) => state.customSpaceForm);
  const spaceState = useSelector((state) => state.spaceReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSpaceFormCustom(booking_id, cruise_id, reservation_id, space_id))
    scrollToTop();
    return () => {
      dispatch(initialStateSpaceFormCustom());
      dispatch(initialStateSpace());
    };
  }, []);

  if (bookingStateCustom.fetching || spaceState.fetching) {
    return <Loader active inline />;
  }

  const onSubmit = (data) => {
    data["cruise_id"] = cruise_id;
    data["cabin_id"] = bookingStateCustom.booking.cabin_id;
    data["single"] = bookingStateCustom.booking.single;

    if (space_id) {
      data["reservation_id"] = reservation_id;
      data["booking_id"] = booking_id;
      dispatch(updateSpace(space_id, data));
    } else if (booking_id) {
      dispatch(createSpace(reservation_id, booking_id, data));
    }
  };

  if (spaceState.message === "resource created successfully" || spaceState.message === "resource updated successfully") {
    history.go(-1);
  }

  if (bookingStateCustom.space.state && spaceStates === undefined) {
    setSpaceState(bookingStateCustom.space.state);
    setDisabledState(false);
  }

  if (bookingStateCustom.space.time_limit && spaceTimeLimit === undefined) {
    setSpaceTimeLimit(bookingStateCustom.space.time_limit);
    setDisabled(false);
  }

  if (bookingStateCustom.reservation.charter === 1 && bookingStateCustom.reservation.charter_state === "reserved" && bookingStateCustom.reservation.charter_time_limit && spaceStates === undefined && spaceTimeLimit === undefined) {
    setSpaceState(bookingStateCustom.reservation.charter_state);
    setSpaceState(bookingStateCustom.reservation.charter_state);
    setSpaceTimeLimit(bookingStateCustom.reservation.charter_time_limit);
    setDisabledState(true);
    setDisabled(true);
  } else if (bookingStateCustom.reservation.charter === 1 && bookingStateCustom.reservation.charter_state && spaceStates === undefined && spaceTimeLimit === undefined) {
    setSpaceState(bookingStateCustom.reservation.charter_state);
    setDisabledState(true);
    setDisabled(true);
  }

  const handleReservationState = (e) => {
    setSpaceState(e);
  };

  function calculateTimeLimitDays() {
    var currentDate = new Date();
    var startCruiseDateArray = bookingStateCustom.cruise.start_date_format.split("/");
    var startCruiseDate = new Date(startCruiseDateArray[1] + "/" + startCruiseDateArray[0] + "/" + startCruiseDateArray[2]);
    var differenceInTime = startCruiseDate.getTime() - currentDate.getTime();
    var differenceInDays = differenceInTime / (1000 * 3600 * 24);
    
    if (differenceInDays < 60) {
      return 3;
    }
    return 7;
  }

  const handleTimeLimitDate = (e) => {
    if (e === "reserved") {
      setDisabled(false);
      var currentDate = new Date();
      var timeLimitDate = new Date(currentDate.setDate(currentDate.getDate() + calculateTimeLimitDays()));
      var options = { year: "numeric", month: "2-digit", day: "2-digit" };
      setValue("time_limit", timeLimitDate.toLocaleDateString("es", options).split("/").reverse().join("-"));
    } else {
      setValue("time_limit","");
      setDisabled(true);
    }
  };

  return (
    <>
      {bookingStateCustom.error ? (
        <Message negative content={t(bookingStateCustom.error)} />
      ) : (
        ""
      )}

      <Breadcrumb>
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/cruise-detail`} className="section">{bookingStateCustom.cruise.code}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/reservations`} className="section"> {t("reservation_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings`} className="section">  {t("cabin_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active> {t("cabin_cabin")} {bookingStateCustom.booking.cabin_name}</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces`} className="section">  {t("space_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active> {t("space_path")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <HeadDetail
        yacht_name={bookingStateCustom.cruise.yacht_name}
        itinerary_name={bookingStateCustom.cruise.itinerary_name}
        start_date={bookingStateCustom.cruise.start_date_format}
        end_date={bookingStateCustom.cruise.end_date_format}
      />

      <HeadDetailReservation
        reservation_date={bookingStateCustom.reservation.creation_date}
        reservation_agency={bookingStateCustom.reservation.agency_name}
        reservation_executive={bookingStateCustom.reservation.user}
        reservation_type={bookingStateCustom.reservation.cruise_type}
      />

      <br />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header as='h3' dividing>{t("general_information_tag")}</Header>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("space_state")}</label>
            <select aria-expanded="true" name="state" disabled={
                space_id && bookingStateCustom.space.state === "confirmed"
                  ? true
                  : disabledState === true
                  ? true
                  : false
              }
              defaultValue={spaceStates}
              ref={register({ required: true })}
              onChange={(e) => {
                handleReservationState(e.target.value);
                handleTimeLimitDate(e.target.value);
              }}
            >
              <option></option>
              {reserveStates.map((reserveState, index) => (
                <option className="selected item" key={index} value={reserveState.value}>
                  {t(reserveState.name)}
                </option>
              ))}
            </select>
            {errors.state && (<span className="error_message">{t("required_information")}{" "}</span>)}
          </Form.Field>

          <Form.Field>
            <label>
              {spaceStates === "reserved"
                ? t("space_time_limit") + " *"
                : t("space_time_limit")}{" "}
              <span className="text_date_format"> (dd-mm-yyyy)</span>
            </label>

            <input type="date" disabled={disabled} name="time_limit" defaultValue={spaceTimeLimit}
              ref={register(
                spaceStates === "reserved"
                  ? { required: true }
                  : { required: false }
              )}
            />

            {spaceStates === "reserved"
              ? errors.time_limit && (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                )
              : ""}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("space_passenger") + " *"}</label>
            <select aria-expanded="true" name="gender_id" defaultValue={bookingStateCustom.space.gender_id} ref={register({ required: true })}>
              <option></option>
              {bookingStateCustom.genders.map((gender) => (
                <option key={gender.id} value={gender.id}>{t(gender.name)}</option>
              ))}
            </select>
            {errors.gender_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>

          <Form.Field>
            <label>{t("passenger_nationality") + " *"}</label>
            <select aria-expanded="true" name="passenger_nationality" defaultValue={bookingStateCustom.space.passenger_nationality} ref={register({ required: true })}>
              <option></option>
              {passengerNationalities.map((nationality, index) => (
                <option key={index} value={nationality.value}>{t(nationality.name)}</option>
              ))}
            </select>
            {errors.passenger_nationality && (<span className="error_message">{t("required_information")}</span>
            )}
          </Form.Field>
          
          <Form.Field>
            {bookingStateCustom.contractRate !== "resource not found" ? (
              <>
                <label>{t("contract_contract")}
                  <ContractRatePopup charter_client={bookingStateCustom.contractRate.charter_client} double_client={bookingStateCustom.contractRate.double_client} single_client={bookingStateCustom.contractRate.single_client} airplane_ticket={bookingStateCustom.contractRate.airplane_ticket} />
                </label>
                <div className="ui checkbox">
                  <input type="checkbox" name="contract_rate" defaultChecked={bookingStateCustom.space.contract === 1} ref={register} />
                  <label></label>
                </div>
              </>
            ) : (
              null
            )}
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />
    
        <Link to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces`}><Button>{t("canceled_button")}</Button></Link>
        <Button primary type="submit" loading={bookingStateCustom.processing ? true : false}>{t("save_button")}</Button>
      </Form>
    </>
  );
}
