import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customDayForm = createSlice({
  name: 'customDayForm',
  initialState: {
    itinerary: {},
    day: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateDayFormCustom(state, action) {
      return {
        itinerary: {},
        day: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesDayFormCustom(state, action) {
      return {
        itinerary: {},
        day: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchDayFormCustomSuccess(state, action) {
      return {
        itinerary: action.payload.itinerary,
        day: action.payload.day,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchDayFormCustomError(state, action) {
      return {
        itinerary: {},
        day: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchDayFormCustom = (itinerary_id, day_id) => dispatch => {
  dispatch(requesDayFormCustom());
  axios.get(`/itinerary/${itinerary_id}/form/daysForm?day_id=${day_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchDayFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchDayFormCustom(itinerary_id, day_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchDayFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateDayFormCustom,
  requesDayFormCustom,
  fetchDayFormCustomSuccess,
  fetchDayFormCustomError,
} = customDayForm.actions;

export default customDayForm.reducer;