
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal } from "semantic-ui-react";
import { useForm } from "react-hook-form";

//Actions
import { activeGeneralRate } from "../../redux/actions/rates/generalRates/activeGeneralRate";
import { inactiveGeneralRate } from "../../redux/actions/rates/generalRates/inactiveGeneralRate";
import { activeFamilyRoomRate } from "../../redux/actions/rates/familyRoom/activeFamilyRoom";
import { inactiveFamilyRoomRate } from "../../redux/actions/rates/familyRoom/inactiveFamilyRoom";
import { activeCharterRate } from "../../redux/actions/rates/charterRates/activeCharterRate";
import { inactiveCharterRate } from "../../redux/actions/rates/charterRates/inactiveCharterRate";
import { activeContractRate } from "../../redux/actions/rates/contractRates/activeContractRate";
import { inactiveContractRate } from "../../redux/actions/rates/contractRates/inactiveContractRate";
import { activeIkarusRate } from "../../redux/actions/rates/ikarusRates/activeIkarusRate";
import { inactiveIkarusRate } from "../../redux/actions/rates/ikarusRates/inactiveIkarusRate";
import { activeTicket } from "../../redux/actions/rates/ticket/activeTicket";
import { inactiveTicket } from "../../redux/actions/rates/ticket/inactiveTicket";

export default function ChangeStateRateModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false)
  const { register, handleSubmit, errors, getValues } = useForm();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    switch (props.typeRate) {
      case "GeneralRate":
        submitGeneralRate(data);
        break;
      case "FamilyRoomRate":
        submitFamilyRoomRate(data);
        break;
      case "CharterRate":
        submitCharterRate(data);
        break;
      case "ContractRate":
        submitContractRate(data);
        break;
      case "IkarusRate":
        submitIkarusRate(data);
        break;
      case "TicketRate":
        submitTicketRate(data);
        break;
    }
  };

  function submitGeneralRate(data) {
    if (props.stateRate.active == null) {
      dispatch(activeGeneralRate(props.stateRate.id));
    }

    if (props.stateRate.active) {
      dispatch(inactiveGeneralRate(props.stateRate.id, data));
    }
  }

  function submitFamilyRoomRate(data) {
    if (props.stateRate.active == null) {
      dispatch(activeFamilyRoomRate(props.stateRate.id));
    }

    if (props.stateRate.active) {
      dispatch(inactiveFamilyRoomRate(props.stateRate.id, data));
    }
  }

  function submitCharterRate(data) {
    if (props.stateRate.active == null) {
      dispatch(activeCharterRate(props.stateRate.id));
    }

    if (props.stateRate.active) {
      dispatch(inactiveCharterRate(props.stateRate.id, data));
    }
  }

  function submitContractRate(data) {
    if (props.stateRate.active == null) {
      dispatch(activeContractRate(props.stateRate.id));
    }

    if (props.stateRate.active) {
      dispatch(inactiveContractRate(props.stateRate.id, data));
    }
  }

  function submitIkarusRate(data) {
    if (props.stateRate.active == null) {
      dispatch(activeIkarusRate(props.stateRate.id));
    }

    if (props.stateRate.active) {
      dispatch(inactiveIkarusRate(props.stateRate.id, data));
    }
  }

  function submitTicketRate(data) {
    if (props.stateRate.active == null) {
      dispatch(activeTicket(props.stateRate.id));
    }

    if (props.stateRate.active) {
      dispatch(inactiveTicket(props.stateRate.id, data));
    }
  }

  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={ <Button circular color={props.stateRate.active ? 'green' : 'grey'} icon={props.stateRate.active ? 'toggle on' : 'toggle off'} />}
      >
        <Modal.Header>
          {props.stateRate.active == true ? t("deactivate_rate") : t("active_rate")}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group widths="equal">
                <Form.Field>
                  {props.stateRate.active == true ? (
                    <>
                      <label>{t("confirm_deactivate_rate")}</label>
                      <textarea name="reason_deactivation" placeholder={t("reason_deactivation")} ref={register({ required: true })}/>
                      {errors.reason_deactivation && (<span className="error_message">{t("required_information")}</span>)}
                    </>
                  ):(
                    <label>{t("confirm_active_rate")}</label>
                  )}
                </Form.Field>
              </Form.Group>
              <Button primary type="submit" loading={props.stateRate.processing ? true : false}>
                {props.stateRate.active == true ? t("deactivate_rate") : t("active_rate")}
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            {t("canceled_button")}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
  