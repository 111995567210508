import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class TicketService {
  static getYears() {
    return axios.get("tickets/year");
  }

  static getTickets(payload) {
    const params = new URLSearchParams([['year', payload.year], ['airline_id', payload.airline_id]]);
    return axios.get("tickets", { params });
  }
  static getTicket(ticket_id) {
    return axios.get("tickets/" + ticket_id);
  }

  static postTicket(payload) {
    return axios.post("tickets", payload);
  }

  static putTicket(ticket_id, payload) {
    return axios.put("tickets/" + ticket_id, payload);
  }

  static activeTicket(ticket_id) {
    return axios.put("tickets/" + ticket_id + "/activeTicketRate");
  }

  static inactiveTicket(ticket_id, payload) {
    return axios.put("tickets/" + ticket_id + "/inactiveTicketRate", payload);
  }

  static deleteTicket(ticket_id) {
    return axios.delete("tickets/" + ticket_id);
  }
}
