import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ReservationService from "../../../../services/reservations/ReservationService";

export const REQUEST_FETCH_RESERVATION = "REQUEST_FETCH_RESERVATION";
export const FETCH_RESERVATION_SUCCESS = "FETCH_RESERVATION_SUCCESS";
export const FETCH_RESERVATION_ERROR = "FETCH_RESERVATION_ERROR";
export const INITIAL_STATE_RESERVATION = "INITIAL_STATE_RESERVATION";

function requestFetchReservation() {
  return {
    type: REQUEST_FETCH_RESERVATION,
  };
}

function fetchReservationSuccess(json) {
  return {
    type: FETCH_RESERVATION_SUCCESS,
    reservation: json.data,
  };
}

function fetchReservationError(json) {
  return {
    type: FETCH_RESERVATION_ERROR,
    error: json.data,
  };
}

export function initialStateReservation() {
  return {
    type: INITIAL_STATE_RESERVATION,
  };
}

export function fetchReservation(reservation_id) {
  return (dispatch) => {
    dispatch(requestFetchReservation());
    return ReservationService.getReservation(reservation_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchReservationSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchReservation(reservation_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchReservationError(json.data));
      }
    });
  };
}
