import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class RateService {
  static getRates(specialRate) {
    var url = "rates";
    url = url + `?filter=${specialRate}`;
    return axios.get(url);
  }
  static getRate(rate_id) {
    return axios.get("rates/" + rate_id);
  }

  static postRate(payload) {
    return axios.post("rates", payload);
  }

  static putRate(rate_id, payload) {
    return axios.put("rates/" + rate_id, payload);
  }

  static deleteRate(rate_id) {
    return axios.delete("rates/" + rate_id);
  }
}
