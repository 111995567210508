import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class CabinBerthService {
  static getCabinBerths(cabin_id) {
    return axios.get("cabin/" + cabin_id + "/berths");
  }
  static getCabinBerth(cabin_id, id) {
    return axios.get("cabin/" + cabin_id + "/berth/" + id);
  }

  static postCabinBerth(cabin_id, payload) {
    return axios.post("cabin/" + cabin_id + "/berths", payload);
  }

  static putCabinBerth(cabin_id, id, payload) {
    return axios.put(
      "cabin/" + cabin_id + "/berth/" + id,
      payload
    );
  }

  static deleteCabinBerth(cabin_id, id) {
    return axios.delete("cabin/" + cabin_id + "/berth/" + id);
  }
}
