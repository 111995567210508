import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import LanguageService from "../../../../services/catalogs/LanguageService";

export const REQUEST_UPDATE_LANGUAGE = "REQUEST_UPDATE_LANGUAGE";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";
export const UPDATE_LANGUAGE_ERROR = "UPDATE_LANGUAGE_ERROR";

function requestUpdateLanguage() {
  return {
    type: REQUEST_UPDATE_LANGUAGE,
  };
}

function updateLanguageSuccess(json) {
  return {
    type: UPDATE_LANGUAGE_SUCCESS,
    message: json.data,
  };
}

function updateLanguageError(json) {
  return {
    type: UPDATE_LANGUAGE_ERROR,
    error: json.data,
  };
}

export function updateLanguage(language_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateLanguage());
    return LanguageService.putLanguage(language_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateLanguageSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateLanguage(language_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateLanguageError(json.data));
      }
    });
  };
}
