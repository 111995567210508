import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class GeneralRateCabinService {
  static getGeneralRateCabins(generalRate_id) {
    const url = "generalRate/" + generalRate_id + "/cabins";
    return axios.get(url);
  }
  static postGeneralRateCabin(generalRate_id, payload) {
    const url = "generalRate/" + generalRate_id + "/cabins"
    return axios.post(url, payload);
  }
  static deleteGeneralRateCabin(generalRateCabin_id) {
    const url = "generalRateCabin/" + generalRateCabin_id;
    return axios.delete(url);
  }
}
