import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//UI
import { Button, Modal, Icon, Table, Input, Header, Loader, Breadcrumb, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { initialStateYachtItinerary } from "../../../redux/actions/catalogs/yachtItinerary/fetchYachtItinerary";
import { deleteYachtItinerary } from "../../../redux/actions/catalogs/yachtItinerary/deleteYachtItinerary";
import { fetchYachtItineraryListCustom } from "../../../redux/slices/custom/catalogs/yachtItinerary/customYachtItineraryList.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function YachtItineraryList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let { itinerary_id } = useParams();

  const yachtItineraryStateCustom = useSelector((state) => state.customYachtItineraryList);
  const yachtItineraryState = useSelector((state) => state.yachtItineraryReducer);
  
  useEffect(() => {
    dispatch(fetchYachtItineraryListCustom(itinerary_id));
    scrollToTop();
  }, []);

  if (yachtItineraryStateCustom.fetching || yachtItineraryState.fetching) {
    return <Loader active inline />;
  }

  if (yachtItineraryStateCustom.error) {
    return <div>Error: {yachtItineraryStateCustom.error}</div>;
  }

  if (yachtItineraryState.message === "resource deleted successfully") {
    dispatch(fetchYachtItineraryListCustom(itinerary_id));
    dispatch(initialStateYachtItinerary());
  }

  const handleDeleteYachtItinerary = (id) => {
    dispatch(deleteYachtItinerary(itinerary_id, id));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/itineraries" className="section">{t("itinerary_title")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{yachtItineraryStateCustom.itinerary.name}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <HeaderCatalog title={t("itinerary_yacht_title")} message={yachtItineraryStateCustom.message} to={`/itineraries/${itinerary_id}/yachts_new`} />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("itinerary_yacht_name")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
        {yachtItineraryStateCustom.yachtItineraries.map((itinerary) =>
          itinerary.yachts.map((yacht) => (
            <Table.Row key={yacht.id}>
              <Table.Cell>{yacht.yacht_name}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell collapsing>
                <Link to={`/itineraries/${itinerary_id}/yachts/${yacht.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
                <Modal
                  trigger={
                    <Button circular color='red' icon='trash alternate' />
                  }
                  content={t("itinerary_yacht_delete")}
                  actions={[
                    t("canceled_button"),
                      {
                        key: "eliminar",
                        content: t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handleDeleteYachtItinerary(yacht.id),
                      },
                  ]}
                  size="tiny"
                />
              </Table.Cell>
            </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </>
  );
}
