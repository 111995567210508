import React, { useEffect, useState } from "react";
import { Tab, Image, Button, Modal, Icon, Card } from "semantic-ui-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import tt41 from "../images/yacht/TTIV/thumb_TIP TOP IV 2.jpg";
import tt42 from "../images/yacht/TTIV/thumb_TIP TOP IV 15.jpg";
import tt43 from "../images/yacht/TTIV/thumb_TIP TOP IV 16.jpg";
import deck from "../images/deckPlan/TIP-TOP-IV-DECK.jpg";
import tt4 from "../images/TTIV.zip";
import "../../../styles/common.css";
import useStyles from "../../../styles/Common";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchItineraries,
  initialStateItineraries,
} from "../../../../redux/actions/b2b/yachts/fetchItineraries";
import { fetchDays } from "../../../../redux/actions/catalogs/day/fetchDays";
import { initialStateDay } from "../../../../redux/actions/catalogs/day/fetchDay";
import {
  fetchImage,
  initialStateImage,
} from "../../../../redux/actions/catalogs/itinerary/fetchImage";
import {
  fetchDaysByItinerary,
  initialStateDaysByItinerary,
} from "../../../../redux/actions/catalogs/day/fetchDaysByItinerary";
import { API_BASE_URL } from "../../../../services/constants";
import ItineraryPDF from "./ItineraryPDF";

//TRASLATION
import { useTranslation } from "react-i18next";

export default function TipTopIV() {
  const classes = useStyles();
  const yachtName = "TIP TOP IV";
  const { t } = useTranslation();
  const itineraryState = useSelector((state) => state.yachtsB2BReducer);
  const dayState = useSelector((state) => state.dayReducer);
  const imageState = useSelector((state) => state.imageReducer);
  const daysByItineraryState = useSelector(
    (state) => state.daysByItineraryReducer
  );
  const [isItineraryLoading, setIsItineraryLoading] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchItineraries(yachtName));

    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }

    return () => {
      dispatch(initialStateImage());
      dispatch(initialStateDaysByItinerary());
      dispatch(initialStateDay());
      dispatch(initialStateItineraries());
    };
  }, [dispatch, yachtName]);

  const getDays = (itinerary_id) => {
    dispatch(fetchDays(itinerary_id));
  };

  const getMap = (itinerary_id) => {
    dispatch(fetchImage(itinerary_id));
  };

  const getPDF = (itinerary_id) => {
    setIsItineraryLoading(itinerary_id);
    dispatch(fetchDaysByItinerary(yachtName, itinerary_id));
    dispatch(fetchImage(itinerary_id));
  };

  if (daysByItineraryState.message === "resource found successfully") {
    ItineraryPDF(daysByItineraryState.days, imageState.image);
    dispatch(initialStateImage());
    dispatch(initialStateDaysByItinerary());
  }

  const panes = [
    {
      menuItem: t("b2b_ship"),
      pane: (
        <Tab.Pane key="section1">
          <div className="yacht_image">
            <a href={tt4} download="tt2.zip" className="download_button">
              <Button color="blue">{t("download_button")}</Button>
            </a>
            <br />
            <br />
            <Image.Group size="medium">
              <Image src={tt41} />
              <Image src={tt42} />
              <Image src={tt43} />
              <Image src={tt42} />
              <Image src={tt43} />
              <Image src={tt41} />
            </Image.Group>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: t("b2b_cabins"),
      pane: (
        <Tab.Pane key="section2">
          <div className="yacht_image">
            <Image.Group size="medium">
              <span className="container">
                <Image src={tt41} className="image" />
                <a href={tt41} download="tt41">
                  <Button
                    color="blue"
                    icon="cloud download"
                    className="download"
                    size="massive"
                  />
                </a>
              </span>
              <span className="container">
                <Image src={tt42} className="image" />
                <a href={tt42} download="tt42">
                  <Button
                    color="blue"
                    icon="cloud download"
                    className="download"
                    size="massive"
                  />
                </a>
              </span>
              <span className="container">
                <Image src={tt43} className="image" />
                <a href={tt43} download="tt43">
                  <Button
                    color="blue"
                    icon="cloud download"
                    className="download"
                    size="massive"
                  />
                </a>
              </span>
            </Image.Group>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: t("b2b_deck"),
      pane: (
        <Tab.Pane key="section3">
          <div className="yacht_image">
            <Image.Group size="large" style={{ paddingLeft: "20%" }}>
              <div className="container">
                <Image src={deck} className="image" />
                <a href={deck} download="deck_plan">
                  <Button
                    color="blue"
                    icon="cloud download"
                    className="download_deck"
                    size="massive"
                  />
                </a>
              </div>
            </Image.Group>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: t("b2b_description"),
      pane: (
        <Tab.Pane key="section4">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
            laudantium incidunt ab aliquam, neque veritatis obcaecati officia
            asperiores soluta hic cumque, qui sunt ut porro, nisi quis
            voluptatibus quibusdam quod. Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Perferendis ipsa quod cum quam ea eos provident,
            incidunt sapiente ut deleniti reprehenderit itaque distinctio porro,
            ex alias dolore! Sit, pariatur temporibus. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. A voluptates iste cumque facilis
            dolorem consequatur quam vel quisquam impedit ut, recusandae neque
            voluptatibus fuga cum? Ipsam voluptas dolor ipsa pariatur?
          </p>
        </Tab.Pane>
      ),
    },
    {
      menuItem: t("b2b_itineraries"),
      pane: (
        <Tab.Pane key="section5">
          <TableContainer component={Paper}>
            <Toolbar>
              <Typography className={classes.title} variant="h6" noWrap>
                {t("b2b_itineraries")}
              </Typography>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Buscar…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
            </Toolbar>
            <Divider variant="middle" />
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("b2b_itinerary_name")}</TableCell>
                  <TableCell>{t("b2b_duration")}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itineraryState.itineraries.map((itinerary) => (
                  <TableRow key={itinerary.id}>
                    <TableCell>{itinerary.name}</TableCell>
                    <TableCell>
                      {itinerary.number_days + "D"}/
                      {itinerary.number_nights + "N"}
                    </TableCell>

                    <TableCell align="right">
                      <Modal
                        trigger={
                          <Button
                            className={
                              dayState.processing
                                ? "ui loading button summary_button"
                                : "ui button summary_button"
                            }
                            onClick={() => getDays(itinerary.id)}
                            icon
                          >
                            <Icon
                              name="wordpress forms"
                              className="icon_color"
                            />
                          </Button>
                        }
                        content={
                          <TableContainer component={Paper}>
                            <Toolbar>
                              <Typography
                                className={classes.title}
                                variant="h6"
                                noWrap
                              >
                                {t("b2b_sumary_itinerary")}
                              </Typography>
                            </Toolbar>
                            <Divider variant="middle" />
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>{t("day_name")}</TableCell>
                                  <TableCell>{t("Am")}</TableCell>
                                  <TableCell>{t("Pm")}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dayState.days.map((dayItem) =>
                                  dayItem.days.map((day) => (
                                    <TableRow key={day.id}>
                                      <TableCell>{t(day.name)}</TableCell>
                                      <TableCell>{day.am}</TableCell>
                                      <TableCell>{day.pm}</TableCell>
                                    </TableRow>
                                  ))
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        }
                        actions={[t("b2b_close_button")]}
                        size="small"
                        centered
                      />
                      <Modal
                        trigger={
                          <Button
                            className={
                              dayState.processing
                                ? "ui loading button edit_button"
                                : "ui button edit_button"
                            }
                            onClick={() => getMap(itinerary.id)}
                            icon
                          >
                            <Icon name="map" className="icon_color" />
                          </Button>
                        }
                        content={
                          imageState.image === "" ? (
                            <div
                              style={{
                                paddingLeft: "90px",
                                paddingTop: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <Card
                                style={{
                                  width: "350px",
                                  height: "370px",
                                }}
                              >
                                <Card.Content
                                  style={{
                                    paddingLeft: "110px",
                                    paddingTop: "20px",
                                    paddingBottom: "20px",
                                  }}
                                >
                                  <p>No hay imágenes</p>
                                </Card.Content>
                              </Card>
                            </div>
                          ) : (
                            <div
                              style={{
                                paddingLeft: "90px",
                                paddingTop: "20px",
                                paddingBottom: "20px",
                              }}
                            >
                              <Card
                                style={{
                                  width: "350px",
                                  height: "370px",
                                }}
                              >
                                <Image
                                  src={API_BASE_URL + imageState.image}
                                  style={{
                                    width: "350px",
                                    height: "370px",
                                  }}
                                />
                              </Card>
                            </div>
                          )
                        }
                        actions={[t("b2b_close_button")]}
                        size="tiny"
                        centered
                      />
                      <Button
                        className={
                          daysByItineraryState.fetching &&
                          imageState.fetching &&
                          itinerary.id === isItineraryLoading
                            ? "ui loading delete_button"
                            : "ui delete_button"
                        }
                        onClick={() => getPDF(itinerary.id)}
                        icon
                      >
                        <Icon className="icon_color" name="file pdf" />
                      </Button>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Tab.Pane>
      ),
    },
    {
      menuItem: t("b2b_information"),
      pane: { key: "tab1", content: "This is a massive tab", size: "massive" },
    },
  ];

  return <Tab panes={panes} renderActiveOnly={false} />;
}
