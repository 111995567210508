import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import TicketService from "../../../../services/rates/TicketService";

export const REQUEST_FETCH_TICKETS = "REQUEST_FETCH_TICKETS";
export const FETCH_TICKETS_SUCCESS = "FETCH_TICKETS_SUCCESS";
export const FETCH_TICKETS_ERROR = "FETCH_TICKETS_ERROR";

function requestFetchTickets() {
  return {
    type: REQUEST_FETCH_TICKETS,
  };
}

function fetchTicketsSuccess(json) {
  return {
    type: FETCH_TICKETS_SUCCESS,
    tickets: json.data,
  };
}

function fetchTicketsError(json) {
  return {
    type: FETCH_TICKETS_ERROR,
    error: json.data,
  };
}

export function fetchTickets(payload) {
  return (dispatch) => {
    dispatch(requestFetchTickets());
    return TicketService.getTickets(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchTicketsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchTickets(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchTicketsError(json.data));
      }
    });
  };
}
