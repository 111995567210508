import {
  REQUEST_FETCH_HISTORICAL_RESERVATIONS,
  FETCH_HISTORICAL_RESERVATIONS_SUCCESS,
  FETCH_HISTORICAL_RESERVATIONS_ERROR,
} from "../../../actions/b2b/availability/fetchHistoricalReservationsByAgency";

const initialState = {
  historicalReservations: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const availabilityHistoricalReservationsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case REQUEST_FETCH_HISTORICAL_RESERVATIONS: {
      return {
        ...state,
        historicalReservations: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_HISTORICAL_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        historicalReservations: action.historicalReservations,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_HISTORICAL_RESERVATIONS_ERROR: {
      return {
        ...state,
        historicalReservations: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default availabilityHistoricalReservationsReducer;
