import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createHotelier } from "../../../redux/actions/catalogs/hotelier/createHotelier";
import { fetchHotelier, initialStateHotelier } from "../../../redux/actions/catalogs/hotelier/fetchHotelier";
import { updateHotelier } from "../../../redux/actions/catalogs/hotelier/updateHotelier";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function HotelierForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { hotelier_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const hotelierState = useSelector((state) => state.hotelierReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hotelier_id) {
      dispatch(fetchHotelier(hotelier_id));
    }
    scrollToTop();

    return () => {
      dispatch(initialStateHotelier());
    };
  }, []);

  if (hotelierState.fetching) {
    return <Loader active inline />;
  }

  if (hotelierState.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (hotelier_id) {
      dispatch(updateHotelier(hotelier_id, data));
    } else {
      dispatch(createHotelier(data));
    }
  };

  if (hotelierState.message === "resource created successfully" || hotelierState.message === "resource updated successfully") {
    history.push("/hoteliers");
  }

  return (
    <>
      <Header as='h3' dividing>{t("hotelier_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("hotelier_name")}</label>
            <input name="first_name" defaultValue={hotelierState.hotelier.first_name} placeholder={t("hotelier_name")} maxLength={100} ref={register({ required: true })} autoFocus />
            {errors.first_name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("hotelier_lastName")}</label>
            <input name="last_name" defaultValue={hotelierState.hotelier.last_name} placeholder={t("hotelier_lastName")} maxLength={100} ref={register({ required: true })} />
            {errors.last_name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>{t("active_tag")}</label>
          <div className="ui checkbox">
            <input type="checkbox" name="active" defaultChecked={hotelierState.hotelier.active === 1} ref={register} />
            <label></label>
          </div>
        </Form.Field>

        <Divider variant="middle" />

        <Button primary type="submit" loading={hotelierState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/hoteliers"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
