import jsPDF from "jspdf";
import "jspdf-autotable";
import { API_BASE_URL } from "../../../../services/constants";

export default function ItineraryPDF(itinerary, image) {
  var doc = new jsPDF("p", "pt");
  var img = new Image();

  function addHeader(doc) {
    for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
      doc.setPage(i);
      doc.line(35, 77, 570, 77);

      doc.setFontSize(15);
      doc.setFontStyle("bold");
      doc.text(itinerary[0].yacht_code, 560, 30, "right");
      doc.text(
        itinerary[0].number_nights +
          " nights/ " +
          itinerary[0].number_days +
          " days Itinerary",
        560,
        50,
        "right"
      );
      doc.text(itinerary[0].route, 560, 70, "right");
    }
  }

  //TITLE
  doc.setFontSize(15);
  doc.setFontStyle("bold");
  doc.text(
    itinerary[0].number_nights +
      " NIGHTS/" +
      itinerary[0].number_days +
      " DAYS CRUISE",
    200,
    100
  );
  doc.setFontSize(13);
  doc.setFontStyle("bold");
  doc.text(
    "(" +
      itinerary[0].name.charAt(0).toUpperCase() +
      itinerary[0].name.slice(1) +
      " - " +
      [...itinerary].pop().name.charAt(0).toUpperCase() +
      [...itinerary].pop().name.slice(1) +
      ")",
    230,
    120
  );

  //MAP IMAGE
  if (image !== "") {
    img.src = API_BASE_URL + image;
    doc.addImage(img, "JPEG", 155, 140, 280, 280);
  } else {
    doc.setFontSize(13);
    doc.setFontStyle("normal");
    doc.text("Not image", 250, 340);
  }

  //TABLE
  var columns = [
    { title: "DAY", dataKey: "day" },
    { title: "AM", dataKey: "am" },
    { title: "PM", dataKey: "pm" },
  ];
  var rows = [];

  itinerary.map(function (item) {
    var day = item.name.toUpperCase();
    var am = item.am;
    var pm = item.pm;

    var data = {
      day: day,
      am: am,
      pm: pm,
    };
    return rows.push(data);
  });

  doc.autoTable(columns, rows, {
    startY: 520,
    theme: "striped",
    styles: {
      overflow: "linebreak",
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: 9,
      fontStyle: "bold",
      halign: "center",
      valign: "middle",
    },

    bodyStyles: {
      fillColor: [153, 204, 255],
    },

    headStyles: {
      fillColor: [255, 255, 0],
      textColor: [0, 0, 0],
    },
  });

  doc.addPage();
  //BODY

  itinerary.map(function (item, index) {
    var columnsBody = [];
    var rowsBody = [];

    var title =
      item.name.charAt(0).toUpperCase() +
      item.name.slice(1) +
      ", Day" +
      item.number +
      ".- " +
      item.am +
      " - " +
      item.pm;
    var dataKey = "detail";
    var detail = item.detail;

    var head = {
      title: title,
      dataKey: dataKey,
    };
    columnsBody.push(head);

    var data = {
      detail: detail,
    };
    rowsBody.push(data);
    return doc.autoTable(columnsBody, rowsBody, {
      startY: index === 0 ? 90 : doc.lastAutoTable.finalY + 20,
      theme: "plain",
      headStyles: {
        fontSize: 9,
        fontStyle: "bold",
      },
    });
  });

  // GENERATE PDF
  addHeader(doc);
  doc.save("Itinerary.pdf");
}
