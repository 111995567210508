import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { initialStateContractRate } from "../../../redux/actions/rates/contractRates/fetchContractRate";
import { createContractRate } from "../../../redux/actions/rates/contractRates/createContractRate";
import { updateContractRate } from "../../../redux/actions/rates/contractRates/updateContractRate";
import { fetchContractRateFormCustom, initialStateContractRateFormCustom } from "../../../redux/slices/custom/rates/contractRate/customContractRateForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";
import { years } from "../../resources/optionsList";

export default function ContractForm() {
  const sixDotTwoRegex = new RegExp(/^\d{0,6}(\.\d{0,2})?$/);
  const { t } = useTranslation();
  let history = useHistory();
  let { contract_id } = useParams();
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  let passengerCapacity = [];

  const contractStateCustom = useSelector((state) => state.customContractRateForm);
  const yachtState = useSelector((state) => state.yachtReducer);
  const contractState = useSelector((state) => state.contractRateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContractRateFormCustom(contract_id,""));
    scrollToTop();
    return () => {
      dispatch(initialStateContractRateFormCustom());
      dispatch(initialStateContractRate());
    };
  }, []);

  // General functions
  function calculateDiscount(value, percent) {
    return (value * (1 - (percent * 0.01))).toFixed(2);
  }

  function calculatePercentDiscount(childRate, doubleRate) {
    return ((1 - (childRate / doubleRate)) / 0.01).toFixed(2);
  }

  // Range1 functions
  function calculateCharterOperatorRate() {
    setValue("charter_operator", calculateDiscount(getValues("charter_client"), getValues("operator_percent")));
  }

  function calculateDoubleOperatorRate() {
    setValue("double_operator", calculateDiscount(getValues("double_client"), getValues("operator_percent")));
  }

  function calculateSingleOperatorRate() {
    setValue("single_operator", calculateDiscount(getValues("single_client"), getValues("operator_percent")));
  }

  function calculateOperatorRate() {
    calculateCharterOperatorRate();
    calculateDoubleOperatorRate();
    calculateSingleOperatorRate();
  }

  function calculateOperatorPercent() {
    setValue("operator_percent", calculatePercentDiscount(getValues("charter_operator"), getValues("charter_client")));
    calculateDoubleOperatorRate();
    calculateSingleOperatorRate();
  }

  if (contractStateCustom.fetching || contractState.fetching) {
    return <Loader active inline />;
  }

  if (contractStateCustom.error) {
    return <div>Error: {contractStateCustom.error}</div>;
  }

  for (var i = 1; i <= yachtState.yacht.availability; i++) {
    passengerCapacity.push(i);
  }

  const onSubmit = (data) => {
    if (contract_id) {
      dispatch(updateContractRate(contract_id, data));
    } else {
      dispatch(createContractRate(data));
    }
  };

  if (contractState.message === "resource created successfully" || contractState.message === "resource updated successfully") {
    let contractRateFilters = {
      year: getValues("year"),
      yacht: getValues("yacht_id"),
      agency: getValues("agency_id"),
      days: getValues("days"),
    };
    localStorage.setItem("contractRateFilters", JSON.stringify(contractRateFilters));

    history.push("/contractRates");
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/contractRates" className="section">{t("contract_title")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("contract_contract")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <Form onSubmit={handleSubmit(onSubmit)}>
          <Header as='h4' dividing color='grey'>{t("general_information_tag")}</Header>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t("contract_year")}</label>
              <select name="year" aria-expanded="true" defaultValue={contractStateCustom.contractRate.year} ref={register({ required: true })}>
                <option />
                {years.map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </select>
              {errors.year && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_yacht")}</label>
              <select name="yacht_id"  aria-expanded="true" defaultValue={contractStateCustom.contractRate.yacht_id} ref={register({ required: true })}>
                <option />
                {contractStateCustom.yachts.map((yacht, index) => (
                  <option key={index} value={yacht.id}>{yacht.name}</option>
                ))}
              </select>
              {errors.year && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_agency")}</label>
              <select name="agency_id" aria-expanded="true" defaultValue={contractStateCustom.contractRate.agency_id} ref={register({ required: true })}>
                <option />
                {contractStateCustom.agencies.map((agency, index) => (
                  <option key={index} value={agency.id}>{agency.name}</option>
                ))}
              </select>
              {errors.year && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_days")}</label>
              <select name="days" aria-expanded="true" defaultValue={contractStateCustom.contractRate.days} ref={register({ required: true })}>
                <option />
                {contractStateCustom.days.map((day, index) => (
                  <option key={index} value={day.number_days}>{t(day.number_days)}</option>
                ))}
              </select>
              {errors.days && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
          </Form.Group>

          <Header as='h4' dividing color='grey'>{t("general_clientRate")}</Header>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t("contract_charter")}</label>
              <input type="text" name="charter_client" defaultValue={contractStateCustom.contractRate.charter_client} onChange={calculateCharterOperatorRate} ref={register({ required: true, pattern: sixDotTwoRegex })}/>
              {errors.charter_client && errors.charter_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.charter_client && errors.charter_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_double")}</label>
              <input name="double_client" defaultValue={contractStateCustom.contractRate.double_client} onChange={calculateDoubleOperatorRate} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.double_client && errors.double_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.double_client && errors.double_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_single")}</label>
              <input name="single_client" defaultValue={contractStateCustom.contractRate.single_client} onChange={calculateSingleOperatorRate} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.single_client && errors.single_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.single_client && errors.single_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field>
              <label>{t("ticket_airline")}</label>
              <select name="airplane_ticket" defaultValue={contractStateCustom.contractRate.airplane_ticket} aria-expanded="true" ref={register()}>
                <option value="1">{t("yes")}</option>
                <option value="0">{t("not")}</option>
              </select>
            </Form.Field>
          </Form.Group>

          <Header as='h4' dividing color='grey'>{t("general_operatorRate")}</Header>
          <Form.Group widths="equal">

            <Form.Field width={8}>
              <label>%</label>
              <input name="operator_percent" defaultValue={contractStateCustom.contractRate.percent_operator_discount == null ?  "6.00" : contractStateCustom.contractRate.percent_operator_discount} onChange={calculateOperatorRate} ref={register()} />
              <div className="percent_label_rate">{t("contract_operatorDiscount")}</div>
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_charter")}</label>
              <input type="text" name="charter_operator" defaultValue={contractStateCustom.contractRate.charter_operator} onChange={calculateOperatorPercent} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.charter_operator && errors.charter_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.charter_operator && errors.charter_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_double")}</label>
              <input name="double_operator" defaultValue={contractStateCustom.contractRate.double_operator} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.double_operator && errors.double_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.double_operator && errors.double_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_single")}</label>
              <input name="single_operator" defaultValue={contractStateCustom.contractRate.single_operator} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.single_operator && errors.single_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.single_operator && errors.single_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>     
          </Form.Group>

          <Divider />
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("comment")}</label>
              <textarea rows="3" name="comment" defaultValue={contractStateCustom.contractRate.comment} ref={register()}/>
            </Form.Field>
          </Form.Group>
         
          <Divider variant="middle" />
         
          <Button primary type="submit" loading={contractStateCustom.processing ? true : false}>{t("save_button")}</Button>
          <Link to={"/contractRates"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
