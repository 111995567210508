import {
  REQUEST_FETCH_AVAILABLE_CABINS_BY_BOOKING,
  FETCH_AVAILABLE_CABINS_BY_BOOKING_SUCCESS,
  FETCH_AVAILABLE_CABINS_BY_BOOKING_ERROR,
} from "../../../actions/reservations/cabin/fetchAvailableCabinsByBooking";

const initialState = {
  cabins: [],
  fetching: false,
  message: "",
  error: "",
};

const cabinByBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCH_AVAILABLE_CABINS_BY_BOOKING: {
      return {
        ...state,
        cabins: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_AVAILABLE_CABINS_BY_BOOKING_SUCCESS: {
      return {
        ...state,
        cabins: action.cabins,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_AVAILABLE_CABINS_BY_BOOKING_ERROR: {
      return {
        ...state,
        cabins: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default cabinByBookingReducer;
