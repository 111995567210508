import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class YachtItineraryService {
  static getYachtItineraries(itinerary_id) {
    return axios.get("itinerary/" + itinerary_id + "/yachts");
  }
  static getYachtItinerary(itinerary_id, id) {
    return axios.get(
      "itinerary/" + itinerary_id + "/yacht/" + id
    );
  }

  static postYachtItinerary(itinerary_id, payload) {
    return axios.post(
      "itinerary/" + itinerary_id + "/yachts",
      payload
    );
  }

  static putYachtItinerary(itinerary_id, yacht_id, payload) {
    return axios.put(
      "itinerary/" + itinerary_id + "/yacht/" + yacht_id,
      payload
    );
  }

  static deleteYachtItinerary(itinerary_id, id) {
    return axios.delete(
      "itinerary/" + itinerary_id + "/yacht/" + id
    );
  }
}
