import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class CharterRateService {
  static getCharterRates(payload) {
    return axios.post("charterRates/getByBody", payload);
  }
  
  static getCharterRate(charterRate_id) {
    return axios.get("charterRates/" + charterRate_id);
  }

  static getYears() {
    return axios.get("charterRates/year");
  }

  static postCharterRate(payload) {
    return axios.post("charterRates", payload);
  }

  static putCharterRate(charterRate_id, payload) {
    return axios.put("charterRates/" + charterRate_id, payload);
  }

  static activeCharterRate(charterRate_id) {
    return axios.put("charterRates/" + charterRate_id + "/activeCharterRate");
  }

  static inactiveCharterRate(charterRate_id, payload) {
    return axios.put("charterRates/" + charterRate_id + "/inactiveCharterRate", payload);
  }

  static deleteCharterRate(charterRate_id) {
    return axios.delete("charterRates/" + charterRate_id);
  }
}
