import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideService from "../../../../services/catalogs/GuideService";

export const REQUEST_FETCH_GUIDES = "REQUEST_FETCH_GUIDES";
export const FETCH_GUIDES_SUCCESS = "FETCH_GUIDES_SUCCESS";
export const FETCH_GUIDES_ERROR = "FETCH_GUIDES_ERROR";

function requestFetchGuides() {
  return {
    type: REQUEST_FETCH_GUIDES,
  };
}

function fetchGuidesSuccess(json) {
  return {
    type: FETCH_GUIDES_SUCCESS,
    guides: json.data,
  };
}

function fetchGuidesError(json) {
  return {
    type: FETCH_GUIDES_ERROR,
    error: json.data,
  };
}

export function fetchGuides(searchWord) {
  return (dispatch) => {
    dispatch(requestFetchGuides());
    return GuideService.getGuides(searchWord).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchGuidesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGuides(searchWord));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGuidesError(json.data));
      }
    });
  };
}
