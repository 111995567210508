import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SpaceService from "../../../../services/reservations/SpaceService";

export const REQUEST_FETCH_SPACES_CANCELED = "REQUEST_FETCH_SPACES_CANCELED";
export const FETCH_SPACES_CANCELED_SUCCESS = "FETCH_SPACES_CANCELED_SUCCESS";
export const FETCH_SPACES_CANCELED_ERROR = "FETCH_SPACES_CANCELED_ERROR";
export const INITIAL_STATE_SPACE_CANCELED = "INITIAL_STATE_SPACE_CANCELED";

function requestFetchSpacesCanceled() {
  return {
    type: REQUEST_FETCH_SPACES_CANCELED,
  };
}

function fetchSpacesCanceledSuccess(json) {
  return {
    type: FETCH_SPACES_CANCELED_SUCCESS,
    spacesCanceled: json.data,
  };
}

function fetchSpacesCanceledError(json) {
  return {
    type: FETCH_SPACES_CANCELED_ERROR,
    error: json.data,
  };
}

export function initialStateSpaceCanceled() {
  return {
    type: INITIAL_STATE_SPACE_CANCELED,
  };
}

export function fetchSpacesCanceled(booking_id) {
  return (dispatch) => {
    dispatch(requestFetchSpacesCanceled());
    return SpaceService.getSpacesCanceled(booking_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchSpacesCanceledSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSpacesCanceled(booking_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSpacesCanceledError(json.data));
      }
    });
  };
}
