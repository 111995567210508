import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IkarusRateCabinService from "../../../../services/rates/IkarusRateCabinService";

export const REQUEST_CREATE_IKARUS_RATE_CABIN = "REQUEST_CREATE_IKARUS_RATE_CABIN";
export const CREATE_IKARUS_RATE_CABIN_SUCCESS = "CREATE_IKARUS_RATE_CABIN_SUCCESS";
export const CREATE_IKARUS_RATE_CABIN_ERROR = "CREATE_IKARUS_RATE_CABIN_ERROR";

function requestCreateIkarusRateCabin() {
  return {
    type: REQUEST_CREATE_IKARUS_RATE_CABIN,
  };
}

function createIkarusRateCabinSuccess(json) {
  return {
    type: CREATE_IKARUS_RATE_CABIN_SUCCESS,
    message: json.data,
  };
}

function createIkarusRateCabinError(json) {
  return {
    type: CREATE_IKARUS_RATE_CABIN_ERROR,
    error: json.data,
  };
}

export function createIkarusRatesCabin(ikarusRate_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateIkarusRateCabin());
    return IkarusRateCabinService.postIkarusRateCabin(ikarusRate_id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(createIkarusRateCabinSuccess(json.data));
        } else if(json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(createIkarusRatesCabin(ikarusRate_id, payload));
        } else if(json.data.code === 498){
          logOut();
        } else {
          dispatch(createIkarusRateCabinError(json.data));
        }
      }
    );
  };
}
