import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customIkarusRateCabinForm = createSlice({
  name: 'customIkarusRateCabinForm',
  initialState: {
    cabins: [],
    ikarusRate: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateIkarusRateCabinFormCustom(state, action) {
      return {
        cabins: [],
        ikarusRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesIkarusRateCabinFormCustom(state, action) {
      return {
        cabins: [],
        ikarusRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchIkarusRateCabinFormCustomSuccess(state, action) {
      return {
        cabins: action.payload.cabins.cabins,
        ikarusRate: action.payload.ikarusRate,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchIkarusRateCabinFormCustomError(state, action) {
      return {
        cabins: [],
        ikarusRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchIkarusRateCabinFormCustom = (ikarusRateCabin_id) => dispatch => {
  dispatch(requesIkarusRateCabinFormCustom());
  axios.get(`/ikarusRate/${ikarusRateCabin_id}/ikarusRateCabinForm`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchIkarusRateCabinFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchIkarusRateCabinFormCustom(ikarusRateCabin_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchIkarusRateCabinFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateIkarusRateCabinFormCustom,
  requesIkarusRateCabinFormCustom,
  fetchIkarusRateCabinFormCustomSuccess,
  fetchIkarusRateCabinFormCustomError,
} = customIkarusRateCabinForm.actions;

export default customIkarusRateCabinForm.reducer;