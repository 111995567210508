import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//UI
import { Form } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "semantic-ui-css/semantic.min.css";
import "../../../styles/common.css";
//ACTIONS
import { createVoucher } from "../../../../redux/actions/reservations/voucher/createVoucher";
import { initialStateVoucher } from "../../../../redux/actions/reservations/voucher/fetchVoucher";
import { updateVoucher } from "../../../../redux/actions/reservations/voucher/updateVoucher";
import { fetchPassengers, initialStatePassenger } from "../../../../redux/actions/reservations/passenger/fetchPassengersByAgency";
import { fetchVoucherFormCustom, initialStateVoucherFormCustom } from "../../../../redux/slices/custom/reservations/cruise/voucher/customVoucherForm.slice";
//RESOURCES
import HeadDetail from "../../../../components/headDetail";
import { scrollToTop } from "../../../resources/utils";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "10%",
    marginTop: -12,
    marginLeft: -82,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

export default function VoucherForm() {
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { cruise_id } = useParams();
  let { voucher_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  let start_date_format;
  let end_date_format;
  let agencies = [];
  const voucherStateCustom = useSelector((state) => state.customVoucherForm); 
  const voucherState = useSelector((state) => state.voucherReducer);
  const passengerByAgencyState = useSelector((state) => state.passengerByAgencyReducer);
  const [servicesIncluded, setServicesIncluded] = useState();
  const [active, setActive] = useState(false);
  const [passengers, setPassengers] = useState([]);
  let newPassengers = [];
  let activePassengers = [];
  const [notIncluded, setNotIncluded] = useState(
    "Galapagos National Park entrance fee US$100.00 per person, Transit Control Card fee US$20.00 per person, alcoholic and non-alcoholic beverages, tips, personal expenses and Travel insurance. Wetsuits available for rent on board previous request."
  );
  const [remark, setRemark] = useState(
    "Please be at (Quito/Guayaquil Airport at 00:00 a.m.) on (Mes/Dia/Año). Your flight leaves at (00:00 a.m). A representative from our office, (Nombre/Apellido) will meet you at AVIANCA Counter at (00:00 a.m.) His cell phone number is (teléfono). Please bring your original passport with you. FELIZ VIAJE!\nEmergency Phone:\nThis is the only valid document to board"
  );

  useEffect(() => {
    dispatch(fetchVoucherFormCustom(voucher_id, cruise_id))
    scrollToTop();
    return () => {
      dispatch(initialStateVoucherFormCustom());
      dispatch(initialStateVoucher());
      dispatch(initialStatePassenger())
    };
  }, [dispatch, cruise_id, voucher_id]);

  if (voucherStateCustom.fetching) {
    return <CircularProgress />;
  }

  function formatDate(inputDate) {
    if(inputDate) {
      return inputDate.split("/").reverse().join("-");
    }
  }

  if (voucherStateCustom.error) {
    return <div>Error: {voucherStateCustom.error}</div>;
  }

  if (voucherStateCustom.cruise.start_date_format) {
    start_date_format = formatDate(voucherStateCustom.cruise.start_date_format)
  }

  if (voucherStateCustom.cruise.end_date_format) {
    end_date_format = formatDate(voucherStateCustom.cruise.end_date_format)

  }

  voucherStateCustom.reservations.forEach((reservation) => {
    agencies.push({
      agency_id: reservation.agency_id,
      agency_name: reservation.agency_name,
    });
  });

  const handleAgencyId = (agency_id) => {
    dispatch(fetchPassengers(cruise_id, agency_id));
  };

  if (
    voucherStateCustom.passengers.length > 0 &&
    voucherStateCustom.voucherPassengers.length > 0
  ) {
    voucherStateCustom.passengers.forEach((passenger) => {
      voucherStateCustom.voucherPassengers.forEach((pass) => {
        if (passenger.id === pass.passenger_id) {
          newPassengers.push({ ...passenger, active: true });
        }
      });
    });

    activePassengers = voucherStateCustom.passengers.filter(
      (passenger) =>
        !voucherStateCustom.voucherPassengers.some(
          (pass) => passenger.id === pass.passenger_id
        )
    );

    activePassengers.forEach((passenger) => {
      newPassengers.push({ ...passenger, active: false });
    });
  }

  const handleCheck = (passengerId, check) => {
    if (check === true) {
      setPassengers(passengers.concat({ passenger_id: passengerId }));
    }
  };

  const handleUncheck = (passengerId, check) => {
    var indice;

    let exist = voucherStateCustom.voucherPassengers.filter(
      (pass) => pass.passenger_id === passengerId
    );

    if (exist.length > 0 && check === false) {
      indice = voucherStateCustom.voucherPassengers.findIndex(
        (passId) => passId.passenger_id === passengerId
      );
      voucherStateCustom.voucherPassengers.splice(indice, 1);
    } else {
      indice = passengers.findIndex(
        (passId) => passId.passenger_id === passengerId
      );
      passengers.splice(indice, 1);
    }
  };

  const onSubmit = (data) => {
    data = {
      ...data,
      passengers: [...passengers, ...voucherStateCustom.voucherPassengers],
    };

    if (voucher_id) {
      dispatch(updateVoucher(voucher_id, data));
    } else {
      dispatch(createVoucher(cruise_id, data));
    }
    setServicesIncluded(servicesIncluded);
    setNotIncluded(notIncluded);
    setRemark(remark);
  };

  if (
    voucherState.message === "resource created successfully" ||
    voucherState.message === "resource updated successfully"
  ) {
    history.push(`/cruises/${cruise_id}/vouchers`);
  }

  if (voucherStateCustom.cruise.yacht_code && servicesIncluded === undefined) {
    setServicesIncluded(
      ` Galapagos cruise on board ${voucherStateCustom.cruise.yacht_code}, all meals on board, shore excursions escorted by an English speaking guide, transfers between the islands during the cruise, snorkeling equipment, kayaks and air tickets.`
    );
  }

  return (
    <>
      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{voucherStateCustom.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <Link to={`/cruises/${cruise_id}/vouchers`}>
          <div className="active section">{t("voucher_title")}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">{t("voucher_path")}</div>
      </div>
      <br />
      <br />
      <br />
      <HeadDetail
        yacht_name={voucherStateCustom.cruise.yacht_name}
        itinerary_name={voucherStateCustom.cruise.itinerary_name}
        start_date={voucherStateCustom.cruise.start_date_format}
        end_date={voucherStateCustom.cruise.end_date_format}
      />
      <br />
      <br />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <label>{t("general_information_tag")}</label>
          <br />
          <Divider variant="fullWidth" />
          <br />
          <Form.Group widths="equal">
            <Form.Field>
              <label>
                {t("voucher_startDate")}{" "}
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </label>

              <input
                type="date"
                name="start_date"
                defaultValue={
                  voucher_id
                    ? formatDate(voucherStateCustom.voucher.start_date_format)
                    : start_date_format
                }
                ref={register}
              />
            </Form.Field>
            <Form.Field>
              <label>
                {t("voucher_endDate")}{" "}
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </label>

              <input
                type="date"
                name="end_date"
                defaultValue={
                  voucher_id
                    ? formatDate(voucherStateCustom.voucher.end_date_format)
                    : end_date_format
                }
                ref={register}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("voucher_itinerary")} *</label>
              <input
                placeholder="Itinerario"
                name="itinerary"
                defaultValue={
                  voucher_id
                    ? voucherStateCustom.voucher.itinerary
                    : voucherStateCustom.cruise.itinerary_name
                }
                ref={register({ required: true })}
              />
              {errors.itinerary && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>

            <Form.Field>
              <label>{t("voucher_agency")}*</label>
              <select
                name="agency_id"
                defaultValue={voucherStateCustom.voucher.agency_id}
                ref={register({ required: true })}
                onChange={(event) => handleAgencyId(event.target.value)}
              >
                <option></option>
                {agencies.map((agency, index) => (
                  <option
                    className="selected item"
                    key={index}
                    value={agency.agency_id}
                  >
                    {agency.agency_name}
                  </option>
                ))}
              </select>
              {errors.agency_id && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("voucher_included")}</label>
              <textarea
                rows={3}
                placeholder="Services Included"
                name="included"
                ref={register}
                defaultValue={
                  voucher_id ? voucherStateCustom.voucher.included : servicesIncluded
                }
              ></textarea>
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("voucher_notIncluded")}</label>
              <textarea
                rows={3}
                placeholder="Not Included"
                name="notIncluded"
                ref={register}
                defaultValue={
                  voucher_id ? voucherStateCustom.voucher.notIncluded : notIncluded
                }
              ></textarea>
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("voucher_wetsuits")}</label>
              <textarea
                rows={3}
                placeholder={t("voucher_wetsuits")}
                name="wetsuitsRental"
                ref={register}
                defaultValue={voucherStateCustom.voucher.wetsuitsRental}
              ></textarea>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>{t("voucher_remarks")}</label>
            <textarea
              rows={4}
              placeholder="Remarks"
              name="remarks"
              ref={register}
              defaultValue={
                voucher_id !== undefined ? voucherStateCustom.voucher.remarks : remark
              }
            ></textarea>
          </Form.Field>

          <label>
            {t("voucher_passenger")}
            {voucherStateCustom.fetching === true ? (
              <CircularProgress size={17} color="primary" />
            ) : (
              ""
            )}
          </label>
          <Divider variant="fullWidth" />
          <br />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{t("voucher_lastName")}</TableCell>
                  <TableCell>{t("voucher_name")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {voucher_id &&
                voucherStateCustom.voucherPassengers.length > 0
                  ? newPassengers.map((passenger, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <div className="ui checkbox">
                            <input
                              type="checkbox"
                              name="active"
                              defaultChecked={passenger.active}
                              onChange={(event) => {
                                setActive(event.target.checked);
                                handleCheck(passenger.id, event.target.checked);
                                handleUncheck(
                                  passenger.id,
                                  event.target.checked
                                );
                              }}
                            />
                            <label></label>
                          </div>
                        </TableCell>
                        <TableCell>{passenger.last_name}</TableCell>
                        <TableCell>{passenger.first_name}</TableCell>
                      </TableRow>
                    ))
                  : passengerByAgencyState.passengers.map(
                      (passenger, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <input
                              type="checkbox"
                              name="active"
                              defaultChecked={active}
                              onChange={(event) => {
                                setActive(event.target.checked);
                                handleCheck(passenger.id, event.target.checked);
                                handleUncheck(
                                  passenger.id,
                                  event.target.checked
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell>{passenger.last_name}</TableCell>
                          <TableCell>{passenger.first_name}</TableCell>
                        </TableRow>
                      )
                    )}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <br></br>
          <Divider variant="middle" />
          <br></br>

          <Link to={`/cruises/${cruise_id}/vouchers`}>
            <button type="button" className="ui button">
              {t("canceled_button")}
            </button>
          </Link>

          <button
            type="submit"
            className={
              voucherStateCustom.processing
                ? "ui loading primary button"
                : "ui primary button"
            }
          >
            {t("save_button")}
          </button>
        </div>
      </Form>
    </>
  );
}
