import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Icon, Form, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";
import GenericRateCell from "../../../components/rates/genericRateCell"
import StateRateCell from "../../../components/rates/stateRateCell"
import ChangeStateRateModal from "../../../components/rates/changeStateRateModal"

//Actions
import { initialStateFamilyRoom } from "../../../redux/actions/rates/familyRoom/fetchFamilyRoom";
import { deleteFamilyRoom } from "../../../redux/actions/rates/familyRoom/deleteFamilyRoom";
import { fetchFamilyRoomListCustom, initialStateFamilyRoomListCustom } from "../../../redux/slices/custom/rates/familyRoom/customFamilyRoomList.slice";
// Resources
import { scrollToTop } from "../../resources/utils";

export default function FamilyRoomList() {
  const { t } = useTranslation();
  let actualDate = new Date();
  let currentYear = actualDate.getFullYear();
  let rateType = "";
  let yacht = "";
  let days = "all";

  const familyRoomRateFilters = JSON.parse(localStorage.getItem("familyRoomRateFilters"));
  if (familyRoomRateFilters) {
    currentYear = familyRoomRateFilters.year;
    rateType = familyRoomRateFilters.rate;
    yacht = familyRoomRateFilters.yacht;
    days = familyRoomRateFilters.days;
  }

  const { register, handleSubmit, errors, getValues } = useForm();
  const familyRoomRateStateCustom = useSelector((state) => state.customFamilyRoomList);
  const familyRoomRateState = useSelector((state) => state.familyRoomRateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFamilyRoomListCustom({year: currentYear, rate_id: rateType, yacht_id: yacht, days: days}));
    scrollToTop();
    return () => {
      dispatch(initialStateFamilyRoomListCustom());
     };
  }, []);

  if (familyRoomRateStateCustom.fetching ||familyRoomRateState.fetching) {
    return <Loader active inline />;
  }

  if (familyRoomRateStateCustom.error) {
    return <div>Error: {familyRoomRateStateCustom.error}</div>;
  }

  if (familyRoomRateState.message === "resource deleted successfully") {
    dispatch(fetchFamilyRoomListCustom({year: getValues("year"), rate_id: getValues("rate_id"), yacht_id: getValues("yacht_id"), days: getValues("days")}));
    dispatch(initialStateFamilyRoom())
  }

  if (familyRoomRateState.message === "resource updated successfully") {
    dispatch(fetchFamilyRoomListCustom({year: getValues("year"), rate_id: getValues("rate_id"), yacht_id: getValues("yacht_id"), days: getValues("days")}));
    dispatch(initialStateFamilyRoom())
  }

  const onSubmit = (data) => {
    let familyRoomRateFilters = {
      year: getValues("year"),
      rate: getValues("rate_id"),
      yacht: getValues("yacht_id"),
      days: getValues("days"),
    };
    localStorage.setItem("familyRoomRateFilters", JSON.stringify(familyRoomRateFilters));
    dispatch(fetchFamilyRoomListCustom(data));
  };

  const handleDeleteFamilyRoom = (id) => {
    dispatch(deleteFamilyRoom(id));
  };

  return (
    <>
      <HeaderCatalog title={t("family_title")} message={familyRoomRateStateCustom.message} to="/familyRooms_new" />
      <Divider />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("general_year")}</label>
            <select name="year" defaultValue={currentYear} aria-expanded="true" ref={register}>
              {familyRoomRateStateCustom.years.map((year, index) => (
                <option key={index} value={year.year}>{year.year}</option>
              ))}
            </select>  
          </Form.Field>
          <Form.Field required>
            <label>{t("general_rateType")}</label>
            <select name="rate_id" defaultValue={rateType} aria-expanded="true" ref={register({ required: true })}>
              <option />
              {familyRoomRateStateCustom.rates.map((rateType) => (
                <option key={rateType.id} value={rateType.id}>{t(rateType.name)}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("general_yacht")}</label>
            <select name="yacht_id" defaultValue={yacht} aria-expanded="true" ref={register({ required: true })}>
              <option />
              {familyRoomRateStateCustom.yachts.map((yacht) => (
                <option key={yacht.id} value={yacht.id}>{yacht.name}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("general_days")}</label>
            <select name="days" defaultValue={days} aria-expanded="true" ref={register}>
              <option value="all">{t("all")}</option>
              {familyRoomRateStateCustom.days.map((day, index) => (
                <option key={index} value={day.number_days}>{t(day.number_days)}</option>
              ))}
            </select>
          </Form.Field>
          <Form.Field>
            <label>&nbsp;</label>
            <Button type="submit" loading={familyRoomRateStateCustom.fetching ? true : false} color="green"><Icon name='search' />{t("search_button")}</Button>
          </Form.Field>
        </Form.Group>
      </Form>

      {familyRoomRateStateCustom.familyRooms !== undefined ? (
        <Table color="blue" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell align="center">{t("family_year")} </Table.HeaderCell>
              <Table.HeaderCell align="center">{t("family_type")} </Table.HeaderCell>
              <Table.HeaderCell align="center">{t("family_yacht")} </Table.HeaderCell>
              <Table.HeaderCell align="center">{t("family_days")} </Table.HeaderCell>
              <Table.HeaderCell align="center">{t("family_cabins")} </Table.HeaderCell>
              <Table.HeaderCell align="center">{t("passengers")} </Table.HeaderCell>
              <Table.HeaderCell align="center">{t("family_clientRate")} </Table.HeaderCell>
              <Table.HeaderCell align="center">{t("family_operatorRate")} </Table.HeaderCell>
              <Table.HeaderCell>{t("state")}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {familyRoomRateStateCustom.familyRooms.map((familyRoom) => (
              <Table.Row key={familyRoom.id}>
                <Table.Cell collapsing><ActiveIcon active={familyRoom.active} /></Table.Cell>
                <Table.Cell>{familyRoom.year}</Table.Cell>
                <Table.Cell>{t(familyRoom.rate)}</Table.Cell>
                <Table.Cell>{familyRoom.yacht}</Table.Cell>
                <Table.Cell>{familyRoom.days}</Table.Cell>
                <Table.Cell>{familyRoom.cabins}</Table.Cell>
                <Table.Cell>{familyRoom.passengers}</Table.Cell>
                <Table.Cell><GenericRateCell rate={familyRoom.customer_rate} ticket={familyRoom.airplane_ticket} /></Table.Cell>
                <Table.Cell><GenericRateCell rate={familyRoom.operator_rate} ticket={familyRoom.airplane_ticket} /></Table.Cell>
                <Table.Cell width="2"><StateRateCell activation_date={familyRoom.activation_date} inactivation_date={familyRoom.inactivation_date} reason_deactivation={familyRoom.reason_deactivation} /></Table.Cell>
                <Table.Cell align="right">
                  {familyRoom.active != false ? (
                    <ChangeStateRateModal stateRate={familyRoom} typeRate="FamilyRoomRate" />
                  ):(
                    null
                  )}
                  <Link to={`/familyRooms/${familyRoom.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
                  <Modal
                    trigger={
                      <Button circular color='red' icon='trash alternate' />
                    }
                    content={t("family_delete")}
                    actions={[
                      t("canceled_button"),
                      {
                        key: "eliminar",
                        content: t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handleDeleteFamilyRoom(familyRoom.id),
                      },
                    ]}
                    size="tiny"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      ) : (
        ""
      )}
    </>
  );
}
