import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GeneralRateService from "../../../../services/rates/GeneralRateService";

export const REQUEST_DELETE_GENERAL_RATE = "REQUEST_DELETE_GENERAL_RATE";
export const DELETE_GENERAL_RATE_SUCCESS = "DELETE_GENERAL_RATE_SUCCESS";
export const DELETE_GENERAL_RATE_ERROR = "DELETE_GENERAL_RATE_ERROR";

function requestDeleteGeneralRate() {
  return {
    type: REQUEST_DELETE_GENERAL_RATE,
  };
}

function deleteGeneralRateSuccess(json) {
  return {
    type: DELETE_GENERAL_RATE_SUCCESS,
    message: json.data,
  };
}

function deleteGeneralRateError(json) {
  return {
    type: DELETE_GENERAL_RATE_ERROR,
    error: json.data,
  };
}

export function deleteGeneralRate(cruise_id) {
  return (dispatch) => {
    dispatch(requestDeleteGeneralRate());
    return GeneralRateService.deleteGeneralRate(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteGeneralRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteGeneralRate(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteGeneralRateError(json.data));
      }
    });
  };
}
