import TicketList from "../containers/rates/ticket/TicketList";
import TicketForm from "../containers/rates/ticket/TicketForm";

const routes = [
  {
    path: "/tickets",
    component: TicketList,
  },
  {
    path: "/tickets/tickets_new",
    component: TicketForm,
  },
  {
    path: "/tickets/:ticket_id",
    component: TicketForm,
  },
];

export { routes as ticketRoutes };
