import React, { useEffect, useState } from "react";
import { useHistory, Link, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
//UI
import { Form, Button, Icon } from "semantic-ui-react";
import { status } from "../../../../containers/resources/optionsList";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import HeadDetail from "../../../../components/headDetail";
import HeadDetailReservation from "../../../../components/headDetailReservation";
import brithdayLogo from '../../../icons/birthday-icon.png';
import "semantic-ui-css/semantic.min.css";
//ACTION
import { createPassengerBySpace } from "../../../../redux/actions/reservations/spacePassenger/createPassengerBySpace";
import { initialStatePassengerBySpace, } from "../../../../redux/actions/reservations/spacePassenger/fetchPassengerBySpace";
import { updatePassengerBySpace } from "../../../../redux/actions/reservations/spacePassenger/updatePassengerBySpace";
import { verifyPassenger, initialStateVerifyPassenger, } from "../../../../redux/actions/reservations/spacePassenger/verifyPassenger";
import { fetchPassengerBySpaceFormCustom, initialStatePassengerBySpaceFormCustom} from "../../../../redux/slices/custom/reservations/cruise/passengerBySpace/customPassengerBySpaceForm.slice";
// Resources
import { scrollToTop } from "../../../resources/utils";

export default function PassengerBySpaceForm() {
  let history = useHistory();
  let search = useLocation().search;
  const identificationPassenger = new URLSearchParams(search).get("identification");
  const [state, setState] = useState({open: false, vertical: "top", horizontal: "center",});
  const { vertical, horizontal, open } = state;
  let { cruise_id } = useParams();
  let { reservation_id } = useParams();
  let { booking_id } = useParams();
  let { passenger_id } = useParams();
  let { space_id } = useParams();
  let { spacePassenger_id } = useParams();
  const { t } = useTranslation();
 
  const { register, handleSubmit, errors, getValues } = useForm();
  const [identification, setIdentification] = useState();
  const [identificationType, setIdentificationType] = useState();
  const [noteTransfer, setNoteTransfer] = useState();
  const [notesChild, setNotesChild] = useState();
  const [cleanList, setCleanList] = useState();
  const [ birthdateInCruise, setBirthdateInCruise ] = useState(false);
  const passengerBySpaceStateCustom = useSelector((state) => state.customPassengerBySpaceForm);
  const passengerBySpaceState = useSelector((state) => state.passengerBySpaceReducer);
  const [birth, setBirth] = useState();
  const dispatch = useDispatch();
  const verifyPassengerState = useSelector((state) => state.verifyPassengerReducer);

  useEffect(() => {
    dispatch(fetchPassengerBySpaceFormCustom("", space_id, cruise_id, reservation_id, booking_id, passenger_id));
    scrollToTop();
    return () => {
      dispatch(initialStatePassengerBySpaceFormCustom());
      dispatch(initialStatePassengerBySpace());
    };
  }, [dispatch, space_id, passenger_id, cruise_id, reservation_id, booking_id]);

  if (
    passengerBySpaceStateCustom.fetching ||
    passengerBySpaceState.fetching
  ) {
    return <CircularProgress />;
  }

  if (verifyPassengerState.message === "passenger exist") {
    let passengerId = verifyPassengerState.passenger.id;
    dispatch(initialStateVerifyPassenger());
    history.replace(`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces/${space_id}/spacePassenger/${spacePassenger_id}/passengers/${passengerId}`);
  }

  if (passengerBySpaceStateCustom.error) {
    return <div>Error: {passengerBySpaceStateCustom.error}</div>;
  }

  const handleInsertNoteChild = () => {
    let selectElement = document.getElementById("childNotes");
    let feedNote = document.getElementById("feedNotes");
    if (feedNote.value === undefined) {
      feedNote.value = "";
      setNotesChild((feedNote.value = selectElement.value + feedNote.value));
    } else {
      setNotesChild((feedNote.value = feedNote.value + selectElement.value));
    }
    setCleanList((selectElement.value = "notOption"));
  };

  const handleSearchPassenger = () => {
    dispatch(verifyPassenger(getValues("identification")));
  };

  const handleShowMessage = (newState) => {
    setState({ open: true, ...newState });

    setTimeout(() => {
      setState({ ...state, open: false });
    }, 6000);
  };

  const onSubmit = (data) => {
    if (passenger_id) {
      data.spacePassenger_id = spacePassenger_id
      dispatch(updatePassengerBySpace( booking_id, space_id, passenger_id, data));
    } else {
      dispatch(createPassengerBySpace(booking_id, space_id, data));
    }
  };

  if (passengerBySpaceState.message === "resource created successfully" || passengerBySpaceState.message === "resource updated successfully") {
    history.go(-1);
  }


  const formatDates = (birthDate, startDate, endDate ) => {
    const birth_date =new Date(birthDate);
    const start_date =new Date(startDate);
    const end_date =new Date(endDate);
    birth_date.setFullYear(start_date.getFullYear());
    if (birth_date >= start_date && birth_date <= end_date) {
      setBirth("birthdate");
    } else {
      setBirth("");
    }
  };

  if (
    passengerBySpaceStateCustom.passengerBySpace.birth_date !== undefined &&
    passengerBySpaceStateCustom.cruise.start_date_format !== undefined &&
    passengerBySpaceStateCustom.cruise.end_date_format !== undefined &&
    birth === undefined
  ) {
    formatDates(passengerBySpaceStateCustom.passengerBySpace.birth_date,
                passengerBySpaceStateCustom.cruise.start_date_flight,
                passengerBySpaceStateCustom.cruise.end_date_flight)
  }

  let identificationTypesPassenger = passengerBySpaceStateCustom.identificationTypes.filter(
    (identificationType) => identificationType.type === "passenger"
  );

  let notesChilde = passengerBySpaceStateCustom.notes.filter((note) => note.type === "child");
  let notesTransfer = passengerBySpaceStateCustom.notes.filter(
    (note) => note.type === "transfer"
  );

  const handleBirthdate = (e) => {
    const birthdate =new Date(e);
    const startDate =new Date(passengerBySpaceStateCustom.cruise.start_date_flight);
    const endDate =new Date(passengerBySpaceStateCustom.cruise.end_date_flight);
    birthdate.setFullYear(startDate.getFullYear());
    if (birthdate >= startDate && birthdate <= endDate) {
      setBirth("birthdate");
    } else {
      setBirth("");
    }
  };

  const handleInsertNoteTransfer = () => {
    let selectElement = document.getElementById("transferNotes");
    let transferNote = document.getElementById("transfer_notes");
    let day = document.getElementById("day");

    if (transferNote.value === undefined && day === undefined) {
      transferNote.value = "";
      setNoteTransfer((transferNote.value = selectElement.value + transferNote.value));
    } else {
      setNoteTransfer((transferNote.value = transferNote.value + day.value.split("-").reverse().join("-") + " " + selectElement.value));
      document.getElementById("day").value = "";
    }
    setCleanList((selectElement.value = "notOption"));
  };

  return (
    <>
      {passengerBySpaceStateCustom.message === "passenger exist" ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert severity="error" color="error" variant="filled">
            {t("passenger_exist_message")}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}

      {passengerBySpaceStateCustom.message === "genders are diferent" ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert severity="error" color="error" variant="filled">
            {t("passenger_gender_message")}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{passengerBySpaceStateCustom.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">
          <Link to={`/cruises/${cruise_id}/reservations`}>
            {t("reservation_title")}
          </Link>
        </div>

        <div className="divider">/</div>
        <div className="active section">
          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings`}
          >
            {t("cabin_title")}
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">
          {t("cabin_cabin")} {passengerBySpaceStateCustom.booking.cabin_name}
        </div>
        <div className="divider">/</div>
        <div className="active section">
          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces`}
          >
            {t("space_title")}
          </Link>
        </div>
        <div className="divider">/</div>
        <div className="active section">{t("passenger_path")}</div>
      </div>
      <br />
      <br />
      <HeadDetail
        yacht_name={passengerBySpaceStateCustom.cruise.yacht_name}
        itinerary_name={passengerBySpaceStateCustom.cruise.itinerary_name}
        start_date={passengerBySpaceStateCustom.cruise.start_date_format}
        end_date={passengerBySpaceStateCustom.cruise.end_date_format}
      />
      <HeadDetailReservation
        reservation_date={passengerBySpaceStateCustom.reservation.creation_date}
        reservation_agency={passengerBySpaceStateCustom.reservation.agency_name}
        reservation_executive={passengerBySpaceStateCustom.reservation.executive}
        reservation_type={passengerBySpaceStateCustom.reservation.cruise_type}
      />
      <br />
      <br />
      <Form onSubmit={handleSubmit(onSubmit)}>
          <label>{t("passenger_information")}</label>
          <Divider variant="fullWidth" />
          <br />

          <Form.Group widths="4">
            <Form.Field>
              <label>{t("passenger_identification")}*</label>
              <input placeholder={t("passenger_identification")} name="identification" 
                // disabled={passenger_id ? false : true}
                defaultValue={passengerBySpaceStateCustom.passengerBySpace.identification || identification || identificationPassenger}
                ref={register({ required: true })}
                //onChange={(event) => setIdentification(event.target.value)}
              />
              {errors.identification && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
            <Form.Field width="4">
              <br></br>
              <Button icon labelPosition='left' color="blue" type="button" onClick={() => handleSearchPassenger()} loading={verifyPassengerState.fetching ? true : false}>
                <Icon name='search plus' />
                Buscar pasajero
              </Button>
            </Form.Field>
            <Form.Field widths="4">
              <label>{t("passenger_identificationType")}*</label>
              <select aria-expanded="true" name="identification_type_id"
                defaultValue={passengerBySpaceStateCustom.passengerBySpace.identification_type_id || identificationType}
                onChange={(event) => setIdentificationType(event.target.value)}
                ref={register({ required: true })}
              >
                <option></option>
                {identificationTypesPassenger.map((identificationType) => (
                  <option key={identificationType.id} value={identificationType.id}>{t(identificationType.name)}</option>
                ))}
              </select>
              {errors.identification_type_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
          </Form.Group>
          <br />
          <label>{t("general_information_tag")}</label>
          <Divider variant="fullWidth" />
          <br />

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("passenger_lastName")} *</label>
              <input
                placeholder={t("passenger_lastName")}
                name="last_name"
                style={{ textTransform: "uppercase" }}
                defaultValue={passengerBySpaceStateCustom.passengerBySpace.last_name}
                ref={register({ required: true })}
              />
              {errors.last_name && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>

            <Form.Field>
              <label>{t("passenger_name")} *</label>
              <input
                placeholder={t("passenger_name")}
                name="first_name"
                style={{ textTransform: "uppercase" }}
                defaultValue={passengerBySpaceStateCustom.passengerBySpace.first_name}
                ref={register({ required: true })}
              />
              {errors.first_name && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>

            <Form.Field>
              <label>{t("passenger_email")}</label>
              <input
                placeholder={t("passenger_email")}
                name="email"
                defaultValue={passengerBySpaceStateCustom.passengerBySpace.email}
                ref={register}
              />
            </Form.Field>
            <Form.Field>
              <label>
                {t("passenger_birthday")}{" "}
                <span className="text_date_format">(dd-mm-yyyy)</span> *
              </label>
              <input
                placeholder={t("passenger_birthday")}
                type="date"
                name="birthdate"
                defaultValue={passengerBySpaceStateCustom.passengerBySpace.birth_date}
                onChange={(event) => handleBirthdate(event.target.value)}
                ref={register({ required: true })}
              />
              {errors.birthdate && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("passenger_country")} *</label>

              <select
                aria-expanded="true"
                name="country_id"
                defaultValue={passengerBySpaceStateCustom.passengerBySpace.country_id}
                ref={register({ required: true })}
              >
                <option></option>
                {passengerBySpaceStateCustom.countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {t(country.name)}
                  </option>
                ))}
              </select>
              {errors.country_id && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>
            <Form.Field>
              <label>{t("passenger_gender")}*</label>
              <select
                aria-expanded="true"
                name="gender_id"
                defaultValue={passengerBySpaceStateCustom.passengerBySpace.gender_id}
                ref={register({ required: true })}
              >
                <option></option>
                {passengerBySpaceStateCustom.genders.map((gender) => (
                  <option key={gender.id} value={gender.id}>
                    {t(gender.name)}
                  </option>
                ))}
              </select>
              {errors.gender_id && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>
            {/* Passenger status */}
            <Form.Field>
              <label>{t("passenger_marital_satatus")}</label>
              <select
                aria-expanded="true"
                name="marital_status"
                defaultValue={
                  passengerBySpaceStateCustom.passengerBySpace.marital_status
                }
                ref={register()}
              >
                <option></option>
                {status.map((e, index) => (
                  <option key={index} value={e.value}>
                    {t(e.name)}
                  </option>
                ))}
              </select>
            </Form.Field>

            <Form.Field>
              <Form.Group width="equal">
                <Form.Field width="11">
                  <label>{t("passenger_vegetarian")}</label>
                  <div className="ui checkbox">
                    <input
                      type="checkbox"
                      name="vegetarian"
                      ref={register}
                      defaultChecked={
                        passengerBySpaceStateCustom.passengerBySpace.vegetarian === 1
                      }
                    />
                    <label></label>
                  </div>
                </Form.Field>

                <Form.Field>
                  <label>{t("passenger_glutenFree")}</label>
                  <div className="ui checkbox">
                    <input
                      type="checkbox"
                      name="gluten_free"
                      ref={register}
                      defaultChecked={
                        passengerBySpaceStateCustom.passengerBySpace.gluten_free === 1
                      }
                    />
                    <label></label>
                  </div>
                </Form.Field>
              </Form.Group>
            </Form.Field>
          </Form.Group>

          <br />
          <label>{t("passenger_payments")}</label>
          <Divider variant="fullWidth" />
          <br />
          <Form.Group width="equal">
            <Form.Field width="8">
              <label>{t("passenger_tct")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="tct"
                  defaultChecked={
                    passengerBySpaceStateCustom.passengerBySpace.tct === 1
                  }
                  ref={register}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>{t("passenger_gps")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="gps"
                  defaultChecked={
                    passengerBySpaceStateCustom.passengerBySpace.gps === 1
                  }
                  ref={register}
                />
                <label></label>
              </div>
            </Form.Field>
          </Form.Group>
          {/* --------------------------------------------------NOTAS-------------------------------------------------------------------- */}
          <br />
          <label>{t("passenger_notes")}</label>
          <Divider variant="fullWidth" />
          <br />
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("passenger_aditional_notes_b2c")}</label>
              <textarea
                rows={5}
                disabled={true}
                placeholder={t("passenger_aditional_notes_b2c")}
                name="aditional_notes"
                defaultValue={
                  passengerBySpaceStateCustom.passengerBySpace.aditional_notes
                }
              ></textarea>
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("passenger_foodNotes")}</label>

              <select
                aria-expanded="true"
                id="childNotes"
                defaultValue={cleanList}
              >
                <option value="notOption"></option>
                {notesChilde.map((note) => (
                  <option key={note.id} value={note.message}>
                    {note.message}
                  </option>
                ))}
              </select>
            </Form.Field>
            <Form.Field width="1">
              <br></br>
              <Button
                icon
                color="blue"
                type="button"
                onClick={() => handleInsertNoteChild()}
              >
                <Icon name="plus" />
              </Button>
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("passenger_food")}</label>
              <textarea
                rows={3}
                placeholder={t("passenger_food")}
                name="feed"
                id="feedNotes"
                defaultValue={
                  passengerBySpaceStateCustom.passengerBySpace.feed || notesChild
                }
                ref={register}
                onChange={(event) => setNotesChild(event.target.value)}
              ></textarea>
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("passenger_medical")}</label>
              <textarea
                rows={3}
                placeholder={t("passenger_medical")}
                name="medical_information"
                defaultValue={
                  passengerBySpaceStateCustom.passengerBySpace.medical_information
                }
                ref={register}
              ></textarea>
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("passenger_wetsuits")}</label>
              <textarea
                rows={3}
                placeholder={t("passenger_wetsuits")}
                name="rent_wetsuits"
                defaultValue={
                  passengerBySpaceStateCustom.passengerBySpace.rent_wetsuits
                }
                ref={register}
              ></textarea>
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <div style={{"display":"flex","justify-content": "space-between","align-items": "center", "font-weight":"700","font-size":".92857143em"}}>
              <label style={{"padding-top": "4px"}}>{t("passenger_requirements")}</label>
              {birth === "birthdate" ? (
                  <div style={{"display":"flex","align-items": "center", "gap":"4px"}}>
                    <img style={{"width": "30px","padding-bottom": "4px"}} src={brithdayLogo} alt="brithday"/>
                    <p style={{"padding-top": "4px"}}>{t("spacePassenger_birthday_message")}</p>
                  </div>
                ) : passengerBySpaceStateCustom.passengerBySpace.birthdateInCruise ===
                  1 ? (
                    <div style={{"display":"flex","align-items": "center", "gap":"4px"}}>
                    <img style={{"width": "30px","padding-bottom": "4px"}} src={brithdayLogo} alt="brithday"/>
                    <p style={{"padding-top": "4px"}}>{t("spacePassenger_birthday_message")}</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <textarea
                rows={3}
                placeholder={t("passenger_requirements")}
                name="special_requirements"
                defaultValue={
                  passengerBySpaceStateCustom.passengerBySpace.special_requirements
                }
                ref={register}
              ></textarea>
            </Form.Field>
          </Form.Group>

          {/* ------------------------------------------------TRASLADOS------------------------------------------------------------------ */}
          <br />
          <label>{t("passenger_transfers")}</label>
          <Divider variant="fullWidth" />
          <br />
          <Form.Group>
            <Form.Field width="4">
              <label>{t("passenger_day")}</label>
              <input
                placeholder={t("passenger_day")}
                type="date"
                name="day"
                id="day"
                pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
              />
            </Form.Field>

            <Form.Field>
              <label>{t("passenger_message")}</label>

              <select
                aria-expanded="true"
                name="transferNotes"
                id="transferNotes"
                defaultValue={cleanList}
              >
                <option></option>
                {notesTransfer.map((note) => (
                  <option key={note.id} value={note.message}>
                    {note.message}
                  </option>
                ))}
              </select>
            </Form.Field>
            <Form.Field>
              <br></br>
              <Button
                icon
                color="blue"
                type="button"
                onClick={() => handleInsertNoteTransfer()}
              >
                <Icon name="plus" />
              </Button>
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <textarea
                rows={3}
                placeholder={t("passenger_notesTransfer")}
                name="transfer_notes"
                id="transfer_notes"
                defaultValue={
                  passengerBySpaceStateCustom.passengerBySpace.transfer_notes ||
                  noteTransfer
                }
                ref={register}
                onChange={(event) => setNoteTransfer(event.target.value)}
              ></textarea>
            </Form.Field>
          </Form.Group>

          <Divider variant="middle" />
          <br></br>

          <Link
            to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking_id}/spaces`}
          >
            <button type="button" className="ui button">
              {t("canceled_button")}
            </button>
          </Link>

          <button
            type="submit"
            onClick={() => {
              handleShowMessage({
                vertical: "top",
                horizontal: "right",
              });
              scrollToTop();
            }}
            className={
              passengerBySpaceStateCustom.processing
                ? "ui loading primary button"
                : "ui primary button"
            }
          >
            {t("save_button")}
          </button>  
      </Form>
    </>
  );
}
