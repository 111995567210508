import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CruiseService from "../../../../services/reservations/CruiseService";

export const REQUEST_FETCH_RESERVATIONS = "REQUEST_FETCH_RESERVATIONS";
export const FETCH_RESERVATIONS_SUCCESS = "FETCH_RESERVATIONS_SUCCESS";
export const FETCH_RESERVATIONS_ERROR = "FETCH_RESERVATIONS_ERROR";

function requestFetchReservations() {
  return {
    type: REQUEST_FETCH_RESERVATIONS,
  };
}

function fetchReservationsSuccess(json) {
  return {
    type: FETCH_RESERVATIONS_SUCCESS,
    reservations: json.data,
  };
}

function fetchReservationsError(json) {
  return {
    type: FETCH_RESERVATIONS_ERROR,
    error: json.data,
  };
}

export function fetchReservations(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchReservations());
    return CruiseService.getReservations(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchReservationsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchReservations(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchReservationsError(json.data));
      }
    });
  };
}
