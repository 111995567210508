import YachtList from "../containers/catalogs/yacht/YachtList";
import YachtForm from "../containers/catalogs/yacht/YachtForm";

const routes = [
  {
    path: "/yachts",
    component: YachtList,
  },
  {
    path: "/yachts_new",
    component: YachtForm,
  },
  {
    path: "/yachts/:yacht_id",
    component: YachtForm,
  },
];

export { routes as yachtRoutes };
