import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../styles/Common";
import { useDispatch, useSelector } from "react-redux";
import { createAvailabilityB2bCharter } from "../../../redux/actions/b2b/availability/createAvailabilityB2bCharter";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function AvailabilityListCharter() {
  let history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  let cruise = JSON.parse(sessionStorage.getItem("cruise"));
  let spaces = [];
  let startDate = cruise.startDate.split("-");
  let endDate = cruise.endDate.split("-");
  let startDay = new Date(`${startDate[1]}/${startDate[0]}/${startDate[2]}`);
  let endDay = new Date(`${endDate[1]}/${endDate[0]}/${endDate[2]}`);
  const user = JSON.parse(sessionStorage.getItem("userInfo"));
  const availabilityB2b2CharterState = useSelector(
    (state) => state.availabilityReducer
  );
  const [numberPassengers, setNumberPassengers] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [dispatch]);

  if (
    availabilityB2b2CharterState.message === "resource created successfully"
  ) {
    history.go(-1);
  }

  for (var i = 0; i < cruise.yacht_availability; i++) {
    spaces.push(i);
  }

  const handleCancel = () => {
    history.go(-1);
  };

  const handleShowMessage = (newState) => {
    setState({ open: true, ...newState });

    setTimeout(() => {
      setState({ ...state, open: false });
    }, 6000);
  };

  const handleSubmit = () => {
    dispatch(
      createAvailabilityB2bCharter({
        cruise: {
          ...cruise,
          agencyId: "rREK2Gy2zl",
          rateId: "bEB9ZLKPgW",
          userId: "bEB9ZLKPgW",
          numberPassengers: numberPassengers,
          charterState: "reserved",
        },
      })
    );
  };

  return (
    <>
      {availabilityB2b2CharterState.message ===
        "resource couldn't  be inserted" && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert severity="info" color="error" variant="filled">
            {t("message_charter")}
          </Alert>
        </Snackbar>
      )}

      <TableContainer component={Paper}>
        <Toolbar>
          <div style={{ display: "flex" }}>
            <Typography
              className={classes.title}
              variant="h6"
              noWrap
              style={{ padding: "20px 0 10px  0" }}
            >
              {cruise.yacht}
              <div style={{ fontWeight: "normal", fontSize: "12px" }}>
                {t("itinerary_from") +
                  " " +
                  cruise.startDate.split("-").join("/")}
              </div>
              <div style={{ fontWeight: "normal", fontSize: "12px" }}>
                {t("itinerary_to") + " " + cruise.endDate.split("-").join("/")}
              </div>
            </Typography>
            <div
              style={{
                paddingTop: "20px",
                paddingLeft: "35px",
                fontSize: "14px",
                fontWeight: "bold",
                color: "#2C8ABD",
              }}
            >
              {cruise.days}D - {cruise.nights}N /{" "}
              {t(
                startDay.toLocaleString("es-CO", { weekday: "long" })
              ).toUpperCase()}{" "}
              -{" "}
              {t(
                endDay.toLocaleString("es-CO", { weekday: "long" })
              ).toUpperCase()}
            </div>
          </div>
          <Typography className={classes.title} variant="h6">
            <div
              style={{
                paddingLeft: "600px",
              }}
            >
              {user.userName}
            </div>
            <div
              style={{
                paddingLeft: "600px",
                fontSize: "12px",
                fontWeight: "normal",
              }}
            >
              {user.agencyName}
            </div>
          </Typography>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">{t("passengers_charter")}</TableCell>
              <TableCell>{t("price")}</TableCell>
              <TableCell align="center">
                {t("commission_discount") + " "} (25%)
              </TableCell>
              <TableCell
                style={{
                  display: "flex",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                TOTAL
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center" style={{ fontWeight: "bold" }}>
                CHARTER
              </TableCell>
              <TableCell align="center">
                <select
                  aria-expanded="true"
                  name="passengers"
                  className="ui selection dropdown"
                  onChange={(e) => setNumberPassengers(e.target.value)}
                >
                  {spaces.map((number, index) => (
                    <option
                      className="selected item"
                      key={index}
                      value={number + 1}
                    >
                      {number + 1}
                    </option>
                  ))}
                </select>
              </TableCell>
              <TableCell>$ 48550 </TableCell>
              <TableCell align="center">$ 12137.5</TableCell>
              <TableCell
                style={{
                  color: "#2C8ABD",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                $ 48550
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <div style={{ padding: "20px" }}>
          <Button onClick={() => handleCancel()}>{t("canceled_button")}</Button>
          <button
            onClick={() => {
              handleSubmit();
              handleShowMessage({
                vertical: "top",
                horizontal: "right",
              });
            }}
            className={
              availabilityB2b2CharterState.processing
                ? "ui loading primary button"
                : "ui primary button"
            }
          >
            Charter
          </button>
        </div>
      </TableContainer>
    </>
  );
}
