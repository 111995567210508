import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Button, Form } from "semantic-ui-react";
import useStyles from "../../styles/Common";
import PassengerList from "../../../components/passengerList";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation, useHistory } from "react-router-dom";
import {
  fetchItinerary,
  initialStateItinerary,
} from "../../../redux/actions/catalogs/itinerary/fetchItinerary";
import { fetchCountries } from "../../../redux/actions/catalogs/country/fetchCountries";
import { createDirectClient } from "../../../redux/actions/b2b/availability/createAvailabilityClient";

export default function AvailabilityCOnfirmClientSpaces() {
  let search = useLocation().search;
  const history = useHistory();
  const { t } = useTranslation();
  const chooseOrigin = new URLSearchParams(search).get("origin");
  const classes = useStyles();
  const countryState = useSelector((state) => state.countryReducer);
  const itineraryState = useSelector((state) => state.itineraryReducer);
  const directClientState = useSelector((state) => state.availabilityReducer);
  const [origin, setOrigin] = useState("");
  let cruise = JSON.parse(sessionStorage.getItem("cruise"));
  let spaces = JSON.parse(sessionStorage.getItem("spaces"));
  let passengers = JSON.parse(sessionStorage.getItem("passengers"));
  const itineraryId = cruise.itineraryId;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
    if (itineraryId) {
      dispatch(fetchItinerary(itineraryId));
    }

    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateItinerary());
    };
  }, [dispatch, itineraryId]);

  // END FUNCTIONS

  if (itineraryState.fetching || countryState.fetching) {
    return <CircularProgress />;
  }

  if (itineraryState.error) {
    return <div>Error: {itineraryState.error}</div>;
  }

  if (countryState.error) {
    return <div>Error: {countryState.error}</div>;
  }

  if (chooseOrigin && origin === "") {
    setOrigin(chooseOrigin);
  }

  const handleBuy = () => {
    dispatch(
      createDirectClient({
        cruise: {
          ...cruise,
          agencyId: "rREK2Gy2zl",
          rateId: "bEB9ZLKPgW",
          userId: "bEB9ZLKPgW",
          spaces: spaces,
          passengers: passengers,
        },
      })
    );
  };

  if (directClientState.message === "resource created successfully") {
    history.push(`/b2b/availability/confirmation/purchase?origin=${origin}`);
  }

  return (
    <>
      {origin === "" ? (
        ""
      ) : (
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      )}

      <div style={{ display: "flex" }}>
        <Typography
          className={classes.title}
          variant="h6"
          noWrap
          style={{ padding: "20px 0 10px  0", width: "20%" }}
        >
          {cruise.yacht}
          <div style={{ fontWeight: "normal", fontSize: "12px" }}>
            {t("itinerary_from") + " " + cruise.startDate.split("-").join("/")}
          </div>
          <div style={{ fontWeight: "normal", fontSize: "12px" }}>
            {t("itinerary_to") + " " + cruise.endDate.split("-").join("/")}
          </div>
        </Typography>
        <div
          style={{
            paddingTop: "25px",
            paddingLeft: "35px",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#2C8ABD",
            width: "80%",
          }}
        >
          {itineraryState.itinerary.name}
        </div>
      </div>

      <Divider />
      <br />
      <br />
      <PassengerList />

      <div style={{ fontSize: "15px", textAlign: "justify" }}>
        <span style={{ fontWeight: "bold" }}>{t("include")}: </span>
        <br />
        <p>{t("include_message")}</p>
      </div>
      <br />
      <div style={{ fontSize: "15px", textAlign: "justify" }}>
        <span style={{ fontWeight: "bold" }}>{t("not_include")}: </span>
        <br />
        <p>{t("not_include_message")}</p>
      </div>
      <br />
      <br />
      <div style={{ fontSize: "16px", fontWeight: "bold", color: "#2C8ABD" }}>
        {t("buyer_information")}
        <br />
        <br />
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("passenger_first_name")}*</label>
              <input
                type="text"
                placeholder={t("passenger_first_name")}
                name="first_name"
              />
            </Form.Field>

            <Form.Field>
              <label>{t("passenger_last_name")}*</label>
              <input
                type="text"
                placeholder={t("passenger_last_name")}
                name="last_name"
              />
            </Form.Field>

            <Form.Field>
              <label>{t("passenger_passport_number")}*</label>
              <input
                placeholder={t("passenger_passport_number")}
                name="passport_number"
              />
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("passenger_nationality")}*</label>
              <select aria-expanded="true" name="country_id">
                <option></option>
                {countryState.countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {t(country.name)}
                  </option>
                ))}
              </select>
            </Form.Field>

            <Form.Field>
              <label>{t("agency_phone")}*</label>
              <input placeholder={t("agency_phone")} name="phone" />
            </Form.Field>

            <Form.Field>
              <label>{t("passenger_email")}*</label>
              <input placeholder={t("passenger_email")} name="email" />
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
      <br />
      <Button
        className={
          directClientState.processing
            ? "ui loading primary button"
            : "ui primary button"
        }
        onClick={() => {
          handleBuy();
        }}
      >
        Buy
      </Button>
    </>
  );
}
