import axios from "axios";
export default class CruiseService {
  static getCruises(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, filter, order) {
    var url = "cruises";
    url = url + `?cruiseCode=${cruiseCode}&&cruiseOperate=${cruiseOperate}&&cruiseYear=${cruiseYear}&&cruiseYacht=${cruiseYacht}&&filter=${filter}&&order=${order}`;
    return axios.get(url);
  }
  
  static getCruise(cruise_id) {
    return axios.get("cruises/" + cruise_id);
  }

  static getYears(startYear) {
    return axios.get("cruises/" + startYear + "/year");
  }

  static postCruise(payload) {
    return axios.post("cruises", payload);
  }

  static putCruise(cruise_id, payload) {
    return axios.put("cruises/" + cruise_id, payload);
  }

  static deleteCruise(cruise_id) {
    return axios.delete("cruises/" + cruise_id);
  }

  static putCruiseNotes(cruise_id, payload) {
    return axios.put("cruises/" + cruise_id + "/notes", payload);
  }

  static getReservations(id) {
    return axios.get("cruises/" + id + "/reservations");
  }

  static getReservationsCanceled(id) {
    return axios.get("cruises/" + id + "/reservations/canceled");
  }

  static getCabinsInformationByCruise(cruise_id) {
    return axios.get(
      "cruises/" + cruise_id + "/cabinsInformation"
    );
  }

  static getPassengersByCruise(cruise_id) {
    return axios.get("cruises/" + cruise_id + "/passengers");
  }

  static getSpacesByCruise(cruise_id) {
    return axios.get("cruises/" + cruise_id + "/numberSpaces");
  }

  static getItemsByCruise(cruise_id) {
    return axios.get("/logs/"+ cruise_id);
  }
}
