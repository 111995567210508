import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IkarusRateService from "../../../../services/rates/IkarusRateService";

export const REQUEST_ACTIVE_IKARUS_RATE = "REQUEST_ACTIVE_IKARUS_RATE";
export const ACTIVE_IKARUS_RATE_SUCCESS = "ACTIVE_IKARUS_RATE_SUCCESS";
export const ACTIVE_IKARUS_RATE_ERROR = "ACTIVE_IKARUS_RATE_ERROR";

function requestActiveIkarusRate() {
  return {
    type: REQUEST_ACTIVE_IKARUS_RATE,
  };
}

function activeIkarusRateSuccess(json) {
  return {
    type: ACTIVE_IKARUS_RATE_SUCCESS,
    message: json.data,
  };
}

function activeIkarusRateError(json) {
  return {
    type: ACTIVE_IKARUS_RATE_ERROR,
    error: json.data,
  };
}

export function activeIkarusRate(ikarusRate_id) {
  return (dispatch) => {
    dispatch(requestActiveIkarusRate());
    return IkarusRateService.activeIkarusRate(ikarusRate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(activeIkarusRateSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(activeIkarusRate(ikarusRate_id));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(activeIkarusRateError(json.data));
      }
    });
  };
}
