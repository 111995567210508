import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customVoucherForm = createSlice({
  name: 'customVoucherForm',
  initialState: {
    cruise: {},
    reservations: [],
    passengers: [],
    voucher: {},
    voucherPassengers: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateVoucherFormCustom(state, action) {
      return {
        cruise: {},
        reservations: [],
        passengers: [],
        voucher: {},
        voucherPassengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesVoucherFormCustom(state, action) {
      return {
        cruise: {},
        reservations: [],
        passengers: [],
        voucher: {},
        voucherPassengers: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchVoucherFormCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        reservations: action.payload.reservations,
        passengers: action.payload.passengers,
        voucher: action.payload.voucher,
        voucherPassengers: action.payload.voucherPassengers,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchVoucherFormCustomError(state, action) {
      return {
        cruise: {},
        reservations: [],
        passengers: [],
        voucher: {},
        voucherPassengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchVoucherFormCustom = (voucher_id, cruise_id) => dispatch => {
  dispatch(requesVoucherFormCustom());
  axios.get(`/voucher/${voucher_id}/voucherForm?cruise_id=${cruise_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchVoucherFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchVoucherFormCustom(voucher_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchVoucherFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateVoucherFormCustom,
  requesVoucherFormCustom,
  fetchVoucherFormCustomSuccess,
  fetchVoucherFormCustomError,
} = customVoucherForm.actions;

export default customVoucherForm.reducer;