import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ItineraryService from "../../../../services/catalogs/ItineraryService";

export const REQUEST_FETCH_IMAGE = "REQUEST_FETCH_IMAGE";
export const FETCH_IMAGE_SUCCESS = "FETCH_IMAGE_SUCCESS";
export const FETCH_IMAGE_ERROR = "FETCH_IMAGE_ERROR";
export const INITIAL_STATE_IMAGE = "INITIAL_STATE_IMAGE";

function requestFetchImage() {
  return {
    type: REQUEST_FETCH_IMAGE,
  };
}

function fetchImageSuccess(json) {
  return {
    type: FETCH_IMAGE_SUCCESS,
    image: json.data,
  };
}

function fetchImageError(json) {
  return {
    type: FETCH_IMAGE_ERROR,
    error: json.data,
  };
}

export function initialStateImage() {
  return {
    type: INITIAL_STATE_IMAGE,
  };
}

export function fetchImage(itinerary_id) {
  return (dispatch) => {
    dispatch(requestFetchImage());
    return ItineraryService.getImage(itinerary_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchImageSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchImage(itinerary_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchImageError(json.data));
      }
    });
  };
}
