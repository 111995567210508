import React, { useState } from "react";
import { Card, Image } from "semantic-ui-react";
import pictureOne from "../../icons/Picture1.jpg";
import pictureTwo from "../../icons/Picture2.jpg";
import pictureThree from "../../icons/Picture3.jpg";
import rwittmer from "../../icons/rwittmer.jpg";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function AvailabilityPurchase() {
  let search = useLocation().search;
  const { t } = useTranslation();
  const chooseOrigin = new URLSearchParams(search).get("origin");
  const [origin, setOrigin] = useState("");

  if (chooseOrigin && origin === "") {
    setOrigin(chooseOrigin);
  }

  return (
    <>
      {origin === "" ? (
        ""
      ) : (
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      )}

      <div>
        <Card.Group itemsPerRow={3}>
          <Card>
            <Image src={pictureOne} alt="island" />
          </Card>
          <Card>
            <Image src={pictureTwo} alt="pinguin" />
          </Card>
          <Card>
            <Image src={pictureThree} alt="sealion" />
          </Card>
        </Card.Group>
        <br />
        <br />
        <span
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            textAlign: "center",
            color: "#2C8ABD",
          }}
        >
          <p>{t("successful_purcharse")}</p>
          <p>{t("purcharse_confirm_email")}</p>
        </span>
        <br />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Card>
            <Image src={rwittmer} alt="rwittmer" />
          </Card>
        </div>
      </div>
    </>
  );
}
