import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CruiseLanguageService from "../../../../services/reservations/CruiseLanguageService";

export const REQUEST_FETCH_CRUISE_LANGUAGES = "REQUEST_FETCH_CRUISE_LANGUAGES";
export const FETCH_CRUISE_LANGUAGES_SUCCESS = "FETCH_CRUISE_LANGUAGES_SUCCESS";
export const FETCH_CRUISE_LANGUAGES_ERROR = "FETCH_CRUISE_LANGUAGES_ERROR";

function requestFetchCruiseLanguages() {
  return {
    type: REQUEST_FETCH_CRUISE_LANGUAGES,
  };
}

function fetchCruiseLanguagesSuccess(json) {
  return {
    type: FETCH_CRUISE_LANGUAGES_SUCCESS,
    cruiseLanguages: json.data,
  };
}

function fetchCruiseLanguagesError(json) {
  return {
    type: FETCH_CRUISE_LANGUAGES_ERROR,
    error: json.data,
  };
}

export function fetchCruiseLanguages(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchCruiseLanguages());
    return CruiseLanguageService.getCruiseLanguages(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCruiseLanguagesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCruiseLanguages(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCruiseLanguagesError(json.data));
      }
    });
  };
}
