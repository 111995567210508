import HotelierList from "../containers/catalogs/hotelier/HotelierList";
import HotelierForm from "../containers/catalogs/hotelier/HotelierForm";

const routes = [
  {
    path: "/hoteliers",
    component: HotelierList,
  },
  {
    path: "/hoteliers_new",
    component: HotelierForm,
  },
  {
    path: "/hoteliers/:hotelier_id",
    component: HotelierForm,
  },
];

export { routes as hotelierRoutes };
