import AgencyList from "../containers/catalogs/agency/AgencyList";
import AgencyForm from "../containers/catalogs/agency/AgencyForm";

const routes = [
  {
    path: "/agencies",
    component: AgencyList,
  },
  {
    path: "/agencies_new",
    component: AgencyForm,
  },
  {
    path: "/agencies/:agency_id",
    component: AgencyForm,
  },
];

export { routes as agencyRoutes };
