import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FamilyRoomService from "../../../../services/rates/FamilyRoomService";

export const REQUEST_CREATE_FAMILY_ROOM = "REQUEST_CREATE_FAMILY_ROOM";
export const CREATE_FAMILY_ROOM_SUCCESS = "CREATE_FAMILY_ROOM_SUCCESS";
export const CREATE_FAMILY_ROOM_ERROR = "CREATE_FAMILY_ROOM_ERROR";

function requestCreateFamilyRoom() {
  return {
    type: REQUEST_CREATE_FAMILY_ROOM,
  };
}

function createFamilyRoomSuccess(json) {
  return {
    type: CREATE_FAMILY_ROOM_SUCCESS,
    message: json.data,
  };
}

function createFamilyRoomError(json) {
  return {
    type: CREATE_FAMILY_ROOM_ERROR,
    error: json.data,
  };
}

export function createFamilyRoom(payload) {
  return (dispatch) => {
    dispatch(requestCreateFamilyRoom());
    return FamilyRoomService.postFamilyRoom(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createFamilyRoomSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createFamilyRoom(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createFamilyRoomError(json.data));
      }
    });
  };
}
