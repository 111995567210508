import React, { useState } from "react";
import { ListItem, Card, Feed } from "semantic-ui-react";
import { Collapse, ListItemText } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import TitlesLogs from "./titlesLogsHistory";


const LogsHistory = ({ logsHistory }) => {

    const { t } = useTranslation();
    const [openItem, setOpenItem] = useState(null);

    const formatDateToLocal = (date) => {
        const formattedDate = new Date(date).toLocaleString();
        return formattedDate;
    }

    const handleSectionClick = (index) => {
        setOpenItem(openItem === index ? null : index);
    };

    return (
        <>
            {logsHistory.map((log, index) => (
                <Card key={index} style={{ width: "91vw" }}>
                    <Card.Content>
                        <Feed>
                            <Feed.Event>
                                <Feed.Content >
                                    <Feed.Date style={{ fontSize: "16px", fontWeight: "600" }} content={formatDateToLocal(log.timestamp)} />
                                    <Feed.Summary style={{ color: "#3f83c2" }} >{log.user} </Feed.Summary>
                                    <Feed.Summary >                
                                        <TitlesLogs log={log}/>
                                        <ListItem button onClick={() => handleSectionClick(index)} style={{ display: "flex", width: "10%", alignItems: "center" }}>
                                            <ListItemText secondary={t("log_view_info")} />
                                            {openItem === index ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={openItem === index} timeout="auto" unmountOnExit >
                                            <div style={{ display: "flex", gap: "15px" }}>
                                                {log.dataBefore ?
                                                    <div>
                                                        <ListItemText primary={t("log_info")} />
                                                        {Object.entries(log.dataBefore).map(([key, value]) => (
                                                            <ListItemText secondary={key + ":" + " " + value} />
                                                        ))}
                                                    </div> : ""
                                                }
                                                {log.dataAfter ?
                                                    <div>
                                                    <ListItemText primary={!log.dataBefore ? t("log_info") : t("log_info_updated")} />
                                                    {Object.entries(log.dataAfter).map(([key, value]) => (
                                                        <ListItemText secondary={key + ":" + " " + value} />
                                                    ))}
                                                </div> : ""
                                                }          
                                            </div>
                                        </Collapse>
                                    </Feed.Summary>
                                </Feed.Content>
                            </Feed.Event>
                        </Feed>
                    </Card.Content>
                </Card>
            ))}
        </>
    );
};

export default LogsHistory;