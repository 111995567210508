import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customCabinBerthList = createSlice({
  name: 'customCabinBerthList',
  initialState: {
    cabin: {},
    cabinBerths: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateCabinBerthListCustom(state, action) {
      return {
        cabin: {},
        cabinBerths: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesCabinBerthListCustom(state, action) {
      return {
        cabin: {},
        cabinBerths: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchCabinBerthListCustomSuccess(state, action) {
      return {
        cabin: action.payload.cabin,
        cabinBerths: action.payload.cabinBerths,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchCabinBerthListCustomError(state, action) {
      return {
        cabin: {},
        cabinBerths: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchCabinBerthListCustom = (cabin_id) => dispatch => {
  dispatch(requesCabinBerthListCustom());
  axios.get(`/cabin/${cabin_id}/cabinBerthsList`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchCabinBerthListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCabinBerthListCustom(cabin_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCabinBerthListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateCabinBerthListCustom,
  requesCabinBerthListCustom,
  fetchCabinBerthListCustomSuccess,
  fetchCabinBerthListCustomError,
} = customCabinBerthList.actions;

export default customCabinBerthList.reducer;