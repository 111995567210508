import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createCompany } from "../../../redux/actions/catalogs/company/createCompany";
import { fetchCompany, initialStateCompany } from "../../../redux/actions/catalogs/company/fetchCompany";
import { updateCompany } from "../../../redux/actions/catalogs/company/updateCompany";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function CompanyForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { company_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const companyState = useSelector((state) => state.companyReducer);
  const dispatch = useDispatch();

  const emailValid = new RegExp(/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,)

  useEffect(() => {
    if (company_id) {
      dispatch(fetchCompany(company_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateCompany());
    };
  }, [dispatch, company_id]);

  if (companyState.fetching) {
    return <Loader active inline />;
  }

  if (companyState.error) {
    return <Message negative
      header='Error!'
      content={t("server_error_form")}
    />;
  }

  const onSubmit = (data) => {
    if (company_id) {
      dispatch(updateCompany(company_id, data));
    } else {
      dispatch(createCompany(data));
    }
  };

  if (companyState.message === "resource created successfully" || companyState.message === "resource updated successfully") {
    history.push("/companies");
  }

  return (
    <>
      <Header as='h3' dividing>{t("company_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label> {t("company_name")}</label>
            <input name="name" defaultValue={companyState.company.name} placeholder={t("company_name")} maxLength={100} autoFocus ref={register({ required: true })} />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label> {t("company_commercial_name")} </label>
            <input name="comercial_name" defaultValue={companyState.company.comercial_name} placeholder={t("company_commercial_name")} maxLength={100} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("company_additional_name")}</label>
            <input name="additional_name" defaultValue={companyState.company.aditional_name} placeholder={t("company_additional_name")} maxLength={50} ref={register} />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("company_code")}</label>
            <input name="code" defaultValue={companyState.company.code} placeholder={t("company_code")} maxLength={50} ref={register({ required: true })} />
            {errors.code && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("company_ruc")}</label>
            <input name="ruc" defaultValue={companyState.company.ruc} placeholder={t("company_ruc")} maxLength={50} ref={register({ required: true })} />
            {errors.ruc && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("company_principal_logo")}</label>
            <input name="main_logo" defaultValue={companyState.company.main_logo} placeholder={t("company_principal_logo")} maxLength={50} ref={register} />
          </Form.Field>
          <Form.Field>
            <label>{t("company_report_dashboard")}</label>
            <input name="dashboard_logo" defaultValue={companyState.company.dashboard_logo} placeholder={t("company_report_dashboard")} maxLength={50} ref={register} />
          </Form.Field>
        </Form.Group>
        <Header as='h4' dividing color='grey'>{t("company_billing_information")}</Header>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("company_email")}</label>
            <input name="email" defaultValue={companyState.company.email} placeholder={t("company_email")} maxLength={200} ref={register({required: true, pattern: emailValid})} />
            {errors.email && errors.email.type === "required" &&  (<span className="error_message">{t("required_information")}</span>)}
            {errors.email && errors.email.type === "pattern" &&  (<span className="error_message">{t("company_email_error")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("company_contact_phone")}</label>
            <input name="contact_phone" defaultValue={companyState.company.contact_phone} placeholder={t("company_contact_phone")} maxLength={100} ref={register({required: true})} />
            {errors.contact_phone && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("company_beneficiary_bank")}</label>
            <input name="beneficiary_bank" defaultValue={companyState.company.beneficiary_bank} placeholder={t("company_beneficiary_bank")} maxLength={100} ref={register({required: true})} />
            {errors.beneficiary_bank && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("company_beneficiaryAccount_number")}</label>
            <input name="beneficiaryAccount_number" defaultValue={companyState.company.beneficiaryAccount_number} placeholder={t("company_beneficiaryAccount_number")} maxLength={100} ref={register({required: true})} />
            {errors.beneficiaryAccount_number && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("company_beneficiaryAccount_type")}</label>
            <select name="beneficiaryAccount_type" defaultValue={companyState.company.beneficiaryAccount_type} aria-expanded="true" ref={register({ required: true })} >
              <option></option>
              <option value="current account">{t("company_current_account")}</option>
              <option value="savings account">{t("company_savings_account")}</option>
            </select>
            {errors.beneficiaryAccount_type && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("company_beneficiary_ruc")}</label>
            <input name="beneficiary_ruc" defaultValue={companyState.company.beneficiary_ruc} placeholder={t("company_beneficiary_ruc")} maxLength={50} ref={register({required: true})} />
            {errors.beneficiary_ruc && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("company_beneficiary_adress")}</label>
            <input name="beneficiary_adress" defaultValue={companyState.company.beneficiary_adress} placeholder={t("company_beneficiary_adress")} maxLength={500} ref={register({required: true})} />
            {errors.beneficiary_adress && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={companyState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/companies"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
