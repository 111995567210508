import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//UI
import { Button, Modal, Icon, Loader, Breadcrumb, Divider, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { deleteIkarusRatesCabin, initialStateIkarusRateCabin } from "../../../redux/actions/rates/ikarusRatesCabin/deleteIkarusRatesCabin";
import { fetchIkarusRateCabinListCustom } from "../../../redux/slices/custom/rates/ikarusRateCabins/customIkarusRateCabinList.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function IkarusRateCabinList() {
  const { t } = useTranslation();
  let { ikarusRate_id } = useParams()
  const ikarusRateStateCustom = useSelector((state) => state.customIkarusRateCabinList);
  const ikarusRateCabinState = useSelector((state) => state.ikarusRateCabinReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIkarusRateCabinListCustom(ikarusRate_id));
    scrollToTop();
  }, []);

  if (ikarusRateStateCustom.fetching || ikarusRateCabinState.fetching) {
    return <Loader active inline />;
  }

  if (ikarusRateStateCustom.error) {
    return <div>Error: {ikarusRateStateCustom.error}</div>;
  }

  if (ikarusRateCabinState.message === "resource deleted successfully") {
    dispatch(fetchIkarusRateCabinListCustom(ikarusRate_id));
    dispatch(initialStateIkarusRateCabin());
  }

  const handleDeleteIkarusRateCabin = (ikarusRateCabin_id) => {
    dispatch(deleteIkarusRatesCabin(ikarusRateCabin_id));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/ikarusRates" className="section">{t("ikarusRates_tag")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{ikarusRateStateCustom.ikarusRate.year} - {ikarusRateStateCustom.ikarusRate.yacht} - {ikarusRateStateCustom.ikarusRate.days} días</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <HeaderCatalog title={t("cabin_title")} message={ikarusRateStateCustom.message} to={`/ikarusRates/${ikarusRate_id}/cabins_new`} />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("name")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {ikarusRateStateCustom.ikarusRateCabins.map((ikarusRateCabin) =>
              <Table.Row key={ikarusRateCabin.id}>
                <Table.Cell>{ikarusRateCabin.cabin_name}</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell collapsing>
                  <Modal
                    trigger={
                      <Button className="ui button delete_button" icon>
                        <Icon name="trash alternate" className="icon_color" />
                      </Button>
                    }
                    content={t("delete_button")}
                    actions={[
                      t("canceled_button"),
                      {
                        key: "eliminar",
                        content:t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handleDeleteIkarusRateCabin(ikarusRateCabin.id),
                      },
                    ]}
                    size="tiny"
                  />
                </Table.Cell>
              </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}
