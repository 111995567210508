import CruiseService from "../../../../services/reservations/CruiseService";
import { updateLocalStorageToken, logOut } from "../../../../containers/resources/utils";

export const REQUEST_FETCH_CRUISES = "REQUEST_FETCH_CRUISES";
export const FETCH_CRUISES_SUCCESS = "FETCH_CRUISES_SUCCESS";
export const FETCH_CRUISES_ERROR = "FETCH_CRUISES_ERROR";

function requestFetchCruises() {
  return {
    type: REQUEST_FETCH_CRUISES,
  };
}

function fetchCruisesSuccess(json) {
  return {
    type: FETCH_CRUISES_SUCCESS,
    cruises: json.data,
  };
}

function fetchCruisesError(json) {
  return {
    type: FETCH_CRUISES_ERROR,
    error: json.data,
  };
}

export function fetchCruises(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, filter, order) {
  return (dispatch) => {
    dispatch(requestFetchCruises());
    return CruiseService.getCruises(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, filter, order).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchCruisesSuccess(json.data));
        } else if(json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchCruises(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, filter, order));
        } else if(json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchCruisesError(json.data));
        }
      }
    );
  };
}
