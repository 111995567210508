import { logOut, updateLocalStorageToken } from '../../../../containers/resources/utils';
import GuideOnBoardService from '../../../../services/reservations/GuideOnBoardService';

export const REQUEST_FETCH_GUIDE_ON_BOARD_LOGS = "REQUEST_FETCH_GUIDE_ON_BOARD_LOGS";
export const FETCH_GUIDE_ON_BOARD_LOGS_SUCCESS = "FETCH_GUIDE_ON_BOARD_LOGS_SUCCESS";
export const FETCH_GUIDE_ON_BOARD_LOGS_ERROR = "FETCH_GUIDE_ON_BOARD_LOGS_ERROR";

function requestFetchGuideOnBoardLogs() {
  return {
    type: REQUEST_FETCH_GUIDE_ON_BOARD_LOGS,
  };
}

function fetchGuideOnBoardLogsSuccess(json) {
  return {
    type: FETCH_GUIDE_ON_BOARD_LOGS_SUCCESS,
    logs: json.data,
  };
}

function fetchGuideOnBoardLogsError(json) {
  return {
    type: FETCH_GUIDE_ON_BOARD_LOGS_ERROR,
    error: json.data,
  };
}

export function fetchGuideOnBoardLogs(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchGuideOnBoardLogs());
    return GuideOnBoardService.getItemsByCruise(cruise_id).then((json) => {
      if (json.status === 200) {
        dispatch(fetchGuideOnBoardLogsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGuideOnBoardLogs(cruise_id));
      } else if (json.data.code === 498) {
        logOut()
      } else {
        dispatch(fetchGuideOnBoardLogsError(json.data));
      }
    });
  };
}
