import SeasonList from "../containers/rates/season/SeasonList";
import SeasonForm from "../containers/rates/season/SeasonForm";

const routes = [
  {
    path: "/seasons",
    component: SeasonList,
  },
  {
    path: "/seasons_new",
    component: SeasonForm,
  },
  {
    path: "/seasons/:season_id",
    component: SeasonForm,
  },
];

export { routes as seasonRoutes };
