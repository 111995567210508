import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SpaceService from "../../../../services/reservations/SpaceService";

export const REQUEST_CREATE_SPACE = "REQUEST_CREATE_SPACE";
export const CREATE_SPACE_SUCCESS = "CREATE_SPACE_SUCCESS";
export const CREATE_SPACE_ERROR = "CREATE_SPACE_ERROR";

function requestCreateSpace() {
  return {
    type: REQUEST_CREATE_SPACE,
  };
}

function createSpaceSuccess(json) {
  return {
    type: CREATE_SPACE_SUCCESS,
    message: json.data,
  };
}

function createSpaceError(json) {
  return {
    type: CREATE_SPACE_ERROR,
    error: json.data,
  };
}

export function createSpace(reservation_id, booking_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateSpace());
    return SpaceService.postSpace(reservation_id, booking_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createSpaceSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createSpace(reservation_id, booking_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createSpaceError(json.data));
      }
    });
  };
}
