import {
  REQUEST_FETCH_GUIDE_LANGUAGES,
  FETCH_GUIDE_LANGUAGES_SUCCESS,
  FETCH_GUIDE_LANGUAGES_ERROR,
} from "../../../actions/catalogs/guideLanguage/fetchGuideLanguages";

import {
  INITIAL_STATE_GUIDE_LANGUAGE,
  REQUEST_FETCH_GUIDE_LANGUAGE,
  FETCH_GUIDE_LANGUAGE_SUCCESS,
  FETCH_GUIDE_LANGUAGE_ERROR,
} from "../../../actions/catalogs/guideLanguage/fetchGuideLanguage";

import {
  REQUEST_CREATE_GUIDE_LANGUAGE,
  CREATE_GUIDE_LANGUAGE_SUCCESS,
  CREATE_GUIDE_LANGUAGE_ERROR,
} from "../../../actions/catalogs/guideLanguage/createGuideLanguage";

import {
  REQUEST_UPDATE_GUIDE_LANGUAGE,
  UPDATE_GUIDE_LANGUAGE_SUCCESS,
  UPDATE_GUIDE_LANGUAGE_ERROR,
} from "../../../actions/catalogs/guideLanguage/updateGuideLanguage";

import {
  REQUEST_DELETE_GUIDE_LANGUAGE,
  DELETE_GUIDE_LANGUAGE_SUCCESS,
  DELETE_GUIDE_LANGUAGE_ERROR,
} from "../../../actions/catalogs/guideLanguage/deleteGuideLanguage";

const initialState = {
  guideLanguages: [],
  guideLanguage: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const guideLanguageReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_GUIDE_LANGUAGE: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_GUIDE_LANGUAGES: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDE_LANGUAGES_SUCCESS: {
      return {
        ...state,
        guideLanguages: action.guideLanguages,
        guideLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDE_LANGUAGES_ERROR: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_GUIDE_LANGUAGE: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: action.guideLanguage,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDE_LANGUAGE_ERROR: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_GUIDE_LANGUAGE: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_GUIDE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_GUIDE_LANGUAGE_ERROR: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_GUIDE_LANGUAGE: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_GUIDE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_GUIDE_LANGUAGE_ERROR: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_GUIDE_LANGUAGE: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_GUIDE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_GUIDE_LANGUAGE_ERROR: {
      return {
        ...state,
        guideLanguages: [],
        guideLanguage: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default guideLanguageReducer;
