import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GeneralRateCabinService from "../../../../services/rates/GeneralRateCabinService";

export const REQUEST_CREATE_GENERAL_RATE_CABIN = "REQUEST_CREATE_GENERAL_RATE_CABIN";
export const CREATE_GENERAL_RATE_CABIN_SUCCESS = "CREATE_GENERAL_RATE_CABIN_SUCCESS";
export const CREATE_GENERAL_RATE_CABIN_ERROR = "CREATE_GENERAL_RATE_CABIN_ERROR";

function requestCreateGeneralRateCabin() {
  return {
    type: REQUEST_CREATE_GENERAL_RATE_CABIN,
  };
}

function createGeneralRateCabinSuccess(json) {
  return {
    type: CREATE_GENERAL_RATE_CABIN_SUCCESS,
    message: json.data,
  };
}

function createGeneralRateCabinError(json) {
  return {
    type: CREATE_GENERAL_RATE_CABIN_ERROR,
    error: json.data,
  };
}

export function createGeneralRatesCabin(generalRate_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateGeneralRateCabin());
    return GeneralRateCabinService.postGeneralRateCabin(generalRate_id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(createGeneralRateCabinSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(createGeneralRatesCabin(generalRate_id, payload));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(createGeneralRateCabinError(json.data));
        }
      }
    );
  };
}
