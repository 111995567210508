import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../styles/Common";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchActualReservationsByAgency,
  initialStateReservations,
} from "../../../redux/actions/b2b/availability/fetchActualReservationsByAgency";
import { fetchHistoricalReservationsByAgency } from "../../../redux/actions/b2b/availability/fetchHistoricalReservationsByAgency";

export default function AvailabilityListReservation() {
  const classes = useStyles();
  const { t } = useTranslation();
  const availabilityActualReservationByAgencyState = useSelector(
    (state) => state.availabilityActualReservationsReducer
  );
  const availabilityHistoricalReservationByAgencyState = useSelector(
    (state) => state.availabilityHistoricalReservationsReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActualReservationsByAgency("bEB9ZLKPgW"));
    dispatch(fetchHistoricalReservationsByAgency("bEB9ZLKPgW"));
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateReservations());
    };
  }, [dispatch]);

  if (
    availabilityActualReservationByAgencyState.fetching ||
    availabilityHistoricalReservationByAgencyState.fetching
  ) {
    return <CircularProgress />;
  }

  if (availabilityActualReservationByAgencyState.processing) {
    return <CircularProgress />;
  }

  if (availabilityActualReservationByAgencyState.error) {
    return <div>Error: {availabilityActualReservationByAgencyState.error}</div>;
  }
  if (availabilityHistoricalReservationByAgencyState.error) {
    return (
      <div>Error: {availabilityHistoricalReservationByAgencyState.error}</div>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Toolbar>
          <div style={{ display: "flex" }}>
            <Typography
              className={classes.title}
              variant="h6"
              noWrap
              style={{ padding: "20px 0 10px  0" }}
            >
              {t("b2b_hold_reservation")}
            </Typography>
          </div>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("availability_b2b_cruise_date")}</TableCell>
              <TableCell align="center">
                {t("availability_b2b_yacht")}
              </TableCell>
              <TableCell align="center">
                {t("availability_b2b_itinerary")}
              </TableCell>
              <TableCell align="center">
                {t("availability_b2b_spaces")}
              </TableCell>
              <TableCell align="center">
                {t("avavilability_b2b_price")}
              </TableCell>
              <TableCell align="center">
                {t("availability_b2b_reserve_date")}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availabilityActualReservationByAgencyState.actualReservations.map(
              (reservation, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {reservation.start_date} - {reservation.end_date}
                  </TableCell>
                  <TableCell align="center">{reservation.code}</TableCell>
                  <TableCell align="center">
                    {reservation.number_days}D / {reservation.number_nights}N
                  </TableCell>
                  <TableCell align="center">{reservation.spaces}</TableCell>
                  <TableCell align="center">{reservation.price}</TableCell>
                  <TableCell align="center">
                    {reservation.creation_date}
                  </TableCell>
                  <TableCell>
                    <Link to={`reservationDetail/${reservation.id}`}>
                      {t("avavilability_b2b_enter")}
                    </Link>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <TableContainer component={Paper}>
        <Toolbar>
          <div style={{ display: "flex" }}>
            <Typography
              className={classes.title}
              variant="h6"
              noWrap
              style={{ padding: "20px 0 10px  0" }}
            >
              {t("b2b_historical_reservation")}
            </Typography>
          </div>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("availability_b2b_cruise_date")}</TableCell>
              <TableCell align="center">
                {t("availability_b2b_yacht")}
              </TableCell>
              <TableCell align="center">
                {t("availability_b2b_itinerary")}
              </TableCell>
              <TableCell align="center">
                {t("availability_b2b_spaces")}
              </TableCell>
              <TableCell align="center">
                {t("avavilability_b2b_price")}
              </TableCell>
              <TableCell align="center">
                {t("availability_b2b_reserve_date")}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availabilityHistoricalReservationByAgencyState.historicalReservations.map(
              (reservation, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {reservation.start_date} - {reservation.end_date}
                  </TableCell>
                  <TableCell align="center">{reservation.code}</TableCell>
                  <TableCell align="center">
                    {reservation.number_days}D / {reservation.number_nights}N
                  </TableCell>
                  <TableCell align="center">{reservation.spaces}</TableCell>
                  <TableCell align="center">{reservation.price}</TableCell>
                  <TableCell align="center">
                    {reservation.creation_date}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
