import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GenderService from "../../../../services/catalogs/GenderService";

export const REQUEST_UPDATE_GENDER = "REQUEST_UPDATE_GENDER";
export const UPDATE_GENDER_SUCCESS = "UPDATE_GENDER_SUCCESS";
export const UPDATE_GENDER_ERROR = "UPDATE_GENDER_ERROR";

function requestUpdateGender() {
  return {
    type: REQUEST_UPDATE_GENDER,
  };
}

function updateGenderSuccess(json) {
  return {
    type: UPDATE_GENDER_SUCCESS,
    message: json.data,
  };
}

function updateGenderError(json) {
  return {
    type: UPDATE_GENDER_ERROR,
    error: json.data,
  };
}

export function updateGender(gender_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateGender());
    return GenderService.putGender(gender_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateGenderSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateGender(gender_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateGenderError(json.data));
      }
    });
  };
}
