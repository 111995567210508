import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, useParams, Link } from "react-router-dom";

//UI
import { Form, Message, Divider, Loader, Breadcrumb, Header, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createBooking } from "../../../../redux/actions/reservations/booking/createBooking";
import { updateBooking } from "../../../../redux/actions/reservations/booking/updateBooking";
import { initialStateBooking } from "../../../../redux/actions/reservations/booking/fetchBooking";
import { fetchAvailabilityCabin } from "../../../../redux/actions/reservations/cabin/fetchAvailabilityCabin";
import { fetchBookingFormCustom, initialStateBookingFormCustom } from "../../../../redux/slices/custom/reservations/cruise/booking/customBookingForm.slice";

//Components
import HeadDetail from "../../../../components/headDetail";
import HeadDetailReservation from "../../../../components/headDetailReservation";

// Resources
import { scrollToTop } from "../../../resources/utils";

export default function BookingForm() {
  let history = useHistory();
  let { cruise_id } = useParams();
  let { reservation_id } = useParams();
  let { booking_id } = useParams();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const bookingStateCustom = useSelector((state) => state.customBookingForm);
  const bookingState = useSelector((state) => state.bookingReducer);
  const [idCabin, setIdCabin] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBookingFormCustom(reservation_id, cruise_id, booking_id));
    scrollToTop();
    return () => {
      dispatch(initialStateBookingFormCustom());
      dispatch(initialStateBooking());
    };
  }, []);

  if (bookingStateCustom.fetching || bookingState.fetching) {
    return <Loader active inline />;
  }

  const handleGetAvailabilityCabin = (cabin_id) => {
    dispatch(fetchAvailabilityCabin(cabin_id));
  };

  const onSubmit = (data) => {
    data["cruise_id"] = cruise_id;
    if (booking_id) {
      dispatch(updateBooking(cruise_id, reservation_id, booking_id, data));
    } else if (reservation_id) {
      dispatch(createBooking(reservation_id, data));
    }
  };

  if (bookingState.message === "resource created successfully" || bookingState.message === "resource updated successfully") {
    history.go(-1);
  }

  return (
    <>
      {bookingStateCustom.error ? (
        <Message negative content={t(bookingStateCustom.error)} />
      ) : (
        ""
      )}

      <Breadcrumb>
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/cruise-detail`} className="section">{bookingStateCustom.cruise.code}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/reservations`} className="section"> {t("reservation_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings`} className="section"> {t("booking_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("booking_cabin")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <HeadDetail
        yacht_name={bookingStateCustom.cruise.yacht_name}
        itinerary_name={bookingStateCustom.cruise.itinerary_name}
        start_date={bookingStateCustom.cruise.start_date_format}
        end_date={bookingStateCustom.cruise.end_date_format}
      />

      <HeadDetailReservation
        reservation_date={bookingStateCustom.reservation.creation_date}
        reservation_agency={bookingStateCustom.reservation.agency_name}
        reservation_executive={bookingStateCustom.reservation.user}
        reservation_type={bookingStateCustom.reservation.cruise_type}
      />

      <br />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header as='h3' dividing>{t("general_information_tag")}</Header>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("booking_cabin")}</label>
            <select aria-expanded="true" name="cabin_id" value={idCabin !== "" ? idCabin : bookingStateCustom.booking.cabin_id} ref={register({ required: true })}
              onChange={(event) => {
                setIdCabin(event.target.value);
                handleGetAvailabilityCabin(event.target.value);
              }}
            >
              <option></option>
              {bookingStateCustom.cabins.map((cabin) => (
                <option key={cabin.cabin_id} value={cabin.cabin_id}>
                  {cabin.cabin_name}
                </option>
              ))}
            </select>
            {errors.cabin_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>

          <Form.Field></Form.Field>
          
          <Form.Field>
            <label>{t("booking_single")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="single" defaultChecked={bookingStateCustom.booking.single === 1} ref={register}/>
              <label></label>
            </div>
          </Form.Field>

          <Form.Field>
            <label>{t("booking_double")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="matrimonial" ref={register} defaultChecked={bookingStateCustom.booking.matrimonial}/>
              <label></label>
            </div>
          </Form.Field>
          <Form.Field>
            <label>{t("booking_family")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="family_room" ref={register} defaultChecked={bookingStateCustom.booking.family_room}
              />
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />
        
        <Link to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings`}><Button>{t("canceled_button")}</Button></Link>
        <Button primary type="submit" loading={bookingStateCustom.processing ? true : false}>{t("save_button")}</Button>
      </Form>
    </>
  );
}
