import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class OrderService {
  static getCruises(cruiseCode, cruiseOperate, cruiseYear) {
    var url = "cruises";
    if (cruiseCode !== null) {
      url = url + "?cruiseCode=" + cruiseCode;
    } else if (cruiseOperate !== null && cruiseYear !== null) {
      url = url + `?cruiseOperate=${cruiseOperate}&&cruiseYear=${cruiseYear} `;
    }
    return axios.get(url);
  }
  static getOrderService(cruise_id) {
    return axios.get("cruise/" + cruise_id + "/orderService");
  }
}
