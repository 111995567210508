import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Input, Segment, Header, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { fetchCountries } from "../../../redux/actions/catalogs/country/fetchCountries";
import { deleteCountry } from "../../../redux/actions/catalogs/country/deleteCountry";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function CountryList() {
  const { t } = useTranslation();
  const countryState = useSelector((state) => state.countryReducer);
  const dispatch = useDispatch();
  const { register, getValues } = useForm();

  useEffect(() => {
    dispatch(fetchCountries(""));
    scrollToTop();
  }, []);

  if (countryState.fetching || countryState.processing) {
    return <Loader active inline />;
  }

  if (countryState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (countryState.message === "resource deleted successfully") {
    dispatch(fetchCountries(""));
  }

  const handleDeleteCountry = (country_id) => {
    dispatch(deleteCountry(country_id));
  };

  const handleSearchCountry = () => {
    dispatch(fetchCountries(getValues("searchWord")));
  };

  return (
    <>
      <Segment clearing> 
        <HeaderCatalog title={t("country_title")} message={countryState.message} to="/countries_new" floated='left' />
        <Header as='h5' floated='right'>
          <Input type="text" placeholder={t("search") + "..."} action>
            <input name="searchWord" maxLength={10} ref={register()} />
            <Button type="submit" onClick={() => handleSearchCountry()} icon="search" size="medium" />
          </Input>
        </Header>
      </Segment>

      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("country_name")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {countryState.countries.map((country) => (
          <Table.Row key={country.id}>
            <Table.Cell>{t(country.name)}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/countries/${country.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("country_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteCountry(country.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
