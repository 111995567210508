import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideOnBoardService from "../../../../services/reservations/GuideOnBoardService";

export const REQUEST_DELETE_GUIDE_ON_BOARD = "REQUEST_DELETE_GUIDE_ON_BOARD";
export const DELETE_GUIDE_ON_BOARD_SUCCESS = "DELETE_GUIDE_ON_BOARD_SUCCESS";
export const DELETE_GUIDE_ON_BOARD_ERROR = "DELETE_GUIDE_ON_BOARD_ERROR";

function requestDeleteGuideOnBoard() {
  return {
    type: REQUEST_DELETE_GUIDE_ON_BOARD,
  };
}

function deleteGuideOnBoardSuccess(json) {
  return {
    type: DELETE_GUIDE_ON_BOARD_SUCCESS,
    message: json.data,
  };
}

function deleteGuideOnBoardError(json) {
  return {
    type: DELETE_GUIDE_ON_BOARD_ERROR,
    error: json.data,
  };
}

export function deleteGuideOnBoard(cruise_id, id) {
  return (dispatch) => {
    dispatch(requestDeleteGuideOnBoard());
    return GuideOnBoardService.deleteGuideOnBoard(cruise_id, id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(deleteGuideOnBoardSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(deleteGuideOnBoard(cruise_id, id));
        } else if (json.data.code === 498) {
          logOut()
        } else {
          dispatch(deleteGuideOnBoardError(json.data));
        }
      }
    );
  };
}
