import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import BookingService from "../../../../services/reservations/BookingService";

export const REQUEST_FETCH_BOOKING = "REQUEST_FETCH_BOOKING";
export const FETCH_BOOKING_SUCCESS = "FETCH_BOOKING_SUCCESS";
export const FETCH_BOOKING_ERROR = "FETCH_BOOKING_ERROR";
export const INITIAL_STATE_BOOKING = "INITIAL_STATE_BOOKING";

function requestFetchBooking() {
  return {
    type: REQUEST_FETCH_BOOKING,
  };
}

function fetchBookingSuccess(json) {
  return {
    type: FETCH_BOOKING_SUCCESS,
    booking: json.data,
  };
}

function fetchBookingError(json) {
  return {
    type: FETCH_BOOKING_ERROR,
    error: json.data,
  };
}

export function initialStateBooking() {
  return {
    type: INITIAL_STATE_BOOKING,
  };
}

export function fetchBooking(booking_id) {
  return (dispatch) => {
    dispatch(requestFetchBooking());
    return BookingService.getBooking(booking_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchBookingSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchBooking(booking_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchBookingError(json.data));
      }
    });
  };
}
