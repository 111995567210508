import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import HotelierService from "../../../../services/catalogs/HotelierService";

export const REQUEST_UPDATE_HOTELIER = "REQUEST_UPDATE_HOTELIER";
export const UPDATE_HOTELIER_SUCCESS = "UPDATE_HOTELIER_SUCCESS";
export const UPDATE_HOTELIER_ERROR = "UPDATE_HOTELIER_ERROR";

function requestUpdateHotelier() {
  return {
    type: REQUEST_UPDATE_HOTELIER,
  };
}

function updateHotelierSuccess(json) {
  return {
    type: UPDATE_HOTELIER_SUCCESS,
    message: json.data,
  };
}

function updateHotelierError(json) {
  return {
    type: UPDATE_HOTELIER_ERROR,
    error: json.data,
  };
}

export function updateHotelier(hotelier_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateHotelier());
    return HotelierService.putHotelier(hotelier_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateHotelierSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateHotelier(hotelier_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateHotelierError(json.data));
      }
    });
  };
}
