import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Loader, Button, Breadcrumb } from "semantic-ui-react";

//Actions
import { createSeason } from "../../../redux/actions/rates/season/createSeason";
import { initialStateSeason } from "../../../redux/actions/rates/season/fetchSeason";
import { updateSeason } from "../../../redux/actions/rates/season/updateSeason";
import { fetchSeasonFormCustom, initialStateSeasonFormCustom } from "../../../redux/slices/custom/rates/season/customIkarusRateForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";
import { years } from "../../resources/optionsList";
import { useTranslation } from "react-i18next";

export default function SeasonForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { season_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const seasonStateCustom = useSelector((state) => state.customSeasonForm);
  const seasonState = useSelector((state) => state.seasonReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSeasonFormCustom(season_id));
    scrollToTop();
    return () => {
      dispatch(initialStateSeasonFormCustom())
      dispatch(initialStateSeason())
    }
  }, []);

  if (seasonStateCustom.fetching || seasonStateCustom.fetching) {
    return <Loader active inline />;
  }

  if (seasonStateCustom.error) {
    return <div>Error: {seasonStateCustom.error}</div>;
  }

  const onSubmit = (data) => {
    if (season_id) {
      dispatch(updateSeason(season_id, data));
    } else {
      dispatch(createSeason(data));
    }
  };

  if (seasonState.message === "resource created successfully" || seasonState.message === "resource updated successfully") {
    history.push("/seasons");
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/seasons" className="section">{t("seasons_title")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("season_title")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field width={3}>
            <label>{t("season_year")}*</label>
            <select name="year" aria-expanded="false" defaultValue={seasonStateCustom.season.year} autoFocus ref={register({ required: true })}>
              <option></option>
              {years.map((year, index) => (
                <option value={year} key={index}>{year}</option>
              ))}
            </select>
            {errors.year && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>

          <Form.Field>
            <label>{t("flight_airline")} *</label>
            <select name="airline_id" defaultValue={seasonStateCustom.season.airline_id} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {seasonStateCustom.airlines.map((airline) => (
                <option key={airline.id} value={airline.id}>{airline.name}</option>
              ))}
            </select>
            {errors.airline_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("season_january")}/{t("season_path")}</label>
            <select name="january" defaultValue={seasonStateCustom.season.january} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.january && (<span className="error_message"> {t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("season_february")}/{t("season_path")}</label>
            <select name="february" defaultValue={seasonStateCustom.season.february} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.february && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("season_march")}/{t("season_path")}</label>
            <select name="march" defaultValue={seasonStateCustom.season.march} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.march && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("season_april")}/{t("season_path")}</label>
            <select name="april" defaultValue={seasonStateCustom.season.april} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.april && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("season_may")}/{t("season_path")}</label>
            <select name="may" defaultValue={seasonStateCustom.season.may} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.may && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("season_june")}/{t("season_path")}</label>
            <select name="june" defaultValue={seasonStateCustom.season.june} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.june && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("season_july")}/{t("season_path")}</label>
            <select name="july" defaultValue={seasonStateCustom.season.july} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.july && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("season_august")}/{t("season_path")}</label>
            <select name="august" defaultValue={seasonStateCustom.season.august} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.august && (
              <span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("season_september")}/{t("season_path")}</label>
            <select name="september" defaultValue={seasonStateCustom.season.september} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.september && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("season_october")}/{t("season_path")}</label>
            <select name="october" defaultValue={seasonStateCustom.season.october} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.october && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("season_november")}/{t("season_path")}</label>
            <select name="november" defaultValue={seasonStateCustom.season.november} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.november && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("season_december")}/{t("season_path")}</label>
            <select name="december" defaultValue={seasonStateCustom.season.december} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              <option value="season_high">{t("season_high")}</option>
              <option value="season_low">{t("season_low")}</option>
            </select>
            {errors.december && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />
        
        <Button primary type="submit" loading={seasonStateCustom.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/seasons"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
