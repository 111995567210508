import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Image, Button, Form, Icon, Loader, Divider, Header, Label, Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import single_bed from "../../icons/route_Itinerary_unselected.png";

//Actions
import { updateImage } from "../../../redux/actions/catalogs/itinerary/updateImage";
import { fetchItinerary, initialStateItinerary } from "../../../redux/actions/catalogs/itinerary/fetchItinerary";
import { initialStateImage } from "../../../redux/actions/catalogs/itinerary/fetchImage";

//Resources
import { scrollToTop } from "../../resources/utils";
import { API_BASE_URL } from "../../../services/constants";

export default function ItineraryForm() {
	const { t } = useTranslation();
	let history = useHistory();
	const { register, handleSubmit, errors } = useForm();
	const [file, setFile] = useState(undefined);
	const dispatch = useDispatch();
	let { itinerary_id } = useParams();
	const itineraryState = useSelector((state) => state.itineraryReducer);
	const imageState = useSelector((state) => state.imageReducer);

	useEffect(() => {
    if (itinerary_id) {
      dispatch(fetchItinerary(itinerary_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateItinerary());
			dispatch(initialStateImage());
    };
  }, []);

	const onSubmit = (data) => {
		if(data.file_path[0]) {
			const formData = new FormData();
			formData.append("file_path", data.file_path[0]);
			dispatch(updateImage(itinerary_id, formData));
		}
	};

	const getFileName = (name) => {
    document.getElementById("file-chosen").innerText = name;
  };

	const setImage = (file) => {
    document.getElementById("blah").src = URL.createObjectURL(file)
  };

	console.log("imageState", imageState);

	if (imageState.message === "resource updated successfully") {
    history.push("/itineraries");
  }

	if (itineraryState.fetching) {
    return <Loader active inline />;
  }

  if (itineraryState.error) {
    return <div>Error: {itineraryState.error}</div>;
  }

  return (
    <>
			<Breadcrumb>
				<Breadcrumb.Section ><Link to="/itineraries" className="section">{t("itinerary_title")}</Link></Breadcrumb.Section>
				<Breadcrumb.Divider />
				<Breadcrumb.Section active>{ itineraryState.itinerary.name }</Breadcrumb.Section>
			</Breadcrumb>
			<Divider variant="fullWidth" />

			<Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
				<Form.Group widths="equal">
					<Form.Field>
						<label htmlFor="choose-file" className="custom-file-upload">
						{t("itinerary_map")}    
						</label>
						<input type="file" name="file_path" placeholder={t("itinerary_map")} id="choose-file" accept=".jpg,.jpeg,.pdf,.png" ref={register}
						onChange={(e) => {
							getFileName(e.target.files[0].name);
							setFile(e.target.files[0]);

							const [file] = e.target.files
							if (file) {
								setImage(file)
							}
						}}
						hidden/>
					</Form.Field>
					<Form.Field required>
						<span id="file-chosen">{t("itinerary_file_chosen")}</span>	
					</Form.Field>
					<Form.Field>
					</Form.Field>
				</Form.Group>
					<Form.Field>
						<img id="blah" src={itineraryState.itinerary.file_path ? API_BASE_URL + itineraryState.itinerary.file_path : single_bed} />
					</Form.Field>
		
				<Divider variant="middle" />

				<Button primary type="submit" loading={imageState.processing ? true : false}>{t("save_button")}</Button>
				<Link to={"/itineraries"}><Button>{t("canceled_button")}</Button></Link>
			</Form>
		</>
  );
}