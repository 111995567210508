import GenderList from "../containers/catalogs/gender/GenderList";
import GenderForm from "../containers/catalogs/gender/GenderForm";

const routes = [
  {
    path: "/genders",
    component: GenderList,
  },
  {
    path: "/genders_new",
    component: GenderForm,
  },
  {
    path: "/genders/:gender_id",
    component: GenderForm,
  },
];

export { routes as genderRoutes };
