import CruiseService from "../../../../services/reservations/CruiseService";

export const REQUEST_FETCH_SPACES_BY_CRUISE = "REQUEST_FETCH_SPACES_BY_CRUISE";
export const FETCH_SPACES_BY_CRUISE_SUCCESS = "FETCH_SPACES_BY_CRUISE_SUCCESS";
export const FETCH_SPACES_BY_CRUISE_ERROR = "FETCH_SPACES_BY_CRUISE_ERROR";
export const INITIAL_STATE_SPACES_BY_CRUISE = "INITIAL_STATE_SPACES_BY_CRUISE";

function requestFetchSpacesByCruise() {
  return {
    type: REQUEST_FETCH_SPACES_BY_CRUISE,
  };
}

function fetchSpacesByCruiseSuccess(json) {
  return {
    type: FETCH_SPACES_BY_CRUISE_SUCCESS,
    spaces: json.data,
  };
}

function fetchSpacesByCruiseError(json) {
  return {
    type: FETCH_SPACES_BY_CRUISE_ERROR,
    error: json.data,
  };
}

export function initialStateSpacesByCruise() {
  return {
    type: INITIAL_STATE_SPACES_BY_CRUISE,
  };
}

export function fetchSpacesByCruise(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchSpacesByCruise());
    return CruiseService.getSpacesByCruise(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchSpacesByCruiseSuccess(json.data));
      } else {
        dispatch(fetchSpacesByCruiseError(json.data));
      }
    });
  };
}
