import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import Divider from "@material-ui/core/Divider";
import { createCruiseRate } from "../../../redux/actions/reservations/cruiseRate/createCruiseRate";
import { fetchRates } from "../../../redux/actions/catalogs/rate/fetchRates";
import {
  fetchCruise,
  initialStateCruise,
} from "../../../redux/actions/reservations/cruise/fetchCruise";
import {
  fetchCruiseRate,
  initialStateCruiseRate,
} from "../../../redux/actions/reservations/cruiseRate/fetchCruiseRate";
import { initialStateRate } from "../../../redux/actions/catalogs/rate/fetchRate";
import { updateCruiseRate } from "../../../redux/actions/reservations/cruiseRate/updateCruiseRate";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../styles/common.css";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "10%",
    marginTop: -12,
    marginLeft: -82,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

export default function CruiseRateForm() {
  let history = useHistory();
  let { cruise_id } = useParams();
  let { id } = useParams();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const cruiseState = useSelector((state) => state.cruiseReducer);
  const rateState = useSelector((state) => state.rateReducer);
  const cruiseRateState = useSelector((state) => state.cruiseRateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCruise(cruise_id));
    dispatch(fetchRates("specialRate"));
    if (id) {
      dispatch(fetchCruiseRate(cruise_id, id));
    }
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateCruiseRate());
      dispatch(initialStateRate());
      dispatch(initialStateCruise());
    };
  }, [dispatch, cruise_id, id]);

  if (cruiseState.fetching || rateState.fetching || cruiseRateState.fetching) {
    return <CircularProgress />;
  }

  if (cruiseRateState.error) {
    return <div>Error: {cruiseRateState.error}</div>;
  }

  const onSubmit = (data) => {
    if (id) {
      dispatch(updateCruiseRate(cruise_id, id, data));
    } else {
      dispatch(createCruiseRate(cruise_id, data));
    }
  };

  if (
    cruiseRateState.message === "resource created successfully" ||
    cruiseRateState.message === "resource updated successfully"
  ) {
    history.go(-1);
  }

  return (
    <>
      <div className="ui breadcrumb">
        <Link to="/" className="section">
          TipTops
        </Link>
        <div className="divider">/</div>

        <Link className="section" to={`/cruises/${cruise_id}/rates`}>
          {cruiseState.cruise.code}
        </Link>

        <div className="divider">/</div>
        <div className="active section">Tarifas</div>
      </div>
      <br></br>
      <br></br>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Nombre *</label>
              <select
                aria-expanded="true"
                name="rate_id"
                defaultValue={cruiseRateState.cruiseRate.rate_id}
                ref={register({ required: true })}
              >
                <option></option>
                {rateState.rates.map((rate) => (
                  <option
                    className="selected item"
                    key={rate.id}
                    value={rate.id}
                  >
                    {rate.name}
                  </option>
                ))}
              </select>
              {errors.rate_id && (
                <span className="error_message">Información requerida</span>
              )}
            </Form.Field>
          </Form.Group>

          <Divider variant="middle" />
          <br></br>

          <Link to={`/cruises/${cruise_id}/rates`}>
            <button type="button" className="ui button">
              Cancelar
            </button>
          </Link>

          <button
            type="submit"
            className={
              cruiseRateState.processing
                ? "ui loading primary button"
                : "ui primary button"
            }
          >
            Guardar Cambios
          </button>
        </div>
      </Form>
    </>
  );
}
