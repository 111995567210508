import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
//UI
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Form } from "semantic-ui-react";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../styles/common.css";
import "semantic-ui-css/semantic.min.css";

//ACTION
import { createGuideOnBoard } from "../../../redux/actions/reservations/guideOnBoard/createGuideOnBoard";
import { fetchGuideLanguages } from "../../../redux/actions/catalogs/guideLanguage/fetchGuideLanguages";
import { updateGuideOnBoard } from "../../../redux/actions/reservations/guideOnBoard/updateGuideOnBoard";
import { initialStateGuideOnBoard } from "../../../redux/actions/reservations/guideOnBoard/fetchGuideOnBoard";
import { fetchGuideOnBoardFormCustom,initialStateGuideOnBoardFormCustom } from "../../../redux/slices/custom/reservations/guideOnBoard/customGuideOnBoardForm.slice";
//RESOURCE
import { orders } from "../../resources/optionsList";
import { scrollToTop } from "../../resources/utils";



const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "10%",
    marginTop: -12,
    marginLeft: -82,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

export default function GuideOnBoardForm() {
  let history = useHistory();
  let { cruise_id } = useParams();
  let { id } = useParams();
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const guideOnBoardStateCustom = useSelector(state => state.customGuideOnBoardForm);
  const guideOnBoardState = useSelector((state) => state.guideOnBoardReducer);
  const guideLanguageState = useSelector((state) => state.guideLanguageReducer);
  const guide_id = guideOnBoardStateCustom.guideOnBoard.guide_id;
  const [language, setLanguage] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGuideOnBoardFormCustom("", cruise_id, id));
    scrollToTop();
    return(()=>{
      dispatch(initialStateGuideOnBoardFormCustom());
    })
  }, [cruise_id, id]);

  if (
    guideOnBoardStateCustom.fetching ||
    guideOnBoardState.fetching
  ) {
    return <CircularProgress />;
  }

  if (guideOnBoardStateCustom.error) {
    return <div>Error: {guideOnBoardStateCustom.error}</div>;
  }

  const handleGetGuideLanguages = (event) => {
    dispatch(fetchGuideLanguages(event));
  };

  const handleLanguage = (event) => {
    setLanguage(event);
  };

  const onSubmit = (data) => {
    if (id) {
      dispatch(updateGuideOnBoard(cruise_id, id, data));
    } else {
      dispatch(createGuideOnBoard(cruise_id, data));
    }
  };

  if (
    guideOnBoardState.message === "resource created successfully" ||
    guideOnBoardState.message === "resource updated successfully"
  ) {
    dispatch(initialStateGuideOnBoard())
    history.go(-1);
  }

  return (
    <>
      <div className="ui breadcrumb">
        <Link to="/" className="section">
          TipTops
        </Link>
        <div className="divider">/</div>

        <Link className="section" to={`/cruises/${cruise_id}/guides`}>
          {guideOnBoardStateCustom.cruise.code}
        </Link>

        <div className="divider">/</div>
        <div className="active section">Guías</div>
      </div>
      <br></br>
      <br></br>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Nombre *</label>
              <select
                aria-expanded="true"
                name="guide_id"
                defaultValue={guideOnBoardStateCustom.guideOnBoard.guide_id}
                onChange={(event) =>
                  handleGetGuideLanguages(event.target.value)
                }
                ref={register({ required: true })}
              >
                <option></option>
                {guideOnBoardStateCustom.guides.map((guide) => (
                  <option
                    className="selected item"
                    key={guide.id}
                    value={guide.id}
                  >
                    {guide.first_name} {guide.last_name}
                  </option>
                ))}
              </select>
              {errors.guide_id && (
                <span className="error_message">Información requerida</span>
              )}
            </Form.Field>

            <Form.Field>
              <label>
                Idioma *
                {guideLanguageState.fetching === true ? (
                  <CircularProgress size={17} />
                ) : (
                  ""
                )}
              </label>
              <select
                aria-expanded="true"
                name="language_id"
                value={
                  language
                    ? language
                    : guideOnBoardStateCustom.guideOnBoard.language_id
                }
                onChange={(event) => handleLanguage(event.target.value)}
                ref={register({ required: true })}
              >
                <option></option>
                {guideLanguageState.guideLanguages.map((item) =>
                  item.languages.map((language) => (
                    <option
                      className="selected item"
                      key={language.id}
                      value={language.language_id}
                    >
                      {language.language_name}
                    </option>
                  ))
                )}
              </select>
              {errors.language_id && (
                <span className="error_message">Información requerida</span>
              )}
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field width={8}>
              <label>Orden *</label>
              <select
                aria-expanded="true"
                name="order"
                defaultValue={guideOnBoardStateCustom.guideOnBoard.order}
                ref={register({ required: true })}
              >
                <option></option>
                {orders.map((order, index) => (
                  <option className="selected item" key={index} value={order}>
                    {order}
                  </option>
                ))}
              </select>
              {errors.order && (
                <span className="error_message">Información requerida</span>
              )}
            </Form.Field>
          </Form.Group>

          <Divider variant="middle" />
          <br></br>

          <Link to={`/cruises/${cruise_id}/guides`}>
            <button type="button" className="ui button">
              Cancelar
            </button>
          </Link>

          <button
            type="submit"
            className={
              guideOnBoardStateCustom.processing
                ? "ui loading primary button"
                : "ui primary button"
            }
          >
            Guardar Cambios
          </button>
        </div>
      </Form>
    </>
  );
}
