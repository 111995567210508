import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import RouteService from "../../../../services/catalogs/RouteService";

export const REQUEST_UPDATE_ROUTE = "REQUEST_UPDATE_ROUTE";
export const UPDATE_ROUTE_SUCCESS = "UPDATE_ROUTE_SUCCESS";
export const UPDATE_ROUTE_ERROR = "UPDATE_ROUTE_ERROR";

function requestUpdateRoute() {
  return {
    type: REQUEST_UPDATE_ROUTE,
  };
}

function updateRouteSuccess(json) {
  return {
    type: UPDATE_ROUTE_SUCCESS,
    message: json.data,
  };
}

function updateRouteError(json) {
  return {
    type: UPDATE_ROUTE_ERROR,
    error: json.data,
  };
}

export function updateRoute(route_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateRoute());
    return RouteService.putRoute(route_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateRouteSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateRoute(route_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateRouteError(json.data));
      }
    });
  };
}
