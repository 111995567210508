import VoucherService from "../../../../services/reservations/VoucherService";

export const REQUEST_UPDATE_VOUCHER = "REQUEST_UPDATE_VOUCHER";
export const UPDATE_VOUCHER_SUCCESS = "UPDATE_VOUCHER_SUCCESS";
export const UPDATE_VOUCHER_ERROR = "UPDATE_VOUCHER_ERROR";

function requestUpdateVoucher() {
  return {
    type: REQUEST_UPDATE_VOUCHER,
  };
}

function updateVoucherSuccess(json) {
  return {
    type: UPDATE_VOUCHER_SUCCESS,
    message: json.data,
  };
}

function updateVoucherError(json) {
  return {
    type: UPDATE_VOUCHER_ERROR,
    error: json.data,
  };
}

export function updateVoucher(voucher_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateVoucher());
    return VoucherService.putVoucher(voucher_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateVoucherSuccess(json.data));
      } else {
        dispatch(updateVoucherError(json.data));
      }
    });
  };
}
