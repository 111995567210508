import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GeneralRateCabinService from "../../../../services/rates/GeneralRateCabinService";

export const REQUEST_DELETE_GENERAL_RATE_CABIN = "REQUEST_DELETE_GENERAL_RATE_CABIN";
export const DELETE_GENERAL_RATE_CABIN_SUCCESS = "DELETE_GENERAL_RATE_CABIN_SUCCESS";
export const DELETE_GENERAL_RATE_CABIN_ERROR = "DELETE_GENERAL_RATE_CABIN_ERROR";
export const INITIAL_STATE_GENERAL_RATE_CABIN = "INITIAL_STATE_GENERAL_RATE_CABIN";

function requestDeleteGeneralRateCabin() {
  return {
    type: REQUEST_DELETE_GENERAL_RATE_CABIN,
  };
}

function deleteGeneralRateCabinSuccess(json) {
  return {
    type: DELETE_GENERAL_RATE_CABIN_SUCCESS,
    message: json.data,
  };
}

function deleteGeneralRateCabinError(json) {
  return {
    type: DELETE_GENERAL_RATE_CABIN_ERROR,
    error: json.data,
  };
}

export function initialStateGeneralRateCabin() {
  return {
    type: INITIAL_STATE_GENERAL_RATE_CABIN,
  };
}

export function deleteGeneralRatesCabin(generalRateCabin_id) {
  return (dispatch) => {
    dispatch(requestDeleteGeneralRateCabin());
    return GeneralRateCabinService.deleteGeneralRateCabin(generalRateCabin_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(deleteGeneralRateCabinSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(deleteGeneralRatesCabin(generalRateCabin_id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(deleteGeneralRateCabinError(json.data));
        }
      }
    );
  };
}
