import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { fetchKinds } from "../../../redux/actions/catalogs/kind/fetchKinds";
import { deleteKind } from "../../../redux/actions/catalogs/kind/deleteKind";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function KindList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const kindState = useSelector((state) => state.kindReducer);

  useEffect(() => {
    dispatch(fetchKinds());
    scrollToTop();
  }, []);

  if (kindState.fetching || kindState.processing) {
    return <Loader active inline />;
  }

  if (kindState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (kindState.message === "resource deleted successfully") {
    dispatch(fetchKinds());
  }

  const handledeleteKind = (kind_id) => {
    dispatch(deleteKind(kind_id));
  };

  return (
    <>
    <HeaderCatalog title={t("kind_title")} message={kindState.message} to="/kinds_new" />
    <Table color="blue" striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t("kind_name")}</Table.HeaderCell>
          <Table.HeaderCell>{t("kind_initials")}</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {kindState.kinds.map((kind) => (
        <Table.Row key={kind.id}>
          <Table.Cell>{t(kind.name)}</Table.Cell>
          <Table.Cell>{t(kind.short)}</Table.Cell>
          <Table.Cell collapsing>
            <Link to={`/kinds/${kind.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
            <Modal
              trigger={
                <Button circular color='red' icon='trash alternate' />
              }
              content={t("kind_delete")}
              actions={[
                t("canceled_button"),
                  {
                    key: "eliminar",
                    content: t("delete_button"),
                    className: "delete_button icon_color",
                    onClick: () => handledeleteKind(kind.id),
                  },
              ]}
              size="tiny"
            />
          </Table.Cell>
        </Table.Row>
        ))}
      </Table.Body>
    </Table>
    </>
  );
}
