import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Segment, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createYacht } from "../../../redux/actions/catalogs/yacht/createYacht";
import { initialStateYacht } from "../../../redux/actions/catalogs/yacht/fetchYacht";
import { updateYacht } from "../../../redux/actions/catalogs/yacht/updateYacht";
import { fetchYachtFormCustom, initialStateYachtFormCustom } from "../../../redux/slices/custom/catalogs/yacht/customYachtForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";
import { capacityYacht, availabilityYacht} from '../../resources/optionsList';

export default function YachtForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { yacht_id } = useParams();
  let colors = [
    { name: "#7CD149" },
    { name: "#5584ED" },
    { name: "#A4BDFC" },
    { name: "#47D6DB" },
    { name: "#7BE7BF" },
    { name: "#52B74A" },
    { name: "#FFFF01" },
    { name: "#FFB879" },
    { name: "#FFB879" },
    { name: "#FF887D" },
    { name: "#FF3434" },
    { name: "#DBADFF" },
    { name: "#E1E1E1" },
    { name: "#D1773C" },
    { name: "#089116" },
    { name: "#4671C0" },
  ];
  const { register, handleSubmit, errors } = useForm();
  const yachtStateCustom = useSelector((state) => state.customYachtForm);
  const yachtsState = useSelector((state) => state.yachtsReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchYachtFormCustom(yacht_id));
    scrollToTop();
    return () => {
      dispatch(initialStateYacht());
      dispatch(initialStateYachtFormCustom());
    };
  }, []);

  if (yachtStateCustom.fetching || yachtsState.fetching) {
    return <Loader active inline />;
  }

  if (yachtsState.error) {
    return  <Message negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (yacht_id) {
      dispatch(updateYacht(yacht_id, data));
    } else {
      dispatch(createYacht(data));
    }
  };

  if (yachtsState.message === "resource created successfully" || yachtsState.message === "resource updated successfully") {
    history.push("/yachts");
  }

  return (
    <>
      <Header as='h3' dividing>{t("yacht_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label> {t("yacht_name")}</label>
            <input name="name" defaultValue={yachtStateCustom.yacht.name} placeholder={t("yacht_name")} autoFocus maxLength={100} ref={register({ required: true })} />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label> {t("yacht_code")}</label>
            <input name="code" defaultValue={yachtStateCustom.yacht.code} placeholder={t("yacht_code")}  maxLength={50} ref={register({ required: true })} />
            {errors.code && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label> {t("yacht_company")}</label>
            <select name="company_id" defaultValue={yachtStateCustom.yacht.company_id} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {yachtStateCustom.companies.map((company) => (
                <option key={company.id} value={company.id}>{company.name}</option>
              ))}
            </select>
            {errors.company_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label> {t("yacht_capacity")}</label>
            <select name="capacity" defaultValue={yachtStateCustom.yacht.capacity} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {capacityYacht.map((capacity, index) => (
                <option key={index} value={capacity}>{capacity}</option>
              ))}
            </select>
            {errors.capacity && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label> {t("yacht_availability")}</label>
            <select name="availability" defaultValue={yachtStateCustom.yacht.availability} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {availabilityYacht.map((availability, index) => (
                <option key={index} value={availability}>{availability}</option>
              ))}
            </select>
            {errors.availability && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label> {t("yacht_kind")}</label>
            <select name="kind_id" defaultValue={yachtStateCustom.yacht.kind_id} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {yachtStateCustom.kinds.map((kind) => (
                <option key={kind.id} value={kind.id}>{kind.name}</option>
              ))}
            </select>
            {errors.kind_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label> {t("yacht_order")}</label>
            <input name="display_order" defaultValue={yachtStateCustom.yacht.display_order} placeholder={t("yacht_order")} ref={register({ required: true })} />
            {errors.display_order && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field required>
            <label> {t("yacht_colour")}</label>
            <Form.Group style={{ paddingLeft: "7px" }}>
              {colors.map((color, index) => (
                <Segment
                  compact
                  key={index}
                  style={{
                    backgroundColor: `${color.name}`,
                    margin: 0,
                  }}
                >
                  <div className="ui fitted radio checkbox">
                    <input type="radio" name="color" value={color.name} defaultChecked={yachtStateCustom.yacht.color === color.name} ref={register({ required: true })} />
                    <label></label>
                  </div>
                </Segment>
              ))}
            </Form.Group>
            {errors.color && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field>
            <label> {t("active_tag")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" defaultChecked={yachtStateCustom.yacht.active === 1} ref={register} />
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={yachtsState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/yachts"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
