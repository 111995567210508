import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class LanguageService {
  static getLanguages() {
    return axios.get("languages");
  }
  static getLanguage(language_id) {
    return axios.get("languages/" + language_id);
  }

  static postLanguage(payload) {
    return axios.post("languages", payload);
  }

  static putLanguage(language_id, payload) {
    return axios.put("languages/" + language_id, payload);
  }

  static deleteLanguage(language_id) {
    return axios.delete("languages/" + language_id);
  }
}
