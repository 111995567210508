import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SpaceService from "../../../../services/reservations/SpaceService";

export const REQUEST_UPDATE_DISCOUNT_PRICE = "REQUEST_UPDATE_DISCOUNT_PRICE";
export const UPDATE_DISCOUNT_PRICE_SUCCESS = "UPDATE_DISCOUNT_PRICE_SUCCESS";
export const UPDATE_DISCOUNT_PRICE_ERROR = "UPDATE_DISCOUNT_PRICE_ERROR";

function requestUpdateDiscountPrice() {
  return {
    type: REQUEST_UPDATE_DISCOUNT_PRICE,
  };
}

function updateDiscountPriceSuccess(json) {
  return {
    type: UPDATE_DISCOUNT_PRICE_SUCCESS,
    message: json.data,
  };
}

function updateDiscountPriceError(json) {
  return {
    type: UPDATE_DISCOUNT_PRICE_ERROR,
    error: json.data,
  };
}

export function updateDiscountPrice(space_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateDiscountPrice());
    return SpaceService.updateDiscountPrice(space_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateDiscountPriceSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateDiscountPrice(space_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateDiscountPriceError(json.data));
      }
    });
  };
}
