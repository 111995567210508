import {
  INITIAL_STATE_STATISTIC,
  REQUEST_GENERATE_STATISTIC,
  GENERATE_STATISTIC_SUCCESS,
  GENERATE_STATISTIC_ERROR,
} from "../../../actions/reservations/statistic/generateStatistic";

const initialState = {
  data: File,
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const statisticReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_STATISTIC: {
      return {
        ...state,
        data: "",
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_GENERATE_STATISTIC: {
      return {
        ...state,
        data: "",
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case GENERATE_STATISTIC_SUCCESS: {
      return {
        ...state,
        data: action.data,
        processing: false,
        fetching: false,
        message: "data send succesfully",
        error: "",
      };
    }
    case GENERATE_STATISTIC_ERROR: {
      return {
        ...state,
        data: "",
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default statisticReducer;
