import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//UI
import { Button, Modal, Icon, Accordion, Loader } from "semantic-ui-react";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Collapse } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Fab from "@material-ui/core/Fab";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "../../styles/Common";
import "../../styles/common.css";
//ACTIONS
import { fetchCruiseLanguageLogs } from "../../../redux/actions/reservations/cruiseLanguage/fetchCruiseLanguageLogs";
import { deleteCruiseLanguage } from "../../../redux/actions/reservations/cruiseLanguage/deleteCruiseLanguage";
import { initialStateCruiseLanguage } from "../../../redux/actions/reservations/cruiseLanguage/fetchCruiseLanguage";
import { fetchCruiseLanguageListCustom } from "../../../redux/slices/custom/reservations/cruiseLanguage/customCruiseLanguageList.slice";
//RESOURCES
import LogsHistory from "../../../components/reservation/logsHistory";
import { scrollToTop } from "../../../containers/resources/utils";

export default function CruiseLanguageList() {
  const { t } = useTranslation();
  let { cruise_id } = useParams();
  const classes = useStyles();
  const [openHistory, setOpenHistory] = useState(false);
  const cruiseLanguageStateCustom = useSelector((state) => state.customCruiseLanguajeList);
  const cruiseLanguageState = useSelector((state) => state.cruiseLanguageReducer);
  const cruiseLanguageLogState = useSelector((state) => state.cruiseLanguageLogReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCruiseLanguageListCustom(cruise_id));
    scrollToTop();
  }, [dispatch, cruise_id]);

  if (cruiseLanguageStateCustom.fetching || cruiseLanguageStateCustom.fetching) {
    return <CircularProgress />;
  }

  if (cruiseLanguageStateCustom.processing || cruiseLanguageStateCustom.processing) {
    return <CircularProgress />;
  }

  if (cruiseLanguageStateCustom.error) {
    return <div>Error: {cruiseLanguageStateCustom.error}</div>;
  }

  if (cruiseLanguageState.message === "resource deleted successfully") {
    dispatch(fetchCruiseLanguageListCustom(cruise_id));
    dispatch(fetchCruiseLanguageLogs(cruise_id));
    dispatch(initialStateCruiseLanguage());
  }

  const handledeleteCruiseLanguage = (id) => {
    dispatch(deleteCruiseLanguage(cruise_id, id));
    
  };

  const handleHistoyClick = () => {
    setOpenHistory(!openHistory);
    if (!openHistory ) {
      dispatch(fetchCruiseLanguageLogs(cruise_id));
    } 
  };

  return (
    <>
      <div className="ui breadcrumb">
        <Link to="/" className="section">
          TipTops
        </Link>
        <div className="divider">/</div>

        <div className="active section">{cruiseLanguageStateCustom.cruise.code}</div>
      </div>
      <br></br>
      <br></br>
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Idiomas {cruiseLanguageStateCustom.message}
            <Link to={`/cruises/${cruise_id}/languages_new`}>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className={classes.margin}
              >
                <AddIcon />
              </Fab>
            </Link>
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cruiseLanguageStateCustom.cruiseLanguages.map((cruiseItem) =>
              cruiseItem.languages.map((language) => (
                <TableRow key={language.id}>
                  <TableCell>{language.language_name}</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <Link to={`/cruises/${cruise_id}/languages/${language.id}`}>
                      <Button className="edit_button" icon>
                        <Icon className="icon_color" name="pencil alternate" />
                      </Button>
                    </Link>
                    <Modal
                      trigger={
                        <Button className="ui button delete_button" icon>
                          <Icon name="trash alternate" className="icon_color" />
                        </Button>
                      }
                      content={`¿Quieres eliminar este lenguaje?`}
                      actions={[
                        "Cancelar",
                        {
                          key: "eliminar",
                          content: "Eliminar",
                          className: "delete_button icon_color",
                          onClick: () =>
                            handledeleteCruiseLanguage(language.id),
                        },
                      ]}
                      size="tiny"
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Accordion styled style={{ width: "91vw" }}>
        <Accordion.Title onClick={handleHistoyClick} style={{ display: "flex", gap: "5px" }}>
          {t("log_historical")}
          {openHistory ? <ExpandLess /> : <ExpandMore />}
        </Accordion.Title>
        {cruiseLanguageLogState.fetching  ? (
          <div style={{ display: "flex", width: "91vw", height: "80px", justifyContent: "center" }}>
            <Loader size="medium" active inline>Loading...</Loader>
          </div>
        ) : (
          <Collapse in={openHistory} timeout="auto" unmountOnExit style={{ padding: "15px" }}>
            <LogsHistory
              logsHistory={cruiseLanguageLogState.logs}
            />
          </Collapse>
        )}
      </Accordion>
    </>
  );
}
