import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHistoricalCruises,
  initialStateHistoricalCruise,
} from "../../../../redux/actions/reservations/historicalCruise/fetchHistoricalCruises";
import { fetchHistoricalYears } from "../../../../redux/actions/reservations/historicalCruise/fetchHistoricalYears";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import { Button, Icon, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function HistoricalTipTopList() {
  let actualDate = new Date();
  let year = actualDate.getFullYear();
  let years = [];
  const { t } = useTranslation();
  const [cruiseCode, setCruiseCode] = useState("");
  const [cruiseYear, setCruiseYear] = useState(year.toString());
  const classes = useStyles();
  const historicalCruiseState = useSelector(
    (state) => state.historicalCruiseReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHistoricalCruises(""));
    if (year) {
      dispatch(fetchHistoricalYears(year));
    }
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateHistoricalCruise());
    };
  }, [dispatch, year]);

  if (historicalCruiseState.fetching) {
    return <CircularProgress />;
  }

  if (historicalCruiseState.processing) {
    return <CircularProgress />;
  }

  if (historicalCruiseState.error) {
    return <div>Error: {historicalCruiseState.error}</div>;
  }

  if (historicalCruiseState.message === "resource deleted successfully") {
    dispatch(fetchHistoricalCruises(""));
  }

  if (historicalCruiseState.years === []) {
    return years;
  } else {
    historicalCruiseState.years.filter((year) => {
      years.push(year.year);
      years.sort();

      return (years = [...new Set(years)]);
    });
  }

  const handleSeachCruiseCode = () => {
    dispatch(fetchHistoricalCruises(cruiseCode, null));
  };

  const handleSearchCruiseYear = (event) => {
    setCruiseCode("");
    dispatch(fetchHistoricalCruises(null, event));
  };
  return (
    <>
      <div className="ui breadcrumb">
        <div className="active section">{t("historical_tiptop_path")} </div>
      </div>
      <br></br>
      <br></br>
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("historical_tiptop_title")} {historicalCruiseState.message}
          </Typography>
          <div className={classes.search} style={{ paddingRight: "5px" }}>
            <Input
              type="text"
              placeholder={t("historical_tiptop_code") + "..."}
              action
            >
              <input
                defaultValue={cruiseCode}
                onChange={(event) => setCruiseCode(event.target.value)}
              />
              <Button
                type="submit"
                onClick={() => handleSeachCruiseCode()}
                icon="search"
                size="medium"
              />
            </Input>
          </div>

          <div>
            <select
              className="ui selection dropdown"
              aria-expanded="true"
              name="years"
              value={cruiseYear}
              onChange={(event) => {
                handleSearchCruiseYear(event.target.value);
                setCruiseYear(event.target.value);
              }}
            >
              <option></option>
              {years.map((nextYear, index) => (
                <option className="selected item" key={index} value={nextYear}>
                  {nextYear}
                </option>
              ))}
            </select>
          </div>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("historical_tiptop_code")}</TableCell>
              <TableCell width="120px">
                {t("historical_tiptop_yacht")}
              </TableCell>
              <TableCell>
                {t("historical_tiptop_startDate")}
                <br></br>
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </TableCell>
              <TableCell>
                {t("historical_tiptop_endDate")} <br></br>
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </TableCell>
              <TableCell width="120px">{t("historical_tiptop_name")}</TableCell>
              <TableCell widht="120px">
                {t("historical_tiptop_itinerary")}
              </TableCell>
              <TableCell align="center" width="50px">
                {t("historical_tiptop_guides")}
              </TableCell>
              <TableCell align="center" width="50px">
                {t("historical_tiptop_languages")}
              </TableCell>
              <TableCell align="center" width="50px">
                {t("historical_tiptop_rates")}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historicalCruiseState.cruises.map((cruise) => (
              <TableRow key={cruise.id}>
                <TableCell>{cruise.code}</TableCell>
                <TableCell>{cruise.yacht_name}</TableCell>
                <TableCell>{cruise.start_date}</TableCell>
                <TableCell>{cruise.end_date}</TableCell>
                <TableCell>{cruise.name}</TableCell>
                <TableCell>{cruise.itinerary_name}</TableCell>
                <TableCell align="center">
                  {cruise.guides.length >= 1 ? (
                    <Link to={`/cruises/${cruise.id}/guides`}>
                      {cruise.guides
                        .filter((guide, index, names) =>
                          names.indexOf(guide) === index ? (
                            <span key={index}>{guide}</span>
                          ) : (
                            ""
                          )
                        )
                        .join(" - ")}
                    </Link>
                  ) : (
                    t("No Asignado")
                  )}
                </TableCell>
                <TableCell align="center">
                  {cruise.languages.length >= 1 ? (
                    <Link to={`/cruises/${cruise.id}/languages`}>
                      {[...new Set(cruise.languages)]
                        .map((language_name, index, names) =>
                          names.indexOf(language_name) === index
                            ? t(language_name)
                            : ""
                        )
                        .join(" - ")}
                    </Link>
                  ) : (
                    t("No Asignado")
                  )}
                </TableCell>
                <TableCell align="center">
                  {cruise.rates.length >= 1 ? (
                    <Link to={`/cruises/${cruise.id}/rates`}>
                      {[...new Set(cruise.rates)]
                        .map((rate_name, index, names) =>
                          names.indexOf(rate_name) === index ? t(rate_name) : ""
                        )
                        .join(" - ")}
                    </Link>
                  ) : (
                    t("No Asignado")
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
