import React from "react";

export default function FlightCellCanceledSpace(props) {
  return (
    <>
      {props.space.flights !== null ? (
      <>
        <span>{props.space.flights.split("|")[0]}</span>
        <br />
        <span>{props.space.flights.split("|")[1]}</span>
      </>
      ) : (
        ""
      )}
    </>
  )
}