import React, { useEffect, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../styles/Common";
import "../../styles/common.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCruises,
  initialStateCruises,
} from "../../../redux/actions/b2b/availability/fetchCruises";
import { fetchYachts } from "../../../redux/actions/catalogs/yacht/fetchYachts";
import { fetchItineraries } from "../../../redux/actions/catalogs/itinerary/fetchItineraries";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fetchYears } from "../../../redux/actions/reservations/cruise/fetchYears";
import { Button, Icon, Card, Image, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import { Container } from "@material-ui/core";
import { months } from "../../resources/optionsList";

export default function AvailabilityList() {
  let itineraryDays = [];
  const { t } = useTranslation();
  let actualYear = new Date().getFullYear();
  let years = [actualYear];
  let actualMonth = new Date().getMonth() + 1;
  let monthsCruise = [{ name: "december", value: actualMonth }];
  const user = JSON.parse(sessionStorage.getItem("userInfo"));
  const [year, setYear] = useState(actualYear.toString());
  const [month, setMonth] = useState(actualMonth);
  const [yacht, setYacht] = useState("all");
  const [day, setDays] = useState("all");
  const classes = useStyles();
  const cruiseAvailabilityState = useSelector(
    (state) => state.availabilityReducer
  );
  const cruiseState = useSelector((state) => state.cruiseReducer);
  const yachtState = useSelector((state) => state.yachtsB2BReducer);
  const itineraryState = useSelector((state) => state.itineraryReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchYachts());
    dispatch(fetchItineraries());
    dispatch(fetchCruises(actualMonth, actualYear.toString(), "all", "all"));

    if (actualYear) {
      dispatch(fetchYears(actualYear));
    }

    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateCruises());
    };
  }, [dispatch, yacht, day, actualYear, actualMonth]);

  if (cruiseState.fetching || yachtState.fetching || itineraryState.fetching) {
    return <CircularProgress />;
  }

  if (cruiseState.processing) {
    return <CircularProgress />;
  }

  if (cruiseState.error) {
    return <div>Error: {cruiseState.error}</div>;
  }
  if (yachtState.error) {
    return <div>Error: {yachtState.error}</div>;
  }
  if (itineraryState.error) {
    return <div>Error: {itineraryState.error}</div>;
  }

  itineraryState.itineraries.filter((itinerary) => {
    itineraryDays.push(itinerary.number_days);
    return (itineraryDays = [...new Set(itineraryDays)]);
  });

  const handleSearchCruises = () => {
    dispatch(fetchCruises(month, year, yacht, day));
  };

  if (cruiseState.years === []) {
    return years;
  } else {
    cruiseState.years.filter((year) => {
      years.push(year.year);
      years.sort();

      return (years = [...new Set(years)]);
    });
  }

  if (monthsCruise === []) {
    return monthsCruise;
  } else {
    months.filter((month) => {
      monthsCruise.push(month);
      monthsCruise.sort(function (a, b) {
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        return 0;
      });

      return (monthsCruise = Array.from(
        new Set(monthsCruise.map(JSON.stringify))
      ).map(JSON.parse));
    });
  }

  const handleSelectedSpace = (id, space, checked) => {
    const cb = document.getElementById(id);
    const selected = document.getElementById(space);

    if (selected.checked === true) {
      cb.style.border = "2px solid #000";
      cb.style.fontSize = "11.5px";
    } else {
      cb.style.border = "none";
      cb.style.fontSize = "";
    }
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.root}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("b2b_availability_title")} {cruiseState.message}
          </Typography>
          <Typography className={classes.title} variant="h6">
            <div
              style={{
                paddingLeft: "890px",
              }}
            >
              {user.userName}
            </div>
            <div
              style={{
                paddingLeft: "890px",
                fontSize: "12px",
                fontWeight: "normal",
              }}
            >
              {user.agencyName}
            </div>
          </Typography>
        </Toolbar>
        <br />
        <br />
        <Toolbar style={{ paddingBottom: "15px" }}>
          <div style={{ paddingRight: "5px" }}>
            <label className="labels_ticket">
              {t("b2b_availability_yacht")} *
            </label>
            <br />
            <select
              className="ui selection dropdown"
              aria-expanded="true"
              name="yacht"
              defaultValue={yacht}
              onChange={(event) => {
                setYacht(event.target.value);
              }}
            >
              <option className="selected item" value="all">
                {t("b2b_availability_all")}
              </option>
              {yachtState.yachts.map((yacht) => (
                <option
                  className="selected item"
                  key={yacht.id}
                  value={yacht.name}
                >
                  {yacht.name}
                </option>
              ))}
            </select>
          </div>

          <div style={{ paddingRight: "5px" }}>
            <label className="labels_ticket">
              {t("b2b_availability_days")} *
            </label>
            <br />
            <select
              className="ui selection dropdown"
              aria-expanded="true"
              name="day"
              value={day}
              onChange={(event) => {
                setDays(event.target.value);
              }}
            >
              <option className="selected item" value="all">
                {t("b2b_availability_all")}
              </option>
              {itineraryDays.map((itinerary, index) => (
                <option className="selected item" key={index} value={itinerary}>
                  {itinerary} D / {itinerary - 1} N
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="labels_ticket">
              {t("b2b_availability_month")} *
            </label>
            <br />
            <div style={{ paddingRight: "5px" }} className="ui input">
              <select
                className="ui selection dropdown"
                aria-expanded="true"
                name="month"
                value={month}
                onChange={(event) => {
                  setMonth(event.target.value);
                }}
              >
                {months.map((month, index) => (
                  <option
                    className="selected item"
                    key={index}
                    value={month.value}
                  >
                    {t(month.name)}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label className="labels_ticket">
              {t("b2b_availability_year")} *
            </label>
            <br />
            <div style={{ paddingRight: "5px" }} className="ui input">
              <select
                className="ui selection dropdown"
                aria-expanded="true"
                name="year"
                value={year}
                onChange={(event) => {
                  setYear(event.target.value);
                }}
              >
                {years.map((nextYear, index) => (
                  <option
                    className="selected item"
                    key={index}
                    value={nextYear}
                  >
                    {nextYear}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label>{t("search_button")} </label>
            <br />
            <Button
              onClick={() => {
                handleSearchCruises();
              }}
            >
              <Icon name="search" />
            </Button>
          </div>
          <div style={{ paddingLeft: "220px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#7DC31F",
                }}
              ></div>
              <span
                style={{
                  paddingInlineStart: "15px",
                  fontSize: "10px",
                }}
              >
                {t("b2b_availability_available")}
              </span>
            </div>
            <br />
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  backgroundColor: "#D3D4D6",
                }}
              ></div>
              <span
                style={{
                  paddingInlineStart: "15px",
                  fontSize: "10px",
                }}
              >
                {t("b2b_availability_booked")}
              </span>
            </div>
          </div>
        </Toolbar>
      </TableContainer>
      <Container component={Paper} className={classes.root}>
        {cruiseAvailabilityState.cruises.map((cruise, index) => (
          <Card.Group
            key={index}
            style={{
              width: "1500px",
              height: "500px",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            <Card style={{ width: "400px" }}>
              <Carousel
                indicators={false}
                autoPlay={false}
                navButtonsAlwaysVisible={true}
              >
                {cruise.images.map((item, i) => (
                  <Image
                    key={i}
                    src={item}
                    style={{ width: "400px", height: "400px" }}
                  />
                ))}
              </Carousel>
              <Card.Content>
                <Card.Header>
                  <div className="flex-container">
                    <div style={{ marginRight: "30px" }}>{cruise.yacht}</div>
                    <div style={{ marginRight: "30px", fontSize: "15px" }}>
                      {cruise.itinerary_days} {t("itinerary_days")} /{" "}
                      {cruise.itinerary_nights} {t("itinerary_nights")}
                    </div>
                  </div>
                </Card.Header>
              </Card.Content>
              <Card.Description>
                <div className="flex-container">
                  <div style={{ marginLeft: "12px" }}>
                    {t("itinerary_from")} {cruise.start_date}
                  </div>
                  <div style={{ marginLeft: "130px" }}>Tarifa</div>
                </div>
                <div style={{ marginLeft: "12px" }}>
                  {t("itinerary_to")} {cruise.end_date}
                </div>
                <div
                  style={{
                    marginLeft: "12px",
                    fontWeight: "500",
                    paddingTop: "5px",
                  }}
                >
                  FR = Family Room
                </div>
              </Card.Description>
            </Card>
            <Card style={{ width: "780px", padding: "15px" }}>
              <Card.Group itemsPerRow={5}>
                {cruise.cabins.map((cabin, indexCabin) => (
                  <Card key={indexCabin} style={{ padding: "5px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontWeight: "500",
                        fontSize: "10px",
                      }}
                    >
                      {cabin.deck}
                    </div>
                    <Card.Header
                      textAlign="center"
                      style={{ fontWeight: "bold" }}
                    >
                      {t("cabin_cabin").toUpperCase()} {cabin.cabin}
                    </Card.Header>
                    {/* SPACES */}
                    <Card.Group
                      itemsPerRow={2}
                      style={{
                        display: "flex",
                        paddingTop: "5px",
                        justifyContent: "space-between",
                        marginLeft: "-5px",
                        marginRight: "-5px",
                      }}
                    >
                      {cabin.spaces.map((space, indexSpace) => (
                        <div
                          key={indexSpace}
                          className="ui card"
                          id={
                            cruise.code + indexCabin + cabin.cabin + indexSpace
                          }
                          style={{
                            width: "42px",
                            height: "45px",
                            backgroundColor:
                              space.state === "reserved"
                                ? "#D3D4D6"
                                : space.state === "confirmed"
                                ? "#D3D4D6"
                                : "#7DC31F",
                          }}
                        >
                          {space.state === "available" ? (
                            <>
                              <input
                                id={cruise.code + cabin.cabin + indexSpace}
                                type="checkbox"
                                tabIndex="0"
                                hidden
                                onChange={(e) =>
                                  handleSelectedSpace(
                                    cruise.code +
                                      indexCabin +
                                      cabin.cabin +
                                      indexSpace,
                                    cruise.code + cabin.cabin + indexSpace,
                                    e.target.checked
                                  )
                                }
                              />
                              <label
                                htmlFor={cruise.code + cabin.cabin + indexSpace}
                                style={{
                                  boxSizing: "borderBox",
                                  paddingTop: "15px",
                                  paddingBottom: "15px",
                                  paddingLeft: "3px",
                                }}
                              >
                                $3226
                              </label>
                            </>
                          ) : (
                            <div
                              style={{
                                paddingTop: "15px",
                                paddingLeft: "4px",
                              }}
                            >
                              $3226
                            </div>
                          )}
                        </div>
                      ))}
                    </Card.Group>
                    <div
                      style={{
                        paddingTop: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {cabin.family_room === 1 && (
                        <div
                          style={{
                            color: "#2C8ABD",
                            fontWeight: "500",
                            paddingTop: "7px",
                          }}
                        >
                          FR
                        </div>
                      )}
                      <div
                        style={{
                          paddingTop: "7px",
                        }}
                      >
                        one full bed
                      </div>
                      <div>
                        <Modal
                          trigger={<Button icon="image" size="tiny" />}
                          header={t("cabin_cabin") + cabin.cabin}
                          content={
                            <Carousel
                              indicators={false}
                              autoPlay={false}
                              navButtonsAlwaysVisible={true}
                            >
                              {cabin.images.map((item, i) => (
                                <Image
                                  key={i}
                                  src={item}
                                  style={{ width: "100%", height: "400px" }}
                                />
                              ))}
                            </Carousel>
                          }
                          actions={["Cerrar"]}
                          size="tiny"
                        />
                      </div>
                    </div>
                  </Card>
                ))}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingTop: "20px",
                  }}
                >
                  <Button className="primary button">Hold Request</Button>
                </div>
              </Card.Group>
            </Card>
          </Card.Group>
        ))}
      </Container>
    </>
  );
}
