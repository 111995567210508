import React from "react";
import { Route, Redirect } from 'react-router-dom';
import axios from "axios";

export { PrivateRoute };

function PrivateRoute({ component: Component, ...rest })  {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    if(user) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`; 
    }

    return (
    <Route {...rest} render={props => (
      user ? (
        <Component {...props}/>
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }}/>
      )
    )}/>
    );
}
