import CharterRateService from "../../../../services/rates/CharterRateService";

export const REQUEST_DELETE_CHARTER_RATE = "REQUEST_DELETE_CHARTER_RATE";
export const DELETE_CHARTER_RATE_SUCCESS = "DELETE_CHARTER_RATE_SUCCESS";
export const DELETE_CHARTER_RATE_ERROR = "DELETE_CHARTER_RATE_ERROR";

function requestDeleteCharterRate() {
  return {
    type: REQUEST_DELETE_CHARTER_RATE,
  };
}

function deleteCharterRateSuccess(json) {
  return {
    type: DELETE_CHARTER_RATE_SUCCESS,
    message: json.data,
  };
}

function deleteCharterRateError(json) {
  return {
    type: DELETE_CHARTER_RATE_ERROR,
    error: json.data,
  };
}

export function deleteCharterRate(cruise_id) {
  return (dispatch) => {
    dispatch(requestDeleteCharterRate());
    return CharterRateService.deleteCharterRate(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteCharterRateSuccess(json.data));
      } else {
        dispatch(deleteCharterRateError(json.data));
      }
    });
  };
}
