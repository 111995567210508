import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import AgencyService from "../../../../services/catalogs/AgencyService";

export const REQUEST_FETCH_AGENCIES = "REQUEST_FETCH_AGENCIES";
export const FETCH_AGENCIES_SUCCESS = "FETCH_AGENCIES_SUCCESS";
export const FETCH_AGENCIES_ERROR = "FETCH_AGENCIES_ERROR";

function requestFetchAgencies() {
  return {
    type: REQUEST_FETCH_AGENCIES,
  };
}

function fetchAgenciesSuccess(json) {
  return {
    type: FETCH_AGENCIES_SUCCESS,
    agencies: json.data,
  };
}

function fetchAgenciesError(json) {
  return {
    type: FETCH_AGENCIES_ERROR,
    error: json.data,
  };
}

export function fetchAgencies(searchWord) {
  return (dispatch) => {
    dispatch(requestFetchAgencies());
    return AgencyService.getAgencies(searchWord).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchAgenciesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchAgencies(searchWord));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchAgenciesError(json.data));
      }
    });
  };
}
