import {
  REQUEST_FETCH_GUIDES,
  FETCH_GUIDES_SUCCESS,
  FETCH_GUIDES_ERROR,
} from "../../../actions/catalogs/guide/fetchGuides";

import {
  INITIAL_STATE_GUIDE,
  REQUEST_FETCH_GUIDE,
  FETCH_GUIDE_SUCCESS,
  FETCH_GUIDE_ERROR,
} from "../../../actions/catalogs/guide/fetchGuide";

import {
  REQUEST_CREATE_GUIDE,
  CREATE_GUIDE_SUCCESS,
  CREATE_GUIDE_ERROR,
} from "../../../actions/catalogs/guide/createGuide";

import {
  REQUEST_UPDATE_GUIDE,
  UPDATE_GUIDE_SUCCESS,
  UPDATE_GUIDE_ERROR,
} from "../../../actions/catalogs/guide/updateGuide";

import {
  REQUEST_DELETE_GUIDE,
  DELETE_GUIDE_SUCCESS,
  DELETE_GUIDE_ERROR,
} from "../../../actions/catalogs/guide/deleteGuide";

const initialState = {
  guides: [],
  guide: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const guideReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_GUIDE: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_GUIDES: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDES_SUCCESS: {
      return {
        ...state,
        guides: action.guides,
        guide: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDES_ERROR: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_GUIDE: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDE_SUCCESS: {
      return {
        ...state,
        guides: [],
        guide: action.guide,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDE_ERROR: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_GUIDE: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_GUIDE_SUCCESS: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_GUIDE_ERROR: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_GUIDE: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_GUIDE_SUCCESS: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_GUIDE_ERROR: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_GUIDE: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_GUIDE_SUCCESS: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_GUIDE_ERROR: {
      return {
        ...state,
        guides: [],
        guide: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default guideReducer;
