import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Breadcrumb, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createCabin } from "../../../redux/actions/catalogs/cabin/createCabin";
import { fetchDecks } from "../../../redux/actions/catalogs/deck/fetchDecks";
import { initialStateDeck } from "../../../redux/actions/catalogs/deck/fetchDeck";
import { fetchCabin, initialStateCabin } from "../../../redux/actions/catalogs/cabin/fetchCabin";
import { fetchCabinsByYacht } from "../../../redux/actions/catalogs/cabinByYacht/fetchCabinsByYacht";
import { fetchYacht, initialStateYacht } from "../../../redux/actions/catalogs/yacht/fetchYacht";
import { updateCabin } from "../../../redux/actions/catalogs/cabin/updateCabin";

// Resources
import { scrollToTop } from "../../resources/utils";
import { capacityCabin } from "../../resources/optionsList";

export default function CabinForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { yacht_id } = useParams();
  let { cabin_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const deckState = useSelector((state) => state.deckReducer);
  const yachtState = useSelector((state) => state.yachtReducer);
  const cabinState = useSelector((state) => state.cabinReducer);
  const cabinByYachtState = useSelector((state) => state.cabinsByYachtReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDecks());
    dispatch(fetchYacht(yacht_id));
    dispatch(fetchCabinsByYacht(yacht_id));
    if (cabin_id) {
      dispatch(fetchCabin(cabin_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateCabin());
      dispatch(initialStateDeck());
      dispatch(initialStateYacht());
    };
  }, []);

  if (cabinState.fetching || deckState.fetching || yachtState.fetching || cabinByYachtState.fetching) {
    return <Loader active inline />;
  }

  if (cabinState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (cabin_id) {
      dispatch(updateCabin(cabin_id, data));
    } else {
      dispatch(createCabin(yacht_id, data));
    }
  };

  if (cabinState.message === "resource created successfully" || cabinState.message === "resource updated successfully") {
    history.go(-1);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section><Link to="/yachts" className="section">{t("yacht_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/yacht/${yacht_id}/cabins`} className="section">{yachtState.yacht.name}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("cabin_path")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("cabin_cabin")}</label>
            <input name="name" defaultValue={cabinState.cabin.name} placeholder={t("cabin_cabin")} autoFocus maxLength={50} ref={register({ required: true })} />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("cabin_level")}</label>

            <select name="deck_id" defaultValue={cabinState.cabin.deck_id} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {deckState.decks.map((deck) => (
                <option key={deck.id} value={deck.id}>{deck.name}</option>
              ))}
            </select>
            {errors.deck_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("cabin_capacity")}</label>
            <select name="availability" defaultValue={cabinState.cabin.availability} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {capacityCabin.map((capacity, index) => (
                <option key={index} value={capacity}>{capacity}</option>
              ))}
            </select>
            {errors.availability && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <Form.Group widths="equal">
              <Form.Field >
                <label>{t("cabin_familyRoom")}</label>
                <select name="family_room_cabin" defaultValue={cabinState.cabin.family_room_cabin} aria-expanded="true" ref={register}>
                  <option></option>
                  {cabinByYachtState.cabins.map((cabin) => (
                    <option key={cabin.name} value={cabin.name}>{cabin.name}</option>
                  ))}
                </select>
              </Form.Field>
             
            </Form.Group>
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("cabin_union_beds")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="union_beds" defaultChecked={cabinState.cabin.union_beds === 1} ref={register} />
              <label></label>
            </div>
          </Form.Field>
          <Form.Field>
            <label>{t("active_tag")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" defaultChecked={cabinState.cabin.active === 1} ref={register} />
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={cabinState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={`/yacht/${yacht_id}/cabins`}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
