import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import NoteService from "../../../../services/catalogs/NoteService";

export const REQUEST_CREATE_NOTE = "REQUEST_CREATE_NOTE";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_ERROR = "CREATE_NOTE_ERROR";

function requestCreateNote() {
  return {
    type: REQUEST_CREATE_NOTE,
  };
}

function createNoteSuccess(json) {
  return {
    type: CREATE_NOTE_SUCCESS,
    message: json.data,
  };
}

function createNoteError(json) {
  return {
    type: CREATE_NOTE_ERROR,
    error: json.data,
  };
}

export function createNote(payload) {
  return (dispatch) => {
    dispatch(requestCreateNote());
    return NoteService.postNote(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createNoteSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createNote(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createNoteError(json.data));
      }
    });
  };
}
