import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CaptainService from "../../../../services/catalogs/CaptainService";

export const REQUEST_FETCH_CAPTAINS = "REQUEST_FETCH_CAPTAINS";
export const FETCH_CAPTAINS_SUCCESS = "FETCH_CAPTAINS_SUCCESS";
export const FETCH_CAPTAINS_ERROR = "FETCH_CAPTAINS_ERROR";

function requestFetchCaptains() {
  return {
    type: REQUEST_FETCH_CAPTAINS,
  };
}

function fetchCaptainsSuccess(json) {
  return {
    type: FETCH_CAPTAINS_SUCCESS,
    captains: json.data,
  };
}

function fetchCaptainsError(json) {
  return {
    type: FETCH_CAPTAINS_ERROR,
    error: json.data,
  };
}

export function fetchCaptains(searchWord) {
  return (dispatch) => {
    dispatch(requestFetchCaptains());
    return CaptainService.getCaptains(searchWord).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCaptainsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCaptains(searchWord));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCaptainsError(json.data));
      }
    });
  };
}
