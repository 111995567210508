import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { initialStateFamilyRoom } from "../../../redux/actions/rates/familyRoom/fetchFamilyRoom";
import { createFamilyRoom } from "../../../redux/actions/rates/familyRoom/createFamilyRoom";
import { updateFamilyRoom } from "../../../redux/actions/rates/familyRoom/updateFamilyRoom";
import { fetchFamilyRoomFormCustom, initialStateFamilyRoomFormCustom } from "../../../redux/slices/custom/rates/familyRoom/customFamilyRoomForm.slice";
// Resources
import { scrollToTop } from "../../resources/utils";
import { years } from "../../resources/optionsList";

export default function FamilyRoomForm() {
  const sixDotTwoRegex = new RegExp(/^\d{0,6}(\.\d{0,2})?$/);
  const { t } = useTranslation();
  let history = useHistory();
  let { family_room_id } = useParams();
  const { register, handleSubmit, errors, getValues } = useForm();
  const familyRoomRateStateCustom = useSelector((state) => state.customFamilyRoomForm);

  const familyRoomRateState = useSelector((state) => state.familyRoomRateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFamilyRoomFormCustom(family_room_id));
    scrollToTop();
    return () => {
      dispatch(initialStateFamilyRoomFormCustom());
      dispatch(initialStateFamilyRoom());
    };
  }, []);

  if (familyRoomRateStateCustom.fetching || familyRoomRateState.fetching ) {
    return <Loader active inline />;
  }

  const onSubmit = (data) => {
    if (family_room_id) {
      dispatch(updateFamilyRoom(family_room_id, data));
    } else {
      dispatch(createFamilyRoom(data));
    }
  };

  if (familyRoomRateState.message === "resource created successfully" || familyRoomRateState.message === "resource updated successfully") {
    let familyRoomRateFilters = {
      year: getValues("year"),
      rate: getValues("rate_id"),
      yacht: getValues("yacht_id"),
      days: getValues("days"),
    };
    localStorage.setItem("familyRoomRateFilters", JSON.stringify(familyRoomRateFilters));
    history.push("/familyRooms");
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section ><Link to="/familyRooms" className="section">{t("family_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("family_path")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("family_year")}</label>
            <select name="year" aria-expanded="true" defaultValue={familyRoomRateStateCustom.familyRoom.year} ref={register({ required: true })}>
                <option />
                {years.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
            </select>
            {errors.year && (<span className="error_message">{t("required_information")}{" "}</span>)}
          </Form.Field>

          <Form.Field required>
            <label>{t("family_type")}</label>
            <select name="rate_id" defaultValue={familyRoomRateStateCustom.familyRoom.rate_id} aria-expanded="true" ref={register({ required: true })}>
              <option />
              {familyRoomRateStateCustom.rates.map((rateType, index) => (
                <option key={rateType.id} value={rateType.id}>{t(rateType.name)}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>

          <Form.Field required>
            <label>{t("family_yacht")}</label>
            <select name="yacht_id" defaultValue={familyRoomRateStateCustom.familyRoom.yacht_id} aria-expanded="true" ref={register({ required: true }) }>
              <option />
              {familyRoomRateStateCustom.yachts.map((yacht) => (
                <option key={yacht.id}value={yacht.id}>{yacht.name}</option>
              ))}
            </select>
            {errors.yacht_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>

          <Form.Field>
            <label>{t("family_days")}</label>
              <select name="days" defaultValue={familyRoomRateStateCustom.familyRoom.days} aria-expanded="true" ref={register({ required: true })}>
                <option />
                {familyRoomRateStateCustom.days.map((day, index) => (
                  <option key={index} value={day.number_days}>{t(day.number_days)}</option>
                ))}
              </select>
              {errors.days && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>
        
        <Form.Group widths="equal">
          <Form.Field  width={2}>
            <label>{t("family_passengers")} </label>
            <select aria-expanded="true" name="passengers" defaultValue={familyRoomRateStateCustom.familyRoom.passengers} ref={register({ required: true })}>
              <option></option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
            {errors.passengers && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>

          <Form.Field width={2} />

          <Form.Field width={2}>
            <label>{t("family_clientRate")} *</label>
            <input name="customer_rate" defaultValue={familyRoomRateStateCustom.familyRoom.customer_rate} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
            {errors.customer_rate && errors.customer_rate.type === "required" && <span className="error_message">{t("required_information")}</span>}
            {errors.customer_rate && errors.customer_rate.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
          </Form.Field>

          <Form.Field  width={2}>
            <label>{t("ticket_airline")} </label>
            <select aria-expanded="true" name="airplane_ticket" defaultValue={familyRoomRateStateCustom.familyRoom.airplane_ticket} ref={register({ required: true })}>
              <option value="1">{t("yes")} </option>
              <option value="0">{t("not")} </option>
            </select>
          </Form.Field>

          <Form.Field width={2} />

          <Form.Field width={2}>
            <label>{t("family_operatorRate")} *</label>
            <input name="operator_rate" defaultValue={familyRoomRateStateCustom.familyRoom.operator_rate} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
            {errors.operator_rate && errors.operator_rate.type === "required" && <span className="error_message">{t("required_information")}</span>}
            {errors.operator_rate && errors.operator_rate.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
          </Form.Field>
        </Form.Group>

        <Divider />
        <Form.Group widths="equal">
          
          <Form.Field>
            <label>{t("comment")}</label>
            <textarea rows="3" name="comment" defaultValue={familyRoomRateStateCustom.familyRoom.comment} ref={register}/>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />
       
        <Button primary type="submit" loading={familyRoomRateStateCustom.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/familyRooms"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
