import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ReservationService from "../../../../services/reservations/ReservationService";

export const REQUEST_CREATE_RESERVATION = "REQUEST_CREATE_RESERVATION";
export const CREATE_RESERVATION_SUCCESS = "CREATE_RESERVATION_SUCCESS";
export const CREATE_RESERVATION_ERROR = "CREATE_RESERVATION_ERROR";

function requestCreateReservation() {
  return {
    type: REQUEST_CREATE_RESERVATION,
  };
}

function createReservationSuccess(json) {
  return {
    type: CREATE_RESERVATION_SUCCESS,
    message: json.data,
  };
}

function createReservationError(json) {
  return {
    type: CREATE_RESERVATION_ERROR,
    error: json.data,
  };
}

export function createReservation(cruise_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateReservation());
    return ReservationService.postReservation(cruise_id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(createReservationSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(createReservation(cruise_id, payload));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(createReservationError(json.data));
        }
      }
    );
  };
}
