import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class AirlineService {
  static getAirlines() {
    return axios.get("airlines");
  }
  static getAirline(airline_id) {
    return axios.get("airlines/" + airline_id);
  }

  static postAirline(payload) {
    return axios.post("airlines", payload);
  }

  static putAirline(airline_id, payload) {
    return axios.put("airlines/" + airline_id, payload);
  }

  static deleteAirline(airline_id) {
    return axios.delete("airlines/" + airline_id);
  }
}
