import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customYachtItineraryForm = createSlice({
  name: 'customYachtItineraryForm',
  initialState: {
    yachts: [],
    itinerary: {},
    yachtItinerary: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateYachtItineraryFormCustom(state, action) {
      return {
        yachts: [],
        itinerary: {},
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesYachtItineraryFormCustom(state, action) {
      return {
        yachts: [],
        itinerary: {},
        yachtItinerary: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchYachtItineraryFormCustomSuccess(state, action) {
      return {
        yachts: action.payload.yachts,
        itinerary: action.payload.itinerary,
        yachtItinerary: action.payload.yachtItinerary,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchYachtItineraryFormCustomError(state, action) {
      return {
        yachts: [],
        itinerary: {},
        yachtItinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchYachtItineraryFormCustom = (itinerary_id, yacht_id) => dispatch => {
  dispatch(requesYachtItineraryFormCustom());
  axios.get(`/itinerary/${itinerary_id}/form/yachtItineraryForm?yacht_id=${yacht_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchYachtItineraryFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchYachtItineraryFormCustom(itinerary_id, yacht_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchYachtItineraryFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateYachtItineraryFormCustom,
  requesYachtItineraryFormCustom,
  fetchYachtItineraryFormCustomSuccess,
  fetchYachtItineraryFormCustomError,
} = customYachtItineraryForm.actions;

export default customYachtItineraryForm.reducer;