import React from "react";
import SetDiscountModal from "../../components/cruise/setDiscountModal"

export default function GeneralRateCell(props) {
  return (
    <>
      { (props.user.role == "admin" && props.space.special_discount_price == 0.00) ? (
        <>
          <SetDiscountModal stateSpace={props.space} />
        </>
        ) : (
        <>
          - $ {props.space.special_discount_price}
        </>
      )}
    </>
  )
}