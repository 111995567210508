import CruiseRateService from "../../../../services/reservations/CruiseRateService";

export const REQUEST_DELETE_CRUISE_RATE = "REQUEST_DELETE_CRUISE_RATE";
export const DELETE_CRUISE_RATE_SUCCESS = "DELETE_CRUISE_RATE_SUCCESS";
export const DELETE_CRUISE_RATE_ERROR = "DELETE_CRUISE_RATE_ERROR";

function requestDeleteCruiseRate() {
  return {
    type: REQUEST_DELETE_CRUISE_RATE,
  };
}

function deleteCruiseRateSuccess(json) {
  return {
    type: DELETE_CRUISE_RATE_SUCCESS,
    message: json.data,
  };
}

function deleteCruiseRateError(json) {
  return {
    type: DELETE_CRUISE_RATE_ERROR,
    error: json.data,
  };
}

export function deleteCruiseRate(cruise_id, id) {
  return (dispatch) => {
    dispatch(requestDeleteCruiseRate());
    return CruiseRateService.deleteCruiseRate(cruise_id, id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteCruiseRateSuccess(json.data));
      } else {
        dispatch(deleteCruiseRateError(json.data));
      }
    });
  };
}
