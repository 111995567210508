import {
    REQUEST_FETCH_YEARS,
    FETCH_YEARS_SUCCESS,
    FETCH_YEARS_ERROR,
    INITIAL_STATE_YEARS,
} from "../../../actions/rates/generalRates/fetchYears";

const initialState = {
    years: [],
    fetching: false,
    message: "",
    error: "",
};

const generalRateYearsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_YEARS: {
      return {
        ...state,
        years: [],
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_YEARS: {
      return {
        ...state,
        years: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_SUCCESS: {
      return {
        ...state,
        years: action.years,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_ERROR: {
      return {
        ...state,
        years: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default generalRateYearsReducer;