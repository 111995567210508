import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import YachtItineraryService from "../../../../services/catalogs/YachtItineraryService";

export const REQUEST_FETCH_YACHT_ITINERARY = "REQUEST_FETCH_YACHT_ITINERARY";
export const FETCH_YACHT_ITINERARY_SUCCESS = "FETCH_YACHT_ITINERARY_SUCCESS";
export const FETCH_YACHT_ITINERARY_ERROR = "FETCH_YACHT_ITINERARY_ERROR";
export const INITIAL_STATE_YACHT_ITINERARY = "INITIAL_STATE_YACHT_ITINERARY";

function requestFetchYachtItinerary() {
  return {
    type: REQUEST_FETCH_YACHT_ITINERARY,
  };
}

function fetchYachtItinerarySuccess(json) {
  return {
    type: FETCH_YACHT_ITINERARY_SUCCESS,
    yachtItinerary: json.data,
  };
}

function fetchYachtItineraryError(json) {
  return {
    type: FETCH_YACHT_ITINERARY_ERROR,
    error: json.data,
  };
}

export function initialStateYachtItinerary() {
  return {
    type: INITIAL_STATE_YACHT_ITINERARY,
  };
}

export function fetchYachtItinerary(itinerary_id, id) {
  return (dispatch) => {
    dispatch(requestFetchYachtItinerary());
    return YachtItineraryService.getYachtItinerary(itinerary_id, id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchYachtItinerarySuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchYachtItinerary(itinerary_id, id));
        } else if (json.data.code === 498) {
          logOut()
        } else {
          dispatch(fetchYachtItineraryError(json.data));
        }
      }
    );
  };
}
