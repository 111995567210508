import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CabinBerthService from "../../../../services/catalogs/CabinBerthService";

export const REQUEST_DELETE_CABIN_BERTH = "REQUEST_DELETE_CABIN_BERTH";
export const DELETE_CABIN_BERTH_SUCCESS = "DELETE_CABIN_BERTH_SUCCESS";
export const DELETE_CABIN_BERTH_ERROR = "DELETE_CABIN_BERTH_ERROR";

function requestDeleteCabinBerth() {
  return {
    type: REQUEST_DELETE_CABIN_BERTH,
  };
}

function deleteCabinBerthSuccess(json) {
  return {
    type: DELETE_CABIN_BERTH_SUCCESS,
    message: json.data,
  };
}

function deleteCabinBerthError(json) {
  return {
    type: DELETE_CABIN_BERTH_ERROR,
    error: json.data,
  };
}

export function deleteCabinBerth(cabin_id, id) {
  return (dispatch) => {
    dispatch(requestDeleteCabinBerth());
    return CabinBerthService.deleteCabinBerth(cabin_id, id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteCabinBerthSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteCabinBerth(cabin_id, id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteCabinBerthError(json.data));
      }
    });
  };
}
