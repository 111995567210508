import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import BookingService from "../../../../services/reservations/BookingService";

export const REQUEST_FETCH_AVAILABLE_CABINS_BY_BOOKING =
  "REQUEST_FETCH_AVAILABLE_CABINS_BY_BOOKING";
export const FETCH_AVAILABLE_CABINS_BY_BOOKING_SUCCESS =
  "FETCH_AVAILABLE_CABINS_BY_BOOKING_SUCCESS";
export const FETCH_AVAILABLE_CABINS_BY_BOOKING_ERROR =
  "FETCH_AVAILABLE_CABINS_BY_BOOKING_ERROR";

function requestFetchAvailableCabinsByBooking() {
  return {
    type: REQUEST_FETCH_AVAILABLE_CABINS_BY_BOOKING,
  };
}

function fetchAvailableCabinsByBookingSuccess(json) {
  return {
    type: FETCH_AVAILABLE_CABINS_BY_BOOKING_SUCCESS,
    cabins: json.data,
  };
}

function fetchAvailableCabinsByBookingError(json) {
  return {
    type: FETCH_AVAILABLE_CABINS_BY_BOOKING_ERROR,
    error: json.data,
  };
}

export function fetchAvailableCabinsByBooking(id) {
  return (dispatch) => {
    dispatch(requestFetchAvailableCabinsByBooking());
    return BookingService.getAvailableCabins(id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchAvailableCabinsByBookingSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchAvailableCabinsByBooking(id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchAvailableCabinsByBookingError(json.data));
      }
    });
  };
}
