import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideLanguageService from "../../../../services/catalogs/GuideLanguageService";

export const REQUEST_FETCH_GUIDE_LANGUAGES = "REQUEST_FETCH_GUIDE_LANGUAGES";
export const FETCH_GUIDE_LANGUAGES_SUCCESS = "FETCH_GUIDE_LANGUAGES_SUCCESS";
export const FETCH_GUIDE_LANGUAGES_ERROR = "FETCH_GUIDE_LANGUAGES_ERROR";

function requestFetchGuideLanguages() {
  return {
    type: REQUEST_FETCH_GUIDE_LANGUAGES,
  };
}

function fetchGuideLanguagesSuccess(json) {
  return {
    type: FETCH_GUIDE_LANGUAGES_SUCCESS,
    guideLanguages: json.data,
  };
}

function fetchGuideLanguagesError(json) {
  return {
    type: FETCH_GUIDE_LANGUAGES_ERROR,
    error: json.data,
  };
}

export function fetchGuideLanguages(guide_id) {
  return (dispatch) => {
    dispatch(requestFetchGuideLanguages());
    return GuideLanguageService.getGuideLanguages(guide_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchGuideLanguagesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGuideLanguages(guide_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGuideLanguagesError(json.data));
      }
    });
  };
}
