import { logOut, updateLocalStorageToken } from '../../../../containers/resources/utils';
import BookingService from '../../../../services/reservations/BookingService';

export const REQUEST_FETCH_BOOKING_LOGS = "REQUEST_FETCH_BOOKING_LOGS";
export const FETCH_BOOKING_LOGS_SUCCESS = "FETCH_BOOKING_LOGS_SUCCESS";
export const FETCH_BOOKING_LOGS_ERROR = "FETCH_BOOKING_LOGS_ERROR";

function requestFetchBookingLogs() {
  return {
    type: REQUEST_FETCH_BOOKING_LOGS,
  };
}

function fetchBookingLogsSuccess(json) {
  return {
    type: FETCH_BOOKING_LOGS_SUCCESS,
    logs: json.data
  };
}

function fetchBookingLogsError(json) {
  return {
    type: FETCH_BOOKING_LOGS_ERROR,
    error: json.data,
  };
}

export function fetchBookingLogs(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchBookingLogs());
    return BookingService.getItemsByField(cruise_id).then((json) => {
      if (json.status === 200) {
        dispatch(fetchBookingLogsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchBookingLogs(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchBookingLogsError(json.data));
      }
    });
  };
}
