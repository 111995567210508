import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SpaceFlightService from "../../../../services/reservations/SpaceFlightService";

export const REQUEST_FETCH_NUMBER_FLIGHTS = "REQUEST_FETCH_NUMBER_FLIGHTS";
export const FETCH_NUMBER_FLIGHTS_SUCCESS = "FETCH_NUMBER_FLIGHTS_SUCCESS";
export const FETCH_NUMBER_FLIGHTS_ERROR = "FETCH_NUMBER_FLIGHTS_ERROR";
export const INITIAL_STATE_NUMBER_SPACE_FLIGHT =
  "INITIAL_STATE_NUMBER_SPACE_FLIGHT";

function requestFetchNumberFlights() {
  return {
    type: REQUEST_FETCH_NUMBER_FLIGHTS,
  };
}

function fetchNumberFlightsSuccess(json) {
  return {
    type: FETCH_NUMBER_FLIGHTS_SUCCESS,
    numberFlights: json.data,
  };
}

function fetchNumberFlightsError(json) {
  return {
    type: FETCH_NUMBER_FLIGHTS_ERROR,
    error: json.data,
  };
}

export function initialStateNumberSpaceFlight() {
  return {
    type: INITIAL_STATE_NUMBER_SPACE_FLIGHT,
  };
}

export function fetchNumberFlightsBySpace(space_id) {
  return (dispatch) => {
    dispatch(requestFetchNumberFlights());
    return SpaceFlightService.getNumberSpaceFlights(space_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchNumberFlightsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchNumberFlightsBySpace(space_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchNumberFlightsError(json.data));
      }
    });
  };
}
