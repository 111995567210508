import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customIkarusRateList = createSlice({
  name: 'customIkarusRateList',
  initialState: {
    years: [],
    agencies: [],
    yachts: [],
    days: [],
    ikarusRates: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateIkarusRateListCustom(state, action) {
      return {
        years: [],
        agencies: [],
        yachts: [],
        days: [],
        ikarusRates: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesIkarusRateListCustom(state, action) {
      return {
        years: [],
        agencies: [],
        yachts: [],
        days: [],
        ikarusRates: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchIkarusRateListCustomSuccess(state, action) {
      return {
        years: action.payload.years,
        agencies: action.payload.agencies,
        yachts: action.payload.yachts,
        days: action.payload.days,
        ikarusRates: action.payload.ikarusRates,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchIkarusRateListCustomError(state, action) {
      return {
        years: [],
        agencies: [],
        yachts: [],
        days: [],
        ikarusRates: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchIkarusRateListCustom = (payload, searchWord) => dispatch => {
  const params = new URLSearchParams([['year', payload.year], ['days', payload.days], ['agency_id', payload.agency_id], ['yacht_id', payload.yacht_id]]);
  dispatch(requesIkarusRateListCustom());
  axios.get(`/ikarusRates/ikarusRatesList?searchWord=${searchWord}`, {params})
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchIkarusRateListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchIkarusRateListCustom(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchIkarusRateListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateIkarusRateListCustom,
  requesIkarusRateListCustom,
  fetchIkarusRateListCustomSuccess,
  fetchIkarusRateListCustomError,
} = customIkarusRateList.actions;

export default customIkarusRateList.reducer;