import {
  REQUEST_FETCH_DECKS,
  FETCH_DECKS_SUCCESS,
  FETCH_DECKS_ERROR,
} from "../../../actions/catalogs/deck/fetchDecks";

import {
  INITIAL_STATE_DECK,
  REQUEST_FETCH_DECK,
  FETCH_DECK_SUCCESS,
  FETCH_DECK_ERROR,
} from "../../../actions/catalogs/deck/fetchDeck";

import {
  REQUEST_CREATE_DECK,
  CREATE_DECK_SUCCESS,
  CREATE_DECK_ERROR,
} from "../../../actions/catalogs/deck/createDeck";

import {
  REQUEST_UPDATE_DECK,
  UPDATE_DECK_SUCCESS,
  UPDATE_DECK_ERROR,
} from "../../../actions/catalogs/deck/updateDeck";

import {
  REQUEST_DELETE_DECK,
  DELETE_DECK_SUCCESS,
  DELETE_DECK_ERROR,
} from "../../../actions/catalogs/deck/deleteDeck";

const initialState = {
  decks: [],
  deck: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const deckReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_DECK: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_DECKS: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_DECKS_SUCCESS: {
      return {
        ...state,
        decks: action.decks,
        deck: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_DECKS_ERROR: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_DECK: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_DECK_SUCCESS: {
      return {
        ...state,
        decks: [],
        deck: action.deck,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_DECK_ERROR: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_DECK: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_DECK_SUCCESS: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_DECK_ERROR: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_DECK: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_DECK_SUCCESS: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_DECK_ERROR: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_DECK: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_DECK_SUCCESS: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_DECK_ERROR: {
      return {
        ...state,
        decks: [],
        deck: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default deckReducer;
