import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import TicketService from "../../../../services/rates/TicketService";

export const REQUEST_CREATE_TICKET = "REQUEST_CREATE_TICKET";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_ERROR = "CREATE_TICKET_ERROR";

function requestCreateTicket() {
  return {
    type: REQUEST_CREATE_TICKET,
  };
}

function createTicketSuccess(json) {
  return {
    type: CREATE_TICKET_SUCCESS,
    message: json.data,
  };
}

function createTicketError(json) {
  return {
    type: CREATE_TICKET_ERROR,
    error: json.data,
  };
}

export function createTicket(payload) {
  return (dispatch) => {
    dispatch(requestCreateTicket());
    return TicketService.postTicket(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createTicketSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createTicket(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createTicketError(json.data));
      }
    });
  };
}
