import {
  REQUEST_FETCH_ITINERARIES_BY_YACHT,
  FETCH_ITINERARIES_BY_YACHT_SUCCESS,
  FETCH_ITINERARIES_BY_YACHT_ERROR,
  INITIAL_STATE_ITINERARIES_BY_YACHT,
} from "../../../actions/catalogs/itineraryByYacht/fetchItinerariesByYacht";

const initialState = {
  itineraries: [],
  fetching: false,
  message: "",
  error: "",
};

const itineraryByYachtReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_ITINERARIES_BY_YACHT: {
      return {
        ...state,
        itineraries: [],
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_ITINERARIES_BY_YACHT: {
      return {
        ...state,
        itineraries: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_ITINERARIES_BY_YACHT_SUCCESS: {
      return {
        ...state,
        itineraries: action.itineraries,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_ITINERARIES_BY_YACHT_ERROR: {
      return {
        ...state,
        itineraries: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default itineraryByYachtReducer;
