import UserService from "../../../../services/catalogs/UserService";
import { updateLocalStorageToken, logOut } from "../../../../containers/resources/utils";

export const REQUEST_FETCH_USERS = "REQUEST_FETCH_USERS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";

function requestFetchUsers() {
  return {
    type: REQUEST_FETCH_USERS,
  };
}

function fetchUsersSuccess(json) {
  return {
    type: FETCH_USERS_SUCCESS,
    users: json.data,
  };
}

function fetchUsersError(json) {
  return {
    type: FETCH_USERS_ERROR,
    error: json.data,
  };
}

export function fetchAgencyUsers(agency_id) {
  return (dispatch) => {
    dispatch(requestFetchUsers());
    return UserService.getAgencyUsers(agency_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchUsersSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchAgencyUsers(agency_id));
      }else if (json.data.code === 498){
        logOut();
      } else {
        dispatch(fetchUsersError(json.data));
      }
    });
  };
}

export function fetchCompanyUsers() {
  return (dispatch) => {
    dispatch(requestFetchUsers());
    return UserService.getUsers().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchUsersSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCompanyUsers());
      }else if (json.data.code === 498){
        logOut();
      } else {
        dispatch(fetchUsersError(json.data));
      }
    });
  };
}
