import {
  REQUEST_FETCH_CRUISES_BY_PASSENGER,
  FETCH_CRUISES_BY_PASSENGER_SUCCESS,
  FETCH_CRUISES_BY_PASSENGER_ERROR,
  INITIAL_STATE_CRUISE_BY_PASSENGER,
} from "../../../actions/reservations/passenger/fetchCruisesByPassenger";

const initialState = {
  cruises: [],
  fetching: false,
  message: "",
  error: "",
};

const cruiseByPassengerReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_CRUISE_BY_PASSENGER: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_CRUISES_BY_PASSENGER: {
      return {
        ...state,
        cruises: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISES_BY_PASSENGER_SUCCESS: {
      return {
        ...state,
        cruises: action.cruises,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISES_BY_PASSENGER_ERROR: {
      return {
        ...state,
        cruises: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default cruiseByPassengerReducer;
