import UserService from "../../../../services/catalogs/UserService";

export const REQUEST_FORGOT_PASSWORD_USER = "REQUEST_FORGOT_PASSWORD_USER";
export const FORGOT_PASSWORD_USER_SUCCESS = "FORGOT_PASSWORD_USER_SUCCESS";
export const FORGOT_PASSWORD_USER_ERROR = "FORGOT_PASSWORD_USER_ERROR";

function requestForgotPasswordUser() {
  return {
    type: REQUEST_FORGOT_PASSWORD_USER,
  };
}

function ForgotPasswordUserSuccess(json) {
  return {
    type: FORGOT_PASSWORD_USER_SUCCESS,
    message: json.data,
  };
}

function ForgotPasswordUserError(json) {
  return {
    type: FORGOT_PASSWORD_USER_ERROR,
    error: json.data,
  };
}

export function ForgotPasswordUser( payload) {
  return (dispatch) => {
    dispatch(requestForgotPasswordUser());
    return UserService.ForgotPasswordUser(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(ForgotPasswordUserSuccess(json.data));
      } else {
        dispatch(ForgotPasswordUserError(json.data));
      }
    });
  };
}
