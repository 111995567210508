import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GenderService from "../../../../services/catalogs/GenderService";

export const REQUEST_FETCH_GENDER = "REQUEST_FETCH_GENDER";
export const FETCH_GENDER_SUCCESS = "FETCH_GENDER_SUCCESS";
export const FETCH_GENDER_ERROR = "FETCH_GENDER_ERROR";
export const INITIAL_STATE_GENDER = "INITIAL_STATE_GENDER";

function requestFetchGender() {
  return {
    type: REQUEST_FETCH_GENDER,
  };
}

function fetchGenderSuccess(json) {
  return {
    type: FETCH_GENDER_SUCCESS,
    gender: json.data,
  };
}

function fetchGenderError(json) {
  return {
    type: FETCH_GENDER_ERROR,
    error: json.data,
  };
}

export function initialStateGender() {
  return {
    type: INITIAL_STATE_GENDER,
  };
}

export function fetchGender(gender_id) {
  return (dispatch) => {
    dispatch(requestFetchGender());
    return GenderService.getGender(gender_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchGenderSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGender(gender_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGenderError(json.data));
      }
    });
  };
}
