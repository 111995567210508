import {
  REQUEST_FETCH_AGENCIES,
  FETCH_AGENCIES_SUCCESS,
  FETCH_AGENCIES_ERROR,
} from "../../../actions/catalogs/agency/fetchAgencies";

import {
  INITIAL_STATE_AGENCY,
  REQUEST_FETCH_AGENCY,
  FETCH_AGENCY_SUCCESS,
  FETCH_AGENCY_ERROR,
} from "../../../actions/catalogs/agency/fetchAgency";

import {
  REQUEST_CREATE_AGENCY,
  CREATE_AGENCY_SUCCESS,
  CREATE_AGENCY_ERROR,
} from "../../../actions/catalogs/agency/createAgency";

import {
  REQUEST_UPDATE_AGENCY,
  UPDATE_AGENCY_SUCCESS,
  UPDATE_AGENCY_ERROR,
} from "../../../actions/catalogs/agency/updateAgency";

import {
  REQUEST_DELETE_AGENCY,
  DELETE_AGENCY_SUCCESS,
  DELETE_AGENCY_ERROR,
} from "../../../actions/catalogs/agency/deleteAgency";

const initialState = {
  agencies: [],
  agency: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const agencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_AGENCY: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_AGENCIES: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_AGENCIES_SUCCESS: {
      return {
        ...state,
        agencies: action.agencies,
        agency: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_AGENCIES_ERROR: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_AGENCY: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_AGENCY_SUCCESS: {
      return {
        ...state,
        agencies: [],
        agency: action.agency,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_AGENCY_ERROR: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_AGENCY: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_AGENCY_SUCCESS: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_AGENCY_ERROR: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_AGENCY: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_AGENCY_SUCCESS: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_AGENCY_ERROR: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_AGENCY: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_AGENCY_SUCCESS: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_AGENCY_ERROR: {
      return {
        ...state,
        agencies: [],
        agency: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default agencyReducer;
