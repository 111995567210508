import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class CountryService {
  static getCountries(searchWord) {
    var url = "countries";
    url = url + `?searchWord=${searchWord}`;
    return axios.get(url);
  }

  static getCountry(country_id) {
    return axios.get("countries/" + country_id);
  }

  static postCountry(payload) {
    return axios.post("countries", payload);
  }

  static putCountry(country_id, payload) {
    return axios.put("countries/" + country_id, payload);
  }

  static deleteCountry(country_id) {
    return axios.delete("countries/" + country_id);
  }
}
