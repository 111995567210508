import VoucherService from "../../../../services/reservations/VoucherService";

export const REQUEST_DELETE_VOUCHER = "REQUEST_DELETE_VOUCHER";
export const DELETE_VOUCHER_SUCCESS = "DELETE_VOUCHER_SUCCESS";
export const DELETE_VOUCHER_ERROR = "DELETE_VOUCHER_ERROR";

function requestDeleteVoucher() {
  return {
    type: REQUEST_DELETE_VOUCHER,
  };
}

function deleteVoucherSuccess(json) {
  return {
    type: DELETE_VOUCHER_SUCCESS,
    message: json.data,
  };
}

function deleteVoucherError(json) {
  return {
    type: DELETE_VOUCHER_ERROR,
    error: json.data,
  };
}

export function deleteVoucher(voucher_id) {
  return (dispatch) => {
    dispatch(requestDeleteVoucher());
    return VoucherService.deleteVoucher(voucher_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteVoucherSuccess(json.data));
      } else {
        dispatch(deleteVoucherError(json.data));
      }
    });
  };
}
