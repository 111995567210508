import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Icon, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { fetchSeasons } from "../../../redux/actions/rates/season/fetchSeasons";
import { deleteSeason } from "../../../redux/actions/rates/season/deleteSeason";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function SeasonList() {
  const { t } = useTranslation();
  const seasonState = useSelector((state) => state.seasonReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSeasons());
    scrollToTop();
  }, []);

  if (seasonState.fetching) {
    return <Loader active inline />;
  }

  if (seasonState.error) {
    return <div>Error: {seasonState.error}</div>;
  }

  if (seasonState.message === "resource deleted successfully") {
    dispatch(fetchSeasons());
  }

  const handleDeleteSeason = (season_id) => {
    dispatch(deleteSeason(season_id));
  };

  return (
    <>
      <HeaderCatalog title={t("season_title")} message={seasonState.message} to="/seasons_new" />
      <Divider />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("season_year")} </Table.HeaderCell>           
            <Table.HeaderCell>{t("airline")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_january")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_february")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_march")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_april")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_may")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_june")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_july")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_august")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_september")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_october")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_november")} </Table.HeaderCell>
            <Table.HeaderCell>{t("season_december")} </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {seasonState.seasons.map((season) => (
            <Table.Row key={season.id}>
              <Table.Cell>{season.year}</Table.Cell>
              <Table.Cell>{t(season.airline)}</Table.Cell>
              <Table.Cell>{t(season.january)}</Table.Cell>
              <Table.Cell>{t(season.february)}</Table.Cell>
              <Table.Cell>{t(season.march)}</Table.Cell>
              <Table.Cell>{t(season.april)}</Table.Cell>
              <Table.Cell>{t(season.may)}</Table.Cell>
              <Table.Cell>{t(season.june)}</Table.Cell>
              <Table.Cell>{t(season.july)}</Table.Cell>
              <Table.Cell>{t(season.august)}</Table.Cell>
              <Table.Cell>{t(season.september)}</Table.Cell>
              <Table.Cell>{t(season.october)}</Table.Cell>
              <Table.Cell>{t(season.november)}</Table.Cell>
              <Table.Cell>{t(season.december)}</Table.Cell>
              <Table.Cell collapsing>
                <Link to={`/seasons/${season.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
                <Modal
                  trigger={
                    <Button circular color='red' icon='trash alternate' />
                  }
                  content={t("season_delete")}
                  actions={[
                    t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteSeason(season.id),
                    },
                  ]}
                  size="tiny"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
