import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import YachtService from "../../../../services/catalogs/YachtService";

export const REQUEST_CREATE_YACHT = "REQUEST_CREATE_YACHT";
export const CREATE_YACHT_SUCCESS = "CREATE_YACHT_SUCCESS";
export const CREATE_YACHT_ERROR = "CREATE_YACHT_ERROR";

function requestCreateYacht() {
  return {
    type: REQUEST_CREATE_YACHT,
  };
}

function createYachtSuccess(json) {
  return {
    type: CREATE_YACHT_SUCCESS,
    message: json.data,
  };
}

function createYachtError(json) {
  return {
    type: CREATE_YACHT_ERROR,
    error: json.data,
  };
}

export function createYacht(payload) {
  return (dispatch) => {
    dispatch(requestCreateYacht());
    return YachtService.postYacht(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createYachtSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createYacht(payload));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(createYachtError(json.data));
      }
    });
  };
}
