import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CompanyService from "../../../../services/catalogs/CompanyService";

export const REQUEST_FETCH_COMPANY = "REQUEST_FETCH_COMPANY";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_ERROR = "FETCH_COMPANY_ERROR";
export const INITIAL_STATE_COMPANY = "INITIAL_STATE_COMPANY";

function requestFetchComapny() {
  return {
    type: REQUEST_FETCH_COMPANY,
  };
}

function fetchCompanySuccess(json) {
  return {
    type: FETCH_COMPANY_SUCCESS,
    company: json.data,
  };
}

function fetchCompanyError(json) {
  return {
    type: FETCH_COMPANY_ERROR,
    error: json.data,
  };
}

export function initialStateCompany() {
  return {
    type: INITIAL_STATE_COMPANY,
  };
}

export function fetchCompany(company_id) {
  return (dispatch) => {
    dispatch(requestFetchComapny());
    return CompanyService.getCompany(company_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCompanySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCompany(company_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCompanyError(json.data));
      }
    });
  };
}

export function fetchCompanyByCruise(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchComapny());
    return CompanyService.getCompanyByCruise(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCompanySuccess(json.data));
      } else {
        dispatch(fetchCompanyError(json.data));
      }
    });
  };
}
