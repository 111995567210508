import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customPassengerInFlightList = createSlice({
  name: 'customPassengerInFlightList',
  initialState: {
    cruise: {},
    passengers: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStatePassengerInFlightListCustom(state, action) {
      return {
        cruise: {},
        passengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesPassengerInFlightListCustom(state, action) {
      return {
        cruise: {},
        passengers: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchPassengerInFlightListCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        passengers: action.payload.passengers,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchPassengerInFlightListCustomError(state, action) {
      return {
        cruise: {},
        passengers: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchPassengerInFlightListCustom = (cruise_id) => dispatch => {
  dispatch(requesPassengerInFlightListCustom());
  axios.get(`/cruise/${cruise_id}/passengerInFlightList`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchPassengerInFlightListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchPassengerInFlightListCustom(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchPassengerInFlightListCustomError(json.data.data));
      }
    })
};

export const {
  initialStatePassengerInFlightListCustom,
  requesPassengerInFlightListCustom,
  fetchPassengerInFlightListCustomSuccess,
  fetchPassengerInFlightListCustomError,
} = customPassengerInFlightList.actions;

export default customPassengerInFlightList.reducer;