import {
  REQUEST_FETCH_GUIDES_ON_BOARD,
  FETCH_GUIDES_ON_BOARD_SUCCESS,
  FETCH_GUIDES_ON_BOARD_ERROR,
} from "../../../actions/reservations/guideOnBoard/fetchGuidesOnBoard";

import {
  INITIAL_STATE_GUIDE_ON_BOARD,
  REQUEST_FETCH_GUIDE_ON_BOARD,
  FETCH_GUIDE_ON_BOARD_SUCCESS,
  FETCH_GUIDE_ON_BOARD_ERROR,
} from "../../../actions/reservations/guideOnBoard/fetchGuideOnBoard";

import {
  REQUEST_CREATE_GUIDE_ON_BOARD,
  CREATE_GUIDE_ON_BOARD_SUCCESS,
  CREATE_GUIDE_ON_BOARD_ERROR,
} from "../../../actions/reservations/guideOnBoard/createGuideOnBoard";

import {
  REQUEST_UPDATE_GUIDE_ON_BOARD,
  UPDATE_GUIDE_ON_BOARD_SUCCESS,
  UPDATE_GUIDE_ON_BOARD_ERROR,
} from "../../../actions/reservations/guideOnBoard/updateGuideOnBoard";

import {
  REQUEST_DELETE_GUIDE_ON_BOARD,
  DELETE_GUIDE_ON_BOARD_SUCCESS,
  DELETE_GUIDE_ON_BOARD_ERROR,
} from "../../../actions/reservations/guideOnBoard/deleteGuideOnBoard";

const initialState = {
  guidesOnBoard: [],
  guideOnBoard: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const guideOnBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_GUIDE_ON_BOARD: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_GUIDES_ON_BOARD: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDES_ON_BOARD_SUCCESS: {
      return {
        ...state,
        guidesOnBoard: action.guidesOnBoard,
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDES_ON_BOARD_ERROR: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_GUIDE_ON_BOARD: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDE_ON_BOARD_SUCCESS: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: action.guideOnBoard,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_GUIDE_ON_BOARD_ERROR: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_GUIDE_ON_BOARD: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_GUIDE_ON_BOARD_SUCCESS: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_GUIDE_ON_BOARD_ERROR: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_GUIDE_ON_BOARD: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_GUIDE_ON_BOARD_SUCCESS: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_GUIDE_ON_BOARD_ERROR: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_GUIDE_ON_BOARD: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_GUIDE_ON_BOARD_SUCCESS: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_GUIDE_ON_BOARD_ERROR: {
      return {
        ...state,
        guidesOnBoard: [],
        guideOnBoard: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default guideOnBoardReducer;
