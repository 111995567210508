import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createFlight } from "../../../redux/actions/catalogs/flight/createFlight";
import { fetchAirlines } from "../../../redux/actions/catalogs/airline/fetchAirlines";
import { initialStateAirline } from "../../../redux/actions/catalogs/airline/fetchAirline";
import { fetchFlight, initialStateFlight } from "../../../redux/actions/catalogs/flight/fetchFlight";
import { updateFlight } from "../../../redux/actions/catalogs/flight/updateFlight";
import { fetchFlightFormCustom, initialStateFlightFormCustom } from "../../../redux/slices/custom/catalogs/flight/customFlightForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function FlightForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { flight_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const flightStateCustom = useSelector((state) => state.customFlightForm);
  //const flightStateCustom = useSelector((state) => state.airlineReducer);
  const flightState = useSelector((state) => state.flightReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFlightFormCustom(flight_id));
    scrollToTop();
    return () => {
      dispatch(initialStateFlight());
      dispatch(initialStateFlightFormCustom());
    };
  }, [dispatch, flight_id]);


  if (flightState.fetching || flightStateCustom.fetching) {
    return <Loader active inline />;
  }

  if (flightStateCustom.error || flightState.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (flight_id) {
      dispatch(updateFlight(flight_id, data));
    } else {
      dispatch(createFlight(data));
    }
  };

  if (flightState.message === "resource created successfully" || flightState.message === "resource updated successfully") {
    history.push("/flights");
  }

  return (
    <>
      <Header as='h3' dividing>{t("flight_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("flight_code")}</label>
            <input name="code" defaultValue={flightStateCustom.flight.code} placeholder={t("flight_code")} autoFocus maxLength={50} ref={register({ required: true })} />
            {errors.code && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("flight_airline")}</label>
            <select name="airline_id" defaultValue={flightStateCustom.flight.airline_id} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {flightStateCustom.airlines.map((airline) => (
                <option key={airline.id} value={airline.id}>{airline.name}</option>
              ))}
            </select>
            {errors.airline_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("active_tag")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" defaultChecked={flightStateCustom.flight.active === 1}  ref={register} />
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={flightStateCustom.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/flights"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
