import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class AgencyService {
  static getAgencies(searchWord) {
    var url = "agencies";
    url = url + `?searchWord=${searchWord}`;
    return axios.get(url);
  }
  static getAgency(agency_id) {
    return axios.get("agencies/" + agency_id);
  }

  static postAgency(payload) {
    return axios.post("agencies", payload);
  }

  static putAgency(agency_id, payload) {
    return axios.put("agencies/" + agency_id, payload);
  }

  static deleteAgency(agency_id) {
    return axios.delete("agencies/" + agency_id);
  }
}
