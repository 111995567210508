import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GeneralRateService from "../../../../services/rates/GeneralRateService";

export const REQUEST_ACTIVE_GENERAL_RATE = "REQUEST_ACTIVE_GENERAL_RATE";
export const ACTIVE_GENERAL_RATE_SUCCESS = "ACTIVE_GENERAL_RATE_SUCCESS";
export const ACTIVE_GENERAL_RATE_ERROR = "ACTIVE_GENERAL_RATE_ERROR";

function requestActiveGeneralRate() {
  return {
    type: REQUEST_ACTIVE_GENERAL_RATE,
  };
}

function activeGeneralRateSuccess(json) {
  return {
    type: ACTIVE_GENERAL_RATE_SUCCESS,
    message: json.data,
  };
}

function activeGeneralRateError(json) {
  return {
    type: ACTIVE_GENERAL_RATE_ERROR,
    error: json.data,
  };
}

export function activeGeneralRate(generalRate_id) {
  return (dispatch) => {
    dispatch(requestActiveGeneralRate());
    return GeneralRateService.activeGeneralRate(generalRate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(activeGeneralRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(activeGeneralRate(generalRate_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(activeGeneralRateError(json.data));
      }
    });
  };
}
