import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customSpaceFlightList = createSlice({
  name: 'customSpaceFlightList',
  initialState: {
    cruise: {},
    reservation: {},
    spaceFlights: [],
    booking: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateSpaceFlightListCustom(state, action) {
      return {
        cruise: {},
        reservation: {},
        spaceFlights: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesSpaceFlightListCustom(state, action) {
      return {
        cruise: {},
        reservation: {},
        spaceFlights: [],
        booking: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchSpaceFlightListCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        reservation: action.payload.reservation,
        spaceFlights: action.payload.spaceFlights,
        booking: action.payload.booking,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchSpaceFlightListCustomError(state, action) {
      return {
        cruise: {},
        reservation: {},
        spaceFlights: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchSpaceFlightListCustom = (space_id, cruise_id, reservation_id, booking_id) => dispatch => {
  dispatch(requesSpaceFlightListCustom());
  var url = `space/${space_id}/spaceFlightsList`;
  url = url + `?cruise_id=${cruise_id}&&reservation_id=${reservation_id}&&booking_id=${booking_id}`;
  axios.get(`/${url}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchSpaceFlightListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSpaceFlightListCustom(space_id, cruise_id, reservation_id, booking_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSpaceFlightListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateSpaceFlightListCustom,
  requesSpaceFlightListCustom,
  fetchSpaceFlightListCustomSuccess,
  fetchSpaceFlightListCustomError,
} = customSpaceFlightList.actions;

export default customSpaceFlightList.reducer;