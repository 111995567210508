import RoleService from "../../../../services/catalogs/RoleService";

export const REQUEST_FETCH_ROLES = "REQUEST_FETCH_ROLES ";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_ERROR = "FETCH_ROLES_ERROR";

function requestFetchRoles() {
  return {
    type: REQUEST_FETCH_ROLES,
  };
}

function fetchRolesSuccess(json) {
  return {
    type: FETCH_ROLES_SUCCESS,
    roles: json.data,
  };
}

function fetchRolesError(json) {
  return {
    type: FETCH_ROLES_ERROR,
    error: json.data,
  };
}

export function fetchRoles() {
  return (dispatch) => {
    dispatch(requestFetchRoles());
    return RoleService.getRoles().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchRolesSuccess(json.data));
      } else {
        dispatch(fetchRolesError(json.data));
      }
    });
  };
}
