import {
  REQUEST_FETCH_TICKETS,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKETS_ERROR,
} from "../../../actions/rates/ticket/fetchTickets";

import {
  INITIAL_STATE_TICKET,
  REQUEST_FETCH_TICKET,
  FETCH_TICKET_SUCCESS,
  FETCH_TICKET_ERROR,
} from "../../../actions/rates/ticket/fetchTicket";

import {
  REQUEST_FETCH_YEARS,
  FETCH_YEARS_SUCCESS,
  FETCH_YEARS_ERROR,
} from "../../../actions/rates/ticket/fetchYears";

import {
  REQUEST_CREATE_TICKET,
  CREATE_TICKET_SUCCESS,
  CREATE_TICKET_ERROR,
} from "../../../actions/rates/ticket/createTicket";

import {
  REQUEST_UPDATE_TICKET,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_ERROR,
} from "../../../actions/rates/ticket/updateTicket";

import {
  REQUEST_ACTIVE_TICKET,
  ACTIVE_TICKET_SUCCESS,
  ACTIVE_TICKET_ERROR,
} from "../../../actions/rates/ticket/activeTicket";

import {
  REQUEST_INACTIVE_TICKET,
  INACTIVE_TICKET_SUCCESS,
  INACTIVE_TICKET_ERROR,
} from "../../../actions/rates/ticket/inactiveTicket";

import {
  REQUEST_DELETE_TICKET,
  DELETE_TICKET_SUCCESS,
  DELETE_TICKET_ERROR,
} from "../../../actions/rates/ticket/deleteTicket";

const initialState = {
  tickets: [],
  years: [],
  ticket: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_TICKET: {
      return {
        ...state,
        tickets: [],
        years: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_TICKETS: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_TICKETS_SUCCESS: {
      return {
        ...state,
        tickets: action.tickets,
        ticket: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_TICKETS_ERROR: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_TICKET: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_TICKET_SUCCESS: {
      return {
        ...state,
        tickets: [],
        ticket: action.ticket,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_TICKET_ERROR: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_TICKET: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_TICKET_SUCCESS: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_TICKET_ERROR: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_TICKET: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_TICKET_SUCCESS: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_TICKET_ERROR: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_TICKET: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_ACTIVE_TICKET: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case ACTIVE_TICKET_SUCCESS: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case ACTIVE_TICKET_ERROR: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_INACTIVE_TICKET: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case INACTIVE_TICKET_SUCCESS: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case INACTIVE_TICKET_ERROR: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case DELETE_TICKET_SUCCESS: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_TICKET_ERROR: {
      return {
        ...state,
        tickets: [],
        ticket: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_YEARS: {
      return {
        ...state,
        years: [],
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_SUCCESS: {
      return {
        ...state,
        years: action.years,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_YEARS_ERROR: {
      return {
        ...state,
        years: [],
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default ticketReducer;
