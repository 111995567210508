import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import RouteService from "../../../../services/catalogs/RouteService";

export const REQUEST_DELETE_ROUTE = "REQUEST_DELETE_ROUTE";
export const DELETE_ROUTE_SUCCESS = "DELETE_ROUTE_SUCCESS";
export const DELETE_ROUTE_ERROR = "DELETE_ROUTE_ERROR";

function requestDeleteRoute() {
  return {
    type: REQUEST_DELETE_ROUTE,
  };
}

function deleteRouteSuccess(json) {
  return {
    type: DELETE_ROUTE_SUCCESS,
    message: json.data,
  };
}

function deleteRouteError(json) {
  return {
    type: DELETE_ROUTE_ERROR,
    error: json.data,
  };
}

export function deleteRoute(route_id) {
  return (dispatch) => {
    dispatch(requestDeleteRoute());
    return RouteService.deleteRoute(route_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteRouteSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteRoute(route_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteRouteError(json.data));
      }
    });
  };
}
