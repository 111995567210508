import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class GuideLanguageService {
  static getGuideLanguages(guide_id) {
    return axios.get("guide/" + guide_id + "/languages");
  }
  static getGuideLanguage(guide_id, id) {
    return axios.get("guide/" + guide_id + "/language/" + id);
  }

  static postGuideLanguage(guide_id, payload) {
    return axios.post(
      "guide/" + guide_id + "/languages",
      payload
    );
  }

  static putGuideLanguage(guide_id, id, payload) {
    return axios.put(
      "guide/" + guide_id + "/language/" + id,
      payload
    );
  }

  static deleteGuideLanguage(guide_id, id) {
    return axios.delete("guide/" + guide_id + "/language/" + id);
  }
}
