import {
  REQUEST_FETCH_CABIN_BERTHS,
  FETCH_CABIN_BERTHS_SUCCESS,
  FETCH_CABIN_BERTHS_ERROR,
} from "../../../actions/catalogs/cabinBerth/fetchCabinBerths";

import {
  INITIAL_STATE_CABIN_BERTH,
  REQUEST_FETCH_CABIN_BERTH,
  FETCH_CABIN_BERTH_SUCCESS,
  FETCH_CABIN_BERTH_ERROR,
} from "../../../actions/catalogs/cabinBerth/fetchCabinBerth";

import {
  REQUEST_CREATE_CABIN_BERTH,
  CREATE_CABIN_BERTH_SUCCESS,
  CREATE_CABIN_BERTH_ERROR,
} from "../../../actions/catalogs/cabinBerth/createCabinBerth";

import {
  REQUEST_UPDATE_CABIN_BERTH,
  UPDATE_CABIN_BERTH_SUCCESS,
  UPDATE_CABIN_BERTH_ERROR,
} from "../../../actions/catalogs/cabinBerth/updateCabinBerth";

import {
  REQUEST_DELETE_CABIN_BERTH,
  DELETE_CABIN_BERTH_SUCCESS,
  DELETE_CABIN_BERTH_ERROR,
} from "../../../actions/catalogs/cabinBerth/deleteCabinBerth";

const initialState = {
  cabinBerths: [],
  cabinBerth: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const cabinBerthReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_CABIN_BERTH: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_CABIN_BERTHS: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CABIN_BERTHS_SUCCESS: {
      return {
        ...state,
        cabinBerths: action.cabinBerths,
        cabinBerth: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CABIN_BERTHS_ERROR: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_CABIN_BERTH: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CABIN_BERTH_SUCCESS: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: action.cabinBerth,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CABIN_BERTH_ERROR: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_CABIN_BERTH: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_CABIN_BERTH_SUCCESS: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_CABIN_BERTH_ERROR: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_CABIN_BERTH: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_CABIN_BERTH_SUCCESS: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_CABIN_BERTH_ERROR: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_CABIN_BERTH: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_CABIN_BERTH_SUCCESS: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_CABIN_BERTH_ERROR: {
      return {
        ...state,
        cabinBerths: [],
        cabinBerth: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default cabinBerthReducer;
