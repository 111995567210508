import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//UI
import { Table, Breadcrumb, Divider, Loader, Button, Modal, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import LogsHistory from "../../../../components/reservation/logsHistory";
import { Accordion, ListItem, Card, Feed} from "semantic-ui-react";
import { Collapse, ListItemText } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

//Actions
import { fetchBookingLogs} from "../../../../redux/actions/reservations/booking/fetchBookingsLogs";
import { initialStateBooking } from "../../../../redux/actions/reservations/booking/fetchBooking";
import { deleteBooking } from "../../../../redux/actions/reservations/booking/deleteBooking";
import { fetchBookingListCustom, initialStateBookingListCustom } from "../../../../redux/slices/custom/reservations/cruise/booking/customBookingList.slice";

//Components
import HeaderCatalog from "../../../../components/headerCatalog";
import HeadDetail from "../../../../components/headDetail";
import HeadDetailReservation from "../../../../components/headDetailReservation";

// Resources
import { scrollToTop } from "../../../resources/utils";

export default function BookingList() {
  let { cruise_id } = useParams();
  let { reservation_id } = useParams();
  const { t } = useTranslation();
  const bookingStateCustom = useSelector((state) => state.customBookingList);
  const bookingState = useSelector((state) => state.bookingReducer);
  const bookingLogsState = useSelector((state) => state.bookingLogReducer);
  const dispatch = useDispatch();
  var total = 0;

  const [openHistory, setOpenHistory] = useState(false);

  useEffect(() => {
    dispatch(fetchBookingListCustom(reservation_id, cruise_id));
    scrollToTop();
    return () => {
      dispatch(initialStateBookingListCustom());
    };
  }, []);

  if (bookingStateCustom.fetching) {
    return <Loader active inline />;
  }

  if (bookingStateCustom.processing) {
    return <Loader active inline />;
  }

  if (bookingStateCustom.error) {
    return <div>Error: {bookingStateCustom.error}</div>;
  }

  if (bookingState.message === "resource deleted successfully") {
    dispatch(fetchBookingListCustom(reservation_id));
    dispatch(fetchBookingLogs(reservation_id));
    dispatch(initialStateBooking());
  }

  const handledeleteBooking = (id) => {
    dispatch(deleteBooking(id));
  };

  const handleHistoyClick = () => {
    setOpenHistory(!openHistory);
    if (!openHistory ) {
      dispatch(fetchBookingLogs(reservation_id));
    } 
  };

  bookingStateCustom.bookings.map((booking) => (
    total = total + booking.price    
  ));

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/cruise-detail`} className="section">{bookingStateCustom.cruise.code}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/cruises/${cruise_id}/reservations`} className="section"> {t("reservation_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active> {t("booking_title")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />
      <HeadDetail yacht_name={bookingStateCustom.cruise.yacht_name} itinerary_name={bookingStateCustom.cruise.itinerary_name} start_date={bookingStateCustom.cruise.start_date_format} end_date={bookingStateCustom.cruise.end_date_format} />
      <HeadDetailReservation reservation_date={bookingStateCustom.reservation.creation_date} reservation_agency={bookingStateCustom.reservation.agency_name} reservation_executive={bookingStateCustom.reservation.user} reservation_type={bookingStateCustom.reservation.cruise_type} />
      <br></br>

      <HeaderCatalog title={t("booking_title")} message={bookingStateCustom.message} to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings_new`} /> 

        <Table color="blue" striped>
          <Table.Header>
            <Table.Row align="center">
              <Table.HeaderCell>{t("booking_code")}<br/><br/></Table.HeaderCell>
              <Table.HeaderCell>{t("booking_executive")}<br/><br/></Table.HeaderCell>
              <Table.HeaderCell>{t("booking_creation_date")}<br/><span className="text_date_format">(dd-mm-yyyy)</span></Table.HeaderCell>
              <Table.HeaderCell>{t("booking_cabin")}<br/><br/></Table.HeaderCell>
              <Table.HeaderCell>{t("booking_single")}<br/><br/></Table.HeaderCell>
              <Table.HeaderCell>{t("booking_double")}<br/><br/></Table.HeaderCell>
              <Table.HeaderCell>{t("booking_family")}<br/><br/></Table.HeaderCell>
              <Table.HeaderCell>{t("booking_price")}<br/><br/></Table.HeaderCell>
              <Table.HeaderCell>{t("booking_space")}<br/><br/></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {bookingStateCustom.bookings.map((booking) => (
              <Table.Row align="center">
                <Table.Cell>{booking.id}</Table.Cell>
                <Table.Cell>{booking.user}</Table.Cell>
                <Table.Cell>{booking.creation_date}</Table.Cell>
                <Table.Cell>{booking.cabin_name}</Table.Cell>
                <Table.Cell>
                  {booking.single === 1 ? (
                    <div className="active_tag">
                      <div className="bed_active">
                        {t("booking_bed_active")}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {booking.matrimonial === 1 ? (
                    <div className="active_tag">
                      <div className="bed_active">
                        {t("booking_bed_active")}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Table.Cell>
                <Table.Cell>
                  {booking.family_room === 1 ? (
                    <div className="active_tag">
                      <div className="bed_active">
                        {t("booking_bed_active")}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Table.Cell>
                <Table.Cell><b>$ {booking.price}</b></Table.Cell>
                <Table.Cell>
                  {" "}
                  {booking.spaces !== null ? (
                    <Link to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking.id}/spaces`}>
                      {booking.spaces}
                    </Link>
                  ) : (
                    <Link to={`/cruises/${cruise_id}/reservations/${reservation_id}/bookings/${booking.id}/spaces`}>
                      <Button className="add_button" icon circular size="mini">
                        <Icon className="icon_color" name="add" />
                      </Button>
                    </Link>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/cruises/${cruise_id}/reservations/${booking.reservation_id}/booking/${booking.id}`}>
                    <Button className="edit_button" icon>
                      <Icon className="icon_color" name="pencil alternate" />
                    </Button>
                  </Link>
                  <Modal
                    trigger={
                      <Button className="ui button delete_button" icon>
                        <Icon name="trash alternate" className="icon_color" />
                      </Button>
                    }
                    content={t("booking_delete")}
                    actions={[
                      t("canceled_button"),
                      {
                        key: "eliminar",
                        content: t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handledeleteBooking(booking.id),
                      },
                    ]}
                    size="tiny"
                  />
                  {/* <Link
                    to={`/cruises/${cruise_id}/reservations/${booking.reservation_id}/booking/${booking.id}/cabin/${booking.cabin_id}`}
                  >
                    <Button color="teal" icon>
                      <Icon className="icon_color" name="exchange" />
                    </Button>
                  </Link> */}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row align="center">
              <Table.HeaderCell><b>TOTAL:</b></Table.HeaderCell>  
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell><b>$ {total}</b></Table.HeaderCell>  
              <Table.HeaderCell />
              <Table.HeaderCell />
            </Table.Row>
          </Table.Footer>
        </Table>
      <Accordion styled style={{ width: "91vw" }}>
        <Accordion.Title onClick={handleHistoyClick} style={{ display: "flex", gap: "5px" }}>
          {t("log_historical")}
          {openHistory ? <ExpandLess /> : <ExpandMore />}
        </Accordion.Title>
        {bookingLogsState.fetching  ? (
          <div style={{ display: "flex", width: "91vw", height: "80px", justifyContent: "center" }}>
            <Loader size="medium" active inline>Loading...</Loader>
          </div>
        ) : (
          <Collapse in={openHistory} timeout="auto" unmountOnExit style={{ padding: "15px" }}>
            <LogsHistory
              logsHistory={bookingLogsState.logs}
            />
          </Collapse>
        )}
      </Accordion>
    </>
  );
}
