import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CruiseService from "../../../../services/reservations/CruiseService";

export const REQUEST_FETCH_CRUISE = "REQUEST_FETCH_CRUISE";
export const FETCH_CRUISE_SUCCESS = "FETCH_CRUISE_SUCCESS";
export const FETCH_CRUISE_ERROR = "FETCH_CRUISE_ERROR";
export const INITIAL_STATE_CRUISE = "INITIAL_STATE_CRUISE";

function requestFetchCrusie() {
  return {
    type: REQUEST_FETCH_CRUISE,
  };
}

function fetchCruiseSuccess(json) {
  return {
    type: FETCH_CRUISE_SUCCESS,
    cruise: json.data,
  };
}

function fetchCruiseError(json) {
  return {
    type: FETCH_CRUISE_ERROR,
    error: json.data,
  };
}

export function initialStateCruise() {
  return {
    type: INITIAL_STATE_CRUISE,
  };
}

export function fetchCruise(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchCrusie());
    return CruiseService.getCruise(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCruiseSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCruise(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCruiseError(json.data));
      }
    });
  };
}
