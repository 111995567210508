import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ContractRateService from "../../../../services/rates/ContractRateService";

export const REQUEST_FETCH_CONTRACT_RATE = "REQUEST_FETCH_CONTRACT_RATE";
export const FETCH_CONTRACT_RATE_SUCCESS = "FETCH_CONTRACT_RATE_SUCCESS";
export const FETCH_CONTRACT_RATE_ERROR = "FETCH_CONTRACT_RATE_ERROR";
export const INITIAL_STATE_CONTRACT_RATE = "INITIAL_STATE_CONTRACT_RATE";

function requestFetchContractRate() {
  return {
    type: REQUEST_FETCH_CONTRACT_RATE,
  };
}

function fetchContractRateSuccess(json) {
  return {
    type: FETCH_CONTRACT_RATE_SUCCESS,
    contract: json.data,
  };
}

function fetchContractRateError(json) {
  return {
    type: FETCH_CONTRACT_RATE_ERROR,
    error: json.data,
  };
}

export function initialStateContractRate() {
  return {
    type: INITIAL_STATE_CONTRACT_RATE,
  };
}

export function fetchContractRate(contract_id) {
  return (dispatch) => {
    dispatch(requestFetchContractRate());
    return ContractRateService.getContract(contract_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchContractRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchContractRate(contract_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchContractRateError(json.data));
      }
    });
  };
}
