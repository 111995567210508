import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SeasonService from "../../../../services/rates/SeasonService";

export const REQUEST_UPDATE_SEASON = "REQUEST_UPDATE_SEASON";
export const UPDATE_SEASON_SUCCESS = "UPDATE_SEASON_SUCCESS";
export const UPDATE_SEASON_ERROR = "UPDATE_SEASON_ERROR";

function requestUpdateSeason() {
  return {
    type: REQUEST_UPDATE_SEASON,
  };
}

function updateSeasonSuccess(json) {
  return {
    type: UPDATE_SEASON_SUCCESS,
    message: json.data,
  };
}

function updateSeasonError(json) {
  return {
    type: UPDATE_SEASON_ERROR,
    error: json.data,
  };
}

export function updateSeason(season_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateSeason());
    return SeasonService.putSeason(season_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateSeasonSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateSeason(season_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateSeasonError(json.data));
      }
    });
  };
}
