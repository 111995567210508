import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { fetchYacht, initialStateYacht } from "../../../redux/actions/catalogs/yacht/fetchYacht";
import { initialStateIkarusRate } from "../../../redux/actions/rates/ikarusRates/fetchIkarusRate";
import { createIkarusRate } from "../../../redux/actions/rates/ikarusRates/createIkarusRate";
import { updateIkarusRate } from "../../../redux/actions/rates/ikarusRates/updateIkarusRate";
import { fetchIkarusRateFormCustom, initialStateIkarusRateFormCustom } from "../../../redux/slices/custom/rates/ikarusRate/customIkarusRateForm.slice";

// Resources
import { scrollToTop } from "../../resources/utils";
import { years } from "../../resources/optionsList";

export default function ContractForm() {
  const sixDotTwoRegex = new RegExp(/^\d{0,6}(\.\d{0,2})?$/);
  const { t } = useTranslation();
  let history = useHistory();
  let { ikarusRate_id } = useParams();
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  let passengerCapacity = [];

  const ikarusStateCustom = useSelector((state) => state.customIkarusRateForm);
  const yachtState = useSelector((state) => state.yachtReducer);
  const ikarusState = useSelector((state) => state.ikarusRateReducer);

  let yacht_id = ikarusStateCustom.ikarusRate.yacht_id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIkarusRateFormCustom("",ikarusRate_id));
    return () => {
      dispatch(initialStateIkarusRateFormCustom());
      dispatch(initialStateIkarusRate());
      dispatch(initialStateYacht());
    };
  }, []);

  useEffect(() => {
    if (yacht_id) {
      dispatch(fetchYacht(yacht_id));
    }
  }, [yacht_id]);

  // General functions
  function calculateDiscount(value, percent) {
    return (value * (1 - (percent * 0.01))).toFixed(2);
  }

  function calculatePercentDiscount(childRate, doubleRate) {
    return ((1 - (childRate / doubleRate)) / 0.01).toFixed(2);
  }

  // Range1 functions
  function calculateRange1DoubleOperatorRate() {
    setValue("range1_double_operator", calculateDiscount(getValues("range1_double_client"), getValues("range1_operator_percent")));
  }

  function calculateRange1SingleOperatorRate() {
    setValue("range1_single_operator", calculateDiscount(getValues("range1_single_client"), getValues("range1_operator_percent")));
  }

  function calculateRange1OperatorRate() {
    calculateRange1DoubleOperatorRate();
    calculateRange1SingleOperatorRate();
  }

  function calculateRange1OperatorPercent() {
    setValue("range1_operator_percent", calculatePercentDiscount(getValues("range1_double_operator"), getValues("range1_double_client")));
    calculateRange1DoubleOperatorRate();
    calculateRange1SingleOperatorRate();
  }

  // Range2 functions
  function calculateRange2DoubleOperatorRate() {
    setValue("range2_double_operator", calculateDiscount(getValues("range2_double_client"), getValues("range2_operator_percent")));
  }

  function calculateRange2SingleOperatorRate() {
    setValue("range2_single_operator", calculateDiscount(getValues("range2_single_client"), getValues("range2_operator_percent")));
  }

  function calculateRange2OperatorRate() {
    calculateRange2DoubleOperatorRate();
    calculateRange2SingleOperatorRate();
  }

  function calculateRange2OperatorPercent() {
    setValue("range2_operator_percent", calculatePercentDiscount(getValues("range2_double_operator"), getValues("range2_double_client")));
    calculateRange2DoubleOperatorRate();
    calculateRange2SingleOperatorRate();
  }

  // Range3 functions
  function calculateRange3DoubleOperatorRate() {
    setValue("range3_double_operator", calculateDiscount(getValues("range3_double_client"), getValues("range3_operator_percent")));
  }

  function calculateRange3SingleOperatorRate() {
    setValue("range3_single_operator", calculateDiscount(getValues("range3_single_client"), getValues("range3_operator_percent")));
  }

  function calculateRange3OperatorRate() {
    calculateRange3DoubleOperatorRate();
    calculateRange3SingleOperatorRate();
  }

  function calculateRange3OperatorPercent() {
    setValue("range3_operator_percent", calculatePercentDiscount(getValues("range3_double_operator"), getValues("range3_double_client")));
    calculateRange3DoubleOperatorRate();
    calculateRange3SingleOperatorRate();
  }

  // Charter functions
   function calculateCharterOperatorRate() {
    setValue("charter_operator", calculateDiscount(getValues("charter_client"), getValues("charter_operator_percent")));
  }

  function calculateCharterOperatorPercent() {
    setValue("charter_operator_percent", calculatePercentDiscount(getValues("charter_operator"), getValues("charter_client")));
  }

  if (ikarusStateCustom.fetching || ikarusState.fetching) {
    return <Loader active inline />;
  }

  if (ikarusStateCustom.error) {
    return <div>Error: {ikarusStateCustom.error}</div>;
  }

  for (var i = 1; i <= yachtState.yacht.availability; i++) {
    passengerCapacity.push(i);
  }

  function handleYacht(yacht_id) {
    dispatch(fetchYacht(yacht_id));
  }

  const onSubmit = (data) => {
    if (ikarusRate_id) {
      dispatch(updateIkarusRate(ikarusRate_id, data));
    } else {
      dispatch(createIkarusRate(data));
    }
  };

  if (ikarusState.message === "resource created successfully" || ikarusState.message === "resource updated successfully") {
    let ikarusRateFilters = {
      year: getValues("year"),
      yacht: getValues("yacht_id"),
      agency: getValues("agency_id"),
      days: getValues("days"),
    };
    localStorage.setItem("ikarusRateFilters", JSON.stringify(ikarusRateFilters));
    history.push("/ikarusRates");
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/ikarusRates" className="section">{t("ikarusRates_tag")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("ikarusRate_tag")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <Form onSubmit={handleSubmit(onSubmit)}>
          
          <Header as='h4' dividing color='grey'>{t("general_information_tag")}</Header>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t("contract_year")}</label>
              <select name="year" aria-expanded="true" defaultValue={ikarusStateCustom.ikarusRate.year} ref={register({ required: true })}>
                <option />
                {years.map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </select>
              {errors.year && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_yacht")}</label>
              <select name="yacht_id"  aria-expanded="true" defaultValue={ikarusStateCustom.ikarusRate.yacht_id} ref={register({ required: true })}
                onChange={(e) => {
                  handleYacht(e.target.value);
                }}
              >
                <option />
                {ikarusStateCustom.yachts.map((yacht, index) => (
                  <option key={index} value={yacht.id}>{yacht.name}</option>
                ))}
              </select>
              {errors.yacht_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_agency")}</label>
              <select name="agency_id" aria-expanded="true" defaultValue={ikarusStateCustom.ikarusRate.agency_id} ref={register({ required: true })}>
                <option />
                {ikarusStateCustom.agencies.map((agency, index) => (
                  <option key={index} value={agency.id}>{agency.name}</option>
                ))}
              </select>
              {errors.agency_id && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_days")}</label>
              <select name="days" aria-expanded="true" defaultValue={ikarusStateCustom.ikarusRate.days} ref={register({ required: true })}>
                <option />
                {ikarusStateCustom.days.map((day, index) => (
                  <option key={index} value={day.number_days}>{t(day.number_days)}</option>
                ))}
              </select>
              {errors.days && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
          </Form.Group>

          <Header as='h4' dividing color='blue'>{t("range1")}</Header>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t("from")}</label>
              {yachtState.fetching ? (
                <Loader active inline />
              ) : (
                <select name="range1_number_passenger_from" defaultValue={ikarusStateCustom.ikarusRate.range1_number_passenger_from} aria-expanded="true" ref={register({ required: true })}>
                  {passengerCapacity.map((number, index) => (
                    <option key={index} value={number}>{number}</option>
                  ))}
                </select>
              )}
              {errors.charter_operator && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("to")}</label>
              {yachtState.fetching ? (
                <Loader active inline />
              ) : (
                <select name="range1_number_passenger_to" defaultValue={ikarusStateCustom.ikarusRate.range1_number_passenger_to} aria-expanded="true" ref={register({ required: true })}>
                  {passengerCapacity.map((number, index) => (
                    <option key={index} value={number}>{number}</option>
                  ))}
                </select>
              )}
              {errors.double_operator && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("tour_leader_percent_reduction")}</label>
                <select name="range1_tour_leader_percent_reduction" defaultValue={ikarusStateCustom.ikarusRate.range1_tour_leader_percent_reduction} aria-expanded="true" ref={register({ required: true })}>
                    <option key='0' value='0'>0</option>
                    <option key='1' value='25'>25</option>
                    <option key='2' value='50'>50</option>
                    <option key='3' value='75'>75</option>
                    <option key='4' value='100'>100</option>
                </select>
              {errors.charter_operator && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

          </Form.Group>

          <Header as='h4' dividing color='grey'>{t("general_clientRate")}</Header>

          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t("contract_double")}</label>
              <input name="range1_double_client" defaultValue={ikarusStateCustom.ikarusRate.range1_double_client} onChange={calculateRange1DoubleOperatorRate} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range1_double_client && errors.range1_double_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range1_double_client && errors.range1_double_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_single")}</label>
              <input name="range1_single_client" defaultValue={ikarusStateCustom.ikarusRate.range1_single_client} onChange={calculateRange1SingleOperatorRate} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range1_single_client && errors.range1_single_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range1_single_client && errors.range1_single_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          <Header as='h4' dividing color='grey'>{t("general_operatorRate")}</Header>
          <Form.Group widths="equal">
            <Form.Field width={8}>
              <label>%</label>
              <input name="range1_operator_percent" defaultValue={ikarusStateCustom.ikarusRate.percent_range1_operator_discount == null ?  "6.00" : ikarusStateCustom.ikarusRate.percent_range1_operator_discount} onChange={calculateRange1OperatorRate} ref={register()} />
              <div className="percent_label_rate">{t("contract_operatorDiscount")}</div>
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_double")}</label>
              <input name="range1_double_operator" defaultValue={ikarusStateCustom.ikarusRate.range1_double_operator} onChange={calculateRange1OperatorPercent} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range1_double_operator && errors.range1_double_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range1_double_operator && errors.range1_double_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_single")}</label>
              <input name="range1_single_operator" defaultValue={ikarusStateCustom.ikarusRate.range1_single_operator} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range1_single_operator && errors.range1_single_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range1_single_operator && errors.range1_single_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>     
          </Form.Group>

          <Header as='h4' dividing color='blue'>{t("range2")}</Header>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t("from")}</label>
              {yachtState.fetching ? (
                <Loader active inline />
              ) : (
                <select name="range2_number_passenger_from" defaultValue={ikarusStateCustom.ikarusRate.range2_number_passenger_from} aria-expanded="true" ref={register({ required: true })}>
                  {passengerCapacity.map((number, index) => (
                    <option key={index} value={number}>{number}</option>
                  ))}
                </select>
              )}
              {errors.charter_operator && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("to")}</label>
              {yachtState.fetching ? (
                <Loader active inline />
              ) : (
                <select name="range2_number_passenger_to" defaultValue={ikarusStateCustom.ikarusRate.range2_number_passenger_to} aria-expanded="true" ref={register({ required: true })}>
                  {passengerCapacity.map((number, index) => (
                    <option key={index} value={number}>{number}</option>
                  ))}
                </select>
              )}
              {errors.double_operator && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("tour_leader_percent_reduction")}</label>
                <select name="range2_tour_leader_percent_reduction" defaultValue={ikarusStateCustom.ikarusRate.range2_tour_leader_percent_reduction} aria-expanded="true" ref={register({ required: true })}>
                    <option key='0' value='0'>0</option>
                    <option key='1' value='25'>25</option>
                    <option key='2' value='50'>50</option>
                    <option key='3' value='75'>75</option>
                    <option key='4' value='100'>100</option>
                </select>
              {errors.charter_operator && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
    
          </Form.Group>

          <Header as='h4' dividing color='grey'>{t("general_clientRate")}</Header>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t("contract_double")}</label>
              <input name="range2_double_client" defaultValue={ikarusStateCustom.ikarusRate.range2_double_client} onChange={calculateRange2DoubleOperatorRate} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range2_double_client && errors.range2_double_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range2_double_client && errors.range2_double_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_single")}</label>
              <input name="range2_single_client" defaultValue={ikarusStateCustom.ikarusRate.range2_single_client} onChange={calculateRange2SingleOperatorRate} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range2_single_client && errors.range2_single_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range2_single_client && errors.range2_single_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          <Header as='h4' dividing color='grey'>{t("general_operatorRate")}</Header>
          <Form.Group widths="equal">
            <Form.Field width={8}>
              <label>%</label>
              <input name="range2_operator_percent" defaultValue={ikarusStateCustom.ikarusRate.percent_range2_operator_discount == null ?  "6.00" : ikarusStateCustom.ikarusRate.percent_range2_operator_discount} onChange={calculateRange2OperatorRate} ref={register()} />
              <div className="percent_label_rate">{t("contract_operatorDiscount")}</div>
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_double")}</label>
              <input name="range2_double_operator" defaultValue={ikarusStateCustom.ikarusRate.range2_double_operator} onChange={calculateRange2OperatorPercent} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range2_double_operator && errors.range2_double_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range2_double_operator && errors.range2_double_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_single")}</label>
              <input name="range2_single_operator" defaultValue={ikarusStateCustom.ikarusRate.range2_single_operator} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range2_single_operator && errors.range2_single_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range2_single_operator && errors.range2_single_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>     
          </Form.Group>

          <Header as='h4' dividing color='blue'>{t("range3")}</Header>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t("from")}</label>
              {yachtState.fetching ? (
                <Loader active inline />
              ) : (
                <select name="range3_number_passenger_from" defaultValue={ikarusStateCustom.ikarusRate.range3_number_passenger_from} aria-expanded="true" ref={register({ required: true })}>
                  {passengerCapacity.map((number, index) => (
                    <option key={index} value={number}>{number}</option>
                  ))}
                </select>
              )}
              {errors.charter_operator && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("to")}</label>
              {yachtState.fetching ? (
                <Loader active inline />
              ) : (
                <select name="range3_number_passenger_to" defaultValue={ikarusStateCustom.ikarusRate.range3_number_passenger_to} aria-expanded="true" ref={register({ required: true })}>
                  {passengerCapacity.map((number, index) => (
                    <option key={index} value={number}>{number}</option>
                  ))}
                </select>
              )}
              {errors.double_operator && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>

            <Form.Field required>
              <label>{t("tour_leader_percent_reduction")}</label>
                <select name="range3_tour_leader_percent_reduction" defaultValue={ikarusStateCustom.ikarusRate.range3_tour_leader_percent_reduction} aria-expanded="true" ref={register({ required: true })}>
                    <option key='0' value='0'>0</option>
                    <option key='1' value='25'>25</option>
                    <option key='2' value='50'>50</option>
                    <option key='3' value='75'>75</option>
                    <option key='4' value='100'>100</option>
                </select>
              {errors.charter_operator && (<span className="error_message">{t("required_information")}</span>)}
            </Form.Field>
    
          </Form.Group>

          <Header as='h4' dividing color='grey'>{t("general_clientRate")}</Header>
          <Form.Group widths="equal">
       
            <Form.Field required>
              <label>{t("contract_double")}</label>
              <input name="range3_double_client" defaultValue={ikarusStateCustom.ikarusRate.range3_double_client} onChange={calculateRange3DoubleOperatorRate} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range3_double_client && errors.range3_double_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range3_double_client && errors.range3_double_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_single")}</label>
              <input name="range3_single_client" defaultValue={ikarusStateCustom.ikarusRate.range3_single_client} onChange={calculateRange3SingleOperatorRate} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range3_single_client && errors.range3_single_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range3_single_client && errors.range3_single_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          <Header as='h4' dividing color='grey'>{t("general_operatorRate")}</Header>
          <Form.Group widths="equal">
            <Form.Field width={8}>
              <label>%</label>
              <input name="range3_operator_percent" defaultValue={ikarusStateCustom.ikarusRate.percent_range3_operator_discount == null ?  "6.00" : ikarusStateCustom.ikarusRate.percent_range3_operator_discount} onChange={calculateRange3OperatorRate} ref={register()} />
              <div className="percent_label_rate">{t("contract_operatorDiscount")}</div>
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_double")}</label>
              <input name="range3_double_operator" defaultValue={ikarusStateCustom.ikarusRate.range3_double_operator} onChange={calculateRange3OperatorPercent} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range3_double_operator && errors.range3_double_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range3_double_operator && errors.range3_double_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_single")}</label>
              <input name="range3_single_operator" defaultValue={ikarusStateCustom.ikarusRate.range3_single_operator} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.range3_single_operator && errors.range3_single_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.range3_single_operator && errors.range3_single_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>     
          </Form.Group>

          <Header as='h4' dividing color='blue'>{t("charter")}</Header>

          <Header as='h4' dividing color='grey'>{t("general_clientRate")}</Header>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>{t("contract_charter")}</label>
              <input type="text" name="charter_client" defaultValue={ikarusStateCustom.ikarusRate.charter_client} onChange={calculateCharterOperatorRate} ref={register({ required: true, pattern: sixDotTwoRegex })}/>
              {errors.charter_client && errors.charter_client.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.charter_client && errors.charter_client.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          <Header as='h4' dividing color='grey'>{t("general_operatorRate")}</Header>
          <Form.Group widths="equal">
            <Form.Field width={8}>
              <label>%</label>
              <input name="charter_operator_percent" defaultValue={ikarusStateCustom.ikarusRate.percent_charter_operator_discount == null ?  "6.00" : ikarusStateCustom.ikarusRate.percent_charter_operator_discount} onChange={calculateCharterOperatorRate} ref={register()} />
              <div className="percent_label_rate">{t("contract_operatorDiscount")}</div>
            </Form.Field>

            <Form.Field required>
              <label>{t("contract_charter")}</label>
              <input type="text" name="charter_operator" defaultValue={ikarusStateCustom.ikarusRate.charter_operator} onChange={calculateCharterOperatorPercent} ref={register({ pattern: sixDotTwoRegex, required: true })}/>
              {errors.charter_operator && errors.charter_operator.type === "required" && <span className="error_message">{t("required_information")}</span>}
              {errors.charter_operator && errors.charter_operator.type === "pattern" && <span className="error_message">{t("valid_number")}</span>}
            </Form.Field>
          </Form.Group>

          <Divider />
          <Form.Group>
            <Form.Field width={4}>
              <label>{t("ticket_airline")}</label>
              <select name="airplane_ticket" defaultValue={ikarusStateCustom.ikarusRate.airplane_ticket} aria-expanded="true" ref={register()}>
                <option value="1">{t("yes")}</option>
                <option value="0">{t("not")}</option>
              </select>
            </Form.Field>
            <Form.Field width={12}>
              <label>{t("comment")}</label>
              <textarea rows="3" name="comment" defaultValue={ikarusStateCustom.ikarusRate.comment} ref={register}/>
            </Form.Field>
          </Form.Group>
          
          <Divider variant="middle" />

          <Button primary type="submit" loading={ikarusStateCustom.processing ? true : false}>{t("save_button")}</Button>
          <Link to={"/ikarusRates"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
