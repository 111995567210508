import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FlightService from "../../../../services/catalogs/FlightService";

export const REQUEST_UPDATE_FLIGHT = "REQUEST_UPDATE_FLIGHT";
export const UPDATE_FLIGHT_SUCCESS = "UPDATE_FLIGHT_SUCCESS";
export const UPDATE_FLIGHT_ERROR = "UPDATE_FLIGHT_ERROR";

function requestUpdateFlight() {
  return {
    type: REQUEST_UPDATE_FLIGHT,
  };
}

function updateFlightSuccess(json) {
  return {
    type: UPDATE_FLIGHT_SUCCESS,
    message: json.data,
  };
}

function updateFlightError(json) {
  return {
    type: UPDATE_FLIGHT_ERROR,
    error: json.data,
  };
}

export function updateFlight(flight_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateFlight());
    return FlightService.putFlight(flight_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateFlightSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateFlight(flight_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateFlightError(json.data));
      }
    });
  };
}
