import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class GeneralRateService {
  static getGeneralRates(payload) {
    return axios.post("generalRates/getByBody", payload);
  }
  
  static getGeneralRate(generalRate_id) {
    return axios.get("generalRates/" + generalRate_id);
  }

  static getYears() {
    return axios.get("generalRates/year");
  }

  static postGeneralRate(payload) {
    return axios.post("generalRates", payload);
  }

  static putGeneralRate(generalRate_id, payload) {
    return axios.put("generalRates/" + generalRate_id, payload);
  }

  static activeGeneralRate(generalRate_id) {
    return axios.put("generalRates/" + generalRate_id + "/activeGeneralRate");
  }

  static inactiveGeneralRate(generalRate_id, payload) {
    return axios.put("generalRates/" + generalRate_id + "/inactiveGeneralRate", payload);
  }

  static deleteGeneralRate(generalRate_id) {
    return axios.delete("generalRates/" + generalRate_id);
  }
}
