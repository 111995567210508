import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

export default function HeaderCatalog(props) {
    const { t } = useTranslation();
    return (
      <>
        <span style={{fontWeight: "bold", fontSize: "18px"}}>{t(props.title)} {props.message}</span>
        <Link to={props.to}>
          <Button circular color='blue' icon='add' />
        </Link>
      </>
    );
  }
  