import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class CompanyService {
  static getCompanies() {
    return axios.get("companies");
  }
  static getCompany(company_id) {
    return axios.get("companies/" + company_id);
  }
  static getCompanyByCruise(cruise_id) {
    return axios.get("companies/companybycruise/" + cruise_id);
  }
  static postCompany(payload) {
    return axios.post("companies", payload);
  }

  static putCompany(company_id, payload) {
    return axios.put("companies/" + company_id, payload);
  }

  static deleteCompany(company_id) {
    return axios.delete("companies/" + company_id);
  }
}
