import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customTipTopForm = createSlice({
  name: 'customTipTopForm',
  initialState: {
    cruise: {},
    yachts: [],
    captains: [],
    hoteliers: [],
    rates: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateTipTopFormCustom(state, action) {
      return {
        cruise: {},
        yachts: [],
        captains: [],
        hoteliers: [],
        rates: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesTipTopFormCustom(state, action) {
      return {
        cruise: {},
        yachts: [],
        captains: [],
        hoteliers: [],
        rates: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchTipTopFormCustomSuccess(state, action) {
      return {
        cruise: action.payload.cruise,
        yachts: action.payload.yachts,
        captains: action.payload.captains,
        hoteliers: action.payload.hoteliers,
        rates: action.payload.rates,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchTipTopFormCustomError(state, action) {
      return {
        cruise: {},
        yachts: [],
        captains: [],
        hoteliers: [],
        rates: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchTipTopFormCustom = (searchWord, cruise_id) => dispatch => {
  dispatch(requesTipTopFormCustom());
  var url = "cruise/tipTopForm";
  url = url + `?searchWord=${searchWord}&&cruise_id=${cruise_id}`;
  axios.get(`/${url}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchTipTopFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchTipTopFormCustom(searchWord, cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchTipTopFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateTipTopFormCustom,
  requesTipTopFormCustom,
  fetchTipTopFormCustomSuccess,
  fetchTipTopFormCustomError,
} = customTipTopForm.actions;

export default customTipTopForm.reducer;