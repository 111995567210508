import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import KindService from "../../../../services/catalogs/KindService";

export const REQUEST_CREATE_KIND = "REQUEST_CREATE_KIND";
export const CREATE_KIND_SUCCESS = "CREATE_KIND_SUCCESS";
export const CREATE_KIND_ERROR = "CREATE_KIND_ERROR";

function requestCreateKind() {
  return {
    type: REQUEST_CREATE_KIND,
  };
}

function createKindSuccess(json) {
  return {
    type: CREATE_KIND_SUCCESS,
    message: json.data,
  };
}

function createKindError(json) {
  return {
    type: CREATE_KIND_ERROR,
    error: json.data,
  };
}

export function createKind(payload) {
  return (dispatch) => {
    dispatch(requestCreateKind());
    return KindService.postKind(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createKindSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createKind(payload));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(createKindError(json.data));
      }
    });
  };
}
