import axios from "axios";

const scrollToTop = () => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  };

  const updateLocalStorageToken = (token) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    userInfo.token = token
    const updatedUserInfo = JSON.stringify(userInfo);
    localStorage.setItem('userInfo', updatedUserInfo);
    axios.defaults.headers.common['Authorization'] = `Bearer ${userInfo.token}`;
  }

  const logOut = () => {
    localStorage.clear();
    window.location.href = '/login';
  }

  export {
    scrollToTop,
    updateLocalStorageToken,
    logOut
  };