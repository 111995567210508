import { logOut, updateLocalStorageToken } from '../../../../containers/resources/utils';
import SpaceService from '../../../../services/reservations/SpaceService';

export const REQUEST_FETCH_SPACE_LOGS = "REQUEST_FETCH_SPACE_LOGS";
export const FETCH_SPACE_LOGS_SUCCESS = "FETCH_SPACE_LOGS_SUCCESS";
export const FETCH_SPACE_LOGS_ERROR = "FETCH_SPACE_LOGS_ERROR";

function requestFetchSpaceLogs() {
  return {
    type: REQUEST_FETCH_SPACE_LOGS,
  };
}

function fetchSpaceLogsSuccess(json) {
  return {
    type: FETCH_SPACE_LOGS_SUCCESS,
    logs: json.data,
  };
}

function fetchSpaceLogsError(json) {
  return {
    type: FETCH_SPACE_LOGS_ERROR,
    error: json.data,
  };
}

export function fetchSpaceLogs(booking_id) {
  return (dispatch) => {
    dispatch(requestFetchSpaceLogs());
    return SpaceService.getItemsByField(booking_id).then((json) => {
      if (json.status === 200) {
        dispatch(fetchSpaceLogsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSpaceLogs(booking_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSpaceLogsError(json.data));
      }
    });
  };
}
