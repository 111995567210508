import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Icon, Form, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";
import GenericRateCell from "../../../components/rates/genericRateCell"
import StateRateCell from "../../../components/rates/stateRateCell"
import ChangeStateRateModal from "../../../components/rates/changeStateRateModal"

//Actions
import { initialStateCharterRate } from "../../../redux/actions/rates/charterRates/fetchCharterRate";
import { deleteCharterRate } from "../../../redux/actions/rates/charterRates/deleteCharterRate";
import { fetchCharterRateListCustom, initialStateCharterRateListCustom } from "../../../redux/slices/custom/rates/charterRate/customCharterRateList.slice"
// Resources
import { scrollToTop } from "../../resources/utils";

export default function CharterRateList() {
  const { t } = useTranslation();
  let actualDate = new Date();
  let currentYear = actualDate.getFullYear();
  let rateType = "";
  let yacht = "";
  let days = "all";

  const charterRateFilters = JSON.parse(localStorage.getItem("charterRateFilters"));
  if (charterRateFilters) {
    currentYear = charterRateFilters.year;
    rateType = charterRateFilters.rate;
    yacht = charterRateFilters.yacht;
    days = charterRateFilters.days;
  }

  const { register, handleSubmit, errors, getValues } = useForm();

  const charterRateStateCustom = useSelector((state) => state.customCharterRateList);
  const charterRateState = useSelector((state) => state.charterRateReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCharterRateListCustom({year: currentYear, rate_id: rateType, yacht_id: yacht, days: days}));
    scrollToTop();
    return () => {
      dispatch(initialStateCharterRateListCustom())
      dispatch(initialStateCharterRate());
    };
  }, []);

  if (charterRateStateCustom.fetching || charterRateState.fetching) {
    return <Loader active inline />;
  }

  if (charterRateStateCustom.error) {
    return <div>Error: {charterRateStateCustom.error}</div>;
  }

  if (charterRateState.message === "resource deleted successfully") {
    dispatch(fetchCharterRateListCustom({year: getValues("year"), rate_id: getValues("rate_id"), yacht_id: getValues("yacht_id"), days: getValues("days")}));
    dispatch(initialStateCharterRate());
  }

  if (charterRateState.message === "resource updated successfully") {
    dispatch(fetchCharterRateListCustom({year: getValues("year"), rate_id: getValues("rate_id"), yacht_id: getValues("yacht_id"), days: getValues("days")}));
    dispatch(initialStateCharterRate());
  }

  const onSubmit = (data) => {
    let charterRateFilters = {
      year: getValues("year"),
      rate: getValues("rate_id"),
      yacht: getValues("yacht_id"),
      days: getValues("days"),
    };
    localStorage.setItem("charterRateFilters", JSON.stringify(charterRateFilters));
    dispatch(fetchCharterRateListCustom(data));
  };

  const handleDeleteCharterRate = (charterRate_id) => {
    dispatch(deleteCharterRate(charterRate_id));
  };

  return (
    <>
      <HeaderCatalog title={t("charter_title")} message={charterRateStateCustom.message} to="/charterRates_new" />
      <Divider />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("general_year")}</label>
            <select name="year" defaultValue={currentYear} aria-expanded="true" ref={register}>
              {charterRateStateCustom.years.map((year, index) => (
                <option key={index} value={year.year}>{year.year}</option>
              ))}
            </select>  
          </Form.Field>
          <Form.Field required>
            <label>{t("general_rateType")}</label>
            <select name="rate_id" defaultValue={rateType} aria-expanded="true" ref={register({ required: true })}>
              <option />
              {charterRateStateCustom.rates.map((rateType) => (
                <option key={rateType.id} value={rateType.id}>{t(rateType.name)}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("general_yacht")}</label>
            <select name="yacht_id" defaultValue={yacht} aria-expanded="true" ref={register({ required: true })}>
              <option />
              {charterRateStateCustom.yachts.map((yacht) => (
                <option key={yacht.id} value={yacht.id}>{yacht.name}</option>
              ))}
            </select>
            {errors.rate_id && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("general_days")}</label>
            <select name="days" defaultValue={days} aria-expanded="true" ref={register}>
              <option value="all">{t("all")}</option>
              {charterRateStateCustom.days.map((day, index) => (
                 <option key={index} value={day.number_days}>{t(day.number_days)}</option>
              ))}
            </select>
          </Form.Field>
          <Form.Field>
            <label>&nbsp;</label>
            <Button type="submit" loading={charterRateStateCustom.fetching ? true : false} color="green"><Icon name='search' />{t("search_button")}</Button>
          </Form.Field>
        </Form.Group>
      </Form>

      {charterRateStateCustom.charterRates !== undefined ? (
        <Table color="blue" striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>{t("charter_year")}</Table.HeaderCell>
              <Table.HeaderCell>{t("charter_rateType")}</Table.HeaderCell>
              <Table.HeaderCell>{t("charter_yacht")}</Table.HeaderCell>
              <Table.HeaderCell>{t("charter_days")}</Table.HeaderCell>
              <Table.HeaderCell>{t("charter_clientRate")}</Table.HeaderCell>
              <Table.HeaderCell>{t("charter_operatorRate")}</Table.HeaderCell>
              <Table.HeaderCell>{t("state")}</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
              {charterRateStateCustom.charterRates.map((charterRate) => (
                <Table.Row key={charterRate.id}>
                  <Table.Cell collapsing><ActiveIcon active={charterRate.active} /></Table.Cell>
                  <Table.Cell>{charterRate.year}</Table.Cell>
                  <Table.Cell>{t(charterRate.rate)}</Table.Cell>
                  <Table.Cell>{charterRate.yacht}</Table.Cell>
                  <Table.Cell>{charterRate.days}</Table.Cell>
                  <Table.Cell><GenericRateCell rate={charterRate.rate_client} ticket={charterRate.airplane_ticket} /></Table.Cell>
                  <Table.Cell><GenericRateCell rate={charterRate.rate_operator} ticket={charterRate.airplane_ticket} /></Table.Cell>
                  <Table.Cell width="2"><StateRateCell activation_date={charterRate.activation_date} inactivation_date={charterRate.inactivation_date} reason_deactivation={charterRate.reason_deactivation} /></Table.Cell>
                  <Table.Cell collapsing>
                    {charterRate.active != false ? (
                      <ChangeStateRateModal stateRate={charterRate} typeRate="CharterRate" />
                    ):(
                      null
                    )}
                    <Link to={`/charterRates/${charterRate.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
                    <Modal
                      trigger={
                        <Button circular color='red' icon='trash alternate' />
                      }
                      content={t("general_delete")}
                      actions={[
                        t("canceled_button"),
                          {
                            key: "eliminar",
                            content: t("delete_button"),
                            className: "delete_button icon_color",
                            onClick: () => handleDeleteCharterRate(charterRate.id),
                          },
                      ]}
                      size="tiny"
                    />
                  </Table.Cell>
                </Table.Row>
                ))}
            </Table.Body>
        </Table>
      ) : (
        ""
      )}
    </>
  );
}
