import CruiseRateService from "../../../../services/reservations/CruiseRateService";

export const REQUEST_FETCH_CRUISE_RATES = "REQUEST_FETCH_CRUISE_RATES";
export const FETCH_CRUISE_RATES_SUCCESS = "FETCH_CRUISE_RATES_SUCCESS";
export const FETCH_CRUISE_RATES_ERROR = "FETCH_CRUISE_RATES_ERROR";

function requestFetchCruiseRates() {
  return {
    type: REQUEST_FETCH_CRUISE_RATES,
  };
}

function fetchCruiseRatesSuccess(json) {
  return {
    type: FETCH_CRUISE_RATES_SUCCESS,
    cruiseRates: json.data,
  };
}

function fetchCruiseRatesError(json) {
  return {
    type: FETCH_CRUISE_RATES_ERROR,
    error: json.data,
  };
}

export function fetchCruiseRates(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchCruiseRates());
    return CruiseRateService.getCruiseRates(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCruiseRatesSuccess(json.data));
      } else {
        dispatch(fetchCruiseRatesError(json.data));
      }
    });
  };
}
