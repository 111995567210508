import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ItineraryService from "../../../../services/catalogs/ItineraryService";

export const REQUEST_CREATE_ITINERARY = "REQUEST_CREATE_ITINERARY";
export const CREATE_ITINERARY_SUCCESS = "CREATE_ITINERARY_SUCCESS";
export const CREATE_ITINERARY_ERROR = "CREATE_ITINERARY_ERROR";

function requestCreateItinerary() {
  return {
    type: REQUEST_CREATE_ITINERARY,
  };
}

function createItinerarySuccess(json) {
  return {
    type: CREATE_ITINERARY_SUCCESS,
    message: json.data,
  };
}

function createItineraryError(json) {
  return {
    type: CREATE_ITINERARY_ERROR,
    error: json.data,
  };
}

export function createItinerary(payload) {
  return (dispatch) => {
    dispatch(requestCreateItinerary());
    return ItineraryService.postItinerary(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createItinerarySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createItinerary(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createItineraryError(json.data));
      }
    });
  };
}
