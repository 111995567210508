import {
  REQUEST_FETCH_BOOKINGS,
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_ERROR,
} from "../../../actions/reservations/booking/fetchBookings";

import {
  INITIAL_STATE_BOOKING,
  REQUEST_FETCH_BOOKING,
  FETCH_BOOKING_SUCCESS,
  FETCH_BOOKING_ERROR,
} from "../../../actions/reservations/booking/fetchBooking";

import {
  REQUEST_CREATE_BOOKING,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_ERROR,
} from "../../../actions/reservations/booking/createBooking";

import {
  REQUEST_UPDATE_BOOKING,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_ERROR,
} from "../../../actions/reservations/booking/updateBooking";

import {
  REQUEST_DELETE_BOOKING,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_ERROR,
} from "../../../actions/reservations/booking/deleteBooking";

const initialState = {
  bookings: [],
  booking: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_BOOKING: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_BOOKINGS: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_BOOKINGS_SUCCESS: {
      return {
        ...state,
        bookings: action.bookings,
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_BOOKINGS_ERROR: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_BOOKING: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_BOOKING_SUCCESS: {
      return {
        ...state,
        bookings: [],
        booking: action.booking,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_BOOKING_ERROR: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_BOOKING: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_BOOKING_SUCCESS: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_BOOKING_ERROR: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_BOOKING: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_BOOKING_SUCCESS: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_BOOKING_ERROR: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_BOOKING: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_BOOKING_SUCCESS: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_BOOKING_ERROR: {
      return {
        ...state,
        bookings: [],
        booking: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default bookingReducer;
