import HistoricalCruiseService from "../../../../services/reservations/HistoricalCruiseService";

export const REQUEST_FETCH_HISTORICAL_CRUISES =
  "REQUEST_FETCH_HISTORICAL_CRUISES";
export const FETCH_HISTORICAL_CRUISES_SUCCESS =
  "FETCH_HISTORICAL_CRUISES_SUCCESS";
export const FETCH_HISTORICAL_CRUISES_ERROR = "FETCH_HISTORICAL_CRUISES_ERROR";
export const INITIAL_STATE_HISTORICAL_CRUISE =
  "INITIAL_STATE_HISTORICAL_CRUISE";

function requestFetchHistoricalCruises() {
  return {
    type: REQUEST_FETCH_HISTORICAL_CRUISES,
  };
}

function fetchHistoricalCruisesSuccess(json) {
  return {
    type: FETCH_HISTORICAL_CRUISES_SUCCESS,
    cruises: json.data,
  };
}

function fetchHistoricalCruisesError(json) {
  return {
    type: FETCH_HISTORICAL_CRUISES_ERROR,
    error: json.data,
  };
}

export function initialStateHistoricalCruise() {
  return {
    type: INITIAL_STATE_HISTORICAL_CRUISE,
  };
}

export function fetchHistoricalCruises(cruiseCode, cruiseYear) {
  return (dispatch) => {
    dispatch(requestFetchHistoricalCruises());
    return HistoricalCruiseService.getHistoricalCruises(
      cruiseCode,
      cruiseYear
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchHistoricalCruisesSuccess(json.data));
      } else {
        dispatch(fetchHistoricalCruisesError(json.data));
      }
    });
  };
}
