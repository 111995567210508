import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customSeasonForm = createSlice({
  name: 'customSeasonForm',
  initialState: {
    airlines: [],
    season: {},
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateSeasonFormCustom(state, action) {
      return {
        airlines: [],
        season: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesSeasonFormCustom(state, action) {
      return {
        airlines: [],
        season: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchSeasonFormCustomSuccess(state, action) {
      return {
        airlines: action.payload.airlines,
        season: action.payload.season,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchSeasonFormCustomError(state, action) {
      return {
        airlines: [],
        season: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchSeasonFormCustom = (season_id) => dispatch => {
  dispatch(requesSeasonFormCustom());
  axios.get(`/seasons/form/seasonForm?season_id=${season_id}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchSeasonFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSeasonFormCustom(season_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSeasonFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateSeasonFormCustom,
  requesSeasonFormCustom,
  fetchSeasonFormCustomSuccess,
  fetchSeasonFormCustomError,
} = customSeasonForm.actions;

export default customSeasonForm.reducer;