import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ItineraryService from "../../../../services/catalogs/ItineraryService";

export const REQUEST_FETCH_ITINERARY = "REQUEST_FETCH_ITINERARY";
export const FETCH_ITINERARY_SUCCESS = "FETCH_ITINERARY_SUCCESS";
export const FETCH_ITINERARY_ERROR = "FETCH_ITINERARY_ERROR";
export const INITIAL_STATE_ITINERARY = "INITIAL_STATE_ITINERARY";

function requestFetchItinerary() {
  return {
    type: REQUEST_FETCH_ITINERARY,
  };
}

function fetchItinerarySuccess(json) {
  return {
    type: FETCH_ITINERARY_SUCCESS,
    itinerary: json.data,
  };
}

function fetchItineraryError(json) {
  return {
    type: FETCH_ITINERARY_ERROR,
    error: json.data,
  };
}

export function initialStateItinerary() {
  return {
    type: INITIAL_STATE_ITINERARY,
  };
}

export function fetchItinerary(itinerary_id) {
  return (dispatch) => {
    dispatch(requestFetchItinerary());
    return ItineraryService.getItinerary(itinerary_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchItinerarySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchItinerary(itinerary_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchItineraryError(json.data));
      }
    });
  };
}
