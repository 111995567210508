import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CaptainService from "../../../../services/catalogs/CaptainService";

export const REQUEST_UPDATE_CAPTAIN = "REQUEST_UPDATE_CAPTAIN";
export const UPDATE_CAPTAIN_SUCCESS = "UPDATE_CAPTAIN_SUCCESS";
export const UPDATE_CAPTAIN_ERROR = "UPDATE_CAPTAIN_ERROR";

function requestUpdateCaptain() {
  return {
    type: REQUEST_UPDATE_CAPTAIN,
  };
}

function updateCaptainSuccess(json) {
  return {
    type: UPDATE_CAPTAIN_SUCCESS,
    message: json.data,
  };
}

function updateCaptainError(json) {
  return {
    type: UPDATE_CAPTAIN_ERROR,
    error: json.data,
  };
}

export function updateCaptain(captain_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateCaptain());
    return CaptainService.putCaptain(captain_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateCaptainSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateCaptain(captain_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateCaptainError(json.data));
      }
    });
  };
}
