
import React from "react";
import { useTranslation } from "react-i18next";
import { Popup, Grid, Icon, List, Header } from "semantic-ui-react";

export default function ContractRatePopup(props) {
  const { t } = useTranslation();
  return (
    <>
      <Popup trigger={<Icon name='info' color='red' size='small' circular />} flowing hoverable>
        <Grid centered divided columns={3}>
          <Grid.Column textAlign='center'>
            <Header as='h4'>Charter</Header>
            <p>
              <List.Icon name='dollar' color='blue' />
              <b>{props.charter_client}</b>
            </p>
            <p>
              <List.Icon color='red' name={props.airplane_ticket === 1 ? 'plane' : 'dont'} />
              {props.airplane_ticket === 1 ? t("general_priceInclude") : t("general_priceNotInclude")}
            </p>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Header as='h4'>Doble</Header>
            <p>
              <List.Icon name='dollar' color='blue' />
              <b>{props.double_client}</b>
            </p>
            <p>
              <List.Icon color='red' name={props.airplane_ticket === 1 ? 'plane' : 'dont'} />
              {props.airplane_ticket === 1 ? t("general_priceInclude") : t("general_priceNotInclude")}
            </p>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Header as='h4'>Sencilla</Header>
            <p>
              <List.Icon name='dollar' color='blue' />
              <b>{props.single_client}</b>
            </p>
            <p>
              <List.Icon color='red' name={props.airplane_ticket === 1 ? 'plane' : 'dont'} />
              {props.airplane_ticket === 1 ? t("general_priceInclude") : t("general_priceNotInclude")}
            </p>
          </Grid.Column>
        </Grid>
      </Popup>
    </>
  );
}
