import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import PassengerService from "../../../../services/reservations/PassengerService";

export const REQUEST_FETCH_SEARCH_PASSENGERS =
  "REQUEST_FETCH_SEARCH_PASSENGERS";
export const FETCH_SEARCH_PASSENGERS_SUCCESS =
  "FETCH_SEARCH_PASSENGERS_SUCCESS";
export const FETCH_SEARCH_PASSENGERS_ERROR = "FETCH_SEARCH_PASSENGERS_ERROR";
export const INITIAL_STATE_SEARCH_PASSENGER = "INITIAL_STATE_SEARCH_PASSENGER";

function requestFetchSearchPassengers() {
  return {
    type: REQUEST_FETCH_SEARCH_PASSENGERS,
  };
}

function fetchSearchPassengersSuccess(json) {
  return {
    type: FETCH_SEARCH_PASSENGERS_SUCCESS,
    passengers: json.data,
  };
}

function fetchSearchPassengersError(json) {
  return {
    type: FETCH_SEARCH_PASSENGERS_ERROR,
    error: json.data,
  };
}

export function initialStateSearchPassenger() {
  return {
    type: INITIAL_STATE_SEARCH_PASSENGER,
  };
}

export function fetchSearchPassengers(name, passport) {
  return (dispatch) => {
    dispatch(requestFetchSearchPassengers());
    return PassengerService.getSearchPassengers(name, passport).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchSearchPassengersSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSearchPassengers(name, passport));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSearchPassengersError(json.data));
      }
    });
  };
}
