import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class ContractRateService {
  static getYears() {
    return axios.get("contractRates/year");
  }

  static getAgenciesByContract(contractType) {
    var url = "contractRates/contractType";
    if (contractType !== null) {
      url = url + `?specific_contract=${contractType}`;
    }
    return axios.get(url);
  }

  static getContracts(payload) {
    return axios.post("contractRates/getByBody", payload);
  }

  static getContract(contract_id) {
    return axios.get("contractRates/" + contract_id);
  }

  static findContract(reservation_id) {
    return axios.get("contractRates/find_rate/" + reservation_id);
  }

  static findContractByCruiseAgencyCruiseType(cruise_id, payload) {
    return axios.post("contractRates/find_contract_rate_by_cruise_agency_cruiseType/" + cruise_id, payload);
  }

  static postContract(payload) {
    return axios.post("contractRates", payload);
  }

  static putContract(contract_id, payload) {
    return axios.put("contractRates/" + contract_id, payload);
  }

  static activeContractRate(contract_id) {
    return axios.put("contractRates/" + contract_id + "/activeContractRate");
  }

  static inactiveContractRate(contract_id, payload) {
    return axios.put("contractRates/" + contract_id + "/inactiveContractRate", payload);
  }

  static deleteContract(contract_id) {
    return axios.delete("contractRates/" + contract_id);
  }
}
