import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class SpaceService {
  static getSpaceFlights(space_id) {
    return axios.get("space/" + space_id + "/spaceFlights");
  }

  static getSpaceFlight(space_id, space_flight_id) {
    return axios.get(
      "space/" + space_id + "/spaceFlights/" + space_flight_id
    );
  }

  static getNumberSpaceFlights(space_id) {
    return axios.get("space/" + space_id + "/numberFlights");
  }

  static postSpaceFlight(space_id, payload) {
    return axios.post(
      "space/" + space_id + "/spaceFlights",
      payload
    );
  }

  static putSpaceFlight(space_flight_id, payload) {
    return axios.put("spaceFlights/" + space_flight_id, payload);
  }

  static deleteSpaceFlight(space_flight_id) {
    return axios.delete("spaceFlights/" + space_flight_id);
  }

  static getItemsByField(space_id) {
    return axios.get("space/spaceFlight/logs/" + space_id);
  }
}
