import AvailableCabinService from "../../../../services/reservations/AvailableCabinService";

export const REQUEST_UPDATE_ONE_CABIN = "REQUEST_UPDATE_ONE_CABIN";
export const UPDATE_ONE_CABIN_SUCCESS = "UPDATE_ONE_CABIN_SUCCESS";
export const UPDATE_ONE_CABIN_ERROR = "UPDATE_ONE_CABIN_ERROR";
export const INITIAL_STATE_UPDATE_CABIN = "INITIAL_STATE_UPDATE_CABIN";

function requestUpdateOneCabin() {
  return {
    type: REQUEST_UPDATE_ONE_CABIN,
  };
}

function updateOneCabinSuccess(json) {
  return {
    type: UPDATE_ONE_CABIN_SUCCESS,
    message: json.data,
  };
}

function updateOneCabinError(json) {
  return {
    type: UPDATE_ONE_CABIN_ERROR,
    error: json.data,
  };
}

export function initialStateUpdateCabin() {
  return {
    type: INITIAL_STATE_UPDATE_CABIN,
  };
}

export function updateOneCabin(payload) {
  return (dispatch) => {
    dispatch(requestUpdateOneCabin());
    return AvailableCabinService.updateOneCabin(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateOneCabinSuccess(json.data));
      } else {
        dispatch(updateOneCabinError(json.data));
      }
    });
  };
}
