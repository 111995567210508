import CountryList from "../containers/catalogs/country/CountryList";
import CountryForm from "../containers/catalogs/country/CountryForm";

const routes = [
  {
    path: "/countries",
    component: CountryList,
  },
  {
    path: "/countries_new",
    component: CountryForm,
  },
  {
    path: "/countries/:country_id",
    component: CountryForm,
  },
];

export { routes as countryRoutes };
