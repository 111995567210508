import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//UI
import { Button, Modal, Icon, Table, Input, Header, Loader, Breadcrumb, Divider, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { initialStateDay } from "../../../redux/actions/catalogs/day/fetchDay";
import { deleteDay } from "../../../redux/actions/catalogs/day/deleteDay";
import { fetchDayListCustom, initialStateDayListCustom } from "../../../redux/slices/custom/catalogs/day/customDayList.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function DayList() {
  const { t } = useTranslation();
  let { itinerary_id } = useParams();
  const dispatch = useDispatch();
  const dayStateCustom = useSelector((state) => state.customDayList);
  const dayState = useSelector((state) => state.dayReducer);

  useEffect(() => {
    dispatch(fetchDayListCustom(itinerary_id));
    scrollToTop();
    return () => {
      dispatch(initialStateDayListCustom());
      dispatch(initialStateDay());
    };
  }, []);

  if (dayStateCustom.fetching || dayState.fetching) {
    return <Loader active inline />;
  }

  if (dayStateCustom.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (dayState.message === "resource deleted successfully") {
    dispatch(fetchDayListCustom(itinerary_id));
    dispatch(initialStateDay());
  }

  const handledeleteDay = (day_id) => {
    dispatch(deleteDay(day_id));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section><Link to="/itineraries" className="section">{t("itinerary_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{dayStateCustom.itinerary.name}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <HeaderCatalog title={t("day_title")} message={dayStateCustom.message} to={`/itinerary/${itinerary_id}/days_new`} />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("day_number")}</Table.HeaderCell>
            <Table.HeaderCell>{t("day_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("day_am")}</Table.HeaderCell>
            <Table.HeaderCell>{t("day_pm")}</Table.HeaderCell>
            <Table.HeaderCell>{t("day_division")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {dayStateCustom.days.map((dayItem) =>
            dayItem.days.map((day) => (
              <Table.Row key={day.id}>
                <Table.Cell>{day.number}</Table.Cell>
                <Table.Cell>{t(day.name)}</Table.Cell>
                <Table.Cell>{day.am}</Table.Cell>
                <Table.Cell>{day.pm}</Table.Cell>
                <Table.Cell>{day.transfer === 1 ? (<div>{t("yes")}</div>) : (<div>{t("not")}</div>)}</Table.Cell>
                <Table.Cell collapsing>
                  <Link to={`/itinerary/${day.itinerary_id}/day/${day.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
                  <Modal
                    trigger={
                      <Button circular color='red' icon='trash alternate' />
                    }
                    content={t("day_delete")}
                    actions={[
                      t("canceled_button"),
                        {
                          key: "eliminar",
                          content: t("delete_button"),
                          className: "delete_button icon_color",
                          onClick: () => handledeleteDay(day.id),
                        },
                    ]}
                    size="tiny"
                  />
                </Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </>
  );
}
