import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
//UI
import logo from '../icons/logo.png'
import ShowPassword from '../../components/ShowPassword';
//TRASLATION
import { useTranslation } from "react-i18next";
//ACTIONS
import { changePasswordUser } from '../../redux/actions/catalogs/user/changePasswordUser';

const ChangePasswordForm = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { t } = useTranslation();
  let { user_id } = useParams();
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
  const { getValues, register, handleSubmit, errors } = useForm();
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [isPasswordConfirmHidden, setIsPasswordConfirmHidden] = useState(true);
  const [comparePass, setComparePass] = useState(true)
  const userState = useSelector(state => state.userReducer)

  const onSubmit = (data) => {
    if (data.password === data.confirmPassword) {
      dispatch(changePasswordUser(user_id, data));
    }
  };

  function comparePassword() {
    if (getValues("password") !== getValues("confirmPassword")) {
      setComparePass(false)
    } else {
      setComparePass(true)
    }
  }

  if (userState.message === "password updated successfully") {
    setTimeout(() => {
      history.push("/");
    }, 4500);
  }

  return (
    <div
      className={`text-sky-800 flex justify-center items-center 2xl:items-center transition-all h-full w-full min-h-screen bg-cover bg-center bg-[url('../src/containers/icons/Picture3.jpg')]`}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-gray-600/40 backdrop-blur-sm"></div>
      <div className="w-[90%] h-full transition-all fle rounded-md sm:w-2/3 md:w-[35%]  bg-[#ffffff6a] sm:bg-[#ffffff6a] backdrop-blur-lg shadow-lg shadow-gray-500 flex flex-col items-center justify-center">
        <div className='flex flex-col gap-2 w-[65%] mt-14'>
          <h1 className="text-center text-[18px] font-semibold ">{t("change_password_title")}</h1>
        </div>
        <div className='flex flex-col p-2 w-[80%] border-green-500 rounded-md border mt-6 bg-green-200'>
          {userState.message === "password updated successfully" ?
            <>
              <h1 className="text-left text-sm mb-0">{t("change_password_success")}</h1>
              <div className='pl-2 text-sm'>
                <p className='mb-0'>{t("change_password_success_message")}</p>
              </div>
            </> :
            <>
              <h1 className="text-left text-sm mb-0">{t("change_password_instructions")}</h1>
              <div className='pl-2 text-sm'>
                <p className='mb-0'>- {t("change_password_instruction_one")}</p>
                <p className='mb-0'>- {t("change_password_instruction_two")}</p>
                <p className='mb-0'>- {t("change_password_instruction_three")}</p>
              </div>
            </>
          }
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-[75%] text-gray-800 mx-auto mb-8">
          <div className='flex flex-col'>
            <div className="relative flex flex-col mt-8">
              <input className={'w-full placeholder-gray-800 p-2 rounded-xl focus:outline-none peer bg-transparent shadow-[inset_0_-1px_0_0_rgba(29,34,43,.2)]'}
                type={isPasswordHidden ? 'password' : 'text'}
                ref={register({ required: true, pattern: regex, minLength: 6, maxLength: 14 })}
                placeholder="Contraseña"
                name="password"
              />
              <ShowPassword
                isPasswordHidden={isPasswordHidden}
                setIsPasswordHidden={setIsPasswordHidden}
              />
            </div>
            {errors.password && errors.password.type === "required" && <span className="text-red-600">{t("required_information")}</span>}
            {errors.password && errors.password.type === "pattern" && <span className="text-red-600">{t("change_password_not_comply")}</span>}
            {errors.password && errors.password.type === "minLength" && <span className="text-red-600">{t("change_password_minimum_six_characters")}</span>}
            {errors.password && errors.password.type === "maxLength" && <span className="text-red-600">{t("change_password_maximum_fourteen_characters")}</span>}
            <div className="relative flex flex-col mt-8">
              <input className={'w-full placeholder-gray-800 p-2 rounded-xl focus:outline-none peer bg-transparent shadow-[inset_0_-1px_0_0_rgba(29,34,43,.2)]'}
                type={isPasswordConfirmHidden ? 'password' : 'text'}
                ref={register({ required: true })}
                placeholder="Confirme contraseña"
                name="confirmPassword"
                onChange={comparePassword}
              />
              <ShowPassword
                isPasswordHidden={isPasswordConfirmHidden}
                setIsPasswordHidden={setIsPasswordConfirmHidden}
              />
            </div>
            {errors.confirmPassword && errors.confirmPassword.type === "required" && <span className="text-red-600">{t("required_information")}</span>}
            {!comparePass && <span className="text-red-600">{t("change_password_passwords_donot_match")}</span>}
          </div>
          <div className='flex w-full justify-center h-[35px] mt-10'>
            <button type=" submit" className="btn shadow-lg btn-block w-[70%] bg-sky-700 hover:bg-sky-800 hover:duration-75 border-none rounded-md  text-white">
              {t("change_password_reset_pass")}
            </button>
          </div>
        </form>
      </div>
      <div className="absolute w-[220px] h-[20px] z-10 -translate-x-1/2 top-5 left-1/2  sm:translate-x-0  sm:top-[80%] sm:left-5 rotate-[0.5deg] ">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
};

export default ChangePasswordForm;