import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class AvailableCabinService {
  static getAvailablePassengers(
    cruise_id,
    reservation_id,
    booking_id,
    passenger_id
  ) {
    var url =
      API_BASE_URL +
      "cruise/" +
      cruise_id +
      "/reservation/" +
      reservation_id +
      "/booking/" +
      booking_id +
      "/passenger/" +
      passenger_id +
      "/selectCabins";
    return axios.get(url);
  }

  static updateTwoPassengers(payload) {
    var url = "bookings/switchTwoPassengers";
    return axios.put(url, payload);
  }
}
