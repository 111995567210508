import VoucherService from "../../../../services/reservations/VoucherService";

export const REQUEST_CREATE_VOUCHER = "REQUEST_CREATE_VOUCHER";
export const CREATE_VOUCHER_SUCCESS = "CREATE_VOUCHER_SUCCESS";
export const CREATE_VOUCHER_ERROR = "CREATE_VOUCHER_ERROR";

function requestCreateVoucher() {
  return {
    type: REQUEST_CREATE_VOUCHER,
  };
}

function createVoucherSuccess(json) {
  return {
    type: CREATE_VOUCHER_SUCCESS,
    message: json.data,
  };
}

function createVoucherError(json) {
  return {
    type: CREATE_VOUCHER_ERROR,
    error: json.data,
  };
}

export function createVoucher(cruise_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateVoucher());
    return VoucherService.postVoucher(cruise_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createVoucherSuccess(json.data));
      } else {
        dispatch(createVoucherError(json.data));
      }
    });
  };
}
