import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPassenger,
  initialStatePassenger,
} from "../../../../redux/actions/reservations/passenger/fetchPassengerDetail";
import {
  fetchCruisesByPassenger,
  initialStateCruiseByPassenger,
} from "../../../../redux/actions/reservations/passenger/fetchCruisesByPassenger";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { Link, useParams } from "react-router-dom";
import { Form, Table as Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function CruisePassengerList() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { passenger_id } = useParams();
  const passengerDetailState = useSelector(
    (state) => state.passengerDetailReducer
  );
  const cruiseByPassengerState = useSelector(
    (state) => state.cruiseByPassengerReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (passenger_id) {
      dispatch(fetchPassenger(passenger_id));
      dispatch(fetchCruisesByPassenger(passenger_id));
    }
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStatePassenger());
      dispatch(initialStateCruiseByPassenger());
    };
  }, [dispatch, passenger_id]);

  if (passengerDetailState.fetching || cruiseByPassengerState.fetching) {
    return <CircularProgress />;
  }

  if (passengerDetailState.error) {
    return <div>Error: {passengerDetailState.error}</div>;
  }

  if (cruiseByPassengerState.error) {
    return <div>Error: {cruiseByPassengerState.error}</div>;
  }

  return (
    <>
      <div className="ui breadcrumb">
        <Link to="/passengers">
          <div className="active section">{t("passengers_tag")}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">
          {passengerDetailState.passenger.first_name}{" "}
          {passengerDetailState.passenger.last_name}
        </div>
      </div>
      <br></br>
      <br></br>
      <br></br>

      <Form>
        <Form.Group>
          <Form.Field>
            <Tab
              celled
              fixed
              structured
              textAlign="left"
              verticalAlign="middle"
            >
              <Tab.Body>
                <Tab.Row>
                  <Tab.Cell
                    rowSpan="2"
                    textAlign="center"
                    width={2}
                    style={{
                      backgroundColor: "#47D6DB",
                      color: "white",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("personal_information_tag")}
                  </Tab.Cell>
                  <Tab.Cell>
                    <span
                      style={{
                        color: "#2185D0",
                        fontSize: "20px",
                        fontWeight: "800",
                      }}
                    >
                      {passengerDetailState.passenger.first_name}{" "}
                      {passengerDetailState.passenger.last_name}{" "}
                    </span>
                  </Tab.Cell>
                  <Tab.Cell>
                    <span className="detail_passenger">{t("Pasaporte")}: </span>{" "}
                    {passengerDetailState.passenger.identification}{" "}
                  </Tab.Cell>
                  <Tab.Cell>
                    <span className="detail_passenger">
                      {t("passenger_search_nationality")}:{" "}
                    </span>{" "}
                    {passengerDetailState.passenger.country}
                  </Tab.Cell>
                </Tab.Row>
                <Tab.Row>
                  <Tab.Cell>
                    <span className="detail_passenger">
                      {t("gender_title")}:{" "}
                    </span>{" "}
                    {t(passengerDetailState.passenger.gender)}
                  </Tab.Cell>
                  <Tab.Cell>
                    <span className="detail_passenger">
                      {" "}
                      {t("passenger_search_age")}:{" "}
                    </span>{" "}
                    {passengerDetailState.passenger.age}{" "}
                    {t("passenger_search_years_old")}{" "}
                  </Tab.Cell>
                  <Tab.Cell>
                    <span className="detail_passenger">
                      {" "}
                      {t("passenger_email")}:{" "}
                    </span>{" "}
                    {passengerDetailState.passenger.email}
                  </Tab.Cell>
                </Tab.Row>
              </Tab.Body>
            </Tab>
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field>
            <Tab celled fixed structured textAlign="left" verticalAlign="top">
              <Tab.Body>
                <Tab.Row>
                  <Tab.Cell
                    rowSpan="3"
                    textAlign="center"
                    width={2}
                    style={{
                      backgroundColor: "#92C1FF",
                      color: "white",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("general_information_tag")}
                  </Tab.Cell>

                  <Tab.Cell>
                    <span className="detail_passenger">
                      {" "}
                      {t("passenger_vegetarian")}:{" "}
                    </span>{" "}
                    {passengerDetailState.passenger.vegeterian === 1
                      ? "SI"
                      : "NO"}
                  </Tab.Cell>
                  <Tab.Cell>
                    <span className="detail_passenger">
                      {t("passenger_glutenFree")}:{" "}
                    </span>{" "}
                    {passengerDetailState.passenger.gluten_free === 1
                      ? "SI"
                      : "NO"}
                  </Tab.Cell>

                  <Tab.Cell>
                    <span className="detail_passenger"> TCT: </span>{" "}
                    {passengerDetailState.passenger.tct === 1
                      ? t("yes")
                      : t("not")}
                  </Tab.Cell>
                  <Tab.Cell>
                    <span className="detail_passenger"> GPS: </span>{" "}
                    {passengerDetailState.passenger.geps === 1
                      ? t("yes")
                      : t("not")}
                  </Tab.Cell>
                </Tab.Row>
                <Tab.Row>
                  <Tab.Cell colSpan="2" verticalAlign="top">
                    <span className="detail_passenger">
                      {" "}
                      {t("passenger_feed")}:{" "}
                    </span>{" "}
                    {passengerDetailState.passenger.feed}
                  </Tab.Cell>
                  <Tab.Cell colSpan="2" verticalAlign="top">
                    <span className="detail_passenger">
                      {" "}
                      {t("passenger_med")}:{" "}
                    </span>
                    {passengerDetailState.passenger.medical_information}
                  </Tab.Cell>
                </Tab.Row>

                <Tab.Row>
                  <Tab.Cell colSpan="2" verticalAlign="top">
                    <span className="detail_passenger">
                      {" "}
                      {t("passenger_wet")}:{" "}
                    </span>
                    {passengerDetailState.passenger.rent_wetsuits}
                  </Tab.Cell>
                  <Tab.Cell colSpan="2">
                    <span className="detail_passenger" verticalAlign="top">
                      {" "}
                      {t("passenger_requirements")}:{" "}
                    </span>
                    {passengerDetailState.passenger.special_requirements}
                  </Tab.Cell>
                </Tab.Row>
              </Tab.Body>
            </Tab>
          </Form.Field>
        </Form.Group>
      </Form>

      <br></br>

      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("cruises_tag")}
          </Typography>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("cruise_code")}</TableCell>
              <TableCell>{t("cruise_yacht")}</TableCell>
              <TableCell>
                {t("cruise_startDate")} <br></br>
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </TableCell>
              <TableCell>
                {t("cruise_endDate")} <br></br>
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </TableCell>
              <TableCell>{t("cruise_name")}</TableCell>
              <TableCell width="300px">{t("cruise_itinerary")}</TableCell>
              <TableCell align="center">{t("cabin_cabin")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cruiseByPassengerState.cruises.map((cruise) => (
              <TableRow key={cruise.id}>
                <TableCell>{cruise.code}</TableCell>
                <TableCell>{cruise.yacht}</TableCell>
                <TableCell>{cruise.start_date_format}</TableCell>
                <TableCell>{cruise.end_date_format}</TableCell>
                <TableCell>{cruise.name}</TableCell>
                <TableCell>{cruise.itinerary}</TableCell>
                <TableCell align="center">{cruise.cabin}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
