import {
  INITIAL_STATE_VERIFY_SPACES_BY_RESERVATION,
  REQUEST_SPACES_BY_RESERVATION,
  VERIFY_SPACES_BY_RESERVATION_SUCCESS,
  VERIFY_SPACES_BY_RESERVATION_ERROR,
} from "../../../actions/reservations/reservation/verifySpacesByReservation";

const initialState = {
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const verifySpacesByReservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_VERIFY_SPACES_BY_RESERVATION: {
      return {
        ...state,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }

    case REQUEST_SPACES_BY_RESERVATION: {
      return {
        ...state,
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case VERIFY_SPACES_BY_RESERVATION_SUCCESS: {
      return {
        ...state,
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case VERIFY_SPACES_BY_RESERVATION_ERROR: {
      return {
        ...state,
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default verifySpacesByReservationReducer;
