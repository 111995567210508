import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import BerthService from "../../../../services/catalogs/BerthService";

export const REQUEST_UPDATE_BERTH = "REQUEST_UPDATE_BERTH";
export const UPDATE_BERTH_SUCCESS = "UPDATE_BERTH_SUCCESS";
export const UPDATE_BERTH_ERROR = "UPDATE_BERTH_ERROR";

function requestUpdateBerth() {
  return {
    type: REQUEST_UPDATE_BERTH,
  };
}

function updateBerthSuccess(json) {
  return {
    type: UPDATE_BERTH_SUCCESS,
    message: json.data,
  };
}

function updateBerthError(json) {
  return {
    type: UPDATE_BERTH_ERROR,
    error: json.data,
  };
}

export function updateBerth(berth_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateBerth());
    return BerthService.putBerth(berth_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateBerthSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateBerth(berth_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateBerthError(json.data));
      }
    });
  };
}
