import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { fetchYachts } from "../../../redux/actions/catalogs/yacht/fetchYachts";
import { deleteYacht } from "../../../redux/actions/catalogs/yacht/deleteYacht";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function YachtList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const yachtState = useSelector((state) => state.yachtsReducer);
 
  useEffect(() => {
    dispatch(fetchYachts());
    scrollToTop();
  }, []);

  if (yachtState.fetching || yachtState.processing) {
    return <Loader active inline />;
  }

  if (yachtState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (yachtState.message === "resource deleted successfully") {
    dispatch(fetchYachts());
  }

  const handleDeleteYacht = (yacht_id) => {
    dispatch(deleteYacht(yacht_id));
  };

  return (
    <>
      <HeaderCatalog title={t("yacht_title")} message={yachtState.message} to="/yachts_new" />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("yacht_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("yacht_initials")}</Table.HeaderCell>
            <Table.HeaderCell>{t("yacht_capacity")}</Table.HeaderCell>
            <Table.HeaderCell>{t("yacht_availability")}</Table.HeaderCell>
            <Table.HeaderCell>{t("yacht_order")}</Table.HeaderCell>
            <Table.HeaderCell>{t("yacht_colour")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {yachtState.yachts.map((yacht) => (
          <Table.Row key={yacht.id}>
            <Table.Cell collapsing><ActiveIcon active={yacht.active} /></Table.Cell>
            <Table.Cell><Link to={`/yacht/${yacht.id}/cabins`}>{yacht.name}</Link></Table.Cell>
            <Table.Cell>{yacht.code}</Table.Cell>
            <Table.Cell>{yacht.capacity}</Table.Cell>
            <Table.Cell>{yacht.availability}</Table.Cell>
            <Table.Cell>{yacht.display_order}</Table.Cell>
            <Table.Cell><div style={{width: "15px", height: "15px", backgroundColor: `${yacht.color}`}}></div></Table.Cell>

            <Table.Cell collapsing>
              <Link to={`/yachts/${yacht.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("yacht_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteYacht(yacht.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
