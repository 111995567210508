import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class CabinService {
  static getCabins(yacht_id) {
    return axios.get("yacht/" + yacht_id + "/cabins");
  }
  static getCabin(cabin_id) {
    return axios.get("cabins/" + cabin_id);
  }

  static postCabin(yacht_id, payload) {
    return axios.post("yacht/" + yacht_id + "/cabins", payload);
  }

  static putCabin(cabin_id, payload) {
    return axios.put("cabins/" + cabin_id, payload);
  }

  static deleteCabin(cabin_id) {
    return axios.delete("cabins/" + cabin_id);
  }

  static getAvailabilityCabin(cabin_id) {
    return axios.get("availabilityCabin/" + cabin_id);
  }
}
