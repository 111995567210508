import {
  REQUEST_FETCH_SEASONS,
  FETCH_SEASONS_SUCCESS,
  FETCH_SEASONS_ERROR,
} from "../../../actions/rates/season/fetchSeasons";

import {
  INITIAL_STATE_SEASON,
  REQUEST_FETCH_SEASON,
  FETCH_SEASON_SUCCESS,
  FETCH_SEASON_ERROR,
} from "../../../actions/rates/season/fetchSeason";

import {
  REQUEST_CREATE_SEASON,
  CREATE_SEASON_SUCCESS,
  CREATE_SEASON_ERROR,
} from "../../../actions/rates/season/createSeason";

import {
  REQUEST_UPDATE_SEASON,
  UPDATE_SEASON_SUCCESS,
  UPDATE_SEASON_ERROR,
} from "../../../actions/rates/season/updateSeason";

import {
  REQUEST_DELETE_SEASON,
  DELETE_SEASON_SUCCESS,
  DELETE_SEASON_ERROR,
} from "../../../actions/rates/season/deleteSeason";

const initialState = {
  seasons: [],
  season: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const seasonReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_SEASON: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_SEASONS: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_SEASONS_SUCCESS: {
      return {
        ...state,
        seasons: action.seasons,
        season: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_SEASONS_ERROR: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_SEASON: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_SEASON_SUCCESS: {
      return {
        ...state,
        seasons: [],
        season: action.season,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_SEASON_ERROR: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_SEASON: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_SEASON_SUCCESS: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_SEASON_ERROR: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_SEASON: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_SEASON_SUCCESS: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_SEASON_ERROR: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_SEASON: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_SEASON_SUCCESS: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_SEASON_ERROR: {
      return {
        ...state,
        seasons: [],
        season: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default seasonReducer;
