import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import AgencyService from "../../../../services/catalogs/AgencyService";

export const REQUEST_CREATE_AGENCY = "REQUEST_CREATE_AGENCY";
export const CREATE_AGENCY_SUCCESS = "CREATE_AGENCY_SUCCESS";
export const CREATE_AGENCY_ERROR = "CREATE_AGENCY_ERROR";

function requestCreateAgency() {
  return {
    type: REQUEST_CREATE_AGENCY,
  };
}

function createAgencySuccess(json) {
  return {
    type: CREATE_AGENCY_SUCCESS,
    message: json.data,
  };
}

function createAgencyError(json) {
  return {
    type: CREATE_AGENCY_ERROR,
    error: json.data,
  };
}

export function createAgency(payload) {
  return (dispatch) => {
    dispatch(requestCreateAgency());
    return AgencyService.postAgency(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createAgencySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createAgency(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createAgencyError(json.data));
      }
    });
  };
}
