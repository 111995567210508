import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CruiseService from "../../../../services/reservations/CruiseService";

export const REQUEST_DELETE_CRUISE = "REQUEST_DELETE_CRUISE";
export const DELETE_CRUISE_SUCCESS = "DELETE_CRUISE_SUCCESS";
export const DELETE_CRUISE_ERROR = "DELETE_CRUISE_ERROR";

function requestDeleteCruise() {
  return {
    type: REQUEST_DELETE_CRUISE,
  };
}

function deleteCruiseSuccess(json) {
  return {
    type: DELETE_CRUISE_SUCCESS,
    message: json.data,
  };
}

function deleteCruiseError(json) {
  return {
    type: DELETE_CRUISE_ERROR,
    error: json.data,
  };
}

export function deleteCruise(cruise_id) {
  return (dispatch) => {
    dispatch(requestDeleteCruise());
    return CruiseService.deleteCruise(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteCruiseSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteCruise(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteCruiseError(json.data));
      }
    });
  };
}
