import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customReservationForm = createSlice({
  name: 'customReservationForm',
  initialState: {
    agencies: [],
    cruise: {},
    reservation: {},
    verifySpacesByReservation: "",
    checkItineraryDivision: "",
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateReservationFormCustom(state, action) {
      return {
        agencies: [],
        cruise: {},
        reservation: {},
        verifySpacesByReservation: "",
        checkItineraryDivision: "",
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesReservationFormCustom(state, action) {
      return {
        agencies: [],
        cruise: {},
        reservation: {},
        verifySpacesByReservation: "",
        checkItineraryDivision: "",
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchReservationFormCustomSuccess(state, action) {
      return {
        agencies: action.payload.agencies,
        cruise: action.payload.cruise,
        reservation: action.payload.reservation,
        verifySpacesByReservation: action.payload.verifySpacesByReservation,
        checkItineraryDivision: action.payload.checkItineraryDivision,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchReservationFormCustomError(state, action) {
      return {
        agencies: [],
        cruise: {},
        reservation: {},
        verifySpacesByReservation: "",
        checkItineraryDivision: "",
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchReservationFormCustom = (searchWord, cruise_id, reservation_id) => dispatch => {
  dispatch(requesReservationFormCustom());
  var url = `cruise/reservationForm/${cruise_id}`;
  url = url + `?searchWord=${searchWord}&&reservation_id=${reservation_id}`;
  axios.get(`/${url}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchReservationFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchReservationFormCustom(searchWord, cruise_id, reservation_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchReservationFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateReservationFormCustom,
  requesReservationFormCustom,
  fetchReservationFormCustomSuccess,
  fetchReservationFormCustomError,
} = customReservationForm.actions;

export default customReservationForm.reducer;