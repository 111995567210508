import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import NoteService from "../../../../services/catalogs/NoteService";

export const REQUEST_UPDATE_NOTE = "REQUEST_UPDATE_NOTE";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_ERROR = "UPDATE_NOTE_ERROR";

function requestUpdateNote() {
  return {
    type: REQUEST_UPDATE_NOTE,
  };
}

function updateNoteSuccess(json) {
  return {
    type: UPDATE_NOTE_SUCCESS,
    message: json.data,
  };
}

function updateNoteError(json) {
  return {
    type: UPDATE_NOTE_ERROR,
    error: json.data,
  };
}

export function updateNote(note_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateNote());
    return NoteService.putNote(note_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateNoteSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateNote(note_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateNoteError(json.data));
      }
    });
  };
}
