import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customContractRateList = createSlice({
  name: 'customContractRateList',
  initialState: {
    years: [],
    agencies: [],
    yachts: [],
    days: [],
    contractRates: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateContractRateListCustom(state, action) {
      return {
        years: [],
        agencies: [],
        yachts: [],
        days: [],
        contractRates: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesContractRateListCustom(state, action) {
      return {
        years: [],
        agencies: [],
        yachts: [],
        days: [],
        contractRates: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchContractRateListCustomSuccess(state, action) {
      return {
        years: action.payload.years,
        agencies: action.payload.agencies,
        yachts: action.payload.yachts,
        days: action.payload.days,
        contractRates: action.payload.contractRates,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchContractRateListCustomError(state, action) {
      return {
        years: [],
        agencies: [],
        yachts: [],
        days: [],
        contractRates: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchContractRateListCustom = (payload, searchWord) => dispatch => {
  dispatch(requesContractRateListCustom());
  axios.post(`/contractRates/contractRatesList?searchWord=${searchWord}`, payload)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchContractRateListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchContractRateListCustom(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchContractRateListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateContractRateListCustom,
  requesContractRateListCustom,
  fetchContractRateListCustomSuccess,
  fetchContractRateListCustomError,
} = customContractRateList.actions;

export default customContractRateList.reducer;