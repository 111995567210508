import {
  INITIAL_STATE_RESERVATION_DETAIL,
  REQUEST_FETCH_RESERVATION_DETAIL,
  FETCH_RESERVATION_DETAIL_SUCCESS,
  FETCH_RESERVATION_DETAIL_ERROR,
} from "../../../actions/b2b/availability/fetchReservationDetail";

import {
  REQUEST_UPDATE_SPACES,
  UPDATE_SPACES_SUCCESS,
  UPDATE_SPACES_ERROR,
} from "../../../actions/b2b/availability/updateSpacesByReservation";

const initialState = {
  reservation: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const availabilityReservationDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_RESERVATION_DETAIL: {
      return {
        ...state,
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_RESERVATION_DETAIL: {
      return {
        ...state,
        reservation: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_RESERVATION_DETAIL_SUCCESS: {
      return {
        ...state,
        reservation: action.reservation,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_RESERVATION_DETAIL_ERROR: {
      return {
        ...state,
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_SPACES: {
      return {
        ...state,
        reservation: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_SPACES_SUCCESS: {
      return {
        ...state,
        reservation: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_SPACES_ERROR: {
      return {
        ...state,
        reservation: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default availabilityReservationDetailReducer;
