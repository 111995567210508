import { createSlice } from '@reduxjs/toolkit';
import { updateLocalStorageToken, logOut } from "../../../../../../containers/resources/utils";
import axios from 'axios';

export const customSpaceForm = createSlice({
  name: 'customSpaceForm',
  initialState: {
    genders: [],
    cruise: {},
    reservation: {},
    booking: {},
    space: {},
    contractRate: {},
    cabins: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateSpaceFormCustom(state, action) {
      return {
        genders: [],
        cruise: {},
        reservation: {},
        booking: {},
        space: {},
        contractRate: {},
        cabins: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesSpaceFormCustom(state, action) {
      return {
        genders: [],
        cruise: {},
        reservation: {},
        booking: {},
        space: {},
        contractRate: {},
        cabins: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchSpaceFormCustomSuccess(state, action) {
      return {
        genders: action.payload.genders,
        cruise: action.payload.cruise,
        reservation: action.payload.reservation,
        booking: action.payload.booking,
        space: action.payload.space,
        contractRate: action.payload.contractRate,
        cabins: action.payload.cabins,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchSpaceFormCustomError(state, action) {
      return {
        genders: [],
        cruise: {},
        reservation: {},
        booking: {},
        space: {},
        contractRate: {},
        cabins: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchSpaceFormCustom = (booking_id, cruise_id, reservation_id, space_id) => dispatch => {
  dispatch(requesSpaceFormCustom());
  var url = `booking/${booking_id}/spaceForm`;
  url = url + `?cruise_id=${cruise_id}&&reservation_id=${reservation_id}&&space_id=${space_id}`;
  axios.get(`/${url}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchSpaceFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSpaceFormCustom(booking_id, cruise_id, reservation_id, space_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSpaceFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateSpaceFormCustom,
  requesSpaceFormCustom,
  fetchSpaceFormCustomSuccess,
  fetchSpaceFormCustomError,
} = customSpaceForm.actions;

export default customSpaceForm.reducer;