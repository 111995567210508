import {
  REQUEST_FETCH_GENERAL_RATES,
  FETCH_GENERAL_RATES_SUCCESS,
  FETCH_GENERAL_RATES_ERROR,
} from "../../../actions/rates/generalRates/fetchGeneralRates";

import {
  INITIAL_STATE_GENERAL_RATE,
  REQUEST_FETCH_GENERAL_RATE,
  FETCH_GENERAL_RATE_SUCCESS,
  FETCH_GENERAL_RATE_ERROR,
} from "../../../actions/rates/generalRates/fetchGeneralRate";

import {
  REQUEST_CREATE_GENERAL_RATE,
  CREATE_GENERAL_RATE_SUCCESS,
  CREATE_GENERAL_RATE_ERROR,
} from "../../../actions/rates/generalRates/createGeneralRate";

import {
  REQUEST_UPDATE_GENERAL_RATE,
  UPDATE_GENERAL_RATE_SUCCESS,
  UPDATE_GENERAL_RATE_ERROR,
} from "../../../actions/rates/generalRates/updateGeneralRate";

import {
  REQUEST_ACTIVE_GENERAL_RATE,
  ACTIVE_GENERAL_RATE_SUCCESS,
  ACTIVE_GENERAL_RATE_ERROR,
} from "../../../actions/rates/generalRates/activeGeneralRate";

import {
  REQUEST_INACTIVE_GENERAL_RATE,
  INACTIVE_GENERAL_RATE_SUCCESS,
  INACTIVE_GENERAL_RATE_ERROR,
} from "../../../actions/rates/generalRates/inactiveGeneralRate";

import {
  REQUEST_DELETE_GENERAL_RATE,
  DELETE_GENERAL_RATE_SUCCESS,
  DELETE_GENERAL_RATE_ERROR,
} from "../../../actions/rates/generalRates/deleteGeneralRate";

const initialState = {
  generalRates: [],
  generalRate: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const generalRateReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_GENERAL_RATE: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_GENERAL_RATES: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_GENERAL_RATES_SUCCESS: {
      return {
        ...state,
        generalRates: action.generalRates,
        generalRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_GENERAL_RATES_ERROR: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_GENERAL_RATE: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_GENERAL_RATE_SUCCESS: {
      return {
        ...state,
        generalRates: [],
        generalRate: action.generalRate,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_GENERAL_RATE_ERROR: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_GENERAL_RATE: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_GENERAL_RATE_SUCCESS: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_GENERAL_RATE_ERROR: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_GENERAL_RATE: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_GENERAL_RATE_SUCCESS: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_GENERAL_RATE_ERROR: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_ACTIVE_GENERAL_RATE: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case ACTIVE_GENERAL_RATE_SUCCESS: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case ACTIVE_GENERAL_RATE_ERROR: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_INACTIVE_GENERAL_RATE: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case INACTIVE_GENERAL_RATE_SUCCESS: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case INACTIVE_GENERAL_RATE_ERROR: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_GENERAL_RATE: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_GENERAL_RATE_SUCCESS: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_GENERAL_RATE_ERROR: {
      return {
        ...state,
        generalRates: [],
        generalRate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default generalRateReducer;
