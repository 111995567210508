import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class HotelierService {
  static getHoteliers(searchWord) {
    var url = "hoteliers";
    url = url + `?searchWord=${searchWord}`;
    return axios.get(url);
  }
  static getHotelier(hotelier_id) {
    return axios.get("hoteliers/" + hotelier_id);
  }

  static postHotelier(payload) {
    return axios.post("hoteliers", payload);
  }

  static putHotelier(hotelier_id, payload) {
    return axios.put("hoteliers/" + hotelier_id, payload);
  }

  static deleteHotelier(hotelier_id) {
    return axios.delete("hoteliers/" + hotelier_id);
  }
}
