import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createLanguage } from "../../../redux/actions/catalogs/language/createLanguage";
import { fetchLanguage, initialStateLanguage } from "../../../redux/actions/catalogs/language/fetchLanguage";
import { updateLanguage } from "../../../redux/actions/catalogs/language/updateLanguage";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function LanguageForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { language_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const languageState = useSelector((state) => state.languageReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (language_id) {
      dispatch(fetchLanguage(language_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateLanguage());
    };
  }, []);

  if (languageState.fetching) {
    return <Loader active inline />;
  }

  if (languageState.error) {
    return <Message negative
      header='Error!'
      content={t("server_error_form")}
    />;
  }

  const onSubmit = (data) => {
    if (language_id) {
      dispatch(updateLanguage(language_id, data));
    } else {
      dispatch(createLanguage(data));
    }
  };

  if (languageState.message === "resource created successfully" || languageState.message === "resource updated successfully") {
    history.push("/languages");
  }

  return (
    <>
      <Header as='h3' dividing>{t("lang_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("lang_name")}</label>
            <input name="name" defaultValue={languageState.language.name} placeholder={t("lang_name")} maxLength={45} ref={register({ required: true })} autoFocus/>
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("lang_initials")}</label>
            <input name="short" defaultValue={languageState.language.short} placeholder={t("lang_initials")} maxLength={45} ref={register({ required: true })} />
            {errors.short && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />
        
        <Button primary type="submit" loading={languageState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/languages"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
