import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import NoteService from "../../../../services/catalogs/NoteService";

export const REQUEST_DELETE_NOTE = "REQUEST_DELETE_NOTE";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_ERROR = "DELETE_NOTE_ERROR";

function requestDeleteNote() {
  return {
    type: REQUEST_DELETE_NOTE,
  };
}

function deleteNoteSuccess(json) {
  return {
    type: DELETE_NOTE_SUCCESS,
    message: json.data,
  };
}

function deleteNoteError(json) {
  return {
    type: DELETE_NOTE_ERROR,
    error: json.data,
  };
}

export function deleteNote(note_id) {
  return (dispatch) => {
    dispatch(requestDeleteNote());
    return NoteService.deleteNote(note_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteNoteSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteNote(note_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteNoteError(json.data));
      }
    });
  };
}
