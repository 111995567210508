import CruiseRateService from "../../../../services/reservations/CruiseRateService";

export const REQUEST_UPDATE_CRUISE_RATE = "REQUEST_UPDATE_CRUISE_RATE";
export const UPDATE_CRUISE_RATE_SUCCESS = "UPDATE_CRUISE_RATE_SUCCESS";
export const UPDATE_CRUISE_RATE_ERROR = "UPDATE_CRUISE_RATE_ERROR";

function requestUpdateCruiseRate() {
  return {
    type: REQUEST_UPDATE_CRUISE_RATE,
  };
}

function updateCruiseRateSuccess(json) {
  return {
    type: UPDATE_CRUISE_RATE_SUCCESS,
    message: json.data,
  };
}

function updateCruiseRateError(json) {
  return {
    type: UPDATE_CRUISE_RATE_ERROR,
    error: json.data,
  };
}

export function updateCruiseRate(cruise_id, id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateCruiseRate());
    return CruiseRateService.putCruiseRate(cruise_id, id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(updateCruiseRateSuccess(json.data));
        } else {
          dispatch(updateCruiseRateError(json.data));
        }
      }
    );
  };
}
