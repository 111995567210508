import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Icon, Image, Loader, Table, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { fetchBerths } from "../../../redux/actions/catalogs/berth/fetchBerths";
import { deleteBerth } from "../../../redux/actions/catalogs/berth/deleteBerth";

// Resources
import { scrollToTop } from "../../resources/utils";
import single_bed from "../../icons/single_bed.png";
import double_bed from "../../icons/double_bed.png";
import bunk from "../../icons/bunk.png";

export default function BerthList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const berthState = useSelector((state) => state.berthReducer);
 
  useEffect(() => {
    dispatch(fetchBerths());
    scrollToTop();
  }, []);

  if (berthState.fetching || berthState.processing) {
    return <Loader active inline />;
  }

  if (berthState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (berthState.message === "resource deleted successfully") {
    dispatch(fetchBerths());
  }

  const handledeleteBerth = (berth_id) => {
    dispatch(deleteBerth(berth_id));
  };

  return (
    <>
     <HeaderCatalog title={t("berth_title")} message={berthState.message} to="/berths_new" />
     <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("berth_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("berth_capacity")}</Table.HeaderCell>
            <Table.HeaderCell>{t("berth_icon")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {berthState.berths.map((berth) => (
          <Table.Row key={berth.id}>
            <Table.Cell>{t(berth.name)}</Table.Cell>
            <Table.Cell>{berth.capacity}</Table.Cell>
            <Table.Cell>
              {berth.icon === "single_bed.png" ? (
                <Image src={single_bed} size="mini" />
              ) : berth.icon === "double_bed.png" ? (
                <Image src={double_bed} size="mini" />
              ) : berth.icon === "bunk.png" ? (
                <Image src={bunk} size="mini" />
              ) : (
                ""
              )}
            </Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/berths/${berth.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("berth_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handledeleteBerth(berth.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
