import {
  INITIAL_STATE_CRUISES,
  REQUEST_FETCH_CRUISES,
  FETCH_CRUISES_SUCCESS,
  FETCH_CRUISES_ERROR,
} from "../../../actions/b2b/availability/fetchCruises";

import {
  REQUEST_CREATE_AVAILABILITYB2B,
  CREATE_AVAILABILITYB2B_SUCCESS,
  CREATE_AVAILABILITYB2B_ERROR,
} from "../../../actions/b2b/availability/createAvailabilityB2b";

import {
  REQUEST_CREATE_AVAILABILITYB2B_CHARTER,
  CREATE_AVAILABILITYB2B_CHARTER_SUCCESS,
  CREATE_AVAILABILITYB2B_CHARTER_ERROR,
} from "../../../actions/b2b/availability/createAvailabilityB2bCharter";

import {
  REQUEST_CREATE_DIRECT_CLIENT,
  CREATE_DIRECT_CLIENT_SUCCESS,
  CREATE_DIRECT_CLIENT_ERROR,
} from "../../../actions/b2b/availability/createAvailabilityClient";

const initialState = {
  cruises: [],
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const availabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_CRUISES: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_CRUISES: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISES_SUCCESS: {
      return {
        ...state,
        cruises: action.cruises,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_CRUISES_ERROR: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_AVAILABILITYB2B: {
      return {
        ...state,
        cruises: [],
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_AVAILABILITYB2B_SUCCESS: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_AVAILABILITYB2B_ERROR: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_AVAILABILITYB2B_CHARTER: {
      return {
        ...state,
        cruises: [],
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_AVAILABILITYB2B_CHARTER_SUCCESS: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_AVAILABILITYB2B_CHARTER_ERROR: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_DIRECT_CLIENT: {
      return {
        ...state,
        cruises: [],
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_DIRECT_CLIENT_SUCCESS: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_DIRECT_CLIENT_ERROR: {
      return {
        ...state,
        cruises: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default availabilityReducer;
