import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Breadcrumb, Divider, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { fetchCabins } from "../../../redux/actions/catalogs/cabin/fetchCabins";
import { fetchYacht, initialStateYacht } from "../../../redux/actions/catalogs/yacht/fetchYacht";
import { deleteCabin } from "../../../redux/actions/catalogs/cabin/deleteCabin";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function CabinList() {
  const { t } = useTranslation();
  let { yacht_id } = useParams();
  const cabinState = useSelector((state) => state.cabinReducer);
  const yachtState = useSelector((state) => state.yachtReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchYacht(yacht_id));
    dispatch(fetchCabins(yacht_id));
    scrollToTop();
    return () => {
      dispatch(initialStateYacht());
    };
  }, [dispatch, yacht_id]);

  if (cabinState.fetching || cabinState.processing || yachtState.fetching) {
    return <Loader active inline />;
  }

  if (cabinState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (cabinState.message === "resource deleted successfully") {
    dispatch(fetchCabins(yacht_id));
  }

  const handleDeleteCabin = (id) => {
    dispatch(deleteCabin(id));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/yachts" className="section">{t("yacht_title")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{yachtState.yacht.name}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <HeaderCatalog title={t("cabin_title")} message={cabinState.message} to={`/yacht/${yacht_id}/cabins_new`} />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("cabin_cabin")}</Table.HeaderCell>
            <Table.HeaderCell>{t("cabin_level")}</Table.HeaderCell>
            <Table.HeaderCell>{t("cabin_berths")}</Table.HeaderCell>
            <Table.HeaderCell>{t("cabin_capacity")}</Table.HeaderCell>
            <Table.HeaderCell>{t("cabin_familyRoom")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {cabinState.cabins.map((cabin) => (
          <Table.Row key={cabin.id}>
            <Table.Cell collapsing><ActiveIcon active={cabin.active} /></Table.Cell>
            <Table.Cell>{t(cabin.name)}</Table.Cell>
            <Table.Cell>{t(cabin.deck_name)}</Table.Cell>
            <Table.Cell>
              {cabin.cabin_berths.length >= 1 ? (
                <Link to={`/cabins/${cabin.id}/berths`}>
                   {cabin.cabin_berths.map((berth) => (
                    <div>{berth}</div>
                  ))}
                </Link>
              ) : (
                <Link to={`/cabins/${cabin.id}/berths`}>
                  <Button circular color='blue' icon='add' size="mini" />
                </Link>
              )}
            </Table.Cell>
            <Table.Cell>{cabin.availability}</Table.Cell>
            <Table.Cell>{cabin.family_room_cabin}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/yacht/${yacht_id}/cabin/${cabin.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("cabin_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteCabin(cabin.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
