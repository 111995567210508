import TipTopList from "../containers/reservations/cruise/TipTop/TipTopList";
import TipTopForm from "../containers/reservations/cruise/TipTop/TipTopForm";
import ReservationList from "../containers/reservations/cruise/reservation/ReservationList";

const routes = [
  {
    path: "/",
    component: TipTopList,
  },
  {
    path: "/cruises/cruises_new",
    component: TipTopForm,
  },
  {
    path: "/cruises/:cruise_id",
    component: TipTopForm,
  },
  {
    path: "/cruises/:cruise_id/reservations",
    component: ReservationList,
  },
];

export { routes as cruiseTipTopRoutes };
