import CharterRateService from "../../../../services/rates/CharterRateService";

export const REQUEST_FETCH_CHARTER_RATE = "REQUEST_FETCH_CHARTER_RATE";
export const FETCH_CHARTER_RATE_SUCCESS = "FETCH_CHARTER_RATE_SUCCESS";
export const FETCH_CHARTER_RATE_ERROR = "FETCH_CHARTER_RATE_ERROR";
export const INITIAL_STATE_CHARTER_RATE = "INITIAL_STATE_CHARTER_RATE";

function requestFetchCharterRate() {
  return {
    type: REQUEST_FETCH_CHARTER_RATE,
  };
}

function fetchCharterRateSuccess(json) {
  return {
    type: FETCH_CHARTER_RATE_SUCCESS,
    charterRate: json.data,
  };
}

function fetchCharterRateError(json) {
  return {
    type: FETCH_CHARTER_RATE_ERROR,
    error: json.data,
  };
}

export function initialStateCharterRate() {
  return {
    type: INITIAL_STATE_CHARTER_RATE,
  };
}

export function fetchCharterRate(charterRate_id) {
  return (dispatch) => {
    dispatch(requestFetchCharterRate());
    return CharterRateService.getCharterRate(charterRate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCharterRateSuccess(json.data));
      } else {
        dispatch(fetchCharterRateError(json.data));
      }
    });
  };
}
