import AvailableCabinService from "../../../../services/reservations/AvailableCabinService";

export const REQUEST_UPDATE_TWO_CABINS = "REQUEST_UPDATE_TWO_CABINS";
export const UPDATE_TWO_CABINS_SUCCESS = "UPDATE_TWO_CABINS_SUCCESS";
export const UPDATE_TWO_CABINS_ERROR = "UPDATE_TWO_CABINS_ERROR";

function requestUpdateTwoCabins() {
  return {
    type: REQUEST_UPDATE_TWO_CABINS,
  };
}

function updateTwoCabinsSuccess(json) {
  return {
    type: UPDATE_TWO_CABINS_SUCCESS,
    message: json.data,
  };
}

function updateTwoCabinsError(json) {
  return {
    type: UPDATE_TWO_CABINS_ERROR,
    error: json.data,
  };
}

export function updateTwoCabins(payload) {
  return (dispatch) => {
    dispatch(requestUpdateTwoCabins());
    return AvailableCabinService.updateTwoCabins(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateTwoCabinsSuccess(json.data));
      } else {
        dispatch(updateTwoCabinsError(json.data));
      }
    });
  };
}
