import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import SpaceService from "../../../../services/reservations/SpaceService";

export const REQUEST_FETCH_SPACES = "REQUEST_FETCH_SPACES";
export const FETCH_SPACES_SUCCESS = "FETCH_SPACES_SUCCESS";
export const FETCH_SPACES_ERROR = "FETCH_SPACES_ERROR";

function requestFetchSpaces() {
  return {
    type: REQUEST_FETCH_SPACES,
  };
}

function fetchSpacesSuccess(json) {
  return {
    type: FETCH_SPACES_SUCCESS,
    spaces: json.data,
  };
}

function fetchSpacesError(json) {
  return {
    type: FETCH_SPACES_ERROR,
    error: json.data,
  };
}

export function fetchSpaces(booking_id) {
  return (dispatch) => {
    dispatch(requestFetchSpaces());
    return SpaceService.getSpaces(booking_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchSpacesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSpaces(booking_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSpacesError(json.data));
      }
    });
  };
}
