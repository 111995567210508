import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GeneralRateService from "../../../../services/rates/GeneralRateService";

export const REQUEST_FETCH_GENERAL_RATE = "REQUEST_FETCH_GENERAL_RATE";
export const FETCH_GENERAL_RATE_SUCCESS = "FETCH_GENERAL_RATE_SUCCESS";
export const FETCH_GENERAL_RATE_ERROR = "FETCH_GENERAL_RATE_ERROR";
export const INITIAL_STATE_GENERAL_RATE = "INITIAL_STATE_GENERAL_RATE";

function requestFetchGeneralRate() {
  return {
    type: REQUEST_FETCH_GENERAL_RATE,
  };
}

function fetchGeneralRateSuccess(json) {
  return {
    type: FETCH_GENERAL_RATE_SUCCESS,
    generalRate: json.data,
  };
}

function fetchGeneralRateError(json) {
  return {
    type: FETCH_GENERAL_RATE_ERROR,
    error: json.data,
  };
}

export function initialStateGeneralRate() {
  return {
    type: INITIAL_STATE_GENERAL_RATE,
  };
}

export function fetchGeneralRate(generalRate_id) {
  return (dispatch) => {
    dispatch(requestFetchGeneralRate());
    return GeneralRateService.getGeneralRate(generalRate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchGeneralRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGeneralRate(generalRate_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGeneralRateError(json.data));
      }
    });
  };
}
