import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import RateService from "../../../../services/catalogs/RateService";

export const REQUEST_FETCH_RATE = "REQUEST_FETCH_RATE";
export const FETCH_RATE_SUCCESS = "FETCH_RATE_SUCCESS";
export const FETCH_RATE_ERROR = "FETCH_RATE_ERROR";
export const INITIAL_STATE_RATE = "INITIAL_STATE_RATE";

function requestFetchRate() {
  return {
    type: REQUEST_FETCH_RATE,
  };
}

function fetchRateSuccess(json) {
  return {
    type: FETCH_RATE_SUCCESS,
    rate: json.data,
  };
}

function fetchRateError(json) {
  return {
    type: FETCH_RATE_ERROR,
    error: json.data,
  };
}

export function initialStateRate() {
  return {
    type: INITIAL_STATE_RATE,
  };
}

export function fetchRate(rate_id) {
  return (dispatch) => {
    dispatch(requestFetchRate());
    return RateService.getRate(rate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchRate(rate_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchRateError(json.data));
      }
    });
  };
}
