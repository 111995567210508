import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FamilyRoomService from "../../../../services/rates/FamilyRoomService";

export const REQUEST_FETCH_CABINS = "REQUEST_FETCH_CABINS";
export const FETCH_CABINS_SUCCESS = "FETCH_CABINS_SUCCESS";
export const FETCH_CABINS_ERROR = "FETCH_CABINS_ERROR";
export const INITIAL_STATE_CABIN = "INITIAL_STATE_CABIN";

function requestFetchCabins() {
  return {
    type: REQUEST_FETCH_CABINS,
  };
}

function fetchCabinsSuccess(json) {
  return {
    type: FETCH_CABINS_SUCCESS,
    cabins: json.data,
  };
}

function fetchCabinsError(json) {
  return {
    type: FETCH_CABINS_ERROR,
    error: json.data,
  };
}

export function initialStateCabin() {
  return {
    type: INITIAL_STATE_CABIN,
  };
}

export function fetchCabinsByYacht(yacht_id) {
  return (dispatch) => {
    dispatch(requestFetchCabins());
    return FamilyRoomService.getCabinsByYacht(yacht_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCabinsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCabinsByYacht(yacht_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCabinsError(json.data));
      }
    });
  };
}
