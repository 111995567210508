import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IkarusRateService from "../../../../services/rates/IkarusRateService";

export const REQUEST_FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE = "REQUEST_FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE";
export const FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE_SUCCESS = "FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE_SUCCESS";
export const FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE_ERROR = "FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE_ERROR";
export const INITIAL_STATE_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE = "INITIAL_STATE_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE";

function requestFindIkarusRateByCruiseAgencyContractType() {
  return {
    type: REQUEST_FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE,
  };
}

function findIkarusRateByCruiseAgencyContractTypeSuccess(json) {
  return {
    type: FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE_SUCCESS,
    ikarusRates: json.data,
  };
}

function findIkarusRateByCruiseAgencyContractTypeError(json) {
  return {
    type: FIND_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE_ERROR,
    error: json.data,
  };
}

export function initialStateIkarusRateByCruiseAgencyContractType() {
  return {
    type: INITIAL_STATE_IKARUS_RATE_BY_CRUISE_AGENCY_CRUISETYPE,
  };
}

export function findIkarusRateByCruiseAgencyContractType(cruise_id, payload) {
  return (dispatch) => {
    dispatch(requestFindIkarusRateByCruiseAgencyContractType());
    return IkarusRateService.findIkarusRateByCruiseAgencyCruiseType(cruise_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(findIkarusRateByCruiseAgencyContractTypeSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(findIkarusRateByCruiseAgencyContractType(cruise_id, payload));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(findIkarusRateByCruiseAgencyContractTypeError(json.data));
      }
    });
  };
}
