import CruiseLanguageList from "../containers/reservations/cruiseLanguage/CruiseLanguageList";
import CruiseLanguageForm from "../containers/reservations/cruiseLanguage/CruiseLanguageForm";

const routes = [
  {
    path: "/cruises/:cruise_id/languages",
    component: CruiseLanguageList,
  },
  {
    path: "/cruises/:cruise_id/languages_new",
    component: CruiseLanguageForm,
  },
  {
    path: "/cruises/:cruise_id/languages/:id",
    component: CruiseLanguageForm,
  },
];

export { routes as cruiseLanguageRoutes };
