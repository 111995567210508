import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Breadcrumb, Divider, Message} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { initialStateCabin } from "../../../redux/actions/catalogs/cabin/fetchCabin";
import { deleteCabinBerth } from "../../../redux/actions/catalogs/cabinBerth/deleteCabinBerth";
import { fetchCabinBerthListCustom, initialStateCabinBerthListCustom } from "../../../redux/slices/custom/catalogs/cabinBerth/customCabinBerthList.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function CabinBerthList() {
  let { cabin_id } = useParams();
  const { t } = useTranslation();
  
  const cabinBerthStateCustom = useSelector((state) => state.customCabinBerthList);
  const cabinBerthState = useSelector((state) => state.cabinBerthReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCabinBerthListCustom(cabin_id));
    scrollToTop();
    return () => {
      dispatch(initialStateCabin());
      dispatch(initialStateCabinBerthListCustom)
    };
  }, []);

  if (cabinBerthState.fetching || cabinBerthStateCustom.fetching) {
    return <Loader active inline />;
  }

  if (cabinBerthStateCustom.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (cabinBerthState.message === "resource deleted successfully") {
    dispatch(fetchCabinBerthListCustom(cabin_id));
    dispatch(initialStateCabin());
  }

  const handleDeleteCabinBerth = (id) => {
    dispatch(deleteCabinBerth(cabin_id, id));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/yachts" className="section">{t("yacht_title")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section >
          <Link to={`/yacht/${cabinBerthStateCustom.cabin.yacht_id}/cabins`} className="section">{cabinBerthStateCustom.cabin.yacht_name}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{cabinBerthStateCustom.cabin.name}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <HeaderCatalog title={t("cabinBerth_title")} message={cabinBerthStateCustom.message} to={`/cabins/${cabin_id}/berths_new`} />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("cabinBerth_name")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {cabinBerthStateCustom.cabinBerths.map((cabinItem) =>
            cabinItem.berths.map((cabinBerth) => (
              <Table.Row key={cabinBerth.id}>
                <Table.Cell>{t(cabinBerth.berth_name)}</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell collapsing>
                  <Link to={`/cabins/${cabin_id}/berths/${cabinBerth.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
                  <Modal
                    trigger={
                      <Button circular color='red' icon='trash alternate' />
                    }
                    content={t("cabinBerth_delete")}
                    actions={[
                      t("canceled_button"),
                        {
                          key: "eliminar",
                          content: t("delete_button"),
                          className: "delete_button icon_color",
                          onClick: () => handleDeleteCabinBerth(cabinBerth.id),
                        },
                    ]}
                    size="tiny"
                  />
                </Table.Cell>
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </>
  );
}
