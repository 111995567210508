import jsPDF from "jspdf";
import "jspdf-autotable";

export default function generateZarpeReport(passengers, cruise) {
  var doc = new jsPDF("p", "pt");

  // HEADER

  doc.text(cruise.short + ": " + cruise.yacht_name, 30, 50);

  // BODY

  var columnsBody = ["", "Nombre", "Identificación", "Nacionalidad"];
  var rowsBody = [];

  passengers.map(function (passenger, index) {
    var passengerRow = [
      index + 1,
      passenger.last_name + " / " + passenger.first_name,
      passenger.identification,
      passenger.country,
    ];
    rowsBody.push(passengerRow);
    return 0;
  });

  doc.autoTable(columnsBody, rowsBody, {
    startY: 70,
    headStyles: {
      fillColor: [28, 132, 198],
    },
  });

  // GENERATE PDF
  doc.save("Zarpe.pdf");
}
