import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CruiseLanguageService from "../../../../services/reservations/CruiseLanguageService";

export const REQUEST_FETCH_CRUISE_LANGUAGE = "REQUEST_FETCH_CRUISE_LANGUAGE";
export const FETCH_CRUISE_LANGUAGE_SUCCESS = "FETCH_CRUISE_LANGUAGE_SUCCESS";
export const FETCH_CRUISE_LANGUAGE_ERROR = "FETCH_CRUISE_LANGUAGE_ERROR";
export const INITIAL_STATE_CRUISE_LANGUAGE = "INITIAL_STATE_CRUISE_LANGUAGE";

function requestFetchCrusieLanguage() {
  return {
    type: REQUEST_FETCH_CRUISE_LANGUAGE,
  };
}

function fetchCruiseLanguageSuccess(json) {
  return {
    type: FETCH_CRUISE_LANGUAGE_SUCCESS,
    cruiseLanguage: json.data,
  };
}

function fetchCruiseLanguageError(json) {
  return {
    type: FETCH_CRUISE_LANGUAGE_ERROR,
    error: json.data,
  };
}

export function initialStateCruiseLanguage() {
  return {
    type: INITIAL_STATE_CRUISE_LANGUAGE,
  };
}

export function fetchCruiseLanguage(cruise_id, id) {
  return (dispatch) => {
    dispatch(requestFetchCrusieLanguage());
    return CruiseLanguageService.getCruiseLanguage(cruise_id, id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchCruiseLanguageSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchCruiseLanguage(cruise_id, id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchCruiseLanguageError(json.data));
        }
      }
    );
  };
}
