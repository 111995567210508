import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import PassengerService from "../../../../services/reservations/PassengerService";

export const REQUEST_FETCH_PASSENGERS_IN_FLIGHT =
  "REQUEST_FETCH_PASSENGERS_IN_FLIGHT";
export const FETCH_PASSENGERS_IN_FLIGHT_SUCCESS =
  "FETCH_PASSENGERS_IN_FLIGHT_SUCCESS";
export const FETCH_PASSENGERS_IN_FLIGHT_ERROR =
  "FETCH_PASSENGERS_IN_FLIGHT_ERROR";
export const INITIAL_STATE_PASSENGER_IN_FLIGHT =
  "INITIAL_STATE_PASSENGER_IN_FLIGHT";

function requestFetchPassengersInFlight() {
  return {
    type: REQUEST_FETCH_PASSENGERS_IN_FLIGHT,
  };
}

function fetchPassengersInFlightSuccess(json) {
  return {
    type: FETCH_PASSENGERS_IN_FLIGHT_SUCCESS,
    passengers: json.data,
  };
}

function fetchPassengersInFlightError(json) {
  return {
    type: FETCH_PASSENGERS_IN_FLIGHT_ERROR,
    error: json.data,
  };
}

export function initialStatePassengerInFlight() {
  return {
    type: INITIAL_STATE_PASSENGER_IN_FLIGHT,
  };
}

export function fetchPassengersInFlight(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchPassengersInFlight());
    return PassengerService.getPassengersInFlight(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchPassengersInFlightSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchPassengersInFlight(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchPassengersInFlightError(json.data));
      }
    });
  };
}
