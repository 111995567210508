import React from "react";
import GenderIcon from "../../components/reservation/genderIcon";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

export default function PassengerCellCanceledSpace(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <>
            <GenderIcon gender={props.space.gender_name} />
            {props.space.last_name} {props.space.first_name}
            <br></br>
            <span className="text_date_format">{t(props.space.passenger_nationality)}</span>
        </>
    )
}