import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IdentificationTypeService from "../../../../services/catalogs/IdentificationTypeService";

export const REQUEST_DELETE_IDENTIFICATION_TYPE =
  "REQUEST_DELETE_IDENTIFICATION_TYPE";
export const DELETE_IDENTIFICATION_TYPE_SUCCESS =
  "DELETE_IDENTIFICATION_TYPE_SUCCESS";
export const DELETE_IDENTIFICATION_TYPE_ERROR =
  "DELETE_IDENTIFICATION_TYPE_ERROR";

function requestDeleteIdentificationType() {
  return {
    type: REQUEST_DELETE_IDENTIFICATION_TYPE,
  };
}

function deleteIdentificationTypeSuccess(json) {
  return {
    type: DELETE_IDENTIFICATION_TYPE_SUCCESS,
    message: json.data,
  };
}

function deleteIdentificationTypeError(json) {
  return {
    type: DELETE_IDENTIFICATION_TYPE_ERROR,
    error: json.data,
  };
}

export function deleteIdentificationType(identification_type_id) {
  return (dispatch) => {
    dispatch(requestDeleteIdentificationType());
    return IdentificationTypeService.deleteIdentificationType(
      identification_type_id
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteIdentificationTypeSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteIdentificationType(identification_type_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteIdentificationTypeError(json.data));
      }
    });
  };
}
