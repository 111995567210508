import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IkarusRateCabinService from "../../../../services/rates/IkarusRateCabinService";

export const REQUEST_FETCH_IKARUS_RATE_CABINS = "REQUEST_FETCH_IKARUS_RATE_CABINS";
export const FETCH_IKARUS_RATE_CABINS_SUCCESS = "FETCH_IKARUS_RATE_CABINS_SUCCESS";
export const FETCH_IKARUS_RATE_CABINS_ERROR = "FETCH_IKARUS_RATE_CABINS_ERROR";

function requestFetchIkarusRateCabins() {
  return {
    type: REQUEST_FETCH_IKARUS_RATE_CABINS,
  };
}

function fetchIkarusRateCabinsSuccess(json) {
  return {
    type: FETCH_IKARUS_RATE_CABINS_SUCCESS,
    ikarusRateCabins: json.data,
  };
}

function fetchIkarusRateCabinsError(json) {
  return {
    type: FETCH_IKARUS_RATE_CABINS_ERROR,
    error: json.data,
  };
}

export function fetchIkarusRatesCabin(ikarusRate_id) {
  return (dispatch) => {
    dispatch(requestFetchIkarusRateCabins());
    return IkarusRateCabinService.getIkarusRateCabins(ikarusRate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchIkarusRateCabinsSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchIkarusRatesCabin(ikarusRate_id));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(fetchIkarusRateCabinsError(json.data));
      }
    });
  };
}
