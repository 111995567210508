import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import OrderService from "../../../../services/reservations/OrderService";

export const REQUEST_FETCH_ORDER_SERVICE = "REQUEST_FETCH_ORDER_SERVICE";
export const FETCH_ORDER_SERVICE_SUCCESS = "FETCH_ORDER_SERVICE_SUCCESS";
export const FETCH_ORDER_SERVICE_ERROR = "FETCH_ORDER_SERVICE_ERROR";
export const INITIAL_STATE_ORDER_SERVICE = "INITIAL_STATE_ORDER_SERVICE";

function requestFetchOrderService() {
  return {
    type: REQUEST_FETCH_ORDER_SERVICE,
  };
}

function fetchOrderServiceSuccess(json) {
  return {
    type: FETCH_ORDER_SERVICE_SUCCESS,
    orderService: json.data,
    message: json.message,
  };
}

function fetchOrderServiceError(json) {
  return {
    type: FETCH_ORDER_SERVICE_ERROR,
    error: json.data,
  };
}

export function initialStateOrderService() {
  return {
    type: INITIAL_STATE_ORDER_SERVICE,
  };
}

export function fetchOrderService(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchOrderService());
    return OrderService.getOrderService(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchOrderServiceSuccess(json.data, json.message));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchOrderService(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchOrderServiceError(json.data));
      }
    });
  };
}
