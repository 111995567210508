import LanguageList from "../containers/catalogs/language/LanguageList";
import LanguageForm from "../containers/catalogs/language/LanguageForm";

const routes = [
  {
    path: "/languages",
    component: LanguageList,
  },
  {
    path: "/languages_new",
    component: LanguageForm,
  },
  {
    path: "/languages/:language_id",
    component: LanguageForm,
  },
];

export { routes as languageRoutes };
