import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FlightService from "../../../../services/catalogs/FlightService";

export const REQUEST_FETCH_FLIGHTS = "REQUEST_FETCH_FLIGHTS";
export const FETCH_FLIGHTS_SUCCESS = "FETCH_FLIGHTS_SUCCESS";
export const FETCH_FLIGHTS_ERROR = "FETCH_FLIGHTS_ERROR";

function requestFetchFlights() {
  return {
    type: REQUEST_FETCH_FLIGHTS,
  };
}

function fetchFlightsSuccess(json) {
  return {
    type: FETCH_FLIGHTS_SUCCESS,
    flights: json.data,
  };
}

function fetchFlightsError(json) {
  return {
    type: FETCH_FLIGHTS_ERROR,
    error: json.data,
  };
}

export function fetchFlights(searchWord) {
  return (dispatch) => {
    dispatch(requestFetchFlights());
    return FlightService.getFlights(searchWord).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchFlightsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchFlights(searchWord));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchFlightsError(json.data));
      }
    });
  };
}
