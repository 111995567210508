import HistoricalCruiseService from "../../../../services/reservations/HistoricalCruiseService";

export const REQUEST_FETCH_HISTORICAL_YEARS = "REQUEST_FETCH_HISTORICAL_YEARS";
export const FETCH_HISTORICAL_YEARS_SUCCESS = "FETCH_HISTORICAL_YEARS_SUCCESS";
export const FETCH_HISTORICAL_YEARS_ERROR = "FETCH_HISTORICAL_YEARS_ERROR";

function requestFetchHistoricalYears() {
  return {
    type: REQUEST_FETCH_HISTORICAL_YEARS,
  };
}

function fetchHistoricalYearsSuccess(json) {
  return {
    type: FETCH_HISTORICAL_YEARS_SUCCESS,
    years: json.data,
  };
}

function fetchHistoricalYearsError(json) {
  return {
    type: FETCH_HISTORICAL_YEARS_ERROR,
    error: json.data,
  };
}

export function fetchHistoricalYears(startYear) {
  return (dispatch) => {
    dispatch(requestFetchHistoricalYears());
    return HistoricalCruiseService.getHistoricalYears(startYear).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchHistoricalYearsSuccess(json.data));
        } else {
          dispatch(fetchHistoricalYearsError(json.data));
        }
      }
    );
  };
}
