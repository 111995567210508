import BerthList from "../containers/catalogs/berth/BerthList";
import BerthForm from "../containers/catalogs/berth/BerthForm";

const routes = [
  {
    path: "/berths",
    component: BerthList,
  },
  {
    path: "/berths_new",
    component: BerthForm,
  },
  {
    path: "/berths/:berth_id",
    component: BerthForm,
  },
];

export { routes as berthRoutes };
