import AvailableCabinList from "../containers/reservations/cruise/changeCabin/AvailableCabinList";
import AvailablePassengerList from "../containers/reservations/cruise/changeCabin/AvailablePassengerList";

const routes = [
  {
    path:
      "/cruises/:cruise_id/reservations/:reservation_id/booking/:booking_id/cabin/:cabin_id",
    component: AvailableCabinList,
  },
  {
    path:
      "/cruises/:cruise_id/reservations/:reservation_id/booking/:booking_id/passengers/:passenger_id",
    component: AvailablePassengerList,
  },
];

export { routes as changeCabinRoutes };
