import {
  INITIAL_STATE_IMAGE,
  REQUEST_FETCH_IMAGE,
  FETCH_IMAGE_SUCCESS,
  FETCH_IMAGE_ERROR,
} from "../../../actions/catalogs/itinerary/fetchImage";

import {
  REQUEST_UPDATE_IMAGE,
  UPDATE_IMAGE_SUCCESS,
  UPDATE_IMAGE_ERROR,
} from "../../../actions/catalogs/itinerary/updateImage";

import {
  REQUEST_DELETE_IMAGE,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_ERROR,
} from "../../../actions/catalogs/itinerary/deleteImage";

const initialState = {
  image: "",
  processing: false,
  fetching: false,
  message: "",
  messupdate: "",
  error: "",
};

const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_IMAGE: {
      return {
        ...state,
        image: "",
        processing: false,
        fetching: false,
        message: "",
        messupdate: "",
        error: "",
      };
    }
    case REQUEST_FETCH_IMAGE: {
      return {
        ...state,
        image: "",
        processing: false,
        fetching: true,
        message: "",
        messupdate: "",
        error: "",
      };
    }
    case FETCH_IMAGE_SUCCESS: {
      return {
        ...state,
        image: action.image,
        processing: false,
        fetching: false,
        message: "",
        messupdate: "",
        error: "",
      };
    }
    case FETCH_IMAGE_ERROR: {
      return {
        ...state,
        image: "",
        processing: false,
        fetching: false,
        message: "",
        messupdate: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_IMAGE: {
      return {
        ...state,
        image: "",
        processing: true,
        fetching: false,
        message: "",
        messupdate: "",
        error: "",
      };
    }
    case UPDATE_IMAGE_SUCCESS: {
      return {
        ...state,
        image: "",
        processing: false,
        fetching: false,
        message: action.message,
        messupdate: "",
        error: "",
      };
    }
    case UPDATE_IMAGE_ERROR: {
      return {
        ...state,
        image: "",
        processing: false,
        fetching: false,
        message: "",
        messupdate: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_IMAGE: {
      return {
        ...state,
        image: "",
        processing: true,
        fetching: false,
        message: "",
        messupdate: "",
        error: "",
      };
    }
    case DELETE_IMAGE_SUCCESS: {
      return {
        ...state,
        image: "",
        processing: false,
        fetching: false,
        message: "",
        messupdate: action.message,
        error: "",
      };
    }
    case DELETE_IMAGE_ERROR: {
      return {
        ...state,
        image: "",
        processing: false,
        fetching: false,
        message: "",
        messupdate: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default imageReducer;
