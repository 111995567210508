import CharterRateService from "../../../../services/rates/CharterRateService";

export const REQUEST_UPDATE_CHARTER_RATE = "REQUEST_UPDATE_CHARTER_RATE";
export const UPDATE_CHARTER_RATE_SUCCESS = "UPDATE_CHARTER_RATE_SUCCESS";
export const UPDATE_CHARTER_RATE_ERROR = "UPDATE_CHARTER_RATE_ERROR";

function requestUpdateCharterRate() {
  return {
    type: REQUEST_UPDATE_CHARTER_RATE,
  };
}

function updateCharterRateSuccess(json) {
  return {
    type: UPDATE_CHARTER_RATE_SUCCESS,
    message: json.data,
  };
}

function updateCharterRateError(json) {
  return {
    type: UPDATE_CHARTER_RATE_ERROR,
    error: json.data,
  };
}

export function updateCharterRate(generalRate_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateCharterRate());
    return CharterRateService.putCharterRate(generalRate_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateCharterRateSuccess(json.data));
      } else {
        dispatch(updateCharterRateError(json.data));
      }
    });
  };
}
