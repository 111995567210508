import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
//ACRIONS
import { deleteCruise } from "../../../../redux/actions/reservations/cruise/deleteCruise";
import { initialStateCruise } from "../../../../redux/actions/reservations/cruise/fetchCruise";
import { fetchTipTopListCustom, initialStateTipTopListCustom } from "../../../../redux/slices/custom/reservations/cruise/TipTop/customTipTopList.slice";
//UI
import { Table, Segment, Header,  Button, Modal, Icon, Input, Label, Accordion, Loader, Message } from "semantic-ui-react";
import HeaderCatalog from "../../../../components/headerCatalog";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Collapse } from "@material-ui/core";
import "../../../styles/common.css";
import LogsHistory from "../../../../components/reservation/logsHistory";

export default function TipTopList() {
  let actualDate = new Date().getFullYear();
  const { t } = useTranslation();
  const [cruiseCode, setCruiseCode] = useState("");
  const [openHistory, setOpenHistory] = useState(false);
  const [itineraryOrder, setItineraryOrder] = useState("ASC");
  const [codeOrder, setCodeOrder] = useState("ASC");
  const [yachtOrder, setYachtOrder] = useState("ASC");
  const [startDateOrder, setStartDateOrder] = useState("ASC");
  const [endDateOrder, setEndDateOrder] = useState("ASC");
  const [cruiseNameOrder, setCruiseNameOrder] = useState("ASC");


  const [cruiseOperate, setCruiseOperate] = useState(
    localStorage.getItem("operate") !== null
      ? localStorage.getItem("operate")
      : "por operar"
  );
  const [cruiseYear, setCruiseYear] = useState(
    localStorage.getItem("year") !== null
      ? localStorage.getItem("year")
      : actualDate.toString()
  );
  const [cruiseYacht, setCruiseYacht] = useState(
    localStorage.getItem("yacht") !== null
      ? localStorage.getItem("yacht")
      : "all"
  );
  const cruiseCustomState = useSelector((state) => state.customTipTopList);
  const cruiseState = useSelector((state) => state.cruiseReducer);
  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch(fetchTipTopListCustom("", cruiseOperate, cruiseYear, cruiseYacht, "", ""));
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateTipTopListCustom());
    };
  }, [dispatch, actualDate, cruiseOperate, cruiseYear]); 

  if (cruiseCustomState.fetching) {
    return <CircularProgress />;
  }

  if (cruiseCustomState.processing) {
    return <CircularProgress />;
  }

  if (cruiseCustomState.error) {
    return <div>Error: {cruiseCustomState.error}</div>;
  }

  if (cruiseState.message === "resource deleted successfully") {
    dispatch(fetchTipTopListCustom("", cruiseOperate, cruiseYear, cruiseYacht));
    dispatch(initialStateCruise()); 
  }

  const handledeleteCruise = (cruise_id) => {
    dispatch(deleteCruise(cruise_id));
  };

  const handleSeachCruiseCode = () => {
    dispatch(fetchTipTopListCustom(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, "", ""));
  };

  const handleSearchCruiseOperate = (event) => {
    setCruiseCode("");
    dispatch(fetchTipTopListCustom(cruiseCode, event, cruiseYear, cruiseYacht, "", ""));

  };

  const handleSearchCruiseYear = (event) => {
    setCruiseCode("");
    dispatch(fetchTipTopListCustom(cruiseCode, cruiseOperate, event, cruiseYacht, "", ""));
  };

  const handleSearchCruiseYacht = (event) => {
    setCruiseCode("");
    dispatch(fetchTipTopListCustom(cruiseCode, cruiseOperate, cruiseYear, event, "", ""));
  };

  const orderByItinerary = () => {
    setItineraryOrder(itineraryOrder == "ASC" ? "DESC" : "ASC");
    dispatch(fetchTipTopListCustom(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, "itinerary", itineraryOrder));
  };

  const orderByCode = () => {
    setCodeOrder(codeOrder == "ASC" ? "DESC" : "ASC");
    dispatch(fetchTipTopListCustom(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, "code", codeOrder));
  };

  const orderByYacth = () => {
    setYachtOrder(yachtOrder == "ASC" ? "DESC" : "ASC");
    dispatch(fetchTipTopListCustom(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, "yacht", yachtOrder));
  };

  const orderByStartDate = () => {
    setStartDateOrder(startDateOrder == "ASC" ? "DESC" : "ASC");
    dispatch(fetchTipTopListCustom(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, "startDate", startDateOrder));
  };

  const orderByEndDate = () => {
    setEndDateOrder(endDateOrder == "ASC" ? "DESC" : "ASC");
    dispatch(fetchTipTopListCustom(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, "endDate", endDateOrder));
  };

  const orderByCruiseName = () => {
    setCruiseNameOrder(cruiseNameOrder == "ASC" ? "DESC" : "ASC");
    dispatch(fetchTipTopListCustom(cruiseCode, cruiseOperate, cruiseYear, cruiseYacht, "cruiseName", cruiseNameOrder));
  };

  const handleHistoyClick = () => {
    setOpenHistory(!openHistory);
  };

  return (
    <>     
        <Segment clearing> 
          <HeaderCatalog title={t("cruise_title")} message={cruiseCustomState.message} to="/cruises/cruises_new" />
          <Header as='h5' floated='right'>
            <Input type="text" placeholder={t("cruise_code") + "..."} action>
              <input
                defaultValue={cruiseCode}
                onChange={(event) => setCruiseCode(event.target.value)}
              />
              <Button
                type="submit"
                onClick={() => handleSeachCruiseCode()}
                icon="search"
                size="medium"
              />
            </Input>
            &nbsp;
         
            <select
              className="ui selection dropdown"
              aria-expanded="true"
              name="operates"
              defaultValue={cruiseOperate}
              onChange={(event) => {
                handleSearchCruiseOperate(event.target.value);
                setCruiseOperate(event.target.value);
                localStorage.setItem("operate", event.target.value);
              }}
            >
              <option className="selected item" value="operados">
                {t("cruise_operate")}
              </option>
              <option className="selected item" value="por operar">
                {t("cruise_toOperate")}
              </option>
            </select>
            &nbsp;
        
            <select
              className="ui selection dropdown"
              aria-expanded="true"
              name="years"
              value={cruiseYear}
              onChange={(event) => {
                handleSearchCruiseYear(event.target.value);
                setCruiseYear(event.target.value);
                localStorage.setItem("year", event.target.value);
              }}
            >
              { cruiseCustomState.years.map((year) => (
                <option className="selected item" value={year.year}>
                  {year.year}
                </option>
              ))}
            </select>
            &nbsp;
        
            <select name="yacht_id" className="ui selection dropdown" aria-expanded="true"
                defaultValue={cruiseYacht}
                onChange={(event) => {
                  handleSearchCruiseYacht(event.target.value);
                  setCruiseYacht(event.target.value);
                  localStorage.setItem("yacht", event.target.value);
                }}
            >
              <option value="all">{t("all")}</option>
              {cruiseCustomState.yachts.map((yacht) => (
                <option key={yacht.id} value={yacht.id}>{yacht.name}</option>
              ))}
            </select>
            </Header>
         
        </Segment>
        <Table color="blue" striped>
          <Table.Header align="center">
            <Table.Row>
              <Table.HeaderCell>{t("cruise_code")}
                <a href="#"   onClick={() => {orderByCode();}}>  <Icon name='sort' /></a>
                <br></br><br></br>
              </Table.HeaderCell>
              <Table.HeaderCell>{t("cruise_yacht")}
                <a href="#"   onClick={() => {orderByYacth();}}>  <Icon name='sort' /></a>
                <br></br><br></br>
              </Table.HeaderCell>
              <Table.HeaderCell>{t("cruise_startDate")}
                <a href="#"   onClick={() => {orderByStartDate();}}>  <Icon name='sort' /></a>
                <br></br><span className="text_date_format">(dd-mm-yyyy)</span>
              </Table.HeaderCell>
              <Table.HeaderCell>{t("cruise_endDate")} 
                <a href="#"   onClick={() => {orderByEndDate();}}>  <Icon name='sort' /></a>
                <br></br><span className="text_date_format">(dd-mm-yyyy)</span>
              </Table.HeaderCell>
              <Table.HeaderCell>{t("cruise_total")}<br></br><br></br></Table.HeaderCell>
              <Table.HeaderCell>{t("cruise_firstPart")}<br></br><br></br></Table.HeaderCell>
              <Table.HeaderCell>{t("cruise_secondPart")}<br></br><br></br></Table.HeaderCell>
              <Table.HeaderCell>{t("cruise_wait")}<br></br><br></br></Table.HeaderCell>
              <Table.HeaderCell>{t("cruise_name")}
              <a href="#"   onClick={() => {orderByCruiseName();}}>  <Icon name='sort' /></a>
                <br></br><br></br>
                </Table.HeaderCell>
              <Table.HeaderCell>{t("cruise_itinerary")}
                <a href="#"   onClick={() => {orderByItinerary();}}>  <Icon name='sort' /></a>
                <br></br><br></br>
              </Table.HeaderCell>
              <Table.HeaderCell align="center">{t("cruise_guides")}<br></br><br></br></Table.HeaderCell>
              <Table.HeaderCell align="center">{t("cruise_languages")}<br></br><br></br></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body align="center">
            {cruiseCustomState.cruises.map((cruise) => (
              <Table.Row key={cruise.id}>
                <Table.Cell style={{ fontSize: "14px" }}>
                  <Link to={`/cruises/${cruise.id}/cruise-detail`}>
                  <b>{cruise.code}</b>
                  </Link>
                </Table.Cell>
                <Table.Cell style={{ backgroundColor: cruise.yacht_color, fontSize: "11px" }}>{cruise.yacht_name}</Table.Cell>
                <Table.Cell style={{ fontSize: "11px" }}>{cruise.start_date}</Table.Cell>
                <Table.Cell style={{ fontSize: "11px" }}>{cruise.end_date}</Table.Cell>
                <Table.Cell
                  align="center"
                  style={{ backgroundColor: "#FBD75C", fontSize: "11px" }}
                >
                  {cruise.TO}
                </Table.Cell>
                <Table.Cell
                  align="center"
                  style={{ backgroundColor: "#47D6DB", fontSize: "11px" }}
                >
                  {cruise.PP}
                </Table.Cell>
                <Table.Cell
                  align="center"
                  style={{ backgroundColor: "#DBADFF", fontSize: "11px" }}
                >
                  {cruise.SP}
                </Table.Cell>
                <Table.Cell
                  align="center"
                  style={{ backgroundColor: "#7AE7BF", fontSize: "11px" }}
                >
                  {cruise.ESP}
                </Table.Cell>
                <Table.Cell style={{ fontSize: "11px" }}>{cruise.name}</Table.Cell>
                <Table.Cell style={{ fontSize: "11px" }}>{cruise.itinerary_name}</Table.Cell>
                <Table.Cell align="center" style={{ fontSize: "11px" }}>
                  {cruise.guides.length >= 1 ? (
                    <Link to={`/cruises/${cruise.id}/guides`}>
                      {cruise.guides.map((guide) => (
                        <div>{guide}</div>
                      ))}
                    </Link>
                  ) : (
                    <Link to={`/cruises/${cruise.id}/guides`}>
                      <Button className="add_button" icon circular size="mini">
                        <Icon className="icon_color" name="add" />
                      </Button>
                    </Link>
                  )}
                </Table.Cell>
                <Table.Cell align="center" style={{ fontSize: "11px" }}>
                  {cruise.languages.length >= 1 ? (
                    <Link to={`/cruises/${cruise.id}/languages`}>
                      {cruise.languages.map((language) => (
                        <div>{t(language)}</div>
                      ))}
                    </Link>
                  ) : (
                    <Link to={`/cruises/${cruise.id}/languages`}>
                      <Button className="add_button" icon circular size="mini">
                        <Icon className="icon_color" name="add" />
                      </Button>
                    </Link>
                  )}
                </Table.Cell>
                <Table.Cell align="right">
                  <Link to={`/cruises/${cruise.id}`}>
                    <Button circular color='blue' icon='pencil alternate' size="mini" />
                  </Link>
                  <Modal
                    trigger={
                      <Button circular  color='red' icon="trash alternate" size="mini" />
               
                    }
                    content={t("cruise_delete")}
                    actions={[
                      t("canceled_button"),
                      {
                        key: "eliminar",
                        content: t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handledeleteCruise(cruise.id),
                      },
                    ]}
                    size="tiny"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Accordion styled style={{ width: "91vw" }}>
        <Accordion.Title onClick={handleHistoyClick} style={{ display: "flex", gap: "5px" }}>
          {t("log_historical")}
          {openHistory ? <ExpandLess /> : <ExpandMore />}
        </Accordion.Title>
        {cruiseCustomState.fetching  ? (
          <div style={{ display: "flex", width: "91vw", height: "80px", justifyContent: "center" }}>
            <Loader size="medium" active inline>Loading...</Loader>
          </div>
        ) : (
          <Collapse in={openHistory} timeout="auto" unmountOnExit style={{ padding: "15px" }}>
            <LogsHistory
              logsHistory={cruiseCustomState.logs}
            />
          </Collapse>
        )}
      </Accordion>
     
    </>
  );
}
