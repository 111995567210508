import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ItineraryService from "../../../../services/catalogs/ItineraryService";

export const REQUEST_FETCH_ITINERARY_DIVISION =
  "REQUEST_FETCH_ITINERARY_DIVISION";
export const FETCH_ITINERARY_DIVISION_SUCCESS =
  "FETCH_ITINERARY_DIVISION_SUCCESS";
export const FETCH_ITINERARY_DIVISION_ERROR = "FETCH_ITINERARY_DIVISION_ERROR";

function requestFetchItineraryDivision() {
  return {
    type: REQUEST_FETCH_ITINERARY_DIVISION,
  };
}

function fetchItineraryDivisionSuccess(json) {
  return {
    type: FETCH_ITINERARY_DIVISION_SUCCESS,
    message: json.data,
  };
}

function fetchItineraryDivisionError(json) {
  return {
    type: FETCH_ITINERARY_DIVISION_ERROR,
    error: json.data,
  };
}

export function checkItineraryDivision(itinerary_id) {
  return (dispatch) => {
    dispatch(requestFetchItineraryDivision());
    return ItineraryService.checkItineraryDivision(itinerary_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchItineraryDivisionSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(checkItineraryDivision(itinerary_id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchItineraryDivisionError(json.data));
        }
      }
    );
  };
}
