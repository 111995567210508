import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Breadcrumb, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createDay } from "../../../redux/actions/catalogs/day/createDay";
import { initialStateDay } from "../../../redux/actions/catalogs/day/fetchDay";
import { updateDay } from "../../../redux/actions/catalogs/day/updateDay";
import { fetchDayFormCustom, initialStateDayFormCustom }  from "../../../redux/slices/custom/catalogs/day/customDayForm.slice";

// Resources
import { numbers, days } from "../../resources/optionsList";
import { useForm } from "react-hook-form";
import { scrollToTop } from "../../resources/utils";

export default function DayForm() {
  let history = useHistory();
  let { itinerary_id } = useParams();
  let { day_id } = useParams();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const dayStateCustom = useSelector((state) => state.customDayForm);
  const dayState = useSelector((state) => state.dayReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDayFormCustom(itinerary_id, day_id));
    scrollToTop();
    return () => {
      dispatch(initialStateDay());
      dispatch(initialStateDayFormCustom());
    };
  }, []);

  if (dayStateCustom.fetching || dayState.fetching) {
    return <Loader active inline />;
  }

  if (dayStateCustom.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (day_id) {
      dispatch(updateDay(day_id, data));
    } else {
      dispatch(createDay(itinerary_id, data));
    }
  };

  if (dayState.message === "resource created successfully" || dayState.message === "resource updated successfully") {
    history.go(-1);
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section><Link to="/itineraries" className="section">{t("itinerary_title")}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section><Link to={`/itinerary/${itinerary_id}/days`} className="section">{dayStateCustom.itinerary.name}</Link></Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{t("day_name")}</Breadcrumb.Section>
      </Breadcrumb>
      <Divider variant="fullWidth" />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("day_number")}</label>
            <select name="number" defaultValue={dayStateCustom.day.number} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {numbers.map((number, index) => (
                <option key={index} value={number}>{number}</option>
              ))}
            </select>
            {errors.number && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("day_name")}</label>
            <select name="name" defaultValue={dayStateCustom.day.name} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {days.map((day, index) => (
                <option key={index} value={day}>{t(day)}</option>
              ))}
            </select>
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("day_am")}</label>
            <input name="am" defaultValue={dayStateCustom.day.am} placeholder={t("day_am")} maxLength={500} ref={register({ required: true })} />
            {errors.am && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("day_pm")}</label>
            <input name="pm" defaultValue={dayStateCustom.day.pm} placeholder={t("day_pm")} maxLength={500} ref={register({ required: true })} />
            {errors.pm && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
         <Form.Field>
          <label>{t("day_activities_detail")}</label>
          <textarea name="detail" defaultValue={dayStateCustom.day.detail} placeholder={t("day_activities_detail")} maxLength={2000} ref={register} />
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("day_itinerary_division")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="transfer" defaultChecked={dayStateCustom.day.transfer === 1} ref={register} />
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={dayStateCustom.processing ? true : false}>{t("save_button")}</Button>
        <Link to={`/itinerary/${itinerary_id ? itinerary_id : dayStateCustom.day.itinerary_id}/days`}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
