import SpaceList from "../containers/reservations/cruise/space/SpaceList";
import SpaceForm from "../containers/reservations/cruise/space/SpaceForm";

const routes = [
  {
    path:
      "/cruises/:cruise_id/reservations/:reservation_id/bookings/:booking_id/spaces",
    component: SpaceList,
  },
  {
    path:
      "/cruises/:cruise_id/reservations/:reservation_id/bookings/:booking_id/spaces_new",
    component: SpaceForm,
  },
  {
    path:
      "/cruises/:cruise_id/reservations/:reservation_id/bookings/:booking_id/spaces/:space_id",
    component: SpaceForm,
  },
];

export { routes as spaceRoutes };
