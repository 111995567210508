import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customGuideLanguageList = createSlice({
  name: 'customGuideLanguageList',
  initialState: {
    guide: {},
    guideLanguages: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateGuideLanguageListCustom(state, action) {
      return {
        guide: {},
        guideLanguages: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesGuideLanguageListCustom(state, action) {
      return {
        guide: {},
        guideLanguages: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchGuideLanguageListCustomSuccess(state, action) {
      return {
        guide: action.payload.guide,
        guideLanguages: action.payload.guideLanguages,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchGuideLanguageListCustomError(state, action) {
      return {
        guide: {},
        guideLanguages: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchGuideLanguageListCustom = (guide_id) => dispatch => {
  dispatch(requesGuideLanguageListCustom());
  axios.get(`/guide/${guide_id}/guideLanguagesList`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchGuideLanguageListCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchGuideLanguageListCustom(guide_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchGuideLanguageListCustomError(json.data.data));
      }
    })
};

export const {
  initialStateGuideLanguageListCustom,
  requesGuideLanguageListCustom,
  fetchGuideLanguageListCustomSuccess,
  fetchGuideLanguageListCustomError,
} = customGuideLanguageList.actions;

export default customGuideLanguageList.reducer;