import RateList from "../containers/rates/rate/RateList";
import RateForm from "../containers/rates/rate/RateForm";

const routes = [
  {
    path: "/rates",
    component: RateList,
  },
  {
    path: "/rates_new",
    component: RateForm,
  },
  {
    path: "/rates/:rate_id",
    component: RateForm,
  },
];

export { routes as rateRoutes };
