import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { logOut, updateLocalStorageToken } from '../../../../../containers/resources/utils';

export const customContractRateForm = createSlice({
  name: 'customContractRateForm',
  initialState: {
    agencies: [],
    yachts: [],
    days: [],
    contractRate: [],
    processing: false,
    fetching: false,
    message: "",
    error: "",
  },
  reducers: {
    initialStateContractRateFormCustom(state, action) {
      return {
        agencies: [],
        yachts: [],
        days: [],
        contractRate: [],
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    requesContractRateFormCustom(state, action) {
      return {
        agencies: [],
        yachts: [],
        days: [],
        contractRate: [],
        processing: false,
        fetching: true,
        message: "",
        error: "",
      }
    },
    fetchContractRateFormCustomSuccess(state, action) {
      return {
        agencies: action.payload.agencies,
        yachts: action.payload.yachts,
        days: action.payload.days,
        contractRate: action.payload.contractRate,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      }
    },
    fetchContractRateFormCustomError(state, action) {
      return {
        agencies: [],
        yachts: [],
        days: [],
        contractRate: [],
        processing: false,
        fetching: false,
        message: "",
        error: action.payload.message,
      }
    }
  }
})

export const fetchContractRateFormCustom = (contractRate_id, searchWord) => dispatch => {
  dispatch(requesContractRateFormCustom());
  axios.get(`/contractRates/form/contractRatesForm?contractRate_id=${contractRate_id}&&searchWord=${searchWord}`)
    .then(json => {
      if (json.data.code === 200) {
        dispatch(fetchContractRateFormCustomSuccess(json.data.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchContractRateFormCustom(contractRate_id, searchWord));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchContractRateFormCustomError(json.data.data));
      }
    })
};

export const {
  initialStateContractRateFormCustom,
  requesContractRateFormCustom,
  fetchContractRateFormCustomSuccess,
  fetchContractRateFormCustomError,
} = customContractRateForm.actions;

export default customContractRateForm.reducer;