import { logOut, updateLocalStorageToken } from '../../../../containers/resources/utils';
import SpaceFlightsService from '../../../../services/reservations/SpaceFlightService';

export const REQUEST_FETCH_SPACE_FLIGHTS_LOGS = "REQUEST_FETCH_SPACE_FLIGHTS_LOGS";
export const FETCH_SPACE_FLIGHTS_LOGS_SUCCESS = "FETCH_SPACE_FLIGHTS_LOGS_SUCCESS";
export const FETCH_SPACE_FLIGHTS_LOGS_ERROR = "FETCH_SPACE_FLIGHTS_LOGS_ERROR";

function requestFetchSpaceFlightsLogs() {
  return {
    type: REQUEST_FETCH_SPACE_FLIGHTS_LOGS,
  };
}

function fetchSpaceFlightsLogsSuccess(json) {
  return {
    type: FETCH_SPACE_FLIGHTS_LOGS_SUCCESS,
    logs: json
  };
}

function fetchSpaceFlightsLogsError(json) {
  return {
    type: FETCH_SPACE_FLIGHTS_LOGS_ERROR,
    error: json,
  };
}

export function fetchSpaceFlightsLogs(space_id) {
  return (dispatch) => {
    dispatch(requestFetchSpaceFlightsLogs());
    return SpaceFlightsService.getItemsByField(space_id).then((json) => {
      if (json.status === 200) {
        dispatch(fetchSpaceFlightsLogsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchSpaceFlightsLogs(space_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchSpaceFlightsLogsError(json.data));
      }
    });
  };
}
