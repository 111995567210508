import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import BookingService from "../../../../services/reservations/BookingService";

export const REQUEST_CREATE_BOOKING = "REQUEST_CREATE_BOOKING";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_ERROR = "CREATE_BOOKING_ERROR";

function requestCreateBooking() {
  return {
    type: REQUEST_CREATE_BOOKING,
  };
}

function createBookingSuccess(json) {
  return {
    type: CREATE_BOOKING_SUCCESS,
    message: json.data,
  };
}

function createBookingError(json) {
  return {
    type: CREATE_BOOKING_ERROR,
    error: json.data,
  };
}

export function createBooking(reservation_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateBooking());
    return BookingService.postBooking(reservation_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createBookingSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createBooking(reservation_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createBookingError(json.data));
      }
    });
  };
}
