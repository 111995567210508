import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import FamilyRoomService from "../../../../services/rates/FamilyRoomService";

export const REQUEST_INACTIVE_FAMILY_ROOM_RATE = "REQUEST_INACTIVE_FAMILY_ROOM_RATE";
export const INACTIVE_FAMILY_ROOM_RATE_SUCCESS = "INACTIVE_FAMILY_ROOM_RATE_SUCCESS";
export const INACTIVE_FAMILY_ROOM_RATE_ERROR = "INACTIVE_FAMILY_ROOM_RATE_ERROR";

function requestInactiveFamilyRoomRate() {
  return {
    type: REQUEST_INACTIVE_FAMILY_ROOM_RATE,
  };
}

function inactiveFamilyRoomRateSuccess(json) {
  return {
    type: INACTIVE_FAMILY_ROOM_RATE_SUCCESS,
    message: json.data,
  };
}

function inactiveFamilyRoomRateError(json) {
  return {
    type: INACTIVE_FAMILY_ROOM_RATE_ERROR,
    error: json.data,
  };
}

export function inactiveFamilyRoomRate(familyRoomRate_id, payload) {
  return (dispatch) => {
    dispatch(requestInactiveFamilyRoomRate());
    return FamilyRoomService.inactiveFamilyRoom(familyRoomRate_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(inactiveFamilyRoomRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(inactiveFamilyRoomRate(familyRoomRate_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(inactiveFamilyRoomRateError(json.data));
      }
    });
  };
}
