import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import YachtService from "../../../../services/catalogs/YachtService";

export const REQUEST_DELETE_YACHT = "REQUEST_DELETE_YACHT";
export const DELETE_YACHT_SUCCESS = "DELETE_YACHT_SUCCESS";
export const DELETE_YACHT_ERROR = "DELETE_YACHT_ERROR";

function requestDeleteYacht() {
  return {
    type: REQUEST_DELETE_YACHT,
  };
}

function deleteYachtSuccess(json) {
  return {
    type: DELETE_YACHT_SUCCESS,
    message: json.data,
  };
}

function deleteYachtError(json) {
  return {
    type: DELETE_YACHT_ERROR,
    error: json.data,
  };
}

export function deleteYacht(yacht_id) {
  return (dispatch) => {
    dispatch(requestDeleteYacht());
    return YachtService.deleteYacht(yacht_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteYachtSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteYacht(yacht_id));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(deleteYachtError(json.data));
      }
    });
  };
}
