import CharterRateService from "../../../../services/rates/CharterRateService";

export const REQUEST_CREATE_CHARTER_RATE = "REQUEST_CREATE_CHARTER_RATE";
export const CREATE_CHARTER_RATE_SUCCESS = "CREATE_CHARTER_RATE_SUCCESS";
export const CREATE_CHARTER_RATE_ERROR = "CREATE_CHARTER_RATE_ERROR";

function requestCreateCharterRate() {
  return {
    type: REQUEST_CREATE_CHARTER_RATE,
  };
}

function createCharterRateSuccess(json) {
  return {
    type: CREATE_CHARTER_RATE_SUCCESS,
    message: json.data,
  };
}

function createCharterRateError(json) {
  return {
    type: CREATE_CHARTER_RATE_ERROR,
    error: json.data,
  };
}

export function createCharterRate(payload) {
  return (dispatch) => {
    dispatch(requestCreateCharterRate());
    return CharterRateService.postCharterRate(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createCharterRateSuccess(json.data));
      } else {
        dispatch(createCharterRateError(json.data));
      }
    });
  };
}
