import GuideLanguageList from "../containers/catalogs/guideLanguage/GuideLanguageList";
import GuideLanguageForm from "../containers/catalogs/guideLanguage/GuideLanguageForm";

const routes = [
  {
    path: "/guides/:guide_id/languages",
    component: GuideLanguageList,
  },
  {
    path: "/guides/:guide_id/languages_new",
    component: GuideLanguageForm,
  },
  {
    path: "/guides/:guide_id/languages/:language_id",
    component: GuideLanguageForm,
  },
];

export { routes as guideLanguageRoutes };
