import CruiseService from "../../../../services/reservations/CruiseService";
import { updateLocalStorageToken, logOut } from "../../../../containers/resources/utils";

export const REQUEST_FETCH_YEARS = "REQUEST_FETCH_YEARS";
export const FETCH_YEARS_SUCCESS = "FETCH_YEARS_SUCCESS";
export const FETCH_YEARS_ERROR = "FETCH_YEARS_ERROR";

function requestFetchYears() {
  return {
    type: REQUEST_FETCH_YEARS,
  };
}

function fetchYearsSuccess(json) {
  return {
    type: FETCH_YEARS_SUCCESS,
    years: json.data,
  };
}

function fetchYearsError(json) {
  return {
    type: FETCH_YEARS_ERROR,
    error: json.data,
  };
}

export function fetchYears(startYear) {
  return (dispatch) => {
    dispatch(requestFetchYears());
    return CruiseService.getYears(startYear).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchYearsSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchYears(startYear));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(fetchYearsError(json.data));
      }
    });
  };
}
