import {
  REQUEST_FETCH_RATES,
  FETCH_RATES_SUCCESS,
  FETCH_RATES_ERROR,
} from "../../../actions/catalogs/rate/fetchRates";

import {
  INITIAL_STATE_RATE,
  REQUEST_FETCH_RATE,
  FETCH_RATE_SUCCESS,
  FETCH_RATE_ERROR,
} from "../../../actions/catalogs/rate/fetchRate";

import {
  REQUEST_CREATE_RATE,
  CREATE_RATE_SUCCESS,
  CREATE_RATE_ERROR,
} from "../../../actions/catalogs/rate/createRate";

import {
  REQUEST_UPDATE_RATE,
  UPDATE_RATE_SUCCESS,
  UPDATE_RATE_ERROR,
} from "../../../actions/catalogs/rate/updateRate";

import {
  REQUEST_DELETE_RATE,
  DELETE_RATE_SUCCESS,
  DELETE_RATE_ERROR,
} from "../../../actions/catalogs/rate/deleteRate";

const initialState = {
  rates: [],
  rate: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const rateReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_RATE: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_RATES: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_RATES_SUCCESS: {
      return {
        ...state,
        rates: action.rates,
        rate: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_RATES_ERROR: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_RATE: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_RATE_SUCCESS: {
      return {
        ...state,
        rates: [],
        rate: action.rate,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_RATE_ERROR: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_RATE: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_RATE_SUCCESS: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_RATE_ERROR: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_RATE: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_RATE_SUCCESS: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_RATE_ERROR: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_RATE: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_RATE_SUCCESS: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_RATE_ERROR: {
      return {
        ...state,
        rates: [],
        rate: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default rateReducer;
