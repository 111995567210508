import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSearchPassengers,
  initialStateSearchPassenger,
} from "../../../../redux/actions/reservations/passenger/fetchSearchPassengers";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import { Button, Icon, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function SearchPassengerList() {
  const { t } = useTranslation();
  const [passengerName, setPassengerName] = useState("");
  const [passport, setPassport] = useState();
  const classes = useStyles();
  const searchPassengerState = useSelector(
    (state) => state.searchPassengerReducer
  );
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateSearchPassenger());
    };
  }, [dispatch]);

  if (searchPassengerState.fetching) {
    return <CircularProgress />;
  }

  if (searchPassengerState.error) {
    return <div>Error: {searchPassengerState.error}</div>;
  }

  const handleSeachPassengerName = () => {
    dispatch(fetchSearchPassengers(passengerName, null));
  };

  const handleSeachPassengerPassport = () => {
    dispatch(fetchSearchPassengers(null, passport));
  };

  return (
    <>
      <div className="ui breadcrumb">
        <div className="active section">{t("passenger_search_title")}</div>
      </div>
      <br></br>
      <br></br>
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("passenger_search_title")} {searchPassengerState.message}
          </Typography>
          <div className={classes.search} style={{ paddingRight: "5px" }}>
            <Input
              type="text"
              placeholder={
                t("passenger_search_lastName") +
                ", " +
                t("passenger_search_name") +
                "..."
              }
              action
            >
              <input
                defaultValue={passengerName}
                onChange={(event) => setPassengerName(event.target.value)}
              />
              <Button
                type="submit"
                onClick={() => handleSeachPassengerName()}
                icon="search"
                size="medium"
              />
            </Input>
          </div>
          <div className={classes.search} style={{ paddingRight: "5px" }}>
            <Input
              type="text"
              placeholder={t("passenger_search_passport") + "..."}
              action
            >
              <input
                defaultValue={passport}
                onChange={(event) => setPassport(event.target.value)}
              />
              <Button
                type="submit"
                onClick={() => handleSeachPassengerPassport()}
                icon="search"
                size="medium"
              />
            </Input>
          </div>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("passenger_search_name")}</TableCell>
              <TableCell>{t("passenger_search_passport")}</TableCell>
              <TableCell>{t("passenger_search_nationality")}</TableCell>
              <TableCell>{t("passenger_search_gender")}</TableCell>
              <TableCell>{t("passenger_search_age")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchPassengerState.passengers.map((passenger) => (
              <TableRow key={passenger.id}>
                <TableCell>
                  <Link to={`/passengers/${passenger.id}`}>
                    {passenger.last_name.toUpperCase()} /{" "}
                    {passenger.first_name.toUpperCase()}
                  </Link>
                </TableCell>
                <TableCell>{t(passenger.identification)}</TableCell>
                <TableCell>{t(passenger.country)}</TableCell>
                <TableCell>{t(passenger.gender)}</TableCell>
                <TableCell>
                  {passenger.age} {t("passenger_search_years_old")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
