import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Breadcrumb, Divider, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { initialStateGuideLanguage } from "../../../redux/actions/catalogs/guideLanguage/fetchGuideLanguage";
import { deleteGuideLanguage } from "../../../redux/actions/catalogs/guideLanguage/deleteGuideLanguage";
import { fetchGuideLanguageListCustom } from "../../../redux/slices/custom/catalogs/guideLanguage/customGuideLanguageList.slice";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function GuideLanguageList() {
  const { t } = useTranslation();
  let { guide_id } = useParams();
  const guideLanguageStateCustom = useSelector((state) => state.customGuideLanguageList);
  const guideLanguageState = useSelector((state) => state.guideLanguageReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGuideLanguageListCustom(guide_id));
    scrollToTop();
  }, []);

  if (guideLanguageStateCustom.fetching || guideLanguageState.processing) {
    return <Loader active inline />;
  }

  if (guideLanguageStateCustom.error || guideLanguageState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (guideLanguageState.message === "resource deleted successfully") {
    dispatch(fetchGuideLanguageListCustom(guide_id));
    dispatch(initialStateGuideLanguage())
  }

  const handleDeleteGuideLanguage = (language_id) => {
    dispatch(deleteGuideLanguage(guide_id, language_id));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section >
          <Link to="/guides" className="section">{t("guide_title")}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>{guideLanguageStateCustom.guide.first_name + " " + guideLanguageStateCustom.guide.last_name}</Breadcrumb.Section>
      </Breadcrumb>

      <Divider variant="fullWidth" />

      <HeaderCatalog title={t("guide_lang_title")} message={guideLanguageStateCustom.message} to={`/guides/${guide_id}/languages_new`} />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("guide_lang_name")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {guideLanguageStateCustom.guideLanguages.map((guide) => 
            guide.languages.map((language) => (
            <Table.Row key={language.id}>
              <Table.Cell>{t(language.language_name)}</Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell collapsing>
                <Link to={`/guides/${guide_id}/languages/${language.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
                <Modal
                  trigger={
                    <Button circular color='red' icon='trash alternate' />
                  }
                  content={t("airline_delete")}
                  actions={[
                    t("canceled_button"),
                      {
                        key: "eliminar",
                        content: t("delete_button"),
                        className: "delete_button icon_color",
                        onClick: () => handleDeleteGuideLanguage(language.id),
                      },
                  ]}
                  size="tiny"
                />
              </Table.Cell>
            </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </>
  );
}
