import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideLanguageService from "../../../../services/catalogs/GuideLanguageService";

export const REQUEST_UPDATE_GUIDE_LANGUAGE = "REQUEST_UPDATE_GUIDE_LANGUAGE";
export const UPDATE_GUIDE_LANGUAGE_SUCCESS = "UPDATE_GUIDE_LANGUAGE_SUCCESS";
export const UPDATE_GUIDE_LANGUAGE_ERROR = "UPDATE_GUIDE_LANGUAGE_ERROR";

function requestUpdateGuideLanguage() {
  return {
    type: REQUEST_UPDATE_GUIDE_LANGUAGE,
  };
}

function updateGuideLanguageSuccess(json) {
  return {
    type: UPDATE_GUIDE_LANGUAGE_SUCCESS,
    message: json.data,
  };
}

function updateGuideLanguageError(json) {
  return {
    type: UPDATE_GUIDE_LANGUAGE_ERROR,
    error: json.data,
  };
}

export function updateGuideLanguage(guide_id, id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateGuideLanguage());
    return GuideLanguageService.putGuideLanguage(guide_id, id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(updateGuideLanguageSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(updateGuideLanguage(guide_id, id, payload));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(updateGuideLanguageError(json.data));
        }
      }
    );
  };
}
