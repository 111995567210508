import {
  REQUEST_FETCH_YACHTS,
  FETCH_YACHTS_SUCCESS,
  FETCH_YACHTS_ERROR,
  INITIAL_STATE_YACHTS,
} from "../../../actions/catalogs/yacht/fetchYachts";

import {
  REQUEST_CREATE_YACHT,
  CREATE_YACHT_SUCCESS,
  CREATE_YACHT_ERROR,
} from "../../../actions/catalogs/yacht/createYacht";

import {
  REQUEST_UPDATE_YACHT,
  UPDATE_YACHT_SUCCESS,
  UPDATE_YACHT_ERROR,
} from "../../../actions/catalogs/yacht/updateYacht";

import {
  REQUEST_DELETE_YACHT,
  DELETE_YACHT_SUCCESS,
  DELETE_YACHT_ERROR,
} from "../../../actions/catalogs/yacht/deleteYacht";

const initialState = {
  yachts: [],
  yacht: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const yachtsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_YACHTS: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_YACHTS: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_YACHTS_SUCCESS: {
      return {
        ...state,
        yachts: action.yachts,
        yacht: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_YACHTS_ERROR: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_YACHT: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_YACHT_SUCCESS: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_YACHT_ERROR: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_YACHT: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_YACHT_SUCCESS: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_YACHT_ERROR: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_YACHT: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_YACHT_SUCCESS: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_YACHT_ERROR: {
      return {
        ...state,
        yachts: [],
        yacht: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default yachtsReducer;
