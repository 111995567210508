import AvailabilityService from "../../../../services/b2b/AvailabilityService";

export const REQUEST_CREATE_DIRECT_CLIENT = "REQUEST_CREATE_DIRECT_CLIENT";
export const CREATE_DIRECT_CLIENT_SUCCESS = "CREATE_DIRECT_CLIENT_SUCCESS";
export const CREATE_DIRECT_CLIENT_ERROR = "CREATE_DIRECT_CLIENT_ERROR";

function requestCreateDirectClient() {
  return {
    type: REQUEST_CREATE_DIRECT_CLIENT,
  };
}

function createDirectClientSuccess(json) {
  return {
    type: CREATE_DIRECT_CLIENT_SUCCESS,
    message: json.data,
  };
}

function createDirectClientError(json) {
  return {
    type: CREATE_DIRECT_CLIENT_ERROR,
    error: json.data,
  };
}

export function createDirectClient(payload) {
  return (dispatch) => {
    dispatch(requestCreateDirectClient());
    return AvailabilityService.postSpacesAndPassengers(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createDirectClientSuccess(json.data));
      } else {
        dispatch(createDirectClientError(json.data));
      }
    });
  };
}
