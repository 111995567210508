import AvailableCabinService from "../../../../services/reservations/AvailableCabinService";

export const REQUEST_FETCH_AVAILABLE_CABINS = "REQUEST_FETCH_AVAILABLE_CABINS";
export const FETCH_AVAILABLE_CABINS_SUCCESS = "FETCH_AVAILABLE_CABINS_SUCCESS";
export const FETCH_AVAILABLE_CABINS_ERROR = "FETCH_AVAILABLE_CABINS_ERROR";
export const INITIAL_STATE_AVAILABLE_CABINS = "INITIAL_STATE_AVAILABLE_CABINS";

function requestFetchAvailableCabins() {
  return {
    type: REQUEST_FETCH_AVAILABLE_CABINS,
  };
}

function fetchAvailableCabinsSuccess(json) {
  return {
    type: FETCH_AVAILABLE_CABINS_SUCCESS,
    cabins: json.data,
  };
}

function fetchAvailableCabinsError(json) {
  return {
    type: FETCH_AVAILABLE_CABINS_ERROR,
    error: json.data,
  };
}

export function initialStateAvailableCabins() {
  return {
    type: INITIAL_STATE_AVAILABLE_CABINS,
  };
}

export function fetchAvailableCabins(cruise_id, reservation_id, booking_id) {
  return (dispatch) => {
    dispatch(requestFetchAvailableCabins());
    return AvailableCabinService.getAvailableCabins(
      cruise_id,
      reservation_id,
      booking_id
    ).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchAvailableCabinsSuccess(json.data));
      } else {
        dispatch(fetchAvailableCabinsError(json.data));
      }
    });
  };
}
