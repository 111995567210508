import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GuideOnBoardService from "../../../../services/reservations/GuideOnBoardService";

export const REQUEST_CREATE_GUIDE_ON_BOARD = "REQUEST_CREATE_GUIDE_ON_BOARD";
export const CREATE_GUIDE_ON_BOARD_SUCCESS = "CREATE_GUIDE_ON_BOARD_SUCCESS";
export const CREATE_GUIDE_ON_BOARD_ERROR = "CREATE_GUIDE_ON_BOARD_ERROR";

function requestCreateGuideOnBoard() {
  return {
    type: REQUEST_CREATE_GUIDE_ON_BOARD,
  };
}

function createGuideOnBoardSuccess(json) {
  return {
    type: CREATE_GUIDE_ON_BOARD_SUCCESS,
    message: json.data,
  };
}

function createGuideOnBoardError(json) {
  return {
    type: CREATE_GUIDE_ON_BOARD_ERROR,
    error: json.data,
  };
}

export function createGuideOnBoard(cruise_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateGuideOnBoard());
    return GuideOnBoardService.postGuideOnBoard(cruise_id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(createGuideOnBoardSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(createGuideOnBoard(cruise_id, payload));
        } else if (json.data.code === 498) {
          logOut()
        } else {
          dispatch(createGuideOnBoardError(json.data));
        }
      }
    );
  };
}
