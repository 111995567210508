import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";

//Actions
import { fetchIdentificationTypes } from "../../../redux/actions/catalogs/identificationType/fetchIdentificationTypes";
import { deleteIdentificationType } from "../../../redux/actions/catalogs/identificationType/deleteIdentificationType";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function IdentificationTypeList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const identificationTypeState = useSelector((state) => state.identificationTypeReducer);

  useEffect(() => {
    dispatch(fetchIdentificationTypes());
    scrollToTop();
  }, []);

  if (identificationTypeState.fetching || identificationTypeState.processing) {
    return <Loader active inline />;
  }

  if (identificationTypeState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (identificationTypeState.message === "resource deleted successfully") {
    dispatch(fetchIdentificationTypes());
  }

  const handleDeleteIdentificationType = (identification_type_id) => {
    dispatch(deleteIdentificationType(identification_type_id));
  };

  return (
    <>
      <HeaderCatalog title={t("identificationType_title")} message={identificationTypeState.message} to="/identificationTypes_new" />
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("identificationType_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("identificationType_type")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {identificationTypeState.identificationTypes.map((identificationType) => (
          <Table.Row key={identificationType.id}>
             <Table.Cell>{t(identificationType.name)}</Table.Cell>
            <Table.Cell>{t(identificationType.type)}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/identificationTypes/${identificationType.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("identificationType_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteIdentificationType(identificationType.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
