import React, { Component } from "react";
import { Menu, Visibility } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import "../containers/styles/common.css";

const menuStyle = {
  border: "grove #E0E0E0",
  borderRadius: 0,
  transition: "box-shadow 0.3s ease, padding 0.3s ease",
  backgroundColor: "#FFFFFF",
  height: "80px",
  width: "100%",
};

const fixedMenuStyle = {
  backgroundColor: "#FFF",
  border: "1px solid #ddd",
  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
  height: "60px",
  paddingLeft: "50px",
};

class HeadDetailReservation extends Component {
  state = {
    menuFixed: false,
    overlayFixed: false,
  };

  stickOverlay = () => this.setState({ overlayFixed: true });

  stickTopMenu = () => this.setState({ menuFixed: true });

  unStickOverlay = () => this.setState({ overlayFixed: false });

  unStickTopMenu = () => this.setState({ menuFixed: false });

  render() {
    const { menuFixed } = this.state;
    const { t } = this.props;

    return (
      <div>
        <Visibility
          onBottomPassed={this.stickTopMenu}
          onBottomVisible={this.unStickTopMenu}
          once={false}
        >
          <Menu
            borderless
            compact
            style={
              (menuFixed ? fixedMenuStyle : menuStyle,
              {
                display: "flex",
                padding: "0 25px 0 25px",
                justifyContent: "space-between",
                backgroundColor: "#3f83c2",
              })
            }
          >
            <Menu.Item className="head_style_title">
              {t("reservation_date") + ":"}
            </Menu.Item>

            <Menu.Item className="head_style_value">{this.props.reservation_date}</Menu.Item>

            <Menu.Item className="head_style_title">
              {t("reservation_agency")}:
            </Menu.Item>
            <Menu.Item className="head_style_value">{this.props.reservation_agency}</Menu.Item>

            <Menu.Item className="head_style_title">
              {t("reservation_executive")}:
            </Menu.Item>

            <Menu.Item className="head_style_value">{this.props.reservation_executive}</Menu.Item>

            <Menu.Item className="head_style_title">
              {t("reservation_type")}:
            </Menu.Item>
            <Menu.Item className="head_style_value">
              {this.props.reservation_type}
            </Menu.Item>
          </Menu>
        </Visibility>
      </div>
    );
  }
}

export default withTranslation()(HeadDetailReservation);
