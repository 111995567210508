import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import YachtItineraryService from "../../../../services/catalogs/YachtItineraryService";

export const REQUEST_FETCH_YACHT_ITINERARIES =
  "REQUEST_FETCH_YACHT_ITINERARIES";
export const FETCH_YACHT_ITINERARIES_SUCCESS =
  "FETCH_YACHT_ITINERARIES_SUCCESS";
export const FETCH_YACHT_ITINERARIES_ERROR = "FETCH_YACHT_ITINERARIES_ERROR";

function requestFetchYachItineraries() {
  return {
    type: REQUEST_FETCH_YACHT_ITINERARIES,
  };
}

function fetchYachtItinerariesSuccess(json) {
  return {
    type: FETCH_YACHT_ITINERARIES_SUCCESS,
    yachtItineraries: json.data,
  };
}

function fetchYachtItinerariesError(json) {
  return {
    type: FETCH_YACHT_ITINERARIES_ERROR,
    error: json.data,
  };
}

export function fetchYachtItineraries(itinerary_id) {
  return (dispatch) => {
    dispatch(requestFetchYachItineraries());
    return YachtItineraryService.getYachtItineraries(itinerary_id).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchYachtItinerariesSuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(fetchYachtItineraries(itinerary_id));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(fetchYachtItinerariesError(json.data));
        }
      }
    );
  };
}
