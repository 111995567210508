import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class BookingService {
  static getBookings(reservation_id) {
    return axios.get("reservation/" + reservation_id + "/bookings");
  }

  static getBooking(booking_id) {
    return axios.get("booking/" + booking_id);
  }

  static postBooking(reservation_id, payload) {
    return axios.post(
      "reservation/" + reservation_id + "/bookings",
      payload
    );
  }

  static putBooking(cruise_id, reservation_id, booking_id, payload) {
    return axios.put("cruise/" + cruise_id + "/reservation/" + reservation_id + "/booking/" + booking_id, payload);
  }

  static deleteBooking(booking_id) {
    return axios.delete("booking/" + booking_id);
  }

  static getAvailableCabins(reservation_id) {
    var url =
      "reservation/" + reservation_id + "/cabinsAvailable";
    return axios.get(url);
  }

  static getItemsByField(reservation_id) {
    return axios.get("reservation/bookings/logs/" + reservation_id);
  }
}
