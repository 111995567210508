import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CaptainService from "../../../../services/catalogs/CaptainService";

export const REQUEST_FETCH_CAPTAIN = "REQUEST_FETCH_CAPTAIN";
export const FETCH_CAPTAIN_SUCCESS = "FETCH_CAPTAIN_SUCCESS";
export const FETCH_CAPTAIN_ERROR = "FETCH_CAPTAIN_ERROR";
export const INITIAL_STATE_CAPTAIN = "INITIAL_STATE_CAPTAIN";

function requestFetchCaptain() {
  return {
    type: REQUEST_FETCH_CAPTAIN,
  };
}

function fetchCaptainSuccess(json) {
  return {
    type: FETCH_CAPTAIN_SUCCESS,
    captain: json.data,
  };
}

function fetchCaptainError(json) {
  return {
    type: FETCH_CAPTAIN_ERROR,
    error: json.data,
  };
}

export function initialStateCaptain() {
  return {
    type: INITIAL_STATE_CAPTAIN,
  };
}

export function fetchCaptain(captain_id) {
  return (dispatch) => {
    dispatch(requestFetchCaptain());
    return CaptainService.getCaptain(captain_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCaptainSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCaptain(captain_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCaptainError(json.data));
      }
    });
  };
}
