import VoucherList from "../containers/reservations/cruise/voucher/VoucherList";
import VoucherForm from "../containers/reservations/cruise/voucher/VoucherForm";

const routes = [
  {
    path: "/cruises/:cruise_id/vouchers",
    component: VoucherList,
  },
  {
    path: "/cruises/:cruise_id/vouchers_new",
    component: VoucherForm,
  },
  {
    path: "/cruises/:cruise_id/vouchers/:voucher_id",
    component: VoucherForm,
  },
];

export { routes as voucherRoutes };
