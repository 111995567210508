import YachtItineraryList from "../containers/catalogs/yachtItinerary/YachtItineraryList";
import YachtItineraryForm from "../containers/catalogs/yachtItinerary/YachtItineraryForm";

const routes = [
  {
    path: "/itineraries/:itinerary_id/yachts",
    component: YachtItineraryList,
  },
  {
    path: "/itineraries/:itinerary_id/yachts_new",
    component: YachtItineraryForm,
  },
  {
    path: "/itineraries/:itinerary_id/yachts/:yacht_id",
    component: YachtItineraryForm,
  },
];

export { routes as yachtItineraryRoutes };
