import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import KindService from "../../../../services/catalogs/KindService";

export const REQUEST_FETCH_KINDS = "REQUEST_FETCH_KINDS";
export const FETCH_KINDS_SUCCESS = "FETCH_KINDS_SUCCESS";
export const FETCH_KINDS_ERROR = "FETCH_KINDS_ERROR";

function requestFetchKinds() {
  return {
    type: REQUEST_FETCH_KINDS,
  };
}

function fetchKindsSuccess(json) {
  return {
    type: FETCH_KINDS_SUCCESS,
    kinds: json.data,
  };
}

function fetchKindsError(json) {
  return {
    type: FETCH_KINDS_ERROR,
    error: json.data,
  };
}

export function fetchKinds() {
  return (dispatch) => {
    dispatch(requestFetchKinds());
    return KindService.getKinds().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchKindsSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchKinds());
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(fetchKindsError(json.data));
      }
    });
  };
}
