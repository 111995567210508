import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class IkarusRateCabinService {
  static getIkarusRateCabins(ikarusRate_id) {
    const url = "ikarusRate/" + ikarusRate_id + "/cabins";
    return axios.get(url);
  }
  static postIkarusRateCabin(ikarusRate_id, payload) {
    const url = "ikarusRate/" + ikarusRate_id + "/cabins"
    return axios.post(url, payload);
  }
  static deleteIkarusRateCabin(ikarusRateCabin_id) {
    const url = "ikarusRateCabin/" + ikarusRateCabin_id;
    return axios.delete(url);
  }
}
