import React from "react";
import { useTranslation } from "react-i18next";
import { List } from "semantic-ui-react";

export default function GeneralRateCell(props) {
    const { t } = useTranslation();
    return (
      <>
        <List size="large">
          {props.activation_date ? (
          <List.Item>
              <List.Content >
              <List.Header >{props.activation_date}</List.Header>
              <List.Description>{t("date_active_rate")}</List.Description>
              </List.Content>
          </List.Item>
          ) : (
            null
          )}
          
          {props.inactivation_date ? (
          <List.Item>
              <List.Content>
              <List.Header>{props.inactivation_date}</List.Header>
              <List.Description>{t("date_deactivate_rate")}</List.Description>
              </List.Content>
          </List.Item>
          ) : (
            null
          )}
          
          <List.Item>
              <List.Content>
              <List.Description>{props.reason_deactivation} </List.Description>
              </List.Content>
          </List.Item>
        </List>
      </>
    );
  }
  