import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

//UI
import { Image, Button, Form, Icon, Loader, Divider, Header, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createItinerary } from "../../../redux/actions/catalogs/itinerary/createItinerary";
import { fetchItinerary, initialStateItinerary } from "../../../redux/actions/catalogs/itinerary/fetchItinerary";
import { updateItinerary } from "../../../redux/actions/catalogs/itinerary/updateItinerary";

//Resources
import { numbers, days } from "../../resources/optionsList";
import { scrollToTop } from "../../resources/utils";

export default function ItineraryForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { itinerary_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const itineraryState = useSelector((state) => state.itineraryReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (itinerary_id) {
      dispatch(fetchItinerary(itinerary_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateItinerary());
    };
  }, []);

  if (itineraryState.fetching) {
    return <Loader active inline />;
  }

  if (itineraryState.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (itinerary_id) {
      dispatch(updateItinerary(itinerary_id, data));
    } else {
      dispatch(createItinerary(data));
    }
  };

  if (itineraryState.message === "resource created successfully" || itineraryState.message === "resource updated successfully") {
    history.push("/itineraries");
  }

  return (
    <>
      <Header as='h3' dividing>{t("itinerary_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("itinerary_name")} *</label>
            <input name="name" defaultValue={itineraryState.itinerary.name} placeholder={t("itinerary_name")} maxLength={200} ref={register({ required: true })} autoFocus />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("itinerary_code")}</label>
            <input name="code" defaultValue={itineraryState.itinerary.code} placeholder={t("itinerary_code")} maxLength={200} ref={register}/>
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("itinerary_days")} *</label>
            <select name="number_days" defaultValue={itineraryState.itinerary.number_days} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {numbers.map((number, index) => (
                <option key={index} value={number}>{number}</option>
              ))}
            </select>
            {errors.number_days && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("itinerary_nights")} *</label>
            <select name="number_nights" defaultValue={itineraryState.itinerary.number_nights} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {numbers.map((number, index) => (
                <option key={index} value={number}>{number}</option>
              ))}
            </select>
            {errors.number_nights && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("itinerary_start_day")} *</label>
            <select name="start_day" defaultValue={itineraryState.itinerary.start_day} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {days.map((day, index) => (
                <option key={index} value={day}>{t(day)}</option>
              ))}
            </select>
            {errors.start_day && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field>
            <label>{t("itinerary_end_day")} *</label>
            <select name="end_day" defaultValue={itineraryState.itinerary.end_day} aria-expanded="true" ref={register({ required: true })}>
              <option></option>
              {days.map((day, index) => (
                <option key={index} value={day}>{t(day)}</option>
              ))}
            </select>
            {errors.end_day && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>{t("itinerary_route")}</label>
            <input name="route" defaultValue={itineraryState.itinerary.route} placeholder={t("itinerary_route")} maxLength={25} ref={register}/>
          </Form.Field>

          <Form.Field>
            <label>{t("active_tag")}</label>
            <div className="ui checkbox">
              <input type="checkbox" name="active" defaultChecked={itineraryState.itinerary.active === 1} ref={register}/>
              <label></label>
            </div>
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={itineraryState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/itineraries"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
