import {
  REQUEST_FETCH_ITINERARIES,
  FETCH_ITINERARIES_SUCCESS,
  FETCH_ITINERARIES_ERROR,
} from "../../../actions/catalogs/itinerary/fetchItineraries";

import {
  INITIAL_STATE_ITINERARY,
  REQUEST_FETCH_ITINERARY,
  FETCH_ITINERARY_SUCCESS,
  FETCH_ITINERARY_ERROR,
} from "../../../actions/catalogs/itinerary/fetchItinerary";

import {
  REQUEST_CREATE_ITINERARY,
  CREATE_ITINERARY_SUCCESS,
  CREATE_ITINERARY_ERROR,
} from "../../../actions/catalogs/itinerary/createItinerary";

import {
  REQUEST_UPDATE_ITINERARY,
  UPDATE_ITINERARY_SUCCESS,
  UPDATE_ITINERARY_ERROR,
} from "../../../actions/catalogs/itinerary/updateItinerary";

import {
  REQUEST_DELETE_ITINERARY,
  DELETE_ITINERARY_SUCCESS,
  DELETE_ITINERARY_ERROR,
} from "../../../actions/catalogs/itinerary/deleteItinerary";

import {
  REQUEST_FETCH_ITINERARY_DIVISION,
  FETCH_ITINERARY_DIVISION_SUCCESS,
  FETCH_ITINERARY_DIVISION_ERROR,
} from "../../../actions/catalogs/itinerary/checkItineraryDivision";

const initialState = {
  itineraries: [],
  itinerary: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const itineraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_ITINERARY: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_ITINERARIES: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_ITINERARIES_SUCCESS: {
      return {
        ...state,
        itineraries: action.itineraries,
        itinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_ITINERARIES_ERROR: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_ITINERARY: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_ITINERARY_SUCCESS: {
      return {
        ...state,
        itineraries: [],
        itinerary: action.itinerary,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_ITINERARY_ERROR: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_ITINERARY: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_ITINERARY_SUCCESS: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_ITINERARY_ERROR: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_ITINERARY: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_ITINERARY_SUCCESS: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_ITINERARY_ERROR: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_ITINERARY: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_ITINERARY_SUCCESS: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_ITINERARY_ERROR: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_ITINERARY_DIVISION: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_ITINERARY_DIVISION_SUCCESS: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case FETCH_ITINERARY_DIVISION_ERROR: {
      return {
        ...state,
        itineraries: [],
        itinerary: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default itineraryReducer;
