import React, { useEffect, useState } from "react";
import { Form, Icon } from "semantic-ui-react";
import useStyles from "../../styles/Common";
import "../../styles/common.css";
import { useDispatch, useSelector } from "react-redux";
import {
  generateStatisticReport,
  initialStateStatisticReport,
} from "../../../redux/actions/reservations/statistic/generateStatisticReport";
import {
  generateStatistic,
  initialStateStatistic,
} from "../../../redux/actions/reservations/statistic/generateStatistic";
import Divider from "@material-ui/core/Divider";
import { useForm } from "react-hook-form";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function StatisticReportList() {
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm();
  const classes = useStyles();
  const statisticReportState = useSelector(
    (state) => state.statisticReportReducer
  );
  const [statistic, setStatistic] = useState({});
  const statisticState = useSelector((state) => state.statisticReducer);
  let filters = [];
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    return () => {
      dispatch(initialStateStatisticReport());
      dispatch(initialStateStatistic());
    };
  }, [dispatch]);

  const getSelectedItems = () => {
    var elements = document.getElementsByName("active");
    filters.length = 0;
    elements.forEach((el) => {
      if (el.checked) {
        filters.push(el.id);
      }
    });
    return filters;
  };

  const onSubmit = (data) => {
    dispatch(initialStateStatistic());
    getSelectedItems();
    if (filters.length > 0) {
      setStatistic({
        start_date: data.start_date,
        end_date: data.end_date,
        filters: filters,
      });
      dispatch(
        generateStatisticReport({
          start_date: data.start_date,
          end_date: data.end_date,
          filters: filters,
        })
      );
    } else {
      setStatistic({
        start_date: data.start_date,
        end_date: data.end_date,
        filters: [],
      });
      dispatch(
        generateStatisticReport({
          start_date: data.start_date,
          end_date: data.end_date,
          filters: [],
        })
      );
    }
  };

  const handleGenerateReport = (type) => {
    dispatch(
      generateStatistic(
        statistic.start_date,
        statistic.end_date,
        type,
        statistic.filters
      )
    );
    dispatch(initialStateStatistic());
  };

  if (statisticState.message === "data send succesfully") {
    window.open(statisticState.data, "_self");
    dispatch(initialStateStatistic());
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
          <label>{t("statistic_title")}</label>
          <Divider variant="fullWidth" />
          <br />
          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("statistic_age")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="active"
                  id="age"
                  ref={register}
                  onChange={() => getSelectedItems()}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>{t("statistic_gender")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="active"
                  id="gender"
                  ref={register}
                  onChange={() => getSelectedItems()}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>{t("statistic_nationality")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="active"
                  id="country"
                  ref={register}
                  onChange={() => getSelectedItems()}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>{t("statistic_agency")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="active"
                  id="agency"
                  ref={register}
                  onChange={() => getSelectedItems()}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>{t("statistic_route")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="active"
                  id="route"
                  ref={register}
                  onChange={() => getSelectedItems()}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>{t("statistic_vegetarian")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="active"
                  id="vegetarian"
                  ref={register}
                  onChange={() => getSelectedItems()}
                />
                <label></label>
              </div>
            </Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>{t("statistic_cabin")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="active"
                  id="cabin"
                  ref={register}
                  onChange={() => getSelectedItems()}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>{t("statistic_cruiseType")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="active"
                  id="cruise_type"
                  ref={register}
                  onChange={() => getSelectedItems()}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>{t("statistic_cabinType")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="active"
                  id="deck"
                  ref={register}
                  onChange={() => getSelectedItems()}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>{t("statistic_rate")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="active"
                  id="rate"
                  ref={register}
                  onChange={() => getSelectedItems()}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field>
              <label>{t("statistic_accommodation")}</label>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="active"
                  id="single_bed"
                  ref={register}
                  onChange={() => getSelectedItems()}
                />
                <label></label>
              </div>
            </Form.Field>

            <Form.Field></Form.Field>
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Field>
              <label>
                {t("statistic_startDate")}*{" "}
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </label>
              <input
                type="date"
                placeholder="Yate"
                name="start_date"
                ref={register({ required: true })}
                defaultValue={statistic.start_date}
              />
              {errors.start_date && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>
            <Form.Field>
              <label>
                {t("statistic_endDate")}*{" "}
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </label>
              <input
                type="date"
                placeholder="Yate"
                name="end_date"
                ref={register({ required: true })}
                defaultValue={statistic.end_date}
              />
              {errors.end_date && (
                <span className="error_message">
                  {t("required_information")}
                </span>
              )}
            </Form.Field>
            <Form.Field width={2}>
              <label style={{ paddingTop: "20px" }}></label>
              <Button
                type="submit"
                className={
                  statisticReportState.fetching
                    ? "ui loading primary button"
                    : "ui primary button"
                }
              >
                {t("search_button")}
              </Button>
            </Form.Field>
          </Form.Group>
        </div>
      </Form>
      <br></br>
      <Divider variant="fullWidth" />
      <br></br>
      <div className="flex-container">
        <table className="table table-striped table-style">
          <tbody>
            <tr>
              <th
                colSpan="11"
                style={{
                  backgroundColor: "#DCDCDC",
                  width: "100px",
                  height: "60px",
                }}
              >
                {t("statistic_total")}{" "}
                <span>
                  (
                  {statisticReportState.statistic.totalPassengers !== undefined
                    ? statisticReportState.statistic.totalPassengers
                    : 0}
                </span>
                ){" "}
                <button
                  type="submit"
                  onClick={() => handleGenerateReport("total")}
                  className={
                    statisticState.fetching
                      ? "ui loading icon primary button tiny"
                      : "ui primary icon button tiny"
                  }
                  style={{ backgroundColor: "#1BB394", color: "white" }}
                >
                  <Icon name="file excel outline" />
                </button>
              </th>
            </tr>

            {statisticReportState.statistic.total !== undefined &&
              statisticReportState.statistic.total.length > 0 && (
                <tr style={{ backgroundColor: "#85C1E9" }} className="tr-style">
                  {statisticReportState.statistic.total[0].age && (
                    <th className="th-style">{t("statistic_age")}</th>
                  )}
                  {statisticReportState.statistic.total[0].gender && (
                    <th className="th-style">{t("statistic_gender")}</th>
                  )}
                  {statisticReportState.statistic.total[0].country && (
                    <th className="th-style">{t("statistic_nationality")}</th>
                  )}
                  {statisticReportState.statistic.total[0].agency && (
                    <th className="th-style">{t("statistic_agency")}</th>
                  )}
                  {statisticReportState.statistic.total[0].route && (
                    <th className="th-style">{t("statistic_route")}</th>
                  )}

                  {statisticReportState.statistic.total[0].vegetarian && (
                    <th className="th-style">{t("statistic_vegetarian")}</th>
                  )}

                  {statisticReportState.statistic.total[0].cabin && (
                    <th className="th-style">{t("statistic_cabin")}</th>
                  )}
                  {statisticReportState.statistic.total[0].cruise_type && (
                    <th className="th-style">{t("statistic_cruiseType")}</th>
                  )}
                  {statisticReportState.statistic.total[0].deck && (
                    <th className="th-style">{t("statistic_cabinType")}</th>
                  )}
                  {statisticReportState.statistic.total[0].rate && (
                    <th className="th-style">{t("statistic_rate")}</th>
                  )}
                  {statisticReportState.statistic.total[0].passengers !== 0 && (
                    <th className="th-style">{t("statistic_passengers")}</th>
                  )}
                  {statisticReportState.statistic.total[0].percent && (
                    <th className="th-style">{t("statistic_porcentage")}</th>
                  )}
                </tr>
              )}
          </tbody>
          {statisticReportState.statistic.total !== undefined && (
            <tbody>
              {statisticReportState.statistic.total.length > 0 &&
                statisticReportState.statistic.total.map((total, index) => (
                  <tr key={index} className="tr-style">
                    {total.age && <th className="th-style">{total.age}</th>}
                    {total.gender && (
                      <th className="th-style">{total.gender}</th>
                    )}
                    {total.country && (
                      <th className="th-style">{total.country}</th>
                    )}
                    {total.agency && (
                      <th className="th-style">{total.agency}</th>
                    )}
                    {total.route && <th className="th-style">{total.route}</th>}
                    {total.vegetarian && (
                      <th className="th-style">{total.vegetarian}</th>
                    )}
                    {total.cabin && <th className="th-style">{total.cabin}</th>}
                    {total.cruise_type && (
                      <th className="th-style">{total.cruise_type}</th>
                    )}
                    {total.deck && <th className="th-style">{total.deck}</th>}
                    {total.rate && <th className="th-style">{total.rate}</th>}
                    {total.passengers !== 0 && (
                      <th className="th-style">{total.passengers}</th>
                    )}
                    {total.percent && (
                      <th className="th-style">{total.percent}</th>
                    )}
                  </tr>
                ))}
            </tbody>
          )}
        </table>
        <table className="table table-striped table-style">
          <tbody>
            <tr>
              <th
                colSpan="11"
                style={{
                  backgroundColor: "#DCDCDC",
                  width: "100px",
                  height: "60px",
                }}
              >
                {t("statistic_tiptop_two")}{" "}
                <span>
                  (
                  {statisticReportState.statistic.totalPassengersTTII !==
                  undefined
                    ? statisticReportState.statistic.totalPassengersTTII
                    : 0}
                  )
                </span>{" "}
                <button
                  type="submit"
                  onClick={() => handleGenerateReport("tttwo")}
                  className={
                    statisticState.fetching
                      ? "ui loading icon primary button tiny"
                      : "ui primary icon button tiny"
                  }
                  style={{ backgroundColor: "#1BB394", color: "white" }}
                >
                  <Icon name="file excel outline" />
                </button>
              </th>
            </tr>
            {statisticReportState.statistic.ttII !== undefined &&
              statisticReportState.statistic.ttII.length > 0 && (
                <tr style={{ backgroundColor: "#85C1E9" }} className="tr-style">
                  {statisticReportState.statistic.ttII[0].age && (
                    <th className="th-style">{t("statistic_age")}</th>
                  )}
                  {statisticReportState.statistic.ttII[0].gender && (
                    <th className="th-style">{t("statistic_gender")}</th>
                  )}
                  {statisticReportState.statistic.ttII[0].country && (
                    <th className="th-style">{t("statistic_nationality")}</th>
                  )}
                  {statisticReportState.statistic.ttII[0].agency && (
                    <th className="th-style">{t("statistic_agency")}</th>
                  )}
                  {statisticReportState.statistic.ttII[0].route && (
                    <th className="th-style">{t("statistic_route")}</th>
                  )}

                  {statisticReportState.statistic.ttII[0].vegetarian && (
                    <th className="th-style">{t("statistic_vegetarian")}</th>
                  )}

                  {statisticReportState.statistic.ttII[0].cabin && (
                    <th className="th-style">{t("statistic_cabin")}</th>
                  )}
                  {statisticReportState.statistic.ttII[0].cruise_type && (
                    <th className="th-style">{t("statistic_cruiseType")}</th>
                  )}
                  {statisticReportState.statistic.ttII[0].deck && (
                    <th className="th-style">{t("statistic_cabinType")}</th>
                  )}
                  {statisticReportState.statistic.ttII[0].rate && (
                    <th className="th-style">{t("statistic_rate")}</th>
                  )}
                  {statisticReportState.statistic.ttII[0].passengers !== 0 && (
                    <th className="th-style">{t("statistic_passengers")}</th>
                  )}
                  {statisticReportState.statistic.ttII[0].percent && (
                    <th className="th-style">{t("statistic_porcentage")}</th>
                  )}
                </tr>
              )}
          </tbody>
          {statisticReportState.statistic.ttII !== undefined && (
            <tbody>
              {statisticReportState.statistic.ttII.length > 0 &&
                statisticReportState.statistic.ttII.map((tt2, index) => (
                  <tr key={index} className="tr-style">
                    {tt2.age && <th className="th-style">{tt2.age}</th>}
                    {tt2.gender && <th className="th-style">{tt2.gender}</th>}
                    {tt2.country && <th className="th-style">{tt2.country}</th>}
                    {tt2.agency && <th className="th-style">{tt2.agency}</th>}
                    {tt2.route && <th className="th-style">{tt2.route}</th>}
                    {tt2.vegetarian && (
                      <th className="th-style">{tt2.vegetarian}</th>
                    )}
                    {tt2.cabin && <th className="th-style">{tt2.cabin}</th>}
                    {tt2.cruise_type && (
                      <th className="th-style">{tt2.cruise_type}</th>
                    )}
                    {tt2.deck && <th className="th-style">{tt2.deck}</th>}
                    {tt2.rate && <th className="th-style">{tt2.rate}</th>}
                    {tt2.passengers !== 0 && (
                      <th className="th-style">{tt2.passengers}</th>
                    )}
                    {tt2.percent && <th className="th-style">{tt2.percent}</th>}
                  </tr>
                ))}
            </tbody>
          )}
        </table>
        <table className="table table-striped table-style">
          <tbody>
            <tr>
              <th
                colSpan="11"
                style={{
                  backgroundColor: "#DCDCDC",
                  width: "100px",
                  height: "60px",
                }}
              >
                {t("statistic_tiptop_four")}{" "}
                <span>
                  (
                  {statisticReportState.statistic.totalPassengersTTIV !==
                  undefined
                    ? statisticReportState.statistic.totalPassengersTTIV
                    : 0}
                  )
                </span>{" "}
                <button
                  type="submit"
                  onClick={() => handleGenerateReport("ttfour")}
                  className={
                    statisticState.fetching
                      ? "ui loading icon primary button tiny"
                      : "ui primary icon button tiny"
                  }
                  style={{ backgroundColor: "#1BB394", color: "white" }}
                >
                  <Icon name="file excel outline" />
                </button>
              </th>
            </tr>
            {statisticReportState.statistic.ttIV !== undefined &&
              statisticReportState.statistic.ttIV.length > 0 && (
                <tr style={{ backgroundColor: "#85C1E9" }} className="tr-style">
                  {statisticReportState.statistic.ttIV[0].age && (
                    <th className="th-style">{t("statistic_age")}</th>
                  )}
                  {statisticReportState.statistic.ttIV[0].gender && (
                    <th className="th-style">{t("statistic_gender")}</th>
                  )}
                  {statisticReportState.statistic.ttIV[0].country && (
                    <th className="th-style">{t("statistic_nationality")}</th>
                  )}
                  {statisticReportState.statistic.ttIV[0].agency && (
                    <th className="th-style">{t("statistic_agency")}</th>
                  )}
                  {statisticReportState.statistic.ttIV[0].route && (
                    <th className="th-style">{t("statistic_route")}</th>
                  )}
                  {statisticReportState.statistic.ttIV[0].vegetarian && (
                    <th className="th-style">{t("statistic_vegetarian")}</th>
                  )}
                  {statisticReportState.statistic.ttIV[0].cabin && (
                    <th className="th-style">{t("statistic_cabin")}</th>
                  )}
                  {statisticReportState.statistic.ttIV[0].cruise_type && (
                    <th className="th-style">{t("statistic_cruiseType")}</th>
                  )}
                  {statisticReportState.statistic.ttIV[0].deck && (
                    <th className="th-style">{t("statistic_cabinType")}</th>
                  )}
                  {statisticReportState.statistic.ttIV[0].rate && (
                    <th className="th-style">{t("statistic_rate")}</th>
                  )}
                  {statisticReportState.statistic.ttIV[0].passengers !== 0 && (
                    <th className="th-style">{t("statistic_passengers")}</th>
                  )}
                  {statisticReportState.statistic.ttIV[0].percent && (
                    <th className="th-style">{t("statistic_porcentage")}</th>
                  )}
                </tr>
              )}
          </tbody>
          {statisticReportState.statistic.ttIV !== undefined && (
            <tbody>
              {statisticReportState.statistic.ttIV.length > 0 &&
                statisticReportState.statistic.ttIV.map((tt4, index) => (
                  <tr key={index} className="tr-style">
                    {tt4.age && <th className="th-style">{tt4.age}</th>}
                    {tt4.gender && <th className="th-style">{tt4.gender}</th>}
                    {tt4.country && <th className="th-style">{tt4.country}</th>}
                    {tt4.agency && <th className="th-style">{tt4.agency}</th>}
                    {tt4.route && <th className="th-style">{tt4.route}</th>}
                    {tt4.vegetarian && (
                      <th className="th-style">{tt4.vegetarian}</th>
                    )}
                    {tt4.cabin && <th className="th-style">{tt4.cabin}</th>}
                    {tt4.cruise_type && (
                      <th className="th-style">{tt4.cruise_type}</th>
                    )}
                    {tt4.deck && <th className="th-style">{tt4.deck}</th>}
                    {tt4.rate && <th className="th-style">{tt4.rate}</th>}
                    {tt4.passengers !== 0 && (
                      <th className="th-style">{tt4.passengers}</th>
                    )}
                    {tt4.percent && <th className="th-style">{tt4.percent}</th>}
                  </tr>
                ))}
            </tbody>
          )}
        </table>
        <table className="table table-striped table-style">
          <tbody>
            <tr>
              <th
                colSpan="11"
                style={{
                  backgroundColor: "#DCDCDC",
                  width: "100px",
                  height: "60px",
                }}
              >
                {t("statistic_tiptop_five")}{" "}
                <span>
                  (
                  {statisticReportState.statistic.totalPassengersTTV !==
                  undefined
                    ? statisticReportState.statistic.totalPassengersTTV
                    : 0}
                  )
                </span>{" "}
                <button
                  type="submit"
                  onClick={() => handleGenerateReport("ttfive")}
                  className={
                    statisticState.fetching
                      ? "ui loading icon primary button tiny"
                      : "ui primary icon button tiny"
                  }
                  style={{ backgroundColor: "#1BB394", color: "white" }}
                >
                  <Icon name="file excel outline" />
                </button>
              </th>
            </tr>
            {statisticReportState.statistic.ttV !== undefined &&
              statisticReportState.statistic.ttV.length > 0 && (
                <tr style={{ backgroundColor: "#85C1E9" }} className="tr-style">
                  {statisticReportState.statistic.ttV[0].age && (
                    <th className="th-style">{t("statistic_age")}</th>
                  )}
                  {statisticReportState.statistic.ttV[0].gender && (
                    <th className="th-style">{t("statistic_gender")}</th>
                  )}
                  {statisticReportState.statistic.ttV[0].country && (
                    <th className="th-style">{t("statistic_nationality")}</th>
                  )}
                  {statisticReportState.statistic.ttV[0].agency && (
                    <th className="th-style">{t("statistic_agency")}</th>
                  )}
                  {statisticReportState.statistic.ttV[0].route && (
                    <th className="th-style">{t("statistic_route")}</th>
                  )}
                  {statisticReportState.statistic.ttV[0].vegetarian && (
                    <th className="th-style">{t("statistic_vegetarian")}</th>
                  )}
                  {statisticReportState.statistic.ttV[0].cabin && (
                    <th className="th-style">{t("statistic_cabin")}</th>
                  )}
                  {statisticReportState.statistic.ttV[0].cruise_type && (
                    <th className="th-style">{t("statistic_cruiseType")}</th>
                  )}
                  {statisticReportState.statistic.ttV[0].deck && (
                    <th className="th-style">{t("statistic_cabinType")}</th>
                  )}
                  {statisticReportState.statistic.ttV[0].rate && (
                    <th className="th-style">{t("statistic_rate")}</th>
                  )}
                  {statisticReportState.statistic.ttV[0].passengers !== 0 && (
                    <th className="th-style">{t("statistic_passengers")}</th>
                  )}
                  {statisticReportState.statistic.ttV[0].percent && (
                    <th className="th-style">{t("statistic_porcentage")}</th>
                  )}
                </tr>
              )}
          </tbody>
          {statisticReportState.statistic.ttV !== undefined && (
            <tbody>
              {statisticReportState.statistic.ttV.length > 0 &&
                statisticReportState.statistic.ttV.map((tt5, index) => (
                  <tr key={index} className="tr-style">
                    {tt5.age && <th className="th-style">{tt5.age}</th>}
                    {tt5.gender && <th className="th-style">{tt5.gender}</th>}
                    {tt5.country && <th className="th-style">{tt5.country}</th>}
                    {tt5.agency && <th className="th-style">{tt5.agency}</th>}
                    {tt5.route && <th className="th-style">{tt5.route}</th>}
                    {tt5.vegetarian && (
                      <th className="th-style">{tt5.vegetarian}</th>
                    )}
                    {tt5.cabin && <th className="th-style">{tt5.cabin}</th>}
                    {tt5.cruise_type && (
                      <th className="th-style">{tt5.cruise_type}</th>
                    )}
                    {tt5.deck && <th className="th-style">{tt5.deck}</th>}
                    {tt5.rate && <th className="th-style">{tt5.rate}</th>}
                    {tt5.passengers !== 0 && (
                      <th className="th-style">{tt5.passengers}</th>
                    )}
                    {tt5.percent && <th className="th-style">{tt5.percent}</th>}
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
}
