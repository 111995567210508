import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import LanguageService from "../../../../services/catalogs/LanguageService";

export const REQUEST_FETCH_LANGUAGES = "REQUEST_FETCH_LANGUAGES";
export const FETCH_LANGUAGES_SUCCESS = "FETCH_LANGUAGES_SUCCESS";
export const FETCH_LANGUAGES_ERROR = "FETCH_LANGUAGES_ERROR";

function requestFetchLanguages() {
  return {
    type: REQUEST_FETCH_LANGUAGES,
  };
}

function fetchLanguagesSuccess(json) {
  return {
    type: FETCH_LANGUAGES_SUCCESS,
    languages: json.data,
  };
}

function fetchLanguagesError(json) {
  return {
    type: FETCH_LANGUAGES_ERROR,
    error: json.data,
  };
}

export function fetchLanguages() {
  return (dispatch) => {
    dispatch(requestFetchLanguages());
    return LanguageService.getLanguages().then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchLanguagesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchLanguages());
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchLanguagesError(json.data));
      }
    });
  };
}
