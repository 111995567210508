import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class VoucherService {
  static getVouchers(cruise_id) {
    return axios.get("cruise/" + cruise_id + "/vouchers");
  }

  static getVoucher(voucher_id) {
    return axios.get("voucher/" + voucher_id);
  }

  static postVoucher(cruise_id, payload) {
    return axios.post(
      "cruise/" + cruise_id + "/vouchers",
      payload
    );
  }

  static putVoucher(voucher_id, payload) {
    return axios.put("voucher/" + voucher_id, payload);
  }

  static deleteVoucher(voucher_id) {
    return axios.delete("voucher/" + voucher_id);
  }

  static getPassengersByAgency(cruise_id, agency_id) {
    return axios.get(
      API_BASE_URL +
        "cruise/" +
        cruise_id +
        "/agency/" +
        agency_id +
        "/passengers"
    );
  }

  static getInformationVoucherPDF(cruise_id, voucher_id) {
    return axios.get(
      API_BASE_URL +
        "cruise/" +
        cruise_id +
        "/voucher/" +
        voucher_id +
        "/passengersByVoucher"
    );
  }
}
