import React, { useState } from "react";
import clsx from "clsx";
import { Dropdown } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import AccountCircle from '@material-ui/icons/AccountCircle';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Chart from "./Chart";
import Deposits from "./Deposits";
import Orders from "./Orders";
import AdminList from "./AdminList";
import ReservationList from "./ReservationList";
import { Route, Switch, useHistory } from "react-router-dom";
import { agencyRoutes } from "../routes/AgencyRoutes";
import { airlineRoutes } from "../routes/AirlineRoutes";
import { berthRoutes } from "../routes/BerthRoutes";
import { cabinRoutes } from "../routes/CabinRoutes";
import { captainRoutes } from "../routes/CaptainRoutes";
import { companyRoutes } from "../routes/CompanyRoutes";
import { countryRoutes } from "../routes/CountryRoutes";
import { deckRoutes } from "../routes/DeckRoutes";
import { flightRoutes } from "../routes/FlightRoutes";
import { genderRoutes } from "../routes/GenderRoutes";
import { guideRoutes } from "../routes/GuideRoutes";
import { guideLanguageRoutes } from "../routes/GuideLanguageRoutes";
import { hotelierRoutes } from "../routes/HotelierRoutes";
import { identificationTypeRoutes } from "../routes/IdentificationTypeRoutes";
import { itineraryRoutes } from "../routes/ItineraryRoutes";
import { kindRoutes } from "../routes/KindRoutes";
import { languageRoutes } from "../routes/LanguageRoutes";
import { noteRoutes } from "../routes/NoteRoutes";
import { rateRoutes } from "../routes/RateRoutes";
import { routeRoutes } from "../routes/RouteRoutes";
import { ticketRoutes } from "../routes/TicketRoutes";
import { seasonRoutes } from "../routes/SeasonRoutes";
import { userRoutes } from "../routes/UserRoutes";
import { yachtRoutes } from "../routes/YachtRoutes";
import { dayRoutes } from "../routes/DayRoutes";
import { cabinBerthRoutes } from "../routes/CabinBerthRoutes";
import { yachtItineraryRoutes } from "../routes/YachtItineraryRoutes";

//Rates
import { contractRateRoutes } from "../routes/rates/ContractRateRoutes";
import { charterRateRoutes } from "../routes/rates/CharterRateRoutes";
import { familyRoomRoutes } from "../routes/rates/FamilyRoomRoutes";
import { generalRateRoutes } from "../routes/rates/GeneralRateRoutes";
import { ikarusRateRoutes } from "../routes/rates/IkarusRateRoutes";

//Reservations
import { bookingRoutes } from "../routes/BookingRoutes";
import { passengerBySpaceRoutes } from "../routes/PassengerBySpaceRoutes";
import { cruiseTipTopRoutes } from "../routes/CruiseRoutes";
import { cruiseLanguageRoutes } from "../routes/CruiseLanguageRoutes";
import { guideonBoardRoutes } from "../routes/GuideOnBoardRoutes";
import { gpsTaxRoutes } from "../routes/GpsTaxRoutes";
import { cruiseRateRoutes } from "../routes/CruiseRateRoutes";
import { cruiseDetailRoutes } from "../routes/CruiseDetailRoutes";
import { passengerDetailRoutes } from "../routes/PassengerDetailRoutes";
import { passengerInFlightRoutes } from "../routes/PassengerInFlightRoutes";
import { passengerIngalaRoutes } from "../routes/PassengerIngalaRoutes";
import { searchPassengerRoutes } from "../routes/SearchPassengerRoutes";
import { reservationRoutes } from "../routes/ReservationRoutes";
import { voucherRoutes } from "../routes/VoucherRoutes";
import { changeCabinRoutes } from "../routes/ChangeCabinRoutes";
import { historicalCruiseRoutes } from "../routes/HistoricalCruiseRoutes";
import { advanceCruiseRoutes } from "../routes/AdvanceCruiseRoutes";
import { statisticReportRoutes } from "../routes/StatisticReportRoutes";
import { spaceRoutes } from "../routes/SpaceRoutes";
import { spaceFlightRoutes } from "../routes/SpaceFlightRoutes";

import { Card, Image } from "semantic-ui-react";
import rwittmerLogo from "../containers/icons/rwittmerLogo.png";

//TRASLATION
import { useTranslation } from "react-i18next";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // height: "100vh",
    overflow: "auto",
    width: "100%",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  let history = useHistory();
  const [language, setLanguage] = useState("ES");
  const { i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const logout = (lng) => {
    localStorage.clear()
    history.push("/login");
  };

  const user = JSON.parse(localStorage.getItem("userInfo"));
 // const user = {role: "admin"};
// console.log("useruser", user);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <Image src={rwittmerLogo} alt="rwittmerLogo"  style={{ width: "87px", height: "50px" }} />
          </Typography>
          <Dropdown text={language} direction="left">
            <Dropdown.Menu>
              <Dropdown.Item
                value="es"
                text="Español - ES"
                onClick={() => {
                  changeLanguage("es");
                  setLanguage("ES");
                }}
              />
              <Dropdown.Item
                value="en"
                text="English - EN"
                onClick={() => {
                  changeLanguage("en");
                  setLanguage("EN");
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Dropdown text={user.userName} direction="left">
            <Dropdown.Menu>
              <Dropdown.Item
                value="logout"
                text="Log out"
                onClick={() => {
                  logout();
                }}
              />
            </Dropdown.Menu>
          </Dropdown>

          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
         
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <Divider />
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        { user.role === "admin" ? (
        <AdminList />
        ) : (
          ""
        )}

        <Divider />
        <ReservationList />
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Switch>

          { user.role === "admin" ? (
            companyRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}
        
          { user.role === "admin" ? (
            airlineRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}
            
          { user.role === "admin" ? (
            agencyRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            berthRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            captainRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            kindRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            genderRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            guideRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            hotelierRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            languageRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            itineraryRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            deckRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            noteRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            countryRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            routeRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            identificationTypeRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            flightRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            cabinRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            guideLanguageRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            rateRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            seasonRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            ticketRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            userRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            yachtRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            dayRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            cabinBerthRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            yachtItineraryRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

            {/**Rates */}
          { user.role === "admin" ? (
            contractRateRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            charterRateRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            generalRateRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}

          { user.role === "admin" ? (
            ikarusRateRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))
          ) : ( "" )}
  

            {/**Reservations */}
            {cruiseTipTopRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {cruiseLanguageRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {guideonBoardRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {cruiseRateRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {cruiseDetailRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {reservationRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {bookingRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {passengerBySpaceRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {passengerDetailRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {gpsTaxRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {voucherRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {passengerInFlightRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {passengerIngalaRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {searchPassengerRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {changeCabinRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {historicalCruiseRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}
            {advanceCruiseRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}
            {statisticReportRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {familyRoomRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {spaceRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            {spaceFlightRoutes.map((route, i) => (
              <Route
                key={i}
                exact
                path={route.path}
                component={route.component}
              />
            ))}

            
          </Switch>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}


