import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import UserService from "../../../../services/catalogs/UserService";

export const REQUEST_CREATE_USER = "REQUEST_CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

function requestCreateUser() {
  return {
    type: REQUEST_CREATE_USER,
  };
}

function createUserSuccess(json) {
  return {
    type: CREATE_USER_SUCCESS,
    message: json.data,
  };
}

function createUserError(json) {
  return {
    type: CREATE_USER_ERROR,
    error: json.data,
  };
}

export function createAgencyUser(agency_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateUser());
    return UserService.createAgencyUser(agency_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createUserSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createAgencyUser(agency_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createUserError(json.data));
      }
    });
  };
}

export function createCompanyUser(payload) {
  return (dispatch) => {
    dispatch(requestCreateUser());
    return UserService.createCompanyUser(payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(createUserSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(createCompanyUser(payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(createUserError(json.data));
      }
    });
  };
}
