import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IkarusRateService from "../../../../services/rates/IkarusRateService";

export const REQUEST_FETCH_IKARUS_RATE = "REQUEST_FETCH_IKARUS_RATE";
export const FETCH_IKARUS_RATE_SUCCESS = "FETCH_IKARUS_RATE_SUCCESS";
export const FETCH_IKARUS_RATE_ERROR = "FETCH_IKARUS_RATE_ERROR";
export const INITIAL_STATE_IKARUS_RATE = "INITIAL_STATE_IKARUS_RATE";

function requestFetchIkarusRate() {
  return {
    type: REQUEST_FETCH_IKARUS_RATE,
  };
}

function fetchIkarusRateSuccess(json) {
  return {
    type: FETCH_IKARUS_RATE_SUCCESS,
    ikarusRate: json.data,
  };
}

function fetchIkarusRateError(json) {
  return {
    type: FETCH_IKARUS_RATE_ERROR,
    error: json.data,
  };
}

export function initialStateIkarusRate() {
  return {
    type: INITIAL_STATE_IKARUS_RATE,
  };
}

export function fetchIkarusRate(ikarusRate_id) {
  return (dispatch) => {
    dispatch(requestFetchIkarusRate());
    return IkarusRateService.getIkarusRate(ikarusRate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchIkarusRateSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchIkarusRate(ikarusRate_id));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(fetchIkarusRateError(json.data));
      }
    });
  };
}
