import {
  REQUEST_FETCH_ORDER_SERVICE,
  FETCH_ORDER_SERVICE_SUCCESS,
  FETCH_ORDER_SERVICE_ERROR,
  INITIAL_STATE_ORDER_SERVICE,
} from "../../../actions/reservations/orderService/fetchOrderService";

const initialState = {
  orderService: {},
  fetching: false,
  message: "",
  error: "",
};

const orderServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_ORDER_SERVICE: {
      return {
        ...state,
        orderService: {},
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_ORDER_SERVICE: {
      return {
        ...state,
        orderService: {},
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_ORDER_SERVICE_SUCCESS: {
      return {
        ...state,
        orderService: action.orderService,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case FETCH_ORDER_SERVICE_ERROR: {
      return {
        ...state,
        orderService: {},
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export default orderServiceReducer;
