import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Input, Header, Segment, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { fetchItineraries } from "../../../redux/actions/catalogs/itinerary/fetchItineraries";
import { deleteItinerary } from "../../../redux/actions/catalogs/itinerary/deleteItinerary";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function ItineraryList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itineraryState = useSelector((state) => state.itineraryReducer);
  const { register, getValues } = useForm();

  useEffect(() => {
    dispatch(fetchItineraries(""));
    scrollToTop();
  }, []);

  if (itineraryState.fetching || itineraryState.processing) {
    return <Loader active inline />;
  }

  if (itineraryState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (itineraryState.message === "resource deleted successfully") {
    dispatch(fetchItineraries(""));
  }

  const handleDeleteItinerary = (itinerary_id) => {
    dispatch(deleteItinerary(itinerary_id));
  };

  const handleSearchItinerary = () => {
    dispatch(fetchItineraries(getValues("searchWord")));
  };

  return (
    <>
      <Segment clearing> 
        <HeaderCatalog title={t("itinerary_title")} message={itineraryState.message} to="/itineraries_new" />
        <Header as='h5' floated='right'>
          <Input type="text" placeholder={t("search") + "..."} action>
            <input name="searchWord" maxLength={10} ref={register()} />
            <Button type="submit" onClick={() => handleSearchItinerary()} icon="search" size="medium" />
          </Input>
        </Header>
      </Segment>
      
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("itinerary_name")}</Table.HeaderCell>
            <Table.HeaderCell>{t("itinerary_code")}</Table.HeaderCell>
            <Table.HeaderCell>{t("itinerary_yacht")}</Table.HeaderCell>
            <Table.HeaderCell>{t("itinerary_days")}</Table.HeaderCell>
            <Table.HeaderCell>{t("itinerary_nights")}</Table.HeaderCell>
            <Table.HeaderCell>{t("itinerary_start_day")}</Table.HeaderCell>
            <Table.HeaderCell>{t("itinerary_end_day")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {itineraryState.itineraries.map((itinerary) => (
          <Table.Row key={itinerary.id}>
            <Table.Cell collapsing><ActiveIcon active={itinerary.active} /></Table.Cell>
            <Table.Cell><Link to={`/itinerary/${itinerary.id}/days`}>{itinerary.name}</Link></Table.Cell>
            <Table.Cell>{itinerary.code}</Table.Cell>
            <Table.Cell collapsing>
              {itinerary.yachts.length >= 1 ? (
                <Link to={`/itineraries/${itinerary.id}/yachts`}>
                  {itinerary.yachts.map((yacht) => (
                    <div>{yacht}</div>
                  ))}
                </Link>
              ) : (
                <Link to={`/itineraries/${itinerary.id}/yachts`}>
                  <Button circular color='blue' icon='add' size="mini" />
                </Link>
              )}
            </Table.Cell>
            <Table.Cell>{itinerary.number_days}</Table.Cell>
            <Table.Cell>{itinerary.number_nights}</Table.Cell>
            <Table.Cell>{t(itinerary.start_day)}</Table.Cell>
            <Table.Cell>{t(itinerary.end_day)}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/itineraries/${itinerary.id}/itinerary_map`}><Button circular color='green' icon='map' /></Link>
              <Link to={`/itineraries/${itinerary.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("itinerary_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteItinerary(itinerary.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
