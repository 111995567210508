import GuideList from "../containers/catalogs/guide/GuideList";
import GuideForm from "../containers/catalogs/guide/GuideForm";

const routes = [
  {
    path: "/guides",
    component: GuideList,
  },
  {
    path: "/guides_new",
    component: GuideForm,
  },
  {
    path: "/guides/:guide_id",
    component: GuideForm,
  },
];

export { routes as guideRoutes };
