import {
  REQUEST_FETCH_DAYS,
  FETCH_DAYS_SUCCESS,
  FETCH_DAYS_ERROR,
} from "../../../actions/catalogs/day/fetchDays";

import {
  INITIAL_STATE_DAY,
  REQUEST_FETCH_DAY,
  FETCH_DAY_SUCCESS,
  FETCH_DAY_ERROR,
} from "../../../actions/catalogs/day/fetchDay";

import {
  REQUEST_CREATE_DAY,
  CREATE_DAY_SUCCESS,
  CREATE_DAY_ERROR,
} from "../../../actions/catalogs/day/createDay";

import {
  REQUEST_UPDATE_DAY,
  UPDATE_DAY_SUCCESS,
  UPDATE_DAY_ERROR,
} from "../../../actions/catalogs/day/updateDay";

import {
  REQUEST_DELETE_DAY,
  DELETE_DAY_SUCCESS,
  DELETE_DAY_ERROR,
} from "../../../actions/catalogs/day/deleteDay";

const initialState = {
  days: [],
  day: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const dayReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_DAY: {
      return {
        ...state,
        days: [],
        day: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_DAYS: {
      return {
        ...state,
        days: [],
        day: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_DAYS_SUCCESS: {
      return {
        ...state,
        days: action.days,
        day: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_DAYS_ERROR: {
      return {
        ...state,
        days: [],
        day: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_DAY: {
      return {
        ...state,
        days: [],
        day: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_DAY_SUCCESS: {
      return {
        ...state,
        days: [],
        day: action.day,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_DAY_ERROR: {
      return {
        ...state,
        days: [],
        day: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_DAY: {
      return {
        ...state,
        days: [],
        day: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_DAY_SUCCESS: {
      return {
        ...state,
        days: [],
        day: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_DAY_ERROR: {
      return {
        ...state,
        days: [],
        day: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_DAY: {
      return {
        ...state,
        days: [],
        day: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_DAY_SUCCESS: {
      return {
        ...state,
        days: [],
        day: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_DAY_ERROR: {
      return {
        ...state,
        days: [],
        day: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_DAY: {
      return {
        ...state,
        days: [],
        day: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_DAY_SUCCESS: {
      return {
        ...state,
        days: [],
        day: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_DAY_ERROR: {
      return {
        ...state,
        days: [],
        day: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default dayReducer;
