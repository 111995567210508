import React, { Component } from "react";
import { Menu, Visibility } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import "../containers/styles/common.css";

const menuStyle = {
  border: "grove #E0E0E0",
  borderRadius: 0,
  transition: "box-shadow 0.3s ease, padding 0.3s ease",
  backgroundColor: "#FF5733",
  height: "80px",
  width: "100%",
};

const fixedMenuStyle = {
  backgroundColor: "#FF5733",
  border: "1px solid #ddd",
  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
  height: "60px",
  paddingLeft: "50px",
};

class HeadDetail extends Component {
  state = {
    menuFixed: false,
    overlayFixed: false,
  };

  stickOverlay = () => this.setState({ overlayFixed: true });

  stickTopMenu = () => this.setState({ menuFixed: true });

  unStickOverlay = () => this.setState({ overlayFixed: false });

  unStickTopMenu = () => this.setState({ menuFixed: false });

  render() {
    const { menuFixed } = this.state;
    const { t } = this.props;

    return (
      <div>
        <Visibility
          onBottomPassed={this.stickTopMenu}
          onBottomVisible={this.unStickTopMenu}
          once={false}
        >
          <Menu
            borderless
            compact
            style={
              (menuFixed ? fixedMenuStyle : menuStyle,
              {
                display: "flex",
                flexWrap: "wrap",
                backgroundColor: "#3f83c2",
              })
            }
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <div className="head_cruise">
                <Menu.Item className="head_style_title">
                  {t("head_detail_yacht") + ":"}
                </Menu.Item>

                <Menu.Item className="yacht_text">
                  {this.props.yacht_name}
                </Menu.Item>
              </div>
              <div className="head_date">
                <Menu.Item className="head_style_title">
                  {t("head_detail_startDate")}:
                </Menu.Item>
                <Menu.Item className="head_style_value">{this.props.start_date}</Menu.Item>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <div className="head_cruise">
                <Menu.Item className="head_style_title">
                  {t("head_detail_itinerary")}:
                </Menu.Item>

                <Menu.Item className="head_style_value">{this.props.itinerary_name}</Menu.Item>
              </div>

              <div className="head_date">
                <Menu.Item className="head_style_title">
                  {t("head_detail_endDate")}:
                </Menu.Item>
                <Menu.Item className="end_date_text head_style_value">
                  {this.props.end_date}
                </Menu.Item>
              </div>
            </div>
          </Menu>
        </Visibility>
      </div>
    );
  }
}

export default withTranslation()(HeadDetail);
