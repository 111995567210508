import React from "react";
import "../containers/styles/common.css";
import phone from "../containers/icons/icon_phoneo.png";
import pin from "../containers/icons/icon_pin.png";
import clock from "../containers/icons/icon_clock.png";
import mail from "../containers/icons/icon_mail.png";
import fb from "../containers/icons/social_facebook_circle.png";
import instagram from "../containers/icons/social_instagram_circle.png";

export default function HeadTipTopCruise() {
  return (
    <header className="mkdf-page-header">
      <div className="mkdf-menu-area">
        <div className="icon-cruise-tip-top">
          <a
            itemProp="url"
            href="https://www.tiptopcruises.com/"
            style={{ height: "75px", width: "75px" }}
          >
            <img
              itemProp="image"
              className="mkdf-normal-logo"
              src="https://www.tiptopcruises.com/wp-content/uploads/2017/08/logo1-square-150x150_357f.png"
              width="90"
              height="90"
              alt="logo"
              // style={{ paddingLeft: "20px", paddingTop: "20px" }}
            />
          </a>
        </div>
        <div className="menu-cruise-tip-top">
          <div className="menu-black">
            <div className="menu-black-right">
              <div className="menu-black-right-items">
                <div className="menu-black-right-icon">
                  <a href="https://tiptopcruises.com/contact-us">
                    <img src={phone} alt="main" />
                  </a>
                </div>
                <div className="menu-black-right-icon">
                  <a
                    className="menu-black-right-a"
                    href="https://tiptopcruises.com/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (+ 593 2) 256 3181 – 2 256 3098
                  </a>
                </div>
              </div>
              <div className="menu-black-right-items">
                <div className="menu-black-right-icon">
                  <a href="https://tiptopcruises.com/contact-us">
                    <img src={pin} alt="main" />
                  </a>
                </div>

                <div className="menu-black-right-icon">
                  <a
                    className="menu-black-right-a"
                    href="https://tiptopcruises.com/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mariscal Foch E7-81 & Diego de Almagro
                  </a>
                </div>
              </div>
              <div className="menu-black-right-items">
                <div className="menu-black-right-icon">
                  <a href="https://tiptopcruises.com/contact-us">
                    <img src={clock} alt="main" />
                  </a>
                </div>
                <div className="menu-black-right-icon">
                  <a
                    className="menu-black-right-a"
                    href="https://www.tiptopcruises.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Monday – Friday : 09.00 – 18.00
                  </a>
                </div>
              </div>
              <div className="menu-black-right-items">
                <div className="menu-black-right-icon">
                  <a href="https://tiptopcruises.com/contact-us">
                    <img src={mail} alt="main" />
                  </a>
                </div>
                <div className="menu-black-right-icon">
                  <a
                    className="menu-black-right-a"
                    href="https://tiptopcruises.com/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sign up to newsletter
                  </a>
                </div>
              </div>
            </div>
            <div className="menu-black-left">
              <div className="menu-black-left-icon">
                <a href="https://www.facebook.com/TipTopCruises">
                  <img src={fb} alt="main" />
                </a>
              </div>
              <div className="menu-black-left-icon">
                <a href="https://www.instagram.com/tiptop_cruises/">
                  <img src={instagram} alt="main" />
                </a>
              </div>
            </div>
          </div>
          <div className="down_menu">
            <nav className="main-menu-tip-top">
              <ul className="main-menu-tip-top-row">
                <li className="main-menu-tip-top-cell">
                  <a href="https://tiptopcruises.com/">
                    <span className="item_outer">
                      <span className="item_text">Home</span>
                    </span>
                  </a>
                </li>
                <li className="main-menu-tip-top-cell">
                  <a href="https://www.tiptopcruises.com/about-us/">
                    <span className="item_outer">
                      <span className="item_text">About Us</span>
                    </span>
                  </a>
                </li>
                <li className="main-menu-tip-top-cell">
                  <a href="https://www.tiptopcruises.com/galapagos/">
                    <span className="item_outer">
                      <span className="item_text">Galápagos</span>
                    </span>
                  </a>
                  <div
                    className="second"
                    style={{
                      height: "0",
                      visibility: "hidden",
                      opacity: "0",
                      overflow: "hidden",
                    }}
                  >
                    <div className="inner">
                      <ul>
                        <li>
                          <a href="https://tiptopcruises.com/galapagos/">
                            <span className="item_outer">
                              <span className="item_text">
                                Island Descriptic
                              </span>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.tiptopcruises.com/why-to-choose-a-first-class-boat/">
                            <span className="item_outer">
                              <span className="item_text">
                                Why to choose a first class boat
                              </span>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="main-menu-tip-top-cell">
                  <a href="https://www.tiptopcruises.com/land-services/">
                    <span className="item_outer">
                      <span className="item_text">Land Services</span>
                    </span>
                  </a>
                </li>
                <li className="main-menu-tip-top-cell">
                  <a href="https://www.tiptopcruises.com/#">
                    <span className="item_outer">
                      <span className="item_text">Promotions</span>
                    </span>
                  </a>
                  <div
                    className="second"
                    style={{
                      height: "0",
                      visibility: "hidden",
                      opacity: "0",
                      overflow: "hidden",
                    }}
                  >
                    <div className="inner">
                      <ul>
                        <li>
                          <a href="https://www.tiptopcruises.com/tip-top-ii-promotions/">
                            <span className="item_outer">
                              <span className="item_text">
                                Tip Top II Promotions
                              </span>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.tiptopcruises.com/tip-top-iv-promotions/">
                            <span className="item_outer">
                              <span className="item_text">
                                Tip Top IV Promotions
                              </span>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.tiptopcruises.com/tip-top-v-promotions-2/">
                            <span className="item_outer">
                              <span className="item_text">
                                Tip Top V Promotions
                              </span>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.tiptopcruises.com/promotions/charters-for-families/">
                            <span className="item_outer">
                              <span className="item_text">
                                Charters for families
                              </span>
                            </span>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.tiptopcruises.com/promotions/photography-tour-2021/">
                            <span className="item_outer">
                              <span className="item_text">
                                Photography Tour 2021
                              </span>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="main-menu-tip-top-cell">
                  <a href="https://tiptopcruises.com/contact-us">
                    <span className="item_outer">
                      <span className="item_text">Contact Us</span>
                    </span>
                  </a>
                </li>
                <li className="main-menu-tip-top-cell">
                  <a href="https://www.tiptopcruises.com/my-account/">
                    <span className="item_outer">
                      <span className="item_text">My Account</span>
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
