import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import DeckService from "../../../../services/catalogs/DeckService";

export const REQUEST_DELETE_DECK = "REQUEST_DELETE_DECK";
export const DELETE_DECK_SUCCESS = "DELETE_DECK_SUCCESS";
export const DELETE_DECK_ERROR = "DELETE_DECK_ERROR";

function requestDeleteDeck() {
  return {
    type: REQUEST_DELETE_DECK,
  };
}

function deleteDeckSuccess(json) {
  return {
    type: DELETE_DECK_SUCCESS,
    message: json.data,
  };
}

function deleteDeckError(json) {
  return {
    type: DELETE_DECK_ERROR,
    error: json.data,
  };
}

export function deleteDeck(deck_id) {
  return (dispatch) => {
    dispatch(requestDeleteDeck());
    return DeckService.deleteDeck(deck_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteDeckSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteDeck(deck_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteDeckError(json.data));
      }
    });
  };
}
