
import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

export default function ActiveIcon(props) {
    const { t } = useTranslation();
    return (
      <>
        {props.active === 1 ? (
          <Icon fitted circular size='mini' inverted color="green" title={t("active_tag")} />
        ) : (
          <Icon disabled circular size='mini' inverted color="grey" title={t("inactive_tag")}/>
        )}
      </>
    );
  }