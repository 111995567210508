import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class CruiseLanguageService {
  static getCruiseLanguages(cruise_id) {
    return axios.get("cruise/" + cruise_id + "/languages");
  }
  static getCruiseLanguage(cruise_id, id) {
    return axios.get("cruise/" + cruise_id + "/language/" + id);
  }

  static postCruiseLanguage(cruise_id, payload) {
    return axios.post(
      "cruise/" + cruise_id + "/languages",
      payload
    );
  }

  static putCruiseLanguage(cruise_id, id, payload) {
    return axios.put(
      "cruise/" + cruise_id + "/language/" + id,
      payload
    );
  }

  static deleteCruiseLanguage(cruise_id, id) {
    return axios.delete(
      "cruise/" + cruise_id + "/language/" + id
    );
  }

  static getItemsByCruise(cruise_id) {
    return axios.get("/languages/logs/cruses/"+ cruise_id);
  }
}
