import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import CabinService from "../../../../services/catalogs/CabinService";

export const REQUEST_DELETE_CABIN = "REQUEST_DELETE_CABIN";
export const DELETE_CABIN_SUCCESS = "DELETE_CABIN_SUCCESS";
export const DELETE_CABIN_ERROR = "DELETE_CABIN_ERROR";

function requestDeleteCabin() {
  return {
    type: REQUEST_DELETE_CABIN,
  };
}

function deleteCabinSuccess(json) {
  return {
    type: DELETE_CABIN_SUCCESS,
    message: json.data,
  };
}

function deleteCabinError(json) {
  return {
    type: DELETE_CABIN_ERROR,
    error: json.data,
  };
}

export function deleteCabin(cabin_id) {
  return (dispatch) => {
    dispatch(requestDeleteCabin());
    return CabinService.deleteCabin(cabin_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteCabinSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteCabin(cabin_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteCabinError(json.data));
      }
    });
  };
}
