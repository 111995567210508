import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import GeneralRateService from "../../../../services/rates/GeneralRateService";

export const REQUEST_INACTIVE_GENERAL_RATE = "REQUEST_INACTIVE_GENERAL_RATE";
export const INACTIVE_GENERAL_RATE_SUCCESS = "INACTIVE_GENERAL_RATE_SUCCESS";
export const INACTIVE_GENERAL_RATE_ERROR = "INACTIVE_GENERAL_RATE_ERROR";

function requestInactiveGeneralRate() {
  return {
    type: REQUEST_INACTIVE_GENERAL_RATE,
  };
}

function inactiveGeneralRateSuccess(json) {
  return {
    type: INACTIVE_GENERAL_RATE_SUCCESS,
    message: json.data,
  };
}

function inactiveGeneralRateError(json) {
  return {
    type: INACTIVE_GENERAL_RATE_ERROR,
    error: json.data,
  };
}

export function inactiveGeneralRate(generalRate_id, payload) {
  return (dispatch) => {
    dispatch(requestInactiveGeneralRate());
    return GeneralRateService.inactiveGeneralRate(generalRate_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(inactiveGeneralRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(inactiveGeneralRate(generalRate_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(inactiveGeneralRateError(json.data));
      }
    });
  };
}
