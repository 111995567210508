import CharterRateService from "../../../../services/rates/CharterRateService";

export const REQUEST_FETCH_YEARS = "REQUEST_FETCH_YEARS";
export const FETCH_YEARS_SUCCESS = "FETCH_YEARS_SUCCESS";
export const FETCH_YEARS_ERROR = "FETCH_CHARTER_YEARS_ERROR";

function requestFetchYears() {
  return {
    type: REQUEST_FETCH_YEARS,
  };
}

function fetchYearsSuccess(json) {
  return {
    type: FETCH_YEARS_SUCCESS,
    years: json.data,
  };
}

function fetchYearsError(json) {
  return {
    type: FETCH_YEARS_ERROR,
    error: json.data,
  };
}

export function fetchYears() {
  return (dispatch) => {
    dispatch(requestFetchYears());
    return CharterRateService.getYears().then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(fetchYearsSuccess(json.data));
        } else {
          dispatch(fetchYearsError(json.data));
        }
      }
    );
  };
}
