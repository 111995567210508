import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function ReservationList() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleScrollUp = () => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader inset component="div" id="nested-list-subheader">
          {t("reservation_tag")}
        </ListSubheader>
      }
      className={classes.root}
    >
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={t("cruises_tag")} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/"
            onClick={() => handleScrollUp()}
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("cruise_tag")} />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/passengers"
            onClick={() => handleScrollUp()}
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("passengers_tag")} />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/historicalCruises/TipTop"
            onClick={() => handleScrollUp()}
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("historicalCruises_tag")} />
          </ListItem>

          <ListItem
            button
            className={classes.nested}
            component={Link}
            to="/availabilityReport"
            onClick={() => handleScrollUp()}
          >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary={t("availabilityReport_tag")} />
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        button
        component={Link}
        to="/advanceReport"
        onClick={() => handleScrollUp()}
      >
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={t("advanceCruise_tag")} />
      </ListItem>
      <ListItem
        button
        component={Link}
        to="/statisticReport"
        onClick={() => handleScrollUp()}
      >
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={t("statistics_tag")} />
      </ListItem>
    </List>
  );
}
