import HistoricalTipTopList from "../containers/reservations/cruise/historicalCruise/HistoricalTipTopList";

const routes = [
  {
    path: "/historicalCruises/TipTop",
    component: HistoricalTipTopList,
  },
];

export { routes as historicalCruiseRoutes };
