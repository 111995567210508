import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class IkarusRateService {
  static getIkarusRates(payload) {
    const params = new URLSearchParams([['year', payload.year], ['days', payload.days], ['agency_id', payload.agency_id], ['yacht_id', payload.yacht_id]]);
    return axios.get("ikarusRates", { params });
  }
  
  static getYears() {
    return axios.get("ikarusRates/year");
  }

  static getIkarusRate(ikarusRate_id) {
    return axios.get("ikarusRates/" + ikarusRate_id);
  }

  static findIkarusRateByCruiseAgencyCruiseType(cruise_id, payload) {
    return axios.post("ikarusRates/find_ikarus_rate_by_cruise_agency_cruiseType/" + cruise_id, payload);
  }

  static postIkarusRate(payload) {
    return axios.post("ikarusRates", payload);
  }

  static putIkarusRate(ikarusRate_id, payload) {
    return axios.put("ikarusRates/" + ikarusRate_id, payload);
  }

  static activeIkarusRate(ikarusRate_id) {
    return axios.put("ikarusRates/" + ikarusRate_id + "/activeIkarusRate");
  }

  static inactiveIkarusRate(ikarusRate_id, payload) {
    return axios.put("ikarusRates/" + ikarusRate_id + "/inactiveIkarusRate", payload);
  }

  static deleteIkarusRate(ikarusRate_id) {
    return axios.delete("ikarusRates/" + ikarusRate_id);
  }
}
