import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import YachtItineraryService from "../../../../services/catalogs/YachtItineraryService";

export const REQUEST_CREATE_YACHT_ITINERARY = "REQUEST_CREATE_YACHT_ITINERARY";
export const CREATE_YACHT_ITINERARY_SUCCESS = "CREATE_YACHT_ITINERARY_SUCCESS";
export const CREATE_YACHT_ITINERARY_ERROR = "CREATE_YACHT_ITINERARY_ERROR";

function requestCreateYachtItinerary() {
  return {
    type: REQUEST_CREATE_YACHT_ITINERARY,
  };
}

function createYachtItinerarySuccess(json) {
  return {
    type: CREATE_YACHT_ITINERARY_SUCCESS,
    message: json.data,
  };
}

function createYachtItineraryError(json) {
  return {
    type: CREATE_YACHT_ITINERARY_ERROR,
    error: json.data,
  };
}

export function createYachtItinerary(itinerary_id, payload) {
  return (dispatch) => {
    dispatch(requestCreateYachtItinerary());
    return YachtItineraryService.postYachtItinerary(itinerary_id, payload).then(
      (json) => {
        if (json.data.code === 200) {
          dispatch(createYachtItinerarySuccess(json.data));
        } else if (json.data.code === 202) {
          updateLocalStorageToken(json.data.token);
          dispatch(createYachtItinerary(itinerary_id, payload));
        } else if (json.data.code === 498) {
          logOut();
        } else {
          dispatch(createYachtItineraryError(json.data));
        }
      }
    );
  };
}
