import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//UI
import { Accordion, Loader} from "semantic-ui-react";
import { Collapse } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Button, Icon, Modal, Form } from "semantic-ui-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import useStyles from "../../../styles/Common";
import "../../../styles/common.css";
//ACTIONS
import { fetchReservationLogs } from "../../../../redux/actions/reservations/reservation/fetchReservationLogs";
import { initialStateReservation } from "../../../../redux/actions/reservations/reservation/fetchReservation"
import { canceledReservation } from "../../../../redux/actions/reservations/reservation/canceledReservation";
import { fetchReservationListCustom, initialStateReservationListCustom } from "../../../../redux/slices/custom/reservations/cruise/reservation/customReservationList.slice";
//RESOURCES
import HeadDetail from "../../../../components/headDetail";
import DetailRatePopup from "../../../../components/rates/detailRatePopup";
import LogsHistory from '../../../../components/reservation/logsHistory';
import { scrollToTop } from "../../../resources/utils";

export default function ReservationList() {
  let { cruise_id } = useParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm();
  const reservationStateCustom = useSelector((state) => state.customReservationList);
  const reservationState = useSelector((state) => state.reservationReducer);
  const reservationLogsState = useSelector((state) => state.reservationLogReducer);
  const [reservationId, setReservationId] = useState("");;
  const [noteCanceled, setNoteCanceled] = useState();
  const [openHistory, setOpenHistory] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReservationListCustom(cruise_id));
    scrollToTop();
    return () => {
      dispatch(initialStateReservationListCustom());
    };
  }, [dispatch, cruise_id]);

  if (reservationStateCustom.fetching) {
    return <CircularProgress />;
  }

  if (reservationStateCustom.processing) {
    return <CircularProgress />;
  }
  if (reservationStateCustom.error) {
    return <div>Error: {reservationStateCustom.error}</div>;
  }

  const onSubmit = (data) => {
    dispatch(canceledReservation(reservationId, data));
    setNoteCanceled();
  };

  if (reservationState.message === "resource canceled successfully") {
    dispatch(fetchReservationListCustom(cruise_id));
    dispatch(fetchReservationLogs(cruise_id));
    dispatch(initialStateReservation());
  }

  const handleHistoyClick = () => {
    setOpenHistory(!openHistory);
    if (!openHistory ) {
      dispatch(fetchReservationLogs(cruise_id));
    } 
};

  return (
    <>
      <div className="ui breadcrumb">
        <Link to={`/cruises/${cruise_id}/cruise-detail`}>
          <div className="active section">{reservationStateCustom.cruise.code}</div>
        </Link>
        <div className="divider">/</div>
        <div className="active section">{t("reservation_title")}</div>
      </div>
      <br />
      <br />
      <br />
      <HeadDetail
        yacht_name={reservationStateCustom.cruise.yacht_name}
        itinerary_name={reservationStateCustom.cruise.itinerary_name}
        start_date={reservationStateCustom.cruise.start_date_format}
        end_date={reservationStateCustom.cruise.end_date_format}
      />
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("reservation_title")} {reservationStateCustom.message}
            <Link to={`/cruises/${cruise_id}/reservations_new`}>
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                className={classes.margin}
              >
                <AddIcon />
              </Fab>
            </Link>
          </Typography>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
              {t("reservation_code")}
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_date")}
                <br></br>
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </TableCell>
              <TableCell align="center">
                {t("reservation_executive")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_agency")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_type")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_price")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_notes")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_r")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_c")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_e")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_cabins")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reservationStateCustom.reservations.map((reservation) => (
              <TableRow key={reservation.id}>
                <TableCell align="center">
                 {reservation.id}
                </TableCell>
                <TableCell align="center">
                  {reservation.creation_date}
                </TableCell>
                <TableCell align="center">{reservation.user}</TableCell>
                <TableCell align="center">{reservation.agency_name}</TableCell>
                <TableCell align="center"><div>{reservation.cruise_type}</div> {reservation.charter == true ? (<span className="text_date_format"> {t("charter").toUpperCase()}</span>) : (null)} </TableCell>
                <TableCell align="center"><b><div style={{ color: "#1261A0" }}>$ {reservation.client_price} <DetailRatePopup spaces={reservation.spaces} client_price={reservation.client_price} operator_price={reservation.operator_price} /></div></b><span className="text_date_format">Operadora: $ {reservation.operator_price}</span></TableCell>
                <TableCell width="250px" align="justify">
                  {reservation.notes}
                </TableCell>
                <TableCell align="center">{reservation.R}</TableCell>
                <TableCell align="center">{reservation.C}</TableCell>
                <TableCell align="center">{reservation.E}</TableCell>

                <TableCell align="center">
                  {" "}
                  {reservation.cabins !== null ? (
                    <Link
                      to={`/cruises/${cruise_id}/reservations/${reservation.id}/bookings`}
                    >
                      {reservation.cabins}
                    </Link>
                  ) : (
                    <Link
                      to={`/cruises/${cruise_id}/reservations/${reservation.id}/bookings`}
                    >
                      <Button className="add_button" icon circular size="mini">
                        <Icon className="icon_color" name="add" />
                      </Button>
                    </Link>
                  )}
                </TableCell>
                <TableCell align="right">
                  <Link
                    to={`/cruises/${cruise_id}/reservations/${reservation.id}`}
                  >
                    <Button className="edit_button" icon>
                      <Icon className="icon_color" name="pencil alternate" />
                    </Button>
                  </Link>
                  <Modal
                    trigger={
                      <Button
                        className="add_button"
                        icon
                        onClick={() => setReservationId(reservation.id)}
                      >
                        <Icon name="times circle" className="icon_color" />
                      </Button>
                    }
                    header={
                      <p
                        style={{
                          color: "black",
                          fontSize: "18px",
                          fontWeight: "bold",
                          paddingTop: "15px",
                          paddingLeft: "20px",
                        }}
                      >
                        {t("reservation_cancelation_message")}
                      </p>
                    }
                    content={
                      <Form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          paddingBottom: "25px",
                        }}
                      >
                        <textarea
                          rows="3"
                          name="reason_cancellation"
                          defaultValue={noteCanceled}
                          style={{ minHeight: 200 }}
                          placeholder={t("reservation_canceled_text")}
                          ref={register({ required: true })}
                        />
                        {errors.reason_cancellation && (
                          <span className="error_message">
                            {t("required_information")}
                          </span>
                        )}
                        <br></br>
                        <br></br>
                        <button
                          type="submit"
                          className={
                            reservationStateCustom.processing
                              ? "ui loading primary button"
                              : "ui primary button"
                          }
                        >
                          {t("cancel_reservation_button")}
                        </button>
                      </Form>
                    }
                    actions={[t("close_button")]}
                    size="tiny"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br></br>
      <TableContainer component={Paper}>
        <Toolbar>
          <Typography
            className={classes.title}
            variant="h6"
            noWrap
            style={{ color: "red" }}
          >
            {t("reservation_canceled")} {reservationStateCustom.message}
          </Typography>
        </Toolbar>
        <Divider variant="middle" />
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
               <TableCell align="center">
                {t("reservation_code")}
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_date")}
                <br></br>
                <span className="text_date_format">(dd-mm-yyyy)</span>
              </TableCell>
              <TableCell align="center">
                {t("reservation_executive")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_agency")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_canceled_date")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_cancel_executive")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_type")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_price")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_notes")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_r")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_c")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_e")}
                <br></br>
                <br></br>
              </TableCell>
              <TableCell align="center">
                {t("reservation_cabins")}
                <br></br>
                <br></br>
              </TableCell>
            </TableRow>
          </TableHead>
          {reservationStateCustom.reservationsCanceled.map((reservation) => (
            <TableBody key={reservation.id}>
              <TableRow>
                <TableCell>{reservation.id}</TableCell>
                <TableCell>{reservation.creation_date}</TableCell>
                <TableCell>{reservation.user}</TableCell>
                <TableCell>{reservation.agency_name}</TableCell>
                <TableCell>{reservation.date_cancellation}</TableCell>
                <TableCell>N</TableCell>
                <TableCell>{reservation.cruise_type}</TableCell>
                <TableCell>{reservation.client_price}</TableCell>
                <TableCell>{reservation.notes}</TableCell>
                <TableCell>{reservation.R}</TableCell>
                <TableCell>{reservation.C}</TableCell>
                <TableCell>{reservation.E}</TableCell>
                <TableCell align="center">{reservation.cabins}</TableCell>
              </TableRow>
              <TableRow
                style={{ borderBottom: "solid 2px rgba(85, 77, 77, 0.87)" }}
              >
                <TableCell
                  colSpan="2"
                  style={{
                    color: "rgba(0, 0, 0, 0.87)",
                    fontWeight: "500",
                  }}
                >
                  {t("resevation_cancelation_reason")} :
                </TableCell>
                <TableCell colSpan="11">
                  {reservation.reason_cancellation}
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      <br />
      <br />
      <Accordion styled style={{ width: "91vw" }}>
        <Accordion.Title onClick={handleHistoyClick} style={{ display: "flex", gap: "5px" }}>
          {t("log_historical")}
          {openHistory ? <ExpandLess /> : <ExpandMore />}
        </Accordion.Title>
        {reservationLogsState.fetching && openHistory ? (
          <div style={{ display: "flex", width: "91vw", height: "80px", justifyContent: "center" }}>
            <Loader size="medium" active inline>Loading...</Loader>
          </div>
        ) : (
          <Collapse in={openHistory} timeout="auto" unmountOnExit style={{ padding: "15px" }}>
            <LogsHistory
               logsHistory={reservationLogsState.logs}
             /> 
          </Collapse>
        )}
      </Accordion>
    </>
  );
}
