import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";

//UI
import { Form, Divider, Header, Loader, Button, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

//Actions
import { createIdentificationType } from "../../../redux/actions/catalogs/identificationType/createIdentificationType";
import { fetchIdentificationType, initialStateIdentificationType } from "../../../redux/actions/catalogs/identificationType/fetchIdentificationType";
import { updateIdentificationType } from "../../../redux/actions/catalogs/identificationType/updateIdentificationType";

// Resources
import { scrollToTop } from "../../resources/utils";
import { identificationTypes } from "../../resources/optionsList";

export default function IdentificationTypeForm() {
  const { t } = useTranslation();
  let history = useHistory();
  let { identification_type_id } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const identificationTypeState = useSelector((state) => state.identificationTypeReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (identification_type_id) {
      dispatch(fetchIdentificationType(identification_type_id));
    }
    scrollToTop();
    return () => {
      dispatch(initialStateIdentificationType());
    };
  }, []);

  if (identificationTypeState.fetching) {
    return <Loader active inline />;
  }

  if (identificationTypeState.error) {
    return <Message max negative
    header='Error!'
    content={t("server_error_form")}
  />
  }

  const onSubmit = (data) => {
    if (identification_type_id) {
      dispatch(updateIdentificationType(identification_type_id, data));
    } else {
      dispatch(createIdentificationType(data));
    }
  };

  if (identificationTypeState.message === "resource created successfully" || identificationTypeState.message === "resource updated successfully") {
    history.push("/identificationTypes");
  }

  return (
    <>
      <Header as='h3' dividing>{t("identificationType_title")}</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group widths="equal">
          <Form.Field required>
            <label>{t("identificationType_name")}</label>
            <input name="name" defaultValue={identificationTypeState.identificationType.name} placeholder={t("identificationType_name")} autoFocus maxLength={100} ref={register({ required: true })} />
            {errors.name && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
          <Form.Field required>
            <label>{t("identificationType_type")}</label>
            <select name="type" defaultValue={identificationTypeState.identificationType.type} aria-expanded="false" ref={register({ required: true })}>
              <option></option>
              {identificationTypes.map((identificationType, index) => (
                <option key={index} value={identificationType}>{t(identificationType)}</option>
              ))}
            </select>
            {errors.type && (<span className="error_message">{t("required_information")}</span>)}
          </Form.Field>
        </Form.Group>

        <Divider variant="middle" />

        <Button primary type="submit" loading={identificationTypeState.processing ? true : false}>{t("save_button")}</Button>
        <Link to={"/identificationTypes"}><Button>{t("canceled_button")}</Button></Link>
      </Form>
    </>
  );
}
