import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

//UI
import { Button, Modal, Table, Loader, Input, Header, Segment, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import HeaderCatalog from "../../../components/headerCatalog";
import ActiveIcon from "../../../components/activeIcon";

//Actions
import { fetchFlights } from "../../../redux/actions/catalogs/flight/fetchFlights";
import { deleteFlight } from "../../../redux/actions/catalogs/flight/deleteFlight";

// Resources
import { scrollToTop } from "../../resources/utils";

export default function FlightList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const flightState = useSelector((state) => state.flightReducer);
  const { register, getValues } = useForm();
  
  useEffect(() => {
    dispatch(fetchFlights(""));
    scrollToTop();
  }, []);

  if (flightState.fetching || flightState.processing) {
    return <Loader active inline />;
  }

  if (flightState.error) {
    return <Message negative
    header='Error!'
    content={t("server_error_list")}
  />
  }

  if (flightState.message === "resource deleted successfully") {
    dispatch(fetchFlights(""));
  }

  const handleDeleteFlight = (flight_id) => {
    dispatch(deleteFlight(flight_id));
  };

  const handleSearchFlight = () => {
    dispatch(fetchFlights(getValues("searchWord")));
  };

  return (
    <>
      <Segment clearing> 
        <HeaderCatalog title={t("flight_title")} message={flightState.message} to="/flights_new" />
        <Header as='h5' floated='right'>
          <Input type="text" placeholder={t("search") + "..."} action>
            <input name="searchWord" maxLength={10} ref={register()} />
            <Button type="submit" onClick={() => handleSearchFlight()} icon="search" size="medium" />
          </Input>
        </Header>
      </Segment>
      
      <Table color="blue" striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{t("flight_code")}</Table.HeaderCell>
            <Table.HeaderCell>{t("flight_airline")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {flightState.flights.map((flight) => (
          <Table.Row key={flight.id}>
            <Table.Cell collapsing><ActiveIcon active={flight.active} /></Table.Cell>
            <Table.Cell>{flight.code}</Table.Cell>
            <Table.Cell>{flight.airline_name}</Table.Cell>
            <Table.Cell collapsing>
              <Link to={`/flights/${flight.id}`}><Button circular color='blue' icon='pencil alternate' /></Link>
              <Modal
                trigger={
                  <Button circular color='red' icon='trash alternate' />
                }
                content={t("flight_delete")}
                actions={[
                  t("canceled_button"),
                    {
                      key: "eliminar",
                      content: t("delete_button"),
                      className: "delete_button icon_color",
                      onClick: () => handleDeleteFlight(flight.id),
                    },
                ]}
                size="tiny"
              />
            </Table.Cell>
          </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
}
