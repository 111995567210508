import axios from "axios";
import { API_BASE_URL } from "../constants";

export default class FlightService {
  static getFlights(searchWord) {
    var url = "flights";
    url = url + `?searchWord=${searchWord}`;
    return axios.get(url);
  }
  static getFlight(flight_id) {
    return axios.get("flights/" + flight_id);
  }

  static postFlight(payload) {
    return axios.post("flights", payload);
  }

  static putFlight(flight_id, payload) {
    return axios.put("flights/" + flight_id, payload);
  }

  static deleteFlight(flight_id) {
    return axios.delete("flights/" + flight_id);
  }
}
