import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import IkarusRateService from "../../../../services/rates/IkarusRateService";

export const REQUEST_INACTIVE_IKARUS_RATE = "REQUEST_INACTIVE_IKARUS_RATE";
export const INACTIVE_IKARUS_RATE_SUCCESS = "INACTIVE_IKARUS_RATE_SUCCESS";
export const INACTIVE_IKARUS_RATE_ERROR = "INACTIVE_IKARUS_RATE_ERROR";

function requestInactiveIkarusRate() {
  return {
    type: REQUEST_INACTIVE_IKARUS_RATE,
  };
}

function inactiveIkarusRateSuccess(json) {
  return {
    type: INACTIVE_IKARUS_RATE_SUCCESS,
    message: json.data,
  };
}

function inactiveIkarusRateError(json) {
  return {
    type: INACTIVE_IKARUS_RATE_ERROR,
    error: json.data,
  };
}

export function inactiveIkarusRate(ikarusRate_id, payload) {
  return (dispatch) => {
    dispatch(requestInactiveIkarusRate());
    return IkarusRateService.inactiveIkarusRate(ikarusRate_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(inactiveIkarusRateSuccess(json.data));
      } else if(json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(inactiveIkarusRate(ikarusRate_id, payload));
      } else if(json.data.code === 498){
        logOut();
      } else {
        dispatch(inactiveIkarusRateError(json.data));
      }
    });
  };
}
