import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import AgencyService from "../../../../services/catalogs/AgencyService";

export const REQUEST_DELETE_AGENCY = "REQUEST_DELETE_AGENCY";
export const DELETE_AGENCY_SUCCESS = "DELETE_AGENCY_SUCCESS";
export const DELETE_AGENCY_ERROR = "DELETE_AGENCY_ERROR";

function requestDeleteAgency() {
  return {
    type: REQUEST_DELETE_AGENCY,
  };
}

function deleteAgencySuccess(json) {
  return {
    type: DELETE_AGENCY_SUCCESS,
    message: json.data,
  };
}

function deleteAgencyError(json) {
  return {
    type: DELETE_AGENCY_ERROR,
    error: json.data,
  };
}

export function deleteAgency(agency_id) {
  return (dispatch) => {
    dispatch(requestDeleteAgency());
    return AgencyService.deleteAgency(agency_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(deleteAgencySuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(deleteAgency(agency_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(deleteAgencyError(json.data));
      }
    });
  };
}
