import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import AirlineService from "../../../../services/catalogs/AirlineService";

export const REQUEST_UPDATE_AIRLINE = "REQUEST_UPDATE_AIRLINE";
export const UPDATE_AIRLINE_SUCCESS = "UPDATE_AIRLINE_SUCCESS";
export const UPDATE_AIRLINE_ERROR = "UPDATE_AIRLINE_ERROR";

function requestUpdateAirline() {
  return {
    type: REQUEST_UPDATE_AIRLINE,
  };
}

function updateAirlineSuccess(json) {
  return {
    type: UPDATE_AIRLINE_SUCCESS,
    message: json.data,
  };
}

function updateAirlineError(json) {
  return {
    type: UPDATE_AIRLINE_ERROR,
    error: json.data,
  };
}

export function updateAirline(airline_id, payload) {
  return (dispatch) => {
    dispatch(requestUpdateAirline());
    return AirlineService.putAirline(airline_id, payload).then((json) => {
      if (json.data.code === 200) {
        dispatch(updateAirlineSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(updateAirline(airline_id, payload));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(updateAirlineError(json.data));
      }
    });
  };
}
