import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useForm } from "react-hook-form";
import "../containers/styles/common.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries } from "../redux/actions/catalogs/country/fetchCountries";
import { fetchGenders } from "../redux/actions/catalogs/gender/fetchGenders";
import { fetchRoutes } from "../redux/actions/catalogs/route/fetchRoutes";
import { fetchSeasons } from "../redux/actions/rates/season/fetchSeasons";
import { fetchTickets } from "../redux/actions/rates/ticket/fetchTickets";
import { fetchIdentificationTypes } from "../redux/actions/catalogs/identificationType/fetchIdentificationTypes";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { status } from "../containers/resources/optionsList";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "10%",
    marginTop: -12,
    marginLeft: -82,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "25ch",
  },
}));

export default function PassengerForm(props) {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const { t } = useTranslation();
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const countryState = useSelector((state) => state.countryReducer);
  const genderState = useSelector((state) => state.genderReducer);
  const routeState = useSelector((state) => state.routeReducer);
  const [idForm, setIdForm] = useState();
  const [cabin, setCabin] = useState();
  const [editMessage, setEditMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [disabledSecondSecction, setDisabledSecondSection] = useState(true);
  const [gpsTax, setGpsTax] = useState(0);
  const [tct, setTct] = useState(0);
  const [gender, setGender] = useState("");
  const [genderId, setGenderId] = useState("");
  const [country, setCountry] = useState("");
  const [departureRoute, setDepartureRoute] = useState("");
  const [returnRoute, setReturnRoute] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [genderAlert, setgenderAlert] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [cruiseDate, setCruiseDate] = useState("");
  const [ticket, setTicket] = useState(0);
  const seasonState = useSelector((state) => state.seasonReducer);
  const ticketState = useSelector((state) => state.ticketReducer);
  const identificationTypeState = useSelector(
    (state) => state.identificationTypeReducer
  );
  const [identificationTypeId, setIdentificationTypeId] = useState("");
  let passengers = JSON.parse(sessionStorage.getItem("passengers"));
  let cruise = JSON.parse(sessionStorage.getItem("cruise")) || [];
  let actualYear = new Date().getFullYear();
  let actualMonth = new Date().getMonth() + 1;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchGenders());
    dispatch(fetchRoutes());
    dispatch(fetchSeasons());
    dispatch(fetchTickets("si", actualYear.toString(), "General"));
    dispatch(fetchIdentificationTypes());
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [dispatch, actualYear]);

  if (
    countryState.fetching ||
    genderState.fetching ||
    routeState.fetching ||
    seasonState.fetching ||
    ticketState.fetching ||
    identificationTypeState.fetching
  ) {
    return <CircularProgress />;
  }

  if (countryState.error) {
    return <div>Error: {countryState.error}</div>;
  }

  if (genderState.error) {
    return <div>Error: {genderState.error}</div>;
  }

  if (routeState.error) {
    return <div>Error: {routeState.error}</div>;
  }

  if (seasonState.error) {
    return <div>Error: {seasonState.error}</div>;
  }

  if (ticketState.error) {
    return <div>Error: {ticketState.error}</div>;
  }

  if (identificationTypeState.error) {
    return <div>Error: {identificationTypeState.error}</div>;
  }

  identificationTypeState.identificationTypes.map((identificationType) => {
    if (
      identificationType.name === "Pasaporte" &&
      identificationTypeId === ""
    ) {
      setIdentificationTypeId(identificationType.id);
    }
    return 1;
  });

  const handleShowMessage = (newState) => {
    setState({ open: true, ...newState });
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 6000);
  };

  const getGenderName = (id) => {
    let gender = document.getElementById(id);
    let genderName = gender.options[gender.selectedIndex].text;

    if (genderName !== "") {
      setGender(genderName);
    } else {
      document.getElementById(props.index + "1").value = "";
      document.getElementById(props.index + "2").value = "";
      document.getElementById(props.index + "3").value = "";
      document.getElementById(props.index + "4").value = "";
      setDisabledSecondSection(true);
    }

    if (
      genderName !== "" &&
      country !== "" &&
      departureRoute !== "" &&
      returnRoute !== ""
    ) {
      handleGetPriceAirTicket(
        genderName,
        country,
        actualYear,
        actualMonth,
        departureRoute,
        returnRoute
      );
    }
  };

  const getCountryName = (id) => {
    const countrySelected = document.getElementById(id);
    let countryName =
      countrySelected.options[countrySelected.selectedIndex].text;

    if (countryName !== "") {
      setCountry(countryName);
    } else {
      document.getElementById(props.index + "1").value = "";
      document.getElementById(props.index + "2").value = "";
      document.getElementById(props.index + "3").value = "";
      document.getElementById(props.index + "4").value = "";
      setDisabledSecondSection(true);
    }

    if (gender !== "" && countryName !== "") {
      setDisabledSecondSection(false);
      handleGetTaxAndGps(cruiseDate, gender, countryName);
      setShowMessage(false);
    }

    if (
      gender !== "" &&
      countryName !== "" &&
      departureRoute !== "" &&
      returnRoute !== ""
    ) {
      handleGetPriceAirTicket(
        gender,
        countryName,
        actualYear,
        actualMonth,
        departureRoute,
        returnRoute
      );
    }
  };

  async function handleGetTaxAndGps(e, gender, country) {
    if (cruise.startDate === e.split("-").reverse().join("-")) {
      setShowMessage(false);
      const result = await getTctAndGPS(gender, country);
      if (result === "resolved") {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
    } else {
      setShowMessage(true);
      setTct(0);
      setGpsTax(0);
      setCruiseDate("");
    }
  }

  const getTctAndGPS = (genderName, countryName) => {
    return new Promise((resolve) => {
      let newGender = genderState.genders.filter((e) => e.name === genderName);
      let newCountry = countryState.countries.filter(
        (e) => e.name === countryName
      );

      if (newCountry[0].name === "Ecuador") {
        setGpsTax(newGender[0].gps_tax_local);
        setTct(newGender[0].tct);
      } else {
        if (newCountry[0].andean_pact === 1) {
          setGpsTax(newGender[0].gps_tax_foreign_andean_pact);
          setTct(newGender[0].tct);
        } else {
          setGpsTax(newGender[0].gps_tax_foreign);
          setTct(newGender[0].tct);
        }
      }
      resolve("resolved");
    });
  };

  const getDepartureRouteName = (id) => {
    const departureRoute = document.getElementById(id);
    let departureRouteName =
      departureRoute.options[departureRoute.selectedIndex].text;
    setDepartureRoute(departureRouteName.toUpperCase());

    if (
      gender !== "" &&
      country !== "" &&
      returnRoute !== "" &&
      departureRouteName !== ""
    ) {
      handleGetPriceAirTicket(
        gender,
        country,
        actualYear,
        actualMonth,
        departureRouteName,
        returnRoute
      );
    } else {
      setTicket(0);
    }
  };

  const getReturnRouteName = (id) => {
    const returnRoute = document.getElementById(id);
    let returnRouteName = returnRoute.options[returnRoute.selectedIndex].text;
    setReturnRoute(returnRouteName.toUpperCase());

    if (
      gender !== "" &&
      country !== "" &&
      returnRouteName !== "" &&
      departureRoute !== ""
    ) {
      handleGetPriceAirTicket(
        gender,
        country,
        actualYear,
        actualMonth,
        departureRoute,
        returnRouteName
      );
    } else {
      setTicket(0);
    }
  };

  async function handleGetPriceAirTicket(g, c, y, m, d, r) {
    const result = await getAirTicketPrice(g, c, y, m, d, r);
    if (result === "resolved") {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }

  const getAirTicketPrice = (
    genderName,
    countryName,
    year,
    month,
    departureRouteFlight,
    returnRouteFlight
  ) => {
    return new Promise((resolve) => {
      //GET SEASON

      var seasonSelected = seasonState.seasons.filter(
        (e) => e.year === year.toString()
      );
      var monthName = Object.keys(seasonSelected[0])[month + 1];
      var seasonName = seasonSelected[0][monthName];

      //GET AIR TICKET RATE
      let ticketSelected = ticketState.tickets.filter(
        (e) =>
          `${e.departureFlight_first_route} - ${e.departureFlight_second_route}` ===
            departureRouteFlight.toUpperCase() &&
          `${e.returnFlight_first_route} - ${e.returnFlight_second_route}` ===
            returnRouteFlight.toUpperCase()
      );

      if (ticketSelected.length > 0) {
        if (genderName === "Niño") {
          if (countryName === "Ecuador") {
            if (seasonName === "season_low") {
              setTicket(
                ticketSelected[0].passengerPrice_nationalChild_lowSeason
              );
            } else {
              setTicket(
                ticketSelected[0].passengerPrice_nationalChild_highSeason
              );
            }
          } else {
            if (seasonName === "season_low") {
              setTicket(
                ticketSelected[0].passengerPrice_foreingChild_lowSeason
              );
            } else {
              setTicket(
                ticketSelected[0].passengerPrice_foreingChild_highSeason
              );
            }
          }
        } else {
          if (countryName === "Ecuador") {
            if (seasonName === "season_low") {
              setTicket(
                ticketSelected[0].passengerPrice_nationalAdult_lowSeason
              );
            } else {
              setTicket(
                ticketSelected[0].passengerPrice_nationalAdult_highSeason
              );
            }
          } else {
            if (seasonName === "season_low") {
              setTicket(
                ticketSelected[0].passengerPrice_foreingAdult_lowSeason
              );
            } else {
              setTicket(
                ticketSelected[0].passengerPrice_foreingAdult_highSeason
              );
            }
          }
        }
      }

      resolve("resolved");
    });
  };

  const onSubmit = (data) => {
    if (genderId !== props.gender) {
      setgenderAlert("don't equal");
      handleShowMessage({
        vertical: "top",
        horizontal: "right",
      });
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        window.scrollTo(0, 0);
      }
    } else {
      setDisabled(false);
      passengers.passengers.push({
        id: idForm,
        cabin: cabin,
        ...data,
        gender_name: gender,
        country_name: country,
        departure_name: departureRoute,
        return_name: returnRoute,
        ticket: true,
        gpsTax: gpsTax,
        tct: tct,
        ticketPrice: ticket,
        identification_type_id: identificationTypeId,
        cabin_id: props.cabin_id,
      });
      sessionStorage.setItem("passengers", JSON.stringify(passengers));
      setEditMessage("edit");
      setGender("");
      setCountry("");
      setDepartureRoute("");
      setReturnRoute("");
    }
  };

  if (editMessage === "edit" && disabled === false) {
    setDisabled(true);
  }

  const editPassenger = (idEdit) => {
    setEditMessage("");
    setDisabled(false);
    passengers = passengers = JSON.parse(sessionStorage.getItem("passengers"));
    let index = passengers.passengers.findIndex((e) => e.id === idEdit);
    passengers.passengers.splice(index, 1);
    sessionStorage.setItem("passengers", JSON.stringify(passengers));
  };

  return (
    <>
      {genderAlert === "don't equal" && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical, horizontal }}
          key={vertical + horizontal}
        >
          <Alert severity="info" color="error" variant="filled">
            {t("gender_selected_message")}
          </Alert>
        </Snackbar>
      )}
      <div>
        <div style={{ fontSize: "18px", fontWeight: "bold", color: "#2C8ABD" }}>
          {" "}
          {t("passenger_cabin")} {props.cabin} - {t("passenger_info")}{" "}
          {props.index + 1}
          {"      "}
          <Link
            to="#"
            style={{
              color: "teal",
              fontSize: "15px",
              textDecoration: "underline",
              paddingLeft: "16px",
            }}
            onClick={() => editPassenger(props.id)}
          >
            {"    "}
            {editMessage === "edit" && "(" + t("edit_passenger") + ")"}
          </Link>
        </div>
        <br />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.root}>
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("passenger_first_name")}*</label>
                <input
                  type="text"
                  placeholder={t("passenger_first_name")}
                  name="first_name"
                  ref={register({ required: true })}
                  disabled={disabled}
                />
                {errors.first_name ? (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                ) : (
                  <span className="error_message">
                    {t("passenger_name_message")}
                  </span>
                )}
              </Form.Field>

              <Form.Field>
                <label>{t("passenger_last_name")}*</label>
                <input
                  disabled={disabled}
                  placeholder={t("passenger_last_name")}
                  name="last_name"
                  ref={register({ required: true })}
                />
                {errors.last_name ? (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                ) : (
                  <span className="error_message">
                    {t("passenger_name_message")}
                  </span>
                )}
              </Form.Field>

              <Form.Field>
                <label>{t("passenger_passport_number")}*</label>
                <input
                  disabled={disabled}
                  placeholder={t("passenger_passport_number")}
                  name="passport_number"
                  ref={register({ required: true })}
                />
                {errors.passport_number && (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                )}
              </Form.Field>

              <Form.Field>
                <label>{t("passenger_email")}*</label>
                <input
                  disabled={disabled}
                  placeholder={t("passenger_email")}
                  name="passenger_email"
                  ref={register({ required: true })}
                />
                {errors.passenger_email && (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("passenger_gender")}*</label>
                <select
                  id={props.index}
                  disabled={disabled}
                  aria-expanded="true"
                  name="gender_id"
                  ref={register({ required: true })}
                  onChange={(e) => {
                    getGenderName(props.index);
                    setGenderId(e.target.value);
                  }}
                >
                  <option></option>
                  {genderState.genders.map((gender) => (
                    <option key={gender.id} value={gender.id}>
                      {t(gender.name)}
                    </option>
                  ))}
                </select>

                {errors.gender_id && (
                  <>
                    <span className="error_message">
                      {t("required_information")}
                    </span>
                    <br />
                  </>
                )}

                {gender === "Niño" && (
                  <>
                    <span className="error_message">
                      {t("child_gender_message")}
                    </span>
                    <br />
                  </>
                )}

                {props.gender !== genderId && genderId !== "" ? (
                  <span className="error_message">
                    {t("gender_selected_message")}
                  </span>
                ) : (
                  ""
                )}
              </Form.Field>

              <Form.Field>
                <label>{t("passenger_nationality")}*</label>
                <select
                  id={props.index + "0"}
                  disabled={disabled}
                  aria-expanded="true"
                  name="country_id"
                  ref={register({ required: true })}
                  onChange={() => {
                    getCountryName(props.index + "0");
                  }}
                >
                  <option></option>
                  {countryState.countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {t(country.name)}
                    </option>
                  ))}
                </select>
                {errors.country_id && (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                )}
              </Form.Field>

              <Form.Field>
                <label>
                  {t("passenger_birthdate")}*{" "}
                  <span className="text_date_format">(dd-mm-yyyy)</span>
                </label>
                <input
                  disabled={disabled}
                  type="date"
                  placeholder={t("passenger_birthdate")}
                  name="birthdate"
                  ref={register({ required: true })}
                />
                {errors.birthdate && (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                )}
              </Form.Field>

              <Form.Field>
                <label>{t("passenger_marital_satatus")}*</label>
                <select
                  disabled={disabled}
                  aria-expanded="true"
                  name="status"
                  ref={register({ required: true })}
                >
                  <option></option>
                  {status.map((e, index) => (
                    <option key={index} value={e.value}>
                      {t(e.name)}
                    </option>
                  ))}
                </select>
                {errors.status && (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                )}
              </Form.Field>
            </Form.Group>
            <br />
            <div
              style={{ fontSize: "15px", fontWeight: "bold", color: "#2C8ABD" }}
            >
              {t("passenger_include_tickets")} :
            </div>
            <br />
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("passenger_departure_flight")}*</label>
                <select
                  id={props.index + "1"}
                  disabled={
                    disabled === true
                      ? disabled
                      : disabledSecondSecction === true &&
                        disabledSecondSecction
                  }
                  aria-expanded="true"
                  name="departure"
                  ref={register({ required: true })}
                  onChange={() => getDepartureRouteName(props.index + "1")}
                >
                  <option></option>
                  {routeState.routes.map((route) => (
                    <option key={route.id} value={route.id}>
                      {route.origin_city} {"(" + route.origin_code + ")"} -{" "}
                      {t(route.destination_city)}{" "}
                      {"(" + route.destination_code + ")"}
                    </option>
                  ))}
                </select>
                {errors.departure && (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                )}
              </Form.Field>

              <Form.Field>
                <label>
                  {t("passenger_route_date")}*{" "}
                  <span className="text_date_format">(dd-mm-yyyy)</span>
                </label>
                <input
                  id={props.index + "3"}
                  disabled={
                    disabled === true
                      ? disabled
                      : disabledSecondSecction === true &&
                        disabledSecondSecction
                  }
                  type="date"
                  placeholder={t("passenger_route_date")}
                  name="date_departure"
                  ref={register({ required: true })}
                  onChange={(e) => {
                    handleGetTaxAndGps(e.target.value, gender, country);
                    setCruiseDate(e.target.value);
                  }}
                />
                {errors.date_departure && (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                )}
              </Form.Field>

              <Form.Field>
                <label>{t("passenger_return_flight")}*</label>
                <select
                  id={props.index + "2"}
                  disabled={
                    disabled === true
                      ? disabled
                      : disabledSecondSecction === true &&
                        disabledSecondSecction
                  }
                  aria-expanded="true"
                  name="return"
                  ref={register({ required: true })}
                  onChange={() => getReturnRouteName(props.index + "2")}
                >
                  <option></option>
                  {routeState.routes.map((route) => (
                    <option key={route.id} value={route.id}>
                      {route.origin_city} {"(" + route.origin_code + ")"} -{" "}
                      {route.destination_city}{" "}
                      {"(" + route.destination_code + ")"}
                    </option>
                  ))}
                </select>
                {errors.return && (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                )}
              </Form.Field>

              <Form.Field>
                <label>
                  {t("passenger_route_date")}*{" "}
                  <span className="text_date_format">(dd-mm-yyyy)</span>
                </label>
                <input
                  id={props.index + "4"}
                  disabled={
                    disabled === true
                      ? disabled
                      : disabledSecondSecction === true &&
                        disabledSecondSecction
                  }
                  type="date"
                  placeholder={t("passenger_route_date")}
                  name="return_date"
                  autoFocus
                  ref={register({ required: true })}
                />
                {errors.return_date && (
                  <span className="error_message">
                    {t("required_information")}
                  </span>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>{t("passenger_aditional_notes")}</label>
              <textarea
                disabled={disabled}
                rows={3}
                placeholder={t("passenger_aditional_notes")}
                autoFocus
                name="message"
                ref={register}
              ></textarea>
            </Form.Field>
            <br />
            <div style={{ fontWeight: "bold" }}>
              <div>
                {t("passenger_air_ticket")}:
                <span
                  style={{
                    color: "#2C8ABD",
                    fontSize: "23px",
                    paddingLeft: "7px",
                  }}
                >
                  $ {ticket}
                </span>{" "}
              </div>
              <br />
              <div>
                GPS TAX:
                <span
                  style={{
                    color: "#2C8ABD",
                    fontSize: "23px",
                    paddingLeft: "30px",
                  }}
                >
                  $ {gpsTax}
                </span>{" "}
              </div>
              <br />

              <div>
                TCT:{" "}
                <span
                  style={{
                    color: "#2C8ABD",
                    fontSize: "23px",
                    paddingLeft: "56px",
                  }}
                >
                  {" "}
                  $ {tct}
                </span>
              </div>
            </div>

            {showMessage === true ? (
              <>
                <br />
                <span style={{ color: "red" }}>
                  {t("message_pay_values")}
                </span>{" "}
                <br />
              </>
            ) : (
              ""
            )}
            <br />
            <button
              disabled={
                disabled === true
                  ? disabled
                  : disabledButton === true
                  ? disabledButton
                  : false
              }
              className="ui teal button"
              type="submit"
              onClick={() => {
                setIdForm(props.id);
                setCabin(props.cabin);
              }}
            >
              {t("passenger_button")}
            </button>
            <br></br>
            <br></br>
            <div
              style={{
                height: "5px",
                width: "100%",
                backgroundColor: "#DCDCDC",
              }}
            ></div>
            <br></br>
            <br></br>
          </div>
        </Form>
      </div>
    </>
  );
}
