import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import ContractRateService from "../../../../services/rates/ContractRateService";

export const REQUEST_ACTIVE_CONTRACT_RATE = "REQUEST_ACTIVE_CONTRACT_RATE";
export const ACTIVE_CONTRACT_RATE_SUCCESS = "ACTIVE_CONTRACT_RATE_SUCCESS";
export const ACTIVE_CONTRACT_RATE_ERROR = "ACTIVE_CONTRACT_RATE_ERROR";

function requestActiveContractRate() {
  return {
    type: REQUEST_ACTIVE_CONTRACT_RATE,
  };
}

function activeContractRateSuccess(json) {
  return {
    type: ACTIVE_CONTRACT_RATE_SUCCESS,
    message: json.data,
  };
}

function activeContractRateError(json) {
  return {
    type: ACTIVE_CONTRACT_RATE_ERROR,
    error: json.data,
  };
}

export function activeContractRate(contractRate_id) {
  return (dispatch) => {
    dispatch(requestActiveContractRate());
    return ContractRateService.activeContractRate(contractRate_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(activeContractRateSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(activeContractRate(contractRate_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(activeContractRateError(json.data));
      }
    });
  };
}
