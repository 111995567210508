import {
  REQUEST_FETCH_SPACES,
  FETCH_SPACES_SUCCESS,
  FETCH_SPACES_ERROR,
} from "../../../actions/reservations/space/fetchSpaces";

import {
  INITIAL_STATE_SPACE,
  REQUEST_FETCH_SPACE,
  FETCH_SPACE_SUCCESS,
  FETCH_SPACE_ERROR,
} from "../../../actions/reservations/space/fetchSpace";

import {
  REQUEST_CREATE_SPACE,
  CREATE_SPACE_SUCCESS,
  CREATE_SPACE_ERROR,
} from "../../../actions/reservations/space/createSpace";

import {
  REQUEST_UPDATE_SPACE,
  UPDATE_SPACE_SUCCESS,
  UPDATE_SPACE_ERROR,
} from "../../../actions/reservations/space/updateSpace";

import {
  REQUEST_DELETE_PASSENGER_BY_SPACE,
  DELETE_PASSENGER_BY_SPACE_SUCCESS,
  DELETE_PASSENGER_BY_SPACE_ERROR,
} from "../../../actions/reservations/spacePassenger/deletePassengerBySpace";

import {
  REQUEST_CANCELED_SPACE,
  CANCELED_SPACE_SUCCESS,
  CANCELED_SPACE_ERROR,
} from "../../../actions/reservations/space/canceledSpace.js";

import {
  REQUEST_UPDATE_DISCOUNT_PRICE,
  UPDATE_DISCOUNT_PRICE_SUCCESS,
  UPDATE_DISCOUNT_PRICE_ERROR,
} from "../../../actions/reservations/space/updateDiscountPrice";

const initialState = {
  spaces: [],
  space: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const spaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_SPACE: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }

    case REQUEST_FETCH_SPACES: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACES_SUCCESS: {
      return {
        ...state,
        spaces: action.spaces,
        space: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACES_ERROR: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_SPACE: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACE_SUCCESS: {
      return {
        ...state,
        spaces: [],
        space: action.space,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_SPACE_ERROR: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_SPACE: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_SPACE_SUCCESS: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_SPACE_ERROR: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_SPACE: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_SPACE_SUCCESS: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_SPACE_ERROR: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_PASSENGER_BY_SPACE: {
      return {
        spaces: [],
        space: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_PASSENGER_BY_SPACE_SUCCESS: {
      return {
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_PASSENGER_BY_SPACE_ERROR: {
      return {
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CANCELED_SPACE: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CANCELED_SPACE_SUCCESS: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CANCELED_SPACE_ERROR: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_DISCOUNT_PRICE: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_DISCOUNT_PRICE_SUCCESS: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_DISCOUNT_PRICE_ERROR: {
      return {
        ...state,
        spaces: [],
        space: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default spaceReducer;
