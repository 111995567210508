import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";
import RateService from "../../../../services/catalogs/RateService";

export const REQUEST_FETCH_RATES = "REQUEST_FETCH_RATES ";
export const FETCH_RATES_SUCCESS = "FETCH_RATES_SUCCESS";
export const FETCH_RATES_ERROR = "FETCH_RATES_ERROR";
export const INITIAL_STATE_RATE = "INITIAL_STATE_RATE";

function requestFetchRates() {
  return {
    type: REQUEST_FETCH_RATES,
  };
}

function fetchRatesSuccess(json) {
  return {
    type: FETCH_RATES_SUCCESS,
    rates: json.data,
  };
}

function fetchRatesError(json) {
  return {
    type: FETCH_RATES_ERROR,
    error: json.data,
  };
}

export function initialStateRate() {
  return {
    type: INITIAL_STATE_RATE,
  };
}

export function fetchRates(specialRate) {
  return (dispatch) => {
    dispatch(requestFetchRates());
    return RateService.getRates(specialRate).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchRatesSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchRates(specialRate));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchRatesError(json.data));
      }
    });
  };
}
