import CruisesService from '../../../../services/reservations/CruiseService';
import { logOut, updateLocalStorageToken } from "../../../../containers/resources/utils";

export const REQUEST_FETCH_CRUISE_LOGS = "REQUEST_FETCH_CRUISE_LOGS";
export const FETCH_CRUISE_LOGS_SUCCESS = "FETCH_CRUISE_LOGS_SUCCESS";
export const FETCH_CRUISE_LOGS_ERROR = "FETCH_CRUISE_LOGS_ERROR";

function requestFetchCruiseLogs() {
  return {
    type: REQUEST_FETCH_CRUISE_LOGS,
  };
}

function fetchCruiseLogsSuccess(json) {
  return {
    type: FETCH_CRUISE_LOGS_SUCCESS,
    logs: json.data,
  };
}

function fetchCruiseLogsError(json) {
  return {
    type: FETCH_CRUISE_LOGS_ERROR,
    error: json.data,
  };
}

export function fetchCruiseLogs(cruise_id) {
  return (dispatch) => {
    dispatch(requestFetchCruiseLogs());
    return CruisesService.getItemsByCruise(cruise_id).then((json) => {
      if (json.data.code === 200) {
        dispatch(fetchCruiseLogsSuccess(json.data));
      } else if (json.data.code === 202) {
        updateLocalStorageToken(json.data.token);
        dispatch(fetchCruiseLogs(cruise_id));
      } else if (json.data.code === 498) {
        logOut();
      } else {
        dispatch(fetchCruiseLogsError(json.data));
      }
    });
  };
}
