import {
  REQUEST_FETCH_FLIGHTS,
  FETCH_FLIGHTS_SUCCESS,
  FETCH_FLIGHTS_ERROR,
} from "../../../actions/catalogs/flight/fetchFlights";

import {
  INITIAL_STATE_FLIGHT,
  REQUEST_FETCH_FLIGHT,
  FETCH_FLIGHT_SUCCESS,
  FETCH_FLIGHT_ERROR,
} from "../../../actions/catalogs/flight/fetchFlight";

import {
  REQUEST_CREATE_FLIGHT,
  CREATE_FLIGHT_SUCCESS,
  CREATE_FLIGHT_ERROR,
} from "../../../actions/catalogs/flight/createFlight";

import {
  REQUEST_UPDATE_FLIGHT,
  UPDATE_FLIGHT_SUCCESS,
  UPDATE_FLIGHT_ERROR,
} from "../../../actions/catalogs/flight/updateFlight";

import {
  REQUEST_DELETE_FLIGHT,
  DELETE_FLIGHT_SUCCESS,
  DELETE_FLIGHT_ERROR,
} from "../../../actions/catalogs/flight/deleteFlight";

const initialState = {
  flights: [],
  flight: {},
  processing: false,
  fetching: false,
  message: "",
  error: "",
};

const flightReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_STATE_FLIGHT: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case REQUEST_FETCH_FLIGHTS: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_FLIGHTS_SUCCESS: {
      return {
        ...state,
        flights: action.flights,
        flight: {},
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_FLIGHTS_ERROR: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_FETCH_FLIGHT: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: false,
        fetching: true,
        message: "",
        error: "",
      };
    }
    case FETCH_FLIGHT_SUCCESS: {
      return {
        ...state,
        flights: [],
        flight: action.flight,
        processing: false,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case FETCH_FLIGHT_ERROR: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_CREATE_FLIGHT: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case CREATE_FLIGHT_SUCCESS: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case CREATE_FLIGHT_ERROR: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_UPDATE_FLIGHT: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case UPDATE_FLIGHT_SUCCESS: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case UPDATE_FLIGHT_ERROR: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    case REQUEST_DELETE_FLIGHT: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: true,
        fetching: false,
        message: "",
        error: "",
      };
    }
    case DELETE_FLIGHT_SUCCESS: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: false,
        fetching: false,
        message: action.message,
        error: "",
      };
    }
    case DELETE_FLIGHT_ERROR: {
      return {
        ...state,
        flights: [],
        flight: {},
        processing: false,
        fetching: false,
        message: "",
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default flightReducer;
