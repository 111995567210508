import jsPDF from "jspdf";
import "jspdf-autotable";
import rwittmer from "../../../icons/rwittmer.jpg";
import tiptoptravel from "../../../icons/TipTopTravel.jpg";
import path from "path";

function convertToEnglish(text) {
  text = text.split("LUNES").join("MONDAY");
  text = text.split("MARTES").join("TUESDAY");
  text = text.split("MIÉRCOLES").join("WEDNESDAY");
  text = text.split("JUEVES").join("THURSDAY");
  text = text.split("VIERNES").join("FRIDAY");
  text = text.split("SÁBADO").join("SATURDAY");
  text = text.split("DOMINGO").join("SUNDAY");

  text = text.split("OPCIÓN").join("OPTION");
  text = text.split("RUTA").join("ROUTE");
  text = text.split("CRUCERO CORTADO").join("SPLIT CRUISE");
  return text;
}

function addFooter(doc) {
  for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
    doc.setPage(i);

    doc.line(20, 816, 570, 816);

    doc.setFontSize(10);
    var date = new Date();
    var currentDate =
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    doc.text(20, 830, currentDate);

    doc.setFontSize(10);
    doc.setFont("helvetica",'bold');
    doc.text(
      500,
      830,
      "Página " + i + " de " + doc.internal.getNumberOfPages()
    );
  }
}

function createSignTable(doc) {
  var columnsSign = [{ title: "", dataKey: "sign" }];

  var rowsSign = [
    { sign: "          Authorized Signature" },
    { sign: "" },
    { sign: "____________________________" },
    { sign: "        f.p. Enrique Wittmer G." },
    { sign: "          General Manager" },
  ];

  doc.autoTable(columnsSign, rowsSign, {
    didDrawPage: function () {
      return false; // Don't draw header row
    },
    startY: doc.lastAutoTable.finalY + 30,
    columnStyles: {
      sign: {
        fillColor: [255, 255, 255],
        fontStyle: "bold",
        textColor: [0, 0, 0],
      },
    },
    styles: {
      overflow: "linebreak",
      fontSize: 9,
    },
  });
}

export default function VoucherPDF(cruise, company, voucher) {
  var reportLogoRolfWittmer = new Image();
  reportLogoRolfWittmer.src = path.resolve(rwittmer);
  var reportLogoTipTipTravel = new Image();
  reportLogoTipTipTravel.src = path.resolve(tiptoptravel);
  var doc = new jsPDF("p", "pt");

  // HEADER

  // IMAGE (LOGO)

  if (cruise.yacht_name === "NORTADA") {
    doc.addImage(reportLogoTipTipTravel, "JPEG", 400, 20, 200, 75);
  } else {
    doc.addImage(reportLogoRolfWittmer, "JPEG", 320, 20, 236, 46);
  }

  doc.setFontSize(18);
  doc.setFont("helvetica", "bold");
  doc.text("VOUCHER.", 40, 34);
  doc.setFontSize(16);

  doc.text(cruise.yacht_code, 40, 55);

  if (company.code === "rw") {
    doc.setFontSize(8);
    doc.text("RWTG-REG-51", 40, 70);
  }

  var columnsHeader = [
    { title: "", dataKey: "label" },
    { title: "", dataKey: "name" },
  ];

  var itinerary = convertToEnglish(cruise.itinerary_name);
  var startDate = cruise.start_date_format;
  var endDate = cruise.end_date_format;

  var rowsHeader = [
    { label: "Cruise Date", name: startDate + " - " + endDate },
    { label: "Itinerary", name: itinerary },
    { label: "For", name: voucher.agency_name },
  ];

  doc.autoTable(columnsHeader, rowsHeader, {
    didDrawPage: function () {
      return false; // Don't draw header row
    },
    startY: 100,
    styles: {
      lineColor: [0, 0, 0],
      lineWidth: 0.0,
      fontSize: 12,
    },
    columnStyles: {
      label: { cellWidth: 90, textColor: [0, 0, 0] },
      name: { textColor: [0, 0, 0] },
    },
  });

  doc.line(
    25,
    doc.lastAutoTable.finalY + 10,
    570,
    doc.lastAutoTable.finalY + 10
  );
  doc.line(
    25,
    doc.lastAutoTable.finalY + 11,
    570,
    doc.lastAutoTable.finalY + 11
  );

  // included

  var columnsIncluded = [{ title: "Services Included", dataKey: "included" }];
  var rowsIncluded = [{ included: voucher.included }];
  doc.autoTable(columnsIncluded, rowsIncluded, {
    startY: doc.lastAutoTable.finalY + 30,
    styles: {
      overflow: "linebreak",
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: 9,
    },
    columnStyles: {
      included: { fillColor: [255, 255, 255] },
    },
    headStyles: {
      fillColor: [248, 248, 248],
      textColor: [0, 0, 0],
    },
  });

  // not included

  var columnsNotIncluded = [{ title: "Not Included", dataKey: "notIncluded" }];

  var rowsNotIncluded = [{ notIncluded: voucher.notIncluded }];

  doc.autoTable(columnsNotIncluded, rowsNotIncluded, {
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: "linebreak",
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: 9,
    },
    columnStyles: {
      notIncluded: { fillColor: [255, 255, 255] },
    },
    headStyles: {
      fillColor: [248, 248, 248],
      textColor: [0, 0, 0],
    },
  });

  // wetsuits

  if (voucher.wetsuitsRental) {
    var columnsWetsuits = [{ title: "Wetsuits rental", dataKey: "wetsuits" }];

    var rowsWetsuits = [{ wetsuits: voucher.wetsuitsRental }];

    doc.autoTable(columnsWetsuits, rowsWetsuits, {
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        overflow: "linebreak",
        lineColor: [0, 0, 0],
        lineWidth: 0.1,
        fontSize: 9,
      },
      columnStyles: {
        wetsuits: { fillColor: [255, 255, 255] },
      },
      headStyles: {
        fillColor: [248, 248, 248],
        textColor: [0, 0, 0],
      },
    });
  }

  // remarks

  var columnsRemarks = [{ title: "Remarks", dataKey: "remarks" }];

  var rowsNotRemarks = [{ remarks: voucher.remarks }];

  doc.autoTable(columnsRemarks, rowsNotRemarks, {
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      overflow: "linebreak",
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: 9,
    },
    columnStyles: {
      remarks: { fillColor: [255, 255, 255] },
    },
    headStyles: {
      fillColor: [248, 248, 248],
      textColor: [0, 0, 0],
    },
  });

  // BODY

  var columnsHeaderPassenger = [{ title: "PASSENGERS", dataKey: "itinerary" }];

  var rowsHeaderPassenger = [];
  doc.autoTable(columnsHeaderPassenger, rowsHeaderPassenger, {
    didDrawPage: function () {
      return false; // Don't draw header row
    },
    overflow: "linebreak",
    startY: doc.lastAutoTable.finalY + 10,
    styles: {
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      fontSize: 9,
    },
    columnStyles: {
      itinerary: { fillColor: [255, 255, 255] },
    },
    headStyles: {
      fillColor: [28, 133, 199],
      textColor: [255, 255, 255],
    },
  });

  var columnPassenger = [
    { title: "CAB", dataKey: "cab" },
    { title: "Name", dataKey: "nombre" },
    { title: "Deck", dataKey: "deck" },
  ];

  var rowsPassenger = [];
  voucher.passengers.map(function (passenger) {
    var cabin = passenger.cabin_name;
    var deck = passenger.deck_name;
    var name = passenger.last_name + " / " + passenger.first_name;

    var rowPassener = {
      cab: cabin,
      nombre: name,
      deck: deck,
    };

    return rowsPassenger.push(rowPassener);
  });

  rowsPassenger = rowsPassenger.sort(function (a, b) {
    if (parseInt(a.cab) > parseInt(b.cab)) return 1;
    if (parseInt(a.cab) < parseInt(b.cab)) return -1;
    return 0;
  });

  doc.autoTable(columnPassenger, rowsPassenger, {
    startY: doc.lastAutoTable.finalY,
    styles: {
      lineColor: [0, 0, 0],
      lineWidth: 0.1,
      overflow: "linebreak",
      fontSize: 10,
      fillColor: [255, 255, 255],
    },
    headStyles: {
      fillColor: [248, 248, 248],
      textColor: [0, 0, 0],
    },
    columnStyles: {
      cab: {
        fillColor: [28, 133, 199],
        textColor: [255, 255, 255],
        halign: "center",
        valign: "middle",
      },
    },
  });

  if (company.code === "rw") {
    createSignTable(doc);
  }

  // GENERATE PDF
  addFooter(doc);
  doc.save("Voucher" + cruise.code + ".pdf");
}
